import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';

import { useTrackContentConsumptionCtx } from 'features/tracks/contexts/TrackContentConsumptionCtx';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';

import { completeQuestionMutationOpts } from '../api/mutations';
import { CompleteMultipleChoiceQuestionBody, CompleteTextQuestionBody } from '../types';

type CompleteMultipleChoiceQuestionPayload = {
  type: 'multipleChoice';
} & Omit<CompleteMultipleChoiceQuestionBody, 'assignment_id'>;

type CompleteTextQuestionPayload = {
  type: 'text';
} & Omit<CompleteTextQuestionBody, 'assignment_id'>;

type CompleteQuestionPayload = CompleteMultipleChoiceQuestionPayload | CompleteTextQuestionPayload;

function getErrorMessage(error: any) {
  return get(error, 'detail', null) || get(error, 0, null);
}

function handleDisplayErrorMessage(error: any) {
  const errorMessage = getErrorMessage(error);
  toast.error('Failed to submit response.', errorMessage);
}

export function useCompleteQuestion() {
  const { currentTrackNode, currentTrackItem, currentItemAssignment } =
    useTrackContentConsumptionCtx();

  const queryClient = useQueryClient();

  /*
    We refetch the assessment after each question completion in order to
    correctly display the progress bar
  */
  const handleRefetchAssessment = () => {
    const assessmentPublicId = get(currentTrackNode, 'track.public_id', '');

    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(assessmentPublicId).queryKey,
    });
  };

  /*
    We refetch the question after each completion in order to correctly display
    the feedback state
  */
  const handleRefetchQuestion = () => {
    const questionPublicId = get(currentTrackItem, 'content_item.public_id', '');

    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(questionPublicId).queryKey,
    });
  };

  const completeMultipleChoiceQuestionMutation = useMutation({
    ...completeQuestionMutationOpts.multipleChoice,
    onError(error) {
      handleDisplayErrorMessage(error);
    },
    onSuccess() {
      handleRefetchQuestion();
      handleRefetchAssessment();
    },
  });

  const completeTextQuestionMutation = useMutation({
    ...completeQuestionMutationOpts.text,
    onError(error) {
      handleDisplayErrorMessage(error);
    },
    onSuccess() {
      handleRefetchQuestion();
      handleRefetchAssessment();
    },
  });

  const handleCompleteQuestion = (payload: CompleteQuestionPayload) => {
    if (!currentItemAssignment) {
      return;
    }

    if (payload.type === 'multipleChoice') {
      completeMultipleChoiceQuestionMutation.mutate({
        assignment_id: currentItemAssignment.id,
        content_type: payload.content_type,
        option_id: payload.option_id,
      });
    } else {
      completeTextQuestionMutation.mutate({
        assignment_id: currentItemAssignment.id,
        content_type: payload.content_type,
        text_response: payload.text_response,
      });
    }
  };

  const isLoading =
    completeMultipleChoiceQuestionMutation.isLoading || completeTextQuestionMutation.isLoading;

  return {
    completeQuestion: handleCompleteQuestion,
    isLoading,
  };
}
