import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPE_COLOR_MAPPING,
  LEARNING_TYPES,
} from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import Icon from 'shared/components/Icon';
import { get } from 'vendor/lodash';

import { useContentItemCardCtx } from './ContentItemCardContext';

const LearningIcon = styled(Icon)`
  flex-shrink: 0;
`;

const LearningTypeBox = styled(Box)`
  display: flex;
  height: 24px;
  padding: 3px 6px 3px 16px;
  align-items: center;
  gap: 8px;
`;

function useLearningTypeVariant(learningType: string) {
  const learningTypeLabels = useLearningTypeLabels();

  const colors = LEARNING_TYPE_COLOR_MAPPING[learningType];
  const icon = LEARNING_TYPE_ICONS_MAPPING[learningType];

  return {
    colors,
    icon,
    label: learningTypeLabels[learningType],
  };
}

export function ContentItemCardLearning() {
  const { contentItem, learningType } = useContentItemCardCtx();

  const {
    colors: learningTypeColors,
    icon: learningTypeIcon,
    label: learningTypeLabel,
  } = useLearningTypeVariant(learningType);

  const isTrack = learningType === LEARNING_TYPES.tracks;
  const trackSteps = get(contentItem, 'number_track_items', 0);

  return (
    <LearningTypeBox bgcolor={learningTypeColors.color200}>
      <LearningIcon
        name={learningTypeIcon}
        width={14}
        height={14}
        color={learningTypeColors.color700}
      />
      <Box display="flex" alignItems="center" gap="4px" color={learningTypeColors.color700}>
        <Typography variant="body2" component="span" noWrap>
          {learningTypeLabel}
        </Typography>
        {isTrack && trackSteps > 0 && (
          <Typography
            variant="body2"
            component="span"
            noWrap
            sx={{
              fontSize: '0.75rem',
            }}
          >
            {' '}
            - {trackSteps} steps
          </Typography>
        )}
      </Box>
    </LearningTypeBox>
  );
}
