import { useRef } from 'react';

import { Session } from 'common/types';
import { useDeleteSessionFromCache } from 'features/sessions/helpers';
import SessionModalManager, {
  useSessionModalManagerState,
} from 'program/components/SessionModalManager';
import { useSessionEnrollmentManager } from 'program/services';
import colors from 'services/colors';
import confirmAlert from 'services/confirm-alert';
import { displayDatetime } from 'services/datetime';
import KebabMenu, { KebabMenuItem } from 'shared/components/KebabMenu';
import { useCurrentUser } from 'shared/hooks';
import { get, join, filter, noop } from 'vendor/lodash';
import { Box, Typography, Card, CardActionArea, CardMedia } from 'vendor/mui';
import { VisibilityIcon, WifiOutlinedIcon, RemoveCircleOutlineIcon } from 'vendor/mui-icons';

interface Props {
  session: Session;
}

const SessionWidgetRow = ({ session }: Props) => {
  const menuRef = useRef(null);
  const { id: currentUserId, timezone: userTimezone } = useCurrentUser();
  const { modalManagerState, modalManagerSetters, openDetailsModal, openRosterModal } =
    useSessionModalManagerState();
  const { deleteSession } = useDeleteSessionFromCache();
  const { dropSession, isLoading } = useSessionEnrollmentManager(session || undefined, {
    onDelete: () => deleteSession(get(session, 'id', -1)),
    onUnenroll: () => deleteSession(get(session, 'id', -1)),
  });
  const openModal = get(session, 'attendance_limit', 0) === 1 ? openDetailsModal : openRosterModal;
  const { name, cover_url: coverURL, host } = session;
  const isHost = currentUserId === host.id;
  return (
    <Card elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CardActionArea onClick={() => openModal()}>
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5 }}>
          <CardMedia
            component="img"
            sx={{ width: 72, height: 44, objectFit: 'cover' }}
            image={coverURL}
            alt={name}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
            <Typography variant="caption" noWrap>
              {join(
                filter(
                  [
                    'Session',
                    displayDatetime(
                      get(session, 'starts_at_tz_aware'),
                      get(session, 'timezone'),
                      userTimezone,
                      get(session, 'allows_online', false)
                    ),
                  ],
                  Boolean
                ),
                ' • '
              )}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
      <KebabMenu ref={menuRef}>
        <KebabMenuItem
          disabled={isLoading}
          actionText="Details"
          ActionIcon={VisibilityIcon}
          onClick={(e) => {
            get(menuRef, 'current.handleClose', noop)(e);
            openModal();
          }}
        />
        <KebabMenuItem
          disabled={isLoading}
          actionText={isHost ? 'Delete' : 'Unenroll'}
          ActionIcon={RemoveCircleOutlineIcon}
          color={colors.error600}
          onClick={(e) => {
            get(menuRef, 'current.handleClose', noop)(e);
            if (isHost) {
              confirmAlert({
                title: 'Delete Session?',
                content: () => <span>Would you like to delete this session?</span>,
                confirmLabel: 'Yes',
                secondaryActionLabel: 'No',
                onConfirm: () => dropSession(),
                isDangerAction: true,
              });
            } else {
              dropSession();
            }
          }}
        />
        {get(session, 'allows_online', false) && (
          <KebabMenuItem
            disabled={isLoading}
            actionText="Join"
            ActionIcon={WifiOutlinedIcon}
            color={colors.action600}
            onClick={(e) => {
              get(menuRef, 'current.handleClose', noop)(e);
              window.open(get(session, 'meeting_url', '') as string, '_blank');
            }}
          />
        )}
      </KebabMenu>
      <SessionModalManager
        session={session}
        state={modalManagerState}
        setters={modalManagerSetters}
        handleDropSession={dropSession}
      />
    </Card>
  );
};

export default SessionWidgetRow;
