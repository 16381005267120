import PropTypes from 'prop-types';

import LogtrailModal from 'shared-modals/components/LogtrailModal';

const UserLogtrailModal = ({ userId, handleClose, ...rest }) => {
  const expression = `eq(user,${userId})`;
  return (
    <LogtrailModal title="User Logs" expression={expression} handleClose={handleClose} {...rest} />
  );
};

UserLogtrailModal.propTypes = {
  userId: PropTypes.string,
  handleClose: PropTypes.func,
};

export default UserLogtrailModal;
