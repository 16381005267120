import PropTypes from 'prop-types';
import React from 'react';

import { TextField } from 'vendor/mui.tsx';

const TextInput = ({ inputProps, ...props }) => {
  return (
    <TextField
      {...props}
      inputProps={{
        style: { resize: 'vertical' },
        ...inputProps,
      }}
      sx={{ ...props.sx }}
      // Fix the label
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

TextInput.defaultProps = {
  type: 'text',
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
};

TextInput.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(['outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
  inputProps: PropTypes.object,
};

export default TextInput;
