import PropTypes from 'prop-types';
import React from 'react';

import Pill from 'shared/components/Pill';
import { isNil } from 'vendor/lodash';

const ScheduledTrackLocation = ({ scheduledTrack, labelInsidePill }) => {
  if (isNil(scheduledTrack?.location)) return null;
  const icon =
    scheduledTrack.is_online && scheduledTrack.is_local
      ? 'world'
      : scheduledTrack.is_online
      ? 'online'
      : 'location';
  return (
    <>
      <Pill
        variant="lighterGrayWithDarkerTextColor"
        icon={icon}
        label={labelInsidePill ? scheduledTrack.location.name : null}
        round={!labelInsidePill}
      />
      {labelInsidePill ? null : scheduledTrack.location.name}
    </>
  );
};

ScheduledTrackLocation.propTypes = {
  scheduledTrack: PropTypes.object.isRequired,
  labelInsidePill: PropTypes.bool,
};

ScheduledTrackLocation.defaultProps = {
  labelInsidePill: false,
};

export default ScheduledTrackLocation;
