import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';
import { titleCase } from 'services/utils';
import { filter, map, reduce, isNil, get } from 'vendor/lodash';

import RQLDashboardFilterBar from '../DashboardFilterBar/RQLDashboardFilterBar';
import RQLCompositeNumberFilter from '../Filters/RQLCompositeNumberFilter';
import RQLCompositeTextFilter from '../Filters/RQLCompositeTextFilter';
import RQLDateRangeFilter from '../Filters/RQLDateRangeFilter';
import RQLDropdownFilter from '../Filters/RQLDropdownFilter';
import EventFilter from '../LazyFilters/EventFilter';
import LazyDropdownFilter from '../LazyFilters/LazyDropdownFilter';

const getFilterComponent = (item) => {
  const label = isNil(item.input.custom_label) ? titleCase(item.filter) : item.input.custom_label;

  if (item.input.type === 'select')
    return <RQLDropdownFilter label={label} options={item.input.options} />;

  if (item.input.type === 'single_select')
    return <RQLDropdownFilter label={label} options={item.input.options} single_select />;

  if (item.input.type === 'model_select') {
    return (
      <LazyDropdownFilter
        label={label}
        actionFunc={actions[item.input.action_name].retrieveList}
        getFetchExtraParams={() => ({
          ...(item.input.extra_params ? item.input.extra_params : {}),
        })}
        isRQL
      />
    );
  }
  if (item.input.type === 'event') return <EventFilter label={label} isRQL />;
  if (item.input.type === 'period') return <RQLDateRangeFilter label={label} />;
  if (item.input.type === 'number') return <RQLCompositeNumberFilter label={label} />;
  return (
    <RQLCompositeTextFilter
      label={label}
      defaultOperator={get(item, 'input.default_operator', 'contains')}
      lookups={item.lookups}
    />
  );
};

const DashboardDynamicFilterBar = ({
  contentType,
  dynamicFilters,
  filterObj,
  addFilter,
  removeFilter,
  updateFilter,
  updateFilters,
  enableSegments,
}) => {
  const filterComponentMapping = reduce(
    dynamicFilters,
    (acc, item) => {
      acc[item.filter] = getFilterComponent(item);
      return acc;
    },
    {}
  );

  const filterMenuOptionsMapping = reduce(
    dynamicFilters,
    (acc, item) => {
      acc[item.filter] = {
        icon: item.input.icon,
        title: isNil(item.input.custom_label) ? titleCase(item.filter) : item.input.custom_label,
      };
      return acc;
    },
    {}
  );

  const defaultFilterNames = map(
    filter(dynamicFilters, (item) => item.input.default),
    (item) => item.filter
  );

  const allFilterNames = map(dynamicFilters, (item) => item.filter);

  return (
    <RQLDashboardFilterBar
      contentType={contentType}
      filters={filterObj.filters || null}
      allFilterNames={allFilterNames}
      defaultFilterNames={defaultFilterNames}
      filterComponentMapping={filterComponentMapping}
      filterMenuOptionsMapping={filterMenuOptionsMapping}
      addFilter={addFilter}
      removeFilter={removeFilter}
      updateFilter={updateFilter}
      updateFilters={updateFilters}
      enableSegments={enableSegments}
    />
  );
};

DashboardDynamicFilterBar.propTypes = {
  contentType: PropTypes.string,
  dynamicFilters: PropTypes.array.isRequired,
  filterObj: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  enableSegments: PropTypes.bool,
};

export default DashboardDynamicFilterBar;
