import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import UserAvatar from 'shared/components/UserAvatar';

import './style.scss';

const StyledMultiCreators = styled.div`
  border: 4px solid ${colors.action600};
  border-radius: 50%;
  background-color: ${colors.action700};
  width: ${(props) => props.size || 56}px;
  height: ${(props) => props.size || 56}px;
  color: ${colors.action700TextColor};
  font-size: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardAvatar = ({
  className,
  creators,
  showBadge,
  badgeTop,
  badgeLeft,
  badgeSize,
  size,
}) => {
  const klassName = className || '';

  const numberOfCreators = creators?.length ?? 0;
  let singleCreator = null;

  if (numberOfCreators === 1) {
    [singleCreator] = creators;
  }

  if (numberOfCreators === 0) {
    return null;
  }

  return (
    <div className={`creator-avatar ${klassName}`}>
      {singleCreator ? (
        <UserAvatar
          size={size}
          user={singleCreator}
          showBadge={showBadge}
          badgeTop={badgeTop}
          badgeLeft={badgeLeft}
          badgeSize={badgeSize}
          hasBorder
        />
      ) : (
        <StyledMultiCreators>{numberOfCreators}</StyledMultiCreators>
      )}
    </div>
  );
};

CardAvatar.propTypes = {
  className: PropTypes.string,
  creators: PropTypes.array,
  showBadge: PropTypes.bool,
  size: PropTypes.string,
  badgeSize: PropTypes.string,
  badgeTop: PropTypes.number,
  badgeLeft: PropTypes.number,
};

export default CardAvatar;
