import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import alerts from 'alerts/reducer';
import backoffice from 'backoffice/reducer';
import entities from 'entities/reducer';
import eventShared from 'event-shared/reducer';
import event from 'event/reducer';
import shared from 'shared/reducer';
import user from 'users/reducer';

export default combineReducers({
  entities,
  event,
  eventShared,
  user,
  alerts,
  shared,
  backoffice,
  form: formReducer,
});
