import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import entitiesActions from 'entities/actions';
import { eventSchema, enrollmentSchema } from 'entities/schema';
import actions from 'event/actions';
import { ApiURLs, fetchURL } from 'services/requests-base';
import usersActions from 'users/actions';

export function* eventDetail(action) {
  const { public_id } = action.payload;

  const url = ApiURLs['api_events:detail']({ public_id });

  try {
    const response = yield call(fetchURL, url);

    const normalized = normalize(response.data, eventSchema);

    yield put(entitiesActions.updateEntities(normalized));
    yield put(
      actions.eventFetchRequestSuccess({
        eventId: response.data.id,
        eventPublicId: response.data.public_id,
        enrollmentId: response.data.enrollment.id,
      })
    );
  } catch (e) {
    yield put(actions.eventFetchRequestFailure({ _error: e.data }));
  }
}

export function* eventFeedbacks(action) {
  const { public_id } = action.payload;

  const url = ApiURLs['api_events:feedbacks']({ public_id });

  try {
    const response = yield call(fetchURL, url);

    yield put(actions.eventFeedbacksFetchRequestSuccess(response.data));
  } catch (e) {
    yield put(actions.eventFeedbacksFetchRequestFailure({ _error: e.data }));
  }
}

export function* eventResources(action) {
  const { public_id } = action.payload;

  const url = ApiURLs['api_events:resources']({ public_id });

  try {
    const response = yield call(fetchURL, url);

    yield put(actions.resourcesFetchRequestSuccess(response.data));
  } catch (e) {
    yield put(actions.resourcesFetchRequestFailure({ _error: e.data }));
  }
}

export function* eventFeedbackForm(action) {
  const { payload } = action;
  const { public_id } = payload;

  const url = ApiURLs['api_events:enrollment_retrieve']({ public_id });

  const options = action.options || {};
  options.method = 'PATCH';
  options.body = JSON.stringify(payload);

  try {
    const response = yield call(fetchURL, url, options);

    const normalized = normalize(response.data, enrollmentSchema);

    yield put(entitiesActions.updateEntities(normalized));

    yield put(actions.eventFeedbackFormSuccess(response.data));

    yield put(actions.eventEnrollmentUpdateRequestSuccess(response.data));
    yield put(actions.eventFetchRequestSubmit({ public_id }));
    yield put(usersActions.currentUserRequestSubmit());
  } catch (e) {
    yield put(actions.eventFeedbackFormFailure({ _error: e.data }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.eventFetchRequestSubmit.toString(), eventDetail);
  yield takeLatest(actions.eventFeedbacksFetchRequestSubmit.toString(), eventFeedbacks);
  yield takeLatest(actions.resourcesFetchRequestSubmit.toString(), eventResources);
  yield takeLatest(actions.eventFeedbackFormSubmit.toString(), eventFeedbackForm);
}
