import PropTypes from 'prop-types';
import React from 'react';

import { Item } from 'inputs/components/ListSelector';
import { map, isEmpty, includes, noop, without } from 'vendor/lodash';

const FilterOptionsList = ({
  disabled,
  selecteds,
  onChange,
  options,
  allItemName,
  singleSelect,
  onAllClick,
  selectAllBeforeEmptying,
  clearWhenSelectingAll,
  checkIsEmpty,
}) => {
  if (isEmpty(options)) return null;

  const handleRemoveItem = (itemValue) => {
    if (includes(selecteds, itemValue)) {
      if (selecteds.length === 1 && selectAllBeforeEmptying) {
        onChange(map(options, 'value'));
      } else {
        onChange(without(selecteds, itemValue));
      }
    }
  };

  const handleAddItem = (itemValue) => {
    if (!includes(selecteds, itemValue)) {
      if (singleSelect) {
        onChange([itemValue]);
      } else if (clearWhenSelectingAll && selecteds.length + 1 === options.length) {
        onChange([]);
      } else {
        onChange([...selecteds, itemValue]);
      }
    }
  };

  const handleChangeItem = (itemValue) => {
    if (includes(selecteds, itemValue)) {
      handleRemoveItem(itemValue);
    } else {
      handleAddItem(itemValue);
    }
  };

  const handleAllClick = () => {
    if (isEmpty(selecteds)) {
      return;
    }
    if (onAllClick) {
      onAllClick(selecteds, onChange);
    } else {
      onChange([]);
    }
  };

  const emptyChecker = checkIsEmpty || isEmpty;

  return (
    <React.Fragment>
      {allItemName && (
        <Item
          item={{ name: allItemName, value: '' }}
          isChecked={emptyChecker(selecteds)}
          onClick={handleAllClick}
          disabled={disabled}
          notRemovable
          limitWidth
          borderless
        />
      )}

      {map(options, (item) => (
        <Item
          key={item.value}
          item={item}
          isChecked={includes(selecteds, item.value)}
          onClick={handleChangeItem}
          disabled={disabled}
          limitWidth
          borderless
        />
      ))}
    </React.Fragment>
  );
};

export default FilterOptionsList;

FilterOptionsList.propTypes = {
  allItemName: PropTypes.any,
  disabled: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
  selecteds: PropTypes.any,
  singleSelect: PropTypes.bool,
  selectAllBeforeEmptying: PropTypes.bool,
  clearWhenSelectingAll: PropTypes.bool,
  onAllClick: PropTypes.func,
  checkIsEmpty: PropTypes.func,
};

FilterOptionsList.defaultProps = {
  onChange: noop,
  singleSelect: false,
  selectAllBeforeEmptying: false,
  clearWhenSelectingAll: true,
};
