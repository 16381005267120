import PropTypes from 'prop-types';
import React from 'react';

import AccordionRadioInput from 'inputs/components/AccordionRadioInput';

const AccordionRadioField = ({ input, meta: { touched, error }, ...props }) => (
  <AccordionRadioInput input={input} hasError={touched && error} {...props} />
);

AccordionRadioField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
};

export default AccordionRadioField;
