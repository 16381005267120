import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import LabelComponent from './Label';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DropingArea = styled.div`
  background: #fff;
  z-index: 1000;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.175);
  width: 100%;

  margin-top: ${({ marginTop }) => marginTop || 40}px;

  ${({ sameWidthAsLabel }) => sameWidthAsLabel && 'min-width: 100%;'}

  ${({ open }) =>
    open
      ? `
    display: inline-block;
  `
      : `
    display: none;
  `}

  ${({ openInBand }) =>
    !openInBand &&
    `
    position: absolute;
  `}

  ${({ openLeft }) =>
    openLeft &&
    `
    right: 0;
    left: auto;
  `}
`;

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      open: false,
      label: '',
      labelActive: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('focusin', this.handleClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('focusin', this.handleClick, false);
  };

  setLabel = (label) => {
    this.setState({ label });
  };

  setLabelActive = (labelActive) => {
    this.setState({ labelActive });
  };

  handleClick = (e) => {
    const { current } = this.node;
    if (e && current && !current.contains(e.target)) {
      this.setState({ open: false });
      e.stopPropagation();
    }
  };

  handleOpenClose = () => {
    const { onOpen, onClose } = this.props;
    const { open } = this.state;

    if (open) {
      if (onOpen) onOpen();
    } else if (onClose) {
      onClose();
    }
  };

  toggleDropdown = (e) => {
    const { disabled } = this.props;

    if (disabled) {
      this.setState({ open: false });
      return;
    }

    this.setState(
      (state) => ({
        open: !state.open,
      }),
      this.handleOpenClose
    );
    e.stopPropagation();
    e.preventDefault();
  };

  openDropdown = (e) => {
    const { disabled } = this.props;

    if (disabled) {
      this.setState({ open: false }, this.handleOpenClose);
      return;
    }

    this.setState({ open: true }, this.handleOpenClose);
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  closeDropdown = (e) => {
    this.setState({ open: false }, this.handleOpenClose);
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render = () => {
    const {
      openInBand,
      children,
      Label,
      disabled,
      labelContentMargin,
      openLeft,
      isInline,
      labelName,
      ...rest
    } = this.props;
    const { open, label, labelActive } = this.state;

    const dropdownControls = {
      open: this.openDropdown,
      close: this.closeDropdown,
      toggle: this.toggleDropdown,
      setLabel: this.setLabel,
      setLabelActive: this.setLabelActive,
    };

    return (
      <Wrapper inline={isInline} ref={this.node}>
        <Label
          label={labelName}
          open={open}
          active={labelActive}
          disabled={disabled}
          dropdownControls={dropdownControls}
        >
          {label}
        </Label>
        <DropingArea
          open={open}
          openInBand={openInBand}
          marginTop={labelContentMargin}
          openLeft={openLeft}
          sameWidthAsLabel={!isInline}
        >
          <HotKeys
            keyMap={{ closeDropdown: 'esc' }}
            handlers={{ closeDropdown: this.closeDropdown }}
          >
            {React.cloneElement(children, {
              ...rest,
              open,
              dropdownControls,
            })}
          </HotKeys>
        </DropingArea>
      </Wrapper>
    );
  };
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  Label: PropTypes.func,
  labelName: PropTypes.string,
  openInBand: PropTypes.bool,
  openLeft: PropTypes.bool,
  labelContentMargin: PropTypes.number,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isInline: PropTypes.bool,
};

Dropdown.defaultProps = {
  Label: LabelComponent,
  labelName: null,
  isInline: true,
};

Dropdown.Wrapper = Wrapper;
Dropdown.DropingArea = DropingArea;

export default Dropdown;
