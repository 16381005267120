import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { useGetEventLabelWithDatetimeRange } from 'event/hooks';
import { Event } from 'event/interfaces';
import colors from 'services/colors';
import { ContentItem } from 'shared-content-item/interfaces';
import { get, noop } from 'vendor/lodash';
import { CardMedia, Paper, Typography, Box, IconButton, grey, Link } from 'vendor/mui';
import { ArrowBack } from 'vendor/mui-icons';

import { useGetContentTypeLabelWithDuration } from '../hooks';

interface LiteHeaderProps {
  content: ContentItem | Event;
}
const LiteHeader = ({ content }: LiteHeaderProps) => {
  const history = useHistory();
  const useGetInfo =
    content.content_type === CONTENT_TYPES.event
      ? useGetEventLabelWithDatetimeRange // is event, show datetime range
      : useGetContentTypeLabelWithDuration; // is another content type, show duration
  const contentInfo = useGetInfo(content);
  const route = get(useContentTypeRoutes(), `${content.content_type}.details`, noop)(content);
  const handleBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(route);
    }
  }, [history, route]);

  return (
    <Paper
      sx={{
        display: 'flex',
        columnGap: 2,
        alignSelf: 'center',
        alignItems: 'center',
        borderRadius: 1,
        width: '80%',
        height: '70px',
        mt: 3,
        p: 1,
        pl: 3,
      }}
    >
      <IconButton onClick={handleBack}>
        <ArrowBack sx={{ color: colors.action700 }} fontSize="small" />
      </IconButton>
      <CardMedia
        component="img"
        alt={content.name}
        src={content.cover_url || content.cover}
        sx={{
          width: '100px',
          height: '50px',
          objectFit: 'contain',
          borderStyle: 'solid',
          borderColor: grey['400'],
          borderRadius: 1,
          borderWidth: '1px',
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0, ml: 1, overflow: 'hidden' }}>
        <Link variant="h6" noWrap href={route} underline="hover" color={grey['900']}>
          {content.name}
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Typography variant="caption">{contentInfo}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default LiteHeader;
