import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import propTypes from 'services/prop-types';
import { FormFieldGroup } from 'shared/components/Form';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import * as validations from 'shared/formValidations';

import DeleteButton from './DeleteButton';
import DeletePanel from './DeletePanel';
import FieldLabel from './FieldLabel';
import FormErrorContainer from './FormErrorContainer';
import FormPanel from './FormPanel';
import SubmitButton from './SubmitButton';

const TitleContainer = styled.h1`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const FormWrapper = styled.form`
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
`;

const FieldsContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  margin-bottom: ${({ margin }) => margin}px;
`;

const SubmittingMessage = styled.div`
  text-align: center;
  padding: 10px 0 10px 0;
  color: #3cce9e;
  font-weight: 500;
  font-size: 24px;
  font-style: italic;
`;

const SubmitButtonsContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
`;

const Form = ({
  title,
  children,
  renderButtons,
  showSaving,
  formRef,
  renderTopInfoBox,
  renderBottomInfoBox,
  ...props
}) => {
  const { submitting, margin, rightTitleSection } = props;
  return (
    <FormWrapper ref={formRef} {...props}>
      {title && (
        <TitleContainer>
          <Text size="h1" color={colors.neutral400}>
            {title}
          </Text>
          {rightTitleSection}
        </TitleContainer>
      )}
      {renderTopInfoBox?.()}
      <FieldsContainer margin={margin}>{children}</FieldsContainer>
      {renderBottomInfoBox?.()}
      {submitting && showSaving ? (
        <SubmittingMessage>
          <Loading />
        </SubmittingMessage>
      ) : (
        renderButtons?.()
      )}
    </FormWrapper>
  );
};

Form.propTypes = {
  title: PropTypes.string,
  rightTitleSection: propTypes.anyChildren,

  children: propTypes.anyChildren,
  renderButtons: PropTypes.func,

  showSaving: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,

  margin: PropTypes.string,
  formRef: PropTypes.any,
  renderTopInfoBox: PropTypes.func,
  renderBottomInfoBox: PropTypes.func,
};

Form.defaultProps = {
  margin: '36',
};

Form.ErrorContainer = FormErrorContainer;
Form.Panel = FormPanel;
Form.FieldLabel = FieldLabel;
Form.FieldGroup = FormFieldGroup;
Form.SubmitButton = SubmitButton;
Form.DeleteButton = DeleteButton;
Form.DeletePanel = DeletePanel;
Form.SubmitButtonsContainer = SubmitButtonsContainer;

Form.validations = validations;

export default Form;
