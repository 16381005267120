import PropTypes from 'prop-types';
import React from 'react';

import { Rating } from '../../../shared';

const RatingField = ({ input, ...props }) => {
  const handleChange = (event, value) => {
    input.onChange(value);
  };

  return <Rating value={input.value} onChange={handleChange} {...props} />;
};

RatingField.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
};

export default RatingField;
