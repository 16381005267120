import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import {
  ATTENDANCE_METHOD_LOCAL,
  ATTENDANCE_METHOD_ONLINE,
  STATUS_RESERVED,
} from 'program/constants';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { get, isEmpty } from 'vendor/lodash';

const EnrollButton = ({ session, attendee, children, onUserEnroll }) => {
  const [updateEnrollment, { status: updateEnrollmentStatus }] = useEntities(
    actions.session.enrollmentUpdate,
    ({ status }) => {
      if (status === STATUS_DONE) {
        onUserEnroll();
      }
    }
  );

  const enrollUser = (attendanceMethod) => {
    const payload = {
      attendance_method: attendanceMethod,
      status: STATUS_RESERVED,
    };

    updateEnrollment(session.id, attendee.id, payload);
  };

  const allowsOnline = get(session, 'allows_online', false);
  const allowsLocal = get(session, 'allows_local', false);

  const text = isEmpty(children) ? 'Enroll' : children;

  if (updateEnrollmentStatus === STATUS_LOADING) {
    return <Loading size={8} hasMargin={false} />;
  }

  return (
    <React.Fragment>
      {allowsLocal && (
        <Button
          onClick={() => enrollUser(ATTENDANCE_METHOD_LOCAL)}
          startIcon={<Icon name="location" />}
        >
          {text}
        </Button>
      )}

      {allowsOnline && (
        <Button
          onClick={() => enrollUser(ATTENDANCE_METHOD_ONLINE)}
          startIcon={<Icon name="online" />}
        >
          {text}
        </Button>
      )}
    </React.Fragment>
  );
};

EnrollButton.propTypes = {
  session: PropTypes.object,
  attendee: PropTypes.object,
  children: PropTypes.node,
  onUserEnroll: PropTypes.func,
};

export default EnrollButton;
