import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AttendanceActionManager from 'event-shared/components/AttendanceActionManager';
import { capitalize } from 'vendor/lodash';

import {
  EventTypeAttendanceRenderEnrolled,
  EventTypeAttendanceRenderProspect,
} from './EventTypeEnrollmentActionComponents';

const ActionElementContainer = styled.div`
  padding: 0 20px;
`;

const EventTypeEnrollmentActionElement = ({ event, labelExternalEnrollmentCompleted }) => {
  const unenrollLabel = event.external_link ? capitalize(labelExternalEnrollmentCompleted) : null;

  return (
    <ActionElementContainer>
      <AttendanceActionManager
        event={event}
        renderEnrolled={(actions, status) => (
          <EventTypeAttendanceRenderEnrolled
            actions={actions}
            status={status}
            unenrollLabel={unenrollLabel}
          />
        )}
        renderProspect={(actions, status) => (
          <EventTypeAttendanceRenderProspect actions={actions} status={status} />
        )}
      />
    </ActionElementContainer>
  );
};

EventTypeEnrollmentActionElement.propTypes = {
  event: PropTypes.object,
  labelExternalEnrollmentCompleted: PropTypes.string,
};

const mapStateToProps = (state) => ({
  labelExternalEnrollmentCompleted:
    state.user.currentUser.labels.label_events_external_enrollment_completed,
});

export default connect(mapStateToProps)(EventTypeEnrollmentActionElement);
