import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { withProductTour } from 'services/product-tour';
import InfoText from 'shared/components/InfoText';

import FieldError from '../FieldError';
import FieldWarning from '../FieldWarning';
import TextInput from '../TextInput';

const Container = styled.div`
  width: 100%;
  margin: 8px 0 0;
`;

const TextField = (props) => {
  const { meta, input, subtext, warning, ...rest } = props;
  const error = meta.error && meta.touched;
  return (
    <Container>
      <TextInput id={input.name} {...input} {...rest} error={error} />
      {subtext && <InfoText top={4} content={subtext} />}
      <FieldError meta={meta} />
      <FieldWarning warning={warning} meta={meta} />
    </Container>
  );
};

TextField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  warning: PropTypes.string,
};

export default withProductTour(TextField);
