/* eslint-disable lodash/prefer-lodash-method */
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { useCurrentExpressionWithoutPage } from 'app/backoffice/utils';
import { DASHBOARD_TYPES_URL_MAPPING } from 'scenes/Dashboard/constants';
import { mapRoute } from 'services/requests';
import Modal from 'shared/components/Modal';
import { isEmpty } from 'vendor/lodash';

import SaveSegmentForm from './SaveSegmentForm';

export const SaveSegmentModal = ({ segment, contentType, handleClose, refreshSegments }) => {
  const location = useLocation();
  const history = useHistory();
  const isEdit = !isEmpty(segment);
  const expression = useCurrentExpressionWithoutPage();

  return (
    <Modal
      title={isEdit ? 'Edit Segment' : 'Save as'}
      handleClose={handleClose}
      height="290px"
      width={500}
    >
      <SaveSegmentForm
        initialValues={segment}
        isEdit={isEdit}
        expression={expression}
        contentType={contentType}
        handleClose={handleClose}
        onSubmitSuccessHandler={({ public_id }) => {
          refreshSegments();
          if (!isEdit) {
            // Redirect the user to the newly created segment page
            const newSegmentUrl = mapRoute(DASHBOARD_TYPES_URL_MAPPING[contentType], { public_id });
            history.replace(`${newSegmentUrl}${location.search}`);
          }
          handleClose();
        }}
      />
    </Modal>
  );
};

SaveSegmentModal.propTypes = {
  segment: PropTypes.object,
  contentType: PropTypes.string,
  handleClose: PropTypes.func,
  refreshSegments: PropTypes.func,
};

SaveSegmentModal.defaultProps = {
  segment: {},
};

export default SaveSegmentModal;
