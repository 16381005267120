import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';

import LazyDropdownFilter from './LazyDropdownFilter';

const GroupFilter = ({ label, ...props }) => {
  return <LazyDropdownFilter label={label} actionFunc={actions.groups.retrieveList} {...props} />;
};

GroupFilter.propTypes = {
  label: PropTypes.string,
};

GroupFilter.defaultProps = {
  label: 'User Groups',
};

export default GroupFilter;
