import React from 'react';

export const ContentItemListenerContext = React.createContext<{
  onChange?: (({ goToFirstPage }: { goToFirstPage?: any }) => void) | null;
  onDelete?: (({ content }: { content?: any }) => void) | null;
}>({
  // null instead of noop because the flow might be different if not present
  onChange: null,
  onDelete: null,
});
