import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router';

import Button from 'app/shared/components/Button';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { canBeDropped } from 'assignments/services';
import { CONTENT_TYPES, DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { useAssignmentActionsCtx } from 'features/contentitems/contexts/AssignmentActionsCtx';
import { DropAssignmentBody } from 'features/contentitems/types';
import { useComposableTrackCtx } from 'features/tracks/contexts/ComposableTrackCtx';
import { ContentItem } from 'shared-content-item/interfaces';
import ButtonLink from 'shared/components/ButtonLink';
import Datetime from 'shared/components/Datetime';
import Pill from 'shared/components/Pill';
import { useIsPreviewQueryParam } from 'shared/hooks';
import DropTrackItemWarnModal from 'stand-alone-shared/components/DropTrackItemWarnModal';
import { get, map, size } from 'vendor/lodash';
import { Box } from 'vendor/mui';
import { BookmarkBorderOutlined, HighlightOffIcon, VisibilityIcon } from 'vendor/mui-icons';

type DropTrackItemConflictInfo = {
  dependents: ContentItem[];
};

interface SecondaryActionButtonProps {
  content: ContentItem;
}

export function SecondaryActionButton(props: SecondaryActionButtonProps) {
  const { content } = props;

  const history = useHistory();

  const [dropTrackItemConflictInfo, setDropTrackItemConflictInfo] =
    React.useState<DropTrackItemConflictInfo | null>(null);
  const showDropTrackItemWarnModal = dropTrackItemConflictInfo != null;

  const { content_type: contentType } = content;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');

  const { create, drop, undoCompletion, isLoading } = useAssignmentActionsCtx();
  const { rootTrack, onRefreshDescendantTrackItems } = useComposableTrackCtx();

  const handleDrop = React.useCallback(
    (dropAssignmentBody: Omit<DropAssignmentBody, 'id'> = {}) => {
      drop(dropAssignmentBody, {
        onSuccess() {
          onRefreshDescendantTrackItems();
        },
        onError(error) {
          const dropError = get(error, 'data.exception');

          if (dropError === DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME) {
            setDropTrackItemConflictInfo({
              dependents: get(error, 'data.dependents', []),
            });
          }
        },
      });
    },
    [drop, content, onRefreshDescendantTrackItems]
  );

  const isPreviewMode = useIsPreviewQueryParam();

  const contentTypeRoutes = useContentTypeRoutes();

  const goToPage = React.useCallback(
    (targetPage: number, preview = false) => {
      const { content_type: contentType, public_id_and_slug: publicIdAndSlug } = rootTrack;
      const url = contentTypeRoutes[contentType].details({
        public_id_and_slug: publicIdAndSlug,
      });

      if (preview) {
        history.push(`${url}?page=${targetPage}&preview=${preview}`);
        return;
      }

      history.push(`${url}?page=${targetPage}`);
    },
    [history, rootTrack, contentTypeRoutes]
  );

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    if (isAssessment || isPreviewMode) {
      return null;
    }

    return (
      <ButtonLink onClick={undoCompletion} variant="error" disabled={isLoading}>
        Undo
      </ButtonLink>
    );
  }

  if (canBeDropped(assignment)) {
    // The code is dealing with both formats, because the old content item endpoint is still
    // returning the assignment in the old format, as long as the migration to CA.2.0 is not
    // done completely, we have to deal with these two formats.
    const dueDate = get(assignment, 'due_date') || get(assignment, 'due_at');
    const isOverdue = dueDate ? moment().isAfter(moment(dueDate).endOf('day')) : false;
    const formattedDueDate = dueDate ? Datetime.Date({ datetime: dueDate }) : '';

    if (dueDate) {
      <Box
        sx={{
          flex: '1 0 auto',
          lineHeight: '18px',
        }}
      >
        {isOverdue ? (
          <Pill icon="exclamation" label={formattedDueDate} variant="error200" size="medium" />
        ) : (
          <Pill icon="clock-filled" label={formattedDueDate} variant="neutral200" size="medium" />
        )}
      </Box>;
    }

    return (
      <>
        <Button onClick={() => handleDrop()} startIcon={<HighlightOffIcon />} disabled={isLoading}>
          Drop
        </Button>
        {showDropTrackItemWarnModal && (
          <DropTrackItemWarnModal
            handleClose={() => setDropTrackItemConflictInfo(null)}
            unassign={() => handleDrop({ force: true })}
            content={content}
            tracks={map(get(dropTrackItemConflictInfo, 'dependents', []), 'content_item')}
          />
        )}
      </>
    );
  }

  if (isAssessment) {
    return (
      <Button
        onClick={() => create()}
        disabled={isLoading || !size(content?.track_items)}
        startIcon={<BookmarkBorderOutlined />}
      >
        Save
      </Button>
    );
  }

  return (
    <Button onClick={() => goToPage(1, true)} disabled={isLoading} startIcon={<VisibilityIcon />}>
      Preview
    </Button>
  );
}
