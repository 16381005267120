import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import actions from 'entities/actions';
import { automatedRuleSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { STATUS_DONE } from 'shared/constants';
import { omit } from 'vendor/lodash';

const Context = createContext({});

export const useAutomatedRuleContext = () => useContext(Context);

export const AutomatedRuleProvider = ({ children }) => {
  // This is necessary due to a bug in how Page.js handle the people segment
  // that prevent us to use `public_id` there.
  const { id: automatedRuleId, public_id: automatedRulePublicId } = useParams();
  const [automatedRule, setAutomatedRule] = useState({});

  const [
    fetchRule,
    { data, status: fetchAutomatedRuleStatus, statusCode: fetchAutomatedRuleStatusCode },
  ] = useEntities(
    actions.automatedRule.retrieveDetails,
    () => {
      if (fetchAutomatedRuleStatus === STATUS_DONE) {
        const { parameters, segment } = data;

        setAutomatedRule({
          ...omit(data, ['parameters', 'segment_id']),
          ...parameters,
          segment_id: segment.public_id,
        });
      }
    },
    {
      schema: automatedRuleSchema,
    }
  );

  useEffect(() => {
    fetchRule(automatedRuleId || automatedRulePublicId);
  }, []);

  return (
    <Context.Provider
      value={{ automatedRule, fetchAutomatedRuleStatus, fetchAutomatedRuleStatusCode }}
    >
      {children}
    </Context.Provider>
  );
};

AutomatedRuleProvider.propTypes = {
  children: PropTypes.node,
};
