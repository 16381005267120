import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from 'services/requests-base';

const fetchTrackItems = async (
  trackId: number,
  userId?: number,
  options: Record<string, any> = { view_mode: 'default' }
) => {
  const queryParams = new URLSearchParams(options).toString();
  const base = userId
    ? ApiURLs['tracks_api:track_items_list_with_user_assignments'](trackId, userId)
    : ApiURLs['tracks_api:track_items_list_with_self_assignments'](trackId);
  const url = `${base}?${queryParams}`;
  const { data } = await fetchURL(url);
  return data;
};

export const track_items = createQueryKeys('track_items', {
  list: (
    trackId: number,
    userId?: number,
    options: Record<string, any> = { view_mode: 'default' }
  ) => ({
    queryKey: [trackId, userId, options],
    queryFn: () => fetchTrackItems(trackId, userId, options),
  }),
});

export type TrackItemsQueryKeys = inferQueryKeys<typeof track_items>;
