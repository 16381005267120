import { Link } from 'react-router-dom';

import Dashboard from 'backoffice/components/Dashboard';
import { useContentTypeRoutes } from 'catalog/hooks';
import colors from 'services/colors';
import { displayDate } from 'services/datetime';
import Text from 'shared/components/Text';
import { Stack, Tooltip, grey } from 'vendor/mui';
import { ErrorIcon } from 'vendor/mui-icons';

import { Activity } from './interfaces';

const actionMap = {
  // General content item actions
  assigned: 'Assigned to',
  started: 'Started',
  completed: 'Completed',
  dropped: 'Dropped',
  exempted: 'Exempted from',

  // Event-specific actions
  presented: 'Presented',
  attended: 'Attended',
  waitlisted: 'Waitlisted in',
  enrolled: 'Enrolled in',
};

export const ActivityRecord = ({
  action,
  actor,
  target,
  is_expired,
  expires_at,
  content_item: { public_id_and_slug, name, content_type },
}: Activity) => {
  const contentTypeRoutesMap = useContentTypeRoutes();

  const buildContentItemRoute = contentTypeRoutesMap[content_type]?.details;
  return (
    <Stack direction="row" spacing={0.4}>
      <Text size="h5" color={colors.neutral900}>
        {actionMap[action]}{' '}
        <Link
          to={buildContentItemRoute?.({
            public_id_and_slug,
          })}
        >
          <Text primary>{name}</Text>
        </Link>
      </Text>
      <Text size="h5" color={colors.neutral700}>
        {actor.id !== target.id && (
          <Stack direction="row">
            [by&nbsp;
            <Dashboard.UserCell data={actor} />]
          </Stack>
        )}
      </Text>
      {is_expired && (
        <Tooltip title={`Expired on ${displayDate(expires_at)}`}>
          <ErrorIcon fontSize="small" sx={{ color: grey[700] }} />
        </Tooltip>
      )}
    </Stack>
  );
};
