import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { getSessionsAvailableCount } from 'program/services';
import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

import SessionGroupEnrollButtons from './SessionGroupEnrollButtons';

const GroupsCheckinListItemWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid ${colors.neutral100};

  &:last-child {
    border-bottom: none;
  }
`;

const GroupsCheckinListItemIconArea = styled.div`
  width: 40px;
  height: 28px;
`;

const GroupsCheckinListItemContentArea = styled.div`
  flex: 1 0 auto;
`;

const GroupsCheckinListItemActionArea = styled.div`
  display: flex;

  > * + * {
    margin-left: 16px;
  }
`;

const GroupsCheckinListItemTitle = styled.div`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  color: ${colors.neutral900};
  line-height: 1;
`;

const GroupsCheckinListItemOwnedBy = styled.span`
  font-size: 12px;
  color: ${colors.neutral600};
  line-height: 1;
`;

const SessionGroupListItem = ({ session, group, refreshEnrollments }) => {
  const groupHasMembers = group.members_count > 0;
  const sessionHasSpotForGroup = group.members_count <= getSessionsAvailableCount(session);
  const sessionHasUnlimitedSpots = session.attendance_limit === 0;

  const isGroupAllowedForEnrollment =
    groupHasMembers && (sessionHasUnlimitedSpots || sessionHasSpotForGroup);

  return (
    <GroupsCheckinListItemWrapper>
      <GroupsCheckinListItemIconArea>
        {group.is_private ? (
          <Icon
            name="lock"
            width={28}
            height={28}
            color={isGroupAllowedForEnrollment ? colors.neutral600 : colors.neutral400}
          />
        ) : (
          <Icon
            name="world"
            width={28}
            height={28}
            color={isGroupAllowedForEnrollment ? colors.neutral600 : colors.neutral400}
          />
        )}
      </GroupsCheckinListItemIconArea>
      <GroupsCheckinListItemContentArea>
        <GroupsCheckinListItemTitle>
          {' '}
          <Text block color={isGroupAllowedForEnrollment ? colors.neutral900 : colors.neutral400}>
            {group.name} ({group.members_count})
          </Text>
        </GroupsCheckinListItemTitle>
        {group.owner && (
          <GroupsCheckinListItemOwnedBy>Owned by {group.owner.name}</GroupsCheckinListItemOwnedBy>
        )}
      </GroupsCheckinListItemContentArea>
      {isGroupAllowedForEnrollment && (
        <GroupsCheckinListItemActionArea>
          <SessionGroupEnrollButtons
            session={session}
            group={group}
            refreshEnrollments={refreshEnrollments}
          />
        </GroupsCheckinListItemActionArea>
      )}
    </GroupsCheckinListItemWrapper>
  );
};

SessionGroupListItem.propTypes = {
  group: PropTypes.object,
  session: PropTypes.object,
  refreshEnrollments: PropTypes.func,
};

export default SessionGroupListItem;
