import { useMemo, useState } from 'react';

import { ATTENDANCE_METHODS, ATTENDANCE_STATUSES } from 'enrollments/constants';
import { Event } from 'event/interfaces';
import Modal, { ModalBody } from 'shared/components/Modal';
import { get } from 'vendor/lodash';
import { Tab, Box } from 'vendor/mui';
import { TabContext, TabList, TabPanel } from 'vendor/mui-lab';

import EnrollmentsQueue from './EnrollmentsQueue';

interface ManageQueueProps {
  event: Event;
  handleClose: () => void;
  handleBack?: () => void;
}

const ManageQueue = ({ event, handleClose, handleBack }: ManageQueueProps) => {
  const {
    id: eventId,
    is_online: isOnline,
    is_local: isLocal,
    enrollment_queue_dict: enrollmentQueueState,
  } = event;
  const [selectedTab, setSelectedTab] = useState(
    isOnline ? ATTENDANCE_METHODS.online : ATTENDANCE_METHODS.local
  );
  const handleChangeTab = (_, newValue: ATTENDANCE_METHODS) => setSelectedTab(newValue);
  const tabProps = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    height: '530px',
    width: '100%',
  };

  const onlineTab = useMemo(() => {
    if (!isOnline) return null;
    return (
      <EnrollmentsQueue
        eventId={eventId}
        maxOrder={get(enrollmentQueueState, 'max_going_online_order', 0) || 0}
        attendanceMethod={ATTENDANCE_METHODS.online}
        attendanceStatuses={[ATTENDANCE_STATUSES.enrolled, ATTENDANCE_STATUSES.checked_in]}
      />
    );
  }, [eventId, enrollmentQueueState, isOnline]);
  const onlineWaitlistTab = useMemo(() => {
    if (!isOnline) return null;
    return (
      <EnrollmentsQueue
        eventId={eventId}
        maxOrder={get(enrollmentQueueState, 'max_wait_list_online_order', 0) || 0}
        attendanceMethod={ATTENDANCE_METHODS.online}
        attendanceStatuses={[ATTENDANCE_STATUSES.waitlisted]}
      />
    );
  }, [eventId, enrollmentQueueState, isOnline]);
  const localTab = useMemo(() => {
    if (!isLocal) return null;
    return (
      <EnrollmentsQueue
        eventId={eventId}
        maxOrder={get(enrollmentQueueState, 'max_going_order', 0) || 0}
        attendanceMethod={ATTENDANCE_METHODS.local}
        attendanceStatuses={[ATTENDANCE_STATUSES.enrolled, ATTENDANCE_STATUSES.checked_in]}
      />
    );
  }, [eventId, enrollmentQueueState, isLocal]);
  const localWaitlistTab = useMemo(() => {
    if (!isLocal) return null;
    return (
      <EnrollmentsQueue
        eventId={eventId}
        maxOrder={get(enrollmentQueueState, 'max_wait_list_order', 0) || 0}
        attendanceMethod={ATTENDANCE_METHODS.local}
        attendanceStatuses={[ATTENDANCE_STATUSES.waitlisted]}
      />
    );
  }, [eventId, enrollmentQueueState, isLocal]);
  return (
    <Modal
      title={`Queue management - ${event.name}`}
      width={1240}
      handleClose={handleClose}
      handleBack={handleBack}
    >
      <ModalBody>
        <TabContext value={selectedTab}>
          <TabList onChange={handleChangeTab} centered>
            <Tab label="Online" value="online" disabled={!event.is_online} />
            <Tab label="Online waitlist" value="online-waitlist" disabled={!event.is_online} />
            <Tab label="In-person" value="local" disabled={!event.is_local} />
            <Tab label="In-person waitlist" value="local-waitlist" disabled={!event.is_local} />
          </TabList>
          <TabPanel value="online">
            <Box sx={tabProps}>{onlineTab}</Box>
          </TabPanel>
          <TabPanel value="online-waitlist">
            <Box sx={tabProps}>{onlineWaitlistTab}</Box>
          </TabPanel>
          <TabPanel value="local">
            <Box sx={tabProps}>{localTab}</Box>
          </TabPanel>
          <TabPanel value="local-waitlist">
            <Box sx={tabProps}>{localWaitlistTab}</Box>
          </TabPanel>
        </TabContext>
      </ModalBody>
    </Modal>
  );
};

export default ManageQueue;
