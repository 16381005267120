import { useQuery } from '@tanstack/react-query';

import { CONTENT_TYPES } from 'app/catalog/constants';
import { EVENT_VIEW_MODES } from 'app/event/constants';
import { queries } from 'queries';
import { size } from 'vendor/lodash';
import rql from 'vendor/rql';

type UseChannelHomeDataParams = {
  rqlExpression: string;
};

export function useChannelHomeDiscoverData(channelId: number, params: UseChannelHomeDataParams) {
  const { rqlExpression } = params;

  const parsedRqlExpression = size(rqlExpression) > 0 ? `&${rqlExpression}` : '';

  const upcomingEventsListRqlExpression = rql({
    start_time: {
      $gt: '-PT0H',
    },
    $ordering: 'start_time',
    status: 'published',
    channel: channelId,
    view_mode: EVENT_VIEW_MODES.listing,
    page_size: 6,
    page: 1,
  });

  const { data: eventsData, isLoading: isLoadingEvents } = useQuery({
    ...queries.events.list(`${upcomingEventsListRqlExpression}${parsedRqlExpression}`),
  });

  const newestContentsListRqlExpression = rql({
    is_inline: false,
    content_type: {
      $out: [
        CONTENT_TYPES.event,
        CONTENT_TYPES.task,
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.question,
        CONTENT_TYPES.text_question,
        CONTENT_TYPES.multiple_choice_question,
      ],
    },
    $ordering: '-created',
    channel: channelId,
    status: 'published',
    view_mode: 'catalog',
    page_size: 6,
    page: 1,
  });

  const { data: contentData, isLoading: isLoadingContent } = useQuery({
    ...queries.content_items.list(`${newestContentsListRqlExpression}${parsedRqlExpression}`),
  });

  const topExpertsListRqlExpression = rql({
    $ordering: '-unique_people_impacted_count',
    channel: channelId,
    view_mode: 'people',
    page_size: 6,
    page: 1,
  });

  // TODO: Filter users that are subscribed to the channel
  const { data: expertsData, isLoading: isLoadingExperts } = useQuery({
    ...queries.users.list(`${topExpertsListRqlExpression}${parsedRqlExpression}`),
  });

  const isLoadingData = isLoadingEvents || isLoadingContent || isLoadingExperts;

  return {
    upcomingEvents: eventsData,
    newestContents: contentData,
    topExperts: expertsData,
    isLoadingData,
  };
}
