import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const CloseIcon = ({ width, height, fill }) => {
  const d =
    'M6.166 5L10 8.834 8.834 10 5 6.166 1.166 10 0 8.834 3.834 5 0 1.166 1.166 0 5 3.834 8.834 0 10 1.166 6.166 5z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 10 10" />;
};

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

CloseIcon.defaultProps = {
  width: 10,
  height: 10,
  fill: '#FFF',
};

export default CloseIcon;
