import { CONTENT_TYPES } from 'catalog/constants';
import {
  filter,
  forEach,
  get,
  isEmpty,
  map,
  isNil,
  has,
  reverse,
  reduce,
  includes,
} from 'vendor/lodash';

import { Track, TrackItem, TrackSectionOrItemNode, TrackSectionWithItems } from '../types';

import { isItemTrackSection } from './helpers';

const getFieldValueFromTrackItems = (fieldName: string, trackItems: TrackItem[] = []) => {
  return reduce(
    trackItems,
    (store, item) => ({
      ...store,
      [get(item, 'content_item.public_id')]: get(item, fieldName),
    }),
    {}
  );
};

function getTrackItemHash(item: TrackItem) {
  const itemPublicId = get(item, 'content_item.public_id');
  const trackPublicId = get(item, 'track_id');

  return `${trackPublicId}_${itemPublicId}`;
}

export function buildComposableTrackData(track: Track, descendantTrackItems: TrackItem[]) {
  const trackItemsAssignments = getFieldValueFromTrackItems(
    'content_item.user_assignment',
    track.track_items
  );

  const trackItemsPermissions = getFieldValueFromTrackItems(
    'content_item.permissions',
    track.track_items
  );

  const sectionsAndItemsOrderedList: TrackSectionOrItemNode[] = [];

  // Is less expensive to reverse and pop/push than use shift/unshift
  const stack: TrackSectionOrItemNode[] = isEmpty(track.sections)
    ? reverse(filter(descendantTrackItems, ['track_id', track.public_id]))
    : reverse(map(track.sections));

  /*
    We need to expand every section in the stack and add its items to the stack.
    We also need to do this for every item that has sections (ie: nested track or assessment)
  */
  while (!isEmpty(stack)) {
    let currentItem = stack.pop() as TrackSectionOrItemNode;

    const currentContentItem = get(currentItem, 'content_item');

    if (isNil(currentContentItem)) {
      // this is a section

      currentItem = { ...currentItem, track_id: currentItem.track_id || track.public_id };
      const sectionDescendantItems = filter(descendantTrackItems, ['section', currentItem.id]);

      stack.push(...reverse(sectionDescendantItems));
    } else if (
      includes(
        [CONTENT_TYPES.track, CONTENT_TYPES.assessment],
        get(currentContentItem, 'content_type')
      )
    ) {
      const currentItemSections = get(currentContentItem, 'sections');

      if (!isEmpty(currentItemSections)) {
        const currentItemSectionsMapped = map(currentItemSections, (section) => ({
          ...section,
          track_id: currentContentItem.public_id,
        }));

        stack.push(...reverse(currentItemSectionsMapped));
      } else {
        const currentItemDescendantItems = filter(descendantTrackItems, [
          'track_id',
          currentContentItem.public_id,
        ]);
        stack.push(...reverse(currentItemDescendantItems));
      }
    }

    if (currentItem) {
      sectionsAndItemsOrderedList.push(currentItem);
    }
  }

  /*
    Hydrate items with their respective assignments and permissions
    Also, group hydrated items by section
  */
  const hydratedItemsMap = new Map<string, TrackItem>();
  const hydratedItemsBySectionMap = new Map<string, TrackItem[]>();

  forEach(sectionsAndItemsOrderedList, (item) => {
    // Skip sections
    if (isItemTrackSection(item)) {
      return;
    }

    const contentItem = get(item, 'content_item');
    const contentItemPublicId = get(contentItem, 'public_id');
    const contentItemAssignments = get(trackItemsAssignments, contentItemPublicId);
    const contentItemPermissions = has(contentItem, 'permissions')
      ? contentItem.permissions
      : get(trackItemsPermissions, contentItemPublicId);

    const hydratedItem: TrackItem = {
      ...item,
      content_item: {
        ...contentItem,
        user_assignment: contentItemAssignments,
        permissions: contentItemPermissions,
      },
    };

    const itemHash = getTrackItemHash(hydratedItem);
    hydratedItemsMap.set(itemHash, hydratedItem);

    const sectionId = get(item, 'section');
    if (sectionId) {
      if (!hydratedItemsBySectionMap.has(sectionId)) {
        hydratedItemsBySectionMap.set(sectionId, []);
      }

      hydratedItemsBySectionMap.get(sectionId)!.push(hydratedItem);
    }
  });

  const sectionsAndHydratedItemsOrderedList: TrackSectionOrItemNode[] = [];
  const nonEmptySectionsAndItemsOrderedList: TrackSectionOrItemNode[] = [];
  const nonEmptySectionsWithItems: TrackSectionWithItems[] = [];

  forEach(sectionsAndItemsOrderedList, (item) => {
    const itemId = get(item, 'id');

    if (isNil(itemId)) {
      return;
    }

    if (isItemTrackSection(item)) {
      sectionsAndHydratedItemsOrderedList.push(item);

      const sectionItems = hydratedItemsBySectionMap.get(itemId as string) || [];

      if (isEmpty(sectionItems)) {
        return;
      }

      nonEmptySectionsAndItemsOrderedList.push(item);

      const hydratedSection = { ...item, items: sectionItems };
      nonEmptySectionsWithItems.push(hydratedSection);

      return;
    }

    const itemHash = getTrackItemHash(item);
    const hydratedItem = hydratedItemsMap.get(itemHash);

    if (hydratedItem == null) {
      return;
    }

    sectionsAndHydratedItemsOrderedList.push(hydratedItem);
    nonEmptySectionsAndItemsOrderedList.push(hydratedItem);
  });

  const sectionsAndItemsIndexesMapping: Record<string, number> = {};

  forEach(nonEmptySectionsAndItemsOrderedList, (value, index) => {
    // TrackSection has no 'public_id', just 'id'. TrackItem has both.

    const track_id = get(value, 'track_id');
    const id = get(value, 'content_item.public_id') || get(value, 'id');

    sectionsAndItemsIndexesMapping[`${track_id}_${id}`] = index;
  });

  return {
    sectionsAndItemsOrderedList: sectionsAndHydratedItemsOrderedList,
    nonEmptySectionsAndItemsOrderedList,
    sectionsAndItemsIndexesMapping,
    nonEmptySectionsWithItems,
  };
}
