import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoogleDriveRenderer = (props) => {
  const {
    blockProps: { src, preview },
  } = props;

  if (preview) {
    return (
      <PreviewContainer>
        <i className="mdi mdi-24px mdi-google-drive" /> Embedded Google Drive Document
      </PreviewContainer>
    );
  }
  return (
    <iframe title={src} src={src} width="100%" height="620px" allowFullScreen frameBorder="0" />
  );
};

GoogleDriveRenderer.propTypes = {
  blockProps: PropTypes.object,
};

export default GoogleDriveRenderer;
