import qs from 'query-string';
import { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router';

import { useEncodedCurrentRoute } from 'app/shared/hooks';
import { Channel } from 'features/channels/types';
import TextInput from 'inputs/components/TextInput';
import { mapRoute } from 'services/requests';
import { Text, formValidations } from 'shared';
import HelpLink from 'shared/components/HelpLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { isEmpty } from 'vendor/lodash';
import { Stack } from 'vendor/mui';

type NewLinkedContentModalProps = {
  fromChannel?: Channel;
  handleClose: MouseEventHandler;
};

const NewLinkedContentModal = (props: NewLinkedContentModalProps) => {
  const { fromChannel, handleClose } = props;

  const history = useHistory();
  const currentRoute = useEncodedCurrentRoute();

  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | undefined>('');

  const onChange = (value: string) => {
    setUrl(value);
    setError(formValidations.url(value));
  };

  const handleNext = () => {
    const queryParams: Record<string, any> = {
      isPublishing: true,
      url,
      origin: currentRoute,
    };

    if (fromChannel) {
      queryParams.channel = fromChannel.slug;
    }

    const queryString = qs.stringify(queryParams);

    history.push(`${mapRoute('linkedContentCreate')}?${queryString}`);
  };

  const isDisabled = Boolean(error) || isEmpty(url);

  return (
    <Modal minBodyHeight={200} title="New Link" handleClose={handleClose} width={500}>
      <ModalBody>
        <Stack direction="column" spacing={2} mt={0.5}>
          <Text>
            Add external third-party content.
            <HelpLink url="http://help.plusplus.app/en/articles/7984993-linked-content" />
          </Text>
          <TextInput
            label="URL"
            inputProps={{
              name: 'message',
              maxLength: 200,
              value: url,
              onChange: (event) => onChange(event.target.value),
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </Stack>
      </ModalBody>
      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton type="button" variant="text" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="button" disabled={isDisabled} onClick={handleNext}>
          Next
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default NewLinkedContentModal;
