import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import colors from 'services/colors';
import DurationAndTags from 'shared-cards/components/DurationAndTags';
import OpeningsPill from 'shared/components/OpeningsPill';
import Pill from 'shared/components/Pill';
import RestrictedIcons from 'shared/components/RestrictedIcons';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import UserSmallerPillList from 'shared/components/UserSmallerPillList';
import { useEllipsisCheck, useTooltipUID } from 'shared/hooks';
import ScheduledTrackDateRange from 'tracks/components/ScheduledTrackDateRange';
import ScheduledTrackLocation from 'tracks/components/ScheduledTrackLocation';
import { filter, isEmpty, map } from 'vendor/lodash';
import { Box, Card, CardContent, Stack, Typography, CardActionArea, CardActions } from 'vendor/mui';

const LinedComponents = styled.div`
  color: ${colors.neutral600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  gap: 4px;
  align-items: center;
  min-height: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScheduledTrackCard = ({ scheduledTrack }) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck({ multipleLines: true });
  const titleTooltip = useTooltipUID();

  const stepsTag = `${scheduledTrack.number_track_items} ${
    scheduledTrack.number_track_items > 1 ? 'steps' : 'step'
  }`;

  const getRoute = useContentTypeRoutes()[CONTENT_TYPES.scheduled_track]?.details;

  const getOwners = () => {
    const role = 'author';

    const main_role = role
      ? filter(scheduledTrack.facilitators, ['role', role])
      : scheduledTrack.facilitators;

    const co_role = role ? filter(scheduledTrack.facilitators, ['role', `co_${role}`]) : [];

    return map([...main_role, ...co_role], 'user');
  };

  const openingsProps = {
    openings: scheduledTrack.capacity - scheduledTrack.assignments_count,
    isUnlimited: scheduledTrack.capacity === 0,
  };

  return (
    <Card sx={{ height: 248, width: 312 }}>
      <CardActionArea sx={{}}>
        <Link to={getRoute(scheduledTrack)}>
          <CardContent
            sx={{ padding: '12px 12px 0', height: 204, '&:last-child': { paddingBottom: 0 } }}
          >
            <Stack sx={{ height: '100%' }} spacing={1}>
              <TitleWrapper>
                <Text
                  size="h3"
                  color={colors.neutral900}
                  ellipsisOnLine={2}
                  ref={nodeRef}
                  {...titleTooltip.targetProps}
                >
                  <RestrictedIcons
                    isPrivate={scheduledTrack.is_hidden}
                    hasGroups={!isEmpty(scheduledTrack.groups)}
                    size="25px"
                  />{' '}
                  {scheduledTrack.name}
                </Text>
                <Tooltip id={titleTooltip.uid} hide={!hasEllipsis} maxWidth={238}>
                  {scheduledTrack.name}
                </Tooltip>
              </TitleWrapper>

              <Stack spacing={0.5}>
                {scheduledTrack.location && (
                  <LinedComponents>
                    <ScheduledTrackLocation scheduledTrack={scheduledTrack} />
                  </LinedComponents>
                )}
                <LinedComponents>
                  <ScheduledTrackDateRange scheduledTrack={scheduledTrack} />
                </LinedComponents>
              </Stack>

              <Box
                height="100%"
                position="relative"
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <UserSmallerPillList users={getOwners()} maxUsers={2} labelMaxLength={70} isCard />
                <DurationAndTags
                  duration={scheduledTrack.duration}
                  emphasisTags={[stepsTag]}
                  tags={scheduledTrack.tags}
                />
              </Box>
            </Stack>
          </CardContent>
        </Link>
      </CardActionArea>

      <CardActions
        sx={{
          backgroundColor: scheduledTrack.assignment ? `${colors.success100}50` : null,
          borderTop: 1,
          borderTopColor: colors.neutral200,
        }}
      >
        <LinedComponents>
          {scheduledTrack.assignment && (
            <Pill icon="added-to-track" label="Enrolled" variant="lightGray" />
          )}
          {!scheduledTrack.assignment && <OpeningsPill {...openingsProps} />}
          <Typography
            sx={{
              fontSize: 14,
              color: scheduledTrack.assignment ? colors.action600 : colors.neutral400,
            }}
          >
            {scheduledTrack.assignments_count} enrolled
          </Typography>
        </LinedComponents>
      </CardActions>
    </Card>
  );
};

ScheduledTrackCard.propTypes = {
  scheduledTrack: PropTypes.object,
};
