import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Loading from 'shared/components/Loading';
import { isEmpty } from 'vendor/lodash';

import { ProgramContext } from './ProgramContext';
import SessionFilterHeader from './SessionFilterHeader';
import SessionsPages from './SessionsPages';

export const EmptyMessage = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  text-align: center;
  font-size: 20px;
`;

export const SessionFilterContent = (props) => {
  const {
    sessions,
    isLoading,
    program,
    hostId,
    availableSessionsCount,
    fetchMoreSessions,
    onEnrollmentDelete,
    setNumberOfColumns,
  } = props;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {(program || hostId) && (
        <SessionFilterHeader
          hostId={hostId}
          program={program}
          sessionsCount={availableSessionsCount}
        />
      )}
      {isEmpty(sessions) ? (
        <EmptyMessage>No sessions found for the selected filters.</EmptyMessage>
      ) : (
        <ProgramContext.Provider value={program}>
          <SessionsPages
            sessions={sessions}
            fetchMoreSessions={fetchMoreSessions}
            onEnrollmentDelete={onEnrollmentDelete}
            setNumberOfColumns={setNumberOfColumns}
          />
        </ProgramContext.Provider>
      )}
    </React.Fragment>
  );
};

SessionFilterContent.propTypes = {
  sessions: PropTypes.array,
  fetchMoreSessions: PropTypes.func,
  onEnrollmentDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  program: PropTypes.object,

  hostId: PropTypes.string,
  availableSessionsCount: PropTypes.number,
  setNumberOfColumns: PropTypes.func,
};

export default SessionFilterContent;
