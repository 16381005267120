import { useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import actions from 'entities/actions';
import { useArchiveUnarchive } from 'entities/utils';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import DropDownMenu from 'shared/components/DropDownMenu';
import { useEncodedCurrentRoute } from 'shared/hooks';
import {
  EDIT_PROGRAM_PERMISSION,
  CREATE_MENTORSHIP_SESSION_PERMISSION,
  CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION,
  CONTENT_ITEM_PERMISSIONS,
} from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { includes } from 'vendor/lodash';

const MentorshipProgramContextMenu = ({ content }: any) => {
  const {
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    is_archived: isArchived,
  } = content;

  const canEdit = includes(content.permissions, EDIT_PROGRAM_PERMISSION);
  const canCreateSession =
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_PERMISSION) ||
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION);
  const canManage =
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const { archiveItem, unarchiveItem } = useArchiveUnarchive(actions.mentorshipProgram.archive);

  if (!canEdit) {
    return null;
  }

  const handleDeleteClick = () => setShowDeleteModal(true);

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.mentorship_program].edit({
              public_id_and_slug: publicIdAndSlug,
            })}
            title="Edit"
            icon="pencil"
          />
          {canCreateSession && (
            <DropDownMenu.Item
              route={`${mapRoute('mentorshipSessionCreate', {
                public_id_and_slug: publicIdAndSlug,
              })}?origin=${currentRoute}`}
              title="Schedule session"
              icon="add"
            />
          )}
          {!isArchived && (
            <DropDownMenu.Item
              onClick={() => archiveItem(publicId)}
              title="Archive"
              icon="archive"
            />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={() => unarchiveItem(publicId)}
                title="Unarchive"
                icon="unarchive"
              />
              <DropDownMenu.Item
                onClick={handleDeleteClick}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            </>
          )}
          {canManage && (
            <>
              <DropDownMenu.Item
                onClick={() => setShowLogModal(true)}
                title="View Logs"
                icon="log"
              />
              <DropDownMenu.Item
                route={mapRoute('mentorshipProgramManageMentors', {
                  public_id_and_slug: publicIdAndSlug,
                })}
                title="Manage Mentors"
                icon="group"
              />
              <DropDownMenu.Item
                route={mapRoute('mentorshipProgramManageMentees', {
                  public_id_and_slug: publicIdAndSlug,
                })}
                title="Manage Mentees"
                icon="group"
              />
            </>
          )}
        </>
      </DropDownMenu>
      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default MentorshipProgramContextMenu;
