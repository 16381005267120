import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import UserProfileInitials from 'shared/components/UserAvatar/UserProfileInitials';

const ImageContent = styled.div`
  background-image: ${({ imageSrc }) => `url('${imageSrc}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: ${({ hasBorder }) => (hasBorder ? `4px solid ${colors.action600}` : 0)};
  margin: 0;
  padding: 0;
  background-color: ${colors.action700};
  color: ${colors.action700TextColor};
  height: 100%;
  width: 100%;
  ${({ imgGrayscale }) =>
    imgGrayscale &&
    `
    filter: grayscale(100%);
  `}
`;

const ProfileImage = ({ userName, imageSrc, imgGrayscale = null, size, ...rest }) => {
  if (!imageSrc) {
    return <UserProfileInitials userName={userName} size={size} {...rest} />;
  }
  return <ImageContent imageSrc={imageSrc} imgGrayscale={imgGrayscale} {...rest} />;
};

ProfileImage.propTypes = {
  userName: PropTypes.string,
  imageSrc: PropTypes.string,
  imgGrayscale: PropTypes.bool,
  size: PropTypes.string,
};

export default ProfileImage;
