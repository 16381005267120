import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';
import TrackCompletionProgressBar from 'shared/components/TrackCompletionProgressBar';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { map, size } from 'vendor/lodash';

import { ContentItemCard } from './ContentItemCard';

const ProgressBarWrapper = styled.div`
  margin-top: 8px;
`;

const TrackCard = ({ track, disableAllClicks }) => {
  return (
    <ContentItemCard
      contentItem={track}
      headerContent={
        track.number_track_items && (
          <Text>
            {track.number_track_items} {track.number_track_items === 1 ? 'step' : 'steps'}
          </Text>
        )
      }
      description={
        <>
          {size(track.track_items_names) === 0 && (
            <ContentDescription description={track.content_body} noPadding />
          )}
          {size(track.track_items_names) !== 0 && (
            <ol>
              {map(track.track_items_names, (child) => (
                <li key={child}>{child}</li>
              ))}
            </ol>
          )}
        </>
      }
      additionalFooterContent={
        track.assignment && (
          <ProgressBarWrapper>
            <TrackCompletionProgressBar
              total={track.number_completable_track_items}
              completed={track.number_completed_track_items}
              completedColor={colors.success600}
              completionStatus={track.assignment.completion_status}
            />
          </ProgressBarWrapper>
        )
      }
      disableAllClicks={disableAllClicks}
    />
  );
};

TrackCard.propTypes = {
  track: PropTypes.object,
  disableAllClicks: PropTypes.bool,
};

export { TrackCard };
