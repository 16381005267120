import { handleActions } from 'redux-actions';

import actions from 'entities/actions';
import { mergeNormalizedArray } from 'shared/services';

export const defaultState = {
  groups: {},
  events: {},
  eventTypes: {},
  enrollments: {},
  userData: {},
  users: {},
  snippets: {},
  tracks: {},
  assignments: {},
  locations: {},
  surveys: {},
  userSurveys: {},
  rooms: {},
  topics: {},
  allTopics: {},
  programs: {},
  mentorshipPrograms: {},
  sessions: {},
  attendees: {},
  segments: {},

  awsData: {},
  s3SignedUrl: {},

  // only for the /enrollments/ page, do not use
  enrollmentsEnrollmentSchema: {},
  enrollmentsEventSchema: {},
};

export default handleActions(
  {
    [actions.runCustomReducer]: (state, action) => ({
      ...action.payload(state, action),
    }),

    [actions.updateEntities]: (state, action) => ({
      ...mergeNormalizedArray(state, action.payload),
    }),
  },
  defaultState
);
