import QueryPeoplePicker from 'app/users/components/QueryPeoplePicker';
import { queries } from 'queries';
import { ContentItem } from 'shared-content-item/interfaces';
import { TableSelectionMode } from 'shared/components/constants';
import { ActionCallback } from 'shared/components/types';
import { isNil, get, size, capitalize } from 'vendor/lodash';

interface SelectParticipantModalProps {
  program: ContentItem;
  assignLabel?: string;
  handleClose: () => void;
  handleBack?: () => void;
  handleCancel?: () => void;
  actionButtonCallback: ActionCallback;
  defaultOrdering?: string;
}

const PAGE_SIZE = 5;
const ADD_CACHE_KEY = 'selected_participants_to_add';

const SelectParticipantModal = ({
  program,
  assignLabel = 'Add Mentor',
  handleClose,
  handleBack,
  handleCancel,
  actionButtonCallback,
  defaultOrdering = 'name',
}: SelectParticipantModalProps) => {
  const fetchFiltersOptions = {
    initial_filters: ['name', 'email', 'title'],
    //exclude_filters: ['eligible_for_assignment', 'eligible_for_reassignment'],
  };
  const fetchItemsOptions = {
    view_mode: 'roster',
  };
  const initialFiltersConfig = [{ name: null }, { email: null }, { title: null }];
  const assignTitle = capitalize(`${assignLabel}`);
  const query = (expression) => queries.users.list(expression);

  return (
    <QueryPeoplePicker
      dialogTitle={`${assignTitle} - ${get(program, 'name', '')}`}
      defaultOrdering={defaultOrdering}
      pageSize={PAGE_SIZE}
      query={query}
      queryOptions={fetchItemsOptions}
      fetchFiltersOptions={fetchFiltersOptions}
      initialFiltersConfig={initialFiltersConfig}
      actionButtonLabel="Next"
      handleClose={handleClose}
      handleBack={handleBack}
      handleCancel={handleCancel}
      actionButtonCallback={actionButtonCallback}
      actionButtonIsDisabled={
        ({ selectedItems, rowCount, selectAll }) =>
          isNil(rowCount) || rowCount === 0 || (size(selectedItems) === 0 && !selectAll) // No data or none selected
      }
      enableSelectAll
      selectionMode={TableSelectionMode.multiple}
      cacheKey={ADD_CACHE_KEY}
    />
  );
};

export default SelectParticipantModal;
