import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const TagIcon = ({ width, height, fill }) => {
  const d =
    'M17.593 10.493L10.05 2.96c-.268-.267-.626-.494-1.076-.68-.45-.187-.862-.28-1.235-' +
    '.28H3.35c-.365 0-.682.134-.95.4-.266.268-.4.585-.4.95v4.39c0 .372.093.783.28 1.233' +
    '.186.45.413.806.68 1.066l7.543 7.554c.26.26.577.39.95.39.365 0 .685-.13.96-.39l5.18' +
    '-5.19c.26-.261.39-.577.39-.95 0-.366-.13-.686-.39-.96zM6.331 6.33a1.301 1.301 0 0 1' +
    '-.955.395c-.373 0-.691-.131-.955-.395a1.3 1.3 0 0 1-.395-.955 1.3 1.3 0 0 1 .395-.9' +
    '55c.264-.263.582-.395.955-.395s.691.132.955.395c.263.264.395.582.395.955a1.3 1.3 0 0' +
    ' 1-.395.955z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

TagIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default TagIcon;
