import { CONTENT_TYPES, LEARNING_TYPES } from 'catalog/constants';
import { useContentFeedback, useContentPeople } from 'catalog/hooks';
import colors from 'services/colors';
import {
  BasePeopleEngagementContainer,
  BasePeopleFacilitatorContainer,
} from 'shared-cards/components/BasePeopleContainer/BasePeopleContainer';
import BaseResourceContainer from 'shared-content-item/components/ResourceContainer/BaseResourceContainer';
import { STATUS_DONE } from 'shared/constants';
import { useResource, useToggles } from 'shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from 'stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { SectionlessTrackItemSummary } from 'tracks/components/SectionlessTrackItemSummary';
import UpcomingScheduledTracks from 'tracks/components/UpcomingScheduledTracks';
import { includes, isEmpty } from 'vendor/lodash';
import { Container, Divider, Grid, Typography } from 'vendor/mui';

export const TrackDetailBodyExtraSidebar = ({ track }: { track: any }) => {
  const { organizers, maintainers } = useContentPeople(track);
  const { showResource } = useResource(track, LEARNING_TYPES.tracks);

  const facilitatorHighlightDisplayLabel = includes(
    [LEARNING_TYPES.events, LEARNING_TYPES.event_types],
    track.contentType
  )
    ? 'Presenter'
    : 'Maintainer';

  const userPillDisplayLabel = 'Organizer';

  return (
    <Grid
      sx={{
        borderLeft: '1px solid',
        borderLeftColor: colors.neutral200,
        background: colors.neutral50,
        paddingBottom: '20px',
      }}
      item
      xs={4}
    >
      {showResource && (
        <Container sx={{ paddingTop: '32px' }}>
          <BaseResourceContainer content={track} showContainer={false} />
        </Container>
      )}
      <Container sx={{ paddingTop: '32px' }}>
        {!isEmpty(maintainers) && (
          <BasePeopleFacilitatorContainer
            facilitators={maintainers}
            facilitatorHighlightDisplayLabel={facilitatorHighlightDisplayLabel}
          />
        )}

        {!isEmpty(organizers) && (
          <BasePeopleFacilitatorContainer
            facilitators={organizers}
            facilitatorCount={10}
            facilitatorHighlightDisplayLabel={userPillDisplayLabel}
          />
        )}
      </Container>
    </Grid>
  );
};

export const TrackDetailBodyExtra = (props: { track: any }) => {
  const { track } = props;

  const { toggle_scheduled_tracks: toggleScheduledTracks } = useToggles();
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    track,
    LEARNING_TYPES.tracks
  );
  const { showResource } = useResource(track, LEARNING_TYPES.tracks);
  const { showPeople, engagedPeople, engagedPeopleCount } = useContentPeople(track);

  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [showResource, showPeople],
  });

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const showSummary = track.content_type !== CONTENT_TYPES.assessment && isEmpty(track.sections);
  const showUpcomingOfferings = toggleScheduledTracks && track.content_type === CONTENT_TYPES.track;

  return (
    <Grid container>
      <Grid item xs={8} paddingBottom="20px">
        <Container>
          {showSummary && <SectionlessTrackItemSummary track={track} />}

          {showUpcomingOfferings && (
            <UpcomingScheduledTracks trackId={track.id} showContainer={false} />
          )}

          <BasePeopleEngagementContainer
            engagedPeople={engagedPeople}
            engagedPeopleCount={engagedPeopleCount}
            engagedPeopleHeading={
              <Typography>
                {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'} engaged
              </Typography>
            }
          />

          {showFeedback && !isEmpty(feedback) && (
            <>
              <Divider />
              <BaseFeedbackContainer
                content={track}
                feedbackList={feedback}
                mode={feedbackContainerMode}
                isLoading={isLoadingFeedback}
                useFlexbox
              />
            </>
          )}
        </Container>
      </Grid>
      <TrackDetailBodyExtraSidebar track={track} />
    </Grid>
  );
};
