import * as permissionConstants from 'shared/permissions';
import { get, includes } from 'vendor/lodash';

export type WidgetsPermissionsCtx = {
  canCreateAnyContent: boolean;
  user: any;
  teamSize: number;
};

export type WidgetPermissionFunc = (ctx: WidgetsPermissionsCtx) => boolean;

function canCreateContent(ctx: WidgetsPermissionsCtx) {
  return ctx.canCreateAnyContent;
}

function hasTeam(ctx: WidgetsPermissionsCtx) {
  return ctx.teamSize > 0;
}

function hasDirects(ctx: WidgetsPermissionsCtx) {
  const userHasDirects = get(ctx.user, 'has_directs', false);
  return userHasDirects;
}

function canCreateEvent(ctx: WidgetsPermissionsCtx) {
  const userPermissions = get(ctx.user, 'permissions', []);
  return includes(userPermissions, permissionConstants.CREATE_EVENT_PERMISSION);
}

export const permissions = {
  canCreateContent,
  hasTeam,
  hasDirects,
  canCreateEvent,
};
