import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Fields } from 'redux-form';

import { ACCESS_LEVELS } from 'catalog/constants';
import PeopleGroupsMultiselectField from 'inputs/components/PeopleGroupsMultiselectField';
import SelectField from 'inputs/components/SelectField';
import colors from 'services/colors';
import { FormFieldGroup } from 'shared/components/Form';
import InfoText from 'shared/components/InfoText';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { useLabels } from 'shared/hooks';
import { isEmpty, toLower, isBoolean, get, capitalize } from 'vendor/lodash';

const validateRequired = Form.validations.required();

const AccessLevelFields = (props) => {
  const [accessLevel, setAccessLevel] = useState(null);
  const { label_internal_groups: labelInternalGroups } = useLabels();

  const {
    is_hidden,
    is_private,
    groups_ids: {
      input: { value: groups, onChange: groupsOnChange },
    },
    contentNameSingular,
    isAccessLevelContentNamePlural,
    isNewForm,
  } = props;

  let chosenIsHidden;
  let chosenIsHiddenOnChange;

  if (is_hidden) {
    chosenIsHidden = get(is_hidden, 'input.value');
    chosenIsHiddenOnChange = get(is_hidden, 'input.onChange');
  } else if (is_private) {
    chosenIsHidden = get(is_private, 'input.value');
    chosenIsHiddenOnChange = get(is_private, 'input.onChange');
  }

  // Set the initial value of access_level
  // Note: If the isHidden and groups change after the mount, this effect will not be triggered.
  useEffect(() => {
    if (!is_hidden && !is_private) {
      return;
    }

    if (isBoolean(chosenIsHidden) && chosenIsHidden) {
      setAccessLevel(ACCESS_LEVELS.unlisted);
    } else if (isBoolean(chosenIsHidden) && !chosenIsHidden && isEmpty(groups)) {
      setAccessLevel(ACCESS_LEVELS.public);
    } else if (isBoolean(chosenIsHidden) && !chosenIsHidden && !isEmpty(groups)) {
      setAccessLevel(ACCESS_LEVELS.restricted);
    }
  }, []);

  useEffect(() => {
    if (!is_hidden && !is_private) {
      return;
    }

    // Send null so the form can trigger the validation
    if (accessLevel === ACCESS_LEVELS.restricted && isEmpty(groups)) {
      groupsOnChange(null);
    }
  }, [accessLevel, groups]);

  const handleAccessLevelChange = (value) => {
    setAccessLevel(value);

    switch (value) {
      case ACCESS_LEVELS.public:
        chosenIsHiddenOnChange(false);
        groupsOnChange([]);
        break;
      case ACCESS_LEVELS.unlisted:
        chosenIsHiddenOnChange(true);
        groupsOnChange([]);
        break;
      case ACCESS_LEVELS.restricted:
        chosenIsHiddenOnChange(false);
        break;
      default:
    }
  };

  // Rename 'Hidden' to 'Unlisted' when all forms are updated
  const accessLevels = [
    { value: ACCESS_LEVELS.public, label: 'Public for everyone' },
    { value: ACCESS_LEVELS.unlisted, label: 'Hidden' },
    {
      value: ACCESS_LEVELS.restricted,
      label: `Hidden and Restricted, except for the following ${toLower(labelInternalGroups)}`,
    },
  ];

  const accessLevelLabel = isNewForm
    ? 'Access Control Preference'
    : `${capitalize(contentNameSingular)} Restriction Level`;

  if (!is_hidden && !is_private) {
    return null;
  }

  const areGroupsRequired = accessLevel === ACCESS_LEVELS.restricted ? validateRequired : null;

  return (
    <>
      <FormFieldGroup>
        <SelectField
          label={accessLevelLabel}
          input={{
            name: 'access_level',
            value: accessLevel || '',
            onChange: handleAccessLevelChange,
          }}
          options={accessLevels}
        />
        {/* TODO: These info texts will probably change to accommodate other content types.  */}
        {accessLevel === ACCESS_LEVELS.public && (
          <InfoText
            top={4}
            content={
              <Text block size="h5" color={colors.neutral500}>
                This {toLower(contentNameSingular)} {isAccessLevelContentNamePlural ? 'are' : 'is'}{' '}
                discoverable by everyone, anyone can join.
              </Text>
            }
          />
        )}
        {accessLevel === ACCESS_LEVELS.unlisted && (
          <InfoText
            top={4}
            content={
              <>
                <Text block size="h5" color={colors.neutral500}>
                  • This {toLower(contentNameSingular)}{' '}
                  {isAccessLevelContentNamePlural ? 'are' : 'is'} not listed for regular users.
                </Text>
                <Text block size="h5" color={colors.neutral500}>
                  • All users can still open the direct URL and join.
                </Text>
              </>
            }
          />
        )}
        {accessLevel === ACCESS_LEVELS.restricted && (
          <InfoText
            top={4}
            content={
              <>
                <Text block size="h5" color={colors.neutral500}>
                  • This {toLower(contentNameSingular)}{' '}
                  {isAccessLevelContentNamePlural ? 'are' : 'is'} only discoverable for specified
                  groups and facilitators.
                </Text>
                <Text block size="h5" color={colors.neutral500}>
                  • All users can still open the direct URL, but only group members & facilitators
                  can join.
                </Text>
              </>
            }
          />
        )}
      </FormFieldGroup>

      {accessLevel === ACCESS_LEVELS.restricted && (
        <FormFieldGroup>
          <Fields
            label={`Selected ${toLower(labelInternalGroups)}`}
            required
            inputId="groups"
            names={['groups_ids']}
            component={PeopleGroupsMultiselectField}
            validate={areGroupsRequired}
            showGroups
          />
        </FormFieldGroup>
      )}
    </>
  );
};

AccessLevelFields.propTypes = {
  is_hidden: PropTypes.object,
  is_private: PropTypes.object,
  groups_ids: PropTypes.object,
  contentNameSingular: PropTypes.string,
  isAccessLevelContentNamePlural: PropTypes.bool,
  isNewForm: PropTypes.bool,
};

AccessLevelFields.defaultProps = {
  isNewForm: false,
  groups_ids: [],
};

export default AccessLevelFields;
