/* eslint-disable func-names */
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
(function(arr) {
  arr.forEach(function(item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        // eslint-disable-next-line unicorn/prefer-node-remove
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
