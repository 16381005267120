import PropTypes from 'prop-types';
import React from 'react';

import { SESSION_ROSTER_DEFAULT_TAB } from 'program/constants';
import { getPrintableRosterUrl } from 'program/services';
import { displayDatetime } from 'services/datetime';
import Modal, { ModalBody, ModalFooter, ModalFooterButtonLink } from 'shared/components/Modal';
import TabbedContent from 'shared/components/TabbedContent';

import SessionRosterAgenda from './SessionRosterAgenda';
import SessionRosterAttendees from './SessionRosterAttendees';
import SessionRosterEveryone from './SessionRosterEveryone';

const SessionRosterModal = ({ session, handleClose, onAddAttendeeClick, forceTab }) => {
  const title = `${session.program_name || session.name} - ${displayDatetime(
    session.starts_at_tz_aware,
    session.timezone
  )}`;

  const enrolledCount = session.enrollments ? session.enrollments.length : 0;

  const printRosterLink = getPrintableRosterUrl(session);

  const getTab = () => {
    if (forceTab) {
      return forceTab;
    }
    return SESSION_ROSTER_DEFAULT_TAB;
  };

  return (
    <Modal
      title={title}
      handleClose={handleClose}
      width={window.innerWidth > 820 ? 820 : null}
      height="640px"
    >
      <ModalBody fullSize>
        <TabbedContent
          forceSelectedTab={getTab()}
          tabs={[
            {
              text: `Enrolled (${enrolledCount})`,
              content: (
                <SessionRosterAttendees session={session} onAddAttendeeClick={onAddAttendeeClick} />
              ),
            },
            {
              text: 'Agenda',
              content: <SessionRosterAgenda session={session} />,
            },
            {
              text: 'Everyone',
              content: <SessionRosterEveryone session={session} />,
            },
          ]}
        />
      </ModalBody>
      <ModalFooter variant="links" justifyContent="spaceBetween">
        <ModalFooterButtonLink url={printRosterLink}>Print Roster</ModalFooterButtonLink>
      </ModalFooter>
    </Modal>
  );
};

SessionRosterModal.propTypes = {
  session: PropTypes.object,
  handleClose: PropTypes.func,
  onAddAttendeeClick: PropTypes.func,
  forceTab: PropTypes.number,
};

export default SessionRosterModal;
