import PropTypes from 'prop-types';
import React from 'react';

import CheckboxInput from 'inputs/components/CheckboxInput';

const CheckboxField = (props) => {
  const { meta, input, ...inputProps } = props;
  return <CheckboxInput {...input} {...inputProps} checked={input.value} />;
};

CheckboxField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default CheckboxField;
