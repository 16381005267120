import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const BlockWrapper = styled('div')`
  margin: 0 150px;
`;
export const Wrapped = (props) => {
  const { children, type } = props;

  let component = children;

  if (type === 'ordered-list-item') {
    component = <ol className="public-DraftStyleDefault-ol">{children}</ol>;
  } else if (type === 'unordered-list-item') {
    component = <ul className="public-DraftStyleDefault-ul">{children}</ul>;
  }

  return <BlockWrapper>{component}</BlockWrapper>;
};

Wrapped.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.string,
};
