import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';

import './user-add-success-modal.scss';

class UserAddSuccessModal extends React.Component {
  constructor() {
    super();
    this.onAddAnotherClick = this.onAddAnotherClick.bind(this);
  }

  onAddAnotherClick() {
    const { onAddAnotherClick } = this.props;
    onAddAnotherClick();
  }

  render() {
    const { show, handleClose } = this.props;

    if (!show) {
      return null;
    }
    return (
      <Modal
        title="Success"
        width={window.innerWidth > 768 ? 600 : 'auto'}
        handleClose={handleClose}
      >
        <ModalBody>
          <Text size="h4">User successfully added!</Text>
        </ModalBody>
        <ModalFooter variant="buttons">
          <ModalFooterButton onClick={this.onAddAnotherClick}>Add Another</ModalFooterButton>
          <ModalFooterButton onClick={handleClose} color="error">
            Close
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    );
  }
}

UserAddSuccessModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  onAddAnotherClick: PropTypes.func,
};

export default UserAddSuccessModal;
