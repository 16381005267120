import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast as toastify } from 'react-toastify';
import styled from 'styled-components';

import colors from 'services/colors';
import { generateGuid } from 'services/utils';
import Button from 'shared/components/Button';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

import 'react-toastify/dist/ReactToastify.min.css';
import './style.scss';

const notificationRoot = document.getElementById('notification-root');

const AUTO_CLOSE = 8000;

const buildNotification = (title, text, type = toastify.TYPE.INFO, options = {}) => ({
  id: generateGuid(),
  text,
  title,
  type,
  ...(options.linkUrl && { linkUrl: options.linkUrl }),
  ...(options.linkLabel && { linkLabel: options.linkLabel }),
  ...(options.buttonLabel && { buttonLabel: options.buttonLabel }),
  ...(options.buttonClickCallback && { buttonClickCallback: options.buttonClickCallback }),
  ...{
    autoClose: AUTO_CLOSE,
    position: toastify.POSITION.BOTTOM_RIGHT,
    ...options,
  },
});

const toastEmit = (notification) => {
  toastify(
    <Notification
      title={notification.title}
      text={notification.text}
      linkLabel={notification.linkLabel}
      linkUrl={notification.linkUrl}
      buttonLabel={notification.buttonLabel}
      buttonClickCallback={notification.buttonClickCallback}
    />,
    {
      toastId: notification.id,
      type: notification.type,
      autoClose: notification.autoClose,
      closeOnClick: notification.closeOnClick,
      position: notification.position,
      className: 'notification-background',
      bodyClassName: 'notification-body',
      progressClassName: 'notification-progress',
    }
  );
};

export const toast = {
  success: (title, text, options) => {
    const notification = buildNotification(title, text, toastify.TYPE.SUCCESS, options);
    toastEmit(notification);
  },
  warning: (title, text, options) => {
    const notification = buildNotification(title, text, toastify.TYPE.WARNING, options);
    toastEmit(notification);
  },
  error: (title, text, options) => {
    const notification = buildNotification(title, text, toastify.TYPE.ERROR, options);
    toastEmit(notification);
  },
};

const Title = styled(Text)`
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  text-align: left;
`;

const Message = styled(Text)`
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  text-align: left;
`;

const Link = styled.a`
  line-height: 1.43;
  letter-spacing: -0.3px;

  text-decoration: underline;
  color: white;

  &:hover,
  &:focus {
    color: ${colors.neutral200};
  }
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-top: 4px;
  }
`;

const StyledButton = styled(Button)`
  display: inline;
  color: ${colors.neutral0};
  border-color: ${colors.neutral0};

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Notification = ({ title, text, linkUrl, linkLabel, buttonLabel, buttonClickCallback }) => {
  return (
    <NotificationContent>
      <div>
        {title && (
          <Title size="h4" align="center" block medium>
            {title}
          </Title>
        )}
        <Message size="h5" align="center" block>
          {text}
        </Message>
        {linkUrl && (
          <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
            <Text size="h5">{linkLabel}</Text>
          </Link>
        )}
      </div>
      <div>
        {buttonClickCallback && (
          <StyledButton onClick={buttonClickCallback}>
            <Text size="h5">{buttonLabel}</Text>
          </StyledButton>
        )}
      </div>
    </NotificationContent>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonClickCallback: PropTypes.func,
};

Notification.defaultProps = {
  title: '',
  linkLabel: 'Link',
};

const CloseClicker = styled(Clicker)`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 10px;
`;

const CloseButton = ({ closeToast }) => (
  <CloseClicker onClick={closeToast}>
    <Icon name="close" width={10} height={10} />
  </CloseClicker>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--error {
    background-color: ${colors.error600};
  }
  .Toastify__toast--error .notification-progress {
    background: ${colors.error200};
  }
  .Toastify__toast--warning {
    background-color: ${colors.alert400};
  }
  .Toastify__toast--warning .notification-progress {
    background: ${colors.alert200};
  }
  .Toastify__toast--success {
    background-color: ${colors.success600};
  }
  .Toastify__toast--success .notification-progress {
    background: ${colors.success200};
  }
`;

const NotificationCenter = () =>
  ReactDOM.createPortal(
    <StyledToastContainer theme="colored" icon={false} closeButton={<CloseButton />} draggable={false} closeOnClick={false} />,
    notificationRoot
  );

export default NotificationCenter;
