import DashboardTopBar from 'backoffice/components/Dashboard/DashboardTopBar';
import actions from 'entities/actions';
import { Actions as AssignmentActions } from 'scenes/Dashboard/Assignments/Actions';
import Assignments from 'scenes/Dashboard/Assignments/Assignments';
import { AssignmentsProvider } from 'scenes/Dashboard/Assignments/Context';
import ManagerPortalPage from 'shared-layouts/ManagerPortalPage';

const TeamAssignments = () => {
  return (
    <AssignmentsProvider
      fetchListAction={actions.contentAssignment.teamRetrieveRql}
      fetchFiltersAction={actions.contentAssignment.teamRetrieveRqlFilters}
      breadcrumbItems={[]}
      exportCSVURL="api_content_items:team_assignments_export_rql"
      dashboardName="directs_assignments"
      enableSegments={false}
    >
      <ManagerPortalPage
        renderTopbar={
          <DashboardTopBar title="Directs assignments" subTitle="My Manager Portal">
            <AssignmentActions />
          </DashboardTopBar>
        }
      >
        <Assignments />
      </ManagerPortalPage>
    </AssignmentsProvider>
  );
};

export default TeamAssignments;
