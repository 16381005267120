import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Button from 'shared/components/Button';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import Text from 'shared/components/Text';
import { useLabels } from 'shared/hooks';
import { get, isEmpty, isNil } from 'vendor/lodash';

const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const StyledRating = styled(Rating)`
  font-size: 20px;
`;

const FeedbackRatingButtonWrapper = styled.div`
  margin-left: 12px;
`;

const RateButtonWrapper = styled.div`
  margin-top: 12px;
`;

const WaitingForFeedbackElement = () => (
  <Text medium color={colors.neutral400}>
    Waiting for Feedback
  </Text>
);

const RateSessionElement = ({ handleRateClick }) => (
  <RateButtonWrapper>
    <RateButton fullWidth onClick={handleRateClick}>
      Rate Session
    </RateButton>
  </RateButtonWrapper>
);

RateSessionElement.propTypes = {
  handleRateClick: PropTypes.func,
};

const FeedbackRatingElement = ({
  feedbackText,
  feedbackRating,
  handleOpenFeedbackModal,
  showEdit,
  showViewFeedback,
}) => (
  <FeedbackContainer>
    <Text color={colors.neutral600}>{feedbackText}</Text>
    <StyledRating value={feedbackRating} readOnly />

    {showEdit && (
      <FeedbackRatingButtonWrapper>
        <Button
          size="small"
          onClick={() => handleOpenFeedbackModal({ readOnly: false })}
          variant="text"
        >
          Edit
        </Button>
      </FeedbackRatingButtonWrapper>
    )}

    {showViewFeedback && (
      <FeedbackRatingButtonWrapper>
        <Button
          size="small"
          onClick={() => handleOpenFeedbackModal({ readOnly: true })}
          variant="text"
        >
          View
        </Button>
      </FeedbackRatingButtonWrapper>
    )}
  </FeedbackContainer>
);

FeedbackRatingElement.defaultProps = {
  showEdit: false,
};

FeedbackRatingElement.propTypes = {
  feedbackText: PropTypes.string,
  feedbackRating: PropTypes.number,

  handleOpenFeedbackModal: PropTypes.func,
  showEdit: PropTypes.bool,
  showViewFeedback: PropTypes.bool,
};

export const FeedbackArea = ({
  session,
  userIsHost,
  userIsAttendee,
  userEnrollment,
  handleOpenFeedbackModal,
}) => {
  const {
    label_mentorship_sessions_creator_title_single: singleMentorLabel,
    label_mentorship_sessions_attendee_title_single: singleMenteeLabel,
  } = useLabels();

  if (!isEmpty(session.feedback_url) || isEmpty(session.enrollments)) {
    return null;
  }

  const isMentorshipSession = isNil(get(session, 'program_id'));
  const isProgramSession = !isNil(get(session, 'program_id'));
  const isAwaitingForMentorFeedback =
    get(session, 'host_awaiting_feedback') || get(session, 'host_feedback_rating') === 0;
  const isAwaitingForAttendeeFeedback =
    get(userEnrollment, 'awaiting_feedback') || get(userEnrollment, 'feedback_rating') === 0;
  const avgAttendeesFeedbackRating = get(session, 'avg_feedback_rating', 0);
  const mentorFeedbackRating = get(session, 'host_feedback_rating');
  const menteeFeedbackRating = get(session, 'enrollments.0.feedback_rating', 0);

  // Attendee/Mentee
  if (userIsAttendee && isAwaitingForAttendeeFeedback)
    return (
      <RateSessionElement handleRateClick={() => handleOpenFeedbackModal({ readOnly: false })} />
    );

  if (userIsAttendee && !isAwaitingForAttendeeFeedback) {
    const attendeeFeedbackRating = get(userEnrollment, 'feedback_rating');

    return (
      <>
        {isMentorshipSession && !isAwaitingForMentorFeedback && (
          <FeedbackRatingElement
            showViewFeedback
            feedbackText={`${singleMentorLabel} rating: `}
            feedbackRating={mentorFeedbackRating}
            handleOpenFeedbackModal={handleOpenFeedbackModal}
          />
        )}

        <FeedbackRatingElement
          showEdit
          feedbackText="Your rating: "
          feedbackRating={attendeeFeedbackRating}
          handleOpenFeedbackModal={handleOpenFeedbackModal}
        />
      </>
    );
  }

  // Mentor
  if (isMentorshipSession && userIsHost && isAwaitingForMentorFeedback) {
    return (
      <>
        {menteeFeedbackRating > 0 && (
          <FeedbackRatingElement
            showViewFeedback
            feedbackText={`${singleMenteeLabel} rating: `}
            feedbackRating={menteeFeedbackRating}
            handleOpenFeedbackModal={handleOpenFeedbackModal}
          />
        )}
        <RateSessionElement handleRateClick={() => handleOpenFeedbackModal({ readOnly: false })} />
      </>
    );
  }

  if (isMentorshipSession && userIsHost && !isAwaitingForMentorFeedback)
    return (
      <>
        {menteeFeedbackRating > 0 && (
          <FeedbackRatingElement
            showViewFeedback
            feedbackText={`${singleMenteeLabel} rating: `}
            feedbackRating={menteeFeedbackRating}
            handleOpenFeedbackModal={handleOpenFeedbackModal}
          />
        )}

        <FeedbackRatingElement
          showEdit
          feedbackText="Your rating: "
          feedbackRating={mentorFeedbackRating}
          handleOpenFeedbackModal={handleOpenFeedbackModal}
        />
      </>
    );

  // Host
  if (isProgramSession && userIsHost && avgAttendeesFeedbackRating === 0)
    return <WaitingForFeedbackElement />;

  if (isProgramSession && userIsHost && avgAttendeesFeedbackRating > 0)
    return (
      <FeedbackRatingElement
        showViewFeedback
        feedbackText="Rating: "
        feedbackRating={avgAttendeesFeedbackRating}
        handleOpenFeedbackModal={handleOpenFeedbackModal}
      />
    );

  return null;
};

FeedbackArea.propTypes = {
  session: PropTypes.object,
  userIsHost: PropTypes.bool,
  userIsAttendee: PropTypes.bool,
  userEnrollment: PropTypes.object,
  handleOpenFeedbackModal: PropTypes.func,
};

export default FeedbackArea;
