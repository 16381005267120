import type { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { getTagType } from 'app/topics/services';
import { MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE } from 'mentorship/constants';
import colors from 'services/colors';
import ConditionalWrapper from 'shared/components/ConditionalWrapper';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import { User } from 'shared/components/types';
import ProfileImage from 'shared/components/UserAvatar/ProfileImage';
import { useCurrentUser } from 'shared/hooks';
import { map, filter, includes, isEmpty } from 'vendor/lodash';
import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Card,
  CardContent,
  Stack,
  StackProps,
  styled,
  grey,
} from 'vendor/mui';

import AvailableSessionsText from './AvailableSessionsText';

const UserImageWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: theme.spacing(9),
  width: theme.spacing(9),
  position: 'absolute',
  left: 12,
  top: -28,
}));

const BadgeAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  top: 20,
  left: 60,
  '&.MuiAvatar-root': {
    position: 'absolute',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const OptionalCardHeader = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  backgroundColor: grey[50],
  borderTopLeftRadius: theme.spacing(0.5),
  borderTopRightRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5),
}));

const UserName = styled(Box)<BoxProps>(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const TagList = styled(Stack)<StackProps>(() => ({
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 90%, white)',
    pointerEvents: 'none',
  },
}));

interface BasePersonCardProps {
  availableSessionsCount?: number;
  badge?: string;
  disableAllClicks?: boolean;
  isMentor?: boolean;
  nextSession?: string;
  route?: string;
  user: User;
  cardProps: ComponentProps<typeof Card>;
  cardContentProps: ComponentProps<typeof CardContent>;
  q?: string;
}

const BasePersonCard = ({
  availableSessionsCount,
  badge,
  disableAllClicks,
  isMentor,
  nextSession,
  route,
  user,
  cardProps,
  cardContentProps,
  q,
}: BasePersonCardProps) => {
  const currentUser = useCurrentUser();

  const tagsWithoutMentorshipPreferences = filter(
    user?.skills,
    (tag) =>
      !includes(
        [MENTOR_PREFERENCE_TAG_TYPE, MENTEE_PREFERENCE_TAG_TYPE],
        getTagType(currentUser, tag)
      )
  );

  const displayName = user?.name || user?.email;

  return (
    <Card sx={{ overflow: 'visible', position: 'relative', mt: 3, width: '312px', ...cardProps }}>
      {isMentor && (
        <OptionalCardHeader>
          <AvailableSessionsText
            availableSessionsCount={availableSessionsCount}
            nextSession={nextSession}
          />
        </OptionalCardHeader>
      )}
      <ConditionalWrapper
        condition={!disableAllClicks && !isEmpty(route)}
        wrapper={(children) => <Link to={{ pathname: route, search: q }}>{children}</Link>}
      >
        <UserImageWrapper>
          <ProfileImage imageSrc={user?.profile_image} userName={displayName} size="large" />
        </UserImageWrapper>
        {badge && <BadgeAvatar src={badge} alt="User badge" />}
      </ConditionalWrapper>

      <CardContent sx={{ pt: 6.5, height: '186px', ...cardContentProps }}>
        <Stack direction="column" mb={1}>
          <UserName>
            <ConditionalWrapper
              condition={!disableAllClicks && !isEmpty(route)}
              wrapper={(children) => <Link to={{ pathname: route, search: q }}>{children}</Link>}
            >
              <Text size="h3" medium color={colors.neutral900} ellipsisOnOverflow lineHeight={20}>
                {displayName}
              </Text>
            </ConditionalWrapper>
          </UserName>

          <Text size="h5" medium color={colors.neutral600} ellipsisOnOverflow lineHeight={20}>
            {user?.title}
          </Text>
          <Text size="h5" color={colors.neutral600} ellipsisOnOverflow lineHeight={20}>
            {user?.department}
          </Text>
          <Text size="h5" color={colors.neutral600} ellipsisOnOverflow lineHeight={20}>
            {user?.location_text || user?.location?.name}
          </Text>
        </Stack>

        <TagList direction="row" spacing={0.5}>
          {map(tagsWithoutMentorshipPreferences, (tag) => (
            <Pill key={tag} size="small" label={tag} />
          ))}
        </TagList>
      </CardContent>
    </Card>
  );
};

export default BasePersonCard;
