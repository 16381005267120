import React from 'react';

import ModalManager from 'shared/components/ModalManager';

import UserAddModal from './UserAddModal';
import UserAddSuccessModal from './UserAddSuccessModal';

class UserAddFlow extends React.Component {
  constructor() {
    super();
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
    this.handleAddAnother = this.handleAddAnother.bind(this);
  }

  startFlow() {
    this.userAddModalManager.show();
  }

  handleSubmitSuccess() {
    this.userAddModalManager.hide();
    this.successModalManager.show();
  }

  handleAddAnother() {
    this.successModalManager.hide();
    this.userAddModalManager.show();
  }

  render() {
    return (
      <>
        <ModalManager
          ref={(m) => {
            this.userAddModalManager = m;
          }}
        >
          <UserAddModal onSubmitSuccess={this.handleSubmitSuccess} />
        </ModalManager>
        <ModalManager
          ref={(m) => {
            this.successModalManager = m;
          }}
        >
          <UserAddSuccessModal onAddAnotherClick={this.handleAddAnother} />
        </ModalManager>
      </>
    );
  }
}

export default UserAddFlow;
