import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import TextField from 'inputs/components/TextField';
import InfoBox from 'shared/components/InfoBox';
import { url } from 'shared/formValidations';

const SurveyLinkWrapper = styled.div`
  margin-bottom: 20px;
`;

const NewExternalSurveyField = () => {
  return (
    <>
      <SurveyLinkWrapper>
        <Field
          label="Third-Party Survey"
          name="external_survey_link"
          component={TextField}
          placeholder="https://"
          validate={[url]}
        />
      </SurveyLinkWrapper>
      <InfoBox
        content={`This URL will show up in the body of emails sent to attendees
            after the event is finished. Collecting and analysing responses will
            only be possible through the selected third party service.`}
      />
    </>
  );
};

export default NewExternalSurveyField;
