import * as React from 'react';

import RQLFilterBar from 'app/filters/components/RQLFilterBar/RQLFilterBar';
import { useNewRqlFilters } from 'app/filters/hooks';
import { PillsDefinitionProps } from 'app/filters/types';
import { useOrderingLabel } from 'app/shared/hooks';
import ViewAllContent from 'catalog/components/ViewAllContent';
import { useFetchDataListPaginated } from 'data-fetching/hooks';
import { EVENT_VIEW_MODES } from 'event/constants';
import EventViewDensity from 'events/components/EventViewDensity';
import { useEventsFiltersDefinition, useEventsPillsDefinition } from 'events/hooks';
import {
  convertPeriodFilterToRQLFactory,
  joinMultipleDatesToRangeFactory,
  translatorFactory,
} from 'feature-parity/utils';
import { cleanRQLExpression } from 'filters/utils';
import FilterPills from 'navigation/components/FilterPills';
import { queries } from 'queries';
import { lazyWithRetry } from 'services/utils';
import CardList from 'shared-cards/components/CardList';
import { OptimalContentItemCard } from 'shared/components/Card';
import LazyErrorBoundary from 'shared/components/LazyErrorBoundary';
import Loading from 'shared/components/Loading';
import { get, isEmpty, omit } from 'vendor/lodash';
import { Box, grey, Typography } from 'vendor/mui';
import rql from 'vendor/rql';

import { Channel } from '../../types';

const LazyCalendar = lazyWithRetry(
  () => import(/* webpackChunkName: "Calendar" */ 'event-list/components/Calendar')
);

const filtersTranslator = translatorFactory({
  translationMap: {
    category: 'main_topics',
    tag: 'topics',
    event_type: 'event_types',
  },
  defaultOrdering: 'start_time',
  processingMap: {
    period: convertPeriodFilterToRQLFactory('start_time'),
    starts_after: (value) => ({ start_time: { $gt: value as string } }),
    starts_before: (value) => ({ start_time: { $lt: value as string } }),
  },
  postProcessing: joinMultipleDatesToRangeFactory('start_time'),
});

function usePillsDefinition(options: PillsDefinitionProps) {
  const { filters } = options;

  const channelPillsDef = { pills: [] };

  const cleanedFilters = omit(filters, ['tab']);
  const eventsPillsDef = useEventsPillsDefinition({ filters: cleanedFilters });

  return {
    pills: [...channelPillsDef.pills, ...eventsPillsDef.pills],
  };
}

const PAGE_SIZE = 24;
const CALENDAR_PAGE_SIZE = 30;

type ChannelHomeEventsProps = {
  channel: Channel;
};

export function ChannelHomeEvents(props: ChannelHomeEventsProps) {
  const { channel } = props;

  const { filters, rqlExpression, ordering, updateFilter, setOrdering, resetFilters, removeValue } =
    useNewRqlFilters({
      initialFiltersState: {
        start_time: { $gt: '-PT0H' },
        status: { $eq: 'published' },
        view_density: { $eq: 'cozy' },
      },
      initialOrdering: 'start_time',
      translator: filtersTranslator,
    });

  const viewDensity = get(filters, 'view_density.$eq', 'cozy') as string;

  const filtersDef = useEventsFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
    fixedFilters: ['My Events'],
  });

  const pillsDef = usePillsDefinition({ filters });

  const pageSize = viewDensity === 'cozy' ? PAGE_SIZE : CALENDAR_PAGE_SIZE;
  const viewMode = viewDensity === 'cozy' ? EVENT_VIEW_MODES.listing : EVENT_VIEW_MODES.calendar;

  const cleanedRQLExpression = cleanRQLExpression(rqlExpression, ['view_density', 'tab']);
  const searchRQLExpression = rql({
    channel: channel.id,
    page_size: pageSize,
    view_mode: viewMode,
  });
  const searchQuery = `${cleanedRQLExpression}&${searchRQLExpression}`;

  const {
    data: events,
    fetchMore: loadMoreEvents,
    status: fetchStatus,
    count,
    hasNextPage,
    nextPage,
    isLoading,
  } = useFetchDataListPaginated({
    ...queries.events.list(searchQuery),
  });

  const handleRemoveFilter = (filter: any) => {
    const filterName = get(filter, 'filterName', '');

    if (filterName === 'tab') {
      resetFilters(true);
      window.location.hash = '';
      return;
    }

    removeValue(filterName, get(filter, 'value', ''));
  };

  const orderingLabel = useOrderingLabel(ordering);

  return (
    <Box pt={2}>
      <Typography variant="h5" sx={{ color: grey['900'] }} fontWeight={500}>
        Events
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <RQLFilterBar
          filters={filtersDef.filters}
          moreFilters={filtersDef.moreFilters}
          onClearAll={resetFilters}
        >
          <EventViewDensity
            key="view-density"
            value={viewDensity}
            onChange={(newViewDensity) => updateFilter({ view_density: { $eq: newViewDensity } })}
          />
        </RQLFilterBar>

        <Box pb={isEmpty(pillsDef.pills) ? 1 : 0}>
          <FilterPills pills={pillsDef.pills} onRemove={handleRemoveFilter} />
        </Box>
      </Box>

      {isLoading && <Loading />}

      {!isLoading && count === 0 && viewDensity === 'cozy' && <Box>No results found</Box>}

      {viewDensity === 'cozy' && (
        <ViewAllContent
          fetchStatus={fetchStatus}
          fetchNextPage={loadMoreEvents}
          hasNextPage={hasNextPage}
          totalResults={count}
          tooltipMessage={orderingLabel}
        >
          <CardList
            items={events}
            renderItem={(item) => (
              <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
            )}
          />
        </ViewAllContent>
      )}

      {viewDensity === 'calendar' && (
        <Box
          sx={{
            padding: '20px',
            backgroundColor: 'white',
            height: '750px',
          }}
        >
          <LazyErrorBoundary>
            <React.Suspense fallback={<Loading />}>
              <LazyCalendar
                onFilterChange={({ starts_after, starts_before }) =>
                  updateFilter({
                    start_time: {
                      $range: { min: get(starts_after, '0'), max: get(starts_before, '0') },
                    },
                  })
                }
                eventList={events}
                eventListStatus={fetchStatus}
                eventListMoreLink={nextPage}
                fetchNextPage={loadMoreEvents}
              />
            </React.Suspense>
          </LazyErrorBoundary>
        </Box>
      )}
    </Box>
  );
}
