import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import SessionBulkCopyModal from 'program-shared/components/SessionBulkDuplicateModal';
import SessionForm from 'program/components/SessionForm';
import { onSessionFormSubmitSuccess } from 'program/components/SessionForm/SessionFormHelpers';
import {
  ATTENDANCE_TYPE_MULTIPLE_ATTENDEES,
  ATTENDANCE_TYPE_SINGLE_ATTENDEE,
} from 'program/constants';
import { mapRoute } from 'services/requests';
import { getNextHalfHour, isGoogleMeetLink } from 'services/utils';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { NAIVE_DATETIME_FORMAT } from 'shared/constants';
import { useCurrentUser, useLabels, useQueryParams, useOriginRoute } from 'shared/hooks';
import { CREATE_SESSION_FOR_OTHERS_PERMISSION } from 'shared/permissions';
import { isEmpty, omit, includes } from 'vendor/lodash';

const getDefaultInitialValues = (currentUser) => {
  const canCreateForOthers = includes(
    currentUser.permissions,
    CREATE_SESSION_FOR_OTHERS_PERMISSION
  );

  return {
    starts_at: getNextHalfHour().format(NAIVE_DATETIME_FORMAT),
    duration: '01:00:00',
    allows_local: false,
    allows_online: true,
    room: '',
    meeting_url: '',
    google_calendar_resource_id: '',
    google_calendar_resource_email: '',
    google_calendar_calendar_owner: currentUser,
    extra_info: '',
    online_extra_info: '',
    attendee_id: null,
    attendance_limit_multiple: '',
    attendance_type: ATTENDANCE_TYPE_SINGLE_ATTENDEE,
    host_id: canCreateForOthers ? null : currentUser.id,
    location_id: canCreateForOthers ? null : currentUser.location_id,
  };
};

const getPreparedInitialValues = (initialValues) => {
  const preparedValues = {
    ...initialValues,
    attendance_limit_multiple:
      initialValues.attendance_limit > 1 ? initialValues.attendance_limit : '',
    attendance_type:
      initialValues.attendance_limit === 1
        ? ATTENDANCE_TYPE_SINGLE_ATTENDEE
        : ATTENDANCE_TYPE_MULTIPLE_ATTENDEES,
    attendance_multiple_ids: initialValues.attendees_ids,
  };

  if (!isEmpty(initialValues.attendees_ids) && initialValues.attendance_limit === 1) {
    [preparedValues.attendee_id] = initialValues.attendees_ids;
  }

  return preparedValues;
};

const getInitialValues = ({ currentUser, session, duplicatedSession, lastSession, programId }) => {
  let initialValues = session;
  let lastSessionFromCurrentProgram = null;
  if (lastSession && (!programId || lastSession.program_id === programId)) {
    lastSessionFromCurrentProgram = lastSession;
  }
  const sessionCopy = duplicatedSession ?? lastSessionFromCurrentProgram;

  if (!isEmpty(sessionCopy)) {
    initialValues = {
      ...omit(sessionCopy, ['id', 'public_id']),
      meeting_url: isGoogleMeetLink(sessionCopy.meeting_url) ? '' : sessionCopy.meeting_url,
    };
  }

  if (isEmpty(initialValues)) {
    initialValues = getDefaultInitialValues(currentUser);
  } else {
    initialValues = getPreparedInitialValues(initialValues);
  }

  if (programId) {
    initialValues = {
      ...initialValues,
      program_id: programId,
    };
  }

  return initialValues;
};

const SessionProgramFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
}) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { label_program: labelProgram } = useLabels();
  const { program_id: programId } = useQueryParams();

  const { session, duplicatedSession, lastSession } = initialValuesParams;

  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({
      currentUser,
      session,
      duplicatedSession,
      lastSession,
      programId,
    })
  );

  const [sessionToBulkDuplicate, setSessionToBulkDuplicate] = useState({});

  const isEdit = Boolean(session);

  const [isDuplicating, setIsDuplicating] = useState(Boolean(duplicatedSession));
  const form = `newSessionProgram${isEdit ? 'Edit' : 'Create'}Form`;

  const showPrefillBox = Boolean(duplicatedSession) || Boolean(lastSession);
  const [prefilledValuesCleaned, setPrefilledValuesCleaned] = useState(false);

  const cleanPrefilledValues = () => {
    setInitialValues(() =>
      getInitialValues({
        currentUser,
      })
    );
    setPrefilledValuesCleaned(true);
    setIsDuplicating(false);
  };

  const undoCleanPrefilledValues = () => {
    // We're only reloading the page for now because our BaseAsyncSelectField needs to be refactored.
    window.location.reload(true);
  };

  const originRoute = useOriginRoute();

  return (
    <>
      <PageTitle title={pageTitle} />
      <SessionForm
        form={form}
        contentName={`${labelProgram} Session`}
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        isDuplicating={isDuplicating}
        showPrefillBox={showPrefillBox}
        prefilledValuesCleaned={prefilledValuesCleaned}
        cleanPrefilledValues={cleanPrefilledValues}
        undoCleanPrefilledValues={undoCleanPrefilledValues}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result, saveAndDuplicate, saveAndBulkDuplicate) => {
          const { public_id: publicId } = result;
          const saveAndDuplicateRoute = `${mapRoute(
            'sessionCreate'
          )}?cloned=${publicId}&from_previous=true`;

          onSessionFormSubmitSuccess(
            result,
            saveAndDuplicate,
            saveAndBulkDuplicate,
            setSessionToBulkDuplicate,
            history,
            isEdit,
            backRoute,
            saveAndDuplicateRoute
          );
        }}
      />
      {!isEmpty(sessionToBulkDuplicate) && (
        <SessionBulkCopyModal
          session={sessionToBulkDuplicate}
          handleClose={() => {
            setSessionToBulkDuplicate({});
            history.push(mapRoute('sessionEdit', { public_id: sessionToBulkDuplicate.public_id }));
          }}
          handleConfirm={() => {
            setSessionToBulkDuplicate({});
            history.push(
              originRoute ||
                mapRoute('programDetails', {
                  public_id_and_slug: sessionToBulkDuplicate.program_public_id_and_slug,
                })
            );
          }}
        />
      )}
    </>
  );
};

export default SessionProgramFormPage;

SessionProgramFormPage.defaultProps = {
  initialValuesParams: {},
};

SessionProgramFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
