import { Box } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import RelaunchCourseModal from 'course/components/RelaunchCourseModal';
import { COURSE_STATUS_DRAFT } from 'course/constants';
import { Course } from 'course/interfaces';
import { launchCourse } from 'course/services';
import { useAssignmentActionsCtx } from 'features/contentitems/contexts/AssignmentActionsCtx';
import { useIsRenderedWithinTrack } from 'features/tracks/hooks/useIsRenderedWithinTrack';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import { useToggles } from 'shared/hooks';
import { get, isEmpty, isNil } from 'vendor/lodash';
import { OpenInNewOutlinedIcon, VisibilityIcon } from 'vendor/mui-icons';

import OldPrimaryActionButton from '../OldPrimaryActionButton';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const DraftLink = styled(Link)`
  width: 100%;
`;

interface PrimaryActionButtonProps {
  content: Course;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const { trackActivity } = useMetrics();
  const courseLabel = useLearningTypeLabels()[LEARNING_TYPES.courses];
  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();

  const [isCourseLaunchLoading, setIsCourseLaunchLoading] = useState(false);
  const [showRelaunchCourseModal, setShowRelaunchCourseModal] = useState(false);

  const { isLoading } = useAssignmentActionsCtx();
  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const isUnpublished = content.status === COURSE_STATUS_DRAFT;

  const assignment = content.user_assignment ?? content.assignment;
  const assignmentState = get(assignment, 'state');
  const isCompleted = assignmentState === ASSIGNMENT_STATES.completed;

  const displayAsPagePrimaryAction = !isRenderedWithinTrack || !isCompleted;

  if (!devToggleContentAssignment2) {
    return (
      <OldPrimaryActionButton content={content} isRenderedWithinTrack={isRenderedWithinTrack} />
    );
  }

  const handlePreviewCourseClick = async () => {
    await launchCourse({
      courseId: content.public_id,
      showPreview: true,
      isLaunchedInsideTrack: isRenderedWithinTrack,
    });
  };

  const handleCourseLaunch = async () => {
    trackActivity(METRICS_ACTIVITIES.COURSE_LAUNCH, {
      courseId: content.id,
      firstLaunch: isNil(content?.assignment?.latest_sync),
    });

    setIsCourseLaunchLoading(true);

    await launchCourse({
      courseId: content.public_id,
      isLaunchedInsideTrack: isRenderedWithinTrack,
    });

    setIsCourseLaunchLoading(false);
  };

  if (isUnpublished) {
    return (
      <Container>
        <DraftLink to={mapRoute('courseEdit', { public_id_and_slug: content.public_id_and_slug })}>
          <Button>Edit and Publish</Button>
        </DraftLink>
      </Container>
    );
  }

  if (isCompleted) {
    return (
      <>
        <Box display="flex" alignItems="center" gap="12px">
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handlePreviewCourseClick}
            startIcon={<VisibilityIcon />}
          >
            Review
          </Button>
          <Button
            variant={displayAsPagePrimaryAction ? 'contained' : 'outlined'}
            onClick={() => setShowRelaunchCourseModal(true)}
            disabled={isLoading}
            startIcon={<OpenInNewOutlinedIcon />}
          >
            Relaunch
          </Button>
        </Box>

        {showRelaunchCourseModal && (
          <RelaunchCourseModal
            title={content.name}
            handleRelaunch={handleCourseLaunch}
            isCourseLaunchLoading={isCourseLaunchLoading}
            handleClose={() => setShowRelaunchCourseModal(false)}
          />
        )}
      </>
    );
  }

  const isLaunchButtonDisabled =
    isCourseLaunchLoading || isLoading || isEmpty(content?.scorm_cloud_id);

  return (
    <Container>
      <Button
        variant="contained"
        disabled={isLaunchButtonDisabled}
        startIcon={<OpenInNewOutlinedIcon />}
        fullWidth
        onClick={handleCourseLaunch}
      >
        {isCourseLaunchLoading
          ? 'Launching...'
          : assignmentState === ASSIGNMENT_STATES.in_progress
          ? `Continue ${courseLabel}`
          : `Launch ${courseLabel}`}
      </Button>
    </Container>
  );
};

export default PrimaryActionButton;
