import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import EventCard from 'event-list/components/EventCard';
import NewEventModal from 'event-shared/components/NewEventModal';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
} from 'event-shared/constants';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import HR from 'shared/components/HR';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import MediaPoint from 'shared/components/MediaPoint';
import Text from 'shared/components/Text';
import { STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import { useCurrentUser, useLabels } from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { map, includes } from 'vendor/lodash';

const EventsGroupContainer = styled.div`
  margin: 0 auto;
  width: 350px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }
`;

const EventsGroupTopBlock = styled.div`
  padding: 0 12px;
  width: 335px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }
`;

const EventsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0;
  width: 350px;

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopSm} {
  }

  ${MediaPoint.DesktopLg} {
    width: 1015px;
  }

  &:after {
    content: '';
    flex: 0 0 360px; /* 336px width + margins */
  }
`;

const EventContainer = styled.div`
  margin: 10px 12px;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  max-width: 312px;

  ${MediaPoint.MobileLg} {
    margin: 10px auto;
  }

  ${MediaPoint.Tablet} {
    margin: 10px 12px;
  }
`;

const PageTitle = styled.span`
  color: ${colors.neutral600};
  font-size: 24px;
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  width: 300px;

  ${MediaPoint.MobileLg} {
    width: 300px;
  }

  ${MediaPoint.Tablet} {
    width: 690px;
  }

  ${MediaPoint.DesktopLg} {
    width: 1000px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EventsSection = (props) => {
  const {
    events,
    requestStatus,
    totalCount,
    headerText,
    singleCreatorTitle,
    multipleCreatorsTitle,
  } = props;

  return (
    <EventsGroupContainer>
      <EventsGroupTopBlock>
        <Text size="h4" block color={colors.neutral600}>
          {totalCount !== 0 ? totalCount : 'No'}
          {` ${headerText}`}
          {totalCount !== 1 && totalCount !== 0 && 's'}
        </Text>
        <HR height={2} color={colors.neutral200} />
      </EventsGroupTopBlock>
      {requestStatus === STATUS_LOADING ? (
        <Loading />
      ) : (
        <EventsCardsContainer>
          {map(events, (event) => (
            <EventContainer key={event.id}>
              <EventCard
                event={event}
                singleCreatorTitle={singleCreatorTitle}
                multipleCreatorsTitle={multipleCreatorsTitle}
              />
            </EventContainer>
          ))}
        </EventsCardsContainer>
      )}
      {requestStatus === STATUS_LOADING_MORE && <Loading />}
    </EventsGroupContainer>
  );
};

EventsSection.propTypes = {
  events: PropTypes.array,
  totalCount: PropTypes.number,
  requestStatus: PropTypes.string,
  headerText: PropTypes.string,
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
};

const MyEvents = () => {
  const currentUser = useCurrentUser();
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  const [fetchUpcomingEvents, upcomingData] = useEntities(actions.event.retrieveList, null, {
    schema: [eventSchema],
  });
  const [fetchPastEvents, pastData] = useEntities(actions.event.retrieveList, null, {
    schema: [eventSchema],
  });
  const [fetchAwaitingFeedbackEvents, awaitingFeedbackData] = useEntities(
    actions.event.retrieveList,
    null,
    {
      schema: [eventSchema],
    }
  );

  useEffect(() => {
    fetchUpcomingEvents(
      {
        period: 'upcoming',
        engaged_users: currentUser.id,
        engagement_type: [
          ENROLLMENT_STATUS_GOING,
          ENROLLMENT_STATUS_GOING_ONLINE,
          ENROLLMENT_STATUS_WAIT_LIST,
          ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
          'facilitating',
        ],
        is_archived: false,
        page_size: 1000, // use max page size, after all we're using fetchAll: true
      },
      { fetchAll: true }
    );
    fetchPastEvents(
      {
        period: 'previous',
        engaged_users: currentUser.id,
        engagement_type: [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE, 'facilitating'],
        is_awaiting_feedback: false,
        is_archived: false,
        page_size: 1000,
      },
      { fetchAll: true } // use max page size, after all we're using fetchAll: true
    );
    fetchAwaitingFeedbackEvents(
      {
        period: 'previous',
        engaged_users: currentUser.id,
        engagement_type: [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE],
        is_awaiting_feedback: true,
        is_archived: false,
        page_size: 1000,
      },
      { fetchAll: true } // use max page size, after all we're using fetchAll: true
    );
  }, []);

  const {
    label_events_single_creator_title: singleCreatorTitle,
    label_events_multiple_creators_title: multipleCreatorsTitle,
  } = useLabels();

  return (
    <div>
      <HeaderArea>
        <FirstRow>
          <PageTitle>My Events</PageTitle>
          {includes(currentUser.permissions, permissionConstants.CREATE_EVENT_PERMISSION) && (
            <div>
              <Button
                size="small"
                onClick={() => setShowNewEventModal(true)}
                startIcon={<Icon name="plus" />}
              >
                Schedule an Event
              </Button>
            </div>
          )}
        </FirstRow>
      </HeaderArea>
      <EventsSection
        headerText="upcoming event"
        events={upcomingData.data}
        requestStatus={upcomingData.status}
        totalCount={upcomingData.count}
        singleCreatorTitle={singleCreatorTitle}
        multipleCreatorsTitle={multipleCreatorsTitle}
      />
      <EventsSection
        headerText="awaiting feedback"
        events={awaitingFeedbackData.data}
        requestStatus={awaitingFeedbackData.status}
        totalCount={awaitingFeedbackData.count}
        singleCreatorTitle={singleCreatorTitle}
        multipleCreatorsTitle={multipleCreatorsTitle}
      />
      <EventsSection
        headerText="past event"
        events={pastData.data}
        requestStatus={pastData.status}
        totalCount={pastData.count}
        singleCreatorTitle={singleCreatorTitle}
        multipleCreatorsTitle={multipleCreatorsTitle}
      />
      {showNewEventModal && <NewEventModal handleClose={() => setShowNewEventModal(false)} />}
    </div>
  );
};

export default MyEvents;
