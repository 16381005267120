import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const DeleteIcon = ({ width, height, fill }) => {
  const d =
    'M8.5 7.344v8.437c0 .26-.21.469-.469.469h-.937a.469.469 ' +
    '0 0 1-.469-.469V7.344c0-.26.21-.469.469-.469h.937c.26 ' +
    '0 .469.21.469.469zm3.906-.469h-.937a.469.469 0 0 0-.469.469v8.437c0 ' +
    '.26.21.469.469.469h.937c.26 0 .469-.21.469-.469V7.344a.469.469 0 0 ' +
    '0-.469-.469zm5.156-3.75c.518 0 .938.42.938.938v.468c0 ' +
    '.26-.21.469-.469.469h-.781v13.125c0 1.036-.84 1.875-1.875 1.875H4.125a1.875 1.875 ' +
    '0 0 1-1.875-1.875V5h-.781A.469.469 0 0 1 1 ' +
    '4.531v-.468c0-.518.42-.938.938-.938h2.906L6.173.91C6.512.346 7.123 0 ' +
    '7.781 0h3.938c.659 0 1.27.346 1.608.91l1.329 2.215h2.906zm-10.531 ' +
    '0h5.438l-.681-1.136a.234.234 0 0 0-.201-.114H7.914a.234.234 0 0 0-.201.114L7.03 ' +
    '3.125zM15.375 5H4.125v12.89c0 .13.105.235.234.235h10.782c.13 0 .234-.105.234-.234V5z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

DeleteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default DeleteIcon;
