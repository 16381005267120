import { useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { EmptyWidget } from 'common/components/EmptyWidget';
import { useContentCreationPermissions } from 'common/hooks/useContentCreationPermissions';
import { buildWidgetsGrid } from 'common/utils/buildWidgetsGrid';
import { WidgetsPermissionsCtx } from 'common/utils/widgetPermissions';
import { CARD_LIST_WIDTH_THRESHOLDS } from 'content-items/components/CardListWrapper/constants';
import { useUsersWidgetQuery } from 'features/users/hooks/useUsersWidgetQuery';
import { useCurrentUser } from 'shared/hooks';
import { filter, get, map, every } from 'vendor/lodash';
import { Box, Stack } from 'vendor/mui';

import { allMyHubWidgets } from '../utils/myHubWidgets';

function getMaxWidgetsPerRow(width: number) {
  const { sm } = CARD_LIST_WIDTH_THRESHOLDS;

  if (width <= sm) return 1;

  return 3;
}

export const MyHubWidgets = () => {
  const user = useCurrentUser();
  const { canCreateAny } = useContentCreationPermissions();

  const { ref, width } = useResizeDetector();
  const maxPerRow = getMaxWidgetsPerRow(Math.round(width || 0));

  const teamSearch = `eq(teammate,${get(user, 'id')})&ordering(name)`;
  const { data, isLoading } = useUsersWidgetQuery(teamSearch);
  const teamTotal = get(data, 'total', 0);

  const grid = useMemo(() => {
    const permissionsCtx: WidgetsPermissionsCtx = {
      canCreateAnyContent: canCreateAny,
      user,
      teamSize: teamTotal,
    };

    const availableWidgets = filter(allMyHubWidgets, (widget) => {
      if (!widget.permissions || widget.permissions.length === 0) {
        return true;
      }

      return every(widget.permissions, (func) => func(permissionsCtx));
    });

    return buildWidgetsGrid(availableWidgets, maxPerRow);
  }, [user, canCreateAny, teamTotal, maxPerRow]);

  return (
    <Box ref={ref} display="flex" flexDirection="column" gap={2.5}>
      {!isLoading ? (
        map(grid, (row, idx) => (
          <Box key={idx} display="flex" gap={3}>
            {map(row, (item) => {
              const WidgetComponent = item.component;

              return <WidgetComponent key={item.name} />;
            })}
          </Box>
        ))
      ) : (
        <Stack direction="row" spacing={3}>
          <EmptyWidget isLoading />
          <EmptyWidget isLoading />
          <EmptyWidget isLoading />
        </Stack>
      )}
    </Box>
  );
};
