import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import emitter from 'services/emitters';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { STATUS_LOADING, STATUS_DONE } from 'shared/constants';
import actions from 'users/actions';
import { get } from 'vendor/lodash';

export interface ConsentNoticeModalProps {
  currentUserUpdateStatus: string;
  fetchCurrentUser: () => void;
  updateCurrentUserConsentNoticeAck: (date: Date) => void;
}

export const ConsentNoticeModal = ({
  currentUserUpdateStatus,
  fetchCurrentUser,
  updateCurrentUserConsentNoticeAck,
}: ConsentNoticeModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [consentNoticeTitle, setConsentNoticeTitle] = useState('');
  const [consentNoticeBlurb, setConsentNoticeBlurb] = useState('');

  const showModalWithText = (consentNoticeTitle, consentNoticeBlurb) => {
    setConsentNoticeTitle(consentNoticeTitle);
    setConsentNoticeBlurb(consentNoticeBlurb);
    setShowModal(true);
  };

  useEffect(() => {
    const handler = ({
      consentNoticeTitle,
      consentNoticeBlurb,
    }: {
      consentNoticeTitle: string;
      consentNoticeBlurb: string;
    }) => showModalWithText(consentNoticeTitle, consentNoticeBlurb);
    emitter.on('requireConsentNotice', handler);
    return () => {
      emitter.off('requireConsentNotice', handler);
    };
  }, []);

  useEffect(() => {
    if (currentUserUpdateStatus === STATUS_DONE) {
      fetchCurrentUser();

      setShowModal(false);
    }
  }, [currentUserUpdateStatus, fetchCurrentUser]);

  const handleClose = () => {
    updateCurrentUserConsentNoticeAck(new Date());
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal title={consentNoticeTitle}>
      <ModalBody scrollable>
        <Text>{consentNoticeBlurb}</Text>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton
          disabled={currentUserUpdateStatus === STATUS_LOADING}
          onClick={handleClose}
        >
          I accept
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentUserUpdateStatus: get(state, 'user.currentUserUpdateStatus'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => {
    dispatch(actions.currentUserRequestSubmit());
  },
  updateCurrentUserConsentNoticeAck: (ackTimestamp) => {
    dispatch(actions.currentUserUpdateRequestSubmit({ consent_notice_ack_at: ackTimestamp }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentNoticeModal);
