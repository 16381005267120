import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AutocompleteField from 'inputs/components/AutocompleteField';
import { ApiURLs, fetchURL } from 'services/requests-base';
import userActions from 'users/actions';
import { map } from 'vendor/lodash';

const GoogleCalendarSelectField = (props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const url = `${ApiURLs['api_integrations:google-calendars']()}`;

  useEffect(() => {
    setLoading(true);
    fetchURL(url)
      .then((response) => {
        setLoading(false);

        const mappedOptions = map(response.data, (i) => ({
          value: i.id,
          label: i.name,
        }));

        setOptions(mappedOptions);

        return mappedOptions;
      })
      .catch(() => {
        setLoading(false);

        dispatch(userActions.currentUserRequestSubmit());
      });
  }, []);

  return <AutocompleteField options={options} loading={loading} {...props} />;
};

GoogleCalendarSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default GoogleCalendarSelectField;
