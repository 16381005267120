import UpcomingEvents from 'event-shared/components/UpcomingEvents/UpcomingEvents';
import colors from 'services/colors';
import { ContentItem } from 'shared-content-item/interfaces';
import MediaPoint from 'shared/components/MediaPoint';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { isEmpty } from 'vendor/lodash';
import { styled } from 'vendor/mui';

const DetailContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const DetailsContainer = styled('div')`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border-top: 1px solid ${colors.neutral100};
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const UpcomingEventsContainer = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;

const RelatedEventsContainer = styled('div')`
  overflow-y: auto;
  height: auto;
  padding-bottom: 10px;
  margin-left: -10px;
  ${MediaPoint.Tablet} {
    margin-left: 0px;
  }
`;

const CoverDescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  ${MediaPoint.DesktopSm} {
    flex: 0 0 61.7%;
  }
`;

const DescriptionContainer = styled('div')`
  margin: 16px 0 16px;
`;

interface EventTypeContentProps {
  content: ContentItem;
}

const EventTypeContent = ({ content }: EventTypeContentProps) => {
  const showDescription = !isEmpty(content.content_body);

  return (
    <div>
      <DetailContainer>
        {showDescription && (
          <CoverDescriptionContainer>
            <DescriptionContainer>
              <ContentDescription description={content.content_body} />
            </DescriptionContainer>
          </CoverDescriptionContainer>
        )}
        <DetailsContainer>
          <UpcomingEventsContainer>
            <RelatedEventsContainer>
              <UpcomingEvents
                eventType={content}
                includePrivate={content.is_hidden}
                showEnrollmentAction
              />
            </RelatedEventsContainer>
          </UpcomingEventsContainer>
        </DetailsContainer>
      </DetailContainer>
    </div>
  );
};

export default EventTypeContent;
