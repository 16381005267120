import * as React from 'react';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import colors from 'services/colors';
import { displayDate } from 'services/datetime';
import { Assignment } from 'shared-content-item/interfaces';
import { isBoolean } from 'vendor/lodash';
import { Box, Tooltip, green, orange, amber } from 'vendor/mui';
import {
  CancelIcon,
  CheckCircleIcon,
  ErrorIcon,
  HistoryToggleOffOutlinedIcon,
} from 'vendor/mui-icons';

interface AssignmentStateIconProps {
  isSelected: boolean;
  assignment?: Assignment;
}

export const AssignmentStateIcon = (props: AssignmentStateIconProps) => {
  const { isSelected, assignment } = props;

  const isCompleted = assignment?.state === ASSIGNMENT_STATES.completed;
  const isOverdue = !isCompleted && assignment?.is_overdue;
  const inProgress = !isCompleted && assignment?.state === ASSIGNMENT_STATES.in_progress;
  const isSuccess = isBoolean(assignment?.is_success) ? assignment?.is_success : null;

  const iconColor = React.useMemo(() => {
    if (isSuccess) {
      return isSelected ? colors.success700 : green[300];
    }

    if (isSuccess === false) {
      return isSelected ? orange[600] : amber[500];
    }

    if (isCompleted) {
      return colors.success600;
    }

    if (isOverdue) {
      return colors.alert400;
    }

    return colors.neutral500;
  }, [isSelected, isSuccess, isCompleted, isOverdue]);

  const IconComponent = React.useMemo(() => {
    if (isSuccess === false) return CancelIcon;
    if (isCompleted || isSuccess) return CheckCircleIcon;
    if (isOverdue) return ErrorIcon;
    if (inProgress) return HistoryToggleOffOutlinedIcon;
    if (assignment?.due_date) return ErrorIcon;

    return null;
  }, [isCompleted, isSuccess, isOverdue, inProgress, assignment]);

  const tooltipText = React.useMemo(() => {
    if (isSuccess === false) return 'Failed';
    if (isCompleted || isSuccess) return 'Completed';
    if (inProgress) return 'In progress';
    if (assignment?.due_date) return `Due ${displayDate(assignment.due_date)}`;
    return '';
  }, [isCompleted, inProgress, assignment]);

  return IconComponent ? (
    <Tooltip title={tooltipText}>
      <Box display="flex" alignItems="center" gap="12px" color={iconColor}>
        <IconComponent sx={{ color: iconColor }} />
      </Box>
    </Tooltip>
  ) : null;
};
