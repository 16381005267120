import React from 'react';
import styled from 'styled-components';

import MediaPoint from 'shared/components/MediaPoint';
import { isEmpty } from 'vendor/lodash';

import { menuAreaWidth } from './constants';

const InnerContainerWrapper = styled.div<{ full?: boolean; hasMenu?: boolean }>`
  ${({ full, hasMenu }) =>
    hasMenu &&
    `
    float: right;

    ${
      full
        ? `
      width: calc(100% - ${menuAreaWidth});
    `
        : `
      width: 100%;
    `
    }

    @media (max-width: 1600px) {
      width: calc(100% - ${menuAreaWidth});
    }
  `}
`;

const InnerContainer = styled.div<{ full?: boolean; hasMenu?: boolean }>`
  margin-right: auto;
  margin-left: auto;

  ${({ full, hasMenu }) =>
    full
      ? `
    width: 100%;
  `
      : `
    ${
      hasMenu
        ? `
      ${MediaPoint.Tablet} {
        width: calc(750px - ${menuAreaWidth});
      }
      ${MediaPoint.DesktopSm} {
        width: calc(970px - ${menuAreaWidth});
      }
      ${MediaPoint.DesktopLg} {
        width: calc(1170px - ${menuAreaWidth});
      }
      ${MediaPoint.DesktopXl} {
        width: 1170px;
      }
    `
        : `
      ${MediaPoint.Tablet} {
        width: 100%;
      }

      ${MediaPoint.DesktopLg} {
        width: 970px;
      }

      ${MediaPoint.DesktopXl} {
        width: 1170px;
      }
    `
    }
  `}
`;

export interface ContainerProps {
  children: React.ReactNode;
  Menu?: React.ReactElement;
  full?: boolean;
}

const Container = (props: ContainerProps) => {
  const { Menu, full, children } = props;
  return (
    <InnerContainerWrapper hasMenu={!isEmpty(Menu)} full={full}>
      <InnerContainer hasMenu={!isEmpty(Menu)} full={full}>
        {children}
      </InnerContainer>
    </InnerContainerWrapper>
  );
};

export default Container;
