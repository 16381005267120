import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  CONVERT_EVENT_LOCAL_TO_ONLINE,
  CONVERT_EVENT_MERGE_TO_LOCAL,
  CONVERT_EVENT_MERGE_TO_ONLINE,
  CONVERT_EVENT_ONLINE_TO_LOCAL,
} from 'event-shared/constants';
import CheckboxInput from 'inputs/components/CheckboxInput';
import TextInput from 'inputs/components/TextInput';
import colors from 'services/colors';
import Datetime from 'shared/components/Datetime';
import HR from 'shared/components/HR';
import InfoBox from 'shared/components/InfoBox';
import Text from 'shared/components/Text';
import { useCurrentUser } from 'shared/hooks';
import { concat, isEmpty, join, map, toInteger } from 'vendor/lodash';
import { Box } from 'vendor/mui';

const InfoText = styled(Text)`
  display: flex;
  margin-bottom: 4px;
`;

const EnrollmentMethodContainer = styled.div`
  margin: 16px 0;
`;

const SimpleFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NotificationCheckboxWrapper = styled.div`
  display: flex;
  ${({ active }) => active && 'margin-bottom: 12px;'}

  > * + * {
    margin-left: 8px;
  }
`;

const IcsNotificationCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  > :first-child {
    margin-top: 4px;
  }

  > * + * {
    margin-left: 8px;
  }
`;

const IcsNotificationCheckboxText = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextToggleNotification = styled.div`
  display: flex;
  text-align: center;
  margin-top: 5px;
`;

const TextToggleICS = styled.div`
  display: flex;
  text-align: center;
  margin-top: 10px;
`;

const StyledHR = styled(HR)`
  margin: 20px -20px;
`;

const getEnrollmentMethodString = (isOnline, isLocal, convertEvent) => {
  if (!convertEvent) {
    if (isOnline && !isLocal) {
      return 'Online';
    }
    if (isLocal && !isOnline) {
      return 'In-Person';
    }
    if (isLocal && isOnline) {
      return 'Online & In-Person';
    }
  } else {
    switch (convertEvent) {
      case CONVERT_EVENT_LOCAL_TO_ONLINE:
        return 'Switched from In-Person to Online';
      case CONVERT_EVENT_ONLINE_TO_LOCAL:
        return 'Switched from Online to In-Person';
      case CONVERT_EVENT_MERGE_TO_LOCAL:
        return 'Merged to In-Person only';
      case CONVERT_EVENT_MERGE_TO_ONLINE:
        return 'Merged to Online only';
      default:
        return '';
    }
  }
  return '';
};

const WarningContent = ({ convertEvent }) => {
  let methodText;
  switch (convertEvent) {
    case CONVERT_EVENT_MERGE_TO_LOCAL:
      methodText = 'merge';
      break;
    case CONVERT_EVENT_MERGE_TO_ONLINE:
      methodText = 'merge';
      break;
    case CONVERT_EVENT_ONLINE_TO_LOCAL:
      methodText = 'switch';
      break;
    case CONVERT_EVENT_LOCAL_TO_ONLINE:
      methodText = 'switch';
      break;
    default:
      methodText = null;
  }

  if (isEmpty(methodText)) return null;

  return (
    <>
      Enrollment {methodText} happens after confirmed update. Completion may not be instantaneous,
      you will be notified when it&apos;s done. Please contact our&nbsp;
      <a href="https://help.plusplus.app/en/">support team</a> if you have problems.
    </>
  );
};

WarningContent.propTypes = {
  convertEvent: PropTypes.string,
};

const ReviewModal = ({
  alertState,
  initialValues,
  selectedCoOrganizers,
  selectedLocation,
  selectedOrganizer,
  selectedPresenters,
  values,
}) => {
  const {
    timeslots,
    convert_event: convertEvent,
    is_online: isOnline,
    is_local: isLocal,
    enrollment_limit: enrollmentLimit,
    wait_list_limit: waitlistLimit,
    online_enrollment_limit: onlineEnrollmentLimit,
    online_wait_list_limit: onlineWaitListLimit,
  } = values;

  const { include_ics_file_in_event_emails: includeICSFilesInEventEmails } = useCurrentUser();

  const selectedOrganizers = concat(selectedOrganizer.name, map(selectedCoOrganizers, 'name'));
  const presentersNamesList = map(selectedPresenters, 'name');

  const [{ sendNotification, notificationText, sendICSFile }, setAlertState] = alertState;

  const handleSendNotificationChange = (value) =>
    setAlertState((s) => ({ ...s, sendNotification: value }));

  const handleSendICSFileChange = (value) => setAlertState((s) => ({ ...s, sendICSFile: value }));

  const handleNotificationTextChange = (event) => {
    const notificationText = event.target.value;
    setAlertState((s) => ({ ...s, notificationText }));
  };

  return (
    <React.Fragment>
      <InfoText size="h4">
        Presenters: {!isEmpty(presentersNamesList) ? join(presentersNamesList, ', ') : 'None'}
      </InfoText>

      <InfoText size="h4">
        Organizers: {!isEmpty(selectedOrganizers) ? join(selectedOrganizers, ', ') : 'None'}
      </InfoText>

      <InfoText size="h4" display="flex">
        Date:&nbsp;
        <Text size="h4">
          {map(timeslots, (timeslot) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* The aware dates can't be used here because the form wasn't submitted, i.e., they weren't calculated yet. */}
              <Box>
                <Datetime.Range
                  start={timeslot.starts_at}
                  end={moment(timeslot.starts_at).add(timeslot.duration).format()}
                  timezone="UTC"
                  displayTimezone="UTC"
                  isOnline={isOnline}
                />
              </Box>
              {timeslot.rooms_info && <Box>{timeslot.rooms_info}</Box>}
            </Box>
          ))}
        </Text>
      </InfoText>

      <InfoText size="h4">Location: {selectedLocation ? selectedLocation.name : 'None'}</InfoText>

      <EnrollmentMethodContainer>
        <InfoText size="h4">
          Enrollment Method:&nbsp;
          <Text size="h4" color={colors.success600}>
            {getEnrollmentMethodString(isOnline, isLocal, convertEvent)}
          </Text>
        </InfoText>
      </EnrollmentMethodContainer>

      {isLocal && (
        <React.Fragment>
          <InfoText size="h4">
            {isOnline && 'In-Person '}Enrolled:&nbsp;
            <Text size="h4" medium>
              {toInteger(initialValues.going_enrollments_count) -
                toInteger(initialValues.going_online_enrollments_count)}
              {enrollmentLimit ? `/${enrollmentLimit} ` : ' '}
              {enrollmentLimit === 1 && enrollmentLimit !== 0 ? 'spot' : 'spots'}
            </Text>
          </InfoText>

          <InfoText size="h4">
            {isOnline && 'In-Person '}Waitlisted:&nbsp;
            <Text size="h4" medium>
              {toInteger(initialValues.waitlist_local_enrollments_count)}
              {waitlistLimit ? `/${waitlistLimit} ` : ' '}
              {waitlistLimit === 1 && waitlistLimit !== 0 ? 'spot' : 'spots'}
            </Text>
          </InfoText>
        </React.Fragment>
      )}
      {isOnline && (
        <React.Fragment>
          <InfoText size="h4">
            {isLocal && 'Online '}Enrolled:&nbsp;
            <Text size="h4" medium>
              {toInteger(initialValues.going_online_enrollments_count)}
              {onlineEnrollmentLimit ? `/${onlineEnrollmentLimit} ` : ' '}
              {onlineEnrollmentLimit === 1 && onlineEnrollmentLimit !== 0 ? 'spot' : 'spots'}
            </Text>
          </InfoText>

          <InfoText size="h4">
            {isLocal && 'Online '}Waitlisted:&nbsp;
            <Text size="h4" medium>
              {toInteger(initialValues.waitlist_going_online_enrollments_count)}
              {onlineWaitListLimit ? `/${onlineWaitListLimit} ` : ' '}
              {onlineWaitListLimit === 1 && onlineWaitListLimit !== 0 ? 'spot' : 'spots'}
            </Text>
          </InfoText>
        </React.Fragment>
      )}

      {convertEvent && (
        <InfoBox
          type="warning"
          content={<WarningContent convertEvent={convertEvent} />}
          margin="16px 0"
        />
      )}

      <SimpleFlexColumn>
        <StyledHR color={colors.neutral200} />

        <NotificationCheckboxWrapper active={sendNotification}>
          <CheckboxInput
            checked={sendNotification}
            onChange={() => handleSendNotificationChange(!sendNotification)}
          />
          <TextToggleNotification>
            <Text size="h4" onClick={() => handleSendNotificationChange(!sendNotification)}>
              Notify all facilitators and attendees about this change.
            </Text>
          </TextToggleNotification>
        </NotificationCheckboxWrapper>

        {sendNotification && (
          <SimpleFlexColumn>
            <InfoText medium size="h5" color={colors.neutral600}>
              Add a message (optional)
            </InfoText>
            <TextInput
              multiline
              rows={3}
              inputProps={{
                value: notificationText,
                onChange: handleNotificationTextChange,
                maxLength: 140,
              }}
              helperText={`${notificationText.length}/140 characters`}
            />
          </SimpleFlexColumn>
        )}
      </SimpleFlexColumn>

      {includeICSFilesInEventEmails && (
        <SimpleFlexColumn>
          <StyledHR color={colors.neutral200} />
          <IcsNotificationCheckboxWrapper active={sendICSFile}>
            <CheckboxInput
              checked={sendICSFile}
              onChange={() => handleSendICSFileChange(!sendICSFile)}
            />
            <IcsNotificationCheckboxText>
              <TextToggleICS>
                <Text size="h4" onClick={() => handleSendICSFileChange(!sendICSFile)}>
                  Push ICS updates to all facilitators and attendees.
                </Text>
              </TextToggleICS>
            </IcsNotificationCheckboxText>
          </IcsNotificationCheckboxWrapper>
        </SimpleFlexColumn>
      )}
    </React.Fragment>
  );
};

ReviewModal.propTypes = {
  alertState: PropTypes.object,
  initialValues: PropTypes.object,
  selectedCoOrganizers: PropTypes.array,
  selectedLocation: PropTypes.object,
  selectedOrganizer: PropTypes.object,
  selectedPresenters: PropTypes.array,
  values: PropTypes.object,
};

export default ReviewModal;
