import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import CustomAttributesModal from 'scenes/UserProfile/CustomAttributesModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import DropDownMenu from 'shared/components/DropDownMenu';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';
import { useCurrentUser } from 'shared/hooks';
import { IS_ADMIN_PERMISSION } from 'shared/permissions';
import { isEmpty, isEqual, includes } from 'vendor/lodash';

import UserLogtrailModal from '../UserLogtrailModal';
import UserSyncConfirmationModal from '../UserSyncConfirmationModal';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = ({ profileData }) => {
  const [showOrgAttrsModal, setShowOrgAttrsModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showUserSyncConfirmationModal, setShowUserSyncConfirmationModal] = useState(false);
  const { name, people_integration_data } = profileData;
  const orgAttrs = Object.entries(people_integration_data || {});

  const handleOrgAttrsModalOpen = () => setShowOrgAttrsModal(true);
  const handleOrgAttrsModalClose = () => setShowOrgAttrsModal(false);
  const handleLogModalOpen = () => setShowLogModal(true);
  const handleLogModalClose = () => setShowLogModal(false);
  const handleUserSyncConfirmationModalOpen = () => setShowUserSyncConfirmationModal(true);
  const handleUserSyncConfirmationModalClose = () => setShowUserSyncConfirmationModal(false);

  const currentUser = useCurrentUser();
  const isUser = isEqual(profileData.id, currentUser.id);
  const isAdmin = includes(currentUser.permissions, IS_ADMIN_PERMISSION);
  const canEdit = isUser || isAdmin;
  const showsDropdown = isAdmin && !isEmpty(orgAttrs);

  return (
    <Container>
      <Text block medium size="h1" color={colors.neutral900}>
        {name || ''}
      </Text>
      {canEdit && !showsDropdown && (
        <Button
          startIcon={<Icon name="pencil" />}
          size="small"
          route={mapRoute('userEdit', { id: profileData.id })}
        >
          Edit
        </Button>
      )}
      {showsDropdown && (
        <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
          <DropDownMenu.Item
            route={mapRoute('userEdit', { id: profileData.id })}
            title="Edit"
            icon="pencil"
          />
          <DropDownMenu.Item
            onClick={handleOrgAttrsModalOpen}
            title="View Custom Attributes"
            icon="list-view"
          />
          {isAdmin && (
            <>
              <DropDownMenu.Item
                onClick={handleUserSyncConfirmationModalOpen}
                title="Sync User Data"
                icon="refresh"
              />
              <DropDownMenu.Item
                route={`${mapRoute('dashboardUserLoads')}?eq(user,${profileData.id})`}
                title="View Latest User Syncs"
                icon="search"
              />
            </>
          )}
          <DropDownMenu.Item onClick={handleLogModalOpen} title="View Logs" icon="log" />
        </DropDownMenu>
      )}
      {showOrgAttrsModal && (
        <CustomAttributesModal user={profileData} handleClose={handleOrgAttrsModalClose} />
      )}
      {showLogModal && (
        <UserLogtrailModal userId={profileData.id} handleClose={handleLogModalClose} />
      )}
      {showUserSyncConfirmationModal && (
        <UserSyncConfirmationModal
          user={profileData}
          handleClose={handleUserSyncConfirmationModalClose}
        />
      )}
    </Container>
  );
};

Title.propTypes = {
  profileData: PropTypes.object,
};

export default Title;
