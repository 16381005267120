import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';
import { get } from 'vendor/lodash';

const OrderingIcon = styled(Icon)`
  margin-left: 8px;
`;

const Label = styled(Text)`
  line-height: 20px;
  ${({ margin }) => margin && `margin: ${margin}`}
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickerHover: false,
      ascDescString: '',
      ascDescStringHover: '',
    };
  }

  isAscending = () => {
    const { orderingKey, selectedFilters } = this.props;
    return get(selectedFilters, 'o', null) === orderingKey;
  };

  isDescending = () => {
    const { orderingKey, selectedFilters } = this.props;
    return get(selectedFilters, 'o', null) === `-${orderingKey}`;
  };

  updateAscDescString = () => {
    const { ascendingText, descendingText } = this.props;
    if (!ascendingText || !descendingText) return null;
    if (this.isAscending()) {
      this.setState({ ascDescString: ` ${ascendingText} ` });
    } else if (this.isDescending()) {
      this.setState({ ascDescString: ` ${descendingText} ` });
    }
    return null;
  };

  handleOnMouseEnter = () => {
    const { ascendingText, descendingText } = this.props;
    if (!ascendingText || !descendingText) return null;
    if (!this.isAscending() && !this.isDescending()) {
      this.setState({ ascDescStringHover: ` ${ascendingText} ` });
    }
    if (this.isAscending()) {
      this.setState({ ascDescStringHover: ` ${descendingText} ` });
    } else if (this.isDescending()) {
      this.setState({ ascDescStringHover: ` ${ascendingText} ` });
    }
    this.setState({ clickerHover: true });
    return null;
  };

  handleOnMouseLeave = () => {
    const { ascendingText, descendingText } = this.props;
    if (!ascendingText || !descendingText) return null;
    if (!this.isAscending() && !this.isDescending()) {
      this.setState({ ascDescStringHover: '' });
    }
    if (this.isAscending()) {
      this.setState({ ascDescStringHover: ` ${ascendingText} ` });
    } else if (this.isDescending()) {
      this.setState({ ascDescStringHover: ` ${descendingText} ` });
    }
    this.setState({ clickerHover: false });
    return null;
  };

  handleClick = () => {
    const { selectedFilters, onFilterChange, orderingKey } = this.props;

    this.updateAscDescString();
    onFilterChange({
      ...selectedFilters,
      o: this.isAscending() ? `-${orderingKey}` : orderingKey,
    });
  };

  handleOnFocusOut = () => {
    this.setState({ ascDescString: '', ascDescStringHover: '' });
  };

  render = () => {
    const { title, orderingKey, icon } = this.props;
    const { clickerHover, ascDescString, ascDescStringHover } = this.state;

    let pointerColor = colors.neutral900;
    if (clickerHover) {
      pointerColor = colors.neutral400;
    }

    let orderingIndicator = null;
    if (this.isAscending()) {
      orderingIndicator = <OrderingIcon name="down" color={pointerColor} height={12} width={12} />;
    } else if (this.isDescending()) {
      orderingIndicator = <OrderingIcon name="up" color={pointerColor} height={12} width={12} />;
    }

    return orderingKey ? (
      <Clicker
        onClick={this.handleClick}
        onBlur={this.handleOnFocusOut}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <Label size="h5">{title}</Label>
        <Label size="h5" margin="0 0 0 2px">
          {ascDescString !== ascDescStringHover ? ascDescStringHover : ascDescString}
        </Label>
        {icon}
        {orderingIndicator}
      </Clicker>
    ) : (
      <Label size="h5">{title}</Label>
    );
  };
}

Header.propTypes = {
  title: PropTypes.string,
  orderingKey: PropTypes.string,
  selectedFilters: PropTypes.object,
  onFilterChange: PropTypes.func,
  ascendingText: PropTypes.string,
  descendingText: PropTypes.string,
  icon: PropTypes.any,
};

export default Header;
