import {
  ATTENDANCE_STATUSES,
  ATTENDANCE_STATUSES_ICONS,
  ATTENDANCE_STATUSES_LABELS,
} from 'enrollments/constants';
import { Enrollment } from 'enrollments/types';
import { Chip, cyan, grey, indigo, orange, pink, red } from 'vendor/mui';

const ATTENDANCE_STATUSES_CHIP_BACKGROUND_COLORS = {
  [ATTENDANCE_STATUSES.no_show]: orange[100],
  [ATTENDANCE_STATUSES.unknown]: grey[300],
  [ATTENDANCE_STATUSES.waitlisted]: indigo[50],
  [ATTENDANCE_STATUSES.enrolled]: cyan[50],
  [ATTENDANCE_STATUSES.checked_in]: cyan[100],
  [ATTENDANCE_STATUSES.dropped]: pink[50],
  [ATTENDANCE_STATUSES.cancelled]: red[100],
};

interface EnrollmentAttendanceStatusChipProps {
  enrollment: Enrollment;
  size?: 'small' | 'medium';
}

const EnrollmentAttendanceStatusChip = ({
  enrollment,
  size = 'small',
}: EnrollmentAttendanceStatusChipProps) => {
  const { attendance_status: attendanceStatus } = enrollment;

  const Icon = ATTENDANCE_STATUSES_ICONS[attendanceStatus];
  const label = ATTENDANCE_STATUSES_LABELS[attendanceStatus];
  const backgroundColor = ATTENDANCE_STATUSES_CHIP_BACKGROUND_COLORS[attendanceStatus];
  const sx = { borderRadius: 1, backgroundColor };

  const chipProps = {
    label,
    size,
    sx,
    icon: <Icon />,
  };

  switch (attendanceStatus) {
    case ATTENDANCE_STATUSES.no_show:
    case ATTENDANCE_STATUSES.unknown:
    case ATTENDANCE_STATUSES.waitlisted:
    case ATTENDANCE_STATUSES.enrolled:
    case ATTENDANCE_STATUSES.checked_in:
    case ATTENDANCE_STATUSES.dropped:
    case ATTENDANCE_STATUSES.cancelled:
      return <Chip {...chipProps} />;
    default:
      return null;
  }
};

export default EnrollmentAttendanceStatusChip;
