import {
  COURSE_REGISTRATION_RESULTS_ICONS,
  COURSE_REGISTRATION_RESULTS_LABELS,
} from 'course/constants';
import { COURSE_REGISTRATION_RESULTS, CourseRegistration } from 'course/interfaces';
import colors from 'services/colors';
import { isNil } from 'vendor/lodash';
import { Chip, cyan, pink, grey } from 'vendor/mui';

interface CourseRegistrationResultChipProps {
  courseRegistration: CourseRegistration;
}

const getResultChipProps = ({ courseRegistration }: CourseRegistrationResultChipProps) => {
  const { scorm_result: scormResult } = courseRegistration;

  // Empty and Unknown results are not displayed
  if (isNil(scormResult) || scormResult === COURSE_REGISTRATION_RESULTS.unknown)
    return { label: null, icon: undefined, sx: {} };

  const label = COURSE_REGISTRATION_RESULTS_LABELS[scormResult];
  const Icon = COURSE_REGISTRATION_RESULTS_ICONS[scormResult];
  let backgroundColor: string = grey[300];

  if (scormResult === COURSE_REGISTRATION_RESULTS.failed) backgroundColor = pink[100];

  if (scormResult === COURSE_REGISTRATION_RESULTS.passed) backgroundColor = cyan[50];

  return {
    label,
    icon: <Icon />,
    sx: { borderRadius: '4px', color: colors.lightActionActive, backgroundColor },
  };
};

const CourseRegistrationResultChip = ({
  courseRegistration,
}: CourseRegistrationResultChipProps) => {
  const { label, icon, sx } = getResultChipProps({ courseRegistration });

  if (isNil(label)) return null;

  return <Chip size="small" label={label} icon={icon} sx={sx} />;
};

export default CourseRegistrationResultChip;
