import PropTypes from 'prop-types';
import React from 'react';

import TextInput from 'inputs/components/TextInput';
import { get } from 'vendor/lodash';

const SettingShortTemplateField = ({ input, ariaLabel, ...props }) => {
  // The input value format is [template_path, template_content]
  // If there's a template, the content is from it
  // Otherwise, it's a customized content
  const value = get(input, 'value.value.1');
  const { label: _, ...propsWithoutLabel } = props;

  return (
    <TextInput
      {...propsWithoutLabel}
      inputProps={{
        ...props.inputProps,
        id: input.name,
        name: input.name,
        'aria-label': ariaLabel,
        value,
        onChange: (event) =>
          input.onChange({ ...input.value, value: ['', event.target.value], inherited: false }),
      }}
    />
  );
};

SettingShortTemplateField.propTypes = {
  input: PropTypes.object,
  inputProps: PropTypes.object,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
};

export default SettingShortTemplateField;
