import { useCallback } from 'react';

import TextEditorInput from '../TextEditorInput';
import { TextEditorInputProps } from '../TextEditorInput/TextEditorInput';

interface Props extends Omit<TextEditorInputProps, 'value' | 'onChange' | 'initial' | 'readOnly'> {
  input: { value: any; onChange: (value) => void };
  meta: { initial: any; touched: boolean; error: any; submitFailed: boolean }; // Temporary typing to satisfy FieldError
}

/**
 * Unwraps the ReduxForm props input and meta into fields used by the TextEditorInput.
 *
 * It also handles by default with content appended to the editor, updating the redux form value accordingly.
 * The default append behavior can be overwritten by passing a new value to `handleAppendToEditor`
 */
const TextEditorField = ({ meta, input, handleAppendToEditor, helperText, ...props }: Props) => {
  const { value, onChange } = input;
  const { initial } = meta;

  const isError = Boolean(meta.touched && meta.error);

  // By default, content appended to the editor (like images or videos) are also
  // computed into the respective redux form value
  const defaultAppendContentToEditor = useCallback(
    (url) => {
      onChange?.({ type: 'link_embed', content: url });
    },
    [onChange]
  );

  return (
    <TextEditorInput
      {...props}
      handleAppendToEditor={handleAppendToEditor || defaultAppendContentToEditor}
      value={value || initial}
      isError={isError}
      onChange={onChange}
      readOnly={false}
      acceptRawTextInput
      helperText={isError ? meta.error : helperText}
    />
  );
};

export default TextEditorField;
