import { compose, createStore, applyMiddleware } from 'redux';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware), persistState(['notifications']));

export default createStore(reducer, enhancer);

sagaMiddleware.run(rootSaga);
