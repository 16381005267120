import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { detailQueryKeyFactory, listQueryKeyFactory } from 'data-fetching/utils';

export const articles = createQueryKeys('articles', {
  list: listQueryKeyFactory({ endpointName: 'articles_api:list_create' }),
  detail: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
});

export type ArticlesQueryKeys = inferQueryKeys<typeof articles>;
