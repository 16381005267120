import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  margin-top: 16px;
  max-height: 39px;
  height: 32px;
  display: flex;
  align-items: center;

  & > * {
    width: ${({ singleButton }) => (singleButton ? '100%' : '48%')};
    ${({ noPadding }) => noPadding && 'padding: 1px 0'};
  }

  & > *:first-child {
    margin-right: ${({ singleButton, noMargin }) => (singleButton || noMargin ? '0' : '2%')};
  }

  & > *:last-child {
    margin-left: ${({ singleButton, noMargin }) => (singleButton || noMargin ? '0' : '2%')};
  }
`;

export default ButtonsContainer;
