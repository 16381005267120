import PropTypes from 'prop-types';

import LogtrailModal from 'shared-modals/components/LogtrailModal';
import { includes } from 'vendor/lodash';

// Based on AVAILABLE_RESOURCE_TYPES at backend/audits/application/serialization.py
const resource_types = ['event', 'event_type', 'session', 'survey', 'content_item', 'testimonial'];

const ContentItemLogtrailModal = ({ content, handleClose, ...rest }) => {
  const content_type = includes(resource_types, content.content_type)
    ? content.content_type
    : 'content_item';
  const expression = `eq(log_type,${content_type})&eq(resource_id,${content.id})`;
  return (
    <LogtrailModal
      title={`${content.name} Logs`}
      expression={expression}
      handleClose={handleClose}
      {...rest}
    />
  );
};

ContentItemLogtrailModal.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ContentItemLogtrailModal;
