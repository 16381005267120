import { cloneDeep } from 'vendor/lodash';

export const surveyJSONSchemaQuestionKind = {
  SHORT_TEXT: { value: 'short_text', label: 'Short Text' },
  PARAGRAPH_TEXT: { value: 'paragraph_text', label: 'Paragraph Text' },
  RATING_SCALE: { value: 'rating_scale', label: 'Rating Scale' },
  LINEAR_SCALE: { value: 'linear_scale', label: 'Linear Scale' },
  SINGLE_CHOICE: { value: 'single_choice', label: 'Single Choice' },
  SINGLE_CHOICE_AND_OTHER: {
    value: 'single_choice_and_other',
    label: 'Single Choice ("Other" option included)',
  },
  MULTIPLE_CHOICE: { value: 'multiple_choice', label: 'Multiple Choice' },
  MULTIPLE_CHOICE_AND_OTHER: {
    value: 'multiple_choice_and_other',
    label: 'Multiple Choice ("Other" option included)',
  },
};

export const createSurveyJSONSchema = {
  title: 'Survey Details',
  type: 'object',
  required: ['title', 'questions'],
  definitions: {
    options: {
      title: 'Options',
      type: 'array',
      format: 'table',
      minItems: 2,
      items: {
        title: ' ',
        type: 'string',
        minLength: 1,
      },
    },
  },
  properties: {
    title: {
      title: 'Title*',
      type: 'string',
      minLength: 1,
      options: {
        inputAttributes: {
          class: 'text-input',
          placeholder: 'Survey Title',
        },
      },
    },
    description: {
      title: 'Description',
      type: 'string',
      options: {
        inputAttributes: {
          class: 'text-input',
          placeholder: 'Survey Description',
        },
      },
    },
    questions: {
      title: 'Survey Questions',
      type: 'array',
      format: 'select',
      uniqueItems: true,
      minItems: 1,
      items: {
        title: 'Survey Question',
        options: {
          keep_oneof_values: false,
        },
        required: ['kind', 'text'],
        properties: {
          kind: {
            title: 'Type*',
            type: 'string',
            enum: [
              surveyJSONSchemaQuestionKind.SHORT_TEXT.label,
              surveyJSONSchemaQuestionKind.PARAGRAPH_TEXT.label,
              surveyJSONSchemaQuestionKind.RATING_SCALE.label,
              surveyJSONSchemaQuestionKind.LINEAR_SCALE.label,
              surveyJSONSchemaQuestionKind.SINGLE_CHOICE.label,
              surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.label,
              surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.label,
              surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.label,
            ],
            minLength: 1,
            options: {
              inputAttributes: {
                class: 'select-input',
                placeholder: 'Select the kind of this question',
              },
            },
          },
          text: {
            title: 'Question*',
            type: 'string',
            minLength: 1,
            options: {
              inputAttributes: {
                class: 'text-input',
                placeholder: 'Question',
              },
            },
          },
          description: {
            title: 'Description',
            type: 'string',
            options: {
              inputAttributes: {
                class: 'text-input',
                placeholder: 'Question Description',
              },
            },
          },
          is_required: {
            title: 'Is required?',
            type: 'boolean',
            format: 'checkbox',
            default: false,
          },
          single_choice_options: {
            $ref: '#/definitions/options',
            options: {
              dependencies: {
                kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE.label,
              },
            },
          },
          single_choice_and_other_options: {
            $ref: '#/definitions/options',
            options: {
              dependencies: {
                kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.label,
              },
            },
          },
          multiple_choice_options: {
            $ref: '#/definitions/options',
            options: {
              dependencies: {
                kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.label,
              },
            },
          },
          multiple_choice_and_other_options: {
            $ref: '#/definitions/options',
            options: {
              dependencies: {
                kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.label,
              },
            },
          },
          linear_scale_settings: {
            title: ' ',
            type: 'object',
            properties: {
              lower_range: {
                title: 'Lower Range',
                type: 'integer',
                default: 0,
                options: {
                  inputAttributes: {
                    class: 'text-input',
                    placeholder: 'Question Lower Range',
                  },
                },
              },
              upper_range: {
                title: 'Upper Range',
                type: 'integer',
                default: 5,
                options: {
                  inputAttributes: {
                    class: 'text-input',
                    placeholder: 'Question Upper Range',
                  },
                },
              },
              label_1: {
                title: 'Lower Label',
                type: 'string',
                options: {
                  inputAttributes: {
                    class: 'text-input',
                    placeholder: 'Question Lower Label',
                  },
                },
              },
              label_2: {
                title: 'Upper Label',
                type: 'string',
                options: {
                  inputAttributes: {
                    class: 'text-input',
                    placeholder: 'Question Upper Label',
                  },
                },
              },
            },
            options: {
              dependencies: {
                kind: surveyJSONSchemaQuestionKind.LINEAR_SCALE.label,
              },
            },
          },
        },
      },
    },
  },
};

export const editSurveyJSONSchema = (() => {
  const schema = cloneDeep(createSurveyJSONSchema);

  // Hide IDs
  schema.properties.id = {
    type: 'integer',
    options: {
      hidden: 'true',
    },
  };
  schema.properties.questions.items.properties.id = {
    type: 'integer',
    options: {
      hidden: 'true',
    },
  };

  // Disable questions array manipulation
  schema.properties.questions.options = {
    disable_array_add: true,
    disable_array_delete: true,
    disable_array_delete_all_rows: true,
    disable_array_delete_last_row: true,
  };

  // Disable ranges in linear scale settings
  schema.properties.questions.items.properties.linear_scale_settings.properties.lower_range.readOnly = true;
  schema.properties.questions.items.properties.linear_scale_settings.properties.upper_range.readOnly = true;

  // Disable kind
  schema.properties.questions.items.properties.kind.readOnly = true;

  return schema;
})();
