import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { OnChangeListenerContext } from 'app/settings/context-providers/OnChangeListenerContext';
import { useUrlHashState } from 'common/hooks/useUrlHashState';
import colors from 'services/colors';
import { scrollToElement } from 'services/utils';
import ContentTopbarPage from 'shared-layouts/ContentTopbarPage';
import ContentSidebarItem from 'shared-layouts/ContentTopbarPage/ContentSidebarItem';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import HR from 'shared/components/HR';
import OldForm from 'shared/components/OldForm';
import { capitalizeMultipleWordsName } from 'shared/services';
import { isEmpty, map, concat, isNil, find, get, size, toInteger } from 'vendor/lodash';
import { TabContext, TabList, TabPanel } from 'vendor/mui-lab.tsx';
import { Tab, Grid, Paper } from 'vendor/mui.tsx';

import FormTopBar from './FormTopBar';
import SectionInfoPanel from './SectionInfoPanel';
import { renderSectionsList } from './utils';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 0 auto;
  width: 960px;
  padding: 20px 0px 40px;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 0 auto;
  width: 960px;
`;

const ContentForm = ({
  isEdit,
  contentNameSingular,
  contentTitle,
  contentInfoPanelText,
  contentHCArticleURL,
  contentInfoBox,
  invalid,
  handleSubmit,
  error,
  submitting,
  breadcrumbsItemList,
  contentSectionsList,
  advancedSettingsList,
  topBarActionName,
  backRoute,
  onSaveAndDuplicateHandler,
  onSaveAndBulkDuplicateHandler,
  onSaveAndApplyHandler,
  disableSave,
  change,
  isModalForm,
  tabsConfig,
  defaultSelectedTab,
  sidebarComponentContent,
  renderAlert,
}) => {
  const capitalizedContentNameSingular = contentNameSingular
    ? capitalizeMultipleWordsName(contentNameSingular)
    : null;

  // Handles the sidebar interactions
  const [urlHash, setUrlHash] = useUrlHashState();

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  // If the page opens up with a hash, we want that users to be sent to the section
  // immediately. However, for sidebar interactions, we'll use smooth scrolling
  const sectionScrollBehaviorRef = useRef('auto');

  useEffect(() => {
    const behavior = sectionScrollBehaviorRef.current;

    if (urlHash) {
      const offsetTop = 64 + 20; // Navbar height + Margin top
      scrollToElement(urlHash, { top: offsetTop, behavior });
    }
  }, [urlHash]);

  // Handles the update of the top bar "title"
  const [topBarTitle, updateTopBarTitle] = useState('');
  useEffect(() => {
    updateTopBarTitle(contentTitle);
  }, [contentTitle]);
  const listenerValue = useRef({
    onChange: change,
    canResetToDefault: true,
  });

  const renderTopBar = !isModalForm && (
    <FormTopBar
      isEdit={isEdit}
      actionName={topBarActionName}
      disableSave={disableSave}
      title={topBarTitle}
      backRoute={backRoute}
      loading={submitting}
      onSaveAndDuplicateHandler={onSaveAndDuplicateHandler}
      onSaveAndBulkDuplicateHandler={onSaveAndBulkDuplicateHandler}
      onSaveAndApplyHandler={onSaveAndApplyHandler}
    />
  );

  const renderSidebarContent =
    !isModalForm &&
    (({ openSidebar, alwaysOpen }) => {
      if (alwaysOpen) {
        openSidebar();
      }

      return map(
        [
          { sectionKey: 1, sections: contentSectionsList },
          { sectionKey: 2, sections: advancedSettingsList },
        ],
        ({ sectionKey, sections }, i, arr) => (
          // Renders all inner sections of a section and then add a HR separator
          // to the end in case this isn't the last top-level section
          <React.Fragment key={sectionKey}>
            {map(
              sections,
              ({ id, icon, label, hideNav }) =>
                // Renders the inner section in the sidebar
                !hideNav && (
                  <ContentSidebarItem
                    key={id}
                    selected={urlHash === id}
                    icon={icon}
                    label={label}
                    onClick={() => {
                      sectionScrollBehaviorRef.current = 'smooth';
                      setUrlHash(id);
                    }}
                  />
                )
            )}
            {i < arr.length - 1 && <HR color={colors.neutral200} />}
          </React.Fragment>
        )
      );
    });

  const tabs = concat(
    [
      {
        id: 'form',
        label: 'Details & Settings',
        renderSidebar: renderSidebarContent
          ? // Forcing non-modal forms to start with the sidebar opened, otherwise keep the default behavior
            ({ openSidebar }) => renderSidebarContent({ openSidebar, alwaysOpen: true })
          : renderSidebarContent,
        renderContent: null,
      },
    ],
    tabsConfig ?? []
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderSidebar = get(
    find(tabs, ({ id }) => id === selectedTab),
    'renderSidebar',
    renderSidebarContent
  );

  const tabListSize = 560;
  const tabSize = toInteger(tabListSize / size(tabs));

  return (
    <OnChangeListenerContext.Provider value={listenerValue.current}>
      <form onSubmit={handleSubmit}>
        <TabContext value={selectedTab}>
          <ContentTopbarPage
            renderTopbar={renderTopBar}
            renderSidebarContent={renderSidebar}
            sidebarComponentContent={sidebarComponentContent}
          >
            <Content>
              {/* Hide the tabs grid if the content has only the form tab  */}
              <Grid sx={{ display: size(tabs) === 1 ? 'none' : 'flex', justifyContent: 'center' }}>
                <Paper
                  elevation={2}
                  sx={{ display: 'flex', width: `${tabListSize}px`, justifyContent: 'center' }}
                >
                  <TabList onChange={handleTabChange}>
                    {map(tabs, ({ id, label }) => {
                      return (
                        <Tab
                          data-testid={id}
                          value={id}
                          label={label}
                          key={`tab-item-${id}`}
                          sx={{ width: `${tabSize}px` }}
                        />
                      );
                    })}
                  </TabList>
                </Paper>
              </Grid>
              {map(tabs, ({ id, renderContent }) => {
                return (
                  <TabPanel value={id} sx={{ p: 0 }} key={`tab-panel-${id}`}>
                    {renderContent && renderContent({ error, invalid, submitting })}

                    {isNil(renderContent) && (
                      <TabContent>
                        {breadcrumbsItemList && <Breadcrumbs items={breadcrumbsItemList} />}

                        <OldForm.ErrorContainer
                          invalid={invalid}
                          error={error}
                          submitting={submitting}
                        />

                        {renderAlert ? renderAlert() : null}

                        {contentNameSingular && (
                          <SectionInfoPanel
                            title={capitalizedContentNameSingular}
                            infoText={contentInfoPanelText}
                            HCArticleURL={contentHCArticleURL}
                            infoBox={contentInfoBox}
                          />
                        )}

                        {renderSectionsList(contentSectionsList)}

                        {!isEmpty(advancedSettingsList) && (
                          <>
                            <SectionInfoPanel
                              title="Advanced Settings"
                              infoText="The advanced settings below typically inherit sensible defaults, which you may choose to customize."
                            />
                            {renderSectionsList(advancedSettingsList)}
                          </>
                        )}
                      </TabContent>
                    )}
                  </TabPanel>
                );
              })}
            </Content>
          </ContentTopbarPage>
        </TabContext>
      </form>
    </OnChangeListenerContext.Provider>
  );
};

const innerSectionShape = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  section: PropTypes.element,
  sectionProps: PropTypes.object,
  hideNav: PropTypes.bool,
};

const tabConfigShape = {
  id: PropTypes.string,
  label: PropTypes.string,
  renderSidebar: PropTypes.func,
  renderContent: PropTypes.func,
};

ContentForm.defaultProps = {
  defaultSelectedTab: 'form',
};

ContentForm.propTypes = {
  invalid: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  change: PropTypes.func,

  isEdit: PropTypes.bool,
  contentTitle: PropTypes.string,

  contentNameSingular: PropTypes.string,
  contentInfoPanelText: PropTypes.string,
  contentHCArticleURL: PropTypes.string,
  contentInfoBox: PropTypes.element,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  contentSectionsList: PropTypes.arrayOf(PropTypes.shape(innerSectionShape)),
  advancedSettingsList: PropTypes.arrayOf(PropTypes.shape(innerSectionShape)),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  onSaveAndDuplicateHandler: PropTypes.func,
  onSaveAndBulkDuplicateHandler: PropTypes.func,
  onSaveAndApplyHandler: PropTypes.func,
  disableSave: PropTypes.bool,

  isModalForm: PropTypes.bool,
  tabsConfig: PropTypes.arrayOf(PropTypes.shape(tabConfigShape)),
  defaultSelectedTab: PropTypes.string,

  sidebarComponentContent: PropTypes.func,

  renderAlert: PropTypes.func,
};

export default ContentForm;
