import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { LabelContainer, Box, Input, Checkmark } from 'inputs/components/SquareCheckboxInput';
import colors from 'services/colors';
import { Tooltip } from 'shared/components/Tooltip';
import { useTooltipUID } from 'shared/hooks';

import Column from './Column';

export const SELECTED_ITEMS_STATUS = {
  none: 'none',
  all: 'all',
  partial: 'partial',
};

const DashboardCheckboxBox = styled(Box)`
  height: 16px;
  width: 16px;
  min-width: 16px;
  line-height: 16px;

  ${({ partial }) =>
    partial &&
    `
      background-color: ${colors.neutral200};
      color: ${colors.neutral0};
  `}
`;

const DashboardCheckmark = styled(Checkmark)`
  left: 4px;
  width: 6px;
  height: 10px;
`;

const PartialDash = styled.span`
  position: absolute;
  top: -4px;
  left: 2px;
  width: 10px;
  height: 12px;
  border: solid white;
  border-width: 0 0 2px 0;
`;

export const SelectorIndicator = ({ status, ...rest }) => {
  const tooltipNone = useTooltipUID();
  const tooltipAll = useTooltipUID();
  const tooltipPartial = useTooltipUID();

  return (
    <LabelContainer {...rest}>
      {status === SELECTED_ITEMS_STATUS.none && (
        <React.Fragment>
          <DashboardCheckboxBox {...tooltipNone.targetProps} />
          <Tooltip id={tooltipNone.uid}>Select All</Tooltip>
        </React.Fragment>
      )}
      {status === SELECTED_ITEMS_STATUS.all && (
        <React.Fragment>
          <DashboardCheckboxBox checked {...tooltipAll.targetProps}>
            <DashboardCheckmark />
          </DashboardCheckboxBox>
          <Tooltip id={tooltipAll.uid}>Deselect All</Tooltip>
        </React.Fragment>
      )}
      {status === SELECTED_ITEMS_STATUS.partial && (
        <React.Fragment>
          <DashboardCheckboxBox partial {...tooltipPartial.targetProps}>
            <PartialDash />
          </DashboardCheckboxBox>
          <Tooltip id={tooltipPartial.uid}>Select All</Tooltip>
        </React.Fragment>
      )}
    </LabelContainer>
  );
};

SelectorIndicator.propTypes = {
  status: PropTypes.string,
};

const DashboardCheckboxInput = (props) => {
  const { checked } = props;

  return (
    // data-testid allows getting checkboxes without identifiers in RTL test cases
    <LabelContainer checked={checked} data-testid="input-checkbox">
      <Input type="checkbox" {...props} />
      <DashboardCheckboxBox checked={checked}>
        {checked && <DashboardCheckmark />}
      </DashboardCheckboxBox>
    </LabelContainer>
  );
};

DashboardCheckboxInput.propTypes = {
  checked: PropTypes.bool,
};

class SelectorColumn extends React.Component {
  onChange = () => {
    const { item, isSelected, addToSelectedItems, removeFromSelectedItems } = this.props;

    if (isSelected) {
      removeFromSelectedItems(item);
    } else {
      addToSelectedItems(item);
    }
  };

  render = () => {
    const { id, isSelected } = this.props;

    return (
      <Column>
        {() => <DashboardCheckboxInput value={id} onChange={this.onChange} checked={isSelected} />}
      </Column>
    );
  };
}

SelectorColumn.propTypes = {
  id: PropTypes.number,
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  addToSelectedItems: PropTypes.func,
  removeFromSelectedItems: PropTypes.func,
};

export default SelectorColumn;
