import styled from 'styled-components';

import MediaPoint from 'shared/components/MediaPoint';

export const PeopleFilterPageContentManagerWrapper = styled.section`
  max-width: 100%;
  margin-top: 24px;

  ${MediaPoint.MobileOnly} {
    padding-right: 0;
  }
`;

export const PeopleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  ${MediaPoint.MobileOnly} {
    padding: 0;
  }
`;

export const PeopleHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

export const PeopleHeaderActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;

  ${MediaPoint.MobileOnly} {
    margin-top: 8px;
    margin-left: 0;
    align-items: end;
  }
`;

export const PeopleHeaderWrapper = styled.div`
  margin: 48px 0 40px 0;
`;

export const PeopleSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PeopleNotFoundMessage = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 25px;
`;

export const PeopleCardList = styled.div`
  margin: 24px -10px -10px; /* -10px = from MentorCard margin */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${MediaPoint.Tablet} {
    justify-content: flex-start;
    margin: 24px -10px -10px; /* -10px = from MentorCard margin */
  }
`;

export const PeopleLoadMoreButtonWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
`;
