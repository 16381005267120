import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import InputLabel from 'inputs/components/InputLabel';
import colors from 'services/colors';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { useTooltipUID } from 'shared/hooks';
import { get, has, includes, isNil } from 'vendor/lodash';

import TemplatePreview from '../TemplatePreview';

import APITokenField from './APITokenField';
import IcalLinkField from './IcalLinkField';
import SettingDatetimeOffsetField from './SettingDatetimeOffsetField';
import SettingLongTemplateField from './SettingLongTemplateField';
import SettingMediumTemplateField from './SettingMediumTemplateField';
import SettingPasswordField from './SettingPasswordField';
import SettingRichTextTemplateField from './SettingRichTextTemplateField';
import SettingSelectField from './SettingSelectField';
import SettingShortTemplateField from './SettingShortTemplateField';
import SettingShortTextField from './SettingShortTextField';
import SettingSlackButtonField from './SettingSlackButtonField';
import SettingTextAreaField from './SettingTextAreaField';
import SettingToggleField from './SettingToggleField';
import SettingTokenField from './SettingTokenField';

const SettingContainer = styled.div``;

const BoxContainer = styled.div`
  border: 1px solid ${({ hasError }) => (hasError ? colors.error600 : colors.neutral0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const FormFieldLabelWrapper = styled.div`
  flex: 1;
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  justify-content: end;

  > div:first-child {
    width: 100%;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const Description = styled(Text)`
  margin-top: 4px;
`;

const settingTypeComponentMap = {
  bool: {
    toggle: SettingToggleField,
    default: SettingToggleField,
  },
  list: {
    short_text: SettingShortTextField,
  },
  str: {
    short_text: SettingShortTextField,
    short_template: SettingShortTemplateField,
    password: SettingPasswordField,
    long_text: SettingTextAreaField,
    medium_template: SettingMediumTemplateField,
    long_template: SettingLongTemplateField,
    rich_text_template: SettingRichTextTemplateField,
    default: SettingShortTextField,
    slack_button: SettingSlackButtonField,
    selection: SettingSelectField,
    api_token: APITokenField,
    ical_feed_url: IcalLinkField,
  },
  int: {
    number: SettingShortTextField,
    selection: SettingSelectField,
    datetime_offset: SettingDatetimeOffsetField,
    default: SettingSelectField,
  },
  float: {
    number: SettingShortTextField,
    selection: SettingSelectField,
  },
  default: { default: null },
  datetime: {
    short_text: SettingShortTextField,
  },
  password: {
    password: SettingPasswordField,
  },
  token: {
    token_input: SettingTokenField,
  },
};

const settingResetValueMethodMap = {
  str: {
    rich_text_template: (isChildLevelSetting, input, callback) => {
      const newValue = isChildLevelSetting ? input.value.parent_value : input.value.default;
      // Refresh workaround
      // Forces the SettingRichTextTemplateField component to re-renderize once by sending an undefined value to it,
      // re-rendering the component content successfully. setTimeout without a value will
      // trigger the re-render in the next rendering cycle.
      // Side-effect: the page flickers a bit due to the component vanishing then popping back up
      callback({
        ...input.value,
        value: undefined,
        inherited: true,
      });
      setTimeout(() =>
        callback({
          ...input.value,
          value: newValue,
          inherited: true,
        })
      );
    },
  },
};

const checkCanResetValue = (input, settingInputType, settingDefault, isChildLevelSetting) => {
  const isChildSettingWithValueOverride = isChildLevelSetting && !input.value.inherited;
  if (isChildLevelSetting) return isChildSettingWithValueOverride;

  const isInputTypeTemplate = includes(
    ['short_template', 'medium_template', 'long_template', 'rich_text_template'],
    settingInputType
  );
  const isResettableSystemLevelSettingWithValueOverride = isInputTypeTemplate
    ? get(input, 'value.value.0') === ''
    : get(input, 'value.value') !== settingDefault;

  return isResettableSystemLevelSettingWithValueOverride;
};

const NotFoundComponent = <InfoBox type="warning" content="Input type is under development." />;

const SettingField = ({ input, meta, setting, inputPosition, handleInputChange }) => {
  const {
    type,
    input: settingInputType,
    access,
    options,
    default: settingDefault,
    ...settingsExtra
  } = setting;

  const Component = get(
    settingTypeComponentMap,
    `${type || 'default'}.${settingInputType || 'default'}`,
    null
  );

  const handleResetValueClick = get(
    settingResetValueMethodMap,
    `${type || 'default'}.${settingInputType || 'default'}`,
    (isChildLevelSetting, input, callback) => {
      const newValue = isChildLevelSetting ? input.value.parent_value : input.value.default;
      callback({
        ...input.value,
        value: newValue,
        inherited: true,
      });
    }
  );

  const hasError = meta.touched && meta.error;

  const isChildLevelSetting = has(input.value, 'parent_value');

  const canResetValue = checkCanResetValue(
    input,
    settingInputType,
    settingDefault,
    isChildLevelSetting
  );

  const showPills = !!handleInputChange;
  const defaultSettingTooltip = useTooltipUID();
  const customSettingTooltip = useTooltipUID();
  const showPreviewButton = includes(
    ['short_template', 'medium_template', 'long_template'],
    settingInputType
  );

  if (
    includes(
      [
        'long_text',
        'datetime_offset',
        'short_template',
        'medium_template',
        'long_template',
        'rich_text_template',
      ],
      settingInputType
    )
  ) {
    inputPosition = 'bottom';
  }

  const inputValue = !isNil(input.value.value) ? Math.abs(input.value.value) : null;
  return (
    <SettingContainer>
      <BoxContainer hasError={hasError}>
        <FormFieldLabelWrapper>
          <InputLabel htmlFor={input.name}>{setting.label}</InputLabel>
        </FormFieldLabelWrapper>
        {Component && (
          <ComponentWrapper>
            {inputPosition === 'right' && (
              <Component
                ariaLabel={setting.label}
                input={input}
                type={type}
                meta={meta}
                options={options}
                disabled={access === 'read_only'}
                settingDefault={settingDefault}
                {...settingsExtra}
              />
            )}
            {(showPreviewButton || showPills) && (
              <ActionsWrapper>
                {showPreviewButton && (
                  <TemplatePreview
                    template={get(input, 'value.value.1')}
                    settingLabel={setting.label}
                    templateName={get(input, 'value.default.0')}
                  />
                )}
                {showPills && !canResetValue && (
                  <>
                    <Tooltip id={defaultSettingTooltip.uid}>Default Setting</Tooltip>
                    <Pill {...defaultSettingTooltip.targetProps} icon="settings-checkmark" round />
                  </>
                )}
                {showPills && canResetValue && (
                  <>
                    <Tooltip id={customSettingTooltip.uid}>Reset to Default</Tooltip>
                    <Pill
                      {...customSettingTooltip.targetProps}
                      icon="reset"
                      round
                      variant="error200"
                      onClick={() =>
                        handleResetValueClick(isChildLevelSetting, input, handleInputChange)
                      }
                    />
                  </>
                )}
              </ActionsWrapper>
            )}
          </ComponentWrapper>
        )}
      </BoxContainer>
      {setting.description && (
        <Description size="h5" color={colors.neutral600}>
          <ReactMarkdown linkTarget="_blank">{setting.description}</ReactMarkdown>
        </Description>
      )}
      {inputPosition === 'bottom' && Component && (
        <Component
          ariaLabel={setting.label}
          input={input}
          type={type}
          meta={meta}
          options={options}
          disabled={access === 'read_only'}
          settingDefault={settingDefault}
          {...settingsExtra}
        />
      )}
      {!Component && NotFoundComponent}
      {hasError && (
        <ErrorMessageContainer>
          <Text size="h5" color={colors.error600}>
            {meta.error}
          </Text>
        </ErrorMessageContainer>
      )}
      {input.name.match(/notification/) && setting.type === 'int' && inputValue !== null && (
        <InfoText content="Up to 15-minute delay" top={4} />
      )}
    </SettingContainer>
  );
};

SettingField.defaultProps = {
  inputPosition: 'right',
  handleInputChange: undefined,
};

SettingField.propTypes = {
  handleInputChange: PropTypes.func,
  input: PropTypes.object,
  meta: PropTypes.object,
  setting: PropTypes.object,
  inputPosition: PropTypes.oneOf(['bottom', 'right']),
};

export default SettingField;
