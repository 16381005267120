import PropTypes from 'prop-types';

const anyChildren = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
]);

const nodeOrList = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

const listItem = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  color: PropTypes.string,
  icon: PropTypes.string,
  mdiIcon: PropTypes.string, // This is deprecated, do not use!
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  anyChildren,
  nodeOrList,
  listItem,
};
