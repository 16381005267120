import { FilterDef } from 'filters/components/RQLFilterBar/RQLFilterBar';
import { FiltersDefinitionProps, FiltersDefinition } from 'filters/types';
import { getFlexibleFiltersDef, getOrderedFiltersDefinition } from 'filters/utils';
import { useCurrentUser } from 'shared/hooks';
import { get, reduce, toString } from 'vendor/lodash';
import { useMediaQuery } from 'vendor/mui';

export const usePeopleDirectoryFiltersDefinition = ({
  filters,
  ordering,
  updateFilter,
  setOrdering,
  fixedFilters,
}: FiltersDefinitionProps): FiltersDefinition => {
  const currentUser = useCurrentUser();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const defaultFiltersOrder = [
    'SearchBar',
    'Title',
    'Department',
    'Sort',
    'Location',
    'Groups',
    'Org Lead',
    'Manager',
    'Teammate',
  ];

  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by name, title, skills...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    Title: {
      type: 'model_select',
      label: 'Title',
      value: get(filters, 'title', null),
      multiple: true,
      sortOptions: true,
      queryName: 'users.titles',
      onChange: (newValue) => updateFilter({ title: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Department: {
      type: 'model_select',
      label: 'Department',
      value: get(filters, 'department', null),
      multiple: true,
      sortOptions: true,
      queryName: 'users.departments',
      fetchQueryOnce: true,
      onChange: (newValue) => updateFilter({ department: newValue }),
    },
    Sort: {
      type: 'select',
      label: 'Sort by',
      value: { $eq: ordering },
      options: [
        { name: 'Impact', value: '-unique_people_impacted_count' },
        { name: 'Name (A-Z)', value: 'name' },
        { name: 'Name (Z-A)', value: '-name' },
        { name: 'Title', value: 'title' },
        { name: 'Department', value: 'department' },
        { name: 'Location', value: 'location_text' },
      ],
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
      onChange: (newValue) => setOrdering(get(newValue, '$eq', 'name') as string),
    },
    Location: {
      type: 'search_bar',
      label: 'Location',
      value: get(filters, 'location_text', null),
      sortByOption: 'location_text',
      sortOptions: true,
      queryName: 'locations',
      onChange: (newValue) => updateFilter({ location_text: newValue }),
    },
    Groups: {
      type: 'model_select',
      label: 'Groups',
      value: get(filters, 'group', null),
      multiple: true,
      sortOptions: true,
      queryName: 'groups',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ group: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    OrgLead: {
      type: 'model_select',
      label: 'Org lead',
      value: get(filters, 'org_lead', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      queryParams: { has_directs: true },
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ org_lead: newValue }),
    },
    Manager: {
      type: 'model_select',
      label: 'Manager',
      value: get(filters, 'manager', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      queryParams: { has_directs: true },
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ manager: newValue }),
    },
    Teammate: {
      type: 'model_select',
      label: 'Teammate',
      value: get(filters, 'teammate', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      queryParams: { has_manager: true },
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ teammate: newValue }),
    },
    'My Audience': {
      type: 'boolean',
      label: 'My audience',
      value: get(filters, 'my_audience', null),
      onChange: (newValue) => updateFilter({ my_audience: newValue }),
    },
    'My Attendance': {
      type: 'boolean',
      label: 'My attendance',
      value: get(filters, 'my_attendance', null),
      onChange: (newValue) => updateFilter({ my_attendance: newValue }),
    },
    'My Experts': {
      type: 'boolean',
      label: 'My experts',
      value: get(filters, 'my_experts', null),
      onChange: (newValue) => updateFilter({ my_experts: newValue }),
    },
    ...reduce(
      getFlexibleFiltersDef({
        filters,
        updateFilter,
        currentUser,
        toggleTypes: ['toggle_mentorship'],
      }),
      (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
      {}
    ),
  };

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_people',
    defaultFiltersOrder,
    allFilters,
    fixedFilters,
    filterWidth: '100%',
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};
