import PropTypes from 'prop-types';
import React from 'react';

import SectionInfoPanel from 'shared/components/NewContentForm/SectionInfoPanel';

const SettingContextTopLevelBlock = ({ settingLabel, children }) => {
  return (
    <div>
      <SectionInfoPanel title={settingLabel} />
      <br />
      {children}
    </div>
  );
};

SettingContextTopLevelBlock.propTypes = {
  settingLabel: PropTypes.string,
  children: PropTypes.node,
};

export default SettingContextTopLevelBlock;
