import PropTypes from 'prop-types';
import React from 'react';
import { Field, Fields, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { prepareSettingsValuesForSubmission } from 'app/settings/services';
import { CHANNEL } from 'catalog/constants';
import * as constants from 'catalog/constants';
import actions from 'entities/actions';
import ChannelSelectField from 'inputs/components/ChannelSelectField';
import CheckboxField from 'inputs/components/CheckboxField';
import CoverImageField from 'inputs/components/CoverImageField';
import DurationField, { validateDuration } from 'inputs/components/DurationField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import GroupSelectField from 'inputs/components/GroupSelectField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TagTypesInputFields from 'inputs/components/TagTypesInputFields';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import { useSettingsSectionsList } from 'settings/hooks';
import { HR } from 'shared';
import { FormFieldGroup } from 'shared/components/Form';
import InfoText from 'shared/components/InfoText';
import NewContentForm from 'shared/components/NewContentForm';
import AccessControlSection from 'shared/components/NewContentForm/sections/AccessControlSection';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import {
  useCurrentUser,
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useChannelToggle,
} from 'shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from 'topics/services';
import { concat, get, isNil, pick, toLower, isEmpty, map, compact } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

const CoverContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CapFieldWrapper = styled.div`
  width: 250px;
`;

const validateRequired = Form.validations.required();
const validateLength = Form.validations.maxLength(255);

export const MentorshipProgramForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  settingsContext,
}) => {
  const currentUser = useCurrentUser();
  const labels = useLabels();
  const labelProgram = labels.label_mentorship_program;
  const labelProgramPlural = labels.label_mentorship_program_plural;
  const labelInternalGroups = labels.label_internal_groups;
  const labelChannel = labels.label_channel;

  useFormPreventTransition(form);

  const [settingsSectionsList] = useSettingsSectionsList(settingsContext, form);
  const programName = useFormSelector(form, 'name');

  const canAddChannelContent = useChannelToggle();
  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  const programSectionsList = [
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: 'program-details',
      label: `Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              label="Title"
              required
              name="name"
              component={TextField}
              placeholder={`Give your ${toLower(labelProgram)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="program_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field name="content_body" component={TextEditorField} />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              label="Expected Duration (H:MM)"
              inputId="duration"
              name="duration"
              component={DurationField}
              validate={[validateDuration]}
              disableClearable={false}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              label="Session Discussion Topics"
              name="session_topics"
              component={TextField}
              placeholder="Optional comma separated list of topics to discuss"
              validate={[validateLength]}
            />
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_coaching']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_coaching']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <FacilitatorField
          label={`${labelProgram} manager(s)`}
          infoText="Can edit details, assign other people as hosts, create, edit and delete sessions."
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'booking-capacity',
      label: 'Booking Capacity',
      icon: 'ticket',
      section: (
        <FormFieldGroup>
          <CapFieldWrapper>
            <Field
              name="attendance_limit"
              component={TextField}
              label="Session Cap"
              type="number"
              inputProps={{
                min: 0,
              }}
              placeholder="Unlimited"
            />
          </CapFieldWrapper>
        </FormFieldGroup>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <>
          <FormFieldGroup>
            <AccessControlSection
              showHideEnrolleesSection={false}
              contentNameSingular={labelProgram}
              accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
              hideEnrolleesName="is_session_calendar_event_private"
            />
          </FormFieldGroup>
          <HR margin="20px 0" />
          <FormFieldGroup>
            <InfoText
              top={4}
              content={
                <Text block size="h5" color="neutral.main">
                  • If set, only users in the groups selected will be able to join this{' '}
                  {labelProgram} as mentors.
                </Text>
              }
            />
            <Field
              label={`Selected Mentor ${toLower(labelInternalGroups)}`}
              infoText="Only people in this groups can join as a mentor of this program"
              inputId="mentor_groups"
              name="mentor_groups_ids"
              component={GroupSelectField}
              multiple
            />
            <InfoText
              top={4}
              content={
                <Text block size="h5" color="neutral.main">
                  • If set, only users in the groups selected will be able to join this{' '}
                  {labelProgram} as mentees.
                </Text>
              }
            />
            <Field
              label={`Selected Mentee ${toLower(labelInternalGroups)}`}
              infoText="Only people in this groups can join as a mentor of this program"
              inputId="mentee_groups"
              name="mentee_groups_ids"
              component={GroupSelectField}
              multiple
            />
          </FormFieldGroup>
          <HR margin="20px 0" />
          <FormFieldGroup>
            <Field
              name="is_session_calendar_event_private"
              type="checkbox"
              component={CheckboxField}
              label="Sessions are Private on Google Calendar"
              infoText="Sessions in this program appear as private calendar events (only visible to attendees)."
              labelSize="h5"
              labelWeight="medium"
            />
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen:
          initialValues.is_hidden ||
          initialValues.is_session_calendar_event_private ||
          !isEmpty(initialValues.groups_ids) ||
          !isEmpty(initialValues.mentor_groups_ids) ||
          !isEmpty(initialValues.mentee_groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  const infoPanelText = `${labelProgramPlural} are a home for mentors and mentees gathered around a particular mission like welcoming women in tech or mentoring new managers.`;
  return (
    <NewContentForm
      contentNameSingular={labelProgram}
      contentInfoPanelText={infoPanelText}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={programName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={programSectionsList}
      advancedSettingsList={advancedSettingsList}
    />
  );
};

MentorshipProgramForm.defaultProps = {
  initialValues: { mentorship: true },
};

MentorshipProgramForm.propTypes = {
  form: PropTypes.string,
  settingsContext: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
};

const ConnectedMentorshipProgramForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, { isEdit, formName, currentUser, initialValues = {} }) => {
    const actionStore = actions.mentorshipProgram;
    const actionName = actionStore[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const payload = {
        ...pick(values, [
          'name',
          'content_body',
          'cover',
          'topics',
          'facilitators_ids',
          'attendance_limit',
          'is_hidden',
          'hide_attendees',
          'groups_ids',
          'mentor_groups_ids',
          'mentee_groups_ids',
          'is_session_calendar_event_private',
          'duration',
          'session_topics',
          'channel_id',
        ]),
      };

      if (!payload.channel_id) {
        delete payload.channel_id;
      }

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_coaching'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const topics = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];
      payload.tags = concat(topics, customTopicsList);

      payload.facilitators = map(payload.facilitators_ids, (id) => ({
        user_id: id,
        role: constants.FACILITATOR_ROLES.main_organizer,
      }));

      if (!values.attendance_limit) {
        // Sets the attendance_limit to unlimited.
        payload.attendance_limit = 0;
      }

      if (!values.duration) {
        payload.duration = '00:00:00';
      }

      // Remove falsey values
      payload.mentor_groups_ids = compact(payload.mentor_groups_ids);
      payload.mentee_groups_ids = compact(payload.mentee_groups_ids);
      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);
      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },

  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(MentorshipProgramForm);

export default ConnectedMentorshipProgramForm;
