import { CONTENT_TYPES } from 'app/catalog/constants';
import {
  Widget,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
  WidgetContent,
} from 'common/components/widget';
import { useWidgetQuery } from 'common/hooks/useWidgetQuery';
import { Session } from 'common/types';
import { Event } from 'event/interfaces';
import SessionWidgetRow from 'features/sessions/components/SessionWidgetRow';
import { mapRoute } from 'services/requests';
import { map, get, size } from 'vendor/lodash';

import {
  EventsWidgetLoadingContent,
  EvetnsWidgetEmptyContent,
  EventWidgetRow,
} from '../components/BaseEventsAsyncWidget';

function useMyUpcomingLiveWidgetQuery(search: string) {
  return useWidgetQuery<Event | Session>('my_upcoming', search);
}

type MyUpcomingLiveWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MyUpcomingLiveWidget(props: MyUpcomingLiveWidgetProps) {
  const { hideOnEmpty = false } = props;

  const search = 'ordering(start_time)';

  const { data, isLoading } = useMyUpcomingLiveWidgetQuery(search);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  const viewAllUrl = `${mapRoute('myUpcoming')}?${search}`;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="My Upcoming Live"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : null
      }
    >
      {isLoading || displayResults ? (
        <WidgetContent>
          <WidgetContentList>
            {displayResults ? (
              map(results, (row) => (
                <WidgetContentListItem key={row.id}>
                  {row.content_type === CONTENT_TYPES.event ? (
                    <EventWidgetRow event={row as Event} />
                  ) : (
                    <SessionWidgetRow
                      // Very old session components are expecting the list of attendees in the session itself, instead of using the enrollments field.
                      session={
                        { ...row, attendees: map(get(row, 'enrollments'), 'attendee') } as Session
                      }
                    />
                  )}
                </WidgetContentListItem>
              ))
            ) : (
              <EventsWidgetLoadingContent />
            )}
          </WidgetContentList>
        </WidgetContent>
      ) : (
        <EvetnsWidgetEmptyContent label={"You don't have upcoming live events"} />
      )}
    </Widget>
  );
}
