import React from 'react';

import TopicsFilter from 'navigation/components/FilterBar/TopicsFilter';
import {
  isEmpty,
  concat,
  filter,
  get,
  includes,
  difference,
  isNil,
  some,
  map,
  every,
  isArray,
  forEach,
  reduce,
  values,
} from 'vendor/lodash';

export const getTags = (topics, mainTopics, flexibleTags) =>
  difference(topics, concat(mainTopics, ...values(flexibleTags)));

export const checkTagHasLabelAndItems = (tagType) =>
  !isEmpty(tagType.label) && !isEmpty(tagType.items);

// Should show tag if at least one toggle type is true if not strict, or if all type is true if strict
export const shouldShowTagType = (
  tagTypeObject,
  toggleTypes,
  strict = false,
  allowedTagTypes = []
) => {
  if (!isEmpty(allowedTagTypes) && !includes(allowedTagTypes, tagTypeObject?.filter_field_name))
    return false;

  if (isArray(toggleTypes)) {
    if (strict) {
      const arr = map(toggleTypes, (type) => tagTypeObject[type]);
      return !isEmpty(arr) && every(arr);
    }
    return isEmpty(toggleTypes) || some(map(toggleTypes, (type) => tagTypeObject[type]));
  }

  return tagTypeObject[toggleTypes];
};

export const buildFlexibleTagsList = (user, toggleTypes, strict = false, allowedTagTypes = []) =>
  filter(user?.custom_tags?.flexible_filter_tags, (flexibleTag) =>
    shouldShowTagType(flexibleTag, toggleTypes, strict, allowedTagTypes)
  ) || [];

export const buildFlexibleTagsFieldNameList = (
  user,
  toggleTypes = [],
  strict = false,
  allowedTagTypes = []
) =>
  map(
    buildFlexibleTagsList(user, toggleTypes, strict, allowedTagTypes),
    (flexibleTag) => flexibleTag.filter_field_name
  );

export const shouldShowCustomFilter = (currentUser, tagType, toggleType) => {
  const customTags = get(currentUser, 'custom_tags');
  if (!customTags || !customTags.flexible_filter_tags) return false;

  let response = false;
  forEach(customTags.flexible_filter_tags, (tags) => {
    if (tags.filter_field_name === tagType) {
      response = tags[toggleType] && checkTagHasLabelAndItems(tags);
    }
  });
  return response;
};

export const buildFlexibleFiltersWidget = ({
  currentUser,
  toggleTypes,
  setSelectedTopics,
  params = {
    toggleHide: false,
    show: true,
    searchParam: null,
    isOpen: true,
  },
  allowedTagTypes = [],
}) => {
  return map(
    buildFlexibleTagsList(currentUser, toggleTypes, true, allowedTagTypes),
    (flexibleTag) => ({
      [`${flexibleTag.filter_field_name}`]: {
        title: flexibleTag.label,
        hidesWhen: params?.toggleHide,
        show: params?.show,
        isOpen: params?.isOpen,
        icon: 'tag',
        component: (
          <TopicsFilter
            key={flexibleTag.filter_field_name}
            filterType={flexibleTag.filter_field_name}
            param={params.searchParam ? params.searchParam : flexibleTag.filter_field_name}
            label={flexibleTag.label}
            setSelectedTopics={setSelectedTopics}
          />
        ),
      },
    })
  );
};

export const buildCustomTopicsList = ({ user, toggleTypes, tags, flexibleTags }) => {
  let customTopicsList = reduce(
    filter(
      user?.custom_tags?.flexible_filter_tags,
      (flexibleTag) =>
        shouldShowTagType(flexibleTag, toggleTypes) &&
        !isEmpty(flexibleTags[flexibleTag.filter_field_name]) &&
        !isNil(flexibleTags[flexibleTag.filter_field_name][0])
    ),
    (tempList, flexibleTag) => concat(tempList, flexibleTags[flexibleTag.filter_field_name]),
    []
  );

  if (!isEmpty(tags) && !isNil(tags[0])) {
    customTopicsList = concat(customTopicsList, tags);
  }
  return customTopicsList;
};

export const sortCustomTags = (currentUser, initialValues, initialValuesParam) => {
  const customTags = currentUser.custom_tags;

  const flexibleTags = reduce(
    customTags?.flexible_filter_tags,
    (tempTags, flexibleTag) => ({
      ...tempTags,
      [flexibleTag.filter_field_name]: filter(flexibleTag.items, (item) =>
        includes(initialValues[initialValuesParam], item)
      ),
    }),
    {}
  );

  const topics = filter(initialValues[initialValuesParam], (param) =>
    includes(get(customTags, 'main_topics.items', []), param)
  );
  const tags = getTags(initialValues[initialValuesParam], topics, flexibleTags);

  return { ...flexibleTags, topics, tags };
};

export const getTagType = (currentUser, tag) => {
  const customTags = currentUser.custom_tags;

  const flexibleTags = get(customTags, 'flexible_filter_tags', []);

  for (const flexibleTag of flexibleTags) {
    if (includes(flexibleTag.items, tag)) {
      return flexibleTag.filter_field_name;
    }
  }

  const topics = get(customTags, 'main_topics.items', []);
  if (includes(topics, tag)) {
    return 'topics';
  }
  return 'tags';
};

export const getTagLabel = (currentUser, tag) => {
  const customTags = currentUser.custom_tags;

  const flexibleTags = get(customTags, 'flexible_filter_tags', []);

  for (const flexibleTag of flexibleTags) {
    if (includes(flexibleTag.items, tag)) {
      return flexibleTag.label;
    }
  }

  return '';
};

export const getUserSkillsWithLabelAndItems = ({ currentUser, skills, allowedTagTypes = [] }) => {
  const formattedSkills = {};

  forEach(skills, (skill) => {
    const skillType = getTagType(currentUser, skill);
    const skillLabel = getTagLabel(currentUser, skill);

    if (!isEmpty(allowedTagTypes) && !includes(allowedTagTypes, skillType)) return;

    if (skillType in formattedSkills) {
      formattedSkills[skillType].items.push(skill);
    } else {
      formattedSkills[skillType] = { label: skillLabel, items: [skill] };
    }
  });

  return formattedSkills;
};
