import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React from 'react';

const PopperMenu = ({ inputRef, open, children, onToggle, sx }) => {
  const handleClose = (event) => {
    if (inputRef?.current?.contains(event.target)) {
      return;
    }

    onToggle(false);
  };

  return (
    <Popper
      open={open}
      anchorEl={inputRef.current}
      role={undefined}
      transition
      placement="bottom-start"
      disablePortal
      sx={{ zIndex: 1, ...sx }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'left top',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
              {children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

PopperMenu.defaultProps = {
  sx: {},
};

PopperMenu.propTypes = {
  children: PropTypes.node,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  inputRef: PropTypes.object,
  // Due to old components in the application, sometimes is necessary to add a different zIndex or another style prop.
  sx: PropTypes.object,
};

export default PopperMenu;
