import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';

import MenteeSelectField from 'app/inputs/components/MenteeSelectField';
import MentorSelectField from 'app/inputs/components/MentorSelectField';
import { ContentItem } from 'app/shared-content-item/interfaces';
import { FormFieldGroup } from 'app/shared/components/Form';
import { User } from 'app/shared/components/types';
import { useCalendarResourcesForSessionsEffect } from 'app/users/hooks';
import actions from 'entities/actions';
import PeopleGroupsMultiselectField from 'inputs/components/PeopleGroupsMultiselectField';
import { toast } from 'notifications/components/NotificationCenter';
import { getMentorshipSessionSubmitBody } from 'program/components/SessionForm/SessionFormHelpers';
import { ATTENDANCE_TYPE_SINGLE_ATTENDEE } from 'program/constants';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import NewContentForm from 'shared/components/NewContentForm';
import SessionEnrollmentCapSection from 'shared/components/NewContentForm/sections/SessionEnrollmentCapSection';
import SessionLocationSection from 'shared/components/NewContentForm/sections/SessionLocationSection';
import Form from 'shared/components/OldForm';
import { useFormPreventTransition, useFormSelector, useLabels, useQueryParams } from 'shared/hooks';
import { CREATE_SESSION_FOR_OTHERS_PERMISSION } from 'shared/permissions';
import { isNil, get, includes, isEmpty, toLower, replace } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

interface ValidationError {
  program_id?: string;
  host_id?: string;
  enrollment_methods?: string;
  attendance_type?: string;
  starts_at?: string;
}

interface MentorshipSessionFormProps {
  content: ContentItem;
  form: string;
  invalid: boolean;
  error: ValidationError;
  submitting: boolean;
  handleSubmit: () => void;
  touch: () => void;
  change: (field: string, value: unknown) => void;

  isEdit: boolean;
  isDuplicating: boolean;
  isPrefillingWithLastSession: boolean;
  showPrefillBox: boolean;
  prefilledValuesCleaned: boolean;
  cleanPrefilledValues: () => void;
  undoCleanPrefilledValues: () => void;

  breadcrumbsItemList: object[];

  contentName: string;
  topBarActionName: string;
  backRoute: string;
}

const validateRequired = Form.validations.required();

const validate = (values, { locations }) => {
  const errors: ValidationError = {};

  if (isNil(values.program_id)) {
    errors.program_id = 'This field is required.';
  }

  if (isNil(values.host_id)) {
    errors.host_id = 'This field is required.';
  }

  if (!values.allows_local && !values.allows_online) {
    errors.enrollment_methods = 'Please select at least one enrollment method below.';
  }

  if (values.attendee_id && values.allows_local && values.allows_online) {
    errors.enrollment_methods =
      'Sessions with pre selected mentee can only have one enrollment method.';
  }

  if (!values.attendance_type) {
    errors.attendance_type = 'An attendance type must be selected.';
  }

  const timezone = locations[values.location_id]?.timezone;

  if (
    values.starts_at &&
    timezone &&
    moment.tz(timezone).isSameOrAfter(moment.tz(replace(values.starts_at, 'Z', ''), timezone))
  ) {
    errors.starts_at = 'The start date and time cannot be in the past.';
  }

  return errors;
};

const checkCanCreateForOthers = (content) => {
  const programPermissions = content ? get(content, 'permissions') : [];
  return includes(programPermissions, CREATE_SESSION_FOR_OTHERS_PERMISSION);
};

export const MentorshipSessionForm = ({
  content,
  form,
  invalid,
  handleSubmit,
  error,
  submitting,
  contentName,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  touch,
  change,
  isDuplicating,
  isPrefillingWithLastSession,
  showPrefillBox,
  prefilledValuesCleaned,
  cleanPrefilledValues,
  undoCleanPrefilledValues,
}: MentorshipSessionFormProps) => {
  useCalendarResourcesForSessionsEffect();
  const { from_previous: fromPrevious } = useQueryParams();
  const {
    label_mentorship_sessions_creator_title_single: labelMentorshipSessionCreatorTitleSingle,
    label_mentorship_sessions_attendee_title_single: labelMentorshipSessionAttendeeTitleSingle,
    label_internal_groups: labelInternalGroups,
  } = useLabels();

  useFormPreventTransition(form);

  const fieldsValues = {
    allows_online: useFormSelector(form, 'allows_online'),
    location_id: useFormSelector(form, 'location_id'),
    is_available_to_other_locations: useFormSelector(form, 'is_available_to_other_locations'),
    groups_ids: useFormSelector(form, 'groups_ids'),
  };

  const saveAndDuplicate = useFormSelector(form, 'save_and_duplicate');
  const saveAndBulkDuplicate = useFormSelector(form, 'save_and_bulk_duplicate');

  const getInfoPanelText =
    'Once you are a Mentor, you can create individual sessions to book time with the people at your organization.';

  const getInfoBox = () => {
    if (!showPrefillBox) return undefined;

    if (!prefilledValuesCleaned) {
      return (
        <InfoBox
          content={`Please notice this session has been prefilled with the data of ${
            isDuplicating ? 'an existing session' : 'your latest created session'
          }. You can clear the form values to start from scratch.`}
          actionText="Clear"
          onClick={cleanPrefilledValues}
        />
      );
    }

    return (
      <InfoBox
        content="Prefilled data was cleared."
        actionText="Prefill Again"
        onClick={undoCleanPrefilledValues}
      />
    );
  };

  const onSaveDuplicateHandler = (field) => {
    if (invalid) {
      handleSubmit();
    } else {
      change(field, true);
    }
  };

  // Get the updated value of the field after its change on onSaveDuplicateHandler
  useEffect(() => {
    if (saveAndDuplicate || saveAndBulkDuplicate) {
      handleSubmit();
    }
  }, [saveAndDuplicate, saveAndBulkDuplicate, handleSubmit]);

  useEffect(() => {
    if (!content || isEdit || isDuplicating || isPrefillingWithLastSession) return;

    const attendanceType = ATTENDANCE_TYPE_SINGLE_ATTENDEE;

    change('attendance_type', attendanceType);
  }, [content, isEdit, isDuplicating, isPrefillingWithLastSession, change]);

  const sectionsList = [
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <>
          <Field component="input" type="hidden" name="save_and_duplicate" />
          <Field component="input" type="hidden" name="save_and_bulk_duplicate" />

          <FormFieldGroup>
            <Field
              name="host_id"
              label={labelMentorshipSessionCreatorTitleSingle}
              required
              component={MentorSelectField}
              program_id={content.public_id}
              validate={[validateRequired]}
              disabled={!checkCanCreateForOthers(content)}
            />
            <InfoText
              top={4}
              bottom={4}
              content={`Only people set as a ${labelMentorshipSessionCreatorTitleSingle} can be selected.
                  Admins can define who is a ${labelMentorshipSessionCreatorTitleSingle} by accessing the person's
                  profile page and editing it.`}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="attendee_id"
              label={labelMentorshipSessionAttendeeTitleSingle}
              component={MenteeSelectField}
              program_id={content.public_id}
              placeholder={`Select the ${labelMentorshipSessionAttendeeTitleSingle} for this session`}
            />
            <InfoText
              top={4}
              bottom={4}
              content="If left blank, the session will be open to others to enroll."
            />
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
      },
    },
    {
      id: 'location',
      label: 'Location & Time',
      icon: 'calendar',
      section: <SessionLocationSection form={form} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'enrollment',
      label: 'Enrollment',
      icon: 'ticket',
      section: (
        <SessionEnrollmentCapSection
          fieldsValues={fieldsValues}
          form={form}
          touch={touch}
          isMentorshipSession
        />
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <FormFieldGroup>
          <Fields
            inputId="groups"
            label={`Restrict Session to ${toLower(labelInternalGroups)}`}
            names={['groups_ids']}
            component={PeopleGroupsMultiselectField}
            showGroups
          />
          <InfoText
            top={4}
            content={`This session will only be available for members of the selected
                  ${toLower(labelInternalGroups)}.`}
          />
        </FormFieldGroup>
      ),
      sectionProps: {
        defaultOpen: !isEmpty(fieldsValues.groups_ids),
      },
    },
  ];

  return (
    <NewContentForm
      contentNameSingular={contentName}
      contentInfoPanelText={getInfoPanelText}
      contentInfoBox={getInfoBox()}
      contentTitle={contentName}
      topBarActionName={topBarActionName}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      submitting={submitting}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={sectionsList}
      advancedSettingsList={advancedSettingsList}
      onSaveAndDuplicateHandler={() => onSaveDuplicateHandler('save_and_duplicate')}
      onSaveAndBulkDuplicateHandler={() => onSaveDuplicateHandler('save_and_bulk_duplicate')}
    />
  );
};

interface onSubmitProps {
  isEdit: boolean;
  form: any;
  currentUser: User;
  content: ContentItem;
  initialValues?: { public_id?: string };
}

const ConnectedSessionForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false, // Needs to be false. Otherwise, fields values will not prefill in case they are inside sections with defaultOpen as false.
  validate,
  onSubmit: (
    values,
    dispatch,
    { isEdit, form, currentUser, content, initialValues = {} }: onSubmitProps
  ) => {
    const canCreateForOthers = checkCanCreateForOthers(content);

    const action = actions.sessionMentorship;
    const actionName = action[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const body = getMentorshipSessionSubmitBody(values, canCreateForOthers, currentUser);
      body.program_id = content.public_id;

      return {
        ...(initialValues?.public_id && { id: initialValues?.public_id }),
        key: form,
        body,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (
    result,
    _dispatch,
    { onSubmitSuccessHandler, saveAndDuplicate, saveAndBulkDuplicate }
  ) => onSubmitSuccessHandler(result, saveAndDuplicate, saveAndBulkDuplicate),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Submission failed. Check the flagged fields and try again.');
  },
})(MentorshipSessionForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);

  const saveAndDuplicate = formSelector(state, 'save_and_duplicate');
  const saveAndBulkDuplicate = formSelector(state, 'save_and_bulk_duplicate');

  const locations = get(state, 'entities.locations');

  return {
    saveAndDuplicate,
    saveAndBulkDuplicate,
    locations,
  };
};

export default connect(mapStateToProps)(ConnectedSessionForm);
