import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { addMenteeToProgram } from 'data-fetching/mutations/mentorship';
import RQLFilterBar from 'filters/components/RQLFilterBar';
import { useRQLFilters } from 'filters/hooks';
import { useMentorshipMenteeFiltersDefinition } from 'mentorship/hooks';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import MentorshipRequestModal from 'scenes/UserProfile/UserTabs/MentorshipRequestModal';
import CardList from 'shared-cards/components/CardList';
import { ContentItem } from 'shared-content-item/interfaces';
import Button from 'shared/components/Button';
import { MenteeCard } from 'shared/components/Card';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import { User } from 'shared/components/types';
import { useCurrentUser } from 'shared/hooks';
import { isError, isEmpty, some, dropRight, map } from 'vendor/lodash';
import { Box, Stack } from 'vendor/mui';

import EmptyMessage from './EmptyMessage';

interface OfferMentorshipProps {
  handleOnClick: () => void;
}

const OfferMentorshipButton = ({ handleOnClick }: OfferMentorshipProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
      paddingBottom: '15px',
    }}
  >
    <Button variant="outlined" onClick={handleOnClick}>
      Offer to mentor
    </Button>
  </Box>
);

const handleOfferToMentorModal = (mentee, setMenteeForRequest, setShowOfferToMentorModal) => {
  return () => {
    setShowOfferToMentorModal(true);
    setMenteeForRequest(mentee);
  };
};

export interface MentorshipProgramMenteeListProps {
  program: ContentItem;
  canJoin: boolean;
}

const MentorshipProgramMenteeList = ({ program, canJoin }: MentorshipProgramMenteeListProps) => {
  const { public_id, id } = program;

  const [showOfferToMentorModal, setShowOfferToMentorModal] = useState<boolean>(false);
  const [menteeForRequest, setMenteeForRequest] = useState<User | null>(null);

  const currentUser = useCurrentUser();
  const { filters, updateFilter, resetFilters, rqlExpression } = useRQLFilters({
    initialFiltersState: {},
    initialOrdering: 'name',
  });

  const { filters: barFilters, moreFilters } = useMentorshipMenteeFiltersDefinition({
    filters,
    updateFilter,
  });

  const queryClient = useQueryClient();
  const {
    isLoading,
    isError: hasError,
    data: response,
    error,
  } = useQuery(queries.mentorship_program.mentees(public_id, rqlExpression));
  const people = map(response?.results, 'mentee');
  const joinMutation = useMutation({
    mutationFn: () => addMenteeToProgram(public_id, currentUser.id, id),
    onError: () => {
      toast.error(`Error: Unable to join ${program.name} as a mentee.`);
    },
    onSuccess: () => {
      toast.success(`Successfully joined ${program.name} as a mentee.`);
      queryClient.invalidateQueries({
        // We drop the filter from the query key list to invalidate all queries
        queryKey: dropRight(queries.mentorship_program.mentees(public_id, rqlExpression).queryKey),
      });
    },
  });

  const { data: isMentor } = useQuery(
    queries.mentorship_program.mentors(public_id, null)._ctx.is_mentor(currentUser.id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (hasError && isError(error)) {
    return <span>Error: {error.message} </span>;
  }

  const isMentee = some(people, (mentee) => {
    return mentee.id == currentUser.id;
  });
  const showJoin = canJoin && !isMentee;

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters} />
        </Box>
        {showJoin && (
          <Button sx={{ minWidth: '140px' }} onClick={() => joinMutation.mutate()}>
            Join as Mentee
          </Button>
        )}
      </Stack>

      {isMentee && <Text size="h2">You are a mentee.</Text>}

      {isEmpty(people) && <EmptyMessage message="No mentees found." />}

      {!isEmpty(people) && (
        <Box sx={{ pt: 3 }}>
          <CardList
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            items={people}
            renderItem={(mentee: User) => (
              <MenteeCard
                cardProps={{ width: '100%' }}
                mentee={mentee}
                offerComponent={
                  isMentor ? (
                    <OfferMentorshipButton
                      handleOnClick={handleOfferToMentorModal(
                        mentee,
                        setMenteeForRequest,
                        setShowOfferToMentorModal
                      )}
                    />
                  ) : null
                }
              />
            )}
          />
        </Box>
      )}

      {showOfferToMentorModal && (
        <MentorshipRequestModal
          mentee={menteeForRequest}
          handleClose={() => setShowOfferToMentorModal(false)}
          program={program}
        />
      )}
    </Box>
  );
};

export default MentorshipProgramMenteeList;
