const MobileOnly = '@media (max-width: 768px)'; // Use only when it is hard to unset rule on Tablet!

const MobileLg = '@media (min-width: 414px)';
const Tablet = '@media (min-width: 768px)';
const DesktopSm = '@media (min-width: 1024px)';
const DesktopLg = '@media (min-width: 1366px)'; // Matches 1366 and 1440 resolutions
const DesktopXl = '@media (min-width: 1650px)';

const MediaPoint = {
  MobileOnly,
  MobileLg,
  Tablet,
  DesktopSm,
  DesktopLg,
  DesktopXl,
};

export default MediaPoint;
