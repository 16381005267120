import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from 'services/requests-base';

import { listQueryKeyFactory } from '../utils';

export const bulk_user_loads = createQueryKeys('bulk_user_loads', {
  list: listQueryKeyFactory({ endpointName: 'users_api:bulk_user_load_list' }),
  listFilters: () => ({
    queryKey: ['users_api:bulk_user_load_rql_filters'],
    queryFn: async () => {
      const url = ApiURLs['users_api:bulk_user_load_rql_filters']();
      const { data } = await fetchURL(url);
      return { results: data };
    },
  }),
});

export type BulkUserLoadsQueryKeys = inferQueryKeys<typeof bulk_user_loads>;
