import * as React from 'react';
import type { ValueOf } from 'type-fest';

import { LEARNING_TYPES } from 'app/catalog/constants';
import { Assignment, ContentItem } from 'app/shared-content-item/interfaces';
import { values } from 'vendor/lodash';

type ContentItemCardCtx = {
  contentItem: ContentItem;
  learningType: ValueOf<typeof LEARNING_TYPES>;
  userAssignment?: Assignment;
  route: string;
};

const Context = React.createContext<ContentItemCardCtx | undefined>(undefined);

type ContentItemCardProviderProps = ContentItemCardCtx & {
  children: React.ReactNode;
};

export function ContentItemCardProvider(props: ContentItemCardProviderProps) {
  const { children, ...contextValues } = props;

  const value = React.useMemo(() => contextValues, values(contextValues)) as ContentItemCardCtx;

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useContentItemCardCtx() {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useContentItemCardCtx must be used within a ContentCardProvider');
  }

  return context;
}
