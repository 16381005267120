import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import DurationAndTags from 'shared-cards/components/DurationAndTags';
import ArchivedPill from 'shared/components/ArchivedPill';
import Button from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

const CardBodyFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const AdditionalFooterContent = styled.div`
  //padding: 0 16px;
`;

const Footer = styled.div`
  border-top: ${(props) => (props.border ? `1px solid ${colors.neutral50}` : 'none')};
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

const getLeftText = (finishedProps, isFinished) => {
  const {
    singleText: singleFinishedText,
    multipleText: multipleFinishedText,
    amount,
  } = finishedProps;

  // Default case: multiple people completed
  let prefix = amount;
  let suffix = multipleFinishedText;

  // Case 1 person completed
  if (amount === 1) {
    suffix = singleFinishedText;
    // Case user was the single person who completed
    if (isFinished) {
      return '';
    }
    // Case no one completed or no data is received
  } else if (!amount) {
    return null;
    // Case user and multiple other people completed
  } else if (isFinished) {
    prefix = `You +${amount - 1}`;
    // Case user and another person completed
    if (amount - 1 === 1) {
      suffix = singleFinishedText;
    }
  }
  return `${prefix} ${suffix}`;
};

const CardFooter = ({
  additionalFooterContent,
  duration,
  tags,
  ratingProps,
  isFinished,
  finishedProps,
  isArchived,
}) => {
  let ratingText;
  let needsRate;
  let ratingData;

  if (ratingProps) {
    ({ text: ratingText, needsRate, ratingData } = ratingProps);
  }

  const leftText = getLeftText(finishedProps, isFinished);

  const shouldRenderFooter = Boolean(leftText) || Boolean(ratingProps) || isArchived;

  return (
    <CardBodyFooter>
      {additionalFooterContent && (
        <AdditionalFooterContent>{additionalFooterContent}</AdditionalFooterContent>
      )}
      <DurationAndTags duration={duration} tags={tags} />
      {shouldRenderFooter && (
        <Footer border={finishedProps || ratingProps}>
          {isArchived && <ArchivedPill size="small" />}
          {finishedProps && (
            <Text size={14} color={colors.neutral400}>
              {leftText}
            </Text>
          )}
          {ratingProps && (
            <>
              <Text color={colors.neutral400} size="h5">
                {ratingText}
              </Text>
              <Rating>
                {needsRate && (
                  // TODO: add button according specs
                  <Button
                    startIcon={<Icon name="star" />}
                    size="small"
                    onClick={null}
                    variant="text"
                  >
                    Rate
                  </Button>
                )}
                {!needsRate && ratingData && (
                  <>
                    <Icon name="star" color={colors.rating600} width={16} height={16} />
                    <Text color={colors.rating700} medium size="h5">
                      {Math.round(ratingData * 10) / 10}
                    </Text>
                  </>
                )}
              </Rating>
            </>
          )}
        </Footer>
      )}
    </CardBodyFooter>
  );
};

CardFooter.propTypes = {
  additionalFooterContent: PropTypes.node,
  duration: PropTypes.string,
  tags: PropTypes.array,
  ratingProps: PropTypes.shape({
    text: PropTypes.string,
    needsRate: PropTypes.bool,
    ratingData: PropTypes.number,
  }),
  isFinished: PropTypes.bool,
  finishedProps: PropTypes.object,
  isArchived: PropTypes.bool,
};

export default CardFooter;
