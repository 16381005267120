import actions from 'app/entities/actions';
import { useEntities } from 'app/entities/utils';
import { toast } from 'app/notifications/components/NotificationCenter';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';

export const useLinkedContentFetchMetadata = () => {
  const [fetch, { data: metadata, status }] = useEntities(
    actions.opengraph.retrieveMetadata,
    ({ status }) => {
      if (status === STATUS_DONE) {
        toast.success('Successfully extracted metadata from the linked page');
      } else if (status === STATUS_ERROR) {
        toast.error('Unable to extract metadata from the linked page');
      }
    }
  );
  return { metadata, status, fetch };
};
