import { CONTENT_TYPES, FACILITATOR_ROLES } from 'catalog/constants';
import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import TrackForm from 'tracks/components/TrackForm/';
import { assessmentFeedbackPolicy } from 'tracks/contants';
import { filter, get, has, isEmpty, isNil, map } from 'vendor/lodash';
import { useQueryClient } from '@tanstack/react-query';
import { queries } from 'queries';

interface baseInitialValues {
  currentUser: any;
  defaultSettings?: any;
  defaultChannelId?: any;
}

interface createInitialValues extends baseInitialValues {
  clonedAssessment?: any;
  assessment?: never;
}

interface editInitialValues extends baseInitialValues {
  assessment: any;
  clonedAssessment?: never;
}

const getInitialValues = ({
  currentUser,
  defaultSettings,
  assessment,
  defaultChannelId,
}: // clonedAssessment,
createInitialValues | editInitialValues) => {
  const initialValues = assessment ? { ...assessment } : {};
  // const initialValues = clonedAssessment
  //   ? omit(
  //       {
  //         ...clonedAssessment,
  //         name: `Duplicate of ${clonedAssessment.name}`,
  //       },
  //       ['public_id', 'public_id_and_slug', 'track_items_names']
  //     )
  //   : assessment
  //   ? { ...assessment }
  //   : {};

  // if (clonedAssessment) {
  //   initialValues.track_items_names = [];
  // }

  // const initialValues = { ...assessment };

  initialValues.maintainers_ids = map(
    filter(initialValues.facilitators, ['role', FACILITATOR_ROLES.maintainer]),
    ({ user }) => user.id
  );

  // Facilitators
  // Set the current user as the default facilitator only in the creation flow
  if (isEmpty(initialValues.maintainers_ids) && isNil(assessment)) {
    initialValues.maintainers_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Settings
  if (!has(initialValues, 'settings')) {
    initialValues.settings = defaultSettings;
  }

  if (initialValues.passing_score > 0) {
    initialValues.passing_score_enabled = true;
    initialValues.passing_score = Math.trunc(initialValues.passing_score * 100);
  } else {
    initialValues.passing_score = 60;
  }

  initialValues.feedback_policy = get(
    assessment,
    'feedback_policy',
    assessmentFeedbackPolicy.afterQuestion
  );

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

interface BaseAssessmentFormPageProps {
  pageTitle: string;
  topBarActionName: string;
  backRoute: string;
  breadcrumbsItemList: object[];
}

interface CreateAssessmentFormPageProps extends BaseAssessmentFormPageProps {
  isEdit?: false;
  initialValuesParams: Omit<createInitialValues, 'currentUser'>;
}

interface EditAssessmentFormPageProps extends BaseAssessmentFormPageProps {
  isEdit: true;
  initialValuesParams: Omit<editInitialValues, 'currentUser'>;
}

type AssessmentFormPageProps = CreateAssessmentFormPageProps | EditAssessmentFormPageProps;

const AssessmentFormPage = (props: AssessmentFormPageProps) => {
  const {
    pageTitle,
    topBarActionName,
    backRoute,
    breadcrumbsItemList,
    initialValuesParams,
    isEdit = false,
  } = props

  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const initialValues = getInitialValues({
    currentUser,
    defaultChannelId,
    ...initialValuesParams,
  });

  const queryClient = useQueryClient()

  const handleOnSubmitSuccess = (result: any) => {
    handleContentOnSubmitSuccessHandler({
      backRoute,
      isEdit,
      result,
    });

    const publicId = get(result, 'public_id');
    if (publicId) {
      queryClient.invalidateQueries({ queryKey: queries.content_items.details(publicId).queryKey, refetchType: 'all' });
    }
  };

  const form = `Assessment${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <TrackForm
        form={form}
        topBarActionName={topBarActionName}
        initialValues={initialValues}
        isEdit={isEdit}
        backRoute={backRoute}
        trackType={CONTENT_TYPES.assessment}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={handleOnSubmitSuccess}
      />
    </>
  );
};

export default AssessmentFormPage;
