import { createActions } from 'redux-actions';

export default createActions(
  {},

  'EVENT_FETCH_REQUEST',
  'EVENT_FETCH_REQUEST_SUBMIT',
  'EVENT_FETCH_REQUEST_SUCCESS',
  'EVENT_FETCH_REQUEST_FAILURE',

  'EVENT_FEEDBACKS_FETCH_REQUEST',
  'EVENT_FEEDBACKS_FETCH_REQUEST_SUBMIT',
  'EVENT_FEEDBACKS_FETCH_REQUEST_SUCCESS',
  'EVENT_FEEDBACKS_FETCH_REQUEST_FAILURE',

  'RELATED_EVENTS_FETCH_REQUEST',
  'RELATED_EVENTS_FETCH_REQUEST_SUBMIT',
  'RELATED_EVENTS_FETCH_REQUEST_SUCCESS',
  'RELATED_EVENTS_FETCH_REQUEST_FAILURE',

  'RESOURCES_FETCH_REQUEST',
  'RESOURCES_FETCH_REQUEST_SUBMIT',
  'RESOURCES_FETCH_REQUEST_SUCCESS',
  'RESOURCES_FETCH_REQUEST_FAILURE',

  'EVENT_FEEDBACK_FORM',
  'EVENT_FEEDBACK_FORM_SUBMIT',
  'EVENT_FEEDBACK_FORM_SUCCESS',
  'EVENT_FEEDBACK_FORM_FAILURE',

  'EVENT_ENROLLMENT_UPDATE_REQUEST',
  'EVENT_ENROLLMENT_UPDATE_REQUEST_SUBMIT',
  'EVENT_ENROLLMENT_UPDATE_REQUEST_SUCCESS',
  'EVENT_ENROLLMENT_UPDATE_REQUEST_FAILURE',

  'EVENT_ATTENDEES_FETCH_REQUEST',
  'EVENT_ATTENDEES_FETCH_REQUEST_SUBMIT',
  'EVENT_ATTENDEES_FETCH_REQUEST_SUCCESS',
  'EVENT_ATTENDEES_FETCH_REQUEST_FAILURE',

  'EVENT_CHECKIN_OTHER_USER_REQUEST',
  'EVENT_CHECKIN_OTHER_USER_REQUEST_SUBMIT',
  'EVENT_CHECKIN_OTHER_USER_REQUEST_SUCCESS',
  'EVENT_CHECKIN_OTHER_USER_REQUEST_FAILURE',

  'EVENT_CHECKOUT_OTHER_USER_REQUEST',
  'EVENT_CHECKOUT_OTHER_USER_REQUEST_SUBMIT',
  'EVENT_CHECKOUT_OTHER_USER_REQUEST_SUCCESS',
  'EVENT_CHECKOUT_OTHER_USER_REQUEST_FAILURE',

  'EVENT_LIST_LOCATIONS_REQUEST',
  'EVENT_LIST_LOCATIONS_REQUEST_SUBMIT',
  'EVENT_LIST_LOCATIONS_REQUEST_SUCCESS',
  'EVENT_LIST_LOCATIONS_REQUEST_FAILURE',

  'EVENT_UNENROLL_OTHER_USER_REQUEST',
  'EVENT_UNENROLL_OTHER_USER_REQUEST_SUBMIT',
  'EVENT_UNENROLL_OTHER_USER_REQUEST_SUCCESS',
  'EVENT_UNENROLL_OTHER_USER_REQUEST_FAILURE',

  'TOGGLE_SHOW_NEW_EVENT_MODAL'
);
