import { PersistentlyDismissableInfoBox } from 'app/shared/components/PersistentlyDismissableInfoBox';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { CONTENT_TYPES } from 'catalog/constants';
import { useCurrentUser, useLabels } from 'shared/hooks';
import { get, toLower, isNil } from 'vendor/lodash';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { TrackItem } from '../types';

function getMessageForContentType(contentType: string, labelTrack: string) {
  const informationMapping = {
    [CONTENT_TYPES.article]: `Read the article and mark it as Complete to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.video]: `Watch the video and mark it as Complete to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.course]: `Launch and finish the course to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.eventtype]: `Enroll and check in to an event from this event type to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.event]: `Attend this event to complete the ${toLower(labelTrack)} step.`,
  };

  return informationMapping[contentType];
}

type TrackContentItemInfoAlertProps = {
  trackItem: TrackItem;
};

export const TrackContentItemInfoAlert = (props: TrackContentItemInfoAlertProps) => {
  const { trackItem } = props;

  const { label_track: labelTrack } = useLabels();
  const currentUser = useCurrentUser();

  const { rootTrack } = useComposableTrackCtx();
  const { currentItemAssignment } = useTrackContentConsumptionCtx();

  const isTrackCompleted = get(rootTrack, 'user_assignment.state') === ASSIGNMENT_STATES.completed;
  const isItemCompleted = get(currentItemAssignment, 'state') === ASSIGNMENT_STATES.completed;
  const contentItem = get(trackItem, 'content_item');

  const message = getMessageForContentType(contentItem.content_type, labelTrack);

  if (isTrackCompleted || isItemCompleted || isNil(message)) {
    return null;
  }

  return (
    <PersistentlyDismissableInfoBox
      localStorageKey={`closedTrackInfoTip-${currentUser.id}-${rootTrack.id}-${contentItem.content_type}`}
      margin="0 0 20px 0"
      content={message}
      actionText="Dismiss"
      type="success"
    />
  );
};
