import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ContentItem } from 'app/shared-content-item/interfaces';

import { ContentItemCardCover } from './ContentItemCardCover';
import { ContentItemCardDescription } from './ContentItemCardDescription';
import { ContentItemCardFooter } from './ContentItemCardFooter';
import { ContentItemCardHeader } from './ContentItemCardHeader';
import { ContentItemCardLearning } from './ContentItemCardLearning';
import { ContentItemCardOwners } from './ContentItemCardOwners';
import { ContentItemCardRoot } from './ContentItemCardRoot';
import { ContentItemCardTags } from './ContentItemCardTags';

const CardBody = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  overflow: hidden;
  padding: 12px 16px 8px 16px;
`;

type ContentItemCardProps = {
  contentItem: ContentItem;
};

export function ContentItemCard(props: ContentItemCardProps) {
  const { contentItem } = props;

  return (
    <ContentItemCardRoot contentItem={contentItem}>
      <ContentItemCardCover />
      <ContentItemCardLearning />
      <CardBody>
        <ContentItemCardHeader />
        <ContentItemCardOwners />
        <ContentItemCardDescription />
        <ContentItemCardTags />
      </CardBody>
      <ContentItemCardFooter />
    </ContentItemCardRoot>
  );
}
