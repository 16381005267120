import React from 'react';

import { WidgetPermissionFunc, permissions } from 'common/utils/widgetPermissions';
import {
  ContentIOwnWidget,
  AssignedContentWidget,
  MySavedContentWidget,
  TopContentWidget,
} from 'features/contentitems/widgets';
import {
  MyUpcomingLiveWidget,
  MyUpcomingEventsWidget,
  EventsIOwnWidget,
  TopEventsWidget,
} from 'features/events/widgets';
import {
  MyTeamWidget,
  MyDirectsWidget,
  TopExpertsWidget,
  MyAudienceWidget,
  MyAttendanceWidget,
  MyExpertsWidget,
} from 'features/users/widgets';

export type SettingConfiguredWidgetPermission =
  | 'can_create_content'
  | 'can_create_event'
  | 'has_team'
  | 'has_directs';

export type SettingConfiguredWidgetsMap = {
  [key: string]: {
    component: React.ComponentType<any>;
    permissions?: WidgetPermissionFunc[];
  };
};

export const settingConfiguredWidgets: SettingConfiguredWidgetsMap = {
  /* ------------------------------ CONTENT ITEMS ----------------------------- */
  content_i_own: {
    component: ContentIOwnWidget,
    permissions: [permissions.canCreateContent],
  },
  assigned_content: {
    component: AssignedContentWidget,
  },
  my_saved_content: {
    component: MySavedContentWidget,
  },
  top_content: {
    component: TopContentWidget,
  },
  /* --------------------------------- EVENTS --------------------------------- */
  my_upcoming: {
    component: MyUpcomingLiveWidget,
  },
  my_upcoming_events: {
    component: MyUpcomingEventsWidget,
  },
  events_i_run: {
    component: EventsIOwnWidget,
    permissions: [permissions.canCreateEvent],
  },
  top_events: {
    component: TopEventsWidget,
  },
  /* ---------------------------------- USERS --------------------------------- */
  my_team: {
    component: MyTeamWidget,
    permissions: [permissions.hasTeam],
  },
  my_directs: {
    component: MyDirectsWidget,
    permissions: [permissions.hasDirects],
  },
  top_experts: {
    component: TopExpertsWidget,
  },
  my_audience: {
    component: MyAudienceWidget,
    permissions: [permissions.canCreateContent],
  },
  my_attendance: {
    component: MyAttendanceWidget,
    permissions: [permissions.canCreateEvent],
  },
  my_experts: {
    component: MyExpertsWidget,
  },
};
