import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import IntegrationDisableModal from 'settings/components/IntegrationDisableModal';
import { ExtraSettingsContext } from 'settings/context-providers/ExtraSettingsContext';
import Button from 'shared/components/Button';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';

const SettingSlackButtonField = ({
  input: {
    value: { value },
  },
}) => {
  const { slackIntegrationUrl } = useContext(ExtraSettingsContext);
  const [isIntegrationActive, setIsIntegrationActive] = useState(Boolean(value));
  const [showIntegrationDisableModal, setShowIntegrationDisableModal] = useState(false);

  const [slackTurnOff] = useEntities(actions.slack.turnOff, ({ status, error }) => {
    if (status === STATUS_DONE) {
      setShowIntegrationDisableModal(false);
      setIsIntegrationActive(false);
    }

    if (status === STATUS_ERROR) {
      toast.error('Error', error?.error);
    }
  });

  useEffect(() => {
    setIsIntegrationActive(value)
  }, [value]);

  if (isIntegrationActive) {
    return (
      <>
        <Button color="error" type="button" onClick={() => setShowIntegrationDisableModal(true)}>
          Disconnect
        </Button>
        {showIntegrationDisableModal && (
          <IntegrationDisableModal
            integrationName="Slack"
            handleDisable={slackTurnOff}
            handleClose={() => setShowIntegrationDisableModal(false)}
          />
        )}
      </>
    );
  }
  return (
    <Button color="secondary" type="button" href={slackIntegrationUrl}>
      Connect
    </Button>
  );
};

SettingSlackButtonField.propTypes = {
  input: PropTypes.object,
};

export default SettingSlackButtonField;
