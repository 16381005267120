import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const HashtagIcon = ({ width, height, fill }) => {
  const d =
    'M17.5 4.776h-1.2l.45-1.95c.15-.825-.375-1.575-1.2-1.8-.825-.15-1.575.375-1.8 1.2l-.45' +
    ' 2.55H8.875l.375-1.95c.15-.825-.375-1.575-1.2-1.8-.825-.15-1.575.375-1.8 1.2l-.45 ' +
    '2.55H4c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5h1.2l-.9 4.5H2.5c-.825 0-1.5.675-1.5' +
    ' 1.5s.675 1.5 1.5 1.5h1.2l-.45 1.95c-.15.825.375 1.575 1.2 1.8h.3c.675 0 1.35-.525' +
    ' 1.5-1.2l.525-2.55H11.2l-.45 1.95c-.15.825.375 1.575 1.2 1.8h.3c.675 0 1.35-.525' +
    ' 1.5-1.2l.525-2.55H16c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5h-1.2l.9-4.5h1.8c.825 ' +
    '0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5zm-5.7 7.5H7.375l.9-4.5H12.7l-.9 4.5z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

HashtagIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default HashtagIcon;
