import PropTypes from 'prop-types';
import React from 'react';

import BaseResourceContainer from './BaseResourceContainer';

const ResourceContainer = ({ content }) => {
  return <BaseResourceContainer content={content} showContainer />;
};

ResourceContainer.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ResourceContainer;
