import PropTypes from 'prop-types';
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';

import { useCurrentUser } from 'app/shared/hooks';
import actions from 'entities/actions';
import { enrollmentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import DoubleEnrollPerTypeWarnModal from 'event-shared/components/DoubleEnrollPerTypeWarnModal';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_EXIT_WAIT_LIST,
  UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL,
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
  DOUBLE_ENROLLMENT_PER_TYPE_NOT_GRANTED_EXCEPTION_NAME,
  CANT_UNENROLL_EVENT_OF_SCHEDULED_TRACK_CODE,
} from 'event-shared/constants';
import MessageModal from 'notifications/components/MessageModal';
import { toast } from 'notifications/components/NotificationCenter';
import { DropEnrollmentModal } from 'scenes/EventDetail/EventActionButtons/DropEnrollmentModal';
import ModalManager from 'shared/components/ModalManager';
import { STATUS_LOADING, STATUS_ERROR, STATUS_DONE } from 'shared/constants';
import UnenrollScheduledTrackEventModal from 'tracks/components/ScheduleTrackModal/UnenrollScheduledTrackEventModal';
import { get, includes } from 'vendor/lodash';

import DeregistrationModal from './DeregistrationModal';
import ExternalWaitlistDeregistrationModal from './ExternalWaitlistDeregistrationModal';
import ExternalWaitlistModal from './ExternalWaitlistModal';
import RegistrationModal from './RegistrationModal';
import WarnDoubleBookingModal from './WarnDoubleBookingModal';

const ERROR_MODAL_MESSAGE = 'Error, refresh and try again';

function checkIsEnrolling(status) {
  return includes(
    [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE, ENROLLMENT_STATUS_WAIT_LIST],
    status
  );
}

function checkIsUnenrolling(status) {
  return includes([ENROLLMENT_STATUS_NOT_GOING, ENROLLMENT_STATUS_EXIT_WAIT_LIST], status);
}

const EventRegistrationManager = forwardRef(function EventRegistrationManager(props, ref) {
  const { event } = props;

  const currentUser = useCurrentUser();
  const userTimezone = get(currentUser, 'timezone');
  const toggleCheckAvailabilityBeforeEnrolling = get(
    currentUser,
    'check_google_calendar_double_bookings'
  );

  const toggleEventExternalUnenrollmentPolicy = get(
    event,
    'settings.toggle_event_external_unenrollment_policy.value',
    false
  );
  const noUnenrollFlow =
    toggleEventExternalUnenrollmentPolicy === UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL;

  const registrationModalManagerRef = useRef();
  const deregistrationModalManagerRef = useRef();
  const externalWaitListModalManagerRef = useRef();
  const externalWaitListDeregistrationModalManagerRef = useRef();
  const errorModalManagerRef = useRef();
  const doubleBookingModalRef = useRef();
  const unenrollScheduledTrackEventModalManagerRef = useRef();
  const dropEnrollmentModalManagerRef = useRef();

  const [requestedStatusChange, setRequestedStatusChange] = useState(null);
  const [status, setStatus] = useState(null);
  const [showDoubleEnrollPerTypeWarnModal, setShowDoubleEnrollPerTypeWarnModal] = useState(false);
  const [scheduledTrackId, setScheduledTrackId] = useState(null);
  const [scheduledTrackName, setScheduledTrackName] = useState(null);

  const handleRequestUpdateEnrollmentStatusChanged = (requestState) => {
    const requestStatus = get(requestState, 'status');

    if (requestStatus === STATUS_ERROR) {
      const requestError = get(requestState, 'error');

      const errException = get(requestError, 'exception');
      if (errException === DOUBLE_ENROLLMENT_PER_TYPE_NOT_GRANTED_EXCEPTION_NAME) {
        setShowDoubleEnrollPerTypeWarnModal(true);
        return;
      }

      const errCode = get(requestError, 'code');
      if (errCode === CANT_UNENROLL_EVENT_OF_SCHEDULED_TRACK_CODE) {
        setScheduledTrackId(get(requestError, 'scheduled_track_id'));
        setScheduledTrackName(get(requestError, 'scheduled_track_name'));
        dropEnrollmentModalManagerRef.current?.hide();
        unenrollScheduledTrackEventModalManagerRef.current?.show();
        return;
      }

      errorModalManagerRef.current?.show();
      return;
    }

    // Close unenroll modal if the request succeed (i.e. with no errors)
    dropEnrollmentModalManagerRef.current?.hide();

    const userHasCheckedIn = status === ENROLLMENT_STATUS_ATTENDED && requestStatus === STATUS_DONE;
    if (userHasCheckedIn) {
      toast.success('Success', 'You are checked in!');
    }

    // Check if the enrollment request was successful before opening the event external link in a new tab
    let checkedOpenEventExternalLink =
      event.requires_external_registration && event.external_link && requestStatus === STATUS_DONE;

    // In case of unenrollment, the external link will not be redirect if the unenrollment policy unenrollment is final
    checkedOpenEventExternalLink = checkIsUnenrolling(status)
      ? checkedOpenEventExternalLink && !noUnenrollFlow
      : checkedOpenEventExternalLink;

    if (checkedOpenEventExternalLink) {
      window.open(event.external_link, '_blank');
    }
  };

  const [requestUpdateEnrollment, requestUpdateEnrollmentState] = useEntities(
    actions.eventEnrollment.updateSubmit,
    handleRequestUpdateEnrollmentStatusChanged,
    {
      key: `enrollmentStatusUpdate${event.public_id}`,
      schema: enrollmentSchema,
    }
  );

  const handleUpdateEnrollmentStatus = (status, params = {}) => {
    let newStatus = status;

    if (status === ENROLLMENT_STATUS_EXIT_WAIT_LIST) {
      newStatus = ENROLLMENT_STATUS_NOT_GOING;
    }

    requestUpdateEnrollment(event.public_id, {
      status: newStatus,
      ...params,
    });
  };

  const handleExternalRegistrationFlow = (newStatus) => {
    const noUnenrollFlow =
      toggleEventExternalUnenrollmentPolicy === UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL;
    const isUnrolling = checkIsUnenrolling(newStatus);

    if (isUnrolling && noUnenrollFlow) {
      handleUpdateEnrollmentStatus(newStatus);
    }

    switch (newStatus) {
      case ENROLLMENT_STATUS_GOING:
        registrationModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_GOING_ONLINE:
        registrationModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_NOT_GOING:
        if (noUnenrollFlow) break;
        deregistrationModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_WAIT_LIST:
        externalWaitListModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_WAIT_LIST_ONLINE:
        externalWaitListModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_EXIT_WAIT_LIST:
        if (noUnenrollFlow) break;
        externalWaitListDeregistrationModalManagerRef.current?.show();
        break;
      case ENROLLMENT_STATUS_ATTENDED:
        handleUpdateEnrollmentStatus(newStatus);
        break;
      default:
        break;
    }
  };

  const handleExecuteStatusChangeFlow = (newStatus) => {
    setStatus(newStatus);

    if (event.requires_external_registration) {
      handleExternalRegistrationFlow(newStatus);
    } else {
      handleUpdateEnrollmentStatus(newStatus);
    }
  };

  const handleRequestCheckAvailabilityStatusChanged = (requestState) => {
    const requestStatus = get(requestState, 'status');

    if (requestStatus === STATUS_LOADING) {
      return;
    }

    const requestData = get(requestState, 'data');
    const isAvailable = get(requestData, 'available', true);

    if (isAvailable) {
      handleExecuteStatusChangeFlow(requestedStatusChange);
    } else {
      doubleBookingModalRef.current?.show();
    }
  };

  const [requestCheckAvailability] = useEntities(
    actions.googleCalendar.freeBusyCheck,
    handleRequestCheckAvailabilityStatusChanged,
    {
      key: `freeBusyCheck${event.id}`,
    }
  );

  const handleRegistrationStatusChanged = (newStatus) => {
    const isEnrolling = checkIsEnrolling(newStatus);

    if (isEnrolling && toggleCheckAvailabilityBeforeEnrolling) {
      setRequestedStatusChange(newStatus);
      requestCheckAvailability({ event_id: event.id });
      return;
    }

    if (checkIsUnenrolling(newStatus)) {
      dropEnrollmentModalManagerRef.current?.show();
      return;
    }

    handleExecuteStatusChangeFlow(newStatus);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        registrationStatusChanged: handleRegistrationStatusChanged,
      };
    },
    [handleRegistrationStatusChanged]
  );

  const limitDoubleBooking = get(event, 'event_type.limit_double_booking', false);

  const updateEnrollmentRequestStatus = get(requestUpdateEnrollmentState, 'status');
  const updateEnrollmentRequestResponse = get(requestUpdateEnrollmentState, 'data');
  const updateEnrollmentRequestError = get(requestUpdateEnrollmentState, 'error');

  return (
    <>
      <ModalManager ref={registrationModalManagerRef}>
        <RegistrationModal
          event={event}
          status={status}
          userTimezone={userTimezone}
          updateEnrollmentStatus={handleUpdateEnrollmentStatus}
        />
      </ModalManager>
      <ModalManager ref={deregistrationModalManagerRef}>
        <DeregistrationModal event={event} updateEnrollmentStatus={handleUpdateEnrollmentStatus} />
      </ModalManager>
      <ModalManager ref={externalWaitListModalManagerRef}>
        <ExternalWaitlistModal
          event={event}
          status={status}
          updateEnrollmentStatus={handleUpdateEnrollmentStatus}
        />
      </ModalManager>
      <ModalManager ref={externalWaitListDeregistrationModalManagerRef}>
        <ExternalWaitlistDeregistrationModal
          event={event}
          updateEnrollmentStatus={handleUpdateEnrollmentStatus}
        />
      </ModalManager>
      <ModalManager ref={unenrollScheduledTrackEventModalManagerRef}>
        <UnenrollScheduledTrackEventModal
          event={event}
          userTimezone={userTimezone}
          updateEnrollmentStatus={handleUpdateEnrollmentStatus}
          scheduledTrackId={scheduledTrackId}
          scheduledTrackName={scheduledTrackName}
        />
      </ModalManager>
      <ModalManager ref={dropEnrollmentModalManagerRef}>
        <DropEnrollmentModal
          event={event}
          performUnenroll={() => handleUpdateEnrollmentStatus(ENROLLMENT_STATUS_NOT_GOING)}
        />
      </ModalManager>
      {limitDoubleBooking && showDoubleEnrollPerTypeWarnModal ? (
        <DoubleEnrollPerTypeWarnModal
          prospectEvent={event}
          prospectEnrollmentStatus={status}
          updateEnrollmentStatus={handleUpdateEnrollmentStatus}
          updateEnrollmentRequestStatus={updateEnrollmentRequestStatus}
          handleClose={() => {
            setShowDoubleEnrollPerTypeWarnModal(false);
          }}
        />
      ) : (
        <ModalManager ref={errorModalManagerRef}>
          <MessageModal
            title="ERROR!"
            message={
              get(updateEnrollmentRequestResponse, 'error') ||
              get(updateEnrollmentRequestError, 'error') ||
              ERROR_MODAL_MESSAGE
            }
          />
        </ModalManager>
      )}
      <ModalManager ref={doubleBookingModalRef}>
        <WarnDoubleBookingModal
          requestedStatus={requestedStatusChange}
          updateEnrollmentStatus={handleExecuteStatusChangeFlow}
        />
      </ModalManager>
    </>
  );
});

EventRegistrationManager.propTypes = {
  event: PropTypes.object,
};

export default EventRegistrationManager;
