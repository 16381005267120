import { useEffect, useState } from 'react';

import { getErrorMessage } from 'assignments/hooks';
import actions from 'entities/actions';
import { enrollmentSchema, eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { Event } from 'event/interfaces';
import { toast } from 'notifications/components/NotificationCenter';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { includes, noop, isNil, get } from 'vendor/lodash';

import { ATTENDANCE_METHODS, ENROLLMENT_VIEW_MODES } from './constants';

interface EnrollmentsBulkActionsProps {
  postActionCallback?: ({ status, data, error }) => void;
}

interface BulkCreateBody {
  event_ids: Array<string | number>;
  user_rql: string;
  attendance_method: string;
  enrollment_policy: string;
  force_checkin: boolean;
  is_reenroll: boolean;
  reason: string;
}

interface BulkActionBody {
  enrollment_rql: string;
  reason?: string;
}

export const useEnrollmentsBulkActions = ({
  postActionCallback = noop,
}: EnrollmentsBulkActionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bulkCreate, { status: bulkCreateStatus, data: bulkCreateResponse }] = useEntities(
    actions.enrollment.bulk,
    postActionCallback
  );
  const [bulkPromote, { status: bulkPromoteStatus, data: bulkPromoteResponse }] = useEntities(
    actions.enrollment.bulkPromote,
    postActionCallback
  );
  const [bulkDemote, { status: bulkDemoteStatus, data: bulkDemoteResponse }] = useEntities(
    actions.enrollment.bulkDemote,
    postActionCallback
  );
  const [bulkCheckin, { status: bulkCheckinStatus, data: bulkCheckinResponse }] = useEntities(
    actions.enrollment.bulkCheckin,
    postActionCallback
  );
  const [bulkUndoCheckin, { status: bulkUndoCheckinStatus, data: bulkUndoCheckinResponse }] =
    useEntities(actions.enrollment.bulkUndoCheckin, postActionCallback);
  const [bulkDrop, { status: bulkDropStatus, data: bulkDropResponse }] = useEntities(
    actions.enrollment.bulkDrop,
    postActionCallback
  );

  useEffect(() => {
    setIsLoading(
      includes(
        [
          bulkCreateStatus,
          bulkCheckinStatus,
          bulkUndoCheckinStatus,
          bulkPromoteStatus,
          bulkDemoteStatus,
          bulkDropStatus,
        ],
        STATUS_LOADING
      )
    );
  }, [
    bulkCreateStatus,
    bulkCheckinStatus,
    bulkUndoCheckinStatus,
    bulkPromoteStatus,
    bulkDemoteStatus,
    bulkDropStatus,
  ]);
  return {
    bulkCreate: (body: BulkCreateBody) => bulkCreate(body),
    bulkCreateStatus,
    bulkCreateResponse,
    bulkPromote: (body: BulkActionBody) => bulkPromote(body),
    bulkPromoteStatus,
    bulkPromoteResponse,
    bulkDemote: (body: BulkActionBody) => bulkDemote(body),
    bulkDemoteStatus,
    bulkDemoteResponse,
    bulkCheckin: (body: BulkActionBody) => bulkCheckin(body),
    bulkCheckinStatus,
    bulkCheckinResponse,
    bulkUndoCheckin: (body: BulkActionBody) => bulkUndoCheckin(body),
    bulkUndoCheckinStatus,
    bulkUndoCheckinResponse,
    bulkDrop: (body: BulkActionBody) => bulkDrop(body),
    bulkDropStatus,
    bulkDropResponse,
    isLoading,
  };
};

interface EnrollmentsActionsProps {
  enrollmentId?: number;
  event?: Event;
  postActionCallback?: (
    id?: number | string | null,
    filters?: Record<string, any> | string
  ) => void;
  viewMode?: ENROLLMENT_VIEW_MODES;
}

interface CreateBody {
  pre_status: string;
}

interface EntityIdentifier {
  publicId: string | null;
  id: number | null;
}

interface EnrollmentActionProps {
  overrideId?: number; // Useful to avoid creating multiple hooks in contexts with multiple enrollments
}

interface EnrollmentUpdateOrderProps extends EnrollmentActionProps {
  order: number;
}

interface EnrollmentPromoteDemoteProps extends EnrollmentActionProps {
  attendanceMethod: ATTENDANCE_METHODS;
}

export const useEnrollmentsActions = ({
  enrollmentId,
  event,
  postActionCallback,
  viewMode = ENROLLMENT_VIEW_MODES.lite,
}: EnrollmentsActionsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [create, { status: createStatus, error: createError }] = useEntities(
    actions.enrollment.createSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to create the enrollment', errorMessage);
      }
      if (includes([STATUS_DONE, STATUS_ERROR], status) && !isNil(postActionCallback)) {
        postActionCallback(enrollmentId, { view_mode: viewMode });
      }
    },
    { schema: enrollmentSchema }
  );

  const [retrieve, { status: retrieveStatus, data: retrieveResponse }] = useEntities(
    actions.enrollment.retrieve,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to retrieve the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  // By default, after every action, the enrollment data is retrieved,
  // this behavior can be replaced by setting a function to postActionCallback
  const postAction = isNil(postActionCallback)
    ? (id?: number | string | null) => retrieve(id, { view_mode: viewMode })
    : (id?: number | string | null) => postActionCallback(id, { view_mode: viewMode });

  const getIdentifier = (identifier?: EntityIdentifier) => {
    const publicId = get(identifier, 'publicId', null);
    const id = get(identifier, 'id', null);
    return publicId || id;
  };

  const [updateOrder, { status: updateOrderStatus, error: updateOrderError }] = useEntities(
    actions.enrollment.updateOrder,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to update the enrollment order', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  const [checkin, { status: checkinStatus, error: checkinError }] = useEntities(
    actions.enrollment.checkin,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to check-in the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  const [undoCheckin, { status: undoCheckinStatus, error: undoCheckinError }] = useEntities(
    actions.enrollment.undoCheckin,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to undo check-in the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  const [promote, { status: promoteStatus, error: promoteError }] = useEntities(
    actions.enrollment.promote,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to promote the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  const [demote, { status: demoteStatus, error: demoteError }] = useEntities(
    actions.enrollment.demote,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to demote the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  const [drop, { status: dropStatus, error: dropError }] = useEntities(
    actions.enrollment.drop,
    ({ status, error, identifier }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        const overrideId = getIdentifier(identifier);
        postAction(enrollmentId || overrideId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to drop the enrollment', errorMessage);
      }
    },
    { schema: enrollmentSchema }
  );

  useEffect(() => {
    setIsLoading(
      includes(
        [
          createStatus,
          retrieveStatus,
          updateOrderStatus,
          checkinStatus,
          undoCheckinStatus,
          promoteStatus,
          demoteStatus,
          dropStatus,
        ],
        STATUS_LOADING
      )
    );
  }, [
    createStatus,
    retrieveStatus,
    updateOrderStatus,
    checkinStatus,
    undoCheckinStatus,
    promoteStatus,
    demoteStatus,
    dropStatus,
  ]);
  return {
    create: isNil(event)
      ? noop
      : ({ pre_status }: CreateBody) => {
          create({
            // contentId and contentSchema will not be sent to the backend,
            // check the getUpdateSideEffectSaga function for more information
            contentId: event.id,
            contentSchema: eventSchema,
            event_id: event.id,
            pre_status: pre_status,
          });
        },
    createStatus,
    createError,
    retrieve: ({ overrideId }: EnrollmentActionProps = {}) => retrieve(overrideId || enrollmentId),
    retrieveStatus,
    retrieveResponse,
    updateOrder: ({ overrideId, order }: EnrollmentUpdateOrderProps) =>
      updateOrder(overrideId || enrollmentId, { order }),
    updateOrderStatus,
    updateOrderError,
    checkin: ({ overrideId }: EnrollmentActionProps) => checkin(overrideId || enrollmentId),
    checkinStatus,
    checkinError,
    undoCheckin: ({ overrideId }: EnrollmentActionProps = {}) =>
      undoCheckin(overrideId || enrollmentId),
    undoCheckinStatus,
    undoCheckinError,
    promote: ({ overrideId, attendanceMethod }: EnrollmentPromoteDemoteProps) =>
      promote(overrideId || enrollmentId, { attendance_method: attendanceMethod }),
    promoteStatus,
    promoteError,
    demote: ({ overrideId, attendanceMethod }: EnrollmentPromoteDemoteProps) =>
      demote(overrideId || enrollmentId, { attendance_method: attendanceMethod }),
    demoteStatus,
    demoteError,
    drop: ({ overrideId }: EnrollmentActionProps = {}) =>
      drop(overrideId || enrollmentId, { force: true }),
    dropStatus,
    dropError,
    isLoading,
  };
};
