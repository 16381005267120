import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InnerBlockWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.neutral200};
  padding: 16px;
  margin-bottom: 16px;
`;

const SettingContextInnerLevelBlock = ({ setting, children }) => {
  return (
    <Container>
      <Text size="h3" color={colors.neutral500}>
        {setting.label}
      </Text>
      <InnerBlockWrapper>
        <Text>
          <ReactMarkdown linkTarget="_blank">{setting.description}</ReactMarkdown>
        </Text>
        {children}
      </InnerBlockWrapper>
    </Container>
  );
};

SettingContextInnerLevelBlock.propTypes = {
  setting: PropTypes.object,
  children: PropTypes.node,
};

export default SettingContextInnerLevelBlock;
