import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { map, size, noop } from 'vendor/lodash';

const StyledContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-flow: column;
  display: flex;
`;

const TabsContainer = styled.div`
  display: flex;
`;

const StyledTab = styled.div`
  padding: 10px 0;
  height: 45px;
  flex: 1 0;
  border-bottom: solid 3px ${(props) => (props.active ? colors.emphasis600 : '#cfcfcf')};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.active ? colors.emphasis600 : '#cfcfcf')};
  cursor: ${({ disableNotCurrent, active }) =>
    !disableNotCurrent || active ? 'pointer' : 'not-allowed'};
`;

const StyledContent = styled.div`
  width: 100%;
  flex: 1;
  flex-flow: column;
  display: flex;
`;

export const TabbedContent = ({ forceSelectedTab, tabs, disableNotCurrent, onTabChange }) => {
  const [currTab, setCurrTab] = useState(forceSelectedTab);

  useEffect(() => {
    setCurrTab(forceSelectedTab);
  }, [forceSelectedTab]);

  useEffect(() => {
    onTabChange(currTab);
  }, [currTab]);

  return (
    <StyledContainer>
      <TabsContainer>
        {map(tabs, (tab, i) => (
          <StyledTab
            key={tab.text}
            value={i}
            numTabs={size(tabs)}
            active={currTab === i}
            disableNotCurrent={disableNotCurrent}
            onClick={() => !disableNotCurrent && setCurrTab(i)}
          >
            {tab.text}
          </StyledTab>
        ))}
      </TabsContainer>
      <StyledContent>{tabs[currTab] && tabs[currTab].content}</StyledContent>
    </StyledContainer>
  );
};

TabbedContent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  forceSelectedTab: PropTypes.number,
  disableNotCurrent: PropTypes.bool,
  onTabChange: PropTypes.func,
};

TabbedContent.defaultProps = {
  forceSelectedTab: 0,
  disableNotCurrent: false,
  onTabChange: noop,
};
export default TabbedContent;
