import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import {
  dateRangeInputToDateRangeRQL,
  dateRangeRQLToDateRangeInput,
  getDateRangeDateRangeInputFormat,
  getDateRangeText,
} from 'filters/utils';
import {
  DATE_RANGE_EXACT_MODE,
  DATE_RANGE_RELATIVE_MODE,
} from 'inputs/components/FlexibleDateRangeInput/constants';
import DateRangeShortcuts, {
  ShortcutConfig,
} from 'inputs/components/FlexibleDateRangeInput/DateRangeShortcuts';
import ExactDateRangeInput from 'inputs/components/FlexibleDateRangeInput/ExactDateRangeInput';
import RelativeDateRangeInput from 'inputs/components/FlexibleDateRangeInput/RelativeDateRangeInput';
import { RQLDateRange } from 'shared/components/types';
import { isUndefined, noop, size } from 'vendor/lodash';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  TextField,
} from 'vendor/mui';

type InputFormat = typeof DATE_RANGE_RELATIVE_MODE | typeof DATE_RANGE_EXACT_MODE;

interface RelativeDateRangeInputOnChangeProps {
  startDate?: string;
  endDate?: string;
}

interface ExactDateRangeInputOnChangeProps {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

interface RQLDateRangePicker {
  value?: RQLDateRange | null;
  label?: string;
  width?: string | number;
  shortcuts?: ShortcutConfig[];
  onChange?: (value: RQLDateRange | null) => void;
}

const RQLDateRangePicker = (props: RQLDateRangePicker) => {
  const { value, label, width, shortcuts, onChange = noop } = props;

  const anchorRef = useRef<HTMLInputElement | null>(null);
  const { startDate, endDate } = dateRangeRQLToDateRangeInput(value);
  const initialFormat = getDateRangeDateRangeInputFormat({ startDate, endDate });
  const [open, setOpen] = useState(false);
  const [format, setFormat] = useState<InputFormat>(initialFormat || DATE_RANGE_RELATIVE_MODE);
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef?.current?.contains(event.target as Node | null)) return;
    setOpen(false);
  };
  const id = open ? 'date-range-picker' : undefined;

  const handleChangeShortcut = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    onChange(dateRangeInputToDateRangeRQL({ startDate: startDate || '', endDate: endDate || '' }));
  };

  useEffect(() => {
    // As the component manages the format state, the first time it is rendered the values (if they exist)
    // have not been loaded yet so it processes a value as empty and sets the format to "relative" (default),
    // this effect fixes this behavior, this change is expected to be executed only once and only in cases
    // where the pre-defined values are in the exact format.
    if (initialFormat && initialFormat != format && (startDate || endDate)) {
      setFormat(initialFormat);
    }
  }, [initialFormat, format, startDate, endDate]);

  if (initialFormat && initialFormat != format && (startDate || endDate)) return null;

  const inputText = getDateRangeText({
    startDate,
    endDate,
    format,
    defaultMessage: 'Pick a date range',
  });

  const canDisplayShortcuts = isUndefined(shortcuts) || size(shortcuts) > 0;

  return (
    <>
      <TextField
        type="text"
        variant="outlined"
        size="small"
        value={inputText}
        sx={{ width }}
        label={label}
        inputRef={anchorRef}
        onClick={() => setOpen(!open)}
        InputProps={{
          style: { resize: 'vertical' },
        }}
        InputLabelProps={{
          shrink: true, // https://mui.com/material-ui/react-text-field/#shrink
        }}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2 }}>
                  <ButtonGroup fullWidth>
                    <Button
                      size="small"
                      variant={format === DATE_RANGE_RELATIVE_MODE ? 'contained' : 'outlined'}
                      onClick={() => {
                        onChange(null); // clear the current value before change the format
                        setFormat(DATE_RANGE_RELATIVE_MODE);
                      }}
                    >
                      Relative
                    </Button>
                    <Button
                      size="small"
                      variant={format === DATE_RANGE_EXACT_MODE ? 'contained' : 'outlined'}
                      onClick={() => {
                        onChange(null); // clear the current value before change the format
                        setFormat(DATE_RANGE_EXACT_MODE);
                      }}
                    >
                      Exact
                    </Button>
                  </ButtonGroup>

                  {canDisplayShortcuts && (
                    <DateRangeShortcuts shortcuts={shortcuts} onChange={handleChangeShortcut} />
                  )}

                  {format === DATE_RANGE_RELATIVE_MODE && (
                    <RelativeDateRangeInput
                      startDate={startDate || null}
                      endDate={endDate || null}
                      onChange={({ startDate, endDate }: RelativeDateRangeInputOnChangeProps) =>
                        onChange(
                          dateRangeInputToDateRangeRQL({
                            startDate: startDate || '',
                            endDate: endDate || '',
                          })
                        )
                      }
                    />
                  )}
                  {format === DATE_RANGE_EXACT_MODE && (
                    <ExactDateRangeInput
                      startDate={startDate ? moment(startDate) : null}
                      endDate={endDate ? moment(endDate) : null}
                      onChange={({ startDate, endDate }: ExactDateRangeInputOnChangeProps) =>
                        onChange(
                          dateRangeInputToDateRangeRQL({
                            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
                            endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
                          })
                        )
                      }
                    />
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default RQLDateRangePicker;
