import React from 'react';

import { MultipleChoiceOption } from 'assessments/interfaces';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import {
  Avatar,
  FormControlLabel,
  FormControlLabelProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  Typography,
  colors,
  styled,
} from 'vendor/mui';
import { CancelIcon, CheckCircleIcon } from 'vendor/mui-icons';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  stateColor: string | null;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'stateColor',
})<StyledFormControlLabelProps>(({ stateColor }) =>
  stateColor
    ? {
        '& .MuiButtonBase-root': {
          '&.Mui-disabled': {
            color: stateColor,
          },
        },
        '& .MuiFormControlLabel-label': {
          '&.Mui-disabled': {
            color: stateColor,
            fontWeight: '500',
          },
        },
      }
    : {}
);

export type MultipleChoiceOptionFeedbackState =
  | 'none' // The user has not submitted an answer
  | 'correct' // The user has submitted an answer and it is correct
  | 'incorrect' // The user has submitted an answer and it is incorrect
  | 'indeterminate'; // The user has submitted an answer and feedback is not yet available

interface MultipleChoiceOptionRadioButtonProps {
  option: MultipleChoiceOption;
  optionLabel: string;
  disabled: boolean;
  feedbackState?: MultipleChoiceOptionFeedbackState;
}

export const MultipleChoiceOptionRadioButton = (props: MultipleChoiceOptionRadioButtonProps) => {
  const { option, optionLabel, disabled, feedbackState } = props;

  const listItemBg = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return colors.green[50];
    }

    if (feedbackState === 'incorrect') {
      return colors.orange[50];
    }

    if (feedbackState === 'indeterminate') {
      return colors.lightBlue[50];
    }

    return null;
  }, [feedbackState]);

  const avatarBg = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return colors.green[200];
    }

    if (feedbackState === 'incorrect') {
      return colors.orange[200];
    }

    if (feedbackState === 'indeterminate') {
      return colors.lightBlue[200];
    }

    return colors.grey[100];
  }, [feedbackState]);

  const radioProps = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return { checkedIcon: <CheckCircleIcon />, checked: true };
    }

    if (feedbackState === 'incorrect') {
      return { checkedIcon: <CancelIcon /> };
    }
  }, [feedbackState]);

  const labelStateColor = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return colors.green[700];
    }

    if (feedbackState === 'incorrect') {
      return colors.orange[700];
    }

    if (feedbackState === 'indeterminate') {
      return colors.lightBlue[700];
    }

    return null;
  }, [feedbackState]);

  return (
    <ListItem sx={{ marginY: 1, padding: 0, backgroundColor: listItemBg, borderRadius: 1 }}>
      <ListItemAvatar>
        <Avatar variant="square" sx={{ backgroundColor: avatarBg, borderRadius: 1 }}>
          <Typography variant="h6" color="text.secondary">
            {optionLabel}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <StyledFormControlLabel
            value={option.id}
            control={<Radio {...radioProps} />}
            label={<ContentDescription description={option.content_body} noPadding />}
            disabled={disabled}
            stateColor={labelStateColor}
            sx={{ marginY: 0 }}
          />
        }
        sx={{ marginY: 0 }}
      />
    </ListItem>
  );
};
