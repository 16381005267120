import { ASSESSMENT_FEEDBACK_POLICY } from 'app/assessments/constants';
import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { CONTENT_TYPES } from 'app/catalog/constants';
import { Track } from 'features/tracks/types';
import { get, isEmpty } from 'vendor/lodash';

import { Assessment } from '../types';

export function getIsFirstQuestionInAssessment(currentQuestionId: number, assessment: Assessment) {
  const track_items = get(assessment, 'track_items', []);
  const firstTrackItem = track_items[0];
  const firstQuestionId = get(firstTrackItem, 'content_item.id');

  return firstQuestionId === currentQuestionId;
}

export function getIsLastQuestionInAssessment(currentQuestionId: number, assessment: Assessment) {
  const track_items = get(assessment, 'track_items', []);

  if (isEmpty(track_items)) return false;

  const lastTrackItem = track_items[track_items.length - 1];
  const lastQuestionId = get(lastTrackItem, 'content_item.id');

  return lastQuestionId === currentQuestionId;
}

export function isTrackAssessment(track: Track): track is Assessment {
  return track.content_type === CONTENT_TYPES.assessment;
}

export function getShouldDisplayAssessmentFeedback(assessment: Assessment) {
  const assignmentState = get(assessment, 'user_assignment.state');

  return (
    assessment.feedback_policy === ASSESSMENT_FEEDBACK_POLICY.after_question ||
    assignmentState === ASSIGNMENT_STATES.completed
  );
}
