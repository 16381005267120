import { useLabels } from 'shared/hooks';
import { SurveyRelationshipEntityType } from 'surveys/constants';

export const useSurveyRelationshipTypeLabels = ({ isPlural = false } = {}) => {
  const labels = useLabels();

  return {
    [SurveyRelationshipEntityType.EVENT_TYPE]: isPlural
      ? labels.label_event_type_plural
      : labels.label_event_type,
    [SurveyRelationshipEntityType.PROGRAM]: isPlural
      ? labels.label_program_plural
      : labels.label_program,
    [SurveyRelationshipEntityType.TRACK]: isPlural ? labels.label_track_plural : labels.label_track,
    [SurveyRelationshipEntityType.ARTICLE]: isPlural
      ? labels.label_article_plural
      : labels.label_article,
    [SurveyRelationshipEntityType.VIDEO]: isPlural ? 'Videos' : 'Video',
    [SurveyRelationshipEntityType.COURSE]: isPlural ? 'Courses' : 'Course',
  };
};
