import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import { eventAttendeeEmailListSchema } from 'app/entities/schema';
import EventArchiveUnarchiveModal from 'app/event/components/EventArchiveUnarchiveModal';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import EventBulkDuplicateModal from 'event-shared/components/EventBulkDuplicate/EventBulkDuplicateModal';
import EventSurveyModal from 'event-shared/components/EventSurveyModal';
import { toast } from 'notifications/components/NotificationCenter';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import confirmAlert from 'services/confirm-alert';
import { useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import SocialSharingModal from 'shared-modals/components/SocialSharingModal';
import DropDownMenu from 'shared/components/DropDownMenu';
import IconButton from 'shared/components/IconButton';
import Text from 'shared/components/Text';
import { STATUS_DONE } from 'shared/constants';
import { useToggles, useCopyToClipboard, useCurrentUser } from 'shared/hooks';
import * as permissions from 'shared/permissions';
import { includes, join, size } from 'vendor/lodash';

import EventDeleteModal from '../EventDeleteModal';

const ConfirmationDialogContent = () => {
  return (
    <Text block size="h4" color={colors.neutral900}>
      You&apos;re force syncing this event to calendar. This action will <b>notify users</b>. Are
      you sure you want to force sync this event?
    </Text>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

export const STATUS_GOING = 'going';
export const STATUS_WAITLIST = 'wait_list';

const EventContextMenu = ({ content: event, contextMenuExtraProps }) => {
  const { is_archived: isArchived } = event;

  const { currentUser } = contextMenuExtraProps;

  const {
    sync_to_google_calendar: syncToGoogleCalendar,
    sync_to_outlook_calendar: syncToOutlookCalendar,
    include_ics_file_in_event_emails: includeIcsFileInEventEmails,
  } = currentUser;

  const { toggle_calendar_force_sync: toggleCalendarForceSync } = useToggles();
  const { copy_emails_default_separator: copyEmailsDefaultSeparator } = useCurrentUser();

  const { trackActivity } = useMetrics();

  const hasAnySyncToCalendarOn =
    syncToGoogleCalendar || syncToOutlookCalendar || includeIcsFileInEventEmails;

  const canEdit = includes(event.permissions, permissions.EDIT_EVENT_PERMISSION);
  const canManageAttendance = includes(
    event.permissions,
    permissions.MANAGE_EVENT_ATTENDANCE_PERMISSION
  );
  const [resyncResource] = useEntities(actions.calendar.resyncResourceToCalendar, null);

  const CLIPBOARD_TIMEOUT = 2000;
  const { copyToClipboard } = useCopyToClipboard(CLIPBOARD_TIMEOUT);

  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showBulkDuplicateModal, setShowBulkDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveUnarchiveModal, setShowArchiveUnarchiveModal] = useState(false);
  const [showSocialSharingModal, setShowSocialSharingModal] = useState(false);

  const handleResyncResource = () => {
    resyncResource({
      pk: event.id,
      type: 'event',
    });
    toast.success('Force Synced successfully!');
  };

  const forceSyncToCalendar = () => {
    if (syncToOutlookCalendar || includeIcsFileInEventEmails) {
      return confirmAlert({
        title: 'Confirm Calendar Force Sync?',
        content: () => <ConfirmationDialogContent />,
        onConfirm: handleResyncResource,
        confirmLabel: 'Confirm Force Sync',
        isDangerSecondaryAction: true,
      });
    }
    return handleResyncResource();
  };

  const [fetchEventsEmail] = useEntities(
    actions.event.retrieveDetailsEmail,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        const emails = data.emails_to_copy;
        const count = size(emails);

        if (count) {
          toast.success(`${count} ${count === 1 ? 'email' : 'emails'} copied.`);
          copyToClipboard(join(emails, copyEmailsDefaultSeparator));
        } else {
          toast.warning('No items to be copied.');
        }
      }
    },
    {
      schema: [eventAttendeeEmailListSchema],
    }
  );

  const copyToClipboardAndRenderSuccessMessage = (status) => {
    fetchEventsEmail(event.public_id, {
      status,
      page_size: 2000,
    });
  };

  const getSocialSharingText = () => {
    const { name, url } = event;
    return `Check out "${name}": ${url}`;
  };

  if (!canEdit) return null;

  return (
    <>
      <Container>
        {event.chatter_share && (
          <IconButton
            title="Share on Chatter"
            iconName="share"
            color={colors.neutral400}
            hoverColor={colors.action600}
            onClick={() => setShowSocialSharingModal(true)}
            width={28}
            height={28}
          />
        )}
        <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
          <>
            <DropDownMenu.Item
              route={mapRoute('eventEdit', { public_id_and_slug: event.public_id_and_slug })}
              title="Edit"
              icon="pencil"
            />
            <DropDownMenu.Item
              route={mapRoute('eventNewFromClone', { public_id: event.public_id })}
              title="Duplicate"
              icon="clone"
            />
            <DropDownMenu.Item
              onClick={() => setShowBulkDuplicateModal(true)}
              title="Bulk Duplicate"
              icon="multiple-clone"
            />
            {toggleCalendarForceSync && hasAnySyncToCalendarOn && (
              <DropDownMenu.Item
                onClick={forceSyncToCalendar}
                title="Force Calendar Sync"
                icon="loading"
              />
            )}
            <DropDownMenu.Item
              onClick={() => copyToClipboardAndRenderSuccessMessage(STATUS_GOING)}
              title="Copy E-mails (Attendees)"
              icon="mail"
            />
            <DropDownMenu.Item
              onClick={() => copyToClipboardAndRenderSuccessMessage(STATUS_WAITLIST)}
              title="Copy E-mails (Waitlisted)"
              icon="mail"
            />
            <DropDownMenu.Separator />
            <DropDownMenu.Item
              route={mapRoute('eventManageAttendance', {
                public_id_and_slug: event.public_id_and_slug,
              })}
              title="Manage Attendance"
              icon="group"
            />
            {canManageAttendance && (
              <DropDownMenu.Item
                onClick={() => setShowSurveyModal(true)}
                title="Manage Surveys"
                icon="survey"
              />
            )}
            <DropDownMenu.Separator />
            {!isArchived && (
              <DropDownMenu.Item
                onClick={() => setShowArchiveUnarchiveModal(true)}
                title="Cancel"
                icon="archive"
              />
            )}
            {isArchived && (
              <>
                <DropDownMenu.Item
                  onClick={() => setShowArchiveUnarchiveModal(true)}
                  title="Restore"
                  icon="unarchive"
                />
                <DropDownMenu.Item
                  onClick={() => setShowDeleteModal(true)}
                  title="Delete"
                  icon="delete"
                  color={colors.error600}
                  textColor={colors.error600}
                />
              </>
            )}
            {canManageAttendance && (
              <DropDownMenu.Item
                onClick={() => setShowLogModal(true)}
                title="View Logs"
                icon="log"
              />
            )}
          </>
        </DropDownMenu>
      </Container>
      {showSurveyModal && (
        <EventSurveyModal event={event} handleClose={() => setShowSurveyModal(false)} />
      )}
      {showBulkDuplicateModal && (
        <EventBulkDuplicateModal
          event={event}
          trackActivity={trackActivity}
          handleClose={() => setShowBulkDuplicateModal(false)}
        />
      )}
      {showDeleteModal && (
        <EventDeleteModal event={event} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showArchiveUnarchiveModal && (
        <EventArchiveUnarchiveModal
          event={event}
          handleClose={() => setShowArchiveUnarchiveModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={event} handleClose={() => setShowLogModal(false)} />
      )}
      {showSocialSharingModal && (
        <SocialSharingModal
          service="chatter"
          serviceName="Chatter"
          textMessage={getSocialSharingText()}
          handleClose={() => setShowSocialSharingModal(false)}
        />
      )}
    </>
  );
};

EventContextMenu.propTypes = {
  content: PropTypes.object,
  contextMenuExtraProps: PropTypes.object,
};

export default EventContextMenu;
