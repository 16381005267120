import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE } from 'event-shared/constants';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  > * {
    flex-grow: 2;
  }

  gap: 16px;
`;

const CheckinUnenrollButtons = ({
  enrollmentStatus,
  allowCancellation,
  onUnenrollButtonClick,
  onCheckinButtonClick,
}) => {
  const getIconName = () => {
    if (enrollmentStatus === ENROLLMENT_STATUS_GOING) {
      return 'location';
    }

    if (enrollmentStatus === ENROLLMENT_STATUS_GOING_ONLINE) {
      return 'online';
    }

    return null;
  };

  return (
    <Container>
      {allowCancellation ? (
        <Button
          alt="Unenroll"
          color="error"
          onClick={onUnenrollButtonClick}
          startIcon={<Icon name={getIconName()} />}
        >
          Unenroll
        </Button>
      ) : (
        <Button
          alt="Unenroll"
          title="You are enrolled in the event and cancellation is closed"
          disabled
        >
          Unenroll
        </Button>
      )}

      <Button alt="Check in" onClick={onCheckinButtonClick}>
        Check in
      </Button>
    </Container>
  );
};

CheckinUnenrollButtons.propTypes = {
  enrollmentStatus: PropTypes.string,
  allowCancellation: PropTypes.bool,
  onUnenrollButtonClick: PropTypes.func,
  onCheckinButtonClick: PropTypes.func,
};

export default CheckinUnenrollButtons;
