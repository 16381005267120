import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal } from 'app/shared';
import ButtonLink from 'app/shared/components/ButtonLink';
import Text from 'app/shared/components/Text';
import ReactMarkdown from 'vendor/react-markdown';

const StyledReactMarkdown = styled(ReactMarkdown)`
  > p {
    white-space: pre-wrap;
  }
`;

const Content = styled.div`
  padding: 16px;
`;

const NotificationTagsCheatsheet = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonLink
        size="medium"
        target="_blank"
        icon="support"
        iconPlacement="left"
        onClick={() => setIsOpen(true)}
      >
        <Text size="h5">Need help with Tags?</Text>
      </ButtonLink>

      {isOpen && (
        <Modal handleClose={() => setIsOpen(false)} title="Available tags" width={600}>
          <Content>
            <Text>
              <StyledReactMarkdown>{content}</StyledReactMarkdown>
            </Text>
          </Content>
        </Modal>
      )}
    </>
  );
};

NotificationTagsCheatsheet.propTypes = {
  content: PropTypes.string,
};

export default NotificationTagsCheatsheet;
