/* eslint-disable @typescript-eslint/ban-ts-comment */

import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

import { FACILITATOR_ROLES, CONTENT_TYPES } from 'catalog/constants';
import { toast } from 'notifications/components/NotificationCenter';
import { displayDate, displayDatetime, formatDuration } from 'services/datetime';
import { useEntityMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import * as scheduledTracksService from 'services/scheduled-tracks';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser, useLabels } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import ScheduledTrackForm from 'tracks/components/ScheduledTrackForm';
import { TrackItem } from 'tracks/interfaces';
import { filter, map, get, isEmpty, omit, isNil } from 'vendor/lodash';

// These interfaces are temporary to help the development, we already have a task to export our API to TS:
// https://app.asana.com/0/1199128744696541/1203616998805524/f

interface User {
  id: number;
}

interface Facilitator {
  user: User;
}

interface SlackChannel {
  name: string;
}

interface ScheduledTrack {
  id: number | null;
  public_id: number | null;
  public_id_and_slug: string | null;
  content_type?: string;
  name: string;
  facilitators: Facilitator[];
  track_items: TrackItem[];
  slack_channel: string;
  maintainers_ids: number[];
  location_id?: number;
  is_online: boolean;
  is_local: boolean;
  original_track_id: number;
  track_items_names: string[];
  capacity: number;
  starts_at: string;
  ends_at: string;
}

interface ScheduledTrackEdit extends Omit<ScheduledTrack, 'slack_channel'> {
  slack_channel: SlackChannel;
}

interface Track {
  id: number;
  name: string;
  track_items: TrackItem[];
  facilitators: Facilitator[];
}

interface ScheduledTrackPreForm {
  name: string;
  slackChannel: string;
  track: Track;
  locationId?: number;
  capacity: number;
  starts_at: string;
  ends_at: string;
}

interface BreadcrumbItem {
  label: string;
  link?: string;
}

interface ScheduledTrackFormPageProps {
  pageTitle: string;
  topBarActionName: string;
  backRoute: string;
  breadcrumbsItemList: BreadcrumbItem[];
  initialValuesParams?: ScheduledTrackEdit;
}

const getEditInitialValues = (currentUser: User, initialValuesParams?: ScheduledTrackEdit) => {
  if (isNil(initialValuesParams)) return null;
  const customInitialTags = sortCustomTags(currentUser, initialValuesParams, 'tags');

  const maintainersIds = map(
    filter(initialValuesParams.facilitators, ['role', FACILITATOR_ROLES.author]),
    ({ user }) => user.id
  );

  const trackItems: TrackItem[] = map(initialValuesParams.track_items, (trackItem) => {
    const { content_item: contentItem } = trackItem;

    if (contentItem.content_type === CONTENT_TYPES.event) {
      const humanizedDate = `${displayDatetime(
        get(contentItem, 'local_time', contentItem.utc_datetime),
        contentItem.timezone
      )} - ${formatDuration(contentItem.duration)}`;

      return {
        ...trackItem,
        content_item: {
          ...contentItem,
          humanizedDate,
        },
      };
    }

    const humanizedAssignmentDueDate = trackItem.assignment_due_date
      ? `Due ${displayDate(trackItem.assignment_due_date, null)}`
      : undefined;

    return { ...trackItem, humanizedAssignmentDueDate };
  });

  const areAllItemsInOrder =
    scheduledTracksService.checkIfAllItemsAreInChronologicalOrder(trackItems);

  const areAllItemsInDateRange = scheduledTracksService.checkIfAllItemsAreInDateRange(
    trackItems,
    initialValuesParams.starts_at,
    initialValuesParams.ends_at
  );

  return {
    ...initialValuesParams,
    ...customInitialTags,
    maintainers_ids: maintainersIds,
    track_items: trackItems,
    slack_channel: get(initialValuesParams, 'slack_channel.name', null),
    // Alerts
    unordered_items_alert: {
      display: !areAllItemsInOrder,
      dismissed: false,
    },
    items_out_of_date_range_alert: {
      display: !areAllItemsInDateRange,
      dismissed: false,
    },
  };
};

const getInitialValues = (
  currentUser: User,
  initialValuesParams?: ScheduledTrackEdit,
  preForm?: ScheduledTrackPreForm
) => {
  if (isEmpty(preForm)) return getEditInitialValues(currentUser, initialValuesParams);
  if (isNil(preForm)) return null;

  const { name, slackChannel, track, locationId, capacity, starts_at, ends_at } = preForm;

  const partialTrack = omit(track, [
    'id',
    'name',
    'public_id',
    'public_id_and_slug',
    'track_items',
    'track_items_names',
  ]);

  const values: ScheduledTrack = {
    id: null,
    public_id: null,
    public_id_and_slug: null,
    name,
    slack_channel: slackChannel,
    location_id: locationId,
    is_online: true,
    is_local: true,
    original_track_id: track.id,
    track_items_names: [],
    capacity,
    starts_at,
    ends_at,
    facilitators: track.facilitators,
    maintainers_ids: map(
      filter(track.facilitators, ['role', FACILITATOR_ROLES.author]),
      ({ user }) => user.id
    ),
    track_items: map(track.track_items, (trackItem): TrackItem => {
      const humanizedAssignmentDueDate = ends_at ? `Due ${displayDate(ends_at)}` : undefined;
      return {
        ...trackItem,
        assignment_due_date: ends_at
          ? moment(ends_at).endOf('day').format('YYYY-MM-DDThh:mm:s')
          : null,
        humanizedAssignmentDueDate,
      };
    }),
    ...partialTrack,
  };

  const customTagsInitialValues = sortCustomTags(currentUser, values, 'tags');

  const areAllItemsInOrder = scheduledTracksService.checkIfAllItemsAreInChronologicalOrder(
    values.track_items
  );

  const areAllItemsInDateRange = scheduledTracksService.checkIfAllItemsAreInDateRange(
    values.track_items,
    values.starts_at,
    values.ends_at
  );

  return {
    ...values,
    ...customTagsInitialValues,
    // Alerts
    unordered_items_alert: {
      display: !areAllItemsInOrder,
      dismissed: false,
    },
    items_out_of_date_range_alert: {
      display: !areAllItemsInDateRange,
      dismissed: false,
    },
  };
};

const ScheduledTrackFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
}: ScheduledTrackFormPageProps) => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { label_track: labelTrack } = useLabels();
  const { trackEntityActivity } = useEntityMetrics();

  const preForm = get(history, 'location.state.scheduledTrack', null);
  // Use state here is necessary because the location state is cleared if the user clicks on a section in the sidebar
  const [initialValues, setInitialValues] = useState<ScheduledTrack | null>(null);

  const updateInitialValues = useCallback(() => {
    if (isNil(initialValues)) {
      setInitialValues(getInitialValues(currentUser, initialValuesParams, preForm));
    }
  }, [initialValues, currentUser, initialValuesParams, preForm]);

  useEffect(() => {
    updateInitialValues();
  }, [updateInitialValues]);

  const isEdit = Boolean(initialValuesParams);
  const form = `newScheduledTrack${isEdit ? 'Edit' : 'Create'}Form`;

  if (isNil(initialValues)) return null;

  return (
    <>
      <PageTitle title={pageTitle} />
      <ScheduledTrackForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result: ScheduledTrack) => {
          toast.success(`Scheduled ${labelTrack} saved successfully!`);

          trackEntityActivity({
            id: result.id,
            isEdit,
            entityType: result.content_type,
          });

          history.push(
            mapRoute('scheduledTrackDetails', { public_id_and_slug: result.public_id_and_slug })
          );
        }}
      />
    </>
  );
};

export default ScheduledTrackFormPage;
