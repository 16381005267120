import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPE_COLOR_MAPPING } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
`;

const TypeIcon = styled(Icon)`
  margin-right: 8px;
`;

const TypeIconContainer = styled.div`
  display: flex;
`;

const DurationContainer = styled.div``;

const ContentTypeContainer = ({ contentType, duration }) => {
  const learningTypeLabels = useLearningTypeLabels();
  return (
    <Container>
      <TypeIconContainer>
        <TypeIcon
          width={16}
          height={16}
          name={LEARNING_TYPE_ICONS_MAPPING[contentType]}
          color={LEARNING_TYPE_COLOR_MAPPING[contentType].color600}
        />
        <Text color={LEARNING_TYPE_COLOR_MAPPING[contentType].color600} medium>
          {learningTypeLabels[contentType]}
        </Text>
      </TypeIconContainer>
      {duration && (
        <DurationContainer>{moment.utc(duration * 1000).format('HH:mm:ss')}</DurationContainer>
      )}
    </Container>
  );
};

ContentTypeContainer.propTypes = {
  contentType: PropTypes.oneOf(['articles', 'courses', 'videos', 'event_types']),
  duration: PropTypes.number,
};

export default ContentTypeContainer;
