import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TextInput from 'inputs/components/TextInput';

const TextInputContainer = styled.div`
  margin-top: 8px;
`;

const SurveyQuestionTextArea = ({ input }) => (
  <TextInputContainer>
    <TextInput multiline rows={3} inputProps={input} />
  </TextInputContainer>
);

SurveyQuestionTextArea.propTypes = {
  input: PropTypes.object,
};

export default SurveyQuestionTextArea;
