import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'services/colors';
import CardFooter from 'shared/components/Card/CardFooter';
import CardHeader from 'shared/components/Card/CardHeader';
import Text from 'shared/components/Text';
import UserSmallerPillList from 'shared/components/UserSmallerPillList';

const Container = styled.div`
  position: relative;
  width: 312px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.neutral0};
  border-radius: 4px;
`;

const CardBody = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 280px;
`;

const CardBodyHeader = styled.div`
  padding: 12px 16px 0;
`;

const CardBodyContent = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 14px;
  padding: 0 16px;
  color: ${colors.neutral600};

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 90%, white);
    pointer-events: none;
  }
`;

const Title = styled.h3`
  margin-top: 4px;
  word-break: break-word;
`;

export const DescriptionStyled = styled.div`
  max-height: 295px;
  overflow: hidden;
  mask-image: linear-gradient(white, transparent 80%);

  * > * {
    margin-top: 0;
  }
`;

const BaseCard = ({
  additionalFooterContent,
  headerContent,
  cover,
  description,
  externalContentLabel,
  finishedTypeLabel,
  icon,
  isHeaderContentDisabled,
  isFinished,
  isNew,
  isUnpublished,
  owners,
  ratingProps,
  finishedProps,
  route,
  duration,
  tags,
  title,
  type,
  disableAllClicks,
  ariaLabel,
  isArchived,
}) => {
  const LinkComponent = !disableAllClicks ? Link : React.Fragment;
  const linkComponentProps = !disableAllClicks ? { to: route, 'aria-label': ariaLabel } : {};

  return (
    <Container>
      <CardHeader
        cover={cover}
        icon={icon}
        type={type}
        headerContent={headerContent}
        isHeaderContentDisabled={isHeaderContentDisabled}
        isFinished={isFinished}
        isUnpublished={isUnpublished}
        finishedTypeLabel={finishedTypeLabel}
        isNew={isNew}
        externalContentLabel={externalContentLabel}
        route={route}
        disableAllClicks={disableAllClicks}
      />
      <CardBody>
        <CardBodyHeader>
          <LinkComponent {...linkComponentProps}>
            <Title>
              <Text color={colors.neutral900} size="h2">
                {title}
              </Text>
            </Title>
          </LinkComponent>
          <UserSmallerPillList users={owners} maxUsers={2} labelMaxLength={70} isCard />
        </CardBodyHeader>
        <CardBodyContent>
          <Text color={colors.neutral600} size="h5">
            {description}
          </Text>
        </CardBodyContent>
        <CardFooter
          additionalFooterContent={additionalFooterContent}
          duration={duration}
          tags={tags}
          isFinished={isFinished}
          finishedProps={finishedProps}
          ratingProps={ratingProps}
          isArchived={isArchived}
        />
      </CardBody>
    </Container>
  );
};

BaseCard.defaultProps = {
  disableAllClicks: false,
  isArchived: false,
};

BaseCard.propTypes = {
  additionalFooterContent: PropTypes.node,
  headerContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  cover: PropTypes.string,
  description: PropTypes.node,
  externalContentLabel: PropTypes.string,
  finishedTypeLabel: PropTypes.string,
  icon: PropTypes.string,
  isHeaderContentDisabled: PropTypes.bool,
  isFinished: PropTypes.bool,
  isNew: PropTypes.bool,
  isUnpublished: PropTypes.bool,
  owners: PropTypes.arrayOf(PropTypes.object),
  ratingProps: PropTypes.object,
  finishedProps: PropTypes.object,
  route: PropTypes.string,
  duration: PropTypes.string,
  tags: PropTypes.array,
  title: PropTypes.any,
  type: PropTypes.string,
  disableAllClicks: PropTypes.bool,
  ariaLabel: PropTypes.string,
  isArchived: PropTypes.bool,
};

export default BaseCard;
