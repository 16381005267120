import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EventTypeListItem from 'event-types/components/EventTypeListItem';
import colors from 'services/colors';
import { OldIcon } from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_LOADING_MORE } from 'shared/constants';
import { useLabels } from 'shared/hooks';
import { isEmpty, map } from 'vendor/lodash';

const EmptyListMessage = styled.div`
  padding: 24px 48px;
  text-align: center;
`;

const SearchIcon = styled(OldIcon)`
  color: ${colors.neutral400};
  font-size: 32px;
`;

const EventTypeLoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ show }) => (show ? '25px 0 25px 0' : '0')};
`;

const EventTypeList = ({
  eventTypes,
  eventTypesStatus,
  handleItemClick,
  nextPage,
  fetchNextPage,
}) => {
  const { label_event_type_plural: labelEventTypePlural } = useLabels();

  if (eventTypesStatus !== STATUS_DONE && eventTypesStatus !== STATUS_LOADING_MORE) {
    return <Loading />;
  }

  if (isEmpty(eventTypes)) {
    return (
      <EmptyListMessage>
        <SearchIcon name="magnify" />
        <Text block size="h5" color={colors.neutral400}>
          Sorry, we couldn’t find any {labelEventTypePlural} matching your search.
        </Text>
      </EmptyListMessage>
    );
  }

  return (
    <React.Fragment>
      {map(eventTypes, (eventType) => (
        <EventTypeListItem
          key={eventType.public_id}
          eventType={eventType}
          handleClick={handleItemClick}
        />
      ))}
      <EventTypeLoadMoreWrapper
        show={Boolean(nextPage) || eventTypesStatus === STATUS_LOADING_MORE}
      >
        <LoadMoreButton
          isLoading={eventTypesStatus === STATUS_LOADING_MORE}
          show={Boolean(nextPage)}
          onClick={fetchNextPage}
          size="large"
        />
      </EventTypeLoadMoreWrapper>
    </React.Fragment>
  );
};

EventTypeList.propTypes = {
  eventTypes: PropTypes.array,
  eventTypesStatus: PropTypes.string,
  handleItemClick: PropTypes.func,
  nextPage: PropTypes.string,
  fetchNextPage: PropTypes.func,
};

export default EventTypeList;
