import {
  DASHBOARD_TYPES,
  DASHBOARD_TYPE_ICONS_MAPPING,
  DASHBOARD_TYPES_URL_MAPPING,
} from 'scenes/Dashboard/constants';
import { displayDatetime } from 'services/datetime';
import { mapRoute } from 'services/requests';
import { map } from 'vendor/lodash';

export const buildSegmentData = ({
  name,
  description,
  content_type,
  created,
  created_by,
  public_id,
}) => {
  const display_name = created_by?.display_name ?? '';

  return {
    public_id,
    name,
    description,
    created: displayDatetime(created),
    created_by: display_name,
    url: mapRoute(DASHBOARD_TYPES_URL_MAPPING[content_type], { public_id }),
  };
};

export const DASHBOARD_SEGMENT_ACTIONS = {
  edit: { title: 'Edit', icon: 'pencil' },
  remove: { title: 'Remove', icon: 'delete' },
};

export const buildDashboardSegmentsList = ({
  data,
  totalCount,
  label,
  busyStateOnClickHandler,
  editOnClickHandler,
  removeOnClickHandler,
}) => {
  if (!data || totalCount === 0) return [];

  let entries = map(data, (segment) => {
    const { name: label, description: tooltipContent, public_id, content_type } = segment;
    const url = mapRoute(DASHBOARD_TYPES_URL_MAPPING[content_type], { public_id });
    return {
      label,
      tooltipContent,
      url,
      contextMenuItems: [
        { ...DASHBOARD_SEGMENT_ACTIONS.edit, onClick: () => editOnClickHandler(segment) },
        { ...DASHBOARD_SEGMENT_ACTIONS.remove, onClick: () => removeOnClickHandler(segment) },
      ],
    };
  });

  // If there are more than 5 segments stored in the DB
  if (totalCount > 5) {
    entries = [...entries, { label: 'Show All', onClick: busyStateOnClickHandler }];
  }

  return [
    {
      label: `All ${label}`,
      url: mapRoute(DASHBOARD_TYPES_URL_MAPPING[data[0].content_type]),
    },
    ...entries,
  ];
};

export const getDashboardMetadata = (type, labels) => {
  const {
    label_event_type_plural: labelEventTypePlural,
    label_program: labelProgram,
    label_mentorship_tab: labelMentorship,
    label_track_plural: labelTrackPlural,
  } = labels;

  const metadata = {
    [DASHBOARD_TYPES.analytics]: {
      type: DASHBOARD_TYPES.analytics,
      label: 'Analytics',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.analytics],
      url: mapRoute('dashboardAnalytics'),
    },
    [DASHBOARD_TYPES.events]: {
      type: DASHBOARD_TYPES.events,
      label: 'Events',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.events],
      url: mapRoute('dashboardEvents'),
    },
    [DASHBOARD_TYPES.content_items]: {
      type: DASHBOARD_TYPES.content_items,
      label: 'Content Items',
      icon: 'source',
      url: mapRoute('dashboardContentItems'),
    },
    [DASHBOARD_TYPES.event_types]: {
      type: DASHBOARD_TYPES.event_types,
      label: labelEventTypePlural,
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.event_types],
      url: mapRoute('dashboardEventTypes'),
    },
    [DASHBOARD_TYPES.events_enrollments]: {
      type: DASHBOARD_TYPES.events_enrollments,
      label: 'Event Enrollments',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.events_enrollments],
      url: mapRoute('dashboardEnrollments'),
    },
    [DASHBOARD_TYPES.programs]: {
      type: DASHBOARD_TYPES.programs,
      label: `${labelProgram} Sessions`,
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.programs],
      url: mapRoute('dashboardPrograms'),
    },
    [DASHBOARD_TYPES.program_sessions_enrollments]: {
      type: DASHBOARD_TYPES.program_sessions_enrollments,
      label: `${labelProgram} Enrollments`,
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.program_sessions_enrollments],
      url: mapRoute('dashboardProgramSessionEnrollments'),
    },
    [DASHBOARD_TYPES.mentorship]: {
      type: DASHBOARD_TYPES.mentorship,
      label: `${labelMentorship} Sessions`,
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.mentorship],
      url: mapRoute('dashboardMentorships'),
    },
    [DASHBOARD_TYPES.assignments]: {
      type: DASHBOARD_TYPES.assignments,
      label: 'Assignments',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.assignments],
      url: mapRoute('dashboardAssignments'),
    },
    [DASHBOARD_TYPES.assessments]: {
      type: DASHBOARD_TYPES.assessments,
      label: 'Assessments',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.assessments],
      url: mapRoute('dashboardAssessments'),
    },
    [DASHBOARD_TYPES.people]: {
      type: DASHBOARD_TYPES.people,
      label: 'People',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.people],
      url: mapRoute('dashboardPeople'),
    },
    [DASHBOARD_TYPES.user_loads]: {
      type: DASHBOARD_TYPES.user_loads,
      label: 'User Loads',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.user_loads],
      url: mapRoute('dashboardUserLoads'),
    },
    [DASHBOARD_TYPES.bulk_user_loads]: {
      type: DASHBOARD_TYPES.bulk_user_loads,
      label: 'People Integration Syncs',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.bulk_user_loads],
      url: mapRoute('dashboardBulkUserLoads'),
    },
    [DASHBOARD_TYPES.groups]: {
      type: DASHBOARD_TYPES.groups,
      label: 'Groups',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.groups],
      url: mapRoute('dashboardGroups'),
    },
    [DASHBOARD_TYPES.surveys]: {
      type: DASHBOARD_TYPES.surveys,
      label: 'Surveys',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.surveys],
      url: mapRoute('dashboardSurveys'),
    },
    [DASHBOARD_TYPES.scheduled_tracks]: {
      type: DASHBOARD_TYPES.scheduled_tracks,
      label: `Scheduled ${labelTrackPlural}`,
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.scheduled_tracks],
      url: mapRoute('dashboardScheduledTracks'),
    },
    [DASHBOARD_TYPES.locations]: {
      type: DASHBOARD_TYPES.locations,
      label: 'Locations',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.locations],
      url: mapRoute('dashboardLocations'),
    },
    [DASHBOARD_TYPES.automated_rules]: {
      type: DASHBOARD_TYPES.automated_rules,
      label: 'Automated Rules',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.automated_rules],
      url: mapRoute('dashboardAutomatedRules'),
    },
    [DASHBOARD_TYPES.logs]: {
      type: DASHBOARD_TYPES.logs,
      label: 'Logs',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.logs],
      url: mapRoute('dashboardLogs'),
    },
    [DASHBOARD_TYPES.active_users]: {
      type: DASHBOARD_TYPES.active_users,
      label: 'Active Users',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.people],
      url: mapRoute('dashboardActiveUsers'),
    },
    [DASHBOARD_TYPES.badges]: {
      type: DASHBOARD_TYPES.badges,
      label: 'Badges',
      icon: DASHBOARD_TYPE_ICONS_MAPPING[DASHBOARD_TYPES.badges],
      url: mapRoute('dashboardBadges'),
    },
  };
  return metadata[type];
};
