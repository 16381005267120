import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BaseGroupCard } from 'scenes/MyGroups/styles';
import colors from 'services/colors';
import { formatDate } from 'services/datetime';
import { mapRoute } from 'services/requests';
import { OldIcon } from 'shared/components/Icon';
import Text from 'shared/components/Text';

const GroupCardContainer = styled(BaseGroupCard)`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`;

const CardBody = styled.div`
  padding: 16px;
`;

const CardTitle = styled(Text)`
  letter-spacing: -0.43px;
`;

const CardInfo = styled(Text)`
  letter-spacing: -0.34px;
  margin-top: 4px;
`;

const CardLink = styled(Link)`
  color: ${colors.neutral900};
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: ${colors.neutral900};
  }
`;

const EditLink = styled(Link)`
  padding: 8px;
  align-self: flex-start;
`;

const EditIcon = styled(OldIcon)`
  line-height: 20px;
`;

const GroupCard = ({ group, userTimezone }) => (
  <GroupCardContainer>
    <CardBody>
      <CardTitle medium block size="h3">
        <CardLink to={mapRoute('groupDetails', { id: `${group.id}` })}>{group.name}</CardLink>
      </CardTitle>
      <CardInfo block size="h5">
        {group.owners_count} {group.owners_count === 1 ? 'owner' : 'owners'}
      </CardInfo>
      <CardInfo block size="h5">
        {group.members_count} {group.members_count === 1 ? 'member' : 'members'}
      </CardInfo>
      <CardInfo block size="h5">
        Last edited on {formatDate(moment(group.modified), userTimezone)}
      </CardInfo>
    </CardBody>

    <EditLink to={`${mapRoute('groupEdit', { id: `${group.id}` })}?origin=${mapRoute('myGroups')}`}>
      <EditIcon name="square-edit-outline" color={colors.neutral200} />
    </EditLink>
  </GroupCardContainer>
);

GroupCard.propTypes = {
  group: PropTypes.object,
  userTimezone: PropTypes.string,
};

export default GroupCard;
