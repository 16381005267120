/* eslint-disable lodash/prefer-lodash-method */
import { closestCenter, DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import equal from 'deep-equal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import BulkDeleteContentItemConfirmationModal from 'backoffice/components/Dashboard/BulkDeleteContentItemConfirmationModal';
import DashboardFilterBar from 'backoffice/components/Dashboard/DashboardFilterBar';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import propTypes from 'services/prop-types';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import BreadcrumbProptypes from 'shared/components/Breadcrumbs/types';
import Clicker from 'shared/components/Clicker';
import ConfirmationModal from 'shared/components/ConfirmationModal/ConfirmationModal';
import { OldIcon } from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import { STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import {
  map,
  ceil,
  isEmpty,
  includes,
  unionBy,
  differenceBy,
  find,
  toInteger,
  filter,
  size,
  pickBy,
  isNil,
} from 'vendor/lodash';
import { Box, Pagination } from 'vendor/mui';

import ActionsBar, { ActionButton } from './ActionsBar';
import BulkActionsBar from './BulkActionsBar';
import * as cells from './Cells';
import Column from './Column';
import ExportCsvButton from './ExportCsvButton';
import * as filters from './Filters';
import HeaderCell from './HeaderCell';
import * as lazyFilters from './LazyFilters';
import MenuColumn, { MenuColumnItem } from './MenuColumn';
import SelectorColumn, { SelectorIndicator, SELECTED_ITEMS_STATUS } from './SelectorColumn';

const EmptyTableContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const TableWrapper = styled.div`
  min-height: 570px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const THead = styled.thead`
  width: 100%;
`;

const HRow = styled.tr`
  height: 38px;
  width: 100%;
`;

const HData = styled.th`
  color: ${colors.neutral900};
  padding: 8px 16px;
  width: ${({ size }) => size || 3}%;

  // padding-right includes distance between this content and the one on the right
  ${({ alignRight }) =>
    alignRight &&
    `
    text-align: right;
    padding-right: 40px;

    ${Clicker} {
      float: right;
    };
  `}
`;

const TBody = styled.tbody``;

const StyledRow = styled.tr`
  margin: 0 0 1px 0;
  background-color: #ffffff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${colors.neutral100};
  `}
  &:hover {
    background-color: ${colors.neutral100};
  }
`;

const Row = ({ rowId, children, isSelected, ...rest }) => {
  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: rowId,
  });

  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledRow
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isSelected={isSelected}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      role="row"
      {...rest}
    >
      {children({
        isHovered,
        dragAndDropSetNodeRef: setNodeRef,
        dragAndDropAttrributesAndListeners: { ...attributes, ...listeners },
      })}
    </StyledRow>
  );
};

Row.propTypes = {
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool,
  children: propTypes.anyChildren,
};

const RowSpacer = styled.tr`
  height: 16px;
  background-color: transparent;
`;

const Container = styled.div`
  padding: 0 54px;
`;

const TableTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Dashboard = ({
  initialFilters,
  defaultFilters,
  rows,
  idAccessor,
  onChange,
  onReorder,
  children,
  pageSize,
  totalCount,
  filterComponentMapping,
  defaultFilterNames,
  availableFilterComponentMapping,
  defaultAvailableFilterNames,
  actionBar,
  bulkBar,
  className,
  requestStatus,
  breadcrumbItems,
  dashboardName,
}) => {
  const { trackActivity } = useMetrics();

  const [currentFilters, setCurrentFilters] = useState({
    page: '1',
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dragAndDropSensors = [useSensor(PointerSensor)];

  // This useEffect is triggering the double request.
  // To fix this is necessary to change the dashboard architecture,
  // but as we are planning to move all dashboard pages to use RQL,
  // and this is already fized there, this will be this way for now.
  useEffect(() => {
    applyChanges({
      ...currentFilters,
      ...initialFilters,
    });
  }, []);

  useEffect(() => {
    const normalizedCurrentFilters = pickBy(currentFilters, (f) => !isEmpty(f));
    setIsFiltered(!equal(normalizedCurrentFilters, { ...defaultFilters, page: '1' }));
  }, [currentFilters]);

  const getSelectedItemsPageStatus = () => {
    const difference = differenceBy(rows, selectedItems, idAccessor);

    if (difference.length === rows.length) {
      return SELECTED_ITEMS_STATUS.none;
    }
    if (difference.length === 0) {
      return SELECTED_ITEMS_STATUS.all;
    }

    return SELECTED_ITEMS_STATUS.partial;
  };

  const onSelectAllClick = () => {
    const status = getSelectedItemsPageStatus();

    if (includes([SELECTED_ITEMS_STATUS.none, SELECTED_ITEMS_STATUS.partial], status)) {
      setSelectedItems((selectedItems) => unionBy(selectedItems, rows, idAccessor));
    } else {
      setSelectedItems((selectedItems) => differenceBy(selectedItems, rows, idAccessor));
    }
  };

  const handlePageChange = (_, page) => {
    window.scrollTo(0, 60);

    applyChanges({
      ...currentFilters,
      page,
    });
  };

  const handleFilterChange = (changedFilters) => {
    applyChanges({ ...changedFilters, page: '1' });
  };

  const refreshDashboard = () => {
    applyChanges(currentFilters);
  };

  const applyChanges = (newFilters) => {
    onChange?.(newFilters);
    setCurrentFilters({ ...newFilters });
  };

  const addToSelectedItems = (item) => {
    setSelectedItems((selectedItems) => unionBy(selectedItems, [item], idAccessor));
  };

  const removeFromSelectedItems = (item) => {
    setSelectedItems((selectedItems) => differenceBy(selectedItems, [item], idAccessor));
  };

  const handleDelete = () => {
    trackActivity(METRICS_ACTIVITIES.DASHBOARD_BULK_ACTION, {
      bulkAction: 'delete',
      itemCount: size(selectedItems),
      dashboardName,
    });
    setSelectedItems([]);
  };

  const isLoading = includes([STATUS_LOADING, STATUS_LOADING_MORE], requestStatus);

  const nonNullChildren = filter(children, (item) => Boolean(item));

  const dashboardBreadcrumbItems = breadcrumbItems || [];

  const paginationCount = ceil(totalCount / pageSize);
  const paginationDefaultPage = toInteger(currentFilters?.page);

  return (
    <Container className={className}>
      <div>
        {dashboardBreadcrumbItems && <Breadcrumbs items={dashboardBreadcrumbItems} hasMargin />}
        {!isNil(filterComponentMapping) && (
          <DashboardFilterBar
            dashboardName={dashboardName}
            filterComponentMapping={filterComponentMapping}
            defaultFilterNames={defaultFilterNames}
            availableFilterComponentMapping={availableFilterComponentMapping}
            defaultAvailableFilterNames={defaultAvailableFilterNames}
            handleFilterChange={handleFilterChange}
            currentFilters={currentFilters}
            hasSelectedItems={!isEmpty(selectedItems)}
          />
        )}
        <TableTopBar>
          {size(selectedItems) === 0 &&
            actionBar &&
            React.cloneElement(actionBar, {
              totalCount,
              isFiltered,
              isLoading,
              refreshDashboard,
              selectedItems,
            })}
          {bulkBar &&
            React.cloneElement(bulkBar, {
              totalCount,
              selectedItems,
              onClearClick: handleDelete,
              refreshDashboard,
            })}
        </TableTopBar>
      </div>
      <TableWrapper>
        <Table>
          <THead>
            <HRow>
              {!isEmpty(bulkBar) && (
                <HData size="4">
                  <SelectorIndicator
                    status={getSelectedItemsPageStatus()}
                    onClick={onSelectAllClick}
                  />
                </HData>
              )}
              {React.Children.map(nonNullChildren, ({ props: { header, size, alignRight } }) => (
                <HData size={size} alignRight={alignRight}>
                  {React.isValidElement(header)
                    ? React.cloneElement(header, {
                        onFilterChange: handleFilterChange,
                        selectedFilters: currentFilters,
                      })
                    : header}
                </HData>
              ))}
            </HRow>
          </THead>
          <TBody>
            <DndContext
              sensors={dragAndDropSensors}
              collisionDetection={closestCenter}
              onDragEnd={onReorder}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={map(rows, idAccessor)} strategy={verticalListSortingStrategy}>
                {!isLoading &&
                  map(rows, (row, rowIndex) => {
                    const id = idAccessor ? row[idAccessor] : row.id;
                    const isSelected = !isEmpty(find(selectedItems, [idAccessor, id]));

                    return (
                      <React.Fragment key={id}>
                        <RowSpacer />
                        <Row isSelected={isSelected} rowId={row[idAccessor]}>
                          {({
                            isHovered,
                            dragAndDropAttrributesAndListeners,
                            dragAndDropSetNodeRef,
                          }) => (
                            <>
                              {!isEmpty(bulkBar) && (
                                <SelectorColumn
                                  id={id}
                                  item={row}
                                  isSelected={isSelected}
                                  addToSelectedItems={addToSelectedItems}
                                  removeFromSelectedItems={removeFromSelectedItems}
                                />
                              )}

                              {React.Children.map(nonNullChildren, (child, index) =>
                                React.cloneElement(child, {
                                  isHovered,
                                  row: {
                                    ...row,
                                    dragAndDropSetNodeRef,
                                    dragAndDropAttrributesAndListeners,
                                  },
                                  rows,
                                  highlighted: index === 0,
                                  index: rowIndex,
                                })
                              )}
                            </>
                          )}
                        </Row>
                      </React.Fragment>
                    );
                  })}
              </SortableContext>
            </DndContext>
          </TBody>
        </Table>
        {isLoading && <Loading />}
        {!isLoading && isEmpty(rows) && (
          <EmptyTableContainer>
            <div>
              <OldIcon name="magnify" size="100" color={colors.neutral600} />
            </div>
            <Text h4 color={colors.neutral400}>
              Sorry, we couldn’t find any results matching your search.
            </Text>
          </EmptyTableContainer>
        )}
      </TableWrapper>

      {!isLoading && !isEmpty(rows) && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" paddingY={2.5}>
          <Pagination
            size="medium"
            color="primary"
            variant="outlined"
            shape="rounded"
            count={paginationCount}
            defaultPage={paginationDefaultPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
  dashboardName: PropTypes.string,

  children: PropTypes.array,
  rows: PropTypes.array,
  requestStatus: PropTypes.string,
  filterComponentMapping: PropTypes.object,
  defaultFilterNames: PropTypes.arrayOf(PropTypes.string),
  availableFilterComponentMapping: PropTypes.object,
  defaultAvailableFilterNames: PropTypes.arrayOf(PropTypes.string),
  initialFilters: PropTypes.object,
  defaultFilters: PropTypes.object,

  bulkBar: PropTypes.node,
  actionBar: PropTypes.node,
  breadcrumbItems: PropTypes.arrayOf(BreadcrumbProptypes.Item),

  idAccessor: PropTypes.string,

  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,

  onChange: PropTypes.func,
  onReorder: PropTypes.func,
};

Dashboard.defaultProps = {
  idAccessor: 'id',
  actionBar: <ActionsBar />,
  defaultFilters: {},
};

Dashboard.Header = HeaderCell;
Dashboard.Column = Column;
Dashboard.MenuColumn = MenuColumn;
Dashboard.MenuColumnItem = MenuColumnItem;

Dashboard.BooleanCell = cells.BooleanCell;
Dashboard.DateTimeCell = cells.DateTimeCell;
Dashboard.DateCell = cells.DateCell;
Dashboard.DateTimeRangeCell = cells.DateTimeRangeCell;
Dashboard.MonthDateCell = cells.MonthDateCell;
Dashboard.UserCell = cells.UserCell;
Dashboard.MultipleCell = cells.MultipleCell;
Dashboard.MultipleUserCell = cells.MultipleUserCell;
Dashboard.LocationCell = cells.LocationCell;
Dashboard.CellLink = cells.CellLink;
Dashboard.TimeslotCell = cells.TimeslotCell;
Dashboard.TextArea = cells.TextArea;
Dashboard.TextCell = cells.TextCell;
Dashboard.HorizontalStackedBarCell = cells.HorizontalStackedBarCell;
Dashboard.AssignmentStateCell = cells.AssignmentStateCell;

Dashboard.TextFilter = filters.TextFilter;
Dashboard.DropdownFilter = filters.DropdownFilter;
Dashboard.LazyDropdownFilter = lazyFilters.LazyDropdownFilter;
Dashboard.ContentItemFilter = lazyFilters.ContentItemFilter;
Dashboard.EventFilter = lazyFilters.EventFilter;
Dashboard.EventTypeFilter = lazyFilters.EventTypeFilter;
Dashboard.GroupFilter = lazyFilters.GroupFilter;
Dashboard.LocationFilter = lazyFilters.LocationFilter;
Dashboard.ProgramFilter = lazyFilters.ProgramFilter;
Dashboard.TopicFilter = lazyFilters.TopicFilter;
Dashboard.UserFilter = lazyFilters.UserFilter;
Dashboard.PeriodFilter = filters.PeriodFilter;

Dashboard.ActionsBar = ActionsBar;
Dashboard.BulkActionsBar = BulkActionsBar;
Dashboard.ActionButton = ActionButton;
Dashboard.ConfirmationModal = ConfirmationModal;
Dashboard.BulkDeleteContentItemConfirmationModal = BulkDeleteContentItemConfirmationModal;
Dashboard.ExportCsvButton = ExportCsvButton;

export default Dashboard;
