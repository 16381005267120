import { toast } from 'notifications/components/NotificationCenter';
import { ATTENDANCE_TYPE_MULTIPLE_ATTENDEES } from 'program/constants';
import { pick, size } from 'vendor/lodash';

const getBaseSubmitPayload = (values, canCreateForOthers, currentUser) => {
  const payload = {
    ...pick(values, [
      'host_id',
      'location_id',
      'starts_at',
      'duration',
      'allows_local',
      'room',
      'extra_info',
      'allows_online',
      'meeting_url',
      'online_extra_info',
      'google_calendar_resource_id',
      'google_calendar_resource_email',
      'program_id',
    ]),
  };

  if (!canCreateForOthers) {
    payload.host_id = currentUser.id;
  }

  payload.attendees_ids = [];

  if (!payload.allows_local) {
    payload.room = '';
    payload.extra_info = '';
  }

  if (!payload.allows_online) {
    payload.meeting_url = '';
    payload.online_extra_info = '';
  }

  return payload;
};

export const getProgramSessionSubmitBody = (values, canCreateForOthers, currentUser) => {
  const payload = getBaseSubmitPayload(values, canCreateForOthers, currentUser);
  payload.program_id = values.program_id;

  const isSessionInThePast = values.in_the_past;

  if (values.attendance_type === ATTENDANCE_TYPE_MULTIPLE_ATTENDEES) {
    payload.attendance_limit = isSessionInThePast
      ? size(values.attendance_multiple_ids)
      : values.attendance_limit_multiple === ''
      ? 0
      : values.attendance_limit_multiple;

    payload.attendees_ids = isSessionInThePast ? values.attendance_multiple_ids : [];
  } else {
    payload.attendance_limit = 1;
    payload.attendees_ids = values.attendee_id ? [values.attendee_id] : [];
  }

  return payload;
};

export const getMentorshipSessionSubmitBody = (values, canCreateForOthers, currentUser) => {
  const payload = getBaseSubmitPayload(values, canCreateForOthers, currentUser);

  payload.groups_ids = values.groups_ids;
  payload.attendees_ids = values.attendee_id ? [values.attendee_id] : [];

  return payload;
};

export const onSessionFormSubmitSuccess = (
  result,
  saveAndDuplicate,
  saveAndBulkDuplicate,
  setSessionToBulkDuplicate,
  history,
  isEdit,
  saveRoute,
  saveAndDuplicateRoute
) => {
  const actionName = isEdit ? 'saved' : 'published';

  if (saveAndDuplicate) {
    history.push(saveAndDuplicateRoute);
    toast.success(
      `Session ${actionName}`,
      `Your previous session was ${actionName}, we also added the current form for creating another one!`
    );
    return;
  }

  if (saveAndBulkDuplicate) {
    setSessionToBulkDuplicate(result);
    toast.success(`Session ${actionName}`, `Your previous session was ${actionName}!`);
    return;
  }

  history.push(saveRoute);
  toast.success(`Session ${isEdit ? 'updated' : 'added'} successfully!`);
};
