import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CheckboxInput from 'inputs/components/CheckboxInput';
import SelectField from 'inputs/components/SelectField';
import TextInput from 'inputs/components/TextInput';
import colors from 'services/colors';
import {
  PERIOD_OPTIONS,
  SECONDS_IN_A_HOUR,
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_WEEK,
  SECONDS_IN_A_MONTH,
} from 'settings/constants';
import Text from 'shared/components/Text';
import { isNil, map, keys } from 'vendor/lodash';

const InputsWrapper = styled.div`
  display: flex;
  gap: 12px;

  > :nth-child(2) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }

  > * + * {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
`;

const SettingDatetimeOffsetField = ({
  input,
  settingDefault,
  matter_relations: matterRelations,
}) => {
  const [isEnable, setIsEnable] = useState(null);
  const [offsetValue, setOffsetValue] = useState(null);
  const [offsetPeriod, setOffsetPeriod] = useState(null);
  const [offsetMatter, setOffsetMatter] = useState(null);

  const matterOptions = map(keys(matterRelations), (key) => ({
    value: key,
    label: matterRelations[key],
  }));
  const [beforeOption, afterOption] = matterOptions;
  const [hoursPeriod, daysPeriod, weeksPeriod, monthsPeriod] = PERIOD_OPTIONS;

  // Transform value from backend to frontend
  useEffect(() => {
    const curentValue = input?.value?.value || input?.value?.default;

    if (isNil(curentValue)) {
      if (input?.value?.inherited) {
        setIsEnable(false);
        input.onChange({
          ...input.value,
          inherited: curentValue === input?.value?.parent_value,
        });
      }
      return;
    }

    const {
      value: { value },
    } = input;

    setIsEnable(value !== null);

    setOffsetMatter(value < 0 ? beforeOption.value : afterOption.value);

    const absValue = Math.abs(value);

    if (absValue % SECONDS_IN_A_HOUR === 0) {
      setOffsetPeriod(hoursPeriod.value);
      setOffsetValue(absValue / SECONDS_IN_A_HOUR);
    }
    if (absValue % SECONDS_IN_A_DAY === 0) {
      setOffsetPeriod(daysPeriod.value);
      setOffsetValue(absValue / SECONDS_IN_A_DAY);
    }
    if (absValue % SECONDS_IN_A_WEEK === 0) {
      setOffsetPeriod(weeksPeriod.value);
      setOffsetValue(absValue / SECONDS_IN_A_WEEK);
    }
    if (absValue % SECONDS_IN_A_MONTH === 0) {
      setOffsetPeriod(monthsPeriod.value);
      setOffsetValue(absValue / SECONDS_IN_A_MONTH);
    }
    if (absValue === 0) {
      setOffsetPeriod(hoursPeriod.value);
      setOffsetValue(absValue);
    }
  }, [input]);

  // Handle NEVER value to trigger the input's useEffect
  useEffect(() => {
    if (!isEnable) return;
    if (!isNil(input?.value?.value)) return;
    if (settingDefault !== null) {
      input.onChange({ ...input.value, value: settingDefault, inherited: false });
    } else {
      input.onChange({ ...input.value, value: SECONDS_IN_A_HOUR, inherited: false });
    }
  }, [isEnable]);

  // Transform value from frontend to backend
  useEffect(() => {
    if (isNil(isEnable) || isNil(offsetValue) || isNil(offsetPeriod) || isNil(offsetMatter)) return;

    if (!isEnable) {
      input.onChange({
        ...input.value,
        value: null,
        inherited: input?.value?.value === input?.value?.parent_value,
      });
      return;
    }

    let {
      value: { value: newValue },
    } = input;

    const offsetMatterFactor = offsetMatter === beforeOption.value ? -1 : 1;

    if (offsetPeriod === hoursPeriod.value) {
      newValue = offsetValue * SECONDS_IN_A_HOUR * offsetMatterFactor;
    }
    if (offsetPeriod === daysPeriod.value) {
      newValue = offsetValue * SECONDS_IN_A_DAY * offsetMatterFactor;
    }
    if (offsetPeriod === weeksPeriod.value) {
      newValue = offsetValue * SECONDS_IN_A_WEEK * offsetMatterFactor;
    }
    if (offsetPeriod === monthsPeriod.value) {
      newValue = offsetValue * SECONDS_IN_A_MONTH * offsetMatterFactor;
    }

    if (newValue === input?.value?.value) return;

    input.onChange({ ...input.value, value: newValue, inherited: false });
  }, [isEnable, offsetValue, offsetPeriod, offsetMatter]);

  return (
    <InputsWrapper>
      <ToggleWrapper>
        <CheckboxInput checked={isEnable} onChange={() => setIsEnable(!isEnable)} />
        {!isEnable && (
          <Text size="h5" color={colors.neutral600}>
            Unspecified
          </Text>
        )}
      </ToggleWrapper>

      {isEnable && (
        <>
          <TextInput
            value={offsetValue}
            onChange={(e) => setOffsetValue(e.target.value)}
            type="number"
            inputProps={{
              min: 0,
            }}
          />
          <SelectField
            options={PERIOD_OPTIONS}
            input={{
              value: offsetPeriod,
              onChange: (value) => setOffsetPeriod(value),
            }}
          />
          <SelectField
            options={matterOptions}
            input={{
              value: offsetMatter,
              onChange: (value) => setOffsetMatter(value),
            }}
          />
        </>
      )}
    </InputsWrapper>
  );
};

SettingDatetimeOffsetField.propTypes = {
  input: PropTypes.object,
  settingDefault: PropTypes.number,
  matter_relations: PropTypes.object,
};

export default SettingDatetimeOffsetField;
