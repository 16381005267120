import colors from 'services/colors';
import { Box, styled } from 'vendor/mui';

export interface TextEditorProps {
  minHeight?: string;
  maxHeight?: string;
  maxWidth?: string;
}

export interface TextEditorContainerProps {
  TextEditorProps?: TextEditorProps;
  backgroundColor?: string;
  showToolbarSeparator?: boolean;
  isFocused?: boolean;
  isError?: boolean;
}
export const TextEditorContainer = styled(Box)<TextEditorContainerProps>(
  ({
    theme,
    TextEditorProps: { minHeight, maxHeight, maxWidth } = {
      minHeight: '170px',
      maxHeight: '310px',
      maxWidth: '100%',
    },
    backgroundColor,
    showToolbarSeparator = true,
    isFocused,
    isError,
  }) => ({
    position: 'relative',
    border: `1px solid ${colors.neutral400}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#fff',
    ...(isFocused &&
      !isError && {
        borderColor: colors.action600,
      }),
    ...(isError && {
      borderColor: colors.error600,
    }),

    '& .rdw-editor-toolbar': {
      ...(!showToolbarSeparator && {
        border: 0,
      }),
      padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
      marginBottom: 0,

      '> div': {
        marginBottom: 0,
      },
    },

    '& .rdw-editor-main': {
      minHeight,
      maxHeight,
      maxWidth,
      ...(backgroundColor && {
        backgroundColor,
      }),
      padding: `0 ${theme.spacing(1)}`,
    },
  })
);
