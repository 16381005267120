import React, { useState } from 'react';

import OldCourseImportForm from 'course/components/OldCourseImportForm';
import actions from 'entities/actions';
import { courseSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import BaseContainer from 'navigation/components/BaseContainer';
import PageTitle from 'shared/components/PageTitle';
import { useSetDetailedObject } from 'shared/hooks';

export const CourseImport = () => {
  const [showImportModal, setShowImportModal] = useState(false);

  const [fetchCourse, { data: course }] = useEntities(actions.course.retrieveDetails, null, {
    schema: courseSchema,
  });

  useSetDetailedObject({ id: null }, 'courses', []);

  const onCourseSubmit = (courseId) => {
    setShowImportModal(true);
    fetchCourse(courseId);
  };

  return (
    <BaseContainer>
      <PageTitle title="Import Course" />
      <OldCourseImportForm
        title="Add content"
        course={course}
        fetchCourse={fetchCourse}
        showImportModal={showImportModal}
        onCourseSubmit={onCourseSubmit}
      />
    </BaseContainer>
  );
};

export default CourseImport;
