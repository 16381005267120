import { useEffect, useState } from 'react';
import { Field } from 'redux-form';

import CheckboxField from 'inputs/components/CheckboxField';
import CheckboxInput from 'inputs/components/CheckboxInput';
import InputLabel from 'inputs/components/InputLabel';
import RadioField from 'inputs/components/RadioField';
import SliderField from 'inputs/components/SliderField';
import TextField from 'inputs/components/TextField';
import TextInput from 'inputs/components/TextInput';
import { FormFieldGroup } from 'shared/components/Form';
import { useFormSelector, useLabels } from 'shared/hooks';
import { assessmentFeedbackPolicy } from 'tracks/contants';
import { toInteger, toLower } from 'vendor/lodash';
import { Box, Stack, Divider } from 'vendor/mui';

interface AssessmentSettingsSectionProps {
  form: string;
  changeFn: (fieldName: string, value: any) => void;
}

const AssessmentSettingsSection = ({ form, changeFn }: AssessmentSettingsSectionProps) => {
  const { label_assessment: labelAssessment, label_question: labelQuestion } = useLabels();

  // maxAttempts: how many assessment assignments the user can have.
  // O means unlimited retries, 1 means the user can take the assessment once (no retries)
  const defaultMaxAttempts = 1;
  const maxAttempts = useFormSelector(form, 'max_attempts') || defaultMaxAttempts;
  const [hasRetriesEnabled, setHasRetriesEnabled] = useState(maxAttempts !== defaultMaxAttempts);

  // maxRetries: how many times the user can retake the assessment.
  const [maxRetries, setMaxRetries] = useState(
    maxAttempts === 0 || maxAttempts === 1 ? maxAttempts : maxAttempts - 1
  );

  const passingGradeEnabled = useFormSelector(form, 'passing_score_enabled');

  useEffect(() => {
    if (hasRetriesEnabled) {
      changeFn('max_attempts', maxRetries === 0 ? maxRetries : maxRetries + 1);
    } else {
      changeFn('max_attempts', defaultMaxAttempts);
    }
    // useCallback does not work with redux change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRetriesEnabled, maxRetries]);

  return (
    <>
      <FormFieldGroup>
        <InputLabel htmlFor="feedback_policy" required>
          Feedback Policy
        </InputLabel>
        <Box marginLeft="10px" marginTop="15px">
          <Stack spacing={2}>
            <Field
              component={RadioField}
              name="feedback_policy"
              label={`Provide feedback after ${toLower(labelAssessment)} completion`}
              optionValue={assessmentFeedbackPolicy.afterAssessment}
              info={`The ${toLower(
                labelAssessment
              )} will only provide the report on performance and its breakdown of correct and incorrect answers upon its completion.`}
              noMargin
            />
            <Field
              component={RadioField}
              name="feedback_policy"
              label={`Provide feedback after each ${toLower(labelQuestion)}`}
              optionValue={assessmentFeedbackPolicy.afterQuestion}
              info={`The ${toLower(
                labelAssessment
              )} will provide immediate feedback on performance after each question submission, indicating whether the answer was correct or incorrect.`}
            />
          </Stack>
        </Box>
      </FormFieldGroup>

      <Divider sx={{ marginY: 2 }} />

      <FormFieldGroup>
        <CheckboxInput
          label="Enable Retries"
          labelWeight="medium"
          labelSize="h5"
          infoText="People are allowed to retake a completed Assessment."
          infoTextAlignment="left"
          checked={hasRetriesEnabled}
          onChange={() => setHasRetriesEnabled(!hasRetriesEnabled)}
          tourId={null}
        />
      </FormFieldGroup>
      {hasRetriesEnabled && (
        <FormFieldGroup>
          <Field component="input" type="hidden" name="max_attempts" />
          <TextInput
            type="number"
            label="Maximum retry attempts"
            value={maxRetries}
            inputProps={{
              min: 1, // Customers are not allowed to set unlimited retries for now
              'aria-label': 'Maximum retry attempts',
            }}
            onChange={(input) => setMaxRetries(toInteger(input.target.value))}
          />
        </FormFieldGroup>
      )}

      <Divider sx={{ marginY: 2 }} />

      <FormFieldGroup>
        <Field
          component={CheckboxField}
          name="passing_score_enabled"
          data-testid="enable_passing_score"
          label="Passing Grade"
          labelWeight="medium"
          labelSize="h5"
          infoText="Enable to set up a minimum grade (%) needed to pass."
          infoTextAlignment="left"
          tourId={null}
        />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ width: '180px' }}>
            <Field
              label="Passing Grade (%)"
              name="passing_score"
              component={TextField}
              min={0}
              max={100}
              type="number"
              disabled={!passingGradeEnabled}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Field
              name="passing_score"
              component={SliderField}
              min={0}
              max={100}
              step={1}
              sx={{ width: '100%' }}
              disabled={!passingGradeEnabled}
            />
          </Box>
        </Stack>
      </FormFieldGroup>
    </>
  );
};

export default AssessmentSettingsSection;
