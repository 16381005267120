import { isNil } from 'lodash';
import * as React from 'react';

import { useContentItemAssignmentActions } from '../hooks/useContentItemAssignmentActions';

type ContentItemAssignmentActionsCtx = ReturnType<typeof useContentItemAssignmentActions>;

export const AssignmentActionsCtx = React.createContext<ContentItemAssignmentActionsCtx | null>(
  null
);

export function useAssignmentActionsCtx() {
  const ctx = React.useContext(AssignmentActionsCtx);

  if (isNil(ctx)) {
    throw new Error('useAssignmentActionsCtx must be used within an AssignmentsActionsProvider');
  }

  return ctx;
}
