import { findIndex, get, isEmpty, size, isNil } from 'lodash';
import * as React from 'react';

import { LinkedContent } from 'app/linkedcontent/interfaces';
import { CONTENT_TYPES } from 'catalog/constants';
import ArticleDetailPage from 'features/articles/components/ArticleDetailPage';
import { MultipleChoiceQuestion, TextQuestion } from 'features/assessments/types';
import { useEnsureContentItemAssignmentQuery } from 'features/contentitems/hooks/useEnsureContentItemAssignmentQuery';
import { useComposableTrackCtx } from 'features/tracks/contexts/ComposableTrackCtx';
import CodelabDetailPage from 'scenes/CodelabDetail/CodelabDetailPage';
import CourseDetailPage from 'scenes/CourseDetail/CourseDetailPage';
import EventTypeDetailPage from 'scenes/EventTypeDetail/EventTypeDetailPage';
import LinkedContentDetailPage from 'scenes/LinkedContentDetail/LinkedContentDetailPage';
import { MultipleChoiceQuestionDetail, TextQuestionDetail } from 'scenes/QuestionDetail';
import { TaskDetailPage } from 'scenes/TaskPage/';
import TrackDetailPage from 'scenes/TrackPage/TrackDetailPage';
import TrackSectionDetailPage from 'scenes/TrackSectionPage/TrackSectionDetailPage';
import VideoDetailPage from 'scenes/VideoDetail/VideoDetailPage';
import Loading from 'shared/components/Loading';
import { useToggles } from 'shared/hooks';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { TrackItem } from '../types';
import { getSupportedContentItemType } from '../utils/getSupportedContentItemType';

import { TrackContentItemInfoAlert } from './TrackContentItemInfoAlert';

type AssessmentQuestionRendererProps = {
  trackItem: TrackItem;
  question: MultipleChoiceQuestion | TextQuestion;
  contentType: string;
};

function AssessmentQuestionRenderer(props: AssessmentQuestionRendererProps) {
  const { question, contentType } = props;

  return (
    <React.Fragment
      // Force react to remount
      key={question.user_assignment?.id}
    >
      {contentType === CONTENT_TYPES.multiple_choice_question && (
        <MultipleChoiceQuestionDetail question={question as MultipleChoiceQuestion} />
      )}
      {contentType === CONTENT_TYPES.text_question && (
        <TextQuestionDetail question={question as TextQuestion} />
      )}
    </React.Fragment>
  );
}

type TrackContentItemSwitcherProps = {
  trackItem: TrackItem;
};

function TrackContentItemSwitcher(props: TrackContentItemSwitcherProps) {
  const { trackItem } = props;

  const publicId = get(trackItem, 'content_item.public_id');
  const supportedContentType = getSupportedContentItemType(trackItem.content_item);

  const isQuestion =
    supportedContentType === CONTENT_TYPES.multiple_choice_question ||
    supportedContentType === CONTENT_TYPES.text_question;

  const isItemRequired = get(trackItem, 'is_required', true);

  const { toggle_composable_tracks: toggleComposableTracks } = useToggles();

  const { data: contentItem, status } = useEnsureContentItemAssignmentQuery(trackItem.content_item);

  if (status === 'loading') {
    return <Loading />;
  }

  // As we now have only one endpoint for contents, when the user is navigating,
  // there may be an intermediate state in that the fetchItem has not started yet
  // but the contentItem variable already has a value from a previous request.
  if (get(contentItem, 'public_id') !== publicId || status === 'error') {
    return null;
  }

  return (
    <>
      <TrackContentItemInfoAlert trackItem={trackItem} />

      {supportedContentType === CONTENT_TYPES.eventtype && (
        <EventTypeDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.article && (
        <ArticleDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.linkedcontent && (
        <LinkedContentDetailPage
          content={contentItem as LinkedContent}
          isRequired={isItemRequired}
        />
      )}
      {supportedContentType === CONTENT_TYPES.assessment && (
        <TrackDetailPage assessment={contentItem as any} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.video && (
        <VideoDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.codelab && (
        <CodelabDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.course && (
        <CourseDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {supportedContentType === CONTENT_TYPES.task && (
        <TaskDetailPage content={contentItem} isRequired={isItemRequired} />
      )}
      {toggleComposableTracks && supportedContentType === CONTENT_TYPES.track && (
        <TrackDetailPage track={contentItem} showBreadcrumbs={false} isRequired={isItemRequired} />
      )}

      {isQuestion && (
        <AssessmentQuestionRenderer
          contentType={supportedContentType}
          trackItem={trackItem}
          question={contentItem as any}
        />
      )}
    </>
  );
}

export function TrackContentRenderer() {
  const { nonEmptySectionsWithItems } = useComposableTrackCtx();
  const { currentTrackItem, currentSection } = useTrackContentConsumptionCtx();

  const isSection = currentSection != null;

  if (isSection) {
    const numberOfTrackSections = size(nonEmptySectionsWithItems);
    const currentSectionIndex = findIndex(nonEmptySectionsWithItems, ['id', currentSection?.id]);

    if (isEmpty(currentSection.items)) {
      return null;
    }

    return (
      <TrackSectionDetailPage
        section={currentSection}
        trackSectionsCount={numberOfTrackSections}
        currentTrackSectionIndex={currentSectionIndex + 1}
      />
    );
  }

  if (isNil(currentTrackItem)) {
    return null;
  }

  return <TrackContentItemSwitcher trackItem={currentTrackItem} />;
}
