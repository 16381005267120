import PropTypes from 'prop-types';
import React from 'react';

import Bubble from 'shared/components/Bubble';

import UserBubble from './UserBubble';

const UserPopover = ({
  badgeLeft,
  badgeSize,
  badgeTop,
  className,
  disableProfileLink,
  imgGrayscale,
  renderPopoverHoverable,
  sendEmailCTA,
  showBadge,
  showEmail,
  user,
}) => {
  return (
    <Bubble
      renderPopoverContent={
        <UserBubble
          badgeLeft={badgeLeft}
          badgeSize={badgeSize}
          badgeTop={badgeTop}
          className={className}
          disableProfileLink={disableProfileLink}
          imgGrayscale={imgGrayscale}
          sendEmailCTA={sendEmailCTA}
          showBadge={showBadge}
          showEmail={showEmail}
          user={user}
        />
      }
    >
      {renderPopoverHoverable}
    </Bubble>
  );
};

UserPopover.propTypes = {
  sendEmailCTA: PropTypes.bool,
  showEmail: PropTypes.bool,
  showBadge: PropTypes.bool,

  badgeLeft: PropTypes.number,
  badgeSize: PropTypes.string,
  badgeTop: PropTypes.number,
  className: PropTypes.string,
  disableProfileLink: PropTypes.bool,
  imgGrayscale: PropTypes.bool,
  renderPopoverHoverable: PropTypes.node,
  user: PropTypes.object,
};

export default UserPopover;
