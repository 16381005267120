import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LEARNING_TYPES, LEARNING_TYPE_ICONS_MAPPING } from 'catalog/constants';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import ButtonLink from 'shared/components/ButtonLink';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { UserPopover } from 'shared/components/UserAvatar';
import { useEllipsisCheck, useLabels, useTooltipUID } from 'shared/hooks';
import { isEmpty, map } from 'vendor/lodash';

const HostsContainer = styled.ul`
  display: flex;
  position: relative;
  padding: 0px;
  margin-bottom: 0px;
  list-style: none;
  overflow: hidden;
  border-right: solid 16px rgba(0, 0, 0, 0);

  > * + * {
    margin-left: 4px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 90%, white);
    pointer-events: none;
  }
`;

const TextContainer = styled.div`
  max-width: 95%;
`;

const OfficeHourHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OfficeHourTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4px;
  max-width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 12px;
  border: 1px solid ${colors.neutral200};
  border-radius: 8px;
`;

const UserPill = ({ user }) => {
  const route = user.id ? mapRoute('userProfile', { id: user.id }) : null;
  return (
    <Link to={route}>
      <Pill
        size="medium"
        label={user.display_name}
        labelMaxWidth={100}
        variant="lighterGrayWithDarkerTextColor"
        imageSrc={user.profile_image}
        arial-label={`Available Host - ${user.display_name}`}
        showLabelOnTooltip={false}
      />
    </Link>
  );
};

UserPill.propTypes = {
  user: PropTypes.object.isRequired,
};

const OfficeHourContainer = ({ officeHour }) => {
  const labelTooltip = useTooltipUID();
  const { label_office_hours_callout_blurb: labelOfficeHoursCalloutBlurb } = useLabels();
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const icon = LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.programs];
  const variant = `lightLearningType${LEARNING_TYPES.programs}`;

  return (
    <Container>
      <OfficeHourHeader>
        <OfficeHourTitle>
          <Pill icon={icon} size="medium" iconMarginRight="0px" variant={variant} round />
          <TextContainer>
            <ButtonLink
              route={mapRoute('programDetails', {
                public_id_and_slug: officeHour.public_id_and_slug,
              })}
              variant="neutral"
              aria-label="Resource Office Hour"
            >
              <Text {...labelTooltip.targetProps} ref={nodeRef} ellipsisOnOverflow>
                {officeHour.name}
              </Text>
              <Tooltip id={labelTooltip.uid} maxWidth={282} hide={!hasEllipsis}>
                {officeHour.name}
              </Tooltip>
            </ButtonLink>
          </TextContainer>
        </OfficeHourTitle>
      </OfficeHourHeader>
      <Text color={colors.neutral600}>{labelOfficeHoursCalloutBlurb}</Text>
      {!isEmpty(officeHour.available_hosts) && (
        <HostsContainer>
          {map(officeHour.available_hosts, (user) => (
            <UserPopover
              showPopover
              sendEmailCTA
              showEmail
              user={user}
              key={user.display_name}
              renderPopoverHoverable={<UserPill user={user} />}
            />
          ))}
        </HostsContainer>
      )}
    </Container>
  );
};

OfficeHourContainer.propTypes = {
  officeHour: PropTypes.object.isRequired,
};

export default OfficeHourContainer;
