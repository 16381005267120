import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SYSTEM_PAGE_NAMES } from 'catalog/constants';
import actions from 'entities/actions';
import { userSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SegmentedControlsInput from 'inputs/components/SegmentedControlsInput';
import SelectField from 'inputs/components/SelectField';
import FilterPageContentManager from 'navigation/components/FilterPageContentManager';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import {
  PeopleCardList,
  PeopleFilterPageContentManagerWrapper,
  PeopleHeader,
  PeopleHeaderActions,
  PeopleHeaderLeft,
  PeopleHeaderWrapper,
  PeopleLoadMoreButtonWrapper,
  PeopleNotFoundMessage,
  PeopleSectionHeader,
  PeoplePageBaseContainer,
} from 'shared-layouts/People';
import Button from 'shared/components/Button';
import { MenteeCard } from 'shared/components/Card';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import MediaPoint from 'shared/components/MediaPoint';
import PageTitle from 'shared/components/PageTitle';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_LOADING_MORE, STATUS_ERROR } from 'shared/constants';
import { useCurrentUser, useRouteFilters, useLabels, useStickyFilters } from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { buildFlexibleTagsFieldNameList } from 'topics/services';
import { includes, map, isEmpty, get, reduce, isEqual } from 'vendor/lodash';
import ReactMarkdown from 'vendor/react-markdown';

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  > ${Text} {
    margin-right: 12px;
  }

  ${MediaPoint.MobileOnly} {
    margin-bottom: 8px;
  }
`;

const SortSelectFieldWrapper = styled.div`
  width: 124px;
`;

const TextContainer = styled(Text)`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
`;

export const SELECT_ORDER_OPTIONS = [
  { label: 'Name (A-Z)', value: 'name' },
  { label: 'Name (Z-A)', value: '-name' },
  { label: 'Interest', value: 'has_mentee_profile,name' },
  { label: 'Title', value: 'title' },
  { label: 'Department', value: 'department' },
  { label: 'Location', value: 'location' },
];

const MENTEE_PAGE_SIZE = 20;

const MenteeFilter = () => {
  const { trackActivity } = useMetrics();

  const currentUser = useCurrentUser();
  const {
    permissions,
    labels: { label_cta_mentee_page_tip_filters: labelCtaMenteePageTipFilters },
  } = currentUser;

  const [currentFilters, updateFiltersOnQueryString] = useRouteFilters({
    notListAttrs: ['name', 'o', 'title', 'department'],
  });

  const [selectedFiltersOnLocalStorage, updateSelectedFiltersOnLocalStorage] =
    useStickyFilters('mentees_page');

  const [filterState, setFilterState] = useState('LOADING');

  const updateFilterQuerystringFromStickyFilters = (filtersObject) => {
    if (isEmpty(currentFilters)) {
      // Will fetch next time filters change
      setFilterState('READY');

      const stickyOrderingParam = get(filtersObject, 'o');

      if (!isEqual({ ...filtersObject, o: stickyOrderingParam }, selectedFiltersOnLocalStorage))
        updateSelectedFiltersOnLocalStorage({ ...filtersObject, o: stickyOrderingParam });
      updateFiltersOnQueryString({
        ...filtersObject,
        o: stickyOrderingParam || SELECT_ORDER_OPTIONS[0].value,
      });
    } else {
      // Need to fetch even without filters changing
      setFilterState('FETCH_NOW');
    }
  };

  const [
    fetchFilters,
    {
      data: { mentee_filters: menteeFilters },
    },
  ] = useEntities(
    actions.currentUser.filters,
    ({ status, data: { selected_mentee_filters: selectedFiltersOnDB } }) => {
      if (status === STATUS_DONE) updateFilterQuerystringFromStickyFilters(selectedFiltersOnDB);
    }
  );

  const [fetchMentees, { data: mentees, status, nextPage, count }, loadMoreMentees] = useEntities(
    actions.userData.retrieveList,
    ({ status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.MENTEES_VIEW, {
          filters: currentFilters,
        });
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  const handleFilterChange = (value) => {
    if (!isEqual(value, selectedFiltersOnLocalStorage))
      updateSelectedFiltersOnLocalStorage({ ...value });
    updateFiltersOnQueryString({ ...value });
  };

  const handleSortChange = (value) => {
    if (!isEqual({ ...currentFilters, o: value }, selectedFiltersOnLocalStorage))
      updateSelectedFiltersOnLocalStorage({ ...currentFilters, o: value });
    updateFiltersOnQueryString({ ...currentFilters, o: value });
  };

  useEffect(() => {
    if (!selectedFiltersOnLocalStorage) {
      fetchFilters({
        system_page: SYSTEM_PAGE_NAMES.menteeFilter,
      });
    } else {
      updateFilterQuerystringFromStickyFilters(selectedFiltersOnLocalStorage);
    }
  }, []);

  const fetchData = () => {
    fetchMentees({
      ...currentFilters,
      wants_to_be_mentored: true,
      page_size: MENTEE_PAGE_SIZE,
      view_mode: 'mentee',
      sticky: 'sticky_mentee',
    });
  };

  // Used for first load with filters in URL
  useEffect(() => {
    if (filterState === 'FETCH_NOW') {
      setFilterState('READY');
      fetchData();
    }
  }, [filterState]);

  // Used when filters change (UI or sticky filters)
  useEffect(() => {
    if (filterState === 'READY') {
      fetchData();
    }
  }, [currentFilters]);

  const {
    label_mentorship_sessions_creator_title_plural: mentorshipHostLabelPlural,
    label_mentorship_sessions_attendee_title_single: mentorshipAttendeeLabelSingular,
    label_mentorship_sessions_attendee_title_plural: mentorshipAttendeeLabelPlural,
    label_mentorship_tab: mentorshipTab,
  } = useLabels();

  const getSearch = () => {
    const searchValue = get(currentFilters, 'q');

    if (isEmpty(searchValue)) return [];

    return [
      {
        id: 'q',
        label: searchValue,
        icon: 'search',
        group: 'q',
      },
    ];
  };

  const [selectedLocations, setSelectedLocations] = useState([]);

  const getLocations = () => {
    return map(selectedLocations, (location) => ({
      id: location.value,
      label: location.name,
      icon: 'location',
      group: 'location',
    }));
  };

  const getName = () => {
    const nameValue = get(currentFilters, 'name');

    if (isEmpty(nameValue)) return [];
    return [
      {
        id: 'name',
        label: nameValue,
        icon: 'text',
        group: 'name',
      },
    ];
  };

  const getTitle = () => {
    const titleValue = get(currentFilters, 'title');

    if (isEmpty(titleValue)) return [];
    return [
      {
        id: 'title',
        label: titleValue,
        icon: 'suitcase',
        group: 'title',
      },
    ];
  };

  const getDepartment = () => {
    const departmentValue = get(currentFilters, 'department');

    if (isEmpty(departmentValue)) return [];
    return [
      {
        id: 'department',
        label: departmentValue,
        icon: 'room',
        group: 'department',
      },
    ];
  };

  const [selectedOrgLeads, setSelectedOrgLeads] = useState([]);

  const getOrgLeads = () => {
    return map(selectedOrgLeads, (orgLead) => ({
      id: orgLead.value,
      label: orgLead.name,
      icon: 'person',
      group: 'org_lead',
    }));
  };

  const [selectedManagers, setSelectedManagers] = useState([]);

  const getManagers = () => {
    return map(selectedManagers, (manager) => ({
      id: manager.value,
      label: manager.name,
      icon: 'person',
      group: 'manager',
    }));
  };

  const [selectedTeammates, setSelectedTeammates] = useState([]);

  const getTeammates = () => {
    return map(selectedTeammates, (teammate) => ({
      id: teammate.value,
      label: teammate.name,
      icon: 'person',
      group: 'teammate',
    }));
  };

  const [selectedGroups, setSelectedGroups] = useState([]);

  const getGroups = () => {
    return map(selectedGroups, (group) => ({
      id: group.value,
      label: group.name,
      icon: 'group',
      group: 'group',
    }));
  };

  const [selectedTopics, setSelectedTopics] = useState({});

  const getTopics = (topicType) => {
    if (!selectedTopics[topicType]) return [];

    return map(selectedTopics[topicType], (topic) => {
      return {
        id: topic.value,
        label: topic.name,
        icon: 'tag',
        group: topicType,
      };
    });
  };

  const filterOptions = menteeFilters ?? {};
  const isLoadingMentees = !includes([STATUS_DONE, STATUS_ERROR], status);

  return (
    <>
      <PageTitle title={`${mentorshipAttendeeLabelSingular} Search`} />
      <PeoplePageBaseContainer
        options={filterOptions}
        updateSelectedFilters={handleFilterChange}
        selectedFilters={currentFilters}
        viewMode="mentee"
        viewportWidthOptions={{
          tablet: '700px',
          desktopSm: '940px',
          desktopLg: '1180px',
        }}
        setSelectedLocations={setSelectedLocations}
        setSelectedOrgLeads={setSelectedOrgLeads}
        setSelectedManagers={setSelectedManagers}
        setSelectedTeammates={setSelectedTeammates}
        setSelectedGroups={setSelectedGroups}
        setSelectedTopics={setSelectedTopics}
      >
        <PeopleHeaderWrapper>
          <PeopleHeader>
            <PeopleHeaderLeft>
              <Text size="h1" color={colors.neutral900} medium>
                {mentorshipTab}
              </Text>
              <SegmentedControlsInput
                items={[
                  {
                    name: `${mentorshipHostLabelPlural}`,
                    value: 'mentorList',
                    route: mapRoute('mentorList'),
                  },
                  {
                    name: `${mentorshipAttendeeLabelPlural}`,
                    value: 'menteeList',
                    route: mapRoute('menteeList'),
                  },
                ]}
                value="menteeList"
              />
            </PeopleHeaderLeft>

            <PeopleHeaderActions>
              <SortWrapper>
                <SortSelectFieldWrapper>
                  <SelectField
                    label="Sort by"
                    data-testid="select-order-mentee"
                    options={SELECT_ORDER_OPTIONS}
                    input={{
                      value: currentFilters.o || '',
                      onChange: handleSortChange,
                      name: 'sort-mentee',
                      id: 'sort-mentee',
                    }}
                  />
                </SortSelectFieldWrapper>
              </SortWrapper>

              {includes(permissions, permissionConstants.CREATE_MENTORSHIP_SESSION_PERMISSION) && (
                <Button
                  startIcon={<Icon name="plus" />}
                  size="small"
                  route={mapRoute('sessionMentorshipCreate')}
                >
                  New Session
                </Button>
              )}
            </PeopleHeaderActions>
          </PeopleHeader>

          <PeopleFilterPageContentManagerWrapper>
            <FilterPageContentManager
              selecteds={currentFilters}
              onChange={handleFilterChange}
              pills={[
                ...getSearch(),
                ...getLocations(),
                ...getName(),
                ...getTitle(),
                ...getDepartment(),
                ...getOrgLeads(),
                ...getManagers(),
                ...getTeammates(),
                ...getGroups(),
                ...reduce(
                  buildFlexibleTagsFieldNameList(currentUser),
                  (temp, tagName) => [...temp, ...getTopics(tagName)],
                  []
                ),
              ]}
              initialFilters={{ o: ['name'] }}
              notListAttributes={['q', 'name', 'title', 'department']}
            />
          </PeopleFilterPageContentManagerWrapper>
        </PeopleHeaderWrapper>

        <PeopleSectionHeader>
          <TextContainer size="h4" color={colors.neutral600} maxWidth="80%">
            <ReactMarkdown linkTarget="_blank">{labelCtaMenteePageTipFilters}</ReactMarkdown>
          </TextContainer>
          <Text color={colors.neutral600}>
            {count} {count === 1 ? 'person' : 'people'}
          </Text>
        </PeopleSectionHeader>

        {isLoadingMentees && <Loading />}

        {!isLoadingMentees && mentees.length <= 0 && (
          <PeopleNotFoundMessage>No mentees found</PeopleNotFoundMessage>
        )}

        <PeopleCardList>
          {map(mentees, (mentee) => (
            <MenteeCard key={mentee.id} mentee={mentee} hasMargins cardProps={{ width: '220px' }} />
          ))}
        </PeopleCardList>
        <PeopleLoadMoreButtonWrapper>
          <LoadMoreButton
            isLoading={status === STATUS_LOADING_MORE}
            show={Boolean(nextPage)}
            onClick={loadMoreMentees}
            size="large"
          />
        </PeopleLoadMoreButtonWrapper>
      </PeoplePageBaseContainer>
    </>
  );
};

export default MenteeFilter;
