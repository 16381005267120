import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import { ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE } from 'event-shared/constants';
import BaseContainer from 'navigation/components/BaseContainer';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import PageHeader from 'shared/components/PageHeader';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { map, isEmpty } from 'vendor/lodash';

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EventItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid ${colors.neutral200};
  padding: 8px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const EventsMissingFeedback = ({
  fetchEventList,
  eventList,
  eventListStatus,
  eventListMoreLink,
  fetchNextPage,
  updateEnrollment,
}) => {
  const [dismissedFeedbackMap, setDismissedFeedbackMap] = useState({});

  const currentUser = useCurrentUser();

  useEffect(() => {
    fetchEventList(currentUser.id);
  }, []);

  const fetchEventsNextPage = () => {
    fetchNextPage(eventListMoreLink);
  };

  const dismissFeedback = (event) => {
    updateEnrollment(event.public_id, { dismiss_feedback: true });
    setDismissedFeedbackMap({ ...dismissedFeedbackMap, [event.id]: true });
  };

  const isLoading = eventListStatus === STATUS_LOADING;
  const isLoadingMore = eventListStatus === STATUS_LOADING_MORE;

  return (
    <BaseContainer>
      <PageHeader subText="You've attended the following events but haven't provided your feedback. How did it go?">
        Events Feedback
      </PageHeader>
      {isLoading && <Loading />}
      <EventsContainer>
        {map(eventList, (event) => (
          <EventItem key={event.id}>
            <Button
              noBorder
              noPadding
              size="small"
              route={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
            >
              {event.name}
            </Button>
            {dismissedFeedbackMap[event.id] ? (
              <Button noBorder size="small" disabled>
                Dismissed
              </Button>
            ) : (
              <Button size="small" onClick={() => dismissFeedback(event)}>
                Dismiss
              </Button>
            )}
          </EventItem>
        ))}
        <ButtonsWrapper>
          <LoadMoreButton
            isLoading={isLoadingMore}
            show={Boolean(eventListMoreLink)}
            onClick={fetchEventsNextPage}
            size="large"
          />
        </ButtonsWrapper>
      </EventsContainer>

      {eventListStatus === STATUS_DONE && isEmpty(eventList) ? (
        <Text size="h2" align="center" block>
          You&#39;ve provided feedback on all the events you&#39;ve taken.
        </Text>
      ) : (
        ''
      )}
    </BaseContainer>
  );
};

EventsMissingFeedback.propTypes = {
  eventList: PropTypes.array,
  eventListStatus: PropTypes.string,
  eventListMoreLink: PropTypes.string,
  fetchNextPage: PropTypes.func,
  fetchEventList: PropTypes.func,
  updateEnrollment: PropTypes.func,
};

const mapStateToProps = (state) => {
  const requestState = getDataFromState('eventsMissingFeedback', state, [eventSchema]);
  return {
    eventList: requestState.data,
    eventListStatus: requestState.status,
    eventListMoreLink: requestState.nextPage,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchEventList: (userId) =>
    dispatch(
      actions.event.retrieveList('eventsMissingFeedback', {
        period: 'previous',
        engaged_users: userId,
        engagement_type: [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE],
        is_awaiting_feedback: true,
        page_size: 10,
      })
    ),
  fetchNextPage: (url) =>
    dispatch(actions.event.retrieveListLoadMore('eventsMissingFeedback', url)),
  updateEnrollment: (publicId, body) =>
    dispatch(
      actions.eventEnrollment.updateSubmit(`enrollmentStatusUpdate${publicId}`, publicId, body)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsMissingFeedback);
