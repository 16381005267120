import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import CheckboxInput from 'inputs/components/SquareCheckboxInput';
import surveyProptypes from 'surveys/types';
import { map, includes, without } from 'vendor/lodash';

const MultipleSelectionContainer = styled.div`
  margin-top: 16px;

  > * + * {
    margin-top: 20px;
  }
`;

const SurveyQuestionMultipleSelection = ({ input, question }) => {
  const { options } = question;

  const isChecked = (option) => includes(input.value, option);

  const handleInputChange = (option) => {
    if (isChecked(option)) {
      input.onChange(without(input.value, option));
    } else {
      input.onChange([...input.value, option]);
    }
  };

  return (
    <MultipleSelectionContainer>
      {map(options, (option) => (
        <CheckboxInput
          key={option}
          id={`multiple-${input.name}-${option}`}
          label={option}
          checked={isChecked(option)}
          onChange={() => handleInputChange(option)}
          onBlur={() => input.onBlur()}
        />
      ))}
    </MultipleSelectionContainer>
  );
};

SurveyQuestionMultipleSelection.propTypes = {
  input: PropTypes.object,
  question: surveyProptypes.surveyQuestion,
};

export default SurveyQuestionMultipleSelection;
