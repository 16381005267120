import PropTypes from 'prop-types';
import React from 'react';

import SettingShortTextField from './SettingShortTextField';

const SettingPasswordField = ({ input, disabled, ariaLabel }) => {
  return (
    <SettingShortTextField
      input={input}
      type="password"
      disabled={disabled}
      ariaLabel={ariaLabel}
    />
  );
};

SettingPasswordField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default SettingPasswordField;
