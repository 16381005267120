import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const LockIcon = ({ width, height, fill }) => {
  const d =
    'M3 8.113v7.736c0 .633.43 1.183 1.045 1.334 1.32.325 3.304.817 5.691.817 2.408 0 4.536-.47 5.931-.776a1.375 1.375 0 0 0 1.081-1.342v-7.77c0-.594-.38-1.1-.91-1.29H14.38V5.02C14.38 2.489 12.203 1 9.874 1c-2.33 0-4.506 1.489-4.506 4.02v1.802H3.909c-.53.19-.909.695-.909 1.29zm7.716 5.775c.06.551-.318 1-.842 1-.523 0-.9-.449-.842-1l.19-1.773c.023-.163-.067-.367-.177-.485-.24-.254-.509-.604-.509-.99 0-.777.6-1.408 1.338-1.408.739 0 1.338.63 1.338 1.408 0 .386-.27.736-.509.99-.11.118-.2.322-.178.485l.191 1.773zM7.031 4.67c0-1.493 1.693-2.112 2.843-2.112s2.843.62 2.843 2.112v2.15H7.03v-2.15z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

LockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default LockIcon;
