import { get } from 'lodash';
import { useEffect, useState } from 'react';

import { redirectToGoogleCalendarLogin } from 'services/integrations';
import { useCurrentUser } from 'shared/hooks';

export const useCalendarResourcesForSessionsEffect = () => {
  const currentUser = useCurrentUser();

  const shouldRequestCalendarAuthorizationForSessions = get(
    currentUser,
    'should_request_authorization_for_sessions'
  );

  const useCalendarResourcesForSessions = get(currentUser, 'use_calendar_resources_for_sessions');

  useEffect(() => {
    if (useCalendarResourcesForSessions && shouldRequestCalendarAuthorizationForSessions) {
      redirectToGoogleCalendarLogin();
    }
  }, [useCalendarResourcesForSessions, shouldRequestCalendarAuthorizationForSessions]);

  return {
    shouldRequestCalendarAuthorizationForSessions,
    useCalendarResourcesForSessions,
  };
};

export const useCalendarResourcesForEventsEffect = () => {
  const currentUser = useCurrentUser();

  const shouldRequestCalendarAuthorizationForEvents = get(
    currentUser,
    'should_request_authorization_for_events'
  );
  const useCalendarResourcesForEvents = get(currentUser, 'use_calendar_resources_for_events');

  useEffect(() => {
    if (useCalendarResourcesForEvents && shouldRequestCalendarAuthorizationForEvents) {
      redirectToGoogleCalendarLogin();
    }
  }, [useCalendarResourcesForEvents, shouldRequestCalendarAuthorizationForEvents]);
};

export const useRenewCalendarAuthorizationModal = () => {
  const currentUser = useCurrentUser();

  const shouldRenewCalendarAuthorization = get(currentUser, 'should_renew_google_calendar');
  const useCalendarResourcesForEvents = get(currentUser, 'use_calendar_resources_for_events');
  const useCalendarResourcesForSessions = get(currentUser, 'use_calendar_resources_for_sessions');

  const [calendarModalDismissed, setCalendarModalDismissed] = useState(false);

  const dismissRenewCalendarAuthorizationModal = () =>
    setCalendarModalDismissed((current) => !current);

  const shouldShowRenewCalendarAuthorizationModal =
    (useCalendarResourcesForSessions || useCalendarResourcesForEvents) &&
    shouldRenewCalendarAuthorization &&
    !calendarModalDismissed;

  return {
    dismissRenewCalendarAuthorizationModal,
    shouldShowRenewCalendarAuthorizationModal,
  };
};
