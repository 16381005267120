import { useEffect, useState } from 'react';
import { Chart, ChartWrapperOptions } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import actions from 'app/entities/actions';
import UserContentBlock from 'app/users/components/UserContentBlock';
import ChartPagination from 'charts/components/ChartPagination';
import { IdParam } from 'common/types';
import { userProfileSchema } from 'entities/schema';
import { getDataFromState, useEntities } from 'entities/utils';
import { DenormalizedUserStatsByQuarterEntry } from 'scenes/UserProfile/interfaces';
import { formatAndFillDataListForGoogleChart } from 'scenes/UserProfile/services';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { map, size, slice } from 'vendor/lodash';
import { amber, Box, deepPurple, lightBlue, pink, teal, Typography } from 'vendor/mui';

const GRAPH_PAGE_MAX_SIZE = 4;

const chartColumns = [
  'Quarter',
  'Mentorships',
  'Office Hours',
  'Events',
  'Content Items',
  'Unique Impacts',
];

export const options: ChartWrapperOptions['options'] = {
  seriesType: 'bars',
  isStacked: true,
  legend: { position: 'bottom' },
  bar: { groupWidth: '30%' },
  series: { 4: { type: 'line' } },
  colors: [pink[500], lightBlue[500], teal[500], deepPurple[500], amber[500]],
  animation: {
    duration: 500,
    easing: 'out',
  },
};

export const OverallImpactWidget = () => {
  const { id: userId } = useParams<IdParam>();

  const [chartData, setChartData] = useState<(string | number)[][]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const { status: userStatus, data: user } = useSelector((state) =>
    getDataFromState(`userProfile${userId}`, state, userProfileSchema)
  );

  const [fetchOverallImpact, { status }] = useEntities(
    actions.userData.retrieveOverallImpactList,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setChartData(
          !size(data)
            ? []
            : formatAndFillDataListForGoogleChart(
                map(data, ({ year, quarter, ...fields }) => ({
                  year,
                  quarter,
                  data: [
                    fields.impacted_users_by_mentorships_count,
                    fields.impacted_users_by_office_hours_count,
                    fields.impacted_users_by_events_count,
                    fields.impacted_users_by_content_items_count,
                    fields.impacted_users_count,
                  ],
                }))
              )
        );
      }
    }
  ) as [
    (id: string) => void,
    {
      data: DenormalizedUserStatsByQuarterEntry[];
      status: string;
    }
  ];

  useEffect(() => {
    if (userStatus === STATUS_DONE) {
      fetchOverallImpact(user.id);
    }
  }, [userStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mostRecentPageIndex =
      chartData.length && chartData.length > GRAPH_PAGE_MAX_SIZE
        ? chartData.length - GRAPH_PAGE_MAX_SIZE
        : 0;
    setCurrentPageIndex(mostRecentPageIndex);
  }, [chartData]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentChartPageData: (string | number)[][] = [
    chartColumns,
    ...slice(chartData, currentPageIndex, currentPageIndex + GRAPH_PAGE_MAX_SIZE),
  ];

  return (
    <UserContentBlock>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Impact over time
        </Typography>
        {status === STATUS_LOADING && <Loading />}
        {status === STATUS_DONE && !size(chartData) && (
          <Typography>
            No impacted people. Help {user.name} impact people by pointing them to office hour and
            mentoring sessions, events, or other content.
          </Typography>
        )}
        {status === STATUS_DONE && !!size(chartData) && (
          <>
            <Chart
              chartType="ComboChart"
              width="100%"
              height="400px"
              data={currentChartPageData}
              options={options}
            />
            <ChartPagination
              direction="row"
              pageOffset={currentPageIndex}
              intervalData={chartData}
              lastPageItemIndex={
                currentPageIndex +
                (chartData.length > GRAPH_PAGE_MAX_SIZE ? GRAPH_PAGE_MAX_SIZE : chartData.length)
              }
              handleIncreasePageOffset={() => setCurrentPageIndex(currentPageIndex + 1)}
              handleDecreasePageOffset={() => setCurrentPageIndex(currentPageIndex - 1)}
            />
          </>
        )}
      </Box>
    </UserContentBlock>
  );
};
