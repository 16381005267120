import styled from 'styled-components';

import MediaPoint from 'shared/components/MediaPoint';

const ContentItemBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${MediaPoint.DesktopSm} {
    justify-content: center;
  }
`;

export default ContentItemBody;
