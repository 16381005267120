import styled from 'styled-components';

const ContentItemAdditional = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 24px;
`;

export default ContentItemAdditional;
