import AnonymousIcon from './AnonymousIcon';
import ArrowIcon from './ArrowIcon';
import BellIcon from './BellIcon';
import CalendarIcon from './CalendarIcon';
import CloseIcon from './CloseIcon';
import DeleteIcon from './DeleteIcon';
import DownloadIcon from './DownloadIcon';
import EditIcon from './EditIcon';
import ExclamationIcon from './ExclamationIcon';
import FatArrowIcon from './FatArrowIcon';
import GearIcon from './GearIcon';
import GroupIcon from './GroupIcon';
import HashtagIcon from './HashtagIcon';
import InfoIcon from './InfoIcon';
import LocationIcon from './LocationIcon';
import LockIcon from './LockIcon';
import MegaphoneIcon from './MegaphoneIcon';
import PlusIcon from './PlusIcon';
import RemoteIcon from './RemoteIcon';
import OldSvgIcon from './SvgIcon';
import TagIcon from './TagIcon';
import TeachIcon from './TeachIcon';
import WorldIcon from './WorldIcon';

OldSvgIcon.Gear = GearIcon;
OldSvgIcon.Remote = RemoteIcon;
OldSvgIcon.Location = LocationIcon;
OldSvgIcon.Calendar = CalendarIcon;
OldSvgIcon.Edit = EditIcon;
OldSvgIcon.Delete = DeleteIcon;
OldSvgIcon.Arrow = ArrowIcon;
OldSvgIcon.Plus = PlusIcon;
OldSvgIcon.Download = DownloadIcon;
OldSvgIcon.FatArrow = FatArrowIcon;
OldSvgIcon.World = WorldIcon;
OldSvgIcon.Lock = LockIcon;
OldSvgIcon.Anonymous = AnonymousIcon;
OldSvgIcon.Close = CloseIcon;
OldSvgIcon.Exclamation = ExclamationIcon;
OldSvgIcon.Bell = BellIcon;
OldSvgIcon.Megaphone = MegaphoneIcon;
OldSvgIcon.Info = InfoIcon;
OldSvgIcon.Hashtag = HashtagIcon;
OldSvgIcon.Tag = TagIcon;
OldSvgIcon.Teach = TeachIcon;
OldSvgIcon.Group = GroupIcon;

export default OldSvgIcon;
