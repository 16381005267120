import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import actions from 'entities/actions';
import { groupSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { useEntityMetrics } from 'services/metrics';
import AccessRestricted from 'shared/components/AccessRestricted';
import Loading from 'shared/components/Loading';
import MediaPoint from 'shared/components/MediaPoint';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { useCurrentUser, useOriginRoute } from 'shared/hooks';
import { get } from 'vendor/lodash';

import GroupEditForm from './GroupEditForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 30px;
  ${MediaPoint.DesktopSm} {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  align-self: center;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
  }
`;

const HeaderTitle = styled.h2`
  color: ${colors.neutral400};
  justify-self: flex-start;
`;

const GroupEdit = () => {
  const { id } = useParams();
  const { trackEntityActivity } = useEntityMetrics();

  const [initialValues, setInitialValues] = useState({});
  const currentUser = useCurrentUser();
  const { home_page_url_path: homePath } = currentUser;
  const redirectionRoute = useOriginRoute(homePath);
  const history = useHistory();
  const location = useLocation();

  const [fetchGroup, { data: group, status: groupRequestStatus }] = useEntities(
    actions.groups.retrieveDetails,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setInitialValues(data);
      }
    },
    {
      schema: groupSchema,
    }
  );

  useEffect(() => {
    fetchGroup(id, { view_mode: 'detail' });
  }, []);

  const onSubmitSuccessHandler = (result) => {
    trackEntityActivity({
      id: result.id,
      isEdit: true,
      entityType: 'group',
    });

    toast.success(
      'Group updated successfully',
      `You updated group ${result.name}, a ${
        result.is_private ? 'private' : 'public'
      } group successfully.`
    );

    const args = queryString.parse(location.search);

    if (!get(args, 'origin')) {
      history.push(homePath);
      return;
    }
    history.push(args.origin);
  };

  if (groupRequestStatus === STATUS_LOADING) return <Loading />;
  if (groupRequestStatus === STATUS_ERROR) {
    return (
      <Container>
        <Header>
          <AccessRestricted />
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderTitle>Edit {group.name}</HeaderTitle>
        <GroupEditForm
          initialValues={initialValues}
          groupRequestStatus={groupRequestStatus}
          redirectionRoute={redirectionRoute}
          currentUser={currentUser}
          onSubmitSuccessHandler={onSubmitSuccessHandler}
        />
      </Header>
    </Container>
  );
};

export default GroupEdit;
