import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AttendanceActionManager from 'event-shared/components/AttendanceActionManager';
import CheckinUnenrollButtons from 'event-shared/components/AttendanceActionManager/CheckinUnenrollButtons';
import RatingArea from 'event-shared/components/AttendanceActionManager/RatingArea';
import RestrictedEnrollmentInfoBox from 'event-shared/components/RestrictedEnrollmentInfoBox';
import { allowCancellation, getUserEnrollmentStatus } from 'event-shared/services';
import Pill from 'shared/components/Pill';

import {
  CardAttendanceRenderEnrolled,
  CardAttendanceRenderFullCapacity,
  CardAttendanceRenderProspect,
  CardAttendanceRenderUncancellableEnroll,
  CardAttendanceRenderWaitlist,
} from './CardAttendanceActionComponents';

const PillWrapper = styled.div`
  margin-bottom: 10px;
`;

const CardAttendanceActionElement = ({
  event,
  enrollmentStatus,
  onRateClick,
  shouldRefreshEvent,
}) => {
  const { userIsEnrolled } = getUserEnrollmentStatus(enrollmentStatus);
  const userCanCancel = allowCancellation(event) && userIsEnrolled;

  return (
    <AttendanceActionManager
      event={event}
      shouldRefreshEvent={shouldRefreshEvent}
      renderProspect={(actions, status, spotStatus) => (
        <CardAttendanceRenderProspect actions={actions} status={status} spotStatus={spotStatus} />
      )}
      renderEnrolled={(actions, status, spotStatus) => (
        <CardAttendanceRenderEnrolled actions={actions} status={status} spotStatus={spotStatus} />
      )}
      renderWaitlist={(actions, status, spotStatus) => (
        <CardAttendanceRenderWaitlist actions={actions} status={status} spotStatus={spotStatus} />
      )}
      renderUncancellableEnroll={() => <CardAttendanceRenderUncancellableEnroll />}
      renderFullCapacity={() => <CardAttendanceRenderFullCapacity event={event} />}
      renderRating={() => <RatingArea event={event} onRateClick={onRateClick} viewMode="cozy" />}
      renderDisabledStatusChange={() => <RestrictedEnrollmentInfoBox event={event} />}
      renderCheckin={({ onCheckinClick, onUnenrollClick }) => (
        <CheckinUnenrollButtons
          enrollmentStatus={enrollmentStatus}
          allowCancellation={userCanCancel}
          onCheckinButtonClick={onCheckinClick}
          onUnenrollButtonClick={onUnenrollClick}
        />
      )}
      renderEnrollmentClosed={() => (
        <PillWrapper>
          <Pill variant="lighterGrayWithDarkerTextColor" label="Enrollment closed" icon="cancel" />
        </PillWrapper>
      )}
      renderEventCancelled={() => (
        <PillWrapper>
          <Pill
            variant="lighterGrayWithDarkerTextColor"
            label="Enrollments cancelled"
            icon="cancel"
          />
        </PillWrapper>
      )}
    />
  );
};

CardAttendanceActionElement.propTypes = {
  event: PropTypes.object,
  enrollmentStatus: PropTypes.string,
  onRateClick: PropTypes.func,
  shouldRefreshEvent: PropTypes.bool,
};

export default CardAttendanceActionElement;
