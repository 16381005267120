import colors from 'services/colors';
import { FullTrackSection } from 'tracks/interfaces';
import { getTrackSectionProgress } from 'tracks/services';
import { isNil } from 'vendor/lodash';
import { CircularProgress } from 'vendor/mui';
import { CheckCircleIcon } from 'vendor/mui-icons';

interface TrackSectionCircularProgressProps {
  section: FullTrackSection;
  userIsAssignedToTrack: boolean;
}

export const TrackSectionCircularProgress = ({
  section,
  userIsAssignedToTrack,
}: TrackSectionCircularProgressProps) => {
  const sectionProgress = getTrackSectionProgress(section);

  if (!userIsAssignedToTrack || isNil(sectionProgress)) {
    return null;
  }

  if (sectionProgress === 100) {
    return <CheckCircleIcon sx={{ color: colors.success600 }} />;
  }

  if (sectionProgress > 0) {
    return <CircularProgress variant="determinate" size={18} value={sectionProgress} />;
  }

  return null;
};
