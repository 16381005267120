import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import { STATUS_DONE } from 'shared/constants';

import RequestEventModal from './RequestEventModal';

const RequestEventModalWrapper = (props) => {
  const { eventType, renderButton, fullWidth, size, primaryButton, toggleAskForTeamSize } = props;

  const [showRequestEventModal, setShowRequestEventModal] = useState(false);

  const history = useHistory();
  const { trackActivity } = useMetrics();

  const [requestEvent, { status: requestEventStatus }] = useEntities(
    actions.eventType.requestEvent,
    ({ data: { message, preferred_attendance_method: attendanceMethod }, status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.EVENT_TYPE_REQUEST, {
          eventTypeId: eventType.id,
          messageLength: message ? message.length : 0,
          attendanceMethod,
        });
      }
    }
  );

  const handleEventRequest = (id, message, quantity, preferredAttendanceMethod) =>
    requestEvent({
      event_type_id: id,
      message,
      additional_people_count: quantity,
      preferred_attendance_method: preferredAttendanceMethod,
    });

  const handleSubmitSuccess = () => {
    toast.success(
      'Event requested successfully',
      'An email was sent to the responsible people, they should get back to you soon.'
    );
    setTimeout(() => {
      history.go(0);
    }, 2000);
  };

  const handleModalOpen = () => setShowRequestEventModal(true);

  const handleModalClose = () => setShowRequestEventModal(false);

  return (
    <React.Fragment>
      {renderButton ? (
        renderButton({ handleModalOpen })
      ) : primaryButton ? (
        <Button onClick={handleModalOpen} fullWidth={fullWidth} size={size}>
          Request an event
        </Button>
      ) : (
        <ButtonLink onClick={handleModalOpen} fullWidth={fullWidth} size={size}>
          Request an event
        </ButtonLink>
      )}
      {showRequestEventModal && (
        <RequestEventModal
          eventType={eventType}
          handleEventRequest={handleEventRequest}
          requestEventStatus={requestEventStatus}
          toggleAskAboutTeamSize={toggleAskForTeamSize}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  );
};

RequestEventModalWrapper.propTypes = {
  eventType: PropTypes.object,
  toggleAskForTeamSize: PropTypes.bool,

  renderButton: PropTypes.func,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  primaryButton: PropTypes.bool,
};

RequestEventModalWrapper.defaultProps = {
  primaryButton: false,
};

export default RequestEventModalWrapper;
