import { RQLComp, RQLList } from 'shared/components/types';
import { get } from 'vendor/lodash';
import { FormControlLabel, Checkbox, grey, Typography } from 'vendor/mui';

interface RQLCheckboxProps {
  value: RQLComp | null;
  label?: string;
  onChange?: (newValue: RQLComp | RQLList | null) => void;
  maxWidth?: string | number;
}

const RQLCheckbox = ({ value, label, onChange, maxWidth }: RQLCheckboxProps) => {
  const checked = get(value, '$eq') === 'true';
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange?.(event.target.checked ? { $eq: 'true' } : null)
          }
          sx={{
            color: grey['600'],
            ':hover': {
              ...(checked ? {} : { color: grey['900'] }),
            },
            paddingTop: 0,
            paddingBottom: 0,
          }}
        />
      }
      label={
        <Typography
          variant="body1"
          sx={{
            color: grey['600'],
            ':hover': {
              color: grey['900'],
            },
          }}
        >
          {label}
        </Typography>
      }
      sx={{ margin: 0, maxWidth }}
    />
  );
};

export default RQLCheckbox;
