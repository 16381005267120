import CryptoJS from 'crypto-js';

import { isEmpty } from 'vendor/lodash';

export function encryptObject(object, key) {
  if (isEmpty(key)) {
    return '';
  }
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(object), key);
  return encrypted.toString();
}

export function decryptObject(encryptedObject, key) {
  const decryptedObjectStr = CryptoJS.AES.decrypt(encryptedObject, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedObjectStr);
}
