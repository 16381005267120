import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonLink from 'app/shared/components/ButtonLink';
import UserContentBlock from 'app/users/components/UserContentBlock';
import { Link, Box, Typography } from 'vendor/mui';

import { UniquePeopleImpacted, Reviews } from '.';

const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const BaseImpactSection = ({
  title,
  impactedUsers,
  impactedUserCount,
  feedbackTitle,
  feedbackList,
  feedbackRatings,
  impactUrl,
}) => {
  return (
    <UserContentBlock>
      <Box p={2.5}>
        <TitleBox>
          <Typography variant="h6" gutterBottom>
            {title} Impact
          </Typography>
          <ButtonLink
            size="medium"
            url="https://help.plusplus.app/en/articles/6953462-how-to-manage-your-plusplus-profile#h_bf6ec48c39"
            target="_blank"
            icon="info"
          >
            Understand these scores
          </ButtonLink>
        </TitleBox>
        <UniquePeopleImpacted users={impactedUsers} totalCount={impactedUserCount} />
        <Reviews
          title={feedbackTitle}
          feedbackRatings={feedbackRatings}
          feedbackList={feedbackList}
        />
        {impactUrl && (
          <Box sx={{ textAlign: 'right' }}>
            <Link href={impactUrl}>View {title}</Link>
          </Box>
        )}
      </Box>
    </UserContentBlock>
  );
};

BaseImpactSection.propTypes = {
  title: PropTypes.string.isRequired,
  impactedUsers: PropTypes.array.isRequired,
  impactedUserCount: PropTypes.number.isRequired,
  feedbackTitle: PropTypes.string,
  feedbackRatings: PropTypes.object,
  feedbackList: PropTypes.array,
  impactUrl: PropTypes.string,
};
