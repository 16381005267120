import { Assignment, ContentItem } from 'shared-content-item/interfaces';

export interface Course extends ContentItem {
  scorm_cloud_id?: string;
}

export enum COURSE_REGISTRATION_RESULTS {
  unknown = 'unknown',
  failed = 'failed',
  passed = 'passed',
}
export interface CourseRegistration extends Assignment {
  scorm_attempts?: number | null;
  scorm_total_time?: number;
  scorm_result?: COURSE_REGISTRATION_RESULTS | null;
}
