import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FilterPageContentManager from 'app/navigation/components/FilterPageContentManager';
import { SYSTEM_PAGE_NAMES } from 'catalog/constants';
import actions from 'entities/actions';
import { userSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SegmentedControlsInput from 'inputs/components/SegmentedControlsInput';
import SelectField from 'inputs/components/SelectField';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import {
  PeopleCardList,
  PeopleFilterPageContentManagerWrapper,
  PeopleHeader,
  PeopleHeaderActions,
  PeopleHeaderLeft,
  PeopleHeaderWrapper,
  PeopleLoadMoreButtonWrapper,
  PeopleNotFoundMessage,
  PeopleSectionHeader,
  PeoplePageBaseContainer,
} from 'shared-layouts/People';
import Button from 'shared/components/Button';
import { MentorCard } from 'shared/components/Card';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import MediaPoint from 'shared/components/MediaPoint';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_LOADING_MORE, STATUS_ERROR } from 'shared/constants';
import {
  useRouteFilters,
  useCurrentUser,
  useToggles,
  useLabels,
  useStickyFilters,
} from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { buildFlexibleTagsFieldNameList } from 'topics/services';
import { find, get, includes, isEmpty, isEqual, map, reduce } from 'vendor/lodash';
import ReactMarkdown from 'vendor/react-markdown';

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  > ${Text} {
    margin-right: 12px;
  }

  ${MediaPoint.MobileOnly} {
    margin-bottom: 8px;
  }
`;

const SortSelectFieldWrapper = styled.div`
  width: 124px;
`;

const TextContainer = styled(Text)`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
`;

const MENTORSHIP_PAGE_SIZE = 20;

const AVAILABILITY_FILTER_VALUES = {
  IS_LOCAL: 'available_local_sessions',
  IS_ONLINE: 'available_online_sessions',
  IS_FRIENDLY_TO_MY_TIME: 'available_friendly_to_my_time_sessions',
};

const AVAILABILITY_ATTRIBUTES = {
  NEXT_SESSION_DATE: 'next_session_date',
  NEXT_LOCAL_SESSION_DATE: 'next_local_session_date',
  NEXT_ONLINE_SESSION_DATE: 'next_online_session_date',
  NEXT_ONLINE_FRIENDLY_TO_MY_TIME_SESSION_DATE: 'next_online_friendly_to_my_time_session_date',
  FREE_HOSTING_SESSIONS_COUNT: 'free_hosting_sessions_count',
  FREE_LOCAL_HOSTING_SESSIONS_COUNT: 'free_local_hosting_sessions_count',
  FREE_ONLINE_HOSTING_SESSIONS_COUNT: 'free_online_hosting_sessions_count',
  FREE_ONLINE_FRIENDLY_TO_MY_TIME_HOSTING_SESSIONS_COUNT:
    'free_online_friendly_to_my_time_hosting_sessions_count',
};

const MENTOR_AVAILABILITY_OPTIONS = [
  {
    name: 'In Person',
    value: AVAILABILITY_FILTER_VALUES.IS_LOCAL,
  },
  {
    name: 'Online',
    value: AVAILABILITY_FILTER_VALUES.IS_ONLINE,
  },
  {
    name: 'Friendly to My Time',
    value: AVAILABILITY_FILTER_VALUES.IS_FRIENDLY_TO_MY_TIME,
  },
];

const getOrderingValueAccordingToAvaliabilityFilter = (filterValues) => {
  const { availability_as_mentor: availabilityAsMentor, o } = filterValues;

  const isLocal = includes(availabilityAsMentor, AVAILABILITY_FILTER_VALUES.IS_LOCAL);
  const isOnline = includes(availabilityAsMentor, AVAILABILITY_FILTER_VALUES.IS_ONLINE);
  const isFriendlyToMyTime = includes(
    availabilityAsMentor,
    AVAILABILITY_FILTER_VALUES.IS_FRIENDLY_TO_MY_TIME
  );
  const isOrderingByUpcoming = includes(
    [
      AVAILABILITY_ATTRIBUTES.NEXT_LOCAL_SESSION_DATE,
      AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_SESSION_DATE,
      AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_FRIENDLY_TO_MY_TIME_SESSION_DATE,
      AVAILABILITY_ATTRIBUTES.NEXT_SESSION_DATE,
    ],
    o
  );

  if (isOrderingByUpcoming) {
    if (isLocal && !isOnline) {
      return AVAILABILITY_ATTRIBUTES.NEXT_LOCAL_SESSION_DATE;
    }

    if (!isLocal && isOnline && isFriendlyToMyTime) {
      return AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_FRIENDLY_TO_MY_TIME_SESSION_DATE;
    }

    if (!isLocal && isOnline) {
      return AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_SESSION_DATE;
    }

    return AVAILABILITY_ATTRIBUTES.NEXT_SESSION_DATE;
  }

  return o;
};

const getMentorCardAttributesAccordingToAvailabilityFilters = (availabilityFiltersIds) => {
  const isLocal = includes(availabilityFiltersIds, AVAILABILITY_FILTER_VALUES.IS_LOCAL);
  const isOnline = includes(availabilityFiltersIds, AVAILABILITY_FILTER_VALUES.IS_ONLINE);
  const isFriendlyToMyTime = includes(
    availabilityFiltersIds,
    AVAILABILITY_FILTER_VALUES.IS_FRIENDLY_TO_MY_TIME
  );

  if (isLocal && !isOnline) {
    return {
      count: AVAILABILITY_ATTRIBUTES.FREE_LOCAL_HOSTING_SESSIONS_COUNT,
      nextDate: AVAILABILITY_ATTRIBUTES.NEXT_LOCAL_SESSION_DATE,
    };
  }

  if (!isLocal && isOnline && isFriendlyToMyTime) {
    return {
      count: AVAILABILITY_ATTRIBUTES.FREE_ONLINE_FRIENDLY_TO_MY_TIME_HOSTING_SESSIONS_COUNT,
      nextDate: AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_FRIENDLY_TO_MY_TIME_SESSION_DATE,
    };
  }

  if (!isLocal && isOnline) {
    return {
      count: AVAILABILITY_ATTRIBUTES.FREE_ONLINE_HOSTING_SESSIONS_COUNT,
      nextDate: AVAILABILITY_ATTRIBUTES.NEXT_ONLINE_SESSION_DATE,
    };
  }

  return {
    count: AVAILABILITY_ATTRIBUTES.FREE_HOSTING_SESSIONS_COUNT,
    nextDate: AVAILABILITY_ATTRIBUTES.NEXT_SESSION_DATE,
  };
};

const MentorFilter = () => {
  const currentUser = useCurrentUser();

  const { trackActivity } = useMetrics();

  const {
    permissions,
    labels: { label_cta_mentor_page_tip_filters: labelCtaMentorPageTipFilters },
  } = currentUser;
  const { toggle_mentee_preferences: toggleMenteePreferences } = useToggles();
  const [currentFilters, updateFiltersOnQueryString] = useRouteFilters({
    notListAttrs: ['name', 'o', 'title', 'department'],
  });
  const [filterState, setFilterState] = useState('LOADING');

  const [selectedFiltersOnLocalStorage, updateSelectedFiltersOnLocalStorage] =
    useStickyFilters('mentors_page');

  const updateFilterQuerystringFromStickyFilters = (filtersObject) => {
    if (isEmpty(currentFilters)) {
      setFilterState('READY');
      const stickyOrderingParam = get(filtersObject, 'o');
      const o = getOrderingValueAccordingToAvaliabilityFilter({
        ...filtersObject,
        o: stickyOrderingParam,
      });
      if (!isEqual({ ...filtersObject, o }, selectedFiltersOnLocalStorage))
        updateSelectedFiltersOnLocalStorage({ ...filtersObject, o });
      updateFiltersOnQueryString({ ...filtersObject, o });
    } else {
      setFilterState('FETCH_NOW');
    }
  };

  const [fetchFilters] = useEntities(
    actions.currentUser.filters,
    ({ status, data: { selected_mentor_filters: selectedFiltersOnDB } }) => {
      if (status === STATUS_DONE) {
        updateFilterQuerystringFromStickyFilters(selectedFiltersOnDB);
      }
    }
  );

  const handleFilterChange = (value) => {
    const o = getOrderingValueAccordingToAvaliabilityFilter(value);

    if (!isEqual(value, selectedFiltersOnLocalStorage))
      updateSelectedFiltersOnLocalStorage({ ...value, o });
    updateFiltersOnQueryString({ ...value, o });
  };

  const handleSortChange = (value) => {
    if (!isEqual({ ...currentFilters, o: value }, selectedFiltersOnLocalStorage))
      updateSelectedFiltersOnLocalStorage({ ...currentFilters, o: value });
    updateFiltersOnQueryString({ ...currentFilters, o: value });
  };

  useEffect(() => {
    if (!selectedFiltersOnLocalStorage) {
      fetchFilters({
        system_page: SYSTEM_PAGE_NAMES.mentorshipSessionsFilter,
      });
    } else {
      updateFilterQuerystringFromStickyFilters(selectedFiltersOnLocalStorage);
    }
  }, []);

  const [
    fetchAllMentors,
    {
      data: allMentors,
      status: fetchAllMentorsStatus,
      nextPage: allMentorsNextPage,
      count: allMentorsCount,
    },
    loadMoreAllMentors,
  ] = useEntities(
    actions.mentor.retrieveList,
    ({ status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.MENTORS_VIEW, {
          filters: currentFilters,
        });
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.mentor.retrieveListLoadMore,
    }
  );

  const fetchMentors = () => {
    fetchAllMentors({
      ...currentFilters,
      page_size: MENTORSHIP_PAGE_SIZE,
      sticky: true,
    });
  };

  // Used for first load with filters in URL
  useEffect(() => {
    if (filterState === 'FETCH_NOW') {
      setFilterState('READY');
      fetchMentors();
    }
  }, [filterState]);

  // Used when filters change (UI or sticky filters)
  useEffect(() => {
    if (filterState === 'READY') {
      fetchMentors();
    }
  }, [currentFilters]);

  const isLoadingAllMentors = !includes([STATUS_DONE, STATUS_ERROR], fetchAllMentorsStatus);

  const {
    label_mentorship_sessions_creator_title_plural: mentorshipHostLabelPlural,
    label_mentorship_sessions_attendee_title_plural: mentorshipAttendeeLabelPlural,
    label_mentorship_tab: mentorshipTab,
  } = useLabels();

  const getSearch = () => {
    const searchValue = get(currentFilters, 'q');

    if (isEmpty(searchValue)) return [];

    return [
      {
        id: 'q',
        label: searchValue,
        icon: 'search',
        group: 'q',
      },
    ];
  };

  const getAvailabilities = () => {
    const availabilityAsMentorValue = currentFilters.availability_as_mentor;

    if (isEmpty(availabilityAsMentorValue)) return [];

    return map(availabilityAsMentorValue, (availabilityAsMentorId) => {
      const item = find(MENTOR_AVAILABILITY_OPTIONS, ['value', availabilityAsMentorId]);
      const label = get(item, 'name');

      return {
        id: availabilityAsMentorId,
        label,
        icon: 'clock',
        group: 'availability_as_mentor',
      };
    });
  };

  const [selectedLocations, setSelectedLocations] = useState([]);

  const getLocations = () => {
    return map(selectedLocations, (location) => ({
      id: location.value,
      label: location.name,
      icon: 'location',
      group: 'location',
    }));
  };

  const getName = () => {
    const nameValue = get(currentFilters, 'name');

    if (isEmpty(nameValue)) return [];
    return [
      {
        id: 'name',
        label: nameValue,
        icon: 'text',
        group: 'name',
      },
    ];
  };

  const getTitle = () => {
    const titleValue = get(currentFilters, 'title');

    if (isEmpty(titleValue)) return [];
    return [
      {
        id: 'title',
        label: titleValue,
        icon: 'suitcase',
        group: 'title',
      },
    ];
  };

  const getDepartment = () => {
    const departmentValue = get(currentFilters, 'department');

    if (isEmpty(departmentValue)) return [];
    return [
      {
        id: 'department',
        label: departmentValue,
        icon: 'room',
        group: 'department',
      },
    ];
  };

  const [selectedOrgLeads, setSelectedOrgLeads] = useState([]);

  const getOrgLeads = () => {
    return map(selectedOrgLeads, (orgLead) => ({
      id: orgLead.value,
      label: orgLead.name,
      icon: 'person',
      group: 'org_lead',
    }));
  };

  const [selectedManagers, setSelectedManagers] = useState([]);

  const getManagers = () => {
    return map(selectedManagers, (manager) => ({
      id: manager.value,
      label: manager.name,
      icon: 'person',
      group: 'manager',
    }));
  };

  const [selectedTeammates, setSelectedTeammates] = useState([]);

  const getTeammates = () => {
    return map(selectedTeammates, (teammate) => ({
      id: teammate.value,
      label: teammate.name,
      icon: 'person',
      group: 'teammate',
    }));
  };

  const [selectedGroups, setSelectedGroups] = useState([]);

  const getGroups = () => {
    return map(selectedGroups, (group) => ({
      id: group.value,
      label: group.name,
      icon: 'group',
      group: 'group',
    }));
  };

  const [selectedTopics, setSelectedTopics] = useState({});

  const getTopics = (topicType) => {
    if (!selectedTopics[topicType]) return [];

    return map(selectedTopics[topicType], (topic) => {
      return {
        id: topic.value,
        label: topic.name,
        icon: 'tag',
        group: topicType,
      };
    });
  };

  const selectedMentorCardAttributes = getMentorCardAttributesAccordingToAvailabilityFilters(
    currentFilters.availability_as_mentor
  );

  const allMentorsCardData = map(allMentors, (mentor) => ({
    ...mentor,
    free_hosting_sessions_count: get(mentor, selectedMentorCardAttributes.count),
    next_session_date: get(mentor, selectedMentorCardAttributes.nextDate),
  }));

  return (
    <>
      <PageTitle title={mentorshipTab} />
      <PeoplePageBaseContainer
        options={{ availability_as_mentor: MENTOR_AVAILABILITY_OPTIONS }}
        updateSelectedFilters={handleFilterChange}
        selectedFilters={currentFilters}
        viewMode="mentor"
        viewportWidthOptions={{
          tablet: '700px',
          desktopSm: '940px',
          desktopLg: '1180px',
        }}
        setSelectedLocations={setSelectedLocations}
        setSelectedOrgLeads={setSelectedOrgLeads}
        setSelectedManagers={setSelectedManagers}
        setSelectedTeammates={setSelectedTeammates}
        setSelectedGroups={setSelectedGroups}
        setSelectedTopics={setSelectedTopics}
      >
        <PeopleHeaderWrapper>
          <PeopleHeader>
            <PeopleHeaderLeft>
              <Text size="h1" color={colors.neutral900} medium>
                {mentorshipTab}
              </Text>
              {toggleMenteePreferences && (
                <SegmentedControlsInput
                  items={[
                    {
                      name: `${mentorshipHostLabelPlural}`,
                      value: 'mentorList',
                      route: mapRoute('mentorList'),
                    },
                    {
                      name: `${mentorshipAttendeeLabelPlural}`,
                      value: 'menteeList',
                      route: mapRoute('menteeList'),
                    },
                  ]}
                  value="mentorList"
                />
              )}
            </PeopleHeaderLeft>

            <PeopleHeaderActions>
              <SortWrapper>
                <SortSelectFieldWrapper>
                  <SelectField
                    label="Sort by"
                    options={[
                      {
                        label: 'Upcoming',
                        value: selectedMentorCardAttributes.nextDate,
                      },
                      { label: 'Name (A-Z)', value: 'name' },
                      { label: 'Name (Z-A)', value: '-name' },
                      { label: 'Interest', value: 'has_mentor_profile,name' },
                      { label: 'Title', value: 'title' },
                      { label: 'Department', value: 'department' },
                      { label: 'Location', value: 'location' },
                    ]}
                    input={{
                      value: currentFilters.o || '',
                      onChange: handleSortChange,
                      name: 'sort-mentor',
                      id: 'sort-mentor',
                    }}
                  />
                </SortSelectFieldWrapper>
              </SortWrapper>

              {includes(permissions, permissionConstants.CREATE_MENTORSHIP_SESSION_PERMISSION) && (
                <Button
                  startIcon={<Icon name="plus" />}
                  route={mapRoute('sessionMentorshipCreate')}
                >
                  New Session
                </Button>
              )}
            </PeopleHeaderActions>
          </PeopleHeader>

          <PeopleFilterPageContentManagerWrapper>
            <FilterPageContentManager
              selecteds={currentFilters}
              onChange={handleFilterChange}
              pills={[
                ...getSearch(),
                ...getAvailabilities(),
                ...getLocations(),
                ...getName(),
                ...getTitle(),
                ...getDepartment(),
                ...getOrgLeads(),
                ...getManagers(),
                ...getTeammates(),
                ...getGroups(),
                ...reduce(
                  buildFlexibleTagsFieldNameList(currentUser),
                  (temp, tagName) => [...temp, ...getTopics(tagName)],
                  []
                ),
              ]}
              initialFilters={{ o: ['next_session_date'] }}
              notListAttributes={['q', 'name', 'title', 'department']}
            />
          </PeopleFilterPageContentManagerWrapper>
        </PeopleHeaderWrapper>

        <PeopleSectionHeader>
          <TextContainer size="h4" color={colors.neutral600} maxWidth="80%">
            <ReactMarkdown linkTarget="_blank">{labelCtaMentorPageTipFilters}</ReactMarkdown>
          </TextContainer>
          <Text color={colors.neutral600}>
            {allMentorsCount} {allMentorsCount === 1 ? 'person' : 'people'}
          </Text>
        </PeopleSectionHeader>

        {isLoadingAllMentors && <Loading />}

        {!isLoadingAllMentors && allMentors.length <= 0 && (
          <PeopleNotFoundMessage>No mentors found</PeopleNotFoundMessage>
        )}

        <PeopleCardList>
          {map(allMentorsCardData, (mentor) => (
            <MentorCard key={mentor.id} mentor={mentor} hasMargins cardProps={{ width: '220px' }} />
          ))}
        </PeopleCardList>
        <PeopleLoadMoreButtonWrapper>
          <LoadMoreButton
            isLoading={fetchAllMentorsStatus === STATUS_LOADING_MORE}
            show={Boolean(allMentorsNextPage)}
            onClick={loadMoreAllMentors}
            size="large"
          />
        </PeopleLoadMoreButtonWrapper>
      </PeoplePageBaseContainer>
    </>
  );
};

export default MentorFilter;
