import PropTypes from 'prop-types';
import React from 'react';

import { get } from 'vendor/lodash';

import { ContentItemCard } from './ContentItemCard';

const ProgramCard = ({ program, disableAllClicks }) => {
  return (
    <ContentItemCard
      contentItem={program}
      finishedTypeLabel="Attended"
      isFinished={program.user_has_attended}
      isHeaderContentDisabled={program.upcoming_sessions_count === 0}
      finishedProps={{
        singleText: 'person engaged',
        multipleText: 'people engaged',
        amount: get(program, 'assignments_count', get(program, 'total_assignments')),
      }}
      disableAllClicks={disableAllClicks}
    />
  );
};

ProgramCard.propTypes = {
  program: PropTypes.object,
  disableAllClicks: PropTypes.bool,
};

export { ProgramCard };
