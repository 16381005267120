import { filter, find, get, map, maxBy, minBy, fill } from 'vendor/lodash';

import { GoogleChartQuarterEntry } from './interfaces';

/**
 * Fills the quarter/year gaps, adding empty entries between the first and last quarter
 *
 * @param data initial data
 * @param columnsLength length of the data column
 * @returns an array with all the quarters between the first and last filled
 */
export const fillQuarterGapsForGoogleChart = (
  data: GoogleChartQuarterEntry[],
  columnsLength: number
): GoogleChartQuarterEntry[] => {
  if (data?.length === 0) return [];

  const lastYear = maxBy(data, 'year')?.year;
  const firstYear = minBy(data, 'year')?.year;

  if (!lastYear || !firstYear) return [];

  const firstQuarter = minBy(
    filter(data, ({ year }) => year === firstYear),
    'quarter'
  )?.quarter;
  const lastQuarter = maxBy(
    filter(data, ({ year }) => year === lastYear),
    'quarter'
  )?.quarter;

  if (!lastQuarter || !firstQuarter) return [];

  const filledData: typeof data = [];

  for (let year = firstYear; year <= lastYear; year += 1) {
    for (let quarter = 1; quarter <= 4; quarter += 1) {
      if (
        (year === firstYear && quarter < firstQuarter) ||
        (year === lastYear && quarter > lastQuarter)
      ) {
        continue; // eslint-disable-line no-continue
      }

      const entry = find(data, { quarter, year }) || {
        quarter,
        year,
        data: fill(new Array(columnsLength), 0),
      };
      filledData.push(entry);
    }
  }

  return filledData;
};

/**
 * Formats data to be presented in a Google Bar Chart (per quarter)
 *
 * - Fills missing quarters between the max and min quarters received.
 * - Concatenates Year and Quarter into a single string.
 *
 * @param data the initial data
 * @returns an bi-dimensional array in which each row is composed of another array
 * containing the quarter/year string followed by the remaining chart data
 */
export const formatAndFillDataListForGoogleChart = (
  data: GoogleChartQuarterEntry[]
): (string | number)[][] => {
  const columnsLength = get(data, '0.data', []).length;

  const filledData = fillQuarterGapsForGoogleChart(data, columnsLength);

  return map(filledData, (entry) => {
    const { quarter, data } = { ...entry, quarter: `${entry.year} Q${entry.quarter}` };

    return [quarter, ...data];
  });
};
