import { useCallback, useContext, useRef, useState } from 'react';

import { QuestionTypes } from 'assessments/components/AssessmentQuestionForm';
import { CONTENT_TYPES } from 'catalog/constants';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import HR from 'shared/components/HR';
import KebabMenu, { HandlesClose, KebabMenuItem } from 'shared/components/KebabMenu';
import OldForm from 'shared/components/OldForm';
import { useLabels } from 'shared/hooks';
import {
  useAddTrackItems,
  useCreateTrackSection,
  useDestructiveActions,
  useTrackSectionsAndItems,
} from 'tracks/hooks';
import { FullTrackSection, TrackItem, TrackSection, trackTypes } from 'tracks/interfaces';
import { getTrackSectionsAndItemsCountText } from 'tracks/services';
import {
  capitalize,
  every,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  noop,
  omit,
  toLower,
} from 'vendor/lodash';
import { Grid, Box, IconButton, Menu, MenuItem } from 'vendor/mui';
import {
  AddCircleOutlineIcon,
  AddIcon,
  ClearAllOutlinedIcon,
  DeleteIcon,
  DvrIcon,
  EditAttributesIcon,
  RemoveCircleOutlineIcon,
  SplitscreenIcon,
} from 'vendor/mui-icons';

import ScheduledTrackSwapEventModal from '../ScheduledTrackSwapEventModal';
import { AssessmentQuestionModalsContext } from '../TrackForm';
import TrackItemChangeAssignmentDueDateModal from '../TrackItemChangeAssignmentDueDateModal';

import { TrackItemFormHeader } from './TrackItemFormHeader';
import { TrackSectionContainer } from './TrackSectionContainer';

const ACTION_BUTTON_SX = {
  color: colors.emphasis700,
  borderColor: colors.emphasis700,
  '&:hover': {
    color: colors.emphasis800,
    borderColor: colors.emphasis800,
  },
};

export interface TrackItemsHeaderContextMenuProps {
  handleRemoveAllSections?: () => void;
  handleRemoveAllItems: () => void;
  handleRemoveAll?: () => void;
  handleToggleIsInline: ({ isInline }: { isInline: boolean }) => void;
  showDestructiveTrackItemsActions?: boolean;
}

interface IndexedTrackItem {
  item: TrackItem;
  index: number;
}

const TrackItemsHeaderContextMenu = ({
  handleRemoveAllSections,
  handleRemoveAllItems,
  handleRemoveAll,
  handleToggleIsInline,
  showDestructiveTrackItemsActions = true,
}: TrackItemsHeaderContextMenuProps) => {
  const contextMenuRef = useRef<HandlesClose>(null);
  const showHR = Boolean(handleRemoveAllSections || showDestructiveTrackItemsActions);

  return (
    <KebabMenu ref={contextMenuRef}>
      <KebabMenuItem
        onClick={(e) => {
          contextMenuRef?.current?.handleClose(e);
          handleToggleIsInline({ isInline: false });
        }}
        ActionIcon={EditAttributesIcon}
        actionText="Set all as Standalone"
      />
      <KebabMenuItem
        onClick={(e) => {
          contextMenuRef?.current?.handleClose(e);
          handleToggleIsInline({ isInline: true });
        }}
        ActionIcon={DvrIcon}
        actionText="Set all as Inline"
      />

      {showHR && <HR />}

      {handleRemoveAllSections && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveAllSections();
          }}
          color={colors.error600}
          ActionIcon={ClearAllOutlinedIcon}
          actionText="Remove all sections"
          actionSubtext="(Keep content)"
        />
      )}
      {showDestructiveTrackItemsActions && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveAllItems();
          }}
          color={colors.error600}
          ActionIcon={RemoveCircleOutlineIcon}
          actionText="Remove all items"
          actionSubtext="(Keep sections)"
        />
      )}
      {showDestructiveTrackItemsActions && handleRemoveAll && (
        <KebabMenuItem
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleRemoveAll();
          }}
          color={colors.error600}
          ActionIcon={DeleteIcon}
          actionText="Remove everything"
        />
      )}
    </KebabMenu>
  );
};

interface TrackItemsFormProps {
  sections: any[];
  items: TrackItem[];
  change: (fieldName: string, value: any) => void;
  renderAlert?: () => React.ReactNode;
  handleSwappedEventsMap?: (event_data: any) => void;
  trackType: trackTypes;
  trackStartsAt?: string;
  trackEndsAt?: string;
  error?: Error | string;
  invalid?: boolean;
  submitting?: boolean;
  isEdit?: boolean;
  form?: string;
}

const TrackItemsForm = ({
  sections,
  items: allItems,
  change,
  renderAlert,
  trackType = CONTENT_TYPES.track,
  handleSwappedEventsMap = noop,
  trackEndsAt,
  error,
  invalid,
  submitting,
  isEdit = false,
}: TrackItemsFormProps) => {
  const [questionMenuAnchor, setQuestionMenuAnchor] = useState<HTMLElement | null>(null);
  const [questionMenuSectionContext, setQuestionMenuSectionContext] = useState<FullTrackSection>();
  const {
    showContentModal,
  }: { showContentModal: (type: string, options?: Record<string, any>) => void } = useContext(
    AssessmentQuestionModalsContext
  );
  const [itemToChangeAssignmentDueDate, setItemToChangeAssignmentDueDate] =
    useState<IndexedTrackItem | null>(null);
  const [eventToSwap, setEventToSwap] = useState<IndexedTrackItem | null>(null);
  const [eventToSchedule, setEventToSchedule] = useState<IndexedTrackItem | null>(null);

  const items = filter(allItems, (item) => !item.archival);

  const {
    label_track: labelTrack,
    label_assessment: labelAssessment,
    label_question: labelQuestion,
    label_question_plural: labelQuestionPlural,
    label_choice: labelChoice,
  } = useLabels();

  const { sectionsWithItems } = useTrackSectionsAndItems(sections, items);

  const handleAddSection = useCreateTrackSection(sections, items, change);
  const handleAddTrackItems: (session: TrackSection, items: any) => void = useAddTrackItems(
    items,
    change
  );

  const handleEditSection = useCallback(
    ({ id, name, description }) => {
      const newSections = map(sections, (trackSection) => {
        if (trackSection.id !== id) return trackSection;
        return {
          ...trackSection,
          name,
          description,
        };
      });
      change('sections', newSections);
    },
    [sections, change]
  );

  const handleToggleIsRequired = useCallback(
    (trackItem: TrackItem) => {
      change(
        'track_items',
        map(items, (item) =>
          item.content_item.public_id === trackItem.content_item.public_id
            ? { ...item, is_required: !get(item, 'is_required', true) }
            : item
        )
      );
    },
    [items, change]
  );

  const handleToggleIsInline = useCallback(
    ({
      trackItem,
      section,
      isInline,
    }: {
      trackItem?: TrackItem;
      section?: TrackSection;
      isInline?: boolean;
    }) => {
      if (every([trackItem, section, isInline], isNil)) return;

      change(
        'track_items',
        map(items, (item) => {
          // Tasks only behave as an inline item
          // Events cannot be set as an inline item
          if (includes([CONTENT_TYPES.task, CONTENT_TYPES.event], item.content_item.content_type))
            return item;

          // Set a single item as inline/standalone
          if (!isNil(trackItem)) {
            return trackItem?.content_item.public_id === item.content_item.public_id
              ? {
                  ...item,
                  content_item: { ...item.content_item, is_inline: !item.content_item.is_inline },
                }
              : item;
          }

          // Set all items within a section as inline/standalone
          if (!isNil(section) && !isNil(isInline)) {
            return section?.id === item.section && !isNil(isInline)
              ? {
                  ...item,
                  content_item: { ...item.content_item, is_inline: isInline },
                }
              : item;
          }

          // Set all items within a track as inline/standalone
          if (!isNil(isInline)) {
            return {
              ...item,
              content_item: { ...item.content_item, is_inline: isInline },
            };
          }

          return item;
        })
      );
    },
    [items, change]
  );

  const handleReplaceAtIndex = (item, index) => {
    const items = [...allItems];
    items.splice(index, 1, item);
    change('track_items', items);
  };

  const handleOnSwapEvent = (newEvent, enrollmentsAction, index) => {
    if (eventToSwap == null) return;
    const { item } = eventToSwap;
    const { content_item: prevEvent } = item;

    handleReplaceAtIndex(omit({ ...item, content_item: newEvent }, 'id'), index);
    handleSwappedEventsMap({ prevEvent, newEvent, enrollmentsAction });
  };

  const handleOnScheduleEvent = (newEvent, enrollmentsAction, index) => {
    if (eventToSchedule == null) return;
    const { item } = eventToSchedule;
    const { content_item: eventType } = item;
    handleReplaceAtIndex(omit({ ...item, content_item: newEvent }, 'id'), index);
    handleSwappedEventsMap({ prevEvent: eventType, newEvent, enrollmentsAction });
  };

  const {
    handleRemoveAll,
    handleRemoveAllSections,
    handleRemoveAllItems,
    handleRemoveItemsFromSection,
    handleRemoveSection,
    handleRemoveItem,
  } = useDestructiveActions(sections, items, change, trackType);

  const hasQuestionWithAnswer = !isEmpty(
    find(items, (trackItem) => {
      return get(trackItem, 'content_item.completed_assignments_count', 0) > 0;
    })
  );
  const showDestructiveTrackItemsActions =
    trackType === CONTENT_TYPES.assessment && !hasQuestionWithAnswer;

  const trackItemsHeaderContextMenuProps = sections?.length
    ? {
        handleRemoveAllSections,
        handleRemoveAllItems,
        handleRemoveAll,
        handleToggleIsInline,
        showDestructiveTrackItemsActions,
      }
    : { handleRemoveAllItems, handleToggleIsInline, showDestructiveTrackItemsActions };

  const getTitle = () => {
    if (trackType === CONTENT_TYPES.assessment) {
      return `${capitalize(labelAssessment)} ${capitalize(labelQuestionPlural)}`;
    }

    if (trackType === CONTENT_TYPES.scheduled_track) {
      return `Scheduled ${capitalize(labelTrack)} Items`;
    }

    return `${capitalize(labelTrack)} Items`;
  };

  const title = getTitle();

  const trackLabel =
    trackType === CONTENT_TYPES.assessment ? toLower(labelAssessment) : toLower(labelTrack);
  const contentLabel =
    trackType === CONTENT_TYPES.assessment ? toLower(labelQuestionPlural) : 'content';

  const subTitle =
    isEmpty(items) && isEmpty(sections)
      ? `No ${contentLabel} yet. Select one of the options to add and organize ${contentLabel} in this ${trackLabel}.`
      : getTrackSectionsAndItemsCountText(sections, items);

  const trackItemsIds = map(items, ({ content_item: contentItem }) => contentItem.id);

  const handleAddContentClick = (event, section?: FullTrackSection) => {
    if (trackType === CONTENT_TYPES.assessment) {
      setQuestionMenuSectionContext(section);
      return setQuestionMenuAnchor(event?.currentTarget);
    }

    return showContentModal('trackItem', { section });
  };

  const handleAddQuestionClick = (questionType: QuestionTypes) => {
    showContentModal(questionType, { section: questionMenuSectionContext });
    setQuestionMenuAnchor(null);
  };

  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          rowGap: '20px',
        }}
      >
        {error && (
          <OldForm.ErrorContainer invalid={invalid} error={error} submitting={submitting} />
        )}

        {renderAlert ? renderAlert() : null}

        <TrackItemFormHeader
          title={title}
          subtitle={subTitle}
          actionButtons={
            <>
              {(trackType === CONTENT_TYPES.track ||
                trackType === CONTENT_TYPES.scheduled_track) && (
                <IconButton
                  sx={{ color: 'primary.dark' }}
                  onClick={() => showContentModal('trackItem')}
                  disabled={!isEmpty(sectionsWithItems)}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
              <IconButton sx={{ color: 'primary.dark' }} onClick={handleAddSection}>
                <SplitscreenIcon />
              </IconButton>
              <TrackItemsHeaderContextMenu {...trackItemsHeaderContextMenuProps} />
            </>
          }
        />
        {isEmpty(sectionsWithItems) ? (
          <TrackSectionContainer
            section={{ items }}
            hasFullSection={false}
            handleSetEventToSchedule={setEventToSchedule}
            handleSetItemToChangeAssignmentDueDate={setItemToChangeAssignmentDueDate}
            handleSetEventToSwap={setEventToSwap}
            {...{
              trackType,
              trackItemsIds,
              handleToggleIsRequired,
              handleToggleIsInline,
              handleRemoveItem,
              handleAddContentClick,
              isEdit,
            }}
          />
        ) : (
          map(sectionsWithItems, (section, i) => (
            <TrackSectionContainer
              key={section.id || `section-${i}`}
              hasFullSection
              handleAddContentClick={(event) => handleAddContentClick(event, section)}
              handleSetEventToSchedule={setEventToSchedule}
              handleSetItemToChangeAssignmentDueDate={setItemToChangeAssignmentDueDate}
              handleSetEventToSwap={setEventToSwap}
              {...{
                section,
                trackType,
                trackItemsIds,
                handleAddTrackItems,
                handleEditSection,
                handleToggleIsRequired,
                handleToggleIsInline,
                handleRemoveItem,
                handleRemoveSection,
                handleRemoveItemsFromSection,
                isEdit,
              }}
            />
          ))
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '8px',
            justifyContent: 'center',
            pt: '20px',
          }}
        >
          <Button
            type="button"
            data-testid="add-content"
            startIcon={<AddIcon />}
            sx={ACTION_BUTTON_SX}
            onClick={(event) => handleAddContentClick(event)}
            disabled={!isEmpty(sectionsWithItems)}
          >
            Add {trackType === CONTENT_TYPES.assessment ? toLower(labelQuestion) : 'content'}
          </Button>
          <Button startIcon={<SplitscreenIcon />} sx={ACTION_BUTTON_SX} onClick={handleAddSection}>
            Add section
          </Button>
        </Box>
      </Grid>
      <Menu
        anchorEl={questionMenuAnchor}
        open={!!questionMenuAnchor}
        onClose={() => setQuestionMenuAnchor(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleAddQuestionClick(CONTENT_TYPES.multiple_choice_question)}>
          Multiple {capitalize(labelChoice)}
        </MenuItem>
        <MenuItem onClick={() => handleAddQuestionClick(CONTENT_TYPES.text_question)}>
          Short Answer
        </MenuItem>
      </Menu>
      {itemToChangeAssignmentDueDate && (
        <TrackItemChangeAssignmentDueDateModal
          {...itemToChangeAssignmentDueDate}
          handleUpdate={handleReplaceAtIndex}
          handleClose={() => setItemToChangeAssignmentDueDate(null)}
        />
      )}
      {eventToSwap && (
        <ScheduledTrackSwapEventModal
          {...eventToSwap}
          handleUpdate={handleOnSwapEvent}
          handleClose={() => setEventToSwap(null)}
          isEditing={isEdit}
          trackEndsAt={trackEndsAt}
        />
      )}
      {eventToSchedule && (
        <ScheduledTrackSwapEventModal
          {...eventToSchedule}
          handleUpdate={handleOnScheduleEvent}
          handleClose={() => setEventToSchedule(null)}
          isEditing={isEdit}
          trackEndsAt={trackEndsAt}
        />
      )}
    </>
  );
};

export default TrackItemsForm;
