import { filter, map, pick } from 'vendor/lodash';

// Sometimes the form can submit a resource link without any information, this will be removed to avoid
// an annoying message to the user, also, this ensures we send only the necessary fields to the endpoint.
export const parseResources = (resources, isEdit) => {
  return map(
    filter(resources, (resource) => resource.name && resource.link),
    (resource) => {
      const linkResource = pick(resource, ['name', 'link']);
      if (isEdit) linkResource.id = resource.id;
      return linkResource;
    }
  );
};
