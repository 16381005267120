import { CreateContentDropdown } from 'common/components/CreateContentDropdown';
import {
  Widget,
  WidgetContent,
  WidgetContentList,
  WidgetContentListItem,
  WidgetCreateAction,
  WidgetLinkAction,
} from 'common/components/widget';
import { mapRoute } from 'services/requests';
import { useCurrentUser } from 'shared/hooks';
import { map, size, get } from 'vendor/lodash';

import {
  ContentItemWidgetLoadingContent,
  ContentItemWidgetEmptyContent,
  ContentItemWidgetRow,
} from '../components/BaseContentItemAsyncWidget';
import { useContentItemWidgetQuery } from '../hooks/useContentItemWidgetQuery';

import { baseSearch } from './utils';

type ContentIOwnWidgetActionProps = {
  search: string;
  total: number;
};

function ContentIOwnWidgetAction(props: ContentIOwnWidgetActionProps) {
  const { search, total } = props;

  if (total <= 3) {
    return (
      <CreateContentDropdown>
        {({ toggleMenu }) => <WidgetCreateAction label="Create Content" onClick={toggleMenu} />}
      </CreateContentDropdown>
    );
  }

  const viewAllUrl = `${mapRoute('unifiedCatalogList')}?${search}`;

  return <WidgetLinkAction label={`View all ${total}`} url={viewAllUrl} />;
}

type ContentIOwnWidgetProps = {
  hideOnEmpty?: boolean;
};

export function ContentIOwnWidget(props: ContentIOwnWidgetProps) {
  const { hideOnEmpty = false } = props;

  const currentUser = useCurrentUser();
  const userID = get(currentUser, 'id');

  const search = `eq(facilitator,${userID})&ordering(-total_assignments)`;
  const querySearch = `${baseSearch}&${search}`;

  const { data, isLoading } = useContentItemWidgetQuery(querySearch);

  const results = get(data, 'results', []);
  const displayResults = !isLoading && size(results) > 0;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Content I Own"
      tooltip="Content I created"
      action={
        !isLoading ? (
          <ContentIOwnWidgetAction search={search} total={get(data, 'total', 0)} />
        ) : null
      }
    >
      {isLoading || displayResults ? (
        <WidgetContent>
          <WidgetContentList>
            {displayResults ? (
              map(results, (contentItem) => (
                <WidgetContentListItem key={contentItem.id}>
                  <ContentItemWidgetRow contentItem={contentItem} />
                </WidgetContentListItem>
              ))
            ) : (
              <ContentItemWidgetLoadingContent />
            )}
          </WidgetContentList>
        </WidgetContent>
      ) : (
        <ContentItemWidgetEmptyContent label={'You didn\'t create any content'} />
      )}
    </Widget>
  );
}
