import Grid from '@mui/material/Grid';
import { useQuery } from '@tanstack/react-query';

import TableDisplay from 'app/shared-modals/components/TableDisplay';
import { queries } from 'queries';
import Page404 from 'scenes/404';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { get, map, filter, isString, isEmpty, isObject } from 'vendor/lodash';
import { Box } from 'vendor/mui';

interface CustomAttributesModalProps {
  user: {
    id: number;
  };
  handleClose: () => void;
}

const DEFAULT_ATTRIBUTES = [
  { key: 'title', label: 'Title' },
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'bio', label: 'Bio' },
  { key: 'location_text', label: 'Location' },
  { key: 'timezone', label: 'Timezone' },
  { key: 'department', label: 'Department' },
  { key: 'working_since', label: 'Hire date' },
  { key: 'employee_id', label: 'Employee ID' },
  { key: 'people_integration_id', label: 'HRIS ID' },
];

const get_bio_info = (value) => {
  try {
    const parsedBio = JSON.parse(value);
    if (parsedBio && isObject(parsedBio)) {
      const blocks = get(parsedBio, 'blocks', {});
      return map(blocks, (block: any) => block.text).join(' ');
    }
  } catch (error) {
    return value;
  }
};

const CustomAttributesModal = (props: CustomAttributesModalProps) => {
  const { user, handleClose } = props;
  const { id: userId } = user;
  const { data, isLoading, isError } = useQuery(queries.users.detail(userId, 'custom_attributes'));

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  const userAttributes = map(DEFAULT_ATTRIBUTES, ({ key, label }) => {
    let value = get(data, key, '');

    if (key === 'bio' && value) {
      value = get_bio_info(value);
    }

    return { label, value: isString(value) ? value : '' };
  });
  const userValidAttributes = filter(userAttributes, ({ value }) => !!value);

  const peopleIntegrationData = get(data, 'people_integration_data', {});
  const itegrationData = Object.entries(peopleIntegrationData);

  return (
    <Modal title="Custom Attributes" handleClose={handleClose} width={600}>
      <ModalBody>
        <Grid container spacing={2} mb={0.5}>
          <Grid item xs={12}>
            {map(userValidAttributes, ({ label, value }, index) => (
              <Grid container key={index} mb={0.7}>
                <Grid item xs={4}>
                  <Text bold>{label}:</Text>
                </Grid>
                <Grid item xs={8}>
                  <Text>{value}</Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box mt={2}>
          <Text>People Integration Data:</Text>
          {isEmpty(itegrationData) && <Text size="h4">This person has no custom attributes.</Text>}
          {!isEmpty(itegrationData) && (
            <TableDisplay columns={['Attribute', 'Value']} rows={itegrationData} />
          )}
        </Box>
      </ModalBody>
      <ModalFooter variant="buttons">
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomAttributesModal;
