import { PageBody } from 'app/shared/components/DefaultPage/PageBody';
import { PageContainer } from 'app/shared/components/DefaultPage/PageContainer';
import { LEARNING_TYPES } from 'catalog/constants';
import { useContentFeedback, useContentPeople } from 'catalog/hooks';
import ContentItemContainer from 'content-items/components/ContentItemContainer';
import { useIsRenderedWithinTrack } from 'features/tracks/hooks/useIsRenderedWithinTrack';
import { LinkedContent } from 'linkedcontent/interfaces';
import colors from 'services/colors';
import { Text } from 'shared';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import BreadCrumbs from 'shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from 'shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemHeader from 'shared-content-item/components/ContentItemHeader';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { STATUS_DONE } from 'shared/constants';
import { useIsPreviewQueryParam } from 'shared/hooks';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import BaseFeedbackContainer from 'stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { Container, Grid, Paper, Stack, Typography } from 'vendor/mui';

import LinkedContentContextMenu from '../LinkedContentContextMenu';
import PrimaryActionButton from '../PrimaryActionButton';

interface LinkedContentDetailPageProps {
  content: LinkedContent;
  refreshContent?: CallableFunction;
  isRequired?: boolean;
}

export const LinkedContentDetailPage = (props: LinkedContentDetailPageProps) => {
  const { content, refreshContent, isRequired } = props;

  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.linkedcontent
  );

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const { is_inline: isInline } = content;
  const clickableName = isInline ? false : isRenderedWithinTrack;
  const isPreviewMode = useIsPreviewQueryParam();

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={content.name} />}
      <ContentItemBackgroundPanel height="120px" />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} margin="20px 0" />}

      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          maintainers={maintainers}
          contextMenu={
            <LinkedContentContextMenu content={content} refreshContent={refreshContent} />
          }
          primaryActionButton={PrimaryActionButton}
          clickableName={clickableName}
          isRequired={isRequired}
          isPreviewMode={isPreviewMode}
        />

        <PageBody>
          <Stack direction="column" gap={3}>
            <Paper>
              <Grid container>
                <Grid item xs={8}>
                  <Container sx={{ padding: '30px' }}>
                    <Typography fontSize="18px" color={colors.emphasis700}>
                      Description
                    </Typography>
                    <ContentDescription description={content.content_body} noPadding />
                  </Container>
                </Grid>
                <Grid
                  sx={{
                    borderLeft: '1px solid',
                    borderLeftColor: colors.neutral200,
                    background: colors.neutral50,
                  }}
                  item
                  xs={4}
                >
                  {showPeople && (
                    <BasePeopleContainer
                      paperBackground={false}
                      contentType={LEARNING_TYPES.linkedcontent}
                      organizers={organizers}
                      maintainers={maintainers}
                      engagedPeople={engagedPeople}
                      engagedPeopleCount={engagedPeopleCount}
                      engagedPeopleHeading={
                        <Text>
                          {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'}{' '}
                          engaged
                        </Text>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
            <Paper>
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode="wide"
                  useFlexbox
                  withFlexBasis
                  fillContainer
                  isLoading={isLoadingFeedback}
                  latestReviewsLabel=""
                />
              )}
            </Paper>
          </Stack>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
};

export default LinkedContentDetailPage;
