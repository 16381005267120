import { denormalize } from 'normalizr';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import actions from 'entities/actions';
import { userSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import AddPeopleModal from 'groups/components/AddPeopleModal';
import SearchInput from 'inputs/components/SearchInput';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { isEmpty, join, uniq, toString, without, map } from 'vendor/lodash';

import GroupUserListField from './GroupUserListField';

const UserListContainer = styled.div`
  margin-top: 24px;
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const UserListHeader = styled.div`
  display: flex;
`;

const AddPeopleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: 16px;
    flex-basis: 32%;
  }
`;

const GroupUserListFields = ({
  currentMembers,
  new_members: newMembers,
  remove_members: removeMembers,
  newMembersDetailsList,
  newMembersDetailsListStatus,
  searchText,
  handleOnSearchTextChange,
  fetchNextPage,
  isLoadingMore,
  loadMoreLink,
  fetchNewMembers,
  addOnlyMode,
}) => {
  const [showAddPeopleModal, setShowAddPeopleModal] = useState(false);

  const handleAddPeople = (newPeople, users) => {
    const userIds = uniq(
      !isEmpty(users) ? [...newPeople, ...map(users, (user) => user.id.toString())] : newPeople
    );
    newMembers.input.onChange(userIds);
    if (!isEmpty(userIds)) {
      fetchNewMembers(userIds);
    }
    setShowAddPeopleModal(false);
  };

  const handleUndoAddPeople = (userId) => {
    const updatedNewMembers = without(newMembers.input.value, toString(userId), userId);
    newMembers.input.onChange(updatedNewMembers);
  };

  const renderGroupMemberDetails = () => {
    if (newMembersDetailsListStatus === STATUS_LOADING) {
      return <Loading />;
    }

    if (newMembersDetailsListStatus === STATUS_DONE && !isEmpty(newMembersDetailsList)) {
      return (
        <GroupUserListField
          users={newMembersDetailsList}
          handleUndoAddPeople={handleUndoAddPeople}
          additionEnabled
        />
      );
    }
    return addOnlyMode
      ? 'No members will be added to the group'
      : 'No new members will be added to the group.';
  };

  return (
    <div>
      <UserListHeader>
        {!addOnlyMode && (
          <AddPeopleWrapper>
            <SearchInput
              placeholder="Search group members"
              value={searchText}
              onChange={handleOnSearchTextChange}
            />
            <Button
              startIcon={<Icon name="plus" />}
              size="small"
              onClick={() => setShowAddPeopleModal(true)}
              type="button"
            >
              Add Members
            </Button>
          </AddPeopleWrapper>
        )}

        {addOnlyMode && (
          <Button
            startIcon={<Icon name="plus" />}
            size="small"
            onClick={() => setShowAddPeopleModal(true)}
            type="button"
          >
            Add Members
          </Button>
        )}
      </UserListHeader>
      <UserListContainer>
        {!addOnlyMode && (
          <React.Fragment>
            <SimpleInfo>NEW MEMBERS</SimpleInfo>
            <SimpleInfo>Will be added to the group upon saving.</SimpleInfo>
          </React.Fragment>
        )}
        {renderGroupMemberDetails()}
      </UserListContainer>
      {removeMembers && (
        <UserListContainer>
          <SimpleInfo>CURRENT MEMBERS</SimpleInfo>
          <SimpleInfo>Changes will be applied upon saving.</SimpleInfo>
          <GroupUserListField
            users={currentMembers}
            removalInput={removeMembers.input}
            fetchNextPage={fetchNextPage}
            isLoadingMore={isLoadingMore}
            loadMoreLink={loadMoreLink}
            removalEnabled
          />
        </UserListContainer>
      )}
      {showAddPeopleModal && (
        <AddPeopleModal
          handleClose={() => setShowAddPeopleModal(false)}
          handleAddPeople={handleAddPeople}
          initialValues={{ people_ids: newMembers.input.value }}
        />
      )}
    </div>
  );
};

GroupUserListFields.propTypes = {
  new_members: PropTypes.object,
  remove_members: PropTypes.object,
  currentMembers: PropTypes.array,
  searchText: PropTypes.string,
  handleOnSearchTextChange: PropTypes.func,
  newMembersDetailsList: PropTypes.array,
  newMembersDetailsListStatus: PropTypes.string,
  fetchNextPage: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  loadMoreLink: PropTypes.string,
  fetchNewMembers: PropTypes.func,
  addOnlyMode: PropTypes.bool, // This flag makes group members edition features do not appear
};

GroupUserListFields.defaultProps = {
  addOnlyMode: false,
};

const mapStateToProps = (state, props) => {
  const { new_members: newMembers } = props;
  const membersRequestState = getDataFromState('newGroupMembers', state, [userSchema]);
  const newMembersDetailsList = denormalize(newMembers.input.value, [userSchema], state.entities);
  return {
    newMembersDetailsList: newMembersDetailsList || [],
    newMembersDetailsListStatus: membersRequestState.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNewMembers: (newMembersIds) =>
    dispatch(
      actions.userData.retrieveList('newGroupMembers', {
        id__in: join(newMembersIds, ','),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupUserListFields);
