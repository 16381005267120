import { useEffect } from 'react';

import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Page404 from 'scenes/404';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import Loading from 'shared/components/Loading';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from 'shared/constants';
import { useSetDetailedObject, usePublicIdFromURL } from 'shared/hooks';

import LinkedContentDetailPage from './LinkedContentDetailPage';

const LinkedContentDetail = () => {
  const { publicId } = usePublicIdFromURL();

  const { trackActivity } = useMetrics();

  const [fetchContent, { data: content, status: contentStatus, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
          contentItemId: content.id,
          contentItemType: content.content_type,
        });
      }
    },
    {
      schema: contentSchema,
    }
  );

  useEffect(() => {
    fetchContent(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'linkedcontent', []);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (contentStatus !== STATUS_DONE) return <Loading />;

  return (
    <LinkedContentDetailPage content={content} refreshContent={() => fetchContent(publicId)} />
  );
};

export default LinkedContentDetail;
