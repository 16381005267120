import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const GearIcon = ({ width, height, fill }) => {
  const d =
    'M17.685 11.415l1.719.992a.485.485 0 0 1 .22.564 9.984 9.984 0 0 1-2.205 3.814.485.485 0 0 ' +
    '1-.598.091l-1.718-.992a7.725 7.725 0 0 1-2.45 1.417v1.983a.484.484 0 0 1-.378.472 10.08 10' +
    '.08 0 0 1-4.404 0 .485.485 0 0 1-.379-.472v-1.983a7.724 7.724 0 0 1-2.45-1.417l-1.717.992a' +
    '.485.485 0 0 1-.598-.09A9.985 9.985 0 0 1 .522 12.97a.485.485 0 0 1 .22-.564l1.718-.992a7.' +
    '802 7.802 0 0 1 0-2.83L.742 7.593a.485.485 0 0 1-.22-.564 9.984 9.984 0 0 1 2.205-3.814.48' +
    '5.485 0 0 1 .598-.09l1.717.99A7.725 7.725 0 0 1 7.492 2.7V.716c0-.226.157-.423.378-.472a10' +
    '.08 10.08 0 0 1 4.404 0c.222.049.38.245.38.472v1.983a7.724 7.724 0 0 1 2.45 1.417l1.717-.9' +
    '92a.485.485 0 0 1 .598.09 9.985 9.985 0 0 1 2.205 3.815.485.485 0 0 1-.22.564l-1.719.992a7' +
    '.802 7.802 0 0 1 0 2.83zM13.3 10a3.23 3.23 0 0 0-3.226-3.226A3.23 3.23 0 0 0 6.847 10a3.23' +
    ' 3.23 0 0 0 3.226 3.226A3.23 3.23 0 0 0 13.299 10z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

GearIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default GearIcon;
