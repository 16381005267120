import React from 'react';

import { Box } from 'vendor/mui';

export const CenteredDiv = (props: React.ComponentProps<typeof Box>) => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    alignContent="center"
    justifyContent="center"
    {...props}
  />
);

export default CenteredDiv;
