import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import RadioInput from 'inputs/components/RadioInput';
import TextInput from 'inputs/components/TextInput';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { Button } from 'shared';
import ButtonLink from 'shared/components/ButtonLink';
import InfoText from 'shared/components/InfoText';
import Loading from 'shared/components/Loading';
import Form from 'shared/components/OldForm';
import { ErrorContainer } from 'shared/components/OldForm/FormErrorContainer';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { includes, startCase, replace, startsWith, map } from 'vendor/lodash';

const ItemContainer = styled.div`
  padding: 12px 20px 20px;

  & + & {
    border-top: 1px solid ${colors.neutral200};
  }

  ${ErrorContainer} {
    margin-top: 8px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px; // from height Button
  gap: 8px;
`;

const RadioOptions = styled.div``;

const CourseConfigField = ({ field, value, onChange, disabled }) => {
  const type = field.metadata?.data_type ?? '';

  if (startsWith(type, 'Enum.')) {
    const options = field.metadata.valid_values;
    return (
      <RadioOptions>
        {map(options, (option) => {
          return (
            <RadioInput
              key={option.value}
              name={option.value}
              value={option.value}
              label={option.value_description}
              disabled={disabled}
              inputValue={value}
              onChange={(v) => onChange(v)}
              labelSize="h5"
            />
          );
        })}
      </RadioOptions>
    );
  }

  if (type === 'bool') {
    return (
      <RadioOptions>
        <RadioInput
          name="Enabled"
          value="true"
          label="Enabled"
          disabled={disabled}
          inputValue={value}
          onChange={(v) => onChange(v)}
          labelSize="h5"
        />
        <RadioInput
          name="Disabled"
          value="false"
          label="Disabled"
          disabled={disabled}
          inputValue={value}
          onChange={(v) => onChange(v)}
          labelSize="h5"
        />
      </RadioOptions>
    );
  }

  return (
    <TextInput
      id={field.id}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      disabled={disabled}
    />
  );
};

CourseConfigField.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const formatConfigurationLabel = (label) => {
  let title = startCase(label);
  title = replace(title, /\bSco\b/, 'SCO');
  return title;
};

const CourseConfigItem = ({
  field,
  courseId,
  disableActions,
  onStartRequest,
  onEndRequest,
  refreshSettings,
}) => {
  const [value, setValue] = useState(field.effective_value);
  const [hasChanged, setHasChanged] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [blockField, setBlockField] = useState(false);

  const [updateConfiguration, { status: status_update }] = useEntities(
    actions.course.updateConfiguration,
    ({ status, error }) => {
      if (status === STATUS_LOADING) {
        onStartRequest();
      } else if (status === STATUS_DONE) {
        toast.success('Setting updated successfully!', '', {
          autoClose: 3000,
        });
        refreshSettings();
        setHasChanged(false);
      } else if (status === STATUS_ERROR) {
        onEndRequest();
        setRequestError(error?.detail);
      }
    }
  );

  // Other useEntities with the same action so I can differentiate between reset and update
  const [resetConfiguration, { status: status_reset }] = useEntities(
    actions.course.updateConfiguration,
    ({ status, error }) => {
      if (status === STATUS_LOADING) {
        onStartRequest();
      } else if (status === STATUS_DONE) {
        toast.success(
          'Setting reset successful!',
          'This operation might take a while. Please wait a minute to reopen this dialog and change this field again.',
          {
            autoClose: 8000,
          }
        );
        setBlockField(true);
        onEndRequest();
      } else if (status === STATUS_ERROR) {
        onEndRequest();
        setRequestError(error?.detail);
      }
    }
  );

  const handleConfirmConfigChange = () => {
    updateConfiguration(courseId, {
      setting_id: field.id,
      setting_value: value,
    });
  };

  const handleResetConfiguration = () => {
    resetConfiguration(courseId, {
      setting_id: field.id,
      clear: true,
    });
  };

  const handleValueChange = (v) => {
    setValue(v);
    setRequestError(null);
  };

  useEffect(() => {
    if (!blockField) {
      setHasChanged(value !== field.effective_value);
    }
  }, [value, field.effective_value]);

  const isLoading = includes([status_update, status_reset], STATUS_LOADING);
  const showResetButton = field.effective_value_source === 'course';

  return (
    <ItemContainer>
      <LabelContainer>
        <Form.FieldLabel htmlFor={field.id} block={false}>
          {formatConfigurationLabel(field.id)}
        </Form.FieldLabel>
        <ActionContainer>
          {isLoading && <Loading hasMargin={false} />}
          {!isLoading &&
            !hasChanged &&
            showResetButton &&
            (!blockField ? (
              <ButtonLink
                title="Reset configuration to use application default"
                variant="error"
                onClick={handleResetConfiguration}
              >
                Reset
              </ButtonLink>
            ) : (
              <InfoText top={0} bottom={0} fontSize={14} content="Field Reset" />
            ))}
          {!isLoading && hasChanged && (
            <Button
              title="Confirm change"
              size="small"
              onClick={handleConfirmConfigChange}
              disabled={disableActions || blockField}
            >
              Update
            </Button>
          )}
        </ActionContainer>
      </LabelContainer>
      <InfoText content={field.metadata?.setting_description} top={8} bottom={12} />
      <CourseConfigField
        field={field}
        disabled={disableActions || blockField}
        value={value}
        onChange={handleValueChange}
      />
      {requestError && <ErrorContainer>{requestError}</ErrorContainer>}
    </ItemContainer>
  );
};

CourseConfigItem.propTypes = {
  field: PropTypes.object,
  courseId: PropTypes.string,
  disableActions: PropTypes.bool,
  onStartRequest: PropTypes.func,
  onEndRequest: PropTypes.func,
  refreshSettings: PropTypes.func,
};

export default CourseConfigItem;
