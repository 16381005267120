import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from 'app/shared/components/Button';
import { STATUS_ERROR } from 'app/shared/constants';
import {
  LEARNING_TYPES,
  DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME,
  COMPLETED_STATUS,
} from 'catalog/constants';
import ButtonLink from 'shared/components/ButtonLink';
import Datetime from 'shared/components/Datetime';
import Pill from 'shared/components/Pill';
import DropTrackItemWarnModal from 'stand-alone-shared/components/DropTrackItemWarnModal';
import { useStandaloneActions } from 'stand-alone-shared/hooks';
import { keys, get } from 'vendor/lodash';
import { BookmarkBorderOutlined, BookmarkOutlined } from 'vendor/mui-icons';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const DateContainer = styled.div`
  flex: 1 0 auto;

  line-height: 18px;
`;

const SecondaryActionButton = ({ content, contentType }) => {
  const [showDropTrackItemWarnModal, setShowDropTrackItemWarnModal] = useState(false);
  const { assign, unassign, undoCompletion, status, error } = useStandaloneActions(
    content,
    contentType
  );

  useEffect(() => {
    if (
      status === STATUS_ERROR &&
      get(error, 'exception') === DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME
    )
      setShowDropTrackItemWarnModal(true);
  }, [status]);

  const isAssigned = Boolean(get(content, 'assignment.id'));
  const isCompleted = get(content, 'assignment.completion_status') === COMPLETED_STATUS;
  const completionDate = get(content, 'assignment.completed_datetime');
  const dueDate = get(content, 'assignment.due_date');

  if (
    contentType === LEARNING_TYPES.events ||
    contentType === LEARNING_TYPES.tracks ||
    ((contentType === LEARNING_TYPES.articles ||
      contentType === LEARNING_TYPES.videos ||
      contentType === LEARNING_TYPES.programs) &&
      !content.is_completable)
  )
    return null;

  if (isCompleted) {
    const formattedCompletionDate = Datetime.Date({ datetime: completionDate });
    return (
      <Container>
        <ButtonLink onClick={undoCompletion} variant="error">
          Undo
        </ButtonLink>
        <DateContainer>
          <Pill
            icon="checkbox-round"
            label={formattedCompletionDate}
            variant="success200"
            size="medium"
          />
        </DateContainer>
      </Container>
    );
  }

  if (isAssigned && !dueDate) {
    return (
      <>
        <Container>
          <Button onClick={unassign} startIcon={<BookmarkOutlined />}>
            Unsave
          </Button>
        </Container>

        {showDropTrackItemWarnModal && (
          <DropTrackItemWarnModal
            handleClose={() => setShowDropTrackItemWarnModal(false)}
            unassign={unassign}
            content={content}
            tracks={get(error, 'tracks')}
          />
        )}
      </>
    );
  }

  if (isAssigned && dueDate) {
    const formattedDueDate = Datetime.Date({ datetime: dueDate });
    if (moment().isAfter(moment(dueDate).endOf('day'))) {
      return (
        <Container>
          <DateContainer>
            <Pill icon="exclamation" label={formattedDueDate} variant="error200" size="medium" />
          </DateContainer>
        </Container>
      );
    }
    return (
      <Container>
        <DateContainer>
          <Pill icon="clock-filled" label={formattedDueDate} variant="neutral200" size="medium" />
        </DateContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Button onClick={() => assign()} startIcon={<BookmarkBorderOutlined />}>
        Save
      </Button>
    </Container>
  );
};

SecondaryActionButton.propTypes = {
  content: PropTypes.object.isRequired,
  contentType: PropTypes.oneOf(keys(LEARNING_TYPES)).isRequired,
};

export default SecondaryActionButton;
