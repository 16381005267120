import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import TabbedContent from 'shared/components/TabbedContent';
import { useCurrentUser, useToggles } from 'shared/hooks';
import { CREATE_MENTORSHIP_SESSION_PERMISSION } from 'shared/permissions';
import { isEmpty, get, includes, size } from 'vendor/lodash';

import EventsTab from './EventsTab';
import SessionsTab from './SessionsTab';

export const UserTabs = (props) => {
  const {
    events,
    pastEvents,
    sessions,
    profileData,
    userTimezone,
    singleCreatorTitle,
    multipleCreatorsTitle,
    sessionsTabTitle,
    onEnrollmentDelete,
  } = props;
  const tabs = [];
  const isMentor = get(profileData, 'is_mentor');
  const hasMentorProfile = get(profileData, 'has_mentor_profile');
  const hasMenteeProfile = get(profileData, 'has_mentee_profile');

  const currentUser = useCurrentUser();

  const {
    toggle_mentor_preferences: toggleMentorPreferences,
    toggle_mentee_preferences: toggleMenteePreferences,
    toggle_mentee_stats: toggleMenteeStats,
    toggle_mentor_stats: toggleMentorStats,
  } = useToggles();

  // Per discussion the is_mentor and has_mentor_profile attributes are sorta duplicates not necessarily synced
  // Currently we show mentor tab even if the global toggle is off if the person has is_mentor set
  const showMentorSection = toggleMentorPreferences ? isMentor || hasMentorProfile : isMentor;

  const showMenteePreferenceSection =
    toggleMenteePreferences &&
    currentUser.id !== profileData.id &&
    hasMenteeProfile &&
    includes(currentUser.permissions, CREATE_MENTORSHIP_SESSION_PERMISSION);

  const shouldShowMentorStatsSection = isMentor && toggleMentorPreferences && toggleMentorStats;
  const shouldShowMenteeStatsSection = toggleMenteePreferences && toggleMenteeStats;

  const shouldShowSessionsTab =
    showMentorSection ||
    showMenteePreferenceSection ||
    shouldShowMentorStatsSection ||
    shouldShowMenteeStatsSection;

  if (!isEmpty(events) || !isEmpty(pastEvents)) {
    tabs.push({
      text: 'Events',
      content: (
        <EventsTab
          userId={profileData.id}
          events={events}
          pastEvents={pastEvents}
          userName={profileData.name}
          userTimezone={userTimezone}
          singleCreatorTitle={singleCreatorTitle}
          multipleCreatorsTitle={multipleCreatorsTitle}
        />
      ),
    });
  }
  if (shouldShowSessionsTab) {
    tabs.push({
      text: sessionsTabTitle,
      content: (
        <SessionsTab
          sessions={sessions}
          profileData={profileData}
          onEnrollmentDelete={onEnrollmentDelete}
          showMentorSection={showMentorSection}
          showMenteePreferenceSection={showMenteePreferenceSection}
          shouldShowMentorStatsSection={shouldShowMentorStatsSection}
          shouldShowMenteeStatsSection={shouldShowMenteeStatsSection}
        />
      ),
    });
  }

  if (isEmpty(tabs)) {
    return null;
  }

  return <TabbedContent tabs={tabs} forceSelectedTab={size(tabs) - 1} />;
};

UserTabs.propTypes = {
  events: PropTypes.array,
  pastEvents: PropTypes.array,
  sessions: PropTypes.array,
  profileData: PropTypes.object,
  userTimezone: PropTypes.string,
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
  sessionsTabTitle: PropTypes.string,
  onEnrollmentDelete: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userTimezone: state.user.currentUser.timezone,
  singleCreatorTitle: state.user.currentUser.labels.label_events_single_creator_title,
  multipleCreatorsTitle: state.user.currentUser.labels.label_events_multiple_creators_title,
  sessionsTabTitle: state.user.currentUser.labels.label_user_profile_sessions_title,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserTabs);
