import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mapRoute } from 'services/requests';
import { useToggles } from 'shared/hooks';
import { isEmpty } from 'vendor/lodash';

import BasePersonCard from './BasePersonCard';

const CardContainer = styled.div`
  ${({ hasMargins }) => hasMargins && 'margin: 12px 10px;'};
`;

const MentorCard = ({ mentor, disableAllClicks, hasMargins, scheduleComponent, cardProps }) => {
  const { toggle_user_badges: toggleUserBadges } = useToggles();

  const { id } = mentor;

  const userProfileUrl = mapRoute('userProfile', { id });
  const displayUserBadges = !isEmpty(mentor.badges) && toggleUserBadges;

  return (
    <CardContainer hasMargins={hasMargins}>
      <BasePersonCard
        user={mentor}
        availableSessionsCount={mentor.free_hosting_sessions_count}
        badge={displayUserBadges && !isEmpty(mentor.badges) ? mentor.badges[0].image : null}
        isMentor
        route={userProfileUrl}
        disableAllClicks={disableAllClicks}
        nextSession={mentor.next_session_date}
        cardProps={cardProps}
      />
      {scheduleComponent}
    </CardContainer>
  );
};

MentorCard.propTypes = {
  mentor: PropTypes.shape({
    id: PropTypes.number,
    free_hosting_sessions_count: PropTypes.number,
    badges: PropTypes.array,
    name: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.object,
    skills: PropTypes.array,
    next_session_date: PropTypes.string,
  }),
  disableAllClicks: PropTypes.bool,
  hasMargins: PropTypes.bool,
  scheduleComponent: PropTypes.node,
  cardProps: PropTypes.object,
};

export { MentorCard };
