import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useEntities } from 'app/entities/utils';
import { FACILITATOR_ROLES } from 'catalog/constants';
import actions from 'entities/actions';
import { sessionEnrollmentSchema } from 'entities/schema';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import Text from 'shared/components/Text';
import { CAN_SEE_SESSIONS_ENROLLMENTS } from 'shared/permissions';
import { map, filter, includes } from 'vendor/lodash';

const ProgramPeopleContainer = ({ content, contentType }) => {
  const [fetchSessionEnrollments, { data: sessionEnrollments }] = useEntities(
    actions.sessionEnrollment.retrieveList,
    null,
    {
      schema: [sessionEnrollmentSchema],
    }
  );

  const totalPeopleGoing = content.denormalized_engaged_users_count || 0;

  useEffect(() => {
    if (!includes(content.permissions, CAN_SEE_SESSIONS_ENROLLMENTS)) return;
    fetchSessionEnrollments({
      page_size: 16,
      session_program: content.public_id,
      unique_by_attendee: true,
      view_mode: 'program_page',
    });
  }, []);

  const getContentOrganizers = () => {
    const organizers = filter(content.facilitators, (facilitator) =>
      includes(
        [
          FACILITATOR_ROLES.main_organizer,
          FACILITATOR_ROLES.co_organizer,
          FACILITATOR_ROLES.program_manager,
        ],
        facilitator.role
      )
    );
    return map(organizers, (organizer) => organizer.user);
  };

  const getContentMaintainers = () => {
    const maintainers = filter(content.facilitators, (facilitator) =>
      includes(
        [
          FACILITATOR_ROLES.maintainer,
          FACILITATOR_ROLES.co_maintainer,
          FACILITATOR_ROLES.author,
          FACILITATOR_ROLES.co_author,
          FACILITATOR_ROLES.main_host,
          FACILITATOR_ROLES.co_host,
          FACILITATOR_ROLES.presenter,
        ],
        facilitator.role
      )
    );

    return map(maintainers, (maintainer) => maintainer.user);
  };

  const organizers = getContentOrganizers();
  const maintainers = getContentMaintainers();
  const engagedPeople = map(sessionEnrollments, (enrollment) => enrollment.attendee);

  return (
    <BasePeopleContainer
      contentType={contentType}
      organizers={organizers}
      maintainers={maintainers}
      engagedPeople={engagedPeople}
      engagedPeopleCount={totalPeopleGoing}
      engagedPeopleHeading={
        <Text>
          {totalPeopleGoing} {totalPeopleGoing > 1 ? 'people' : 'person'} engaged
        </Text>
      }
    />
  );
};

ProgramPeopleContainer.propTypes = { content: PropTypes.object, contentType: PropTypes.string };

export default ProgramPeopleContainer;
