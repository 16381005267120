import styled from 'styled-components';

import BackgroundPanel from 'shared/components/BackgroundPanel';

const ContentItemBackgroundPanel = styled(BackgroundPanel)<{ $isTrackItem?: boolean }>`
  left: 0px;
  height: 120px;
  ${({ $isTrackItem }) => $isTrackItem && 'top: -20px;'}
`;

export default ContentItemBackgroundPanel;
