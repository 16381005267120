import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const ModalBodyContainer = styled.div`
  padding: 16px;
`;

const IntegrationDisableModal = ({ integrationName, handleDisable, handleClose }) => {
  return (
    <Modal
      title={`Disconnect ${integrationName} Integration`}
      handleClose={handleClose}
      width={window.innerWidth > 600 ? 600 : null}
      overflow="hidden"
    >
      <ModalBody fullSize scrollable>
        <ModalBodyContainer>
          <Text size="h5" block>
            By disconnecting this integration, you&#39;ll be revoking PlusPlus&#39; access token and the PlusPlus app will be removed from your Slack workspace.
          </Text>
        </ModalBodyContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton type="button" color="secondary" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="button" onClick={handleDisable} color="error">
          Disconnect {integrationName}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

IntegrationDisableModal.propTypes = {
  integrationName: PropTypes.string,
  handleDisable: PropTypes.func,
  handleClose: PropTypes.func,
};

export default IntegrationDisableModal;
