import { CONTENT_TYPES } from 'app/catalog/constants';

export const TRACK_TYPES = [CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track];

export const getContentRouteBasedOnType = (type) => {
  switch (type) {
    case CONTENT_TYPES.article:
      return 'standAloneArticleDetail';
    case CONTENT_TYPES.codelab:
      return 'standAloneCodelabDetail';
    case CONTENT_TYPES.course:
      return 'standAloneCourseDetail';
    case CONTENT_TYPES.eventtype:
      return 'eventTypeDetails';
    case CONTENT_TYPES.video:
      return 'standAloneVideoDetail';
    case CONTENT_TYPES.track:
      return 'trackDetails';
    case CONTENT_TYPES.scheduled_track:
      return 'scheduledTrackDetails';
    case CONTENT_TYPES.linkedcontent:
      return 'linkedContentDetails';
    case CONTENT_TYPES.assessment:
      return 'assessmentDetails';
    // tasks do not have a dedicated details page
    default:
      return '';
  }
};

export const CONTENT_TYPES_WITH_PROGRESS = [
  CONTENT_TYPES.assessment,
  CONTENT_TYPES.codelab,
  CONTENT_TYPES.course,
  CONTENT_TYPES.scheduled_track,
  CONTENT_TYPES.track,
  CONTENT_TYPES.video,
];
