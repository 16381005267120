import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { sessionStartsAtHasPassed } from 'program/services';
import colors from 'services/colors';
import UserAvatar from 'shared/components/UserAvatar';
import { useCurrentUser } from 'shared/hooks';

import UnenrollButton from './UnenrollButton';

const ListItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  background: ${colors.neutral0};
  border-bottom: 1px solid ${colors.neutral100};
  padding: 12px 20px;
  position: relative;
  clear: both;
  overflow: hidden;

  &:last-child {
    overflow: hidden;
    border-bottom: none;
  }
`;

const ListUserContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: calc(100% - 228.5px);
`;

const StyledUserAvatar = styled(UserAvatar)`
  float: left;
  margin-right: 8px;
`;

const ListUserTexts = styled.div`
  display: inline-block;
  padding: 6px 0;
  max-width: 100%;
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const ListUserName = styled.span`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.neutral600};

  span {
    color: ${colors.neutral900};
  }
`;

const UserInfo = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.67;
  color: ${colors.neutral600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;
`;

const UserEmail = styled.span`
  display: inline-block;
  overflow: hidden;
`;

const ActionButtons = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

export const ListItem = ({ session, enrollment, refreshEnrollments }) => {
  const { attendee } = enrollment;

  const currentUser = useCurrentUser();
  const shouldDisplayActionButtons = !sessionStartsAtHasPassed(session, currentUser.timezone);

  return (
    <ListItemContainer>
      <ListUserContainer>
        <StyledUserAvatar size="medium" user={attendee} showPopover sendEmailCTA />
        <ListUserTexts>
          <ListUserName>{attendee.name}</ListUserName>
          <UserInfo alt={attendee.email}>
            <UserEmail>
              <a href={`mailto:${attendee.email}`} target="_blank" rel="noopener noreferrer">
                {attendee.email}
              </a>
            </UserEmail>
          </UserInfo>
        </ListUserTexts>
      </ListUserContainer>
      {shouldDisplayActionButtons && (
        <ActionButtons>
          <UnenrollButton
            attendee={attendee}
            sessionId={session.id}
            enrollment={enrollment}
            refreshEnrollments={refreshEnrollments}
          />
        </ActionButtons>
      )}
    </ListItemContainer>
  );
};

ListItem.propTypes = {
  enrollment: PropTypes.object,
  session: PropTypes.object,
  refreshEnrollments: PropTypes.func,
};

export default ListItem;
