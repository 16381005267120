import React, { useContext } from 'react';

import { useCurrentUser } from 'app/shared/hooks';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Pill from 'shared/components/Pill';
import { ADMIN_ROLE_KEY } from 'shared/constants';
import { map } from 'vendor/lodash';
import { Avatar, Box, Stack, Typography, Divider, grey, Link } from 'vendor/mui';

import { ProfileDataContext } from '../../types';

import { TestimonialsMenu } from './TestimonialsMenu';
import { ITestimonial } from './types';

export interface TestimonialsQuickListProps {
  testimonials: ITestimonial[];
  Appendix: React.FC;
}

interface QuickTestimonialItemProps {
  testimonial: ITestimonial;
  Appendix: React.FC;
  showMenu: boolean;
}

const QuickTestimonialItem = ({
  testimonial: { id, sender, text, is_hidden: isHidden },
  Appendix,
  showMenu,
}: QuickTestimonialItemProps) => {
  const props = isHidden ? { color: colors.neutral400 } : {};
  const route = mapRoute('userProfile', { id: id });

  return (
    <Stack direction="row" spacing={1.5} data-testid={`testimonials_quick_item_${id}`}>
      <Link href={route} underline="none">
        <Avatar src={sender.profile_image} alt={sender.name} imgGrayscale={isHidden} />
      </Link>
      <Box sx={{ width: '100%', maxHeight: 104 }}>
        <Box display="flex">
          {isHidden && (
            <>
              <Box paddingTop="2px">
                <Pill label="Hidden" size="small" data-testid="testimonials_quick_hidden_pill" />
              </Box>
              &nbsp;
            </>
          )}
          <Typography variant="body1" {...props}>
            <Link variant="h6" href={route} underline="hover" color={grey['900']}>
              {sender.name}
            </Link>
          </Typography>
        </Box>
        <Typography variant="body2" noWrap maxLines={3} {...props}>
          {text}
        </Typography>
        <Appendix />
      </Box>
      {showMenu && (
        <Box height="16px">
          <TestimonialsMenu testimonialId={id} isHidden={isHidden} />
        </Box>
      )}
    </Stack>
  );
};

export const TestimonialsQuickList = ({ testimonials, Appendix }: TestimonialsQuickListProps) => {
  const { id: receiverId } = useContext(ProfileDataContext);
  const { id: viewerId, role: viewerRole } = useCurrentUser();
  const showMenu = viewerRole === ADMIN_ROLE_KEY || receiverId === viewerId;

  const testimonialsSlice = testimonials.slice(0, 3);

  return (
    <Stack spacing={1} divider={<Divider flexItem />}>
      {map(testimonialsSlice, (testimonial) => {
        const { id } = testimonial;
        return (
          <QuickTestimonialItem
            key={id}
            testimonial={testimonial}
            Appendix={Appendix}
            showMenu={showMenu}
          />
        );
      })}
    </Stack>
  );
};
