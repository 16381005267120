import React, { useEffect } from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { sessionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_DONE } from 'shared/constants';
import {
  useCurrentUser,
  useLabels,
  useOriginRoute,
  useQueryParams,
  useSetDetailedObject,
} from 'shared/hooks';
import { isEmpty, toLower } from 'vendor/lodash';

import SessionProgramFormPage from './SessionProgramFormPage';

const SessionProgramCreate = () => {
  const currentUser = useCurrentUser();
  const { cloned: duplicatedSessionId } = useQueryParams();
  const { label_program: labelProgram } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.programs
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  const [fetchSessionToDuplicate, { data: duplicatedSession, status: duplicatedSessionStatus }] =
    useEntities(
      actions.sessionProgram.retrieveDetails,
      ({ statusCode }) => {
        if (statusCode === 403) {
          permissionDeniedRedirectAlert();
        }
      },
      {
        schema: sessionSchema,
      }
    );

  const [fetchLastSession, { data: lastSession, status: lastSessionStatus }] = useEntities(
    actions.sessionProgram.retrieveList,
    null,
    {
      schema: [sessionSchema],
    }
  );

  useEffect(() => {
    if (duplicatedSessionId) {
      fetchSessionToDuplicate(duplicatedSessionId);
      return;
    }

    fetchLastSession({
      o: '-created',
      created_by: currentUser.id,
      page_size: 1,
    });
  }, [duplicatedSessionId]);

  useSetDetailedObject({ id: null }, 'programs', []);

  const isFetchingDuplicatedSession =
    duplicatedSessionId && duplicatedSessionStatus !== STATUS_DONE;
  const isFetchingLastSession = !duplicatedSessionId && lastSessionStatus !== STATUS_DONE;

  if (isFetchingDuplicatedSession || isFetchingLastSession) return <Loading />;

  const initialValuesParams = {};
  if (duplicatedSessionId) initialValuesParams.duplicatedSession = duplicatedSession;
  if (!isEmpty(lastSession)) [initialValuesParams.lastSession] = lastSession;

  return (
    <SessionProgramFormPage
      pageTitle={`${duplicatedSessionId ? 'Duplicate' : 'Create'} ${labelProgram} Session`}
      topBarActionName={`${duplicatedSessionId ? 'Duplicating' : 'Creating'} Session`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: 'Sessions',
          link: catalogRoute,
        },
        {
          label: `${duplicatedSessionId ? 'Duplicate' : 'New'} ${toLower(labelProgram)} session`,
        },
      ]}
    />
  );
};

export default SessionProgramCreate;
