import { schema } from 'normalizr';

export const groupSchema = new schema.Entity('groups');

export const locationSchema = new schema.Entity('locations');

export const userProfileSchema = new schema.Entity('userProfiles', {
  location: locationSchema,
});

export const userSchema = new schema.Entity('users');

export const segmentSchema = new schema.Entity(
  'segments',
  { created_by: userSchema },
  { idAttribute: 'public_id' }
);

export const automatedRuleSchema = new schema.Entity('automatedRules');

export const automatedRuleLogSchema = new schema.Entity('automatedRuleLogs');

export const automatedRuleLogtrailSchema = new schema.Entity('automatedRuleLogtrail');

export const channelSchema = new schema.Entity('channels');

export const eventTypeRequestSchema = new schema.Entity('eventTypeRequests');

export const enrollmentSchema = new schema.Entity('enrollments', {
  user: userSchema,
});

export const eventSchema = new schema.Entity(
  'events',
  {
    presenters: [userSchema],
    enrollment: enrollmentSchema,
    organizer: userSchema,
    co_organizers: [userSchema],
    groups: [groupSchema],
    location: locationSchema,
    channel: channelSchema,
  },
  { idAttribute: 'public_id' }
);

export const roomSchema = new schema.Entity('rooms');

export const topicSchema = new schema.Entity('topics');

export const facilitatorSchema = new schema.Entity('facilitator', {
  user: userSchema,
});

export const contentItemSchema = new schema.Entity('contentItem', {
  facilitators: [facilitatorSchema],
  groups: [groupSchema],
});

export const multipleChoiceOptionSchema = new schema.Entity('multipleChoiceOptions');

export const assignmentSchema = new schema.Entity('assignments', {
  content_item: contentItemSchema,
  user: userSchema,
  created_by: userSchema,
  modified_by: userSchema,
  option: multipleChoiceOptionSchema,
});

export const assignmentProgressSchema = new schema.Entity('assignmentProgress');
export const assignmentCompletionSchema = new schema.Entity('assignmentCompletions');
export const assignmentDropSchema = new schema.Entity('assignmentDrops');
export const assignmentExemptionSchema = new schema.Entity('assignmentExemptions', {
  exempted_by: userSchema,
});
export const assignmentRatingSchema = new schema.Entity('assignmentRatings');

export const eventTypeSchema = new schema.Entity('eventTypes', {
  presenters: [userSchema],
  organizer: userSchema,
  co_organizers: [userSchema],
  groups: [groupSchema],
  location: locationSchema,
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const assignmentEmailsSchema = new schema.Entity('assignmentEmails', {
  user: userSchema,
});

export const articleSchema = new schema.Entity('articles', {
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const taskSchema = new schema.Entity('tasks', {
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

// track_items removed from here to prevent the track item detail page information
// from being overwritten by the track fetch call that retrieves track items with less information
export const trackSchema = new schema.Entity('tracks', {
  facilitators: [facilitatorSchema],
  groups: [groupSchema],
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const scheduledTrackSchema = new schema.Entity('scheduledTracks', {
  facilitators: [facilitatorSchema],
  groups: [groupSchema],
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const assessmentSchema = new schema.Entity('assessments', {
  facilitators: [facilitatorSchema],
  groups: [groupSchema],
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const linkedContentSchema = new schema.Entity('linkedContent', {
  facilitators: [facilitatorSchema],
  groups: [groupSchema],
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const sessionSchema = new schema.Entity('sessions', {
  host: userSchema,
  location: locationSchema,
});

export const sessionEnrollmentSchema = new schema.Entity('sessionEnrollment', {
  attendee: userSchema,
});

export const mentorshipRequestSchema = new schema.Entity('mentorshipRequest', {
  sender: userSchema,
  receiver: userSchema,
});

export const programSchema = new schema.Entity('programs', {
  groups: [groupSchema],
  manager: userSchema,
});

export const mentorshipProgramSchema = new schema.Entity('mentorshipPrograms', {
  groups: [groupSchema],
  manager: userSchema,
});

export const testimonialSchema = new schema.Entity('testimonials', {
  sender: userSchema,
  receiver: userSchema,
});

export const surveySchema = new schema.Entity('surveys');

export const surveyPreviewSchema = new schema.Entity('surveys');

export const userSurveySchema = new schema.Entity('userSurveys');

export const surveyRelationshipSchema = new schema.Entity('surveyRelationships');

export const announcementsSchema = new schema.Entity('announcements');

export const registrationSchema = new schema.Entity('registrations', {
  user: userSchema,
});

export const registrationEmailsSchema = new schema.Entity('registrationEmails', {
  user: userSchema,
});

export const codelabSchema = new schema.Entity('codelabs', {
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
  facilitators: [facilitatorSchema],
});

export const courseSchema = new schema.Entity('courses', {
  registration: registrationSchema,
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
});

export const contentSchema = new schema.Entity('content', {
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
  facilitators: [facilitatorSchema],
  channel: channelSchema,
});

export const videoSchema = new schema.Entity('videos', {
  assignment: assignmentSchema,
  user_assignment: assignmentSchema,
  facilitators: [facilitatorSchema],
});

export const csvImportSchema = new schema.Entity('csvImports');

export const eventAttendeeEmailListSchema = new schema.Entity('eventAttendeeEmailList');

export const trackItemSchema = new schema.Entity('trackItems');
