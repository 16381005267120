import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { OldIcon } from 'shared/components/Icon';
import { capitalizeFirstLetter } from 'shared/services';

const TopicSelectItem = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 15px;
  margin: 3px 10px 3px 0px;
  background-color: ${colors.neutral200};
  color: ${colors.neutral900};

  &:hover {
    cursor: pointer;
  }
`;

const TopicName = styled.span`
  display: inline-block;
  margin: 0 15px 0 15px;
  line-height: 32px;
  font-size: 15px;
`;

const RemoveButtonSection = styled.div`
  display: inline-block;
  margin: 0 6px 0 -6px;
`;

const RemoveButton = styled.span`
  color: ${colors.neutral900};
  text-align: center;
`;

export class TopicItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleClick() {
    const { id, name, onClick } = this.props;

    if (onClick) {
      onClick(id, name);
    }
  }

  handleRemoveClick() {
    const { id, name, onRemoveClick } = this.props;

    if (onRemoveClick) {
      onRemoveClick(id, name);
    }
  }

  render() {
    const { name, onRemoveClick } = this.props;

    return (
      <TopicSelectItem tabIndex="0" role="button" onClick={this.handleClick}>
        <TopicName>{capitalizeFirstLetter(name)}</TopicName>
        {onRemoveClick ? (
          <RemoveButtonSection>
            <RemoveButton
              type="button"
              tabIndex="-1"
              role="button"
              onClick={this.handleRemoveClick}
            >
              <OldIcon name="window-close" size="16" />
            </RemoveButton>
          </RemoveButtonSection>
        ) : (
          ''
        )}
      </TopicSelectItem>
    );
  }
}

TopicItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onClick: PropTypes.func,
};

export default TopicItem;
