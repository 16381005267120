import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { baseButtonStyle, ButtonContent, ButtonWrapper } from 'shared/components/Button';

const HeaderButtonWrapper = styled(ButtonWrapper)`
  ${baseButtonStyle};
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 0;
  padding-left: 20px;
  border-bottom: 1px solid ${colors.neutral100};
  justify-content: flex-start;

  background-color: ${colors.neutral0};
  color: ${colors.action600};

  svg path {
    fill: ${colors.action600};
  }

  &:hover,
  &:focus {
    background-color: ${colors.action600};
    color: ${colors.neutral0};

    svg path {
      fill: ${colors.neutral0};
    }
  }
`;

const FilterBarHeader = ({ closeSidebar }) => {
  return (
    <header>
      <HeaderButtonWrapper icon="filter" size="large" noBorder onClick={closeSidebar}>
        <ButtonContent icon="filter">Filter</ButtonContent>
      </HeaderButtonWrapper>
    </header>
  );
};

FilterBarHeader.propTypes = {
  closeSidebar: PropTypes.func,
};

export default FilterBarHeader;
