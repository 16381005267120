import { useState } from 'react';
import styled from 'styled-components';

import Dashboard from 'backoffice/components/Dashboard';
import { Timeslot } from 'event/interfaces';
import colors from 'services/colors';
import { map, drop, isEmpty, size, isNil } from 'vendor/lodash';

const ViewMoreButton = styled.div`
  background-color: transparent;
  border: none;
  color: ${colors.emphasis600};
  cursor: pointer;
`;

const TimeslotArea = styled.div`
  color: ${colors.neutral600};
`;

interface ViewMoreProps {
  eventTimezone: string;
  timeslots?: Timeslot[];
}

interface SingleTimeslotProps {
  startsAt?: string;
  endsAt?: string;
  location?: string;
  timezone?: string;
}

const SingleTimeslot = ({ startsAt, endsAt, location, timezone }: SingleTimeslotProps) => {
  return (
    <TimeslotArea>
      <Dashboard.TimeslotCell startsAt={startsAt} endsAt={endsAt} timezone={timezone} />
      <div>{location}</div>
    </TimeslotArea>
  );
};

const ViewMore = ({ eventTimezone, timeslots }: ViewMoreProps) => {
  const [viewMore, setViewMore] = useState(false);

  if (isEmpty(timeslots) || isNil(timeslots)) return null;

  const handleButtonOnClick = () => {
    setViewMore(!viewMore);
  };

  const viewMoreEvents = drop(timeslots);

  return (
    <div>
      <SingleTimeslot
        key={timeslots[0].id}
        startsAt={timeslots[0].starts_at_tz_aware}
        endsAt={timeslots[0].ends_at_tz_aware}
        timezone={eventTimezone}
        location={timeslots[0].rooms_info}
      />
      {viewMore && (
        <div>
          {map(viewMoreEvents, (t) => (
            <SingleTimeslot
              key={t.id}
              startsAt={t.starts_at_tz_aware}
              endsAt={t.ends_at_tz_aware}
              timezone={eventTimezone}
              location={t.rooms_info}
            />
          ))}
        </div>
      )}
      {size(timeslots) > 1 && (
        <ViewMoreButton onClick={handleButtonOnClick}>
          {viewMore ? 'Hide sessions' : `View all ${size(timeslots)} sessions`}
        </ViewMoreButton>
      )}
    </div>
  );
};

export default ViewMore;
