import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { mentorshipRequestSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import {
  MENTORSHIP_REQUEST_STATUS_OPEN,
  MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
} from 'program/constants';
import { useMentorshipRequestActions } from 'program/hooks';
import colors from 'services/colors';
import Banner from 'shared/components/Banner';
import ButtonLink from 'shared/components/ButtonLink';
import Text from 'shared/components/Text';
import { useCurrentUser } from 'shared/hooks';
import { isNil, head } from 'vendor/lodash';

import BadgeIcon from './badge-mentor.svg';

const MainTextContainer = styled.div`
  > * + * {
    margin-left: 4px;
  }
`;

const HelpTextContainer = styled.div`
  > * + * {
    margin-left: 4px;
  }
`;

const MentorshipRequestBanner = ({ profileId }) => {
  const [showBanner, setShowBanner] = useState(true);
  const currentUser = useCurrentUser();

  const [fetchMentorshipRequest, { data: mentorshipRequestList }] = useEntities(
    actions.mentorshipRequest.retrieveList,
    null,
    { schema: [mentorshipRequestSchema] }
  );

  useEffect(() => {
    fetchMentorshipRequest({
      sender: profileId,
      receiver: currentUser.id,
      status: MENTORSHIP_REQUEST_STATUS_OPEN,
      kind: MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR,
    });
  }, []);

  const handleClose = () => setShowBanner(false);

  const mentorshipRequest = head(mentorshipRequestList);

  const { dismiss } = useMentorshipRequestActions(mentorshipRequest, { onChange: handleClose });

  if (isNil(mentorshipRequest) || !showBanner) return null;

  const { sender } = mentorshipRequest;

  return (
    <Banner renderIcon={<BadgeIcon />} handleClose={handleClose}>
      <MainTextContainer>
        <Text medium color={colors.neutral900}>
          {sender.name} wants to mentor you!
        </Text>
        <Text color={colors.neutral900}>Book or request a session to accept.</Text>
      </MainTextContainer>
      <HelpTextContainer>
        <ButtonLink variant="error" onClick={dismiss}>
          Dismiss
        </ButtonLink>
        <Text color={colors.neutral400}>
          the request if you are not interested in becoming their mentee.
        </Text>
      </HelpTextContainer>
    </Banner>
  );
};

MentorshipRequestBanner.propTypes = {
  profileId: PropTypes.number,
};

export default MentorshipRequestBanner;
