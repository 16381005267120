import { Paper } from 'vendor/mui';

interface UserContentBlockProps {
  children: JSX.Element | JSX.Element[] | string | string[];
  sx?: object;
}

const UserContentBlock = ({ children, sx }: UserContentBlockProps) => {
  return (
    <Paper elevation={3} sx={{ marginBottom: 2, paddingY: 2, paddingX: 4, borderRadius: 2, ...sx }}>
      {children}
    </Paper>
  );
};

export default UserContentBlock;
