import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import MediaPoint from 'shared/components/MediaPoint';
import OldSvgIcon from 'shared/components/SvgIcon';
import { noop } from 'vendor/lodash';

const ARROW_WIDTH = 20;
const ARROW_MARGIN = 16;
export const PAGE_ARROW_WIDTH = ARROW_WIDTH + ARROW_MARGIN * 2;

export const ArrowContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${ARROW_WIDTH}px;
  height: 32px;
  margin: 50px ${ARROW_MARGIN}px;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};

  ${MediaPoint.Tablet} {
    margin-top: 66px;
    margin-bottom: 66px;
  }
`;

const IconWrapper = styled.div`
  margin: 0px auto;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 0.5px solid ${colors.neutral200};
`;

const PageArrow = ({ orientation, enabled, handleOnClick, showsOnOfficeHour }) => (
  <ArrowContainer
    onClick={enabled ? handleOnClick : noop}
    enabled={enabled}
    orientation={orientation}
  >
    {showsOnOfficeHour ? (
      <OldSvgIcon.Arrow
        orientation={orientation}
        width={20}
        height={32}
        fill={enabled ? colors.emphasis600 : colors.neutral200}
      />
    ) : (
      <IconWrapper>
        <Icon
          name={orientation}
          color={enabled ? colors.action600 : colors.neutral200}
          width={10}
          height={14}
        />
      </IconWrapper>
    )}
  </ArrowContainer>
);

PageArrow.propTypes = {
  orientation: PropTypes.string,
  enabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  showsOnOfficeHour: PropTypes.bool,
};

PageArrow.defaultProps = {
  showsOnOfficeHour: false,
};

export default PageArrow;
