/* eslint-disable no-plusplus */
import queryString from 'query-string';

import { rqlToInput } from 'app/backoffice/components/Dashboard/Filters/utils';
import { rqlExpressionToObject } from 'backoffice/utils';
import {
  isCatalogAppUrl,
  isCoachingAppUrl,
  isEventsAppUrl,
  isMentorsAppUrl,
  isSnippetsAppUrl,
  isStandAloneAppUrl,
  isPeopleAppUrl,
  isUnifiedCatalogAppUrl,
  isMyUpcomingAppUrl,
  isMyHubAppUrl,
} from 'services/requests';
import {
  filter,
  find,
  forEach,
  get,
  head,
  includes,
  indexOf,
  isArray,
  isEqual,
  keys,
  map,
  max,
  toString,
  size,
  trim,
} from 'vendor/lodash';

const countOccurances = (array, value) =>
  filter(array, (element) => isEqual(element, value)).length;

const calculateDetailedObjectAttributeMatch = (attribute, value) => {
  if (isArray(attribute)) {
    return includes(attribute, value);
  }
  return !attribute && toString(attribute) === toString(value);
};

const checkers = {
  events: isEventsAppUrl,
  catalog: isCatalogAppUrl,
  snippet: isSnippetsAppUrl,
  tracks: isSnippetsAppUrl,
  programs: isCoachingAppUrl,
  programSessions: isCoachingAppUrl,
  mentorship: isMentorsAppUrl,
  people: isPeopleAppUrl,
  unified_catalog: isUnifiedCatalogAppUrl,
  articles: isStandAloneAppUrl,
  my_upcoming: isMyUpcomingAppUrl,
  my_hub: isMyHubAppUrl,
};

export const getBestMatchURL = ({
  lastLocationQuerystring,
  currentLocationUrl,
  currentLocationQuerystring,
  tabConfig,
  detailedObject,
  detailedObjectType,
  usesDetailedObject,
  currentSearch,
}) => {
  const getFilter = (rqlObject, name) =>
    rqlToInput(
      find(get(rqlObject, '$and'), (value) => head(keys(value) === name)) || get(rqlObject, name)
    );

  const tabsScore = map(tabConfig, (tab) => {
    if (tab.module === 'external_url') {
      return 0;
    }
    const checker = checkers[tab.module];
    const checkerScore = checker(currentLocationUrl) ? 1 : 0;
    const tabQuerystring = queryString.parse(get(tab, 'querystring', ''));
    const tabRqlObject = rqlExpressionToObject(get(tab, 'querystring', ''));
    const learningTypeFilter = getFilter(tabRqlObject, 'type');
    const fixedFilter = getFilter(tabRqlObject, 'name');

    if (usesDetailedObject && !detailedObject) {
      return 0;
    }

    if (!checkerScore) return checkerScore;

    const id = get(tab, 'id', '');
    let idScore = 0;
    if (id) {
      idScore = includes(currentLocationUrl, `/${id}/`) ? 1 : 0;
    }

    // We want to favor old tabs instead of unified catalog if they're available as they're more specific.
    let oldTabScore = 0;
    if (detailedObjectType && includes(tab.module, detailedObjectType)) {
      oldTabScore++;
      oldTabScore++;
    }

    let detailedObjectScore = 0;
    let querystringScore = 0;
    if (tab.module === 'unified_catalog') {
      if (
        get(tab, 'querystring') === trim(currentSearch, '?') &&
        size(get(tab, 'querystring', '')) > 0
      ) {
        querystringScore++;
      }
      // Favors unified catalog generic tab if there's no querystring present.
      if (!tabQuerystring.learning_types && size(learningTypeFilter) === 0) {
        detailedObjectScore++;
      } else if (
        // Favors specific unified catalog tab (learning_type) when the querystring is available.
        detailedObjectType &&
        (includes(tabQuerystring.learning_types, detailedObjectType) ||
          includes(learningTypeFilter, detailedObjectType))
      ) {
        if (
          (includes(keys(tabQuerystring), 'learning_types') &&
            includes(keys(tabQuerystring), 'fixed') &&
            size(keys(tabQuerystring)) === 2) ||
          (size(learningTypeFilter) > 0 && size(fixedFilter) > 0 && size(keys(tabRqlObject)) <= 3)
        ) {
          detailedObjectScore += 2;
        } else {
          let hasAttributeMatch = false;
          forEach(tabQuerystring, (value, key) => {
            if (isArray(value)) {
              const matchingItems = filter(value, (item) =>
                calculateDetailedObjectAttributeMatch(get(detailedObject, key), [item])
              );
              if (matchingItems.length > 0) {
                detailedObjectScore += matchingItems.length;
                hasAttributeMatch = true;
              }
            } else if (calculateDetailedObjectAttributeMatch(get(detailedObject, key), value)) {
              detailedObjectScore++;
              hasAttributeMatch = true;
            }
          });

          if (hasAttributeMatch) {
            // if there's at least one match
            detailedObjectScore += 2;
          }
        }
      } else if (tabQuerystring) {
        forEach(currentLocationQuerystring, (value, key) => {
          if (get(tabQuerystring, key) === value) {
            querystringScore++;
          }
        });
      }
    } else if (tabQuerystring) {
      forEach(currentLocationQuerystring, (value, key) => {
        if (get(tabQuerystring, key) === value) {
          querystringScore++;
        }
      });
    }

    let lastLocationQuerystringScore = 0;
    if (tabQuerystring && lastLocationQuerystring) {
      forEach(lastLocationQuerystring, (value, key) => {
        if (get(tabQuerystring, key) === value) {
          lastLocationQuerystringScore++;
        }
      });
    }

    return (
      checkerScore +
      idScore +
      lastLocationQuerystringScore +
      querystringScore +
      detailedObjectScore +
      oldTabScore
    );
  });

  const maxScore = max(tabsScore);
  const hasScoreTie = countOccurances(tabsScore, maxScore) > 1;

  return hasScoreTie ? false : indexOf(tabsScore, maxScore);
};
