import { CONTENT_TYPES } from 'app/catalog/constants';
import { useLabels } from 'app/shared/hooks';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';

export type LabelInstance = {
  singular: string;
  plural: string;
};

const DEFAULT_STEP_LABELS: LabelInstance = { singular: 'Step', plural: 'Steps' };

export function useTrackStepLabels() {
  const { rootTrack } = useComposableTrackCtx();
  const isAssessment = rootTrack.content_type === CONTENT_TYPES.assessment;

  const { label_question: labelQuestionSingular, label_question_plural: labelQuestionPlural } =
    useLabels();

  const assessmentStepLabels: LabelInstance = {
    singular: labelQuestionSingular,
    plural: labelQuestionPlural,
  };

  return isAssessment ? assessmentStepLabels : DEFAULT_STEP_LABELS;
}
