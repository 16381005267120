import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FetchDataProps, useFetchDataListPaginated } from 'app/data-fetching/hooks';
import { useRQLRouteFilters } from 'backoffice/hooks';
import { EventSubscription, useRQLFiltersContext } from 'rosters/RQLFiltersContext';
import Button from 'shared/components/Button';
import DynamicFilterBar from 'shared/components/DynamicFilterBar';
import {
  DynamicFilter,
  DynamicFilterBackend,
  RosterAction,
  RQLFilter,
} from 'shared/components/types';
import {
  map,
  get,
  isEmpty,
  includes,
  size,
  isNil,
  some,
  startsWith,
  trimStart,
  isArray,
  slice,
  difference,
  sortBy,
  reduce,
  replace,
  constant,
  noop,
} from 'vendor/lodash';
import {
  DataGrid,
  GridToolbarContainer,
  GridSortModel,
  GridSortItem,
  GridFooterContainer,
  TablePagination,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  Grid,
  GridColumns,
  GridCellParams,
  GridRowParams,
  Radio,
  GridCellEditStopReasons,
  Typography,
  GridPinnedColumns,
  GridColumnVisibilityModel,
  Tooltip,
  Box,
} from 'vendor/mui';
import pluralize from 'vendor/pluralize';

import { TableSelectionMode } from '../constants';
import Loading from '../Loading';
import SplitButton from '../SplitButton';

interface Content {
  id: string;
}

interface ToolbarProps {
  dynamicFilters: DynamicFilter[];
  filters: RQLFilter[];
  addFilter: (filter: RQLFilter) => void;
  removeFilter: (index: number) => void;
  updateFilter: (index: number, filter: RQLFilter) => void;
  updateFilters: (filters: RQLFilter[]) => void;
  filterBarInputWidth?: string;
  topBarActions?: (RosterAction | RosterAction[])[];
  selectedItems: object[];
  expression: string;
  rowCount: number;
  rows: object[];
  enableSelectAll: boolean;
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
  cacheKey?: string;
}

interface PaginationProps {
  pageSize: number;
  rowCount: number;
  rowsPerPageOptions: number[];
}

interface FooterProps extends PaginationProps {
  actions: RosterAction[];
  selectedItems: object[];
  expression: string;
  rows: object[];
  selectAll: boolean;
}

interface QueryDynamicTableProps {
  query: (filters) => FetchDataProps;
  queryOptions?: string;
  dynamicFilters: DynamicFilter[];
  defaultOrdering: string;
  columns: GridColumns;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  actions?: RosterAction[];
  topBarActions?: (RosterAction | RosterAction[])[];
  backend?: DynamicFilterBackend;
  filterBarInputWidth?: string;
  disabledItemsIds?: string[] | number[];
  initialFiltersConfig?: Record<string, any>[];
  isCellEditable?: (params: GridCellParams) => boolean;
  processRowUpdate?: (
    newRow: Record<string, any>,
    oldRow: Record<string, any>
  ) => Promise<Record<string, any>> | Record<string, any>; // Using any here, because it is the row
  onProcessRowUpdateError?: (error: any) => void; // This is the same type of the DataGridProps
  disableColumnMenu?: boolean;
  enableSelectAll?: boolean;
  selectionMode?: TableSelectionMode;
  cacheKey?: string;
  pinnedColumns?: GridPinnedColumns;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

interface ActionButtonProps {
  action: RosterAction | RosterAction[];
  index: number;
  selectedItems: object[];
  expression: string;
  rows: object[];
  rowCount: number;
  selectAll: boolean;
}

const ActionButton = ({
  action,
  index,
  selectedItems,
  expression,
  rows,
  rowCount,
  selectAll,
}: ActionButtonProps) => {
  if (isArray(action)) {
    const firstAction = get(action, 0);
    const genTooltipTitle = firstAction.tooltip ? firstAction.tooltip : constant('');
    const TooltipWrap = firstAction.tooltip ? Tooltip : Box;
    return (
      <TooltipWrap
        title={genTooltipTitle({ selectedItems, expression, rows, rowCount, selectAll })}
      >
        <span>
          <SplitButton
            label={firstAction.label}
            color={firstAction.color}
            variant={firstAction.variant}
            startIcon={firstAction.startIcon}
            onClick={() =>
              firstAction.callback({ selectedItems, expression, rows, rowCount, selectAll })
            }
            onClickExpand={firstAction.onClickExpand}
            disabled={firstAction.isDisabled?.({
              selectedItems,
              expression,
              rows,
              rowCount,
              selectAll,
            })}
            sx={firstAction.sx}
            key={`topbar-action-${index}-0`}
            secondaryActionOptionList={map(slice(action, 1), (item, sIndex) => ({
              label: item.label,
              color: item.color,
              variant: item.variant,
              startIcon: item.startIcon,
              onClick: () =>
                item.callback({ selectedItems, expression, rows, rowCount, selectAll }),
              disabled: item.isDisabled?.({ selectedItems, expression, rows, rowCount, selectAll }),
              sx: item.sx,
              key: `topbar-action-${index}-${sIndex + 1}`,
            }))}
          />
        </span>
      </TooltipWrap>
    );
  }

  const genTooltipTitle = action.tooltip ? action.tooltip : constant('');
  const TooltipWrap = action.tooltip ? Tooltip : React.Fragment;
  return (
    <TooltipWrap title={genTooltipTitle({ selectedItems, expression, rows, rowCount, selectAll })}>
      {/*
        By default disabled elements like <button> do not trigger user interactions so a Tooltip will not activate on normal events like hover.
        To accommodate disabled elements, it is necessary to add a simple wrapper element, such as a span.
        https://mui.com/material-ui/react-tooltip/#disabled-elements
      */}
      <span>
        <Button
          color={action.color}
          variant={action.variant}
          startIcon={action.startIcon}
          onClick={() => action.callback({ selectedItems, expression, rows, rowCount, selectAll })}
          disabled={action.isDisabled?.({ selectedItems, expression, rows, rowCount, selectAll })}
          sx={action.sx}
          key={`topbar-action-${index}`}
        >
          {action.label}
        </Button>
      </span>
    </TooltipWrap>
  );
};

const Toolbar = ({
  dynamicFilters,
  filters,
  addFilter,
  removeFilter,
  updateFilter,
  updateFilters,
  filterBarInputWidth,
  topBarActions,
  selectedItems,
  expression,
  rowCount,
  rows,
  enableSelectAll,
  selectAll,
  setSelectAll,
  cacheKey,
}: ToolbarProps) => {
  const hasActions = !isEmpty(topBarActions);
  const justifyContent =
    enableSelectAll && hasActions ? 'space-between' : hasActions ? 'right' : 'left';
  const totalLabel = pluralize('item', rowCount);
  const selectedLabel = pluralize('item', selectAll ? rowCount : size(selectedItems));
  pluralize.addIrregularRule('Select', 'Select all');
  return (
    <>
      <GridToolbarContainer>
        <DynamicFilterBar
          contentType="event"
          filters={filters}
          dynamicFilters={dynamicFilters}
          addFilter={addFilter}
          removeFilter={removeFilter}
          updateFilter={updateFilter}
          updateFilters={updateFilters}
          enableSegments={false}
          inputWidth={filterBarInputWidth}
          cacheKey={cacheKey}
        />
      </GridToolbarContainer>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '8px',
          justifyContent: justifyContent,
        }}
      >
        {enableSelectAll && (
          <Grid
            sx={{ display: 'flex', flexDirection: 'row', columnGap: '8px', alignItems: 'center' }}
          >
            <Typography variant="body2">
              Showing {rowCount} {totalLabel}
            </Typography>
            {(size(selectedItems) > 0 || selectAll) && (
              <Typography variant="body2">
                {' '}
                – {selectAll ? rowCount : size(selectedItems)} {selectedLabel} selected
              </Typography>
            )}
            {size(selectedItems) > 0 && !selectAll && (
              <Button variant="text" onClick={() => setSelectAll(true)}>
                {pluralize('Select', rowCount)} {rowCount} {totalLabel}
              </Button>
            )}
            {selectAll && (
              <Button variant="text" onClick={() => setSelectAll(false)}>
                Clear selection
              </Button>
            )}
          </Grid>
        )}
        {topBarActions && (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '16px',
              justifyContent: 'right',
            }}
          >
            {map(topBarActions, (action, index) => (
              <ActionButton
                action={action}
                index={index}
                selectedItems={selectedItems}
                expression={expression}
                rowCount={rowCount}
                rows={rows}
                selectAll={selectAll}
                key={`topbar-action-button-${index}`}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const Pagination = ({ pageSize, rowCount, rowsPerPageOptions }: PaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);

  return (
    <TablePagination
      component="div"
      color="primary"
      count={rowCount}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        apiRef.current.setPageSize(get(event, 'target.value', pageSize))
      }
      onPageChange={(_event, value) => apiRef.current.setPage(value ?? 0)}
    />
  );
};

const Footer = ({
  pageSize,
  rowCount,
  rowsPerPageOptions,
  actions,
  selectedItems,
  expression,
  rows,
  selectAll,
}: FooterProps) => {
  return (
    <>
      <GridFooterContainer>
        <div />
        <Pagination
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </GridFooterContainer>
      {actions && (
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', columnGap: '8px', justifyContent: 'right' }}
        >
          {map(actions, (action, index) => (
            <Button
              color={action.color}
              variant={action.variant}
              startIcon={action.startIcon}
              onClick={() =>
                action.callback({ selectedItems, expression, rows, rowCount, selectAll })
              }
              disabled={action.isDisabled?.({
                selectedItems,
                expression,
                rows,
                rowCount,
                selectAll,
              })}
              key={`footer-action-${index}`}
            >
              {action.label}
            </Button>
          ))}
        </Grid>
      )}
    </>
  );
};

const getDefaultSortModel = (defaultOrdering: string): GridSortModel => {
  const item: GridSortItem = {
    field: trimStart(defaultOrdering, '-'),
    sort: startsWith(defaultOrdering, '-') ? 'desc' : 'asc',
  };
  return [item];
};

// Remove pagination and ordering
const clearExpression = (expression: string) =>
  replace(
    replace(replace(expression, /(&?)page=(\d*)/g, ''), /(&?)page_size=(\d*)/g, ''),
    /(&?)ordering\(((-?)\w+)\)/,
    ''
  );

const QueryDynamicTable = ({
  query,
  queryOptions = '',
  dynamicFilters,
  defaultOrdering,
  columns: initialColumns,
  pageSize = 5,
  rowsPerPageOptions = [5, 15, 25, 50],
  actions = [],
  topBarActions = [],
  backend = 'state',
  filterBarInputWidth,
  disabledItemsIds,
  initialFiltersConfig,
  isCellEditable,
  processRowUpdate,
  onProcessRowUpdateError,
  disableColumnMenu = false,
  enableSelectAll = false,
  selectionMode = TableSelectionMode.single,
  cacheKey = '',
  pinnedColumns,
  columnVisibilityModel,
}: QueryDynamicTableProps) => {
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);

  const { isInitialFiltersUpdated, setInitialFiltersUpdated, subscribeEvent } =
    useRQLFiltersContext();
  const {
    expression,
    filterObj,
    addFilter,
    removeFilter,
    updateFilter,
    updateOrdering,
    updatePagination,
    updatePageSize,
    updateFilters,
  } = useRQLRouteFilters(
    defaultOrdering,
    map(dynamicFilters, (item) => item.filter),
    pageSize,
    1,
    backend,
    cacheKey
  );

  const urlFilters = get(filterObj, 'filters', null);
  const [hasUpdatedInitialFilters, setHasUpdatedInitialFilters] = useState(
    isInitialFiltersUpdated(cacheKey)
  );

  const [selectedItems, setSelectedItems] = useState<Content[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const rowsPerPage = includes(rowsPerPageOptions, pageSize)
    ? rowsPerPageOptions
    : sortBy([pageSize, ...rowsPerPageOptions]);
  const additional = queryOptions ? `&${queryOptions}` : '';
  const filters = expression ? `${expression}${additional}` : queryOptions;
  const {
    data,
    isLoading,
    count: contentsCount,
  }: { data?: any; isLoading: boolean; count: number } = useFetchDataListPaginated({
    ...query(filters),
  });

  const columns: GridColumns = useMemo(
    () => [
      ...(selectionMode === TableSelectionMode.single
        ? [
            {
              field: 'radiobutton',
              headerName: '',
              sortable: false,
              disableColumnMenu: true,
              width: 60,
              renderCell: (params) => (
                <Radio
                  checked={some(selectedItems, ({ id }) => params.id === id)}
                  value={params.id}
                />
              ),
            },
          ]
        : []),
      ...initialColumns,
    ],
    [initialColumns, selectedItems, selectionMode]
  );

  const currentPage = get(filterObj, 'page', 1) - 1; // The DataGrid pagination starts from 0

  const handleSortModelChange = useCallback(
    (newSortModel) => {
      const defaulSortModel = getDefaultSortModel(defaultOrdering);
      const field = get(get(newSortModel, 0), 'field', get(defaulSortModel, '[0].field'));
      const sort = get(get(newSortModel, 0), 'sort', get(defaulSortModel, '[0].sort'));
      const sign = sort === 'asc' ? '' : '-';
      updateOrdering(`${sign}${field}`);
    },
    [defaultOrdering, updateOrdering]
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      setCurrentPageSize(newPageSize);
      updatePageSize(newPageSize);
    },
    [updatePageSize]
  );

  const handleRowSelect = useCallback(
    (newSelectedRowsIds: string[]) => {
      const selectedRowsIds =
        selectionMode === TableSelectionMode.single
          ? difference(newSelectedRowsIds, map(selectedItems, 'id'))
          : newSelectedRowsIds;
      // As we are using  "server" as paginationMode, this map allows us to keep the selection even when changing pages.
      const itemsMap = reduce(
        [...selectedItems, ...data],
        (acc, item: Content) => ({ ...acc, [item.id]: item }),
        {}
      );
      // const newSelectedItems: Content[] = map(selectedRowsIds, (id: number) => get(itemsMap, id));
      setSelectedItems(map(selectedRowsIds, (id: number) => get(itemsMap, id)));
    },
    [data, selectedItems, selectionMode]
  );

  const handleExpressionChange = useCallback(
    (newExpression: string) => {
      // Clear the selected items only if the change is not related to pagination or ordering.
      if (clearExpression(expression) !== clearExpression(newExpression)) {
        setSelectedItems([]);
        setSelectAll(false);
      }
    },
    [expression]
  );

  // This "useEffect" ensures the handler will be executed only once.
  useEffect(() => {
    const subscription: EventSubscription = {
      eventName: 'expressionChange',
      handler: ({ expression: newExpression }) => handleExpressionChange(newExpression),
    };
    const unsubscribeEvent = subscribeEvent(cacheKey, subscription);
    return () => {
      unsubscribeEvent();
    };
  }, [subscribeEvent, cacheKey, handleExpressionChange]);

  useEffect(() => {
    const subscription: EventSubscription = {
      eventName: 'forceReload',
      handler: noop,
    };
    const unsubscribeEvent = subscribeEvent(cacheKey, subscription);
    return () => {
      unsubscribeEvent();
    };
  }, [cacheKey, subscribeEvent]);

  const shouldUpdateInitialFilters =
    size(initialFiltersConfig) > 0 &&
    !hasUpdatedInitialFilters &&
    !isNil(urlFilters) &&
    (backend !== 'url' || (isArray(urlFilters) && isEmpty(urlFilters)));

  useEffect(() => {
    // Updates with custom initial filters once
    if (shouldUpdateInitialFilters) {
      updateFilters(initialFiltersConfig);
      setHasUpdatedInitialFilters(true);
      setInitialFiltersUpdated(cacheKey);
    }
  }, [
    updateFilters,
    initialFiltersConfig,
    setInitialFiltersUpdated,
    cacheKey,
    shouldUpdateInitialFilters,
  ]);

  const table = useMemo(() => {
    return (
      <>
        {isLoading && <Loading />}
        {!isLoading && (
          <DataGrid
            initialState={{
              sorting: { sortModel: getDefaultSortModel(defaultOrdering) },
              pinnedColumns,
              columns: {
                columnVisibilityModel,
              },
            }}
            sx={{
              // These css's are specifically for hiding the column separator
              // that is added to the last column item automatically (and also the
              // second-to-last one, as we count the actions as a column)
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader:nth-last-of-type(2) .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              // One of the project scss files add a margin bottom to <p> elements, so we
              // must manually remove this margin by customizing the class in the pagination
              '& p.MuiTablePagination-displayedRows': {
                margin: 0,
                fontWeight: 500,
              },
              // Remove the borders
              border: 'none',
              '& .MuiDataGrid-footerContainer': {
                border: 'none',
              },
            }}
            components={{
              Toolbar,
              Footer,
            }}
            componentsProps={{
              toolbar: {
                dynamicFilters,
                filters: urlFilters,
                addFilter,
                removeFilter,
                updateFilter,
                updateFilters,
                filterBarInputWidth,
                topBarActions,
                selectedItems,
                expression,
                rowCount: contentsCount,
                rows: data,
                enableSelectAll,
                selectAll,
                setSelectAll,
              },
              footer: {
                pageSize: currentPageSize,
                rowCount: contentsCount,
                rowsPerPageOptions: rowsPerPage,
                selectedItems,
                actions,
                expression,
                rows: data,
                selectAll,
              },
              panel: {
                sx: {
                  // This update the padding in the filter popup
                  '& .MuiDataGrid-filterForm': {
                    padding: 2,
                  },
                },
              },
            }}
            columns={columns}
            rows={data as readonly any[]}
            rowCount={contentsCount}
            loading={isLoading}
            page={currentPage}
            pageSize={currentPageSize}
            rowsPerPageOptions={rowsPerPage}
            onPageChange={(newPage) => updatePagination(newPage + 1)}
            onPageSizeChange={handlePageSizeChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            selectionModel={map(selectedItems, 'id')}
            onSelectionModelChange={handleRowSelect}
            keepNonExistentRowsSelected
            isRowSelectable={(params: GridRowParams) => !includes(disabledItemsIds, params.row.id)}
            paginationMode="server"
            pagination
            checkboxSelection={selectionMode === TableSelectionMode.multiple}
            disableSelectionOnClick={selectionMode !== TableSelectionMode.single}
            isCellEditable={isCellEditable}
            autoHeight
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            onCellEditStop={(params, event) => {
              // This ensures that the edit mode will remain open until the user takes some action
              // to commit the result, like pressing enter or something that triggers the edit finish.
              if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                event.defaultMuiPrevented = true;
              }
            }}
            disableColumnMenu={disableColumnMenu}
          />
        )}
      </>
    );
  }, [
    actions,
    addFilter,
    columns,
    data,
    isLoading,
    columnVisibilityModel,
    pinnedColumns,
    contentsCount,
    currentPage,
    currentPageSize,
    defaultOrdering,
    disableColumnMenu,
    disabledItemsIds,
    dynamicFilters,
    enableSelectAll,
    expression,
    filterBarInputWidth,
    handlePageSizeChange,
    handleRowSelect,
    handleSortModelChange,
    isCellEditable,
    onProcessRowUpdateError,
    processRowUpdate,
    removeFilter,
    rowsPerPage,
    selectAll,
    selectedItems,
    selectionMode,
    topBarActions,
    updateFilter,
    updateFilters,
    updatePagination,
    urlFilters,
  ]);
  return table;
};

export default QueryDynamicTable;
