import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { queries } from 'queries';
import Page404 from 'scenes/404';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { useOriginRoute, useLabels } from 'shared/hooks';
import { toLower } from 'vendor/lodash';

import { useEditChannel } from '../api/mutations';
import ChannelFormPage from '../components/ChannelFormPage';
import { useChannelRoutes } from '../hooks/useChannelRoutes';

interface RouteChannelParams {
  slug: string;
}

const ChannelEdit = () => {
  const { slug: channelSlug } = useParams<RouteChannelParams>();

  const { label_channel: labelChannel, label_channel_plural: labelChannelPlural } = useLabels();

  const {
    data: channel,
    isLoading,
    isError,
  } = useQuery(queries.channels.detail(channelSlug || ''));

  const { detail: channelDetailRoute } = useChannelRoutes();

  const backRoute = useOriginRoute(channelDetailRoute);
  const catalogRoute = `${mapRoute('unifiedCatalogList')}`;
  const onSubmit = useEditChannel(channelSlug || '');

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  const initialValuesParams = {
    channel: {
      ...channel,
    },
  };

  return (
    <ChannelFormPage
      pageTitle={`Edit ${labelChannel}`}
      topBarActionName={`Editing ${labelChannel}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelChannelPlural,
          link: catalogRoute,
        },
        {
          label: channel.name,
          link: channelDetailRoute({ slug: channelSlug }),
        },
        {
          label: `Edit ${toLower(labelChannel)}`,
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default ChannelEdit;
