/* eslint-disable import/no-anonymous-default-export */
import EmbedLink from './EmbedLink';

export default {
  options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'textAlign', 'colorPicker', 'emoji'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  link: {
    component: EmbedLink,
    showOpenOptionOnHover: false,
  },
};
