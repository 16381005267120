import styled from 'styled-components';

export const RosterContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(70vh - 90px); // Necessary to show the scrollbar in the child element
  max-height: calc(70vh - 90px); // Necessary to show the scrollbar in the child element
`;

export const RosterControlsArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 20px 20px;

  > * + * {
    margin-left: 16px;
  }
`;
