import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SquareIconButton from 'shared/components/SquareIconButton';

const GraphPaginationControllers = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 12px;
  > * + * {
    margin-left: ${(props) => (props.direction === 'row' ? '16px' : '0px')};
    margin-top: ${(props) => (props.direction === 'row' ? '0px' : '16px')};
  }
`;

const ChartPagination = ({
  handleDecreasePageOffset,
  handleIncreasePageOffset,
  intervalData,
  pageOffset,
  lastPageItemIndex,
  direction,
}) => (
  <GraphPaginationControllers direction={direction}>
    <SquareIconButton
      iconName={direction === 'row' ? 'left' : 'up'}
      width={14}
      height={14}
      onClick={handleDecreasePageOffset}
      disabled={!intervalData || pageOffset === 0}
    />
    <SquareIconButton
      iconName={direction === 'row' ? 'right' : 'down'}
      width={14}
      height={14}
      onClick={handleIncreasePageOffset}
      disabled={!intervalData || lastPageItemIndex === intervalData.length}
    />
  </GraphPaginationControllers>
);

ChartPagination.propTypes = {
  intervalData: PropTypes.array,
  handleDecreasePageOffset: PropTypes.func,
  handleIncreasePageOffset: PropTypes.func,
  pageOffset: PropTypes.number,
  lastPageItemIndex: PropTypes.number,
  direction: PropTypes.string,
};

export default ChartPagination;
