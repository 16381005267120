import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import FileField from 'inputs/components/FileField';
import InputLabel from 'inputs/components/InputLabel';
import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

import CourseUploadFieldHelpText from './CourseUploadFieldHelpText';

const UploadAreaText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const FieldDetails = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 0;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const Label = styled(InputLabel)`
  margin-left: 16px;
`;

const CourseUploadField = (props) => {
  const { helpText, allowedExtensions, hasUploadError } = props;

  const [error, setError] = useState(
    hasUploadError ? 'Error while uploading the file. Please check your file and try again.' : ''
  );

  return (
    <FieldWrapper>
      <FileField
        width="250px"
        height="216px"
        allowedExtensions={allowedExtensions}
        setHelpTextError={setError}
        fileAreaText={(fileName) => (
          <UploadAreaText>
            <Icon name="zip-file-fill" height={30} width={22} color={colors.action600} />
            <Text size="h5" color={colors.action600} medium align="center">
              {fileName}
            </Text>
          </UploadAreaText>
        )}
        uploadAreaText={
          <UploadAreaText>
            <Icon name="zip-file" height={30} width={22} color={colors.action600} />
            <Text size="h5" color={colors.neutral400}>
              Click here to{' '}
              <Text color={colors.action600} size="h5" medium>
                upload a course file.
              </Text>
            </Text>
          </UploadAreaText>
        }
        {...props}
      />
      <FieldDetails>
        <Label>Course file</Label>
        <CourseUploadFieldHelpText helpText={helpText} error={error} />
      </FieldDetails>
    </FieldWrapper>
  );
};

CourseUploadField.propTypes = {
  input: PropTypes.object,
  hasUploadError: PropTypes.bool,
  filePath: PropTypes.string,
  helpText: PropTypes.object,
  allowedExtensions: PropTypes.object,
};

export default CourseUploadField;
