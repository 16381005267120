import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useLearningTypeLabels } from 'catalog/hooks';
import colors from 'services/colors';
import Clicker from 'shared/components/Clicker';
import Text from 'shared/components/Text';

const Container = styled.div`
  width: 312px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.neutral0};
  border-radius: 4px;
`;

const StyledClicker = styled(Clicker)`
  &:hover {
    #view-all {
      text-decoration: underline;
    }
  }
`;

// This is a simplified copy of BaseCard
const ViewAllCard = ({ quantity, learningType, onChangeViewMode }) => {
  const learningTypeLabelSingular = useLearningTypeLabels()[learningType] || 'Content Item';
  const learningTypeLabelPlural =
    useLearningTypeLabels({ isPlural: true })[learningType] || 'Content Items';
  return (
    <StyledClicker onClick={onChangeViewMode}>
      <Container>
        <Text size="h2" medium>
          +
          {quantity === 1
            ? `${quantity} ${learningTypeLabelSingular}`
            : `${quantity} ${learningTypeLabelPlural}`}
        </Text>
        <Text id="view-all" size="h3" color={colors.action700}>
          View All
        </Text>
      </Container>
    </StyledClicker>
  );
};

ViewAllCard.propTypes = {
  quantity: PropTypes.number,
  learningType: PropTypes.string,
  onChangeViewMode: PropTypes.func,
};

export {ViewAllCard};
