import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const ExclamationIcon = ({ width, height, fill }) => {
  const d =
    'm 9.9174294,0.00102624 c -1.3802843,0 -2.6686097,1.34793386 -2.5152447,2.72821826 l 0.857286,10.4923165 c 0.061346,0.828171 0.7074555,1.531912 1.5662991,1.593259 0.9508632,0.06134 1.7789092,-0.611723 1.8709322,-1.593259 L 12.553988,2.7292445 C 12.67668,1.3489601 11.451077,0.00102624 9.9174294,0.00102624 Z m 0.09166,16.22642876 c -0.9201894,0 -1.6876129,0.766404 -1.6876129,1.717267 v 0.336984 c 0,0.950862 0.7367506,1.717268 1.6876129,1.717268 0.950863,0 1.717268,-0.735733 1.717268,-1.717268 v -0.336984 c 0,-0.950863 -0.766405,-1.717267 -1.717268,-1.717267 z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

ExclamationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default ExclamationIcon;
