import PropTypes from 'prop-types';
import React from 'react';

import UserAvatarImage from './UserAvatarImage';
import UserPopover from './UserPopover';

export const UserAvatar = ({
  showPopover,
  showBadge,
  sendEmailCTA,
  user,
  className,
  disableProfileLink,
  size,
  imgGrayscale,
  badgeSize,
  badgeTop,
  badgeLeft,
  hasBorder,
}) => {
  return showPopover ? (
    <UserPopover
      renderPopoverHoverable={
        <UserAvatarImage
          badgeLeft={badgeLeft}
          badgeSize={badgeSize}
          badgeTop={badgeTop}
          className={className}
          disableProfileLink={disableProfileLink}
          hasBorder={hasBorder}
          imgGrayscale={imgGrayscale}
          showBadge={showBadge}
          size={size}
          user={user}
        />
      }
      badgeLeft={badgeLeft}
      badgeSize={badgeSize}
      badgeTop={badgeTop}
      className={className}
      disableProfileLink={disableProfileLink}
      sendEmailCTA={sendEmailCTA}
      showBadge={showBadge}
      size={size}
      user={user}
    />
  ) : (
    <UserAvatarImage
      badgeLeft={badgeLeft}
      badgeSize={badgeSize}
      badgeTop={badgeTop}
      className={className}
      disableProfileLink={disableProfileLink}
      hasBorder={hasBorder}
      imgGrayscale={imgGrayscale}
      showBadge={showBadge}
      size={size}
      user={user}
    />
  );
};

UserAvatar.defaultProps = {
  user: {},
  showPopover: false,
};

UserAvatar.propTypes = {
  sendEmailCTA: PropTypes.bool,
  showBadge: PropTypes.bool,
  showPopover: PropTypes.bool,

  badgeLeft: PropTypes.number,
  badgeSize: PropTypes.string,
  badgeTop: PropTypes.number,
  className: PropTypes.string,
  disableProfileLink: PropTypes.bool,
  hasBorder: PropTypes.bool,
  imgGrayscale: PropTypes.bool,
  size: PropTypes.string,
  user: PropTypes.object,
};

export default UserAvatar;
