import { useLabels } from 'shared/hooks';
import { Tooltip, Chip, colors } from 'vendor/mui';
import { OptionalOutlineIcon } from 'vendor/mui-icons';

interface OptionalChipProps {
  sx?: object;
  size?: 'small' | 'medium';
}

const OptionalChip = (props: OptionalChipProps) => {
  const { size = 'medium', sx = {} } = props;

  const { label_track: labelTrack } = useLabels();

  return (
    <Tooltip title={`This item is optional within this ${labelTrack}`}>
      <Chip
        label="Optional"
        icon={<OptionalOutlineIcon />}
        sx={{ ...sx, backgroundColor: colors.grey[200] }}
        size={size}
      />
    </Tooltip>
  );
};

export default OptionalChip;
