import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CONTENT_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import * as schema from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import { STATUS_DONE } from 'shared/constants';
import { useFormMeta, useFormSelector, useFormSyncErrors } from 'shared/hooks';
import { useContentSurveyContext } from 'surveys/components/NewSurvey/ContentSurveyContext';
import NewSurveyBoxContent from 'surveys/components/NewSurvey/NewSurveyBoxContent';
import NewSurveyBoxHeader from 'surveys/components/NewSurvey/NewSurveyBoxHeader';
import * as constants from 'surveys/constants';
import { get, isEmpty, omit, includes } from 'vendor/lodash';

const Box = styled.div`
  border: 1px solid ${({ hasError }) => (hasError ? colors.error600 : colors.neutral200)};
  border-radius: 4px;
  margin: 20px 0 26px;
  padding: 16px;

  ${({ isOpen }) => isOpen && 'box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);'}
`;

const NewInternalSurveyBox = ({
  formName,
  name,
  index,
  handleOnRemove,
  fields,
  lastOpenedBox,
  setLastOpenedBox,
  assignmentsCount,
  isEditing,
}) => {
  const isOpen = index === lastOpenedBox;

  const [survey, setSurvey] = useState(null);
  const [hasError, setHasError] = useState(false);

  const surveyRelationshipsErrors = useFormSyncErrors(formName, 'survey_relationships');
  const surveyRelationshipsMeta = useFormMeta(formName, 'survey_relationships');

  const contentType = useContentSurveyContext();
  // If showing an event/event type survey, use the labels for it, otherwise, use the content type specific
  const shouldUseContentMatters = !includes(
    [CONTENT_TYPES.eventtype, CONTENT_TYPES.event],
    contentType
  );

  const [fetchSurvey] = useEntities(
    actions.survey.retrieveDetails,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setSurveySummary(data);
      }
    },
    {
      schema: schema.surveySchema,
    }
  );

  useEffect(() => {
    const field = fields.get(index);
    const { survey } = field;

    if (survey) {
      setSurveySummary(survey);
      setLastOpenedBox(-1);
    }
  }, []);

  useEffect(() => {
    if (!survey || isOpen) return;

    setSurveySummary(survey);
  }, [isOpen]);

  useEffect(() => {
    if (hasError && !isOpen) return;

    if (
      isEmpty(surveyRelationshipsErrors) ||
      isEmpty(surveyRelationshipsErrors[index]) ||
      isEmpty(surveyRelationshipsMeta) ||
      isEmpty(surveyRelationshipsMeta[index])
    ) {
      setHasError(false);
      return;
    }

    const otherError = omit(surveyRelationshipsErrors[index], 'survey_id');
    setHasError(!isEmpty(otherError) || get(surveyRelationshipsMeta[index], 'survey_id.touched'));
  }, [surveyRelationshipsErrors]);

  const handleOnSurveyTemplateChange = (selectedId) => {
    if (!selectedId) {
      setSurveySummary(null);
      return;
    }
    fetchSurvey(selectedId);
  };

  const handleOnOpenBox = () => {
    setLastOpenedBox(isOpen ? -1 : index);
  };

  const setSurveySummary = (survey) => {
    if (!survey) {
      setSurvey(null);
      return;
    }

    const field = fields.get(index);

    const schedulePeriodOption =
      Number(field.survey_schedule_period_value) > 1
        ? field.survey_schedule_period_option
        : field.survey_schedule_period_option.slice(0, -1);
    const cutoffPeriodOption =
      Number(field.survey_cutoff_period_value) > 1
        ? field.survey_cutoff_period_option
        : field.survey_cutoff_period_option.slice(0, -1);

    let matterRelationMark = constants.MATTER_RELATION_MARK;
    if (shouldUseContentMatters) {
      matterRelationMark = constants.CONTENT_MATTER_RELATION_MARK;
    }
    const surveyOpens = `${field.survey_schedule_period_value} ${schedulePeriodOption}
      ${matterRelationMark[field.survey_schedule_kind]}`;
    const surveyCloses = `${field.survey_cutoff_period_value} ${cutoffPeriodOption} after opening`;

    setSurvey({
      ...survey,
      opens: surveyOpens,
      closes: surveyCloses,
    });
  };

  const surveyRelationshipsContent = useFormSelector(formName, 'survey_relationships');

  return (
    <Box isOpen={isOpen} hasError={hasError}>
      <NewSurveyBoxHeader
        isOpen={isOpen}
        hasError={hasError}
        handleOnClick={handleOnOpenBox}
        survey={survey}
      />
      {isOpen && (
        <NewSurveyBoxContent
          name={name}
          survey={survey}
          surveyRelationship={surveyRelationshipsContent[index]}
          assignmentsCount={assignmentsCount}
          matterRelationOptions={
            shouldUseContentMatters
              ? constants.contentMatterRelationOptions
              : constants.matterRelationOptions
          }
          isEditing={isEditing}
          handleOnChange={handleOnSurveyTemplateChange}
          handleOnRemove={handleOnRemove}
        />
      )}
    </Box>
  );
};

NewInternalSurveyBox.propTypes = {
  formName: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.number,
  assignmentsCount: PropTypes.number,
  handleOnRemove: PropTypes.func,
  fields: PropTypes.object,
  lastOpenedBox: PropTypes.number,
  setLastOpenedBox: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default NewInternalSurveyBox;
