import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Circle = styled.div`
  background-color: ${colors.neutral200};
  width: 400px;
  height: 400px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
`;

const FourOFour = styled.div`
  font-size: 80px;
  color: ${colors.emphasis600};
`;

const Message = styled.div`
  color: ${colors.neutral900};
  text-align: center;
  margin: 20px;
`;

const StyledLink = styled.a`
  color: ${colors.emphasis600};
  font-weight: 700;
`;

const Page404 = () => (
  <Container>
    <Circle>
      <FourOFour>404</FourOFour>
      <Message>
        Unfortunately, we couldn&apos;t find the content you were looking for{' '}
        <span role="img" aria-label="sad">
          😞
        </span>
      </Message>
      <div>
        Please <StyledLink href="/">return to the homepage</StyledLink>.
      </div>
    </Circle>
  </Container>
);

export default Page404;
