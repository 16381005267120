import PropTypes from 'prop-types';
import React from 'react';

import SettingShortTemplateField from './SettingShortTemplateField';

const SettingLongTemplateField = (props) => {
  return <SettingShortTemplateField multiline {...props} label="" />;
};

SettingLongTemplateField.defaultProps = {
  multiline: true,
  rows: 5,
};

SettingLongTemplateField.propTypes = {
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default SettingLongTemplateField;
