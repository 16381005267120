import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useEventTypeFieldLabels } from 'catalog/hooks';
import {
  EVENT_TYPE_CHANGES_UPCOMING_EVENTS,
  EVENT_TYPE_CHANGES_PREVIOUS_EVENTS,
  EVENT_TYPE_CHANGES_ALL_EVENTS,
} from 'event-shared/constants';
import RadioInput from 'inputs/components/RadioInput';
import CheckboxInput from 'inputs/components/SquareCheckboxInput';
import colors from 'services/colors';
import Modal, { ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { useLabels, useToggles } from 'shared/hooks';
import { concat, without, includes, keys, map, pick } from 'vendor/lodash';
import { Box, Stack } from 'vendor/mui';

const ModalContent = styled.div`
  padding: 20px 40px 5px 40px;
  overflow-y: auto;
`;

const RadioButtonContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 20px 0;
`;

type EventTypeApplyToEventsModalProps = {
  eventFields: Record<string, unknown>;
  handleClose: () => void;
  handleSubmit: (fields: string[], selectedOption: string) => Promise<void>;
};

const EventTypeApplyToEventsModal = (props: EventTypeApplyToEventsModalProps) => {
  const { eventFields, handleClose, handleSubmit: onSubmit } = props;

  const [selectedOption, setSelectedOption] = useState(EVENT_TYPE_CHANGES_UPCOMING_EVENTS);
  const { toggle_channels: toggleChannels } = useToggles();
  const { label_event_type: labelEventType } = useLabels();
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  const submitMutation = useMutation({
    mutationFn: async (values: { fields: string[]; option: string }) => {
      await onSubmit(values.fields, values.option);
    },
    onSuccess: () => {
      handleClose();
    },
  });

  const fieldLabels = useEventTypeFieldLabels();

  const handleChoiceChangeClick = (e) => {
    setSelectedOption(e);
  };

  const toggleField = (field: string) => {
    setCheckedFields((checkedFields) => {
      if (includes(checkedFields, field)) {
        return without(checkedFields, field);
      }

      return concat(checkedFields, field);
    });
  };

  useEffect(() => {
    if (toggleChannels) {
      toggleField('channel_id');
    }
  }, [toggleChannels]);

  const fieldsWithSections = useMemo(() => {
    const sections = [] as { title: string; fields: string[] }[];

    const detailsFields = keys(pick(eventFields, ['cover', 'name', 'content_body', 'tags']));

    if (toggleChannels) {
      detailsFields.push('channel_id');
    }

    sections.push({
      title: 'Details',
      fields: detailsFields,
    });

    const facilitatorsFields = keys(
      pick(eventFields, ['organizer_id', 'co_organizers_ids', 'presenters_ids'])
    );

    sections.push({
      title: 'Facilitators',
      fields: facilitatorsFields,
    });

    const capacityFields = keys(
      pick(eventFields, [
        'enrollment_limit',
        'wait_list_limit',
        'online_enrollment_limit',
        'online_wait_list_limit',
      ])
    );

    sections.push({
      title: 'Enrollment & Waitlist Capacity',
      fields: capacityFields,
    });

    const locationFields = keys(
      pick(eventFields, ['is_local', 'is_online', 'location_id', 'duration', 'watch_link'])
    );

    sections.push({
      title: 'Location & Time',
      fields: locationFields,
    });

    const resourcesFields = keys(pick(eventFields, ['office_hour_id']));

    sections.push({
      title: 'Resources',
      fields: resourcesFields,
    });

    const surveysFields = keys(pick(eventFields, ['external_survey_link']));

    sections.push({
      title: 'Surveys',
      fields: surveysFields,
    });

    const accessControlFields = keys(
      pick(eventFields, ['is_hidden', 'groups_ids', 'hide_enrollees'])
    );

    sections.push({
      title: 'Access Control',
      fields: accessControlFields,
    });

    const externalRegistrationFields = keys(
      pick(eventFields, ['external_link', 'external_link_description'])
    );

    sections.push({
      title: 'External Registration',
      fields: externalRegistrationFields,
    });

    return sections;
  }, [eventFields, toggleChannels]);

  const handleSubmit = () => {
    submitMutation.mutate({ fields: checkedFields, option: selectedOption });
  };

  const isSubmitting = submitMutation.isLoading;

  return (
    <Modal title="Apply to Existing Events" handleClose={handleClose} width={500}>
      <ModalContent>
        <Text block size="h4" color={colors.neutral900}>
          Which fields should change?
        </Text>
        <Stack pt={1} pb={2} gap={2}>
          {map(fieldsWithSections, (section) => (
            <Stack key={section.title}>
              <Text block size="h5" color={colors.neutral700}>
                {section.title}
              </Text>
              <Box>
                {map(section.fields, (field) => {
                  const label = fieldLabels[field] ? fieldLabels[field] : field;
                  const isChecked = includes(checkedFields, field);
                  const isDisabled = toggleChannels && field === 'channel_id';
                  return (
                    <CheckboxContainer key={field}>
                      <CheckboxInput
                        checked={isChecked}
                        onChange={() => toggleField(field)}
                        label={label}
                        disabled={isDisabled}
                      />
                    </CheckboxContainer>
                  );
                })}
              </Box>
            </Stack>
          ))}
        </Stack>

        <Text block size="h4" color={colors.neutral900}>
          Which events should receive these changes?
        </Text>
        <Stack pt={1}>
          <RadioButtonContainer>
            <RadioInput
              inputValue={selectedOption}
              name="apply_changes"
              value={EVENT_TYPE_CHANGES_UPCOMING_EVENTS}
              onChange={handleChoiceChangeClick}
              label={`All future instances of ${labelEventType}`}
              helpText="Including upcoming ones that were already created"
            />
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioInput
              inputValue={selectedOption}
              name="apply_changes"
              value={EVENT_TYPE_CHANGES_PREVIOUS_EVENTS}
              onChange={handleChoiceChangeClick}
              label={`Past instances of ${labelEventType} only`}
              helpText={`Upcoming ones that were already created will not have their fields updated, but do note that any changes that were made to the ${labelEventType} template will reflect on the ones that are created from now on.`}
            />
          </RadioButtonContainer>
          <RadioButtonContainer>
            <RadioInput
              inputValue={selectedOption}
              name="apply_changes"
              value={EVENT_TYPE_CHANGES_ALL_EVENTS}
              onChange={handleChoiceChangeClick}
              label={`Apply changes to all instances of ${labelEventType}`}
              helpText="Including those past, upcoming, and to-be-created"
            />
          </RadioButtonContainer>
        </Stack>

        <StyledText block size="h4" color={colors.neutral500}>
          Caution: this action cannot be undone.
        </StyledText>
      </ModalContent>
      <ModalFooter variant="buttons">
        <ModalFooterButton
          color="secondary"
          onClick={handleClose}
          size="small"
          disabled={isSubmitting}
        >
          Skip
        </ModalFooterButton>
        <ModalFooterButton
          size="small"
          onClick={handleSubmit}
          disabled={checkedFields.length === 0}
          loading={isSubmitting}
        >
          Apply
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default EventTypeApplyToEventsModal;
