import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { CONTENT_TYPES } from 'app/catalog/constants';
import actions from 'app/entities/actions';
import { userSchema } from 'app/entities/schema';
import { useEntities } from 'app/entities/utils';
import Loading from 'app/shared/components/Loading';
import { STATUS_DONE } from 'app/shared/constants';
import { ApiURLs } from 'services/requests-base';
import { some } from 'vendor/lodash';

import { BaseImpactSection } from './shared/BaseImpactSection';

export const EventSection = ({ userId }) => {
  const [
    fetchImpactedUsers,
    { count: impactedUserCount, status: fetchImpactedUsersStatus, data: impactedUsers },
  ] = useEntities(actions.userData.retrieveImpactedList, null, {
    schema: [userSchema],
  });

  const [fetchRatings, { status: fetchFeedbackStatus, data: feedbackRatings }] = useEntities(
    actions.userData.retrieveFacilitatorFeedback,
    null
  );

  const [
    fetchFeedbackList,
    {
      status: fetchFacilitatorReviewStatus,
      data: { results: feedbackList },
    },
  ] = useEntities(actions.content.feedbackList, null);
  const impactUrl = `${ApiURLs['events:list']()}?(eq(facilitator,${userId}))`;
  useEffect(() => {
    fetchImpactedUsers(userId, { impact_content_type: 'event' });
    fetchRatings(userId, { view_mode: 'event' });
    fetchFeedbackList({
      facilitators: [userId],
      content_item_type: [CONTENT_TYPES.event],
    });
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    some(
      [fetchImpactedUsersStatus, fetchFeedbackStatus, fetchFacilitatorReviewStatus],
      (status) => status !== STATUS_DONE
    )
  ) {
    return <Loading />;
  }
  if (impactedUsers == 0) {
    return null;
  }
  return (
    <BaseImpactSection
      impactedUsers={impactedUsers}
      impactedUserCount={impactedUserCount}
      feedbackTitle="Events Reviews"
      feedbackRatings={feedbackRatings.events_ratings}
      feedbackList={feedbackList}
      title="Events"
      impactUrl={impactUrl}
    />
  );
};

EventSection.propTypes = {
  userId: PropTypes.number.isRequired,
};
