import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { hasCreatePermission } from 'app/automated-rule/permissions';
import AutomatedRuleForm from 'automated-rule/components/AutomatedRuleForm';
import { toast } from 'notifications/components/NotificationCenter';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import PageTitle from 'shared/components/PageTitle';
import { useQueryParams, useCurrentUser } from 'shared/hooks';

const AutomatedRuleCreate = () => {
  const history = useHistory();
  const { origin } = useQueryParams();
  const { role: currentUserRole } = useCurrentUser();
  const hasPermission = hasCreatePermission(currentUserRole);

  useEffect(() => {
    if (!hasPermission) {
      permissionDeniedRedirectAlert();
    }
  }, [hasPermission]);

  if (!hasPermission) return null;

  const initialValues = { is_enabled: true };

  return (
    <>
      <PageTitle title="Create an Automated Rule" />
      <AutomatedRuleForm
        form="automatedRuleCreateForm"
        initialValues={initialValues}
        topBarActionName="Creating an Automated Rule"
        onSubmitSuccessHandler={() => {
          const url = origin ?? mapRoute('dashboardAutomatedRules');
          history.push(url);
          toast.success('Automated rule added successfully!');
        }}
      />
    </>
  );
};

export default AutomatedRuleCreate;
