import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

import { CARD_LIST_WIDTH_THRESHOLDS } from 'content-items/components/CardListWrapper/constants';
import colors from 'services/colors';
import { Button } from 'shared';
import { isEmpty, map, size, take } from 'vendor/lodash';
import { Stack, Typography } from 'vendor/mui';
import { ArrowForwardIcon } from 'vendor/mui-icons';

export const getMaxItemsPerRow = (width: number) => {
  const { sm, md, lg, xl } = CARD_LIST_WIDTH_THRESHOLDS;

  if (width < sm) return 1;
  if (width < md) return 2;
  if (width < lg) return 3;
  if (width < xl) return 4;

  return 6;
};

interface HorizontalCardListProps {
  sectionTitle?: string;
  renderItem: any;
  items?: any[];
  totalItemsCount?: number;
  viewAllLabel?: string;
  viewAllUrl?: string;
}

const HorizontalCardlistInner = (props: HorizontalCardListProps) => {
  const {
    sectionTitle,
    renderItem,
    items,
    totalItemsCount,
    viewAllLabel = 'View All',
    viewAllUrl,
  } = props;

  const { ref, width } = useResizeDetector();

  const itemsPerRow = getMaxItemsPerRow(Math.round(width || 0));
  const itemsList = take(items, itemsPerRow);

  const shouldRenderViewAllButton =
    Boolean(viewAllUrl) && totalItemsCount && size(itemsList) < totalItemsCount;

  return (
    <Stack direction="column" gap={1} ref={ref}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" color={colors.neutral500} fontWeight={600}>
          {sectionTitle}
        </Typography>
        {shouldRenderViewAllButton && (
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon />}
            size="small"
            component={Link}
            to={viewAllUrl}
          >
            {viewAllLabel}
          </Button>
        )}
      </Stack>

      <Stack direction="row" gap={3}>
        {map(itemsList, (item) => renderItem(item))}
      </Stack>
    </Stack>
  );
};

const HorizontalCardList = (props: HorizontalCardListProps) => {
  const { items, ...restProps } = props;

  if (isEmpty(items)) return null;

  return <HorizontalCardlistInner items={items} {...restProps} />;
};

export default HorizontalCardList;
