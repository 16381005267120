import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RadioField from 'inputs/components/RadioField';
import Text from 'shared/components/Text';
import { map, range } from 'vendor/lodash';

const GroupWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;

const ScaleLabel = styled(Text)`
  letter-spacing: -0.39px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  max-width: 25%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > * {
    min-height: 44px;
  }
`;

const InputWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    min-height: 44px;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SurveyQuestionLinearScale = ({ input, question }) => {
  const {
    lower_range: start,
    upper_range: end,
    label_1: leftLabel,
    label_2: rightLabel,
  } = question;
  const optionsRange = range(start, end + 1);

  return (
    <GroupWrapper>
      <LabelContainer>
        <div />
        {leftLabel && <ScaleLabel size="h4">{leftLabel}</ScaleLabel>}
      </LabelContainer>
      {map(optionsRange, (value) => (
        <InputWrapper key={value}>
          <Text size="h4">{value}</Text>
          <RadioField input={input} optionValue={value} />
        </InputWrapper>
      ))}
      <LabelContainer>
        <div />
        {rightLabel && <ScaleLabel size="h4">{rightLabel}</ScaleLabel>}
      </LabelContainer>
    </GroupWrapper>
  );
};

SurveyQuestionLinearScale.propTypes = {
  question: PropTypes.object,
  input: PropTypes.object,
};

export default SurveyQuestionLinearScale;
