import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SearchInput from 'inputs/components/SearchInput';
import SelectField from 'inputs/components/SelectField';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import Rating from 'shared/components/Rating';
import Text from 'shared/components/Text';
import { UserPopover, UserAvatarImage } from 'shared/components/UserAvatar';
import { useDebounce } from 'shared/hooks';
import { map, filter, includes, toLower, orderBy, isEqual, isNil } from 'vendor/lodash';

const ControlsArea = styled.div`
  flex: 0 0 72px;
  width: 100%;
  padding: 16px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: 20px;
  }
`;

const SearchFieldWrapper = styled.div`
  flex: 1 1 244px;

  & > div {
    height: 38px;
  }

  & > div > div {
    height: 30px;
  }
`;

const SelectFieldOrderingWrapper = styled.div`
  flex: 1 1 128px;
`;

const CommentsText = styled(Text)`
  padding: 0px 20px 8px;
`;

const FeedbackItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 8px 12px 20px;
  border-bottom: 1px solid ${colors.neutral200};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${colors.neutral100};
  }

  > * + * {
    margin-left: 12px;
  }
`;

const UserAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 132px;
  overflow: hidden;
  word-wrap: break-word;
`;

const StyledUserAvatar = styled(UserAvatarImage)`
  margin-right: 8px;
`;

const LinkStyled = styled(Link)`
  &:hover ${Text} {
    color: ${colors.emphasis600};
    font-weight: 500;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  max-width: 336px;
`;

const FeedbackItem = ({ user, feedbackRating, feedbackText }) => (
  <FeedbackItemContainer>
    <UserPopover
      showPopover
      sendEmailCTA
      user={user}
      renderPopoverHoverable={
        <UserAvatarWrapper>
          <StyledUserAvatar user={user} size="small" />
          <UserInfo>
            <LinkStyled to={mapRoute('userProfile', { id: user.id })}>
              <Text primary size="h4" color={colors.neutral900} ellipsisOnOverflow>
                {user.name}
              </Text>
            </LinkStyled>

            <Rating value={feedbackRating} readOnly />
          </UserInfo>
        </UserAvatarWrapper>
      }
    />

    <RightWrapper>
      <Text size="h5" color={colors.neutral600}>
        {feedbackText}
      </Text>
    </RightWrapper>
  </FeedbackItemContainer>
);

FeedbackItem.propTypes = {
  user: PropTypes.object,
  feedbackRating: PropTypes.number,
  feedbackText: PropTypes.string,
};

export const ReadOnlyModal = ({ session, handleClose }) => {
  const {
    enrollments,
    session_enrollments_count: sessionEnrollmentsCount,
    program_id: programId,
    host,
    host_awaiting_feedback: hostAwaitingFeedback,
    host_feedback_rating: hostFeedbackRating,
    host_feedback_text: hostFeedbackText,
  } = session;

  const hasMultipleAttendees = sessionEnrollmentsCount > 1;

  // Get only the enrollments with feedback
  const [feedbacks, setFeedbacks] = useState(
    filter(enrollments, (enrollment) => enrollment.feedback_rating > 0)
  );
  const [searchText, setSearchText] = useState('');
  const [orderingFilterType, setOrderingFilterType] = useState('name');

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (isEqual(orderingFilterType, 'modified')) {
      setFeedbacks(orderBy(feedbacks, ['modified'], ['desc']));
      return;
    }

    setFeedbacks(orderBy(feedbacks, (enrollment) => enrollment.attendee.name, ['asc']));
  }, [orderingFilterType]);

  const handleOnSearchTextChange = (value) => setSearchText(value);

  const handleOrderingFilterType = (value) => setOrderingFilterType(value);

  const filteredFeedbacks = debouncedSearchText
    ? filter(
        feedbacks,
        ({ attendee }) =>
          includes(toLower(attendee.name), toLower(debouncedSearchText)) ||
          includes(toLower(attendee.email), toLower(debouncedSearchText))
      )
    : feedbacks;

  const sessionHasMentorFeedback =
    isNil(programId) && (!hostAwaitingFeedback || hostFeedbackRating > 0);

  return (
    <Modal title="Session Feedback" handleClose={handleClose} height="200px" width={560}>
      <ModalBody fullSize>
        {hasMultipleAttendees && (
          <>
            <ControlsArea>
              <SearchFieldWrapper>
                <SearchInput
                  placeholder="Search by name or email"
                  value={searchText}
                  onChange={handleOnSearchTextChange}
                />
              </SearchFieldWrapper>
              <SelectFieldOrderingWrapper>
                <SelectField
                  options={[
                    { label: 'Name (A-Z)', value: 'name' },
                    { label: 'Most recent', value: 'modified' },
                  ]}
                  input={{
                    value: orderingFilterType,
                    onChange: handleOrderingFilterType,
                  }}
                />
              </SelectFieldOrderingWrapper>
            </ControlsArea>

            <CommentsText color={colors.neutral900} display="inline-block">
              {filteredFeedbacks.length} comments
            </CommentsText>
          </>
        )}

        {sessionHasMentorFeedback && (
          <FeedbackItem
            user={host}
            feedbackRating={hostFeedbackRating}
            feedbackText={hostFeedbackText}
          />
        )}

        {map(
          enrollments,
          ({ attendee, feedback_rating: feedbackRating, feedback_text: feedbackText }) => (
            <FeedbackItem
              key={attendee.id}
              user={attendee}
              feedbackRating={feedbackRating}
              feedbackText={feedbackText}
            />
          )
        )}
      </ModalBody>

      <ModalFooter />
    </Modal>
  );
};

ReadOnlyModal.propTypes = {
  session: PropTypes.object,

  handleClose: PropTypes.func,
};

export default ReadOnlyModal;
