import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { mapRoute } from 'services/requests';
import InfoBox from 'shared/components/InfoBox';
import Text from 'shared/components/Text';
import { isNil } from 'vendor/lodash';

const RestrictedEnrollmentInfoBox = ({ event }) => {
  const LinkComponent = !isNil(event.organizer) ? Link : React.Fragment;

  return (
    <InfoBox
      content={
        <>
          <Text medium block>
            Restricted enrollment
          </Text>
          <Text>
            You don&apos;t appear to be eligible to enroll into this event. For more information,
            please check with the{' '}
            <LinkComponent
              {...(!isNil(event.organizer) && {
                to: mapRoute('userProfile', { id: event.organizer.id }),
              })}
            >
              organizer.
            </LinkComponent>
          </Text>
        </>
      }
    />
  );
};

RestrictedEnrollmentInfoBox.propTypes = {
  event: PropTypes.shape({
    organizer: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default RestrictedEnrollmentInfoBox;
