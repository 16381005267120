import moment from 'moment';

import { rqlExpressionToObject } from 'backoffice/utils';
import { LEARNING_TYPE_ICONS_MAPPING } from 'catalog/constants';
import { formatFlexibleDateRangeInputText } from 'inputs/components/FlexibleDateRangeInput/utils';
import { RQLDateRange, RQLFilter, User } from 'shared/components/types';
import { buildFlexibleTagsList } from 'topics/services';
import {
  isEmpty,
  map,
  get,
  find,
  split,
  trim,
  slice,
  filter,
  includes,
  has,
  isNil,
  omit,
  head,
  keys,
  concat,
  forEach,
} from 'vendor/lodash';
import rql from 'vendor/rql';

import { Option } from './components/inputs/Select/Select';
import { FilterDef } from './components/RQLFilterBar/RQLFilterBar';

interface GetFlexibleFiltersDefProps {
  filters: RQLFilter | null;
  updateFilter: (newValue: RQLFilter) => void;
  currentUser: User;
  toggleTypes?: string[];
  strict?: boolean;
  allowedTagTypes?: string[];
}

export const getFlexibleFiltersDef = ({
  filters,
  updateFilter,
  currentUser,
  toggleTypes = [],
  strict = false,
  allowedTagTypes = [],
}: GetFlexibleFiltersDefProps): FilterDef[] => {
  const flexibleFilters = buildFlexibleTagsList(currentUser, toggleTypes, strict, allowedTagTypes);
  return map(flexibleFilters, (item) => ({
    type: 'model_select',
    label: item.label,
    value: get(filters, item.filter_field_name, null),
    multiple: true,
    sortOptions: true,
    queryName: 'tags',
    queryParams: { tag_type: item.filter_field_name },
    onChange: (newValue) => updateFilter({ [item.filter_field_name]: newValue }),
    filterName: item.filter_field_name,
  }));
};

interface FilterPillDef {
  id: string;
  value: string;
  label: string;
  icon: string;
  variant: string;
  filterName: string;
}

interface GetLearningTypesPillsDefProps {
  values: string[];
  learningTypesOptions: Option[];
  filterName: string;
}

export const getLearningTypesPillsDef = ({
  values,
  learningTypesOptions,
  filterName,
}: GetLearningTypesPillsDefProps): FilterPillDef[] => {
  return map(values, (learning_type) => {
    const item = find(learningTypesOptions, ['value', learning_type]);
    const label = get(item, 'name', '');
    return {
      id: `learning_type-${learning_type}`,
      value: learning_type,
      label,
      icon: LEARNING_TYPE_ICONS_MAPPING[learning_type],
      variant: `learningType${learning_type}`,
      filterName,
    };
  });
};

type FilterOrderName =
  | 'filter_order_catalog'
  | 'filter_order_events'
  | 'filter_order_mentees'
  | 'filter_order_mentors'
  | 'filter_order_my_upcoming'
  | 'filter_order_people';

interface GetOrderedFiltersDefinitionProps {
  currentUser: User;
  filterOrderName: FilterOrderName;
  // By default, the flexible filters will be added to the end of this list, so it is not necessary to define them.
  defaultFiltersOrder: string[];
  allFilters: Record<string, FilterDef>;
  filterWidth?: string | number;
  fixedFilters?: string[];
  numberOfFiltersShownOutside?: number;
  includeFilterChannels?: boolean;
}

export const getOrderedFiltersDefinition = ({
  currentUser,
  filterOrderName,
  defaultFiltersOrder,
  allFilters,
  filterWidth = '18%',
  fixedFilters,
  numberOfFiltersShownOutside = 2,
  includeFilterChannels = false,
}: GetOrderedFiltersDefinitionProps) => {
  const flexibleFiltersNames = map(get(currentUser, 'custom_tags.flexible_filter_tags'), (tag) =>
    get(tag, 'filter_field_name', '')
  );

  const configuredFiltersOrder = filter(
    split(get(currentUser, `filters_order.${filterOrderName}`, ''), ','),
    (filterName) => !isEmpty(filterName)
  );

  const filtersOrder: string[] = map(
    isEmpty(configuredFiltersOrder)
      ? [...defaultFiltersOrder, ...flexibleFiltersNames]
      : configuredFiltersOrder,
    trim
  );

  // TODO: Remove the condition once all content items are migrated to the Default Channel.
  if (includeFilterChannels) {
    filtersOrder.push('Channels');
  }

  // Search, Sort, and Flexible Filters are added automatically.
  const barFilters: string[] = [
    'SearchBar',
    ...slice(filtersOrder, 0, numberOfFiltersShownOutside),
    'Sort',
  ];
  // Filters not shown outside will be shown inside 'More filters' popper
  const moreFilters: string[] = slice(filtersOrder, numberOfFiltersShownOutside);

  return {
    filters: filter(
      map(barFilters, (label) =>
        has(allFilters, label)
          ? {
              ...allFilters[label],
              ...(!includes(['SearchBar', 'Sort'], label) ? { width: filterWidth } : {}),
            }
          : null
      ),
      (item) => !isNil(item)
    ) as FilterDef[],
    moreFilters: filter(
      map(concat(fixedFilters || [], moreFilters), (label) =>
        has(allFilters, label) ? { ...allFilters[label], width: 300 } : null
      ),
      (item) => !isNil(item)
    ) as FilterDef[],
  };
};

// In some situations, you may need to control some components using the URL,
// but the data used to control this element is not necessary to fetch the data,
// so the best thing to do is to remove this field before making the call to avoid
// it interfering in the caching.
export const excludeFieldFromRQLExpression = (rqlExpression: string, field: string): string => {
  const rqlObject = omit(rqlExpressionToObject(rqlExpression), [field]);
  return rql(
    has(rqlObject, '$and')
      ? {
          ...rqlObject,
          $and: filter(get(rqlObject, '$and'), (item) => head(keys(item)) !== field),
        }
      : rqlObject
  );
};

export function cleanRQLExpression(rqlExpression: string, values: string[] = []) {
  let cleanedRQLExpression = rqlExpression;

  // Iterate over all values and exclude them from the RQL expression
  forEach(values, (value) => {
    cleanedRQLExpression = excludeFieldFromRQLExpression(cleanedRQLExpression, value);
  });

  return cleanedRQLExpression;
}

interface DateRangeInput {
  startDate: string;
  endDate: string;
}

// {$range: {min: '2022-07-01', max: '2022-07-31' } => {startDate: '2022-07-01', endDate: '2022-07-31'}
export const dateRangeRQLToDateRangeInput = (value?: RQLDateRange | null): DateRangeInput => {
  if (isNil(value)) return { startDate: '', endDate: '' };
  const op = head(keys(value));
  if (op === '$range')
    return { startDate: get(value[op], 'min', ''), endDate: get(value[op], 'max', '') };
  if (op === '$gt') return { startDate: value[op] as string, endDate: '' };
  if (op === '$lt') return { startDate: '', endDate: value[op] as string };
  return { startDate: '', endDate: '' };
};

// {startDate: '2022-07-01', endDate: '2022-07-31'} => {$range: {min: '2022-07-01', max: '2022-07-31'}
export const dateRangeInputToDateRangeRQL = ({
  startDate,
  endDate,
}: DateRangeInput): RQLDateRange | null => {
  if (!isEmpty(startDate) && !isEmpty(endDate)) return { $range: { min: startDate, max: endDate } };
  if (!isEmpty(startDate)) return { $gt: startDate as string };
  if (!isEmpty(endDate)) return { $lt: endDate as string };
  return null;
};

type DateRangeInputFormat = 'relative' | 'exact';

interface GetDateRangeDateRangeInputFormatProps {
  startDate: string;
  endDate: string;
}

export const getDateRangeDateRangeInputFormat = ({
  startDate,
  endDate,
}: GetDateRangeDateRangeInputFormatProps): DateRangeInputFormat | null => {
  if (startDate && includes(['P', '-'], startDate[0])) return 'relative';
  if (endDate && includes(['P', '-'], endDate[0])) return 'relative';
  if (startDate || endDate) return 'exact';
  return null;
};

interface GetDateRangeTextProps {
  startDate: string;
  endDate: string;
  format: DateRangeInputFormat;
  defaultMessage?: string;
}

export const getDateRangeText = ({
  startDate,
  endDate,
  format,
  defaultMessage = '',
}: GetDateRangeTextProps): string => {
  if (format === 'relative') {
    return formatFlexibleDateRangeInputText(format)(startDate, endDate) || defaultMessage;
  }
  if (format === 'exact') {
    return (
      formatFlexibleDateRangeInputText(format)(
        startDate ? moment(startDate) : null,
        endDate ? moment(endDate) : null
      ) || defaultMessage
    );
  }
  return defaultMessage;
};
