import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 0 24px 0;
`;

const Header = styled.h1`
  font-size: 28px;
  color: #92969a;
`;

const SubText = styled.h2`
  font-size: 16px;
  color: #424242;
`;

const PageHeader = ({ children, subText }) => (
  <Container>
    <Header>{children}</Header>
    <SubText>{subText}</SubText>
  </Container>
);

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subText: PropTypes.string,
};

export default PageHeader;
