import moment from 'moment-timezone';

import { CONTENT_TYPES } from 'catalog/constants';
import { findIndex, slice, size, get, split, some, every } from 'vendor/lodash';

function stripTimezoneFromDatetime(datetime) {
  return get(split(datetime, 'T'), '0', null);
}

/**
 * Helper funcion to compare dates with datetimes
 *
 * If both items are events, it will return each datetimes
 * Otherwise, it will transform any datetime into a date object and
 * return granulatiry === 'day'
 *
 * @param {*} mainItem
 * @param {*} targetItem
 * @returns an object containing the dates/datetimes to be used when comparing each entry,
 * as well as the granularity param
 */
export function getItemDatesForComparison(mainItem, targetItem) {
  const { content_item: mainContentItem } = mainItem;
  const { content_item: targetContentItem } = targetItem;

  if (
    mainContentItem.content_type === CONTENT_TYPES.event &&
    targetContentItem.content_type === CONTENT_TYPES.event
  ) {
    return {
      base: moment(mainContentItem.starts_at_tz_aware),
      target: moment(targetContentItem.starts_at_tz_aware),
      granularity: null,
    };
  }

  if (mainContentItem.content_type === CONTENT_TYPES.event) {
    return {
      base: moment(stripTimezoneFromDatetime(mainContentItem.starts_at_tz_aware)),
      target: moment(targetItem.assignment_due_date),
      granularity: 'day',
    };
  }

  if (targetContentItem.content_type === CONTENT_TYPES.event) {
    return {
      base: moment(mainItem.assignment_due_date),
      target: moment(stripTimezoneFromDatetime(targetContentItem.starts_at_tz_aware)),
      granularity: 'day',
    };
  }
  return {
    base: moment(mainItem.assignment_due_date),
    target: moment(targetItem.assignment_due_date),
    granularity: 'day',
  };
}

/**
 * Helper function to check if a track item is in chronological order.
 * This works by checking if the item is after all items before
 * it and before all items after it.
 */
export function checkIfItemIsInChronologicalOrder(item, allItems) {
  const itemIdx = findIndex(
    allItems,
    (entry) => get(entry, 'content_item.public_id') === get(item, 'content_item.public_id')
  );

  const allItemsBefore = slice(allItems, 0, itemIdx);

  const hasSomeItemBeforeInvalid = some(allItemsBefore, (targetItem) => {
    const { base, target, granularity } = getItemDatesForComparison(item, targetItem);
    return base.isBefore(target, granularity);
  });

  if (hasSomeItemBeforeInvalid) {
    return false;
  }

  const allItemsAfter = slice(allItems, itemIdx + 1, size(allItems));

  const hasSomeItemAfterInvalid = some(allItemsAfter, (targetItem) => {
    const { base, target, granularity } = getItemDatesForComparison(item, targetItem);

    return base.isAfter(target, granularity);
  });

  return !hasSomeItemAfterInvalid;
}

export function checkIfAllItemsAreInChronologicalOrder(items) {
  return every(items, (item) => checkIfItemIsInChronologicalOrder(item, items));
}

/**
 * Helper function to check if an item is in the date range
 * of a scheduled track.
 *
 * @returns {boolean} true if is in the date range, false otherwise
 */
export function checkIfItemIsInDateRange(trackItem, trackStartsAt, trackEndsAt) {
  const contentType = get(trackItem, 'content_item.content_type');
  if (contentType === CONTENT_TYPES.event) {
    // track start/stop is a datetime but the UI only exposes the date part.
    // But if track.start_time (set at creation) is after item.start_time we show an error
    // for comparison purposes we should look at
    // start.date < item.start_time && stop.date > item.stop_time
    const itemStartsAt = moment(get(trackItem, 'content_item.starts_at_tz_aware'));
    const itemEndsAtDateOnly = moment(get(trackItem, 'content_item.ends_at_tz_aware')).startOf(
      'day'
    );
    const trackStartsAtDateOnly = moment(trackStartsAt).startOf('day');
    return (
      itemStartsAt.isSameOrAfter(trackStartsAtDateOnly) &&
      itemEndsAtDateOnly.isSameOrBefore(trackEndsAt)
    );
  }

  const itemDueDate = moment(get(trackItem, 'assignment_due_date'));
  return itemDueDate.isSameOrAfter(trackStartsAt) && itemDueDate.isSameOrBefore(trackEndsAt);
}

/**
 * Helper function to check if all items are in the date range
 * of a scheduled track.
 *
 * @returns {boolean} true if all items are in the date range, false otherwise
 */
export function checkIfAllItemsAreInDateRange(trackItems, trackStartsAt, trackEndsAt) {
  return every(trackItems, (item) => checkIfItemIsInDateRange(item, trackStartsAt, trackEndsAt));
}
