import actions from 'entities/actions';
import { useArchiveUnarchive } from 'entities/utils';
import { Event } from 'event/interfaces';
import colors from 'services/colors';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { useLabels } from 'shared/hooks';
import { get } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

interface EventArchiveUnarchiveModalProps {
  event: Event;
  handleClose: () => void;
}

const EventArchiveUnarchiveModal = ({
  event: { is_archived, public_id, name, event_type },
  handleClose,
}: EventArchiveUnarchiveModalProps) => {
  const actionLabel = is_archived ? 'Restore' : 'Cancel';
  const noteLabel = is_archived ? 'restored' : 'canceled';

  const { label_event_type: labelEventType } = useLabels();

  const eventArchiveLabels = {
    ARCHIVE_ACTION_LABEL: 'Canceling...',
    UNARCHIVE_ACTION_LABEL: 'Restoring...',
  };

  const { archiveItem, unarchiveItem, isUpdating } = useArchiveUnarchive(
    actions.event.archive,
    eventArchiveLabels
  );

  const handleDeleteClick = () => {
    const action = is_archived ? unarchiveItem : archiveItem;
    action(public_id, {
      update_note: `You received this message due to the event being ${noteLabel}.`,
    });
    handleClose();
  };

  const limitDoubleBooking = get(event_type, 'limit_double_booking');

  return (
    <Modal handleClose={handleClose} title={`${actionLabel} event`}>
      <ModalBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
          <Typography variant="body2" sx={{ color: colors.neutral900 }}>
            You are about to {`${is_archived ? 'restore' : 'cancel'}`} the &quot;{name}&quot; event.
            This will notify the facilitators and participants.
          </Typography>
          {!is_archived && limitDoubleBooking && (
            <Typography variant="body2">
              Please note that archiving this event will not remove enrollments. As this event has a
              parent {labelEventType} with &quot;Prevent enrollment in multiple upcoming events from
              this {labelEventType}&quot; turned on, archiving it without removing the enrollments
              may prevent these users from enrolling in other events of the same {labelEventType}.
              If you wish to prevent this, please manually unenroll users from this event before
              canceling it.
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: colors.neutral900 }}>
            Are you sure you want to proceed?
          </Typography>
        </Box>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Close</ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={handleDeleteClick}
          disabled={isUpdating}
          aria-label={`${actionLabel} event`}
        >
          {`${actionLabel} event`}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default EventArchiveUnarchiveModal;
