import PropTypes from 'prop-types';
import React from 'react';

import { sendExceptionToSentry } from 'services/utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    sendExceptionToSentry(error, errorInfo);
  }

  render() {
    const { children, errorMessage = null } = this.props;
    const { error } = this.state;

    if (error) {
      const defaultMessage =
        'Our team has been notified, but feel free to contact us at support@plusplus.co';
      const message = errorMessage || defaultMessage;
      return (
        <div style={{ textAlign: 'center' }}>
          <h2>We are sorry — something has gone wrong.</h2>
          <p>{message}</p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  errorMessage: PropTypes.string,
};

export default ErrorBoundary;
