import React, { useEffect } from 'react';

import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Page404 from 'scenes/404';
import EventDetailPage from 'scenes/EventDetail/EventDetailPage';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import Loading from 'shared/components/Loading';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from 'shared/constants';
import { useSetDetailedObject, usePublicIdFromURL } from 'shared/hooks';

const EventDetail = () => {
  const { publicId } = usePublicIdFromURL();
  const { trackActivity } = useMetrics();

  const [fetchEvent, { data: event, status: fetchEventStatus, statusCode }] = useEntities(
    actions.event.retrieveDetails,
    ({ status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.EVENT_VIEW, {
          eventId: event.id,
        });
        trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
          contentItemType: 'event',
          contentItemId: event.id,
        });
      }
    },
    {
      schema: eventSchema,
    }
  );

  useEffect(() => {
    fetchEvent(publicId);
  }, [publicId]);

  useSetDetailedObject({ id: publicId }, 'events', [publicId]);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (fetchEventStatus !== STATUS_DONE) return <Loading />;
  return <EventDetailPage event={event} />;
};

export default EventDetail;
