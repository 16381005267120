import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';
import { getEarliestTimeslot } from 'event-shared/services';
import { displayDatetime } from 'services/datetime';
import { useCurrentUser } from 'shared/hooks';
import { map } from 'vendor/lodash';

import LazyDropdownFilter from './LazyDropdownFilter';

const EventFilter = ({ label, param, ...props }) => {
  const { timezone: userTimezone } = useCurrentUser();

  const formatOptions = (options) =>
    map(options, (option) => {
      const formattedDatetime = displayDatetime(
        getEarliestTimeslot(option).starts_at_tz_aware,
        option.timezone,
        userTimezone,
        option.is_online
      );

      return {
        name: `${option.name} - ${formattedDatetime}`,
        value: option.value,
      };
    });

  return (
    <LazyDropdownFilter
      label={label}
      param={param}
      actionFunc={actions.event.retrieveList}
      optionsFormatter={formatOptions}
      {...props}
    />
  );
};

EventFilter.propTypes = {
  label: PropTypes.string,
  param: PropTypes.string,
};

EventFilter.defaultProps = {
  label: 'Events',
  param: 'event',
};

export default EventFilter;
