import styled from 'styled-components';

import { useContentTypeRoutes } from 'app/catalog/hooks';
import {
  ENROLLMENT_CHANGE_CALENDAR_SOURCE,
  ENROLLMENT_CHANGE_INTEGRATION_SOURCE,
} from 'app/event-shared/constants';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { get, startsWith, isEmpty, isNull, upperFirst, toLower, isObject } from 'vendor/lodash';
import { Link } from 'vendor/mui';

const isAsyncJob = (action: string) => {
  return startsWith(action, 'async_job');
};

const TextArea = styled.span`
  color: ${colors.neutral600};
`;

export const getSourceMessage = (log) => {
  switch (log.source) {
    case ENROLLMENT_CHANGE_CALENDAR_SOURCE:
      return 'Action automatically performed by calendar integration.';
    case ENROLLMENT_CHANGE_INTEGRATION_SOURCE:
      return 'Action automatically performed by external integration.';
    default:
      return null;
  }
};

const getUserRouteById = (id) => mapRoute('userProfile', { id: id });

interface LinkProps {
  log: any;
}

export const UserLink = ({ log }: LinkProps) => {
  if (log?.user) {
    if (log.user?.name === 'Inactive user') {
      return <TextArea>{log.user.name}</TextArea>;
    }
    return (
      <Link href={getUserRouteById(log.user.id)} variant="body2" noWrap>
        {log.user.name}
      </Link>
    );
  }
  return <TextArea>System</TextArea>;
};

export const ActorLink = ({ log }: LinkProps) => {
  if (log?.actor) {
    if (log.actor?.name === 'Inactive user') {
      return <TextArea>{log.actor.name}</TextArea>;
    }
    return (
      <Link href={getUserRouteById(log.actor.id)} variant="body2" noWrap>
        {log.actor.name}
      </Link>
    );
  }
  return <TextArea>System</TextArea>;
};

export const FakeResourceLink = ({ resource_type, public_id_and_slug, name }: any) => {
  const contentTypeRoutes = useContentTypeRoutes();
  const resourceName = name ? name : '-';
  if (isEmpty(public_id_and_slug)) {
    return resourceName;
  }
  const resourceUrl = contentTypeRoutes[resource_type]?.details?.({
    public_id_and_slug: public_id_and_slug,
  });

  if (isNull(resourceUrl)) {
    return resourceName;
  }

  return (
    <Link href={resourceUrl} variant="body2" noWrap>
      {' '}
      {resourceName}{' '}
    </Link>
  );
};

export const ResourceLink = ({ log }: LinkProps) => {
  const contentTypeRoutes = useContentTypeRoutes();
  const name = log?.resource?.name ? log.resource.name : '-';
  if (isEmpty(log.resource)) {
    if (isEmpty(log.user)) return '-';
    return <UserLink log={log} />;
  }
  if (isEmpty(log.resource.public_id_and_slug)) {
    return name;
  }

  const resourceUrl = contentTypeRoutes[log.resource_type]?.details?.({
    public_id_and_slug: log.resource.public_id_and_slug,
  });

  if (isNull(resourceUrl)) {
    return name;
  }
  return (
    <Link href={resourceUrl} variant="body2" noWrap>
      {' '}
      {name}{' '}
    </Link>
  );
};

const actionMapping = {
  event_enrollment_change: (log) => {
    const userId = get(log, 'user.id', null);
    const actorId = get(log, 'actor.id', null);

    if (userId === actorId) {
      return (
        <>
          <UserLink log={log} /> changed their enrollment status to {`"${log.data}"`}.{' '}
          {getSourceMessage(log)}
        </>
      );
    }
    return (
      <>
        <ActorLink log={log} /> changed <UserLink log={log} /> enrollment status to{' '}
        {`"${log.data}"`}. {getSourceMessage(log)}
      </>
    );
  },
  event_enrollment_switch: (log) => (
    <>
      <ActorLink log={log} /> switched the enrollment method, updating <UserLink log={log} /> status
      to status to {`"${log.data}"`} . {getSourceMessage(log)}
    </>
  ),
  event_enrollment_merge: (log) => (
    <>
      <ActorLink log={log} /> merged the enrollment method, updating <UserLink log={log} /> status
      to status to {`"${log.data}"`} . {getSourceMessage(log)}
    </>
  ),
  event_enrollment_promotion: (log) => (
    <>
      <UserLink log={log} /> was promoted to {`"${log.data}"`} from the waitlist.{' '}
      {getSourceMessage(log)}
    </>
  ),
  event_enrollment_adjust: (log) => (
    <>
      {'Due to a change in the event capacity, '}
      <UserLink log={log} />
      {` enrollment status was changed to "${log.data}"`}. {getSourceMessage(log)}
    </>
  ),
  event_notification: (log) => (
    <>
      A {`"${log.data}"`} notification was sent. {getSourceMessage(log)}
    </>
  ),
  event_survey_notification: (log) => (
    <>
      A {`"${log.data}"`} survey notification was sent. {getSourceMessage(log)}
    </>
  ),
  event_survey_reminder: (log) => (
    <>
      A {`"${log.data}"`} survey reminder was sent. {getSourceMessage(log)}
    </>
  ),
  event_create: (log) => (
    <>
      Event was created by <ActorLink log={log} />. {getSourceMessage(log)}
    </>
  ),
  recurring_event_create: (log) => (
    <>
      Recurring event was created by <ActorLink log={log} />. {getSourceMessage(log)}
    </>
  ),
  event_edit: (log) => (
    <>
      Event was edited by <ActorLink log={log} />. {getSourceMessage(log)}
    </>
  ),
  event_cap_increase: (log) => (
    <>
      The event enrollment cap was increased to {log.data} to accomodate user added by{' '}
      <ActorLink log={log} />. {getSourceMessage(log)}
    </>
  ),
  async_job: (log) => (
    <>
      <ActorLink log={log} /> did a {log.data?.replaceAll('_', ' ')}.{getSourceMessage(log)}
    </>
  ),
  sendgrid_event_received: (log) => (
    <>
      <ActorLink log={log} /> received a {log.metadata?.event} event for the email{' '}
      {log.metadata.email} with the notification id {log.metadata?.anymail_id}.
      {getSourceMessage(log)}
    </>
  ),
  calendar_sync: (log) => {
    try {
      const event = JSON.parse(log.metadata.event);
      if (event && isObject(event)) {
        return (
          <>
            <ActorLink log={log} /> did a calendar sync for the event
            <FakeResourceLink
              name={(event as any).name}
              resource_type="event"
              public_id_and_slug={(event as any).public_id}
            />{' '}
            and user <UserLink log={log} /> with the timeslot id {log.data}.{getSourceMessage(log)}
          </>
        );
      }
    } catch (e) {
      return defaultMessageGetter(log);
    }
  },
  customer_change: (log) => (
    <>
      <ActorLink log={log} /> updated the settings.{getSourceMessage(log)}
    </>
  ),
};

const defaultMessageGetter = (log) => {
  const action = log.action ? toLower(log.action.replaceAll('_', ' ')) : '';
  const target = log.resource_type ? toLower(log.resource_type.replaceAll('_', ' ')) : '';
  return (
    <>
      <>
        <ActorLink log={log} /> performed{' '}
      </>
      {`${action} action affecting `}
      <>
        {target}: {<ResourceLink log={log} />}{' '}
      </>
      <>
        user: <UserLink log={log} />{' '}
      </>
      {log.data && <>with value {log.data} </>}
    </>
  );
};

export const getMessage = (log) => {
  let parsedAction: string = toLower(log.action);
  if (isAsyncJob(parsedAction)) {
    parsedAction = 'async_job';
  }
  const messageGetter = (log) => {
    if (!actionMapping[parsedAction]) {
      return defaultMessageGetter(log);
    }
    try {
      return actionMapping[parsedAction](log);
    } catch (error) {
      return defaultMessageGetter(log);
    }
  };
  return messageGetter(log);
};

const humanize = (value: any): any => {
  if (isEmpty(value)) {
    return value;
  }
  return upperFirst(toLower(value.toString().replaceAll('_', ' ')));
};

export const getAction = (log: any): any => {
  const action: string = toLower(log.action);
  if (isAsyncJob(action)) {
    return humanize(log.metadata['kind']);
  } else if (action == 'customer_change') {
    return 'Settings update';
  }
  return humanize(action);
};

export const getAdditionalData = (log: any): any[] | null | undefined => {
  if (!log) {
    return Object.entries({});
  }
  const action: string = toLower(log.action);
  const data = { 'result(changed data)': log.data };
  const metadata = isNull(log.metadata) ? {} : log.metadata;
  let additionalMetadata =
    isNull(log.non_searchable_metada) || log.non_searchable_metada === 'null'
      ? {}
      : JSON.parse(log.non_searchable_metada);
  additionalMetadata = { ...data, ...metadata, ...additionalMetadata };
  if (isAsyncJob(action)) {
    additionalMetadata = { ...additionalMetadata, job_status: action };
  }
  return Object.entries(additionalMetadata);
};
