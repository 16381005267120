import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import type { ValueOf } from 'type-fest';

import { useLabels, useFormSelector } from 'app/shared/hooks';
import colors from 'services/colors';
import { getUID } from 'services/utils';
import { filter, findIndex, isEmpty, map, toLower } from 'vendor/lodash';
import { Box, Button, Tab, Tabs, Typography } from 'vendor/mui';

import { AssessmentQuestionChoiceInput } from './AssessmentQuestionChoiceInput';

type Options = {
  id: string;
  value: string;
  content_body: string;
  feedback_text: string;
  selected: boolean;
  has_answer?: boolean;
  is_archived?: boolean;
}[];

export interface MultipleChoicesProps {
  form: string;
  setOptions: (newOptions: Options) => void;
}

const TAB_STATES = {
  live: 'LIVE',
  archived: 'ARCHIVED',
} as const;

type tabTypes = ValueOf<typeof TAB_STATES>;

export const AssessmentQuestionMultipleChoicesInput = ({
  form,
  setOptions,
}: MultipleChoicesProps) => {
  const options: Options = useFormSelector(form, 'option_set');
  const [liveOptions, setLiveOptions] = useState<Options>([]);
  const [archivedOptions, setArchivedOptions] = useState<Options>([]);
  const [dirty, setDirty] = useState(false);
  const [visibleTab, setVisibleTab] = useState<tabTypes>(TAB_STATES.live);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );
  const { label_choice: labelChoice, label_choice_plural: labelChoicePlural } = useLabels();

  useEffect(() => {
    setLiveOptions(filter(options, (option) => !option.is_archived));
    const archived = filter(options, (option) => !!option.is_archived);
    setArchivedOptions(archived);
    if (visibleTab === TAB_STATES.archived && isEmpty(archived)) {
      setVisibleTab(TAB_STATES.live);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeId = findIndex(options, (c) => c.id === active.id);
      const overId = findIndex(options, (c) => c.id === over.id);

      const newArray = arrayMove(options, activeId, overId);
      setOptions(newArray);
    }
  };

  const addChoice = () => {
    setDirty(true);
    setOptions([
      ...options,
      { id: getUID(), value: '', content_body: '', feedback_text: '', selected: false },
    ]);
  };

  const updateOptionText = (id: string, newValue: string) => {
    const index = findIndex(options, (choice) => choice.id === id);

    const newOptions = [...options];

    newOptions[index].content_body = newValue;

    setOptions(newOptions);
  };

  const updateOptionFeedback = (id: string, newValue: string) => {
    const index = findIndex(options, (choice) => choice.id === id);

    const newOptions = [...options];

    newOptions[index].feedback_text = newValue;

    setOptions(newOptions);
  };

  const updateSelectedOption = (id: string) => {
    const newOptions = [...options];
    const index = findIndex(options, (choice) => choice.id === id);
    const previousSelectedIndex = findIndex(options, (choice) => choice.selected);

    if (previousSelectedIndex >= 0) options[previousSelectedIndex].selected = false;
    newOptions[index].selected = true;

    setOptions(newOptions);
  };

  const removeOption = (id: string) => {
    if (liveOptions.length <= 1) return;

    const index = findIndex(options, (choice) => choice.id === id);
    if (index > -1) {
      const [removedOption] = options.splice(index, 1);
      if (removedOption.selected) {
        if (index === options.length) {
          options[index - 1].selected = true;
        } else {
          options[index].selected = true;
        }
      }
    }

    return setOptions([...options]);
  };

  const createUpdateOptionIsArchived = (id) => (isArchived: boolean) => {
    const index = findIndex(options, (choice) => choice.id === id);
    const newOptions = [...options];

    if (index >= 0) {
      const [option] = newOptions.splice(index, 1, {
        ...newOptions[index],
        is_archived: isArchived,
        selected: false,
      });

      if (option.selected) {
        const liveIndex = findIndex(liveOptions, (choice) => choice.id === id);
        if (liveIndex >= 0) {
          liveOptions.splice(liveIndex, 1);
          const newSelectedLiveIndex = liveIndex === liveOptions.length ? liveIndex - 1 : liveIndex;
          const newSelectedIndex = findIndex(
            newOptions,
            (choice) => choice.id === liveOptions[newSelectedLiveIndex].id
          );
          newOptions[newSelectedIndex].selected = true;
        }
      }

      setOptions([...newOptions]);
    }
  };

  const a11yProps = (state: string) => {
    return {
      id: `options-tab-${state}`,
      'aria-controls': `options-tabpanel-${state}`,
    };
  };

  return (
    <Box>
      {archivedOptions.length > 0 && (
        <Box margin={2} marginBottom={0}>
          <Tabs value={visibleTab}>
            <Tab
              value={TAB_STATES.live}
              onClick={() => setVisibleTab(TAB_STATES.live)}
              label={`${labelChoicePlural} (${liveOptions.length})`}
              {...a11yProps('live')}
            />
            <Tab
              value={TAB_STATES.archived}
              onClick={() => setVisibleTab(TAB_STATES.archived)}
              label={`Archived (${archivedOptions.length})`}
              {...a11yProps('archived')}
            />
          </Tabs>
        </Box>
      )}
      {visibleTab === TAB_STATES.live ? (
        <Box display="flex" width="100%" padding={2} paddingBottom={0}>
          <Box flex={1}>
            <Typography variant="subtitle2" color={colors.neutral600} paddingTop="6px">
              Add possible {labelChoicePlural} and select the correct one.
            </Typography>
            <Typography variant="subtitle2" color={colors.neutral600} paddingTop="6px">
              The feedback content will show up after its respective option is chosen and submitted.
            </Typography>
          </Box>
          <Box>
            <Button color="primary" onClick={() => addChoice()}>
              + Add {labelChoice}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display="flex" width="100%" padding={2} paddingBottom={0} height="52.5px">
          <Box flex={1}>
            <Typography variant="subtitle2" color={colors.neutral600} paddingTop="6px">
              Archived {toLower(labelChoicePlural)} are hidden. They cannot be deleted as they
              contain information from previous submissions.
            </Typography>
          </Box>
        </Box>
      )}
      <Box marginTop={2} marginBottom={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => handleDragEnd(event)}
        >
          <SortableContext
            items={visibleTab === TAB_STATES.live ? liveOptions : archivedOptions}
            strategy={verticalListSortingStrategy}
          >
            {map(
              visibleTab === TAB_STATES.live ? liveOptions : archivedOptions,
              (option, index) => (
                <AssessmentQuestionChoiceInput
                  key={option.id}
                  index={index}
                  id={option.id}
                  value={option.content_body}
                  feedback={option.feedback_text}
                  selected={option.selected}
                  disableRemoveChoice={liveOptions.length <= 1}
                  autoFocus={dirty}
                  hasAnswer={option.has_answer}
                  isArchived={option.is_archived}
                  addChoice={() => addChoice()}
                  updateSelectedChoice={() => updateSelectedOption(option.id)}
                  updateChoice={(value) => updateOptionText(option.id, value)}
                  updateChoiceFeedback={(value) => updateOptionFeedback(option.id, value)}
                  updateChoiceIsArchived={createUpdateOptionIsArchived(option.id)}
                  removeChoice={() => removeOption(option.id)}
                />
              )
            )}
          </SortableContext>
        </DndContext>
      </Box>
    </Box>
  );
};

export default AssessmentQuestionMultipleChoicesInput;
