import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { buildRegExpFromDelimiters } from 'services/utils';
import Pill from 'shared/components/Pill';
import { useOnClickOutside } from 'shared/hooks';
import { forEach, includes, map, split, uniq, slice, isEmpty, pull } from 'vendor/lodash';

export const KEYS = {
  NEWLINE: 10,
  CR: 13,
  BACKSPACE: 8,
  COMMA: 188,
  SPACE: 32,
};

const TagsWrapper = styled.div`
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
`;

const PillWrapper = styled.div`
  margin: 2px 4px 2px 0;
`;

const Input = styled.input`
  margin-left: 4px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutral900};
  flex: 1 1 auto;
  border: 0;
  padding: 0;
  outline-width: 0;
`;

const InputWrapper = styled.div`
  border: 1px solid ${colors.neutral400};
  border-radius: 2px;
  max-height: 128px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 32px;

  :focus-within {
    border-color: ${colors.action600};
  }
`;

const TagsInput = ({
  tags,
  delimiters,
  labelField,
  handleAddition,
  handleDeletion,
  placeholder,
  tagLimit,
}) => {
  const [tagValue, setTagValue] = useState('');

  const inputEl = useRef(null);

  const addTag = (tagToBeAdded) => {
    if (isEmpty(tagToBeAdded)) {
      return;
    }
    setTagValue('');
    handleAddition(tagToBeAdded);
  };

  const handleChange = (e) => {
    setTagValue(e.target.value);
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const clipboardText = clipboardData.getData('text');

    const delimiterRegExp = buildRegExpFromDelimiters(delimiters);
    let tagsToBeAdded = pull(split(clipboardText, delimiterRegExp), (item) => isEmpty(item));

    // Checks if we can add some of the tags even if the total sum surpasses the limit
    if (tagLimit && tags.length + tagsToBeAdded.length > tagLimit) {
      if (tags.length < tagLimit) {
        const extraTagsLimit = tagLimit - tags.length;
        tagsToBeAdded = slice(tagsToBeAdded, 0, extraTagsLimit);
      } else {
        tagsToBeAdded = [];
      }
    }

    // Only add unique tags
    forEach(uniq(tagsToBeAdded), (tag, index) => {
      if (tagLimit && index > tagLimit - 1) {
        return;
      }
      addTag(tag);
    });
  };

  const handleDelete = (i, e) => {
    handleDeletion(i, e);
    inputEl.current.focus();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === KEYS.BACKSPACE && tagValue === '') {
      handleDelete(tags.length - 1, e);
    }

    if (includes(delimiters, e.keyCode) && !e.shiftKey) {
      if (e.keyCode !== KEYS.TAB) {
        e.preventDefault();
      }
      if (tagLimit && tags.length > tagLimit) {
        return;
      }
      addTag(tagValue);
    }
  };

  useOnClickOutside(inputEl, () => addTag(tagValue));

  return (
    <InputWrapper>
      <TagsWrapper onClick={() => inputEl.current.focus()}>
        {map(tags, (tag, i) => (
          <PillWrapper key={tag.id}>
            <Pill
              label={labelField ? tag[labelField] : tag}
              onEdit={(e) => handleDelete(i, e)}
              labelMaxWidth={250}
            />
          </PillWrapper>
        ))}
        <Input
          ref={inputEl}
          value={tagValue}
          onChange={handleChange}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      </TagsWrapper>
    </InputWrapper>
  );
};

TagsInput.propTypes = {
  delimiters: PropTypes.arrayOf(PropTypes.number),
  labelField: PropTypes.string,
  tags: PropTypes.array.isRequired,
  handleAddition: PropTypes.func.isRequired,
  handleDeletion: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  tagLimit: PropTypes.number,
};

TagsInput.defaultProps = {
  delimiters: [KEYS.NEWLINE, KEYS.CR, KEYS.COMMA, KEYS.SPACE],
};

export default TagsInput;
