import React, { useContext } from 'react';

import { ExtraSettingsContext } from 'app/settings/context-providers/ExtraSettingsContext';
import ButtonLink from 'app/shared/components/ButtonLink';

const IcalLinkField = () => {
  const { icalUrl } = useContext(ExtraSettingsContext);

  return <ButtonLink url={icalUrl}>Download ICS file</ButtonLink>;
};

export default IcalLinkField;
