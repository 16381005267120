/* @flow */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './style.scss';

export class DropdownOption extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.setHighlighted = this.setHighlighted.bind(this);
    this.resetHighlighted = this.resetHighlighted.bind(this);
  }

  onClick(event) {
    const { onSelect, onClick, value, disabled } = this.props;
    if (!disabled) {
      if (onSelect) {
        onSelect(value);
      }
      if (onClick) {
        event.stopPropagation();
        onClick(value);
      }
    }
  }

  setHighlighted() {
    const { setHighlighted, index } = this.props;
    setHighlighted(index);
  }

  resetHighlighted() {
    const { setHighlighted } = this.props;
    setHighlighted(-1);
  }

  render() {
    const {
      children,
      active,
      disabled,
      highlighted,
      className,
      activeClassName,
      disabledClassName,
      highlightedClassName,
      title,
    } = this.props;
    /* eslint-disable jsx-a11y/onclick-has-role */
    return (
      <li
        tabIndex="0"
        className={classNames('rdw-dropdownoption-default', className, {
          [`rdw-dropdownoption-active ${activeClassName}`]: active,
          [`rdw-dropdownoption-highlighted ${highlightedClassName}`]: highlighted,
          [`rdw-dropdownoption-disabled ${disabledClassName}`]: disabled,
        })}
        onMouseEnter={this.setHighlighted}
        onMouseLeave={this.resetHighlighted}
        onClick={this.onClick}
        title={title}
      >
        {children}
      </li>
    );
    /* eslint-enable jsx-a11y/onclick-has-role */
  }
}

DropdownOption.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  setHighlighted: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  highlighted: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  highlightedClassName: PropTypes.string,
  title: PropTypes.string,
};

export default DropdownOption;
