import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';

const StyledName = styled.span`
  color: ${colors.emphasis600};
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 0 3px;
  cursor: pointer;
  border-radius: 2px;
`;

function CardCreatorName(props) {
  const { className, creators, singleCreatorTitle, multipleCreatorsTitle } = props;

  const numberOfCreators = creators?.length ?? 0;
  let creatorName = '';

  if (numberOfCreators === 1) {
    const name = creators[0].user?.name ? creators[0].user.name : creators[0].name;
    creatorName = `${singleCreatorTitle}: ${name}`;
  } else {
    creatorName = `Multiple ${multipleCreatorsTitle}`;
  }

  if (numberOfCreators === 0) {
    return null;
  }

  return <StyledName className={className}>{creatorName}</StyledName>;
}

CardCreatorName.defaultProps = {
  singleCreatorTitle: 'Creator',
  multipleCreatorsTitle: 'Creators',
};

CardCreatorName.propTypes = {
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
  className: PropTypes.string,
  creators: PropTypes.array,
};

export default CardCreatorName;
