import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_PROGRAM_PERMISSION } from 'app/shared/permissions';
import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { programSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from 'shared/constants';
import { useOriginRoute, useSetDetailedObject, useLabels, usePublicIdFromURL } from 'shared/hooks';
import { toLower } from 'vendor/lodash';

import ProgramFormPage from './ProgramFormPage';

const ProgramEdit = () => {
  const { public_id_and_slug: publicIdAndSlug } = useParams();
  const [userCanSee, setUserCanSee] = useState(false);
  const { publicId } = usePublicIdFromURL();
  const { label_program: labelProgram, label_program_plural: labelProgramPlural } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.programs
  }&o=upcoming`;

  const programDetailRoute = mapRoute('programDetails', { public_id_and_slug: publicIdAndSlug });
  const backRoute = useOriginRoute(programDetailRoute);

  const [fetchProgram, { data: program, status }] = useEntities(
    actions.program.retrieveDetails,
    ({ data: program, status }) => {
      if (status === STATUS_DONE) {
        if (program.permissions?.includes(EDIT_PROGRAM_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: programSchema,
    }
  );

  useEffect(() => {
    fetchProgram(publicId);
  }, []);

  useSetDetailedObject({ id: publicIdAndSlug }, 'programs', [publicIdAndSlug]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = {
    program: {
      ...program,
      attendance_limit: program.attendance_limit === 0 ? '' : program.attendance_limit,
    },
  };

  return (
    <ProgramFormPage
      pageTitle={`Edit ${labelProgram}`}
      topBarActionName={`Editing ${labelProgram}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelProgramPlural,
          link: catalogRoute,
        },
        {
          label: program.name,
          link: programDetailRoute,
        },
        {
          label: `Edit ${toLower(labelProgram)}`,
        },
      ]}
    />
  );
};

export default ProgramEdit;
