import { useEffect } from 'react';

import { redirectToGoogleCalendarLogin } from 'services/integrations';
import { useCurrentUser } from 'shared/hooks';
import { includes, some } from 'vendor/lodash';

const pagesToCheck = ['/backoffice/events/'];

const GoogleCalendarEventAuthChecker = () => {
  const {
    should_request_authorization_for_events: shouldRequestCalendarAuthorizationForEvents,
  } = useCurrentUser();

  useEffect(() => {
    const shouldCheckCurrentPage = some(pagesToCheck, (page) =>
      includes(window.location.href, page)
    );

    if (shouldRequestCalendarAuthorizationForEvents && shouldCheckCurrentPage) {
      redirectToGoogleCalendarLogin();
    }
  }, []);

  return null;
};

export default GoogleCalendarEventAuthChecker;
