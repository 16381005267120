import PropTypes from 'prop-types';

import { CONTENT_TYPES } from 'catalog/constants';
import { ContentItemCard } from 'common/components/ContentItemCard';
import EventCard from 'events/components/EventCard';

const OptimalContentItemCard = (props) => {
  const { contentItem } = props;

  const contentType = contentItem.content_type;

  if (contentType === CONTENT_TYPES.event) {
    return <EventCard event={contentItem} shouldRefreshEvent />;
  }

  return <ContentItemCard contentItem={contentItem} />;
};

OptimalContentItemCard.propTypes = {
  contentItem: PropTypes.object,
};

export { OptimalContentItemCard };
