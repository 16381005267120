import { tint } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import actions from 'entities/actions';
import { eventTypeSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import EventTypeList from 'event-types/components/EventTypeList';
import SearchInput from 'inputs/components/SearchInput';
import colors from 'services/colors';
import { INTERCOM_DATA_TARGETS, withProductTour } from 'services/product-tour';
import { mapRoute } from 'services/requests';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { debounce, includes, split } from 'vendor/lodash';

const SearchContainer = styled.div`
  padding: 20px;
  padding-top: 15px;
  border-bottom: 1px solid ${colors.neutral50};
`;

const EventTypeListContainer = styled.div`
  height: 45vh;
  overflow-y: auto;
`;

const NoEventTypeCoverContainer = styled.div`
  display: inline-block;
  height: 72px;
  width: 128px;
  background-color: ${tint(0.75, colors.emphasis600)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoEventTypeInfoContainer = styled.div`
  width: calc(100% - 128px);
  display: inline-block;
  vertical-align: center;
  padding: 12px;
`;

const NoEventTypeContainer = withProductTour(styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid ${colors.neutral50};

  &:hover {
    background-color: ${colors.neutral50};
  }
`);

export class NewEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  componentDidMount = () => {
    this.handleFilterEventTypes();
  };

  handleSearchChange = (event) => {
    const searchValue = event;
    this.setState({ searchValue });
    this.handleFilterEventTypes(searchValue);
  };

  handleFilterEventTypes = debounce((searchValue) => {
    const { fetchEventTypes } = this.props;
    fetchEventTypes({ q: searchValue, page_size: 30 });
  }, 400);

  handleOptionClick = (eventTypeAndPublicId) => {
    const { history, originRoute, handleClose } = this.props;

    let publicId;
    if (eventTypeAndPublicId) {
      [publicId] = split(eventTypeAndPublicId, '_');
    }

    let route = mapRoute('eventNew');
    if (publicId) {
      route = mapRoute('eventNewFromTemplate', { public_id: publicId });
    }
    if (originRoute) {
      route += publicId ? `&origin=${originRoute}` : `?origin=${originRoute}`;
    }

    if (includes(window.location, mapRoute('eventNew'))) {
      // Redux prevents React Router from detecting that the querystring parameter has changed,
      // so when you're already in the event edit page, pushing the new route to the history
      // doesn't work. Instead we reload the page with the new route.
      // source: https://stackoverflow.com/a/42124328/1060162
      window.location = route;
    } else {
      history.push(route);
      handleClose();
    }
  };

  handleFetchNextPage = () => {
    const { fetchNextPage, eventTypeListNextPage } = this.props;
    fetchNextPage(eventTypeListNextPage);
  };

  render = () => {
    const { eventTypes, eventTypesStatus, handleClose, eventTypeListNextPage, labelEventType } =
      this.props;
    const { searchValue } = this.state;

    return (
      <Modal
        handleClose={handleClose}
        title="Schedule an Event"
        width={window.innerWidth > 600 ? 600 : null}
        overflow="hidden"
      >
        <ModalBody fullSize>
          <SearchContainer>
            <SearchInput
              label={labelEventType}
              placeholder={`Search for an ${labelEventType}`}
              onChange={this.handleSearchChange}
              value={searchValue}
            />
          </SearchContainer>

          <EventTypeListContainer>
            <EventTypeList
              eventTypes={eventTypes}
              eventTypesStatus={eventTypesStatus}
              handleItemClick={this.handleOptionClick}
              fetchNextPage={this.handleFetchNextPage}
              nextPage={eventTypeListNextPage}
            />
          </EventTypeListContainer>

          <NoEventTypeContainer
            onClick={() => this.handleOptionClick()}
            tourId={INTERCOM_DATA_TARGETS.createOneOffEventArea}
          >
            <NoEventTypeCoverContainer>
              <Text block medium size="h2" color={colors.emphasis600}>
                +
              </Text>
            </NoEventTypeCoverContainer>
            <NoEventTypeInfoContainer>
              <Text block medium size="h4" color={colors.emphasis600}>
                Schedule an one-off event
              </Text>
            </NoEventTypeInfoContainer>
          </NoEventTypeContainer>
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  };
}

NewEventModal.propTypes = {
  handleClose: PropTypes.func,
  eventTypes: PropTypes.array,
  eventTypesStatus: PropTypes.string,
  eventTypeListNextPage: PropTypes.string,
  fetchNextPage: PropTypes.func,
  fetchEventTypes: PropTypes.func,

  history: PropTypes.object,
  originRoute: PropTypes.string,

  labelEventType: PropTypes.string,
};

const mapStateToProps = (state) => {
  const requestState = getDataFromState('eventTypesForNewEvent', state, [eventTypeSchema]);
  return {
    eventTypes: requestState.data,
    eventTypesStatus: requestState.status,
    eventTypeListNextPage: requestState.nextPage,

    labelEventType: state.user.currentUser.labels.label_event_type,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEventTypes: (filters) =>
    dispatch(actions.eventType.retrieveList('eventTypesForNewEvent', filters)),
  fetchNextPage: (url) =>
    dispatch(actions.eventType.retrieveListLoadMore('eventTypesForNewEvent', url)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewEventModal));
