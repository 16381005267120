import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const CalendarIcon = ({ width, height, fill }) => {
  const d =
    'M10.421 1.422c.872 0 1.579.637 1.579 1.423v7.733c0 .785-.707 1.422-1.579 1.422H1.58C.707 12' +
    ' 0 11.363 0 10.578V2.845c0-.785.707-1.423 1.579-1.423h1.377v-.71c0-.394.353-.712.79-.712.43' +
    '5 0 .789.318.789.711v.711h3.158v-.71c0-.394.353-.712.789-.712.436 0 .79.318.79.711v.711h1.1' +
    '5zm.086 9.085v-6.39H1.44v6.39h9.066z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 12 12" />;
};

CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default CalendarIcon;
