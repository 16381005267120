import { ContentItem } from 'app/shared-content-item/interfaces';
import { Track } from 'app/tracks/interfaces';
import { useContentTypeRoutes } from 'catalog/hooks';
import { mapRoute } from 'services/requests';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import { useLabels } from 'shared/hooks';
import { isNil } from 'vendor/lodash';
import { Box } from 'vendor/mui';

export interface BreadCrumbsProps {
  content: ContentItem;
  track?: Track;
  margin?: string;
  padding?: string;
}

export const BreadCrumbs = ({ content, track, margin, padding }: BreadCrumbsProps) => {
  const contentTypeRoutes = useContentTypeRoutes();
  const catalogRoute = `${mapRoute('unifiedCatalogList')}`;
  const { label_catalog: labelCatalog } = useLabels();
  return (
    <Box margin={margin ?? '20px 0px -20px 0px'} padding={padding}>
      <Breadcrumbs
        variant="alternate"
        items={[
          {
            label: labelCatalog,
            link: catalogRoute,
          },
          ...(isNil(track)
            ? []
            : [
                {
                  label: track.name,
                  link: contentTypeRoutes[track.content_type].details({
                    public_id_and_slug: track.public_id_and_slug,
                  }),
                },
              ]),
          {
            label: content.name,
            link: contentTypeRoutes[content.content_type].details({
              public_id_and_slug: content.public_id_and_slug,
            }),
          },
        ]}
      />
    </Box>
  );
};

export default BreadCrumbs;
