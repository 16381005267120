import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { FACILITATOR_ROLES } from 'catalog/constants';
import { SimpleCardHeader } from 'shared-cards';
import { get, isEmpty, includes, map, filter } from 'vendor/lodash';

export const EventCardHeader = (props) => {
  const {
    event,
    currentUser,
    route,
    singleCreatorTitle,
    multipleCreatorsTitle,
    mode,
    showPersonalizedMaxLength,
  } = props;

  let canSeeIcon = false;
  const allowedRoles = ['admin', 'organizer'];
  const channel = get(event, 'channel', null);

  if (currentUser) {
    if (includes(allowedRoles, currentUser.role)) {
      canSeeIcon = true;
    }
    if (includes(map(event.co_organizers, 'id'), currentUser.id)) {
      canSeeIcon = true;
    }
    if (includes(map(event.presenters, 'id'), currentUser.id)) {
      canSeeIcon = true;
    }
    if (currentUser.id === get(event, 'organizer.id')) {
      canSeeIcon = true;
    }
  }

  return (
    <SimpleCardHeader
      contentTitle={event.name}
      isPrivate={event.is_hidden && canSeeIcon}
      hasGroups={!isEmpty(event.groups) && canSeeIcon}
      route={route}
      channel={channel}
      creators={get(
        event,
        'presenters',
        // CA 2.0
        map(
          filter(get(event, 'facilitators'), ({ role }) => role === FACILITATOR_ROLES.presenter),
          'user'
        )
      )}
      singleCreatorTitle={singleCreatorTitle}
      multipleCreatorsTitle={multipleCreatorsTitle}
      mode={mode}
      showPersonalizedMaxLength={showPersonalizedMaxLength}
    />
  );
};

EventCardHeader.propTypes = {
  event: PropTypes.object,
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
  mode: PropTypes.string,
  currentUser: PropTypes.object,
  route: PropTypes.string,
  showPersonalizedMaxLength: PropTypes.bool,
};

EventCardHeader.defaultProps = {
  showPersonalizedMaxLength: false,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  singleCreatorTitle: get(state.user, 'currentUser.labels.label_events_single_creator_title'),
  multipleCreatorsTitle: get(state.user, 'currentUser.labels.label_events_multiple_creators_title'),
});

export default connect(mapStateToProps, null)(EventCardHeader);
