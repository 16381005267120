import PropTypes from 'prop-types';
import React from 'react';

export const INTERCOM_DATA_TARGETS = {
  newEventButton: 'New Event Button',
  createOneOffEventArea: 'Create One-Off Event Area',
  eventFormEnrollmentAndCapacity: 'Event Form Enrollment & Capacity',
  eventFormOnlineAccordion: 'Event Form Online Accordion',
  eventFormOnlineCheckbox: 'Event Form Online Checkbox',
  eventFormOnlineEnrollmentCap: 'Event Form Online Enrollment Cap',
  eventFormOnlineWaitlistCap: 'Event Form Online Waitlist Cap',
  limitDoubleBookingWrapper: 'Event Type Form Limit Double Booking Section',
  limitDoubleBookingCheckbox: 'Event Type Form Limit Double Booking Checkbox',
};

const getProductTourProps = (name) => ({
  'data-intercom-target': name,
});

/**
 * Use this HOC when a component must include the product tour identifier.
 * The prop tourId will be converted to the proper DOM element attribute.
 */
export const withProductTour = (Component) => {
  const TourComponent = ({ tourId, forwardedRef, ...rest }) => {
    const tourProps = tourId ? getProductTourProps(tourId) : {};
    return <Component ref={forwardedRef} {...rest} {...tourProps} />;
  };

  TourComponent.propTypes = {
    tourId: PropTypes.string,
    forwardedRef: PropTypes.any,
  };

  return React.forwardRef((props, ref) => {
    return <TourComponent {...props} forwardedRef={ref} />;
  });
};
