import React from 'react';
import PropTypes from 'prop-types';

import AccordionCheckboxInput from 'inputs/components/AccordionCheckboxInput';

const AccordionCheckboxField = ({
  input: { checked, onChange },
  meta: { touched, error },
  ...props
}) => (
  <AccordionCheckboxInput
    value={checked}
    hasError={touched && error}
    onClick={onChange}
    {...props}
  />
);

AccordionCheckboxField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default AccordionCheckboxField;
