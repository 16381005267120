import { map, merge, size, slice, head, keys, remove } from 'vendor/lodash';

const parse_term = (term) => {
  if (size(term.children) === 0) return '';
  return term.children[0].value;
};

const parse_prop = (prop) => {
  if (size(prop.children) === 0) return '';
  return prop.children[0].value;
};

const parse_val = (val) => {
  if (size(val.children) === 0) return '';
  const { value, type } = val.children[0];
  if (type === 'QUOTED_VAL') {
    return value.slice(1, size(value) - 1);
  }
  return value;
};

const parse_ordering_prop = (prop) => {
  if (size(prop.children) === 0) return '';
  if (size(prop.children) === 1) return prop.children[0].children[0].value;
  return `${prop.children[0].value}${prop.children[1].children[0].value}`;
};

const transform_searching = (term, prop, val, is_negative) => {
  const op = `$${parse_term(term)}`;
  const field = parse_prop(prop);
  const value = parse_val(val);
  const length = size(value);
  const get_operation = (op, value, length) => {
    if (length === 0) return {};
    if (length === 1 && value[0] === '*') return { [op]: '' };
    if (length === 1) return { [op]: value };
    if (value[0] === '*' && value[length - 1] !== '*')
      return { [op]: { start: value.slice(1, length) } };
    if (value[length - 1] === '*' && value[0] !== '*')
      return { [op]: { end: value.slice(0, Math.max(0, length - 1)) } };
    if (value[0] === '*' && value[length - 1] === '*') return { [op]: value.slice(1, length - 1) };
    return { [op]: value };
  };
  if (is_negative) return { [field]: { $not: get_operation(op, value, length) } };
  return { [field]: get_operation(op, value, length) };
};

const transform_equal_comp = (prop, val) => {
  const field = parse_prop(prop);
  const value = parse_val(val);
  return { [field]: { $eq: value } };
};

const transform_comp = (term, prop, val, is_negative) => {
  const op = `$${parse_term(term)}`;
  const field = parse_prop(prop);
  const value = parse_val(val);
  if (is_negative) return { [field]: { $not: { [op]: value } } };
  return { [field]: { [op]: value } };
};

const transform_listing = (term, prop, values, is_negative) => {
  const op = `$${parse_term(term)}`;
  const field = parse_prop(prop);
  const list = map(values, parse_val);
  if (is_negative) return { [field]: { $not: { [op]: list } } };
  return { [field]: { [op]: list } };
};

const transform_range = (term, prop, min_val, max_val, is_negative) => {
  const op = `$${parse_term(term)}`;
  const field = parse_prop(prop);
  const min_value = parse_val(min_val);
  const max_value = parse_val(max_val);
  if (is_negative) return { [field]: { $not: { [op]: { min: min_value, max: max_value } } } };
  return { [field]: { [op]: { min: min_value, max: max_value } } };
};

const transform_ordering = (term, props) => {
  const op = `$${parse_term(term)}`;
  const list = map(props, parse_ordering_prop);
  if (size(list) === 1) return { [op]: list[0] };
  return { [op]: list };
};

const transform_expr = (node, is_negative = false) => {
  if (node.data === 'expr_term') return transform_expr_term(node);
  if (size(node.children) < 2) return {};
  if (node.data === 'ordering') {
    return transform_ordering(node.children[0], slice(node.children, 1));
  }
  if (node.data === 'searching') {
    return transform_searching(...node.children, is_negative);
  }
  if (node.data === 'comp') {
    if (size(node.children) === 2)
      // when the expression has an = symbol
      return transform_equal_comp(...node.children);
    return transform_comp(...node.children, is_negative);
  }
  if (node.data === 'listing') {
    return transform_listing(
      node.children[0],
      node.children[1],
      slice(node.children, 2),
      is_negative
    );
  }
  if (node.data === 'range' && size(node.children) === 4) {
    return transform_range(
      node.children[0],
      node.children[1],
      node.children[2],
      node.children[3],
      is_negative
    );
  }
  return {};
};

const transform_expr_term = (node, is_negative = false) => {
  const partial_transform_expr = (children_node) => {
    return transform_expr(children_node, is_negative);
  };
  return merge({}, ...map(node.children, partial_transform_expr));
};

const transform_operator = (node) => {
  if (size(node.children) === 0) return {};
  if (node.children[0].data === 'not_op') {
    if (size(node.children[0].children) === 0) return {};
    return transform_expr_term(node.children[0].children[0], true);
  }
  if (node.children[0].data === 'or_op') {
    return { $or: map(node.children[0].children, transform_term) };
  }
  return { $and: map(node.children[0].children, transform_term) };
};

const transform_term = (node) => {
  if (size(node.children) === 0) return {};
  if (node.children[0].data === 'logical') {
    return transform_operator(node.children[0]);
  }
  return merge({}, ...map(node.children, transform_expr_term));
};

const extract_logical = (originalExpressions) => {
  const expressions = [];
  map(originalExpressions, (exp) => {
    const op = head(keys(exp));
    if (op === '$and' || op === '$or') {
      expressions.push(...extract_logical(exp[op]));
      return;
    }
    expressions.push(exp);
  });
  return expressions;
};

// As the ordering is processed by the logical operator, it will be available in the operator filter list, something like this:
// {$and: [{name: {$eq: 'abc'}}, {$ordering: 'name'}]}
// But this is wrong, the correct object is this one:
// {name: {$eq: 'abc'}, $ordering: 'name'}
// This function "bubble up" the ordering
const extract_ordering = (rqlObject) => {
  const baseOp = head(keys(rqlObject));
  if (baseOp !== '$and' && baseOp !== '$or') return rqlObject;
  const filters = rqlObject[baseOp];
  const orderingFilters = remove(filters, (filter) => head(keys(filter)) === '$ordering');
  if (size(filters) <= 1) return merge({}, ...filters, ...orderingFilters);
  return { [baseOp]: filters, ...merge({}, ...orderingFilters) };
};

// As the tree grows to the right, we need to move all expressions to the same level,
// so a rql object like this:
// {$and: [{name: 'abc'}, {$and: [{title: 'def'}, {department: 'ghi'}]}]}
// should be converted to this:
// {$and: [{name: 'abc'}, {title: 'def'}, {department: 'ghi'}]}
// This is necessary to avoid to handle with ambiguity, for example:
// The expression: (ilike(name,abc)&regex(name,def)) can be expressed in this two ways:
// {name: {$ilike: 'abc', $regex: 'def'}}
// and
// {$and: [{name: {$ilike: 'abc'}}, {name: {$regex: 'def'}}]}
// But the expression ((ilike(name,abc)&ilike(name,def)) only this way:
// {$and: [{name: {$ilike: 'abc'}}, {name: {$ilike: 'def'}}]}
// So we will use only one way to represent the expressions.
const transform_to_single_level = (rqlObject) => {
  const baseOp = head(keys(rqlObject));
  if (baseOp !== '$and' && baseOp !== '$or') return rqlObject;
  return extract_ordering({ [baseOp]: extract_logical(rqlObject[baseOp]) });
};

export const transform = (tree) => {
  const { data } = tree;
  if (data === 'start' && size(tree.children) > 0)
    return transform_to_single_level(transform_term(tree.children[0]));
  return {};
};
