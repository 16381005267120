import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';

import Icon from '../Icon';

export const FormFieldGroup = styled.div`
  margin: ${({ margin }) => margin || 0};

  & + & {
    margin-top: 16px;
  }

  > * + * {
    margin-top: 4px;
  }
`;

const ErrorText = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 5px;
  }
`;

const StyledIcon = styled(Icon)`
  min-width: ${({ width }) => `${width}px` || 0};
`;

// TODO: add meta to support redux-form error handling
export const FormFieldError = ({ customErrorMessage }) => {
  if (!customErrorMessage) {
    return null;
  }

  return (
    <ErrorText>
      <StyledIcon width={15} height={15} name="exclamation" color={colors.error600} />
      <Text color={colors.error600}>{customErrorMessage}</Text>
    </ErrorText>
  );
};

FormFieldError.propTypes = {
  customErrorMessage: PropTypes.string,
};
