import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

export function useUrlHashState() {
  const history = useHistory();
  const location = useLocation();

  const hash = location.hash.slice(1); // Remove '#'

  const setHash = useCallback(
    (newHash: string) => {
      // eslint-disable-next-line lodash/prefer-lodash-method
      history.replace({ ...location, hash: `#${newHash}` });
    },
    [history, location]
  );

  return [hash, setHash] as const;
}
