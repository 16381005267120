import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import actions from 'entities/actions';
import { surveyRelationshipSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { STATUS_LOADING } from 'shared/constants';
import SurveyModal from 'surveys/components/SurveyModal';
import SurveyModalItem from 'surveys/components/SurveyModal/SurveyModalItem';
import { SurveyRelationshipEntityType } from 'surveys/constants';
import { useSurveyRelationshipTypeLabels } from 'surveys/hooks';
import { isEqual, toLower } from 'vendor/lodash';

const ContentSurveyModal = ({ content, handleClose }) => {
  const { public_id: contentPublicId, name: contentName, content_type: contentType } = content;
  const surveyRelationshipTypeLabels = useSurveyRelationshipTypeLabels();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const [fetchContentSurveys, { data: surveys, status, count: surveysCount }] = useEntities(
    actions.surveyRelationship.retrieveList,
    null,
    {
      schema: [surveyRelationshipSchema],
    }
  );

  useEffect(() => {
    fetchContentSurveys({
      content_item: contentPublicId,
      o: '-survey_cutoff_date',
      view_mode: 'surveys_dialog',
    });
  }, []);

  const isLoading = isEqual(status, STATUS_LOADING);
  let entityType;
  if (contentType === CONTENT_TYPES.eventtype) entityType = SurveyRelationshipEntityType.EVENT_TYPE;
  else if (contentType === CONTENT_TYPES.article) entityType = SurveyRelationshipEntityType.ARTICLE;
  else if (contentType === CONTENT_TYPES.video) entityType = SurveyRelationshipEntityType.VIDEO;
  else if (contentType === CONTENT_TYPES.program) entityType = SurveyRelationshipEntityType.PROGRAM;
  else if (contentType === CONTENT_TYPES.course) entityType = SurveyRelationshipEntityType.COURSE;
  else if (contentType === CONTENT_TYPES.track) entityType = SurveyRelationshipEntityType.TRACK;

  let entityLabel = 'content';
  if (entityType) {
    entityLabel = toLower(surveyRelationshipTypeLabels[entityType]);
  }

  return (
    <SurveyModal
      entityLabel={entityLabel}
      isLoading={isLoading}
      title={contentName}
      surveys={surveys}
      surveysCount={surveysCount}
      handleClose={handleClose}
      renderItem={(surveyRelationship) => (
        <SurveyModalItem key={surveyRelationship.id} surveyRelationship={surveyRelationship} />
      )}
      renderTopButton={() => (
        <Button
          endIcon={<Icon name="external" />}
          route={`${contentTypeRoutes[contentType].edit({
            // Some routes use public_id and some use public_id_and_slug,
            // we let the function decide which to use by providing both
            public_id_and_slug: content.public_id_and_slug,
            public_id: contentPublicId,
          })}#surveys`}
        >
          Edit Surveys Schedule
        </Button>
      )}
    />
  );
};

ContentSurveyModal.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ContentSurveyModal;
