import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory } from 'data-fetching/utils';
import { ApiURLs, fetchURL } from 'services/requests-base';

const fetchEvent = async (id: number, viewMode = 'default') => {
  const base = ApiURLs['events_api:retrieve_update_destroy'](id);
  const url = `${base}?view_mode=${viewMode}`;
  const { data } = await fetchURL(url);
  return data;
};

export const events = createQueryKeys('events', {
  list: listQueryKeyFactory({ endpointName: 'events_api:list_create' }),
  detail: (id: number, viewMode = 'default') => ({
    queryKey: [id, viewMode],
    queryFn: () => fetchEvent(id, viewMode),
  }),
  feedbackList: (publicId: string) => ({
    queryKey: [publicId],
    queryFn: async () => {
      const url = ApiURLs['api_events:feedbacks'](publicId);

      const { data: response } = await fetchURL(url);
      return response;
    },
  }),
});

export type EventsQueryKeys = inferQueryKeys<typeof events>;
