import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import colors from 'services/colors';
import { formatDuration } from 'services/datetime';
import { forEach, get, isEmpty } from 'vendor/lodash';

import { useContentItemCardCtx } from './ContentItemCardContext';

export const Container = styled(Box)`
  display: flex;
  gap: 4px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.5rem;
    transform: rotate(-90deg);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    z-index: 1;
  }
`;

const DurationChip = styled(Chip)`
  background-color: ${colors.neutral200};
`;

const TagChip = styled(Chip)`
  background-color: ${colors.neutral100};
`;

export function ContentItemCardTags() {
  const { contentItem } = useContentItemCardCtx();

  const duration = get(contentItem, 'duration');
  const formattedDuration = !isEmpty(duration) ? formatDuration(duration) : null;

  const itemTags = get(contentItem, 'tags_related', get(contentItem, 'tags', []));

  const tagsToDisplay = React.useMemo(() => {
    const tags: any[] = [];

    if (formattedDuration) {
      tags.push(<DurationChip key="duration" label={formattedDuration} size="small" />);
    }

    forEach(itemTags, (tag) => {
      tags.push(<TagChip key={tag} label={tag} size="small" />);
    });

    return tags;
  }, [formattedDuration, itemTags]);

  const canDisplay = !isEmpty(tagsToDisplay);

  if (!canDisplay) {
    return null;
  }

  return <Container>{tagsToDisplay}</Container>;
}
