import React, { useState } from 'react';

import { useCurrentUser, useModuleToggles } from 'app/shared/hooks';
import { CONTENT_TYPES, LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import Button from 'shared/components/Button';
import * as permissionConstants from 'shared/permissions';
import { includes, isEmpty, some } from 'vendor/lodash';
import { Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from 'vendor/mui';
import {
  AddIcon,
  AssignmentTurnedInIcon,
  DescriptionIcon,
  CodeIcon,
  PlayCircleIcon,
  EventNoteIcon,
  VideoLibraryIcon,
  LinkIcon,
} from 'vendor/mui-icons';

interface CreateButtonProps {
  handleSelectContent: (contentType: string) => void;
}

const CreateButton = ({ handleSelectContent }: CreateButtonProps) => {
  const learningTypeLabels = useLearningTypeLabels();
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();
  const { tracks: toggleSnippets } = useModuleToggles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (contentType: string) => {
    setAnchorEl(null);
    if (!isEmpty(contentType)) {
      handleSelectContent(contentType);
    }
  };

  const canCreateEventType = includes(
    permissions,
    permissionConstants.CREATE_EVENT_TYPE_PERMISSION
  );
  const canCreateArticle =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
    includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION);
  const canCreateTask =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tasks)) &&
    includes(permissions, permissionConstants.CREATE_TASK_PERMISSION);
  const canCreateCodelab =
    includes(catalogSectionsOrder, LEARNING_TYPES.codelabs) &&
    includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION);
  const canCreateCourses =
    toggleCourses &&
    includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
    includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION);
  const canCreateVideo =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
    includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION);
  const canCreateLinkedContent =
    includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
    includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION);

  const canCreate = some(
    [
      canCreateArticle,
      canCreateTask,
      canCreateVideo,
      canCreateEventType,
      canCreateCourses,
      canCreateCodelab,
      canCreateLinkedContent,
    ],
    Boolean
  );

  if (!canCreate)
    return (
      <Tooltip title="You do not have permission to create a new Content Item. Get in touch with your system administrator if you wish to do so.">
        <div>
          <Button startIcon={<AddIcon />} disabled>
            Create
          </Button>
        </div>
      </Tooltip>
    );

  return (
    <div>
      <Button startIcon={<AddIcon />} onClick={handleClick} disabled={open}>
        Create
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose('')}>
        {canCreateArticle && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.article)} disableRipple>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.articles]}</ListItemText>
          </MenuItem>
        )}
        {canCreateTask && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.task)} disableRipple>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.tasks]}</ListItemText>
          </MenuItem>
        )}
        {canCreateVideo && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.video)} disableRipple>
            <ListItemIcon>
              <PlayCircleIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.videos]}</ListItemText>
          </MenuItem>
        )}
        {canCreateEventType && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.eventtype)} disableRipple>
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.event_types]}</ListItemText>
          </MenuItem>
        )}
        {canCreateCourses && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.course)} disableRipple>
            <ListItemIcon>
              <VideoLibraryIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.courses]}</ListItemText>
          </MenuItem>
        )}
        {canCreateCodelab && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.codelab)} disableRipple>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.codelabs]}</ListItemText>
          </MenuItem>
        )}
        {canCreateLinkedContent && (
          <MenuItem onClick={() => handleClose(CONTENT_TYPES.linkedcontent)} disableRipple>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText>{learningTypeLabels[LEARNING_TYPES.linkedcontent]}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default CreateButton;
