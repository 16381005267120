import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BaseFilter from 'backoffice/components/Dashboard/Filters/BaseFilter';
import Autocomplete from 'inputs/components/Autocomplete';
import CompositeTextInput from 'inputs/components/CompositeTextInput';
import FlexibleDateRangeInput from 'inputs/components/FlexibleDateRangeInput';
import {
  DATE_RANGE_EXACT_MODE,
  DATE_RANGE_RELATIVE_MODE,
} from 'inputs/components/FlexibleDateRangeInput/constants';
import { getDateString } from 'inputs/components/FlexibleDateRangeInput/utils';
import PeriodDropdown from 'inputs/components/PeriodDropdown';
import TextInput from 'inputs/components/TextInput';
import { Select } from 'shared/components/DropdownBase';
import { API_DATE_FORMAT } from 'shared/constants';
import { useDebounce } from 'shared/hooks';
import {
  difference,
  get,
  size,
  map,
  sortBy,
  omit,
  isArray,
  isNil,
  isEmpty,
  isUndefined,
  includes,
  last,
  toString,
} from 'vendor/lodash';
import { IconButton, InputAdornment } from 'vendor/mui';
import { CloseOutlinedIcon } from 'vendor/mui-icons';

import { filterInputSx } from './styles';

export const TextFilter = ({
  placeholder,
  onChange,
  param,
  selecteds,
  disabled,
  label,
  handleRemoveFilter,
}) => {
  const [value, setValue] = useState(selecteds[param] || '');
  const debouncedValue = useDebounce(value);

  useEffect(() => {
    setValue(selecteds[param] || '');
  }, [selecteds[param]]);

  useEffect(() => {
    onChange({
      ...selecteds,
      [param]: debouncedValue,
    });
  }, [debouncedValue]);

  return (
    <BaseFilter
      label={label}
      param={param}
      handleRemoveFilter={handleRemoveFilter}
      renderInput={() => (
        <TextInput
          label={label}
          aria-label={label}
          value={value}
          placeholder={placeholder}
          onChange={(event) => setValue(event.target.value)}
          disabled={disabled}
          sx={{
            ...(handleRemoveFilter && filterInputSx),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!isEmpty(value) && (
                  <IconButton disableRipple onClick={() => setValue('')}>
                    <CloseOutlinedIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

TextFilter.defaultProps = {
  placeholder: 'Search...',
};

TextFilter.propTypes = {
  param: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
};

export const CompositeTextFilter = ({
  placeholder,
  onChange,
  param,
  selecteds,
  disabled,
  label,
  handleRemoveFilter,
}) => {
  const currentSelectionFilter = selecteds[`${param}-op`];
  const currentTextFilter = selecteds[param];

  const [currentFilterValue, setCurrentFilterValue] = useState({
    selection: currentSelectionFilter,
    text: currentTextFilter,
  });
  const debouncedValue = useDebounce(currentFilterValue);

  const options = [
    { value: 'contains', label: 'Contains' },
    { value: 'notcontains', label: 'Does not contain' },
    { value: 'equal', label: 'Is' },
    { value: 'notequal', label: 'Is not' },
    { value: 'startswith', label: 'Starts with' },
    { value: 'endswith', label: 'Ends with' },
    { value: 'matches', label: 'Matches' },
    { value: 'notmatches', label: 'Does not match' },
    { value: 'in', label: 'Any of' },
    { value: 'out', label: 'None of' },
  ];

  useEffect(() => {
    setCurrentFilterValue({ selection: currentSelectionFilter, text: currentTextFilter });
  }, [currentTextFilter, currentSelectionFilter]);

  useEffect(() => {
    const { selection, text } = debouncedValue;
    if (text !== currentTextFilter || selection !== currentSelectionFilter) {
      // Updates url when values change
      onChange({
        ...selecteds,
        [param]: text,
        [`${param}-op`]: selection || options[0].value,
      });
    } else if (!currentSelectionFilter) {
      // Adds default value fo selection once it's added to the filter list
      onChange({
        ...selecteds,
        [`${param}-op`]: options[0].value,
      });
    }
  }, [debouncedValue]);

  return (
    <BaseFilter
      label={label}
      param={param}
      handleRemoveFilter={handleRemoveFilter}
      renderInput={() => (
        <CompositeTextInput
          label={label}
          aria-label={label}
          value={currentFilterValue.text}
          selection={currentFilterValue.selection}
          options={options}
          placeholder={placeholder}
          onChange={({ selection, value }) => {
            setCurrentFilterValue({ selection, text: value });
          }}
          disabled={disabled}
          sx={{
            ...(handleRemoveFilter && filterInputSx),
          }}
        />
      )}
    />
  );
};

CompositeTextFilter.defaultProps = {
  placeholder: 'Search...',
};

CompositeTextFilter.propTypes = {
  param: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
};

export const DropdownFilter = ({
  label,
  param,
  selecteds, // all filters
  options,
  collectionIsOrdered,
  loading,
  disabled,
  onChange,
  handleRemoveFilter,
  singleSelect,
  ...rest
}) => {
  const handleChange = (newSelecteds) => {
    const prev = get(selecteds, param, []);
    const prevArray = isArray(prev) ? prev : [prev];
    onChange({
      ...selecteds,
      [param]: isUndefined(singleSelect) ? newSelecteds : difference(newSelecteds, prevArray),
    });
  };
  const sortOptions = () => {
    if (collectionIsOrdered) return options || [];
    return sortBy(options, ['name', 'label']) || [];
  };

  const getOptions = () => {
    // Some inputs are using the result of the filter_options view mode,
    // but the Autocomplete expects a label attribute that it's not available in the view mode
    // Also, if the main component is reading the options from the query string,
    // the selecteds options values will have the string type, but the server returns as integer
    // so is necessary to convert the value to string to have the same type to correctly show the selected options.
    return map(sortOptions(), (option) => ({
      ...option,
      label: option.name || option.label,
      value: toString(option.value),
    }));
  };

  const getSelectedItems = () => {
    const selectedItems = selecteds[param] || [];
    if (!isNil(selectedItems) && !isArray(selectedItems)) return [selectedItems];
    else if (singleSelect) return [last(selectedItems)];
    return selectedItems;
  };

  const selectedItems = getSelectedItems();

  const placeholder = size(selectedItems) > 0 ? '' : 'Select';

  return (
    <BaseFilter
      label={label}
      param={param}
      handleRemoveFilter={handleRemoveFilter}
      renderInput={() => (
        <Autocomplete
          label={label}
          disabled={disabled}
          value={selectedItems}
          options={getOptions()}
          onChange={handleChange}
          loading={loading}
          inputMinWidth="80px"
          placeholder={placeholder}
          multiple
          {...rest}
        />
      )}
    />
  );
};

DropdownFilter.propTypes = {
  label: PropTypes.string.isRequired,
  param: PropTypes.string,
  selecteds: PropTypes.object,
  options: PropTypes.array,
  collectionIsOrdered: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
  singleSelect: PropTypes.bool,
};

export const PeriodFilter = (props) => {
  const {
    periodParam,
    dateStartParam,
    dateEndParam,
    selecteds,
    onChange,
    customLabel,
    selectOptions,
    noDefaultDates,
    showClearButton,
    label,
    handleRemoveFilter,
  } = props;
  const handleOnPeriodChange = (newSelecteds) =>
    onChange({
      ...omit(selecteds, [dateStartParam, dateEndParam, periodParam]),
      ...newSelecteds,
    });

  const period = selecteds[periodParam] ? selecteds[periodParam] : null;
  const startDate = selecteds[dateStartParam] ? moment(selecteds[dateStartParam]) : null;
  const endDate = selecteds[dateEndParam] ? moment(selecteds[dateEndParam]) : null;

  return (
    <BaseFilter
      label={label}
      param={periodParam ?? dateStartParam}
      handleRemoveFilter={handleRemoveFilter}
      renderInput={() => (
        <PeriodDropdown
          {...omit(props, ['selecteds'])}
          labelName={label}
          Label={Select}
          onChange={handleOnPeriodChange}
          startDate={startDate}
          endDate={endDate}
          period={period}
          customLabel={customLabel}
          formatDateOutput={API_DATE_FORMAT}
          selectOptions={selectOptions}
          noDefaultDates={noDefaultDates}
          showClearButton={showClearButton}
        />
      )}
    />
  );
};

PeriodFilter.defaultProps = {
  selectOptions: [
    {
      name: 'Upcoming',
      value: 'upcoming',
    },
    {
      name: 'Past',
      value: 'previous',
    },
  ],
};

PeriodFilter.propTypes = {
  periodParam: PropTypes.string,
  dateStartParam: PropTypes.string,
  dateEndParam: PropTypes.string,
  selectOptionsDetails: PropTypes.object,
  selectOptions: PropTypes.array,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

  noDefaultDates: PropTypes.bool,
  showClearButton: PropTypes.bool,

  customLabel: PropTypes.string,

  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
};

export const DateRangeFilter = (props) => {
  const {
    periodParam,
    dateStartParam,
    dateEndParam,
    selecteds,
    onChange,
    label,
    handleRemoveFilter,
  } = props;

  const extractDataFromCurrentState = (mode = DATE_RANGE_RELATIVE_MODE) => {
    let startDate = selecteds[dateStartParam] ? selecteds[dateStartParam] : null;
    let endDate = selecteds[dateEndParam] ? selecteds[dateEndParam] : null;

    if (
      (startDate && !includes(['P', '-'], startDate[0])) ||
      (endDate && !includes(['P', '-'], endDate[0]))
    ) {
      mode = DATE_RANGE_EXACT_MODE;
    }

    if (mode === DATE_RANGE_EXACT_MODE) {
      if (startDate) {
        startDate = moment(startDate);
      }
      if (endDate) {
        endDate = moment(endDate);
      }
    }

    return [mode, startDate, endDate];
  };

  const [initialMode, initialStartDate, initialEndDate] = extractDataFromCurrentState();

  const [value, setValue] = useState({
    mode: initialMode,
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const debouncedValue = useDebounce(value);

  // Updates local state when global state is updated and data is different
  useEffect(() => {
    const {
      mode: currentMode,
      startDate: currentStartDate,
      endDate: currentEndDate,
    } = debouncedValue;

    const [mode, startDate, endDate] = extractDataFromCurrentState(currentMode);

    if (
      mode === currentMode &&
      getDateString(mode, startDate) === getDateString(mode, currentStartDate) &&
      getDateString(mode, endDate) === getDateString(mode, currentEndDate)
    )
      return;

    setValue({ mode, startDate, endDate });
  }, [selecteds[dateStartParam], selecteds[dateEndParam]]);

  // Updates global state when local state is updated and data is different
  useEffect(() => {
    const { mode, startDate, endDate } = debouncedValue;

    const formattedStartDate = getDateString(mode, startDate);
    const formattedEndDate = getDateString(mode, endDate);

    const selectedStartDateString = selecteds[dateStartParam] || '';
    const selectedEndDateString = selecteds[dateEndParam] || '';

    // Avoids infinite updates of the component re-rendering when there's no actual change
    if (
      formattedStartDate === selectedStartDateString &&
      formattedEndDate === selectedEndDateString
    )
      return;

    onChange({
      ...omit(selecteds, [dateStartParam, dateEndParam, periodParam]),
      ...(startDate && {
        [dateStartParam]: formattedStartDate,
      }),
      ...(endDate && { [dateEndParam]: formattedEndDate }),
    });
  }, [debouncedValue.mode, debouncedValue.startDate, debouncedValue.endDate]);

  const { mode, startDate, endDate } = debouncedValue;

  return (
    <BaseFilter
      label={label}
      param={periodParam ?? dateStartParam}
      handleRemoveFilter={handleRemoveFilter}
      renderInput={() => (
        <FlexibleDateRangeInput
          label={label}
          mode={mode}
          startDate={startDate}
          endDate={endDate}
          textInputSx={filterInputSx}
          onChange={({ mode, startDate, endDate } = {}) => {
            setValue({
              mode,
              ...(startDate && { startDate }),
              ...(endDate && { endDate }),
            });
          }}
        />
      )}
    />
  );
};

DateRangeFilter.defaultProps = {};

DateRangeFilter.propTypes = {
  periodParam: PropTypes.string,
  dateStartParam: PropTypes.string,
  dateEndParam: PropTypes.string,
  label: PropTypes.string.isRequired,

  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
};
