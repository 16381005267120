import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { ContentItem } from 'app/shared-content-item/interfaces';
import { DEFAULT_DETAIL_PAGE_STALE_TIME } from 'common/api/constants';
import { COURSE_STATUS_DRAFT } from 'course/constants';
import { queries } from 'queries';
import Page404 from 'scenes/404';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import { usePublicIdFromURL } from 'shared/hooks';
import { get, isNil } from 'vendor/lodash';
import { styled } from 'vendor/mui';

import CourseDetailPage from './CourseDetailPage';

const DraftBanner = styled('div')`
  height: 52px;
  background-color: ${colors.neutral0};
  border-bottom: 2px solid ${colors.neutral100};

  display: flex;
  align-items: center;
  padding: 12px 20px;
`;

const DraftText = styled(Text)`
  margin-left: 20px;
`;

const CourseDetail = () => {
  const [isTracked, setIsTracked] = useState(false);
  const { trackActivity } = useMetrics();
  const { publicId } = usePublicIdFromURL();

  const { data, status, error } = useQuery({
    ...queries.courses.detail(publicId || '', 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
    retry: 0,
  });

  useEffect(() => {
    if (status === 'success' && !isTracked) {
      trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
        contentItemId: get(data, 'id', ''),
        contentItemType: get(data, 'content_type', ''),
      });
      setIsTracked(true);
    }
  }, [status, data, trackActivity, isTracked]);

  if (!isNil(data)) {
    const isUnpublished = (data as ContentItem).status === COURSE_STATUS_DRAFT;

    return (
      <>
        {isUnpublished && (
          <DraftBanner>
            <Icon name="info" height={20} width={20} color={colors.neutral400} />
            <DraftText>
              This page is a draft. Edit and publish your course to make it available to people in
              your organization.
            </DraftText>
          </DraftBanner>
        )}
        <CourseDetailPage content={data as ContentItem} />
      </>
    );
  }

  if (error) return <Page404 />;

  return <Loading />;
};

export default CourseDetail;
