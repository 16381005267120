import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ChannelByline from 'app/shared/components/ChannelByline';
import { useChannelToggle } from 'app/shared/hooks';
import colors from 'services/colors';
import { get } from 'vendor/lodash';
import { styled, Box, Link, LinkProps as MUILinkProps } from 'vendor/mui';

import { useContentItemCardCtx } from './ContentItemCardContext';

type LinkProps = MUILinkProps & { component: ElementType; to: string };

const HeaderTitleLink = styled(Link)<LinkProps>`
  color: ${colors.neutral900};
  font-size: 1.125rem;
  line-height: 1.275;
  word-break: break-word;

  &:hover {
    color: ${colors.neutral900};
  }
`;

export function ContentItemCardHeader() {
  const { contentItem, route } = useContentItemCardCtx();
  const channel = get(contentItem, 'channel', null);

  const toggleChannels = useChannelToggle();

  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <HeaderTitleLink component={RouterLink} to={route} underline="hover" variant="h6">
        {contentItem.name}
      </HeaderTitleLink>

      {toggleChannels && channel && <ChannelByline channel={channel} />}
    </Box>
  );
}
