import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Page404 from 'scenes/404';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING, HTTP_404_NOT_FOUND } from 'shared/constants';
import { useLabels, useOriginRoute, useSetDetailedObject } from 'shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import { includes, toLower } from 'vendor/lodash';

import TrackFormPage from './TrackFormPage';

const TrackEdit = () => {
  const { public_id: publicId } = useParams();
  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();

  const [userCanSee, setUserCanSee] = useState(false);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;
  const trackDetailRoute = mapRoute('trackDetails', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(trackDetailRoute);

  const [fetchTrack, { data: track, status, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ data: track, status }) => {
      if (status === STATUS_DONE) {
        if (includes(track.permissions, CONTENT_ITEM_PERMISSIONS.manage)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: contentSchema,
    }
  );

  useSetDetailedObject({ id: publicId }, 'tracks', [publicId]);

  useEffect(() => {
    fetchTrack(publicId);
  }, []);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { track };
  return (
    <TrackFormPage
      pageTitle={`Edit ${labelTrack}`}
      topBarActionName={`Editing ${labelTrack}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelTrackPlural,
          link: catalogRoute,
        },
        {
          label: track.name,
          link: trackDetailRoute,
        },
        {
          label: `Edit ${toLower(labelTrack)}`,
        },
      ]}
    />
  );
};

export default TrackEdit;
