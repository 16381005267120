import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import Sticky from 'react-sticky-el';
import styled, { css } from 'styled-components';

import { SidebarButton } from 'navigation/components/FilterPage';
import colors from 'services/colors';
import { useContentSidebarContext } from 'shared-layouts/ContentTopbarPage/ContentSidebarContext';
import MediaPoint from 'shared/components/MediaPoint';
import {
  CONTENT_SIDEBAR_MIN_WIDTH,
  CONTENT_SIDEBAR_MAX_WIDTH,
  TOPBAR_HEIGHT,
} from 'shared/ui-constants';

const SidebarWrapperComponent = styled.div`
  display: flex;
`;

const StyledFilterBar = styled.aside`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);

  ${({ showScroll }) =>
    showScroll
      ? css`
          height: 100vh;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 10px;
        `
      : css`
          min-height: 100vh;
        `}
`;

// Used to not let the scrollbar push the content
const FixedWidthContainer = styled.div`
  width: ${({ width }) => `${width}px`};
  ${({ hasTopbar }) => hasTopbar && `margin-top: ${TOPBAR_HEIGHT};`}

  ${MediaPoint.MobileOnly} {
    width: 100vw;
  }
`;

const SidebarContainer = styled.div`
  position: relative;
  ${({ hasTopbar }) => hasTopbar && `margin-top: -${TOPBAR_HEIGHT};`}
  flex: 0 0 ${({ width }) => `${width}px`};
  width: ${({ width }) => `${width}px`};
  margin-left: ${({ open, width }) => (open ? '0' : `-${width}px`)};
  transition: margin-left 0.5s ease 0s;
  // It needs to be in front of the button, but before TopBar
  z-index: 1;

  ${MediaPoint.MobileOnly} {
    width: 100vw;
    margin-left: ${({ open }) => (open ? '0' : `-100vw`)};
    position: fixed;
  }
`;

const ResizeHandle = styled.div`
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -6px;
  display: flex;
  justify-content: center;
`;

const ResizeHandleLine = styled.div`
  width: 4px;
  background-color: ${colors.neutral200};
  opacity: 0;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  transition: opacity 100ms;
  &:hover {
    opacity: 1;
  }z
`;

const ContentSidebar = ({
  children,
  hideOpenSidebarButton,
  hasTopbar,
  sidebarComponentContent,
  sidebarOpenState,
  updateStateFilter,
  renderSidebarFooter,
  showScroll,
}) => {
  // Start closed on mobile
  const [isSidebarOpen, setIsSidebarOpen] = useContentSidebarContext();
  const [sidebarOpenStateFromContent, setsidebarOpenStateFromContent] = sidebarOpenState;

  const [width, setWidth] = useState(CONTENT_SIDEBAR_MIN_WIDTH);
  const isResizing = useRef(false);

  /**
   *
   * @param {MouseEvent} e
   * @returns
   */
  const handleResize = (e) => {
    if (!isResizing.current) {
      return;
    }

    setWidth((prevWidth) => {
      let newWidth = prevWidth + e.movementX;
      newWidth = Math.min(newWidth, CONTENT_SIDEBAR_MAX_WIDTH);
      newWidth = Math.max(newWidth, CONTENT_SIDEBAR_MIN_WIDTH);

      return newWidth;
    });
  };

  const handleStopResizing = () => {
    isResizing.current = false;
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', handleStopResizing);

    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleStopResizing);
    };
  }, []);

  const handleOpen = () => {
    if (sidebarOpenState) {
      setsidebarOpenStateFromContent(true);
      updateStateFilter?.(false);
    } else {
      setIsSidebarOpen(true);
    }
  };

  const handleClose = () => {
    if (sidebarOpenState) {
      setsidebarOpenStateFromContent(false);
      updateStateFilter?.(true);
    } else {
      setIsSidebarOpen(false);
    }
  };

  return (
    <>
      {!hideOpenSidebarButton &&
        (sidebarComponentContent ? (
          sidebarComponentContent({ openSidebar: handleOpen })
        ) : (
          <SidebarButton onClick={handleOpen} icon="filter" text="Filter" />
        ))}

      <SidebarContainer
        id="sidebarContainer"
        open={sidebarOpenState ? sidebarOpenStateFromContent : isSidebarOpen}
        hasTopbar={hasTopbar}
        width={width}
      >
        <Sticky boundaryElement="#sidebarContainer" hideOnBoundaryHit={false}>
          <StyledFilterBar showScroll={showScroll}>
            <FixedWidthContainer hasTopbar={hasTopbar} width={width}>
              {children({ closeSidebar: handleClose, openSidebar: handleOpen })}
            </FixedWidthContainer>
            {renderSidebarFooter && (
              <FixedWidthContainer width={width}>{renderSidebarFooter()}</FixedWidthContainer>
            )}
          </StyledFilterBar>
        </Sticky>

        <ResizeHandle
          onMouseDown={() => {
            isResizing.current = true;
          }}
        >
          <ResizeHandleLine />
        </ResizeHandle>
      </SidebarContainer>
    </>
  );
};

ContentSidebar.propTypes = {
  hideOpenSidebarButton: PropTypes.bool,
  children: PropTypes.func,
  hasTopbar: PropTypes.bool,
  sidebarComponentContent: PropTypes.func,
  sidebarOpenState: PropTypes.array,
  updateStateFilter: PropTypes.func,
  renderSidebarFooter: PropTypes.func,
  showScroll: PropTypes.bool,
};

ContentSidebar.defaultProps = {
  showScroll: false,
};

const ContentSidebarWrapper = ({
  renderSidebarContent,
  renderSidebarFooter,
  hideOpenSidebarButton,
  children,
  hasTopbar,
  sidebarComponentContent,
  sidebarOpenState,
  updateStateFilter,
  showScroll,
}) => {
  if (!renderSidebarContent) {
    return children;
  }

  return (
    <SidebarWrapperComponent>
      <ContentSidebar
        hasTopbar={hasTopbar}
        hideOpenSidebarButton={hideOpenSidebarButton}
        sidebarComponentContent={sidebarComponentContent}
        sidebarOpenState={sidebarOpenState}
        updateStateFilter={updateStateFilter}
        renderSidebarFooter={renderSidebarFooter}
        showScroll={showScroll}
      >
        {renderSidebarContent}
      </ContentSidebar>
      {children}
    </SidebarWrapperComponent>
  );
};

ContentSidebarWrapper.propTypes = {
  renderSidebarContent: PropTypes.func,
  renderSidebarFooter: PropTypes.func,
  hideOpenSidebarButton: PropTypes.bool,
  children: PropTypes.node,
  hasTopbar: PropTypes.bool,
  sidebarComponentContent: PropTypes.func,
  sidebarOpenState: PropTypes.array,
  updateStateFilter: PropTypes.func,
  showScroll: PropTypes.bool,
};

ContentSidebarWrapper.propTypes = {};

export default ContentSidebarWrapper;
