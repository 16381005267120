import * as React from 'react';

import { useNewRqlFilters } from 'app/filters/hooks';
import { get, includes } from 'vendor/lodash';

import { channelHomeTabs } from '../constants';
import { ChannelHomeTab } from '../types';

export function useChannelHomeCurrentTab() {
  const { filters } = useNewRqlFilters();
  const tabFilterValue = get(filters, 'tab.$eq', '');

  const currentTab: ChannelHomeTab | null = React.useMemo(() => {
    if (includes(channelHomeTabs, tabFilterValue as ChannelHomeTab)) {
      return tabFilterValue as ChannelHomeTab;
    }

    return null;
  }, [tabFilterValue]);

  return currentTab;
}
