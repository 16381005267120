import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';
import { isEmpty } from 'vendor/lodash';

import OfficeHourContainer from './OfficeHourContainer';
import ResourceLinkContainer from './ResourceLinkContainer';
import SlackChanelContainer from './SlackChanelContainer';

const TitleWrapper = styled.div`
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  flex: 1;
  min-width: 40%;
  &:first-child:nth-last-child(3),
  &:first-child:nth-last-child(3) ~ & {
    min-width: 90%;
  }

  ${({ showContainer }) =>
    showContainer &&
    `
    background-color: ${colors.neutral0};
    border-radius: 8px;
    padding: 20px;
  `}
`;

const BaseResourceContainer = ({ content, showContainer }) => {
  return (
    <Container showContainer={showContainer}>
      <TitleWrapper>
        <Text medium>Resources</Text>
      </TitleWrapper>
      {content.office_hour && <OfficeHourContainer officeHour={content.office_hour} />}
      {content.slack_channel && <SlackChanelContainer slack_channel={content.slack_channel} />}
      {!isEmpty(content.resources) && <ResourceLinkContainer resources={content.resources} />}
    </Container>
  );
};

BaseResourceContainer.propTypes = {
  content: PropTypes.object.isRequired,
  showContainer: PropTypes.bool,
};

export default BaseResourceContainer;
