import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from 'services/requests-base';
import { noop } from 'vendor/lodash';

const listEndpoint = 'api_logtrail:logs-dashboard-detail';
const fetchFiltersEndpoint = 'api_logtrail:logs-dashboard-filters';

const fetchFilters = async () => {
  const url = ApiURLs[fetchFiltersEndpoint]();
  const { data } = await fetchURL(url);
  return data;
};

const list = async ({ search, nextPage, callbackFn }) => {
  const base = ApiURLs[listEndpoint]();
  const url = nextPage ? nextPage : `${base}?${search}`;
  const { data } = await fetchURL(url);
  callbackFn(data);
  return data;
};

export const logs = createQueryKeys('logs', {
  list: (search = '', callbackFn = noop) => ({
    queryKey: [search],
    queryFn: ({ pageParam: nextPage }) => list({ search, nextPage, callbackFn }),
  }),
  fetchFilters: () => ({
    queryKey: [fetchFiltersEndpoint],
    queryFn: () => fetchFilters(),
  }),
});

export type LogsQueryKeys = inferQueryKeys<typeof logs>;
