import { apiPatch } from "services/requests";
import { MutationOptions } from "@tanstack/react-query";
import { CompleteMultipleChoiceQuestionBody, CompleteTextQuestionBody } from "../types";

type BaseMutationOpts = MutationOptions<any, any, any, any>

const completeMultipleChoiceQuestionMutationOpts = {
  mutationFn: async (body: CompleteMultipleChoiceQuestionBody) => {
    const { data: response } = await apiPatch('questions_api:responses_completion', body.assignment_id, {
      content_type: body.content_type,
      option_id: body.option_id,
    });

    return response
  }
} satisfies BaseMutationOpts;

const completeTextQuestionMutationOpts = {
  mutationFn: async (body: CompleteTextQuestionBody) => {
    const { data: response } = await apiPatch('questions_api:responses_completion', body.assignment_id, {
      content_type: body.content_type,
      text_response: body.text_response,
    });

    return response;
  },
} satisfies BaseMutationOpts;

export const completeQuestionMutationOpts = {
  multipleChoice: completeMultipleChoiceQuestionMutationOpts,
  text: completeTextQuestionMutationOpts,
} 
