import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory, detailQueryKeyFactory } from 'app/data-fetching/utils';

export const videos = createQueryKeys('videos', {
  list: listQueryKeyFactory({ endpointName: 'videos_api:list_create' }),
  detail: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
});

export type VideosQueryKeys = inferQueryKeys<typeof videos>;
