import PropTypes from 'prop-types';
import React from 'react';
import { Field, Fields } from 'redux-form';

import AccessLevelFields from 'inputs/components/AccessLevelFields';
import CheckboxField from 'inputs/components/CheckboxField';
import { FormFieldGroup } from 'shared/components/Form';
import InfoBox from 'shared/components/InfoBox';

const AccessControlSection = ({
  showInfobox,
  showHideEnrolleesSection,
  contentNameSingular,
  accessLevelContentName,
  isAccessLevelContentNamePlural,
  accessLevelFieldsNamesList,
  hideEnrolleesName,
  hideEnrolleesLabel,
  hideEnrolleesInfoText,
  hideAttendeesName,
  hideAttendeesLabel,
  hideAttendeesInfoText,
  showAttendeesSection,
}) => (
  <>
    {showInfobox && (
      <InfoBox
        content={`Organizers will be able to override access settings on their ${contentNameSingular}s.`}
      />
    )}
    <Fields
      names={accessLevelFieldsNamesList}
      component={AccessLevelFields}
      contentNameSingular={accessLevelContentName || contentNameSingular}
      isAccessLevelContentNamePlural={isAccessLevelContentNamePlural}
      isNewForm
    />
    {showHideEnrolleesSection && (
      <FormFieldGroup>
        <Field
          name={hideEnrolleesName}
          type="checkbox"
          component={CheckboxField}
          label={hideEnrolleesLabel}
          infoText={hideEnrolleesInfoText}
          labelSize="h5"
          labelWeight="medium"
        />
      </FormFieldGroup>
    )}
    {showAttendeesSection && (
      <FormFieldGroup>
        <Field
          name={hideAttendeesName}
          type="checkbox"
          component={CheckboxField}
          label={hideAttendeesLabel}
          infoText={hideAttendeesInfoText}
          labelSize="h5"
          labelWeight="medium"
        />
      </FormFieldGroup>
    )}
  </>
);

AccessControlSection.propTypes = {
  showInfobox: PropTypes.bool,
  showHideEnrolleesSection: PropTypes.bool,
  contentNameSingular: PropTypes.string,
  accessLevelContentName: PropTypes.string,
  isAccessLevelContentNamePlural: PropTypes.bool,
  accessLevelFieldsNamesList: PropTypes.arrayOf(PropTypes.string),
  hideEnrolleesName: PropTypes.string,
  hideEnrolleesLabel: PropTypes.string,
  hideEnrolleesInfoText: PropTypes.string,
  hideAttendeesName: PropTypes.string,
  hideAttendeesLabel: PropTypes.string,
  hideAttendeesInfoText: PropTypes.string,
  showAttendeesSection: PropTypes.bool,
};

AccessControlSection.defaultProps = {
  showHideEnrolleesSection: true,
  accessLevelFieldsNamesList: ['is_private', 'groups_ids'],
  hideEnrolleesName: 'hide_enrollees',
  hideAttendeesName: 'hide_attendees',
  hideAttendeesLabel: 'Assignments List is Private',
  hideAttendeesInfoText:
    'Only admins, maintainers, and assignees see who is assigned to this content.',
  showAttendeesSection: true,
};

export default AccessControlSection;
