import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';

const StyledTooltip = styled(ReactTooltip)`
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'}
  max-width: ${({ maxWidth }) => maxWidth || 320}px;
  padding: 8px;

  &.show {
    opacity: 1;
  }

  &.type-dark.place-top {
    ::after {
      border-left-width: 6px;
      border-right-width: 6px;
      bottom: -5px;
    }
  }
`;

const TooltipTitle = styled.div`
  margin-bottom: 4px;
`;

const TooltipBody = styled.div``;

const TooltipLinkContainer = styled.div`
  margin-top: 4px;
`;

const TooltipLink = styled.a`
  text-decoration: underline;
  color: white;

  &:hover,
  &:focus {
    color: ${colors.neutral200};
  }
`;

export const Tooltip = ({
  title,
  link,
  linkLabel,
  children,
  showOnClick,
  hide,
  maxWidth,
  noWrap,
  ...props
}) => {
  const eventProps = {
    event: 'mouseenter focus',
    eventOff: 'mouseleave blur',
  };

  if (showOnClick) {
    eventProps.event = 'click';
  }

  return (
    <StyledTooltip
      {...props}
      {...eventProps}
      effect="solid"
      clickable
      delayHide={link ? 300 : null}
      maxWidth={maxWidth}
      noWrap={noWrap}
    >
      {(() => {
        // this is needed to fix getBoundingRect error
        if (hide) return null;

        return (
          <React.Fragment>
            {title && (
              <TooltipTitle>
                <Text size="h5" medium>
                  {title}
                </Text>
              </TooltipTitle>
            )}
            <TooltipBody>
              <Text size="h6">{children}</Text>
            </TooltipBody>
            {link && (
              <TooltipLinkContainer>
                <TooltipLink href={link} target="_blank" rel="noopener noreferrer">
                  {linkLabel}
                </TooltipLink>
              </TooltipLinkContainer>
            )}
          </React.Fragment>
        );
      })()}
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  maxWidth: PropTypes.number,
  showOnClick: PropTypes.bool,
  hide: PropTypes.bool,
  noWrap: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Tooltip.defaultProps = {
  linkLabel: 'Link',
  showOnClick: false,
  hide: false,
  noWrap: false,
};

export const RawTooltip = ReactTooltip;
