import { createActions } from 'redux-actions';

export default createActions(
  {},
  'CURRENT_USER_REQUEST',
  'CURRENT_USER_REQUEST_SUBMIT',
  'CURRENT_USER_REQUEST_SUCCESS',
  'CURRENT_USER_REQUEST_FAILURE',

  'CURRENT_USER_UPDATE_REQUEST',
  'CURRENT_USER_UPDATE_REQUEST_SUBMIT',
  'CURRENT_USER_UPDATE_REQUEST_SUCCESS',
  'CURRENT_USER_UPDATE_REQUEST_FAILURE'
);
