import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';
import { useLabels } from 'shared/hooks';

import LazyDropdownFilter from './LazyDropdownFilter';

export const EventTypeFilter = ({ param, ...props }) => {
  const { label_event_type_plural: labelEventTypePlural } = useLabels();

  return (
    <LazyDropdownFilter
      label={labelEventTypePlural}
      param={param}
      actionFunc={actions.eventType.retrieveList}
      {...props}
    />
  );
};

EventTypeFilter.propTypes = {
  param: PropTypes.string,
};

EventTypeFilter.defaultProps = {
  param: 'event_types',
};

export default EventTypeFilter;
