import { useQuery } from '@tanstack/react-query';

import { queries } from 'queries';
import { map } from 'vendor/lodash';

import AutocompleteField from '../AutocompleteField';

interface MenteeOption {
  value: number;
  image: string;
  label: string;
}

interface MenteeSelectFieldProps {
  program_id: string;
  input: any;
  meta: any;
}

const MenteeSelectField = (props: MenteeSelectFieldProps) => {
  const { program_id } = props;

  const makeOptions = (data): MenteeOption[] => {
    return map(data.results, (row) => ({
      value: row.mentee.id,
      image: row.mentee.profile_image,
      label: row.mentee.display_name,
    }));
  };

  const { data, isLoading } = useQuery({
    ...queries.mentorship_program.mentees(program_id, ''),
    select: makeOptions,
  });

  return <AutocompleteField options={data} loading={isLoading} {...props} />;
};

export default MenteeSelectField;
