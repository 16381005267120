import PropTypes from 'prop-types';

import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_EXIT_WAIT_LIST,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
} from 'event-shared/constants';
import {
  getEnrollmentConditions,
  getEventStatus,
  getSpotsStatus,
  getUserEnrollmentStatus,
} from 'event-shared/services';
import { constant, get } from 'vendor/lodash';

export const EnrollmentActionButton = ({
  event,
  enrollmentStatus,
  allowCancellation,
  onEnrollmentStatusChange,

  renderProspect,
  renderWaitlist,
  renderEnrolled,

  renderFullCapacity,
  renderUncancellableEnroll,
  renderEventCancelled,
}) => {
  const handleEnrollmentStatusChange = (status) => onEnrollmentStatusChange(status);

  const performUnenroll = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_NOT_GOING);

  const performLeaveWaitlist = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_EXIT_WAIT_LIST);

  const performJoinLocalWaitlist = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_WAIT_LIST);

  const performJoinOnlineWaitlist = () =>
    handleEnrollmentStatusChange(ENROLLMENT_STATUS_WAIT_LIST_ONLINE);

  const performOnlineEnrollment = () =>
    handleEnrollmentStatusChange(ENROLLMENT_STATUS_GOING_ONLINE);

  const performLocalEnrollment = () => handleEnrollmentStatusChange(ENROLLMENT_STATUS_GOING);

  const enrollStatus = getUserEnrollmentStatus(enrollmentStatus);
  const eventStatus = getEventStatus(event);
  let status = {
    ...enrollStatus,
    ...eventStatus,
    permissions: get(event, 'permissions', []),
  };
  const enrollConditions = getEnrollmentConditions(status);
  status = {
    ...status,
    ...enrollConditions,
  };
  const spotStatus = getSpotsStatus(event);

  if (status.eventIsCancelled) {
    return renderEventCancelled();
  }

  // If user is not enrolled and event is full shows disabled button
  if (!enrollStatus.userIsEnrolled && !enrollStatus.userIsOnWaitlist && eventStatus.eventIsFull) {
    return renderFullCapacity();
  }

  // If user is enrolled but can't cancel enrollment shows disabled button
  if (enrollStatus.userIsEnrolled && !allowCancellation) {
    return renderUncancellableEnroll();
  }

  const actions = {
    performLocalEnrollment,
    performOnlineEnrollment,
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
    performLeaveWaitlist,
    performUnenroll,
  };

  if (status.userIsOnWaitlist) {
    return renderWaitlist(actions, status, spotStatus);
  }

  if (status.userIsEnrolled) {
    return renderEnrolled(actions, status, spotStatus);
  }

  return renderProspect(actions, status, spotStatus);
};

EnrollmentActionButton.propTypes = {
  enrollmentStatus: PropTypes.string,
  event: PropTypes.object,

  extraLocalCondition: PropTypes.func,
  extraOnlineCondition: PropTypes.func,

  allowCancellation: PropTypes.bool,
  onEnrollmentStatusChange: PropTypes.func,

  renderProspect: PropTypes.func,
  renderWaitlist: PropTypes.func,
  renderEnrolled: PropTypes.func,

  renderEventCancelled: PropTypes.func,
  renderFullCapacity: PropTypes.func,
  renderUncancellableEnroll: PropTypes.func,
};

EnrollmentActionButton.defaultProps = {
  renderFullCapacity: constant(null),
  renderEventCancelled: constant(null),
  renderUncancellableEnroll: constant(null),

  renderProspect: constant(null),
  renderWaitlist: constant(null),
  renderEnrolled: constant(null),
};

export default EnrollmentActionButton;
