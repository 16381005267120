import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography, Divider } from '@mui/material';
import { red } from '@mui/material/colors';
import * as React from 'react';
import { Link } from 'react-router-dom';

import colors from 'services/colors';
import { useHasChannelPermission, useCurrentUser } from 'shared/hooks';
import { CREATE_CHANNEL_PERMISSION, MANAGE_CHANNEL_PERMISSION } from 'shared/permissions';
import { includes } from 'vendor/lodash';

import { useChannelRoutes } from '../../hooks/useChannelRoutes';
import { Channel } from '../../types';
import ChannelDeleteModal from '../ChannelDeleteModal';

interface ChannelContextMenuProps {
  channel: Channel;
}

function ChannelContextMenu(props: ChannelContextMenuProps) {
  const { channel } = props;

  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;
  const isDefaultChannel = channel.id == defaultChannelId;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleToggleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { slug: channelSlug, permissions: channelPermissions } = channel;
  const canEdit = includes(channelPermissions, MANAGE_CHANNEL_PERMISSION);
  const canDelete = useHasChannelPermission(CREATE_CHANNEL_PERMISSION) && !isDefaultChannel;
  const { edit: channelEditRoute } = useChannelRoutes({ withOriginRoute: true });

  const wrapMenuClose = (callback) => {
    return (event) => {
      handleCloseMenu();
      callback(event);
    };
  };

  const isMenuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <IconButton
        id="context-menu-button"
        size="small"
        aria-controls={isMenuOpen ? 'context-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleToggleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="context-menu"
        aria-labelledby="context-menu-button"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenu-paper': {
            width: '219px',
            maxWidth: '100%',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {canEdit && (
          <MenuItem component={Link} to={channelEditRoute({ slug: channelSlug })}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1" component="span" color={colors.neutral600}>
              Edit
            </Typography>
          </MenuItem>
        )}

        {canEdit && canDelete && <Divider />}

        {canDelete && (
          <MenuItem onClick={wrapMenuClose(() => setShowDeleteModal(true))}>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" sx={{ color: red[400] }} />
            </ListItemIcon>
            <Typography variant="body1" component="span" color={red[400]}>
              Delete
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {showDeleteModal && (
        <ChannelDeleteModal channel={channel} handleClose={() => setShowDeleteModal(false)} />
      )}
    </>
  );
}

export default ChannelContextMenu;
