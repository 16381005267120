import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentActionsCtx } from 'features/contentitems/contexts/AssignmentActionsCtx';
import { ContentItem } from 'shared-content-item/interfaces';
import Button from 'shared/components/Button';
import { get, isNil } from 'vendor/lodash';
import { CheckIcon } from 'vendor/mui-icons';

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;

  const { create, complete, isLoading } = useAssignmentActionsCtx();

  const assignmentState = get(assignment, 'state');

  const handleOnMarkComplete = () => {
    if (isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped) {
      create({ is_completed: true });
      return;
    }

    complete();
  };

  if (!isCompletable || assignmentState === ASSIGNMENT_STATES.completed) {
    return null;
  }

  return (
    <Button
      variant="contained"
      startIcon={<CheckIcon />}
      onClick={handleOnMarkComplete}
      disabled={isLoading}
    >
      Mark Complete
    </Button>
  );
};

export default PrimaryActionButton;
