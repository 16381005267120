import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';

import Icon from 'app/shared/components/Icon';
import TooltipChip from 'app/shared/components/TooltipChip';
import {
  map,
  includes,
  debounce,
  filter,
  isEmpty,
  replace,
  noop,
  differenceBy,
  find,
  toLower,
  isNil,
  isArray,
  isEqual,
} from 'vendor/lodash';
import { Autocomplete as MuiAutocomplete, Box, CircularProgress, Avatar } from 'vendor/mui';

import TextInput from '../TextInput';

const Autocomplete = ({
  id,
  name,
  value,
  label,
  inputMinWidth,
  onChange,
  error,
  placeholder,
  multiple,
  creatable,
  options,
  loading,
  fetchOptions: updateFilter,
  renderTags,
  renderOption,
  showSelectedOptionsFirst,
  getOptionDisabled,
  freeSolo,
  createFreeSoloOption,
  required,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [createdOptions, setCreatedOptions] = useState([]);
  const [firstValueChange, setFirstValueChange] = useState(true);

  const [searchText, setSearchText] = useState('');

  const [focused, setFocused] = useState(false);

  const handleOnFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);

    if (searchText && freeSolo) {
      const selectedOptions = mapValuesToOptions([searchText]);

      if (isEmpty(selectedOptions)) {
        const newOption = createFreeSoloOption(searchText);
        setCreatedOptions([...createdOptions, newOption]);
        selectedOptions.push(newOption);
      }

      setSelectedOptions(selectedOptions);
      onChange(toLower(searchText));
    }

    setSearchText('');
  };

  const updateSearchText = (event) => {
    if (event?.target) {
      setSearchText(event.target.value || '');
    }
  };

  const getUnlistedSelectedOptions = () => {
    return differenceBy(selectedOptions, options, 'value');
  };

  const getUnselectedOptions = () => {
    return differenceBy(options, selectedOptions, 'value');
  };

  const getAvailableOptions = () => {
    const sortedOptions = showSelectedOptionsFirst
      ? [...selectedOptions, ...getUnselectedOptions()]
      : options;

    return [...sortedOptions, ...createdOptions, ...getCreateNewOption()];
  };

  const getSelectedValue = () => {
    if (multiple) {
      const allOptions = [...getUnlistedSelectedOptions(), ...options, ...createdOptions];

      const filteredValues = filter(allOptions, (o) => {
        return includes(value, o.value) || includes(value, o.label);
      });

      if (isEmpty(filteredValues)) {
        return [];
      }

      const selectedOptions = map(value, (val) => {
        return find(filteredValues, (o) => o.value === val || o.label === val);
      });

      return selectedOptions;
    }

    const found =
      find(
        [...getUnlistedSelectedOptions(), ...options, ...createdOptions],
        (o) => o.value === value || o.label === value
      ) || null;

    if (value && !found && freeSolo) {
      setCreatedOptions([...createdOptions, createFreeSoloOption(value)]);
      return value;
    }

    return found;
  };

  const selectedValue = getSelectedValue();

  const getCreateNewOption = () => {
    if (!searchText || !creatable) return [];

    const matchingOptions =
      filter([...options, ...createdOptions], (o) => {
        return searchText === o.value;
      }) || [];

    if (isEmpty(matchingOptions)) {
      return [{ value: searchText, label: `Add ${searchText}`, dynamic: true }];
    }

    return [];
  };

  const handleDelete = (valueToRemove) => {
    setSelectedOptions(filter(selectedOptions, ({ value }) => value !== valueToRemove));

    const updatedOptions = filter(value, (entry) => entry !== valueToRemove);
    onChange(updatedOptions);

    const updatedCreatedOptions = filter(createdOptions, (entry) => entry.value !== valueToRemove);
    setCreatedOptions(updatedCreatedOptions);
  };

  const mapValuesToOptions = (values) => {
    return filter(options, (option) => includes(values, option.value));
  };

  const fetchOptions = useCallback(debounce(updateFilter || noop, 500), []);

  useEffect(() => {
    if (firstValueChange) {
      setFirstValueChange(false);
    }
  }, [value]);

  useEffect(() => {
    const includeValues = multiple && isArray(value) ? value.join(',') : value;
    fetchOptions({ q: searchText, includeValues, selectedOptions });
  }, [searchText, firstValueChange]);

  return (
    <MuiAutocomplete
      disableCloseOnSelect={Boolean(multiple)}
      {...props}
      multiple={multiple}
      value={selectedValue}
      id={id || name}
      title={id || name}
      name={name}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={(option) => option?.label || ''}
      renderTags={
        renderTags ||
        ((optionList) => {
          return map(optionList, (entry) => {
            if (!entry) return null;

            return (
              <TooltipChip
                size="small"
                icon={entry.icon ? <Icon name={entry.icon} /> : null}
                avatar={entry.image ? <Avatar src={entry.image} /> : null}
                key={entry.value}
                tabIndex={-1}
                label={entry.label}
                onDelete={multiple ? () => handleDelete(entry.value) : undefined}
                sx={{ maxWidth: '50%' }}
              />
            );
          });
        })
      }
      options={getAvailableOptions()}
      {...(Boolean(updateFilter) && { filterOptions: (options) => options })}
      loading={loading}
      renderOption={
        renderOption ||
        ((props, option) => (
          <Box component="li" {...props} key={`${name}-${option.value}`}>
            {option.icon && <Icon name={option.icon} />}
            {option.image && <Avatar src={option.image} sx={{ mr: 1, width: 24, height: 24 }} />}
            {option.label}
          </Box>
        ))
      }
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      onChange={(e, newValue) => {
        if (multiple) {
          // deal with dynamic values
          if (creatable) {
            const dynamicValues = filter(newValue, (entry) => entry.dynamic);
            if (!isEmpty(dynamicValues)) {
              setCreatedOptions([
                ...createdOptions,
                ...map(dynamicValues, ({ dynamic, ...entry }) => ({
                  ...entry,
                  label: replace(entry.label, 'Add ', ''),
                })),
              ]);
            }
          }

          // update values array
          const selectedOptionsValues = map(newValue, (entry) => entry?.value || entry);
          setSelectedOptions(mapValuesToOptions(selectedOptionsValues));
          onChange(newValue ? selectedOptionsValues : undefined);
        } else {
          if (creatable && newValue?.dynamic) {
            const { dynamic, label, ...entry } = newValue;

            setCreatedOptions([
              ...createdOptions,
              {
                ...entry,
                label: replace(label, 'Add ', ''),
              },
            ]);
          }

          setSelectedOptions(mapValuesToOptions(!isNil(newValue?.value) ? [newValue.value] : []));
          onChange(newValue?.value || null);
        }

        setSearchText('');
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          required={required}
          onChange={updateSearchText}
          onKeyDown={(event) => {
            if (multiple && event.key === 'Backspace' && event.target.selectionStart !== 0) {
              event.stopPropagation();
            }
          }}
          sx={{
            // Fixes the loading icon position. size='small' currently breaks the loading position
            '& > .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
              minWidth: inputMinWidth,
            },
            '& > .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingRight: '39px',
            },
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          error={Boolean(error)}
          helperText={error}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            value: searchText && focused ? searchText : params.inputProps.value,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

Autocomplete.defaultProps = {
  label: null,
  inputMinWidth: 'unset',
  limitTags: 1,
  size: 'small',
  options: [],
  fetchOptions: null,
  disablePortal: true,
  freeSolo: false,
  getOptionDisabled: (option) => option.disabled,
  createFreeSoloOption: (label) => ({ label, value: toLower(label) }),
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  // min value of the input element (not the field)
  inputMinWidth: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  multiple: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['small', 'medium'])]),
  limitTags: PropTypes.number,
  creatable: PropTypes.bool,
  options: PropTypes.array,
  loading: PropTypes.bool,
  fetchOptions: PropTypes.func,
  renderTags: PropTypes.func,
  renderOption: PropTypes.func,
  disablePortal: PropTypes.bool,
  showSelectedOptionsFirst: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  createFreeSoloOption: PropTypes.func,
  freeSolo: PropTypes.bool,
  required: PropTypes.bool,
};

export default Autocomplete;
