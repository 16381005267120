import { ATTENDANCE_METHODS, ATTENDANCE_METHODS_LABELS } from 'app/enrollments/constants';
import { MY_UPCOMING_TYPES, MY_UPCOMING_TYPES_LABELS } from 'features/my_upcoming/constants';
import { FilterDef } from 'filters/components/RQLFilterBar/RQLFilterBar';
import { FiltersDefinition, FiltersDefinitionProps } from 'filters/types';
import { getFlexibleFiltersDef, getOrderedFiltersDefinition } from 'filters/utils';
import { useCurrentUser, useModuleToggles } from 'shared/hooks';
import { get, reduce, toString } from 'vendor/lodash';
import { useMediaQuery } from 'vendor/mui';

const useMyUpcomingFiltersDefinition = ({
  filters,
  ordering,
  updateFilter,
  setOrdering,
  fixedFilters,
}: FiltersDefinitionProps): FiltersDefinition => {
  const currentUser = useCurrentUser();
  const { mentorship: toggleMentorship } = useModuleToggles();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  // Search, Sort and Flexible Filters are added automatically.
  const defaultFiltersOrder = [
    'Type',
    'Attendance',
    'Ownership',
    'Engagement',
    'Dates',
    'Location',
    'Categories',
    'Tags',
  ];

  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by names, tags, locations...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    Type: {
      type: 'select',
      label: 'Type',
      value: get(filters, 'type', null),
      multiple: true,
      options: [
        { name: MY_UPCOMING_TYPES_LABELS[MY_UPCOMING_TYPES.event], value: MY_UPCOMING_TYPES.event },
        ...(toggleMentorship
          ? [
              {
                name: MY_UPCOMING_TYPES_LABELS[MY_UPCOMING_TYPES.mentorship],
                value: MY_UPCOMING_TYPES.mentorship,
              },
            ]
          : []),
        {
          name: MY_UPCOMING_TYPES_LABELS[MY_UPCOMING_TYPES.one_on_one_session],
          value: MY_UPCOMING_TYPES.one_on_one_session,
        },
        {
          name: MY_UPCOMING_TYPES_LABELS[MY_UPCOMING_TYPES.group_session],
          value: MY_UPCOMING_TYPES.group_session,
        },
      ],
      onChange: (newValue) => updateFilter({ type: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Attendance: {
      type: 'select',
      label: 'Attendance',
      value: get(filters, 'attendance_method', null),
      options: [
        {
          name: ATTENDANCE_METHODS_LABELS[ATTENDANCE_METHODS.online],
          value: ATTENDANCE_METHODS.online,
        },
        {
          name: ATTENDANCE_METHODS_LABELS[ATTENDANCE_METHODS.local],
          value: ATTENDANCE_METHODS.local,
        },
      ],
      onChange: (newValue) => updateFilter({ attendance_method: newValue }),
      gridProps: {
        xs: 2,
      },
    },
    Ownership: {
      type: 'model_select',
      label: 'Ownership',
      value: get(filters, 'facilitator', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ facilitator: newValue }),
    },
    Engagement: {
      type: 'model_select',
      label: 'Engagement',
      value: get(filters, 'attendee', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ attendee: newValue }),
    },
    Sort: {
      type: 'select',
      label: 'Sort by',
      value: { $eq: ordering },
      options: [
        { name: 'Date', value: 'start_time' },
        { name: 'A-Z', value: 'name' },
        { name: 'Z-A', value: '-name' },
      ],
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
      onChange: (newValue) => setOrdering(get(newValue, '$eq', 'start_time') as string),
    },
    Dates: {
      type: 'date_range',
      label: 'Dates',
      value: get(filters, 'start_time', null),
      onChange: (newValue) => updateFilter({ start_time: newValue }),
    },
    Location: {
      type: 'model_select',
      label: 'Location',
      value: get(filters, 'location', null),
      multiple: true,
      sortOptions: true,
      queryName: 'locations',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ location: newValue }),
    },
    Categories: {
      type: 'model_select',
      label: 'Categories',
      value: get(filters, 'category', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      queryParams: { tag_type: 'main_topics' },
      onChange: (newValue) => updateFilter({ category: newValue }),
    },
    Tags: {
      type: 'model_select',
      label: 'Tags',
      value: get(filters, 'tag', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      onChange: (newValue) => updateFilter({ tag: newValue }),
    },
    ...reduce(
      getFlexibleFiltersDef({
        filters,
        updateFilter,
        currentUser,
        toggleTypes: ['toggle_events', 'toggle_coaching'],
      }),
      (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
      {}
    ),
  };

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_my_upcoming',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '100%',
    fixedFilters,
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};

export default useMyUpcomingFiltersDefinition;
