import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import { convertTextToDraftJSBlocks } from 'inputs/components/LazyTextEditor/services';
import { LinkedContent, LinkedContentMetadata } from 'linkedcontent/interfaces';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { User } from 'shared/components/types';
import { INITIAL_DURATIONS } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import { has } from 'vendor/lodash';

import LinkedContentForm from './LinkedContentForm';

interface BaseInitialValues {
  currentUser: User;
  defaultSettings?: Record<string, any>;
  defaultChannelId?: any;
}

interface CreateInitialValues extends BaseInitialValues {
  linkedcontent?: never;
  metadata: LinkedContentMetadata;
  url: string;
}

interface EditInitialValues extends BaseInitialValues {
  linkedcontent?: LinkedContent;
  metadata: never;
  url: never;
}

const getInitialValues = ({
  currentUser,
  defaultSettings,
  linkedcontent,
  metadata,
  url,
  defaultChannelId,
}: CreateInitialValues | EditInitialValues) => {
  // TODO typing https://app.asana.com/0/0/1204714157347136/f
  let initialValues = {
    ...linkedcontent,
  };

  if (metadata || url) {
    initialValues = {
      ...initialValues,
      name: metadata?.title,
      provider: metadata?.site_name,
      content_body: JSON.stringify(convertTextToDraftJSBlocks(metadata?.description)),
      cover: metadata?.image_url,
      duration: metadata?.duration ? metadata.duration : INITIAL_DURATIONS.linkedcontent,
      tags: metadata?.tags,
      url,
    };
  }

  // Facilitators
  if (!has(initialValues, 'facilitators_ids')) {
    initialValues.facilitators_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // If is_completable is not defined, set it as initially true
  if (!has(initialValues, 'is_completable')) {
    initialValues.is_completable = true;
    initialValues.completion_policy = 'mark_completion';
  }

  // Default duration for LinkedContent is 10min
  if (!has(initialValues, 'duration')) {
    initialValues.duration = INITIAL_DURATIONS.linkedcontent;
  }

  // If the duration comes as "zero", set it as null so the frontend
  // won't enforce the field to require a formatting even though it's empty
  if (initialValues.duration === '00:00:00') {
    initialValues.duration = undefined;
  }

  // Settings
  if (!has(initialValues, 'settings')) {
    initialValues.settings = defaultSettings;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

interface LinkedContentFormPageProps {
  pageTitle: string;
  topBarActionName: string;
  backRoute: string;
  breadcrumbsItemList: object[];
  initialValuesParams: any;
}

const LinkedContentFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams = {},
  initialValuesParams: { linkedcontent },
}: LinkedContentFormPageProps) => {
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const initialValues = getInitialValues({
    currentUser,
    defaultChannelId,
    ...initialValuesParams,
  });

  const isEdit = Boolean(linkedcontent);
  const form = `newLinkedContent${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <LinkedContentForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) =>
          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          })
        }
      />
    </>
  );
};

export default LinkedContentFormPage;
