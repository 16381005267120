import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import actions from 'entities/actions';
import { surveyRelationshipSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { Button } from 'shared';
import { STATUS_LOADING } from 'shared/constants';
import { useEncodedCurrentRoute } from 'shared/hooks';
import SurveyModal from 'surveys/components/SurveyModal';
import EventSurveyModalItem from 'surveys/components/SurveyModal/EventSurveyModalItem';
import { SurveyRelationshipEntityType, SurveyRelationshipTypeLabelMap } from 'surveys/constants';
import { get, isEqual, toLower } from 'vendor/lodash';

const EventSurveyModal = ({ event, handleClose }) => {
  const { id: eventId } = event;
  const currentRoute = useEncodedCurrentRoute();

  const [
    fetchEventSurveys,
    { data: eventSurveys, status: eventSurveysStatus, count: eventSurveysCount },
  ] = useEntities(actions.surveyRelationship.retrieveList, null, {
    schema: [surveyRelationshipSchema],
  });

  useEffect(() => {
    fetchEventSurveys({ event: eventId, o: 'survey_schedule_date', view_mode: 'surveys_dialog' });
  }, []);

  const isLoading = isEqual(eventSurveysStatus, STATUS_LOADING);
  const entityLabel = toLower(SurveyRelationshipTypeLabelMap[SurveyRelationshipEntityType.EVENT]);

  const eventAttendeesCount =
    get(event, 'going_local_enrollments_count', 0) +
    get(event, 'going_online_enrollments_count', 0);

  const buttonSX = {
    '&:hover': {
      color: colors.success600,
    },
  };
  return (
    <SurveyModal
      entityLabel={entityLabel}
      handleClose={handleClose}
      isLoading={isLoading}
      surveys={eventSurveys}
      surveysCount={eventSurveysCount}
      renderItem={(surveyRelationship, isLast) => (
        <EventSurveyModalItem
          key={surveyRelationship.id}
          attendeesCount={eventAttendeesCount}
          surveyRelationship={surveyRelationship}
          isLast={isLast}
          eventId={event.id}
        />
      )}
      renderTopButton={() => (
        <Button
          sx={buttonSX}
          icon="external"
          route={`${mapRoute('eventEdit', {
            public_id_and_slug: event.public_id_and_slug,
          })}?origin=${currentRoute}#surveys`}
          target="_blank"
        >
          Edit Surveys Schedule
        </Button>
      )}
    />
  );
};

EventSurveyModal.propTypes = {
  event: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EventSurveyModal;
