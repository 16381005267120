import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import React from 'react';

import { LEARNING_TYPES, CHANNEL } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import { useChannelRoutes } from 'features/channels/hooks/useChannelRoutes';
import {
  LearningTypeMenuItem,
  LearningMenuItem,
} from 'features/contentitems/components/LearningTypeMenuItem';
import { useCreateLearningPermissions } from 'features/contentitems/hooks/useCreateLearningPermissions';
import { mapRoute } from 'services/requests';
import Icon from 'shared/components/Icon';
import { useCurrentUser, useEncodedCurrentRoute, useModuleToggles, useLabels } from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { includes } from 'vendor/lodash';
import { AddIcon } from 'vendor/mui-icons';

const AllLearningsButton = (props) => {
  const { onCodelabClickCallback, onCourseClickCallback, onLinkedContentClickCallback } = props;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const { label_channel: labelChannel } = useLabels();
  const { create: channelCreateRoute } = useChannelRoutes({ withOriginRoute: true });

  const [canCreateAnyLearningType, permissions] = useCreateLearningPermissions();

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const wrapMenuClose = (callback) => {
    return (event) => {
      handleCloseMenu();
      callback(event);
    };
  };

  if (!canCreateAnyLearningType) {
    return null;
  }

  return (
    <>
      <Button
        id="new-content-item-button"
        variant="contained"
        aria-label="New Content Item"
        aria-controls={isMenuOpen ? 'new-content-item-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleOpenMenu}
        startIcon={<AddIcon />}
      >
        New
      </Button>

      <Menu
        id="new-content-item-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'new-content-item-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {permissions.canCreateEventType && <LearningTypeMenuItem type="event_types" />}
        {permissions.canCreateProgram && <LearningTypeMenuItem type="programs" />}
        {permissions.canCreateMentorshipProgram && (
          <LearningTypeMenuItem type="mentorship_programs" />
        )}
        {permissions.canCreateTrack && <LearningTypeMenuItem type="tracks" />}
        {permissions.canCreateArticle && <LearningTypeMenuItem type="articles" />}
        {permissions.canCreateCodelab && (
          <LearningTypeMenuItem type="codelabs" onClick={wrapMenuClose(onCodelabClickCallback)} />
        )}
        {permissions.canCreateCourses && (
          <LearningTypeMenuItem type="courses" onClick={wrapMenuClose(onCourseClickCallback)} />
        )}
        {permissions.canCreateVideo && <LearningTypeMenuItem type="videos" />}
        {permissions.canCreateAssessment && <LearningTypeMenuItem type="assessments" />}
        {permissions.canCreateLinkedContent && (
          <LearningTypeMenuItem
            type="linkedcontent"
            onClick={wrapMenuClose(onLinkedContentClickCallback)}
          />
        )}
        {permissions.canCreateChannel && (
          <LearningMenuItem
            title={labelChannel}
            route={channelCreateRoute}
            icon={CHANNEL.icon}
            color={CHANNEL.color}
            aria-label="New Channel"
          />
        )}
      </Menu>
    </>
  );
};

AllLearningsButton.propTypes = {
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};

function SingleLearningBtn(props) {
  return (
    <Button
      startIcon={<Icon name="plus" />}
      variant="contained"
      sx={{
        height: 40,
      }}
      {...props}
    />
  );
}

const SingleLearningButton = ({
  learningType,
  onCodelabClickCallback,
  onCourseClickCallback,
  onLinkedContentClickCallback,
}) => {
  const currentRoute = useEncodedCurrentRoute();
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();
  const { programs: togglePrograms, tracks: toggleSnippets } = useModuleToggles();

  const learningTypeLabels = useLearningTypeLabels();

  const canCreateEventType = includes(
    permissions,
    permissionConstants.CREATE_EVENT_TYPE_PERMISSION
  );
  const canCreateProgram =
    (togglePrograms || includes(catalogSectionsOrder, LEARNING_TYPES.programs)) &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION);
  const canCreateTrack =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tracks)) &&
    includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION);
  const canCreateArticle =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
    includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION);
  const canCreateCodelab =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.codelabs)) &&
    includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION);
  const canCreateCourses =
    toggleCourses &&
    includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
    includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION);
  const canCreateVideo =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
    includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION);
  const canCreateAssessment =
    includes(catalogSectionsOrder, LEARNING_TYPES.assessments) &&
    includes(permissions, permissionConstants.CREATE_ASSESSMENT_PERMISSION);
  const canCreateLinkedContent =
    includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
    includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION);

  if (learningType === LEARNING_TYPES.event_types && canCreateEventType) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('eventTypeCreate')}?origin=${currentRoute}`}
        aria-label="New Event Type"
      >
        New {learningTypeLabels[LEARNING_TYPES.event_types]}
      </SingleLearningBtn>
    );
  }

  if (learningType === LEARNING_TYPES.programs && canCreateProgram) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('programCreate')}?origin=${currentRoute}`}
        aria-label="New Program"
      >
        New {learningTypeLabels[LEARNING_TYPES.programs]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.tracks && canCreateTrack) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('trackCreate')}?origin=${currentRoute}`}
        aria-label="New Track"
      >
        New {learningTypeLabels[LEARNING_TYPES.tracks]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.articles && canCreateArticle) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('articleCreate')}?origin=${currentRoute}`}
        aria-label="New Article"
      >
        New {learningTypeLabels[LEARNING_TYPES.articles]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.codelabs && canCreateCodelab) {
    return (
      <SingleLearningBtn onClick={onCodelabClickCallback} aria-label="New Codelab">
        New {learningTypeLabels[LEARNING_TYPES.codelabs]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.courses && canCreateCourses) {
    return (
      <SingleLearningBtn onClick={onCourseClickCallback} aria-label="New Course">
        New Course
      </SingleLearningBtn>
    );
  }

  if (learningType === LEARNING_TYPES.videos && canCreateVideo) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('videoCreate')}?origin=${currentRoute}`}
        aria-label="New Video"
      >
        New Video
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.assessments && canCreateAssessment) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('assessmentCreate')}?origin=${currentRoute}`}
        aria-label="New Assessment"
      >
        New {learningTypeLabels[LEARNING_TYPES.assessments]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.linkedcontent && canCreateLinkedContent) {
    return (
      <SingleLearningBtn onClick={onLinkedContentClickCallback} aria-label="New Link">
        New {learningTypeLabels[LEARNING_TYPES.linkedcontent]}
      </SingleLearningBtn>
    );
  }

  return null;
};

SingleLearningButton.propTypes = {
  learningType: PropTypes.string,
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};

const NewLearningButton = ({
  learningType,
  onCodelabClickCallback,
  onCourseClickCallback,
  onLinkedContentClickCallback,
}) => {
  if (learningType) {
    return (
      <SingleLearningButton
        learningType={learningType}
        onCodelabClickCallback={onCodelabClickCallback}
        onCourseClickCallback={onCourseClickCallback}
        onLinkedContentClickCallback={onLinkedContentClickCallback}
      />
    );
  }

  return (
    <AllLearningsButton
      onCodelabClickCallback={onCodelabClickCallback}
      onCourseClickCallback={onCourseClickCallback}
      onLinkedContentClickCallback={onLinkedContentClickCallback}
    />
  );
};

NewLearningButton.propTypes = {
  learningType: PropTypes.string,
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};
export default NewLearningButton;
