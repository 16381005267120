import React from 'react';
import styled from 'styled-components';

import CardAvatar from '../CardAvatar';

export const imageSize = '48px';

export const AvatarTitleContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 0px;
`;

const CardAvatarWrapper = styled.div`
  height: ${imageSize};
  width: ${imageSize};
  margin: 15px;
  display: inline-block;
`;

export const StyledCardAvatar = (props) => (
  <CardAvatarWrapper>
    <CardAvatar {...props} />
  </CardAvatarWrapper>
);

export const HeaderTextContainer = styled.div`
  overflow: hidden;
  display: inline-block;
  width: calc(100% - ${(props) => props.offset}px);
  margin-left: ${(props) => (props.offset > 0 ? 0 : 10)}px;
  vertical-align: top;
  ${(props) => (props.mode === 'card' ? 'margin-top: 3px;' : '')}
  ${(props) => (props.mode === 'card' ? 'max-height: 80px;' : '')}
`;
