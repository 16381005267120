import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';

const BaseTaskConverterModal = ({ content, title, handleClose, modalBodyContent }) => {
  const [convertContent, { status }] = useEntities(actions.task.convert, () => {
    if (status === STATUS_DONE) {
      handleClose();
      toast.success('Content converted successfully!');
      window.location.reload();
    }
  });

  const convert = () =>
    convertContent({ content_type: content.content_type, content_public_id: content.public_id });

  return (
    <Modal title={title} handleClose={handleClose}>
      <ModalBody>{modalBodyContent}</ModalBody>
      <ModalFooter variant="buttons">
        {status === STATUS_LOADING ? (
          <Loading hasMargin={false} />
        ) : (
          <>
            <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
            <ModalFooterButton variant="secondary" onClick={convert}>
              Convert
            </ModalFooterButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

BaseTaskConverterModal.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  modalBodyContent: PropTypes.element,
};

export default BaseTaskConverterModal;
