import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody } from 'shared/components/Modal';
import { useCurrentUser } from 'shared/hooks';
import { includes } from 'vendor/lodash';

import BulkAddUsersForm from './BulkAddUsersForm';

const BulkAddUsersModal = ({ show, handleClose, onSubmitSuccess }) => {
  const currentUser = useCurrentUser();

  if (!includes(['admin', 'organizer'], currentUser.role)) {
    return null;
  }

  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Bulk-Add People"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <BulkAddUsersForm onSubmitSuccess={onSubmitSuccess} />
      </ModalBody>
    </Modal>
  );
};

BulkAddUsersModal.propTypes = {
  show: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
  handleClose: PropTypes.func,
};

export default BulkAddUsersModal;
