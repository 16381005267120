import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  invitedUsers: null,
  invitedEmails: null,
};

export default handleActions(
  {
    [actions.inviteToEventFormSuccess]: (state, action) => ({
      ...state,
      invitedUsers: action.payload.invited_users,
      invitedEmails: action.payload.invited_emails,
    }),
  },
  defaultState
);
