import moment from 'moment';

import { capitalize } from 'vendor/lodash';

export const API_TIME_FORMAT = 'HH:mm:ss';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATETIME_FORMAT = moment.ISO_8601;
export const NAIVE_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const PARENT_PRODUCT_CONFLUENCE = 'plusplus';

// To Be Removed
export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const MONTH_DAY_DISPLAY_FORMAT = 'ddd, MMMM D';
export const DATE_TIME_SHORT_DISPLAY_FORMAT = 'MMM DD h:mm A';
export const TIME_DISPLAY_FORMAT = 'h:mm A';
export const SHORT_DATE_FORMAT = 'MMM D';
export const SHORT_YEAR_DATE_FORMAT = 'MMM D, YYYY';

export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = SHORT_DATE_FORMAT;
export const DATE_YEAR_FORMAT = SHORT_YEAR_DATE_FORMAT;
export const DATETIME_FORMAT = `${DATE_FORMAT} [at] ${TIME_FORMAT}`;
export const DATETIME_YEAR_FORMAT = `${DATE_YEAR_FORMAT} [at] ${TIME_FORMAT}`;
export const MONTH_DATE_FORMAT = 'MMMM YYYY';

export const STATUS_NOT_REQUESTED = 'STATUS_NOT_REQUESTED';
export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_LOADING_MORE = 'STATUS_LOADING_MORE';
export const STATUS_DONE = 'STATUS_DONE';
export const STATUS_ERROR = 'STATUS_ERROR';

export const HTTP_200_OK = 200;
export const HTTP_400_BAD_REQUEST = 400;
export const HTTP_404_NOT_FOUND = 404;
export const HTTP_500_INTERNAL_SERVER_ERROR = 500;

export const SOCIAL_SHARING_FORM = 'SOCIAL_SHARING_FORM';
export const SOCIAL_SHARING_FORM_SUBMIT = 'SOCIAL_SHARING_FORM_SUBMIT';
export const SOCIAL_SHARING_FORM_SUCCESS = 'SOCIAL_SHARING_FORM_SUCCESS';
export const SOCIAL_SHARING_FORM_FAILURE = 'SOCIAL_SHARING_FORM_FAILURE';

export const REGULAR_ROLE_KEY = 'regular';
export const MENTOR_ROLE_KEY = 'mentor';
export const EXTERNAL_ROLE_KEY = 'external';
export const ORGANIZER_ROLE_KEY = 'organizer';
export const ADMIN_ROLE_KEY = 'admin';

export const USER_ROLE_OPTIONS = [
  { value: ADMIN_ROLE_KEY, name: capitalize(ADMIN_ROLE_KEY) },
  { value: ORGANIZER_ROLE_KEY, name: capitalize(ORGANIZER_ROLE_KEY) },
  { value: MENTOR_ROLE_KEY, name: capitalize(MENTOR_ROLE_KEY) },
  { value: EXTERNAL_ROLE_KEY, name: capitalize(EXTERNAL_ROLE_KEY) },
  { value: REGULAR_ROLE_KEY, name: capitalize(REGULAR_ROLE_KEY) },
];

// Recurrence constants
export const RECURRENCE_OPTIONS = [
  { value: 0, label: 'Every weekday' },
  { value: 1, label: 'Every week' },
  { value: 2, label: 'Every 2 weeks' },
  { value: 4, label: 'Every 4 weeks' },
];

export const regexDateHhMm = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$'; /* HH:MM */
export const regexDateHhMmAmPm =
  '^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$'; /* HH:MM AM or HH:MM PM */

// Content Item constants

export const getContentTypes = (labels) => [
  { value: 'article', name: labels.label_article },
  { value: 'codelab', name: labels.label_codelab },
  { value: 'course', name: labels.label_course },
  { value: 'eventtype', name: labels.label_event_type },
  { value: 'video', name: 'Video' },
  { value: 'track', name: labels.label_track },
];

export const AVATAR_SIZES = {
  // Deprecated format
  xs: 24,
  s: 24,
  m: 40,
  l: 48, // This is used only in the event page, but will be removed soon
  xl: 80,
  xxl: 120,
  // Default format
  small: 24,
  medium: 40,
  large: 80,
  'extra-large': 120,
};

export const INITIAL_DURATIONS = {
  article: '00:10:00',
  codelab: '00:30:00',
  course: '00:30:00',
  eventType: '01:00:00',
  program: '00:30:00',
  linkedcontent: '00:10:00',
};

export const ACCESS_LEVEL_MAPPING = {
  public: {
    icon: 'persons',
    variant: 'lighterGray',
    tooltipText: 'Public',
  },
  restricted: {
    icon: 'lock',
    variant: 'alert200',
    tooltipText: 'Restricted',
  },
  hidden: {
    icon: 'hide',
    variant: 'lightGray',
    tooltipText: 'Hidden',
  },
};
