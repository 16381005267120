import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Text from 'app/shared/components/Text';
import colors from 'services/colors';
import Datetime from 'shared/components/Datetime';
import Icon from 'shared/components/Icon';
import { Tooltip } from 'shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from 'shared/hooks';

const TimeslotContainer = styled.div`
  display: flex;
  gap: 4px;
  color: ${colors.neutral600};
  ${({ wrapDatetime }) => !wrapDatetime && ` flex-direction: column; `}
  ${({ wrapDatetime }) => (!wrapDatetime ? ` align-items: flex-start; ` : ` align-items: center;`)}
`;

const DatetimeContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DatetimeWrapper = styled.div`
  max-width: 240px;
`;

const TimeslotCard = ({
  timeslot,
  locationTimezone,
  userTimezone,
  eventIsOnline,
  wrapDatetime,
}) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const datetimeTooltip = useTooltipUID();
  const datetimeText = Datetime({
    datetime: timeslot.starts_at_tz_aware,
    timezone: locationTimezone,
    displayTimezone: userTimezone,
    isOnline: eventIsOnline,
    includeWeekDay: true,
  });
  return (
    <TimeslotContainer wrapDatetime={wrapDatetime}>
      <DatetimeContent>
        <Icon name="calendar-clock" color={colors.neutral600} height={16} width={16} />
        <DatetimeWrapper>
          <Text size="h4" ellipsisOnOverflow ref={nodeRef} {...datetimeTooltip.targetProps}>
            {datetimeText}
          </Text>
          <Tooltip id={datetimeTooltip.uid} hide={!hasEllipsis} maxWidth={260}>
            {datetimeText}
          </Tooltip>
        </DatetimeWrapper>
      </DatetimeContent>
    </TimeslotContainer>
  );
};

TimeslotCard.propTypes = {
  timeslot: PropTypes.object,
  locationTimezone: PropTypes.string,
  userTimezone: PropTypes.string,
  eventIsOnline: PropTypes.bool,
  wrapDatetime: PropTypes.bool,
  marginLeftOutsideLearning: PropTypes.bool,
};

TimeslotCard.defaultProps = {
  wrapDatetime: true,
  marginLeftOutsideLearning: true,
};

export default TimeslotCard;
