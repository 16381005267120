import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import OutsideLearningHoursBadge from 'event-shared/components/OutsideLearningHoursBadge';
import TimeslotRangePill from 'event-shared/components/TimeslotRangePill';
import colors from 'services/colors';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';

const Container = styled.div`
  padding: 20px 20px 0 20px;

  > * {
    margin-bottom: 12px;
  }

  > :last-child {
    margin-bottom: 16px;
  }
`;

const TimeslotRangePillWrapper = styled.div`
  display: flex;
`;

export const TimeslotDetail = ({ timeslot, eventTimezone, userTimezone, isOnline }) => {
  const {
    starts_at_tz_aware: startsAt,
    ends_at_tz_aware: endsAt,
    is_inside_learning_hours: isInsideLearningHours,
  } = timeslot;

  return (
    <Container>
      <TimeslotRangePillWrapper>
        <TimeslotRangePill
          start={startsAt}
          end={endsAt}
          timezone={eventTimezone}
          displayTimezone={userTimezone}
          isOnline={isOnline}
        />
        {!isInsideLearningHours && <OutsideLearningHoursBadge />}
      </TimeslotRangePillWrapper>
      <div>
        {timeslot.rooms_info && (
          <Pill
            variant="lighterGrayWithDarkerTextColor"
            icon="room"
            label={timeslot.rooms_info}
            labelMaxWidth={380}
          />
        )}
        {timeslot.extra_info && (
          <Text size="h6" color={colors.neutral500}>
            Extra Info: {timeslot.extra_info}
          </Text>
        )}
      </div>
    </Container>
  );
};

TimeslotDetail.propTypes = {
  timeslot: PropTypes.object,
  eventTimezone: PropTypes.string,
  userTimezone: PropTypes.string,
  isOnline: PropTypes.bool,
};
