import styled from 'styled-components';

import { FactCheckIcon, SourceIcon, ManageSearchIcon } from 'vendor/mui-icons';

import AddEventIcon from './Icons/add-event.svg';
import AddIcon from './Icons/add.svg';
import AddedToTrack from './Icons/added-to-track.svg';
import AnalyticsIcon from './Icons/analytics.svg';
import AnnounceIcon from './Icons/announce.svg';
import Archive from './Icons/archive.svg';
import Archived from './Icons/archived.svg';
import ArrowToBottom from './Icons/arrow-to-bottom.svg';
import ArrowToTop from './Icons/arrow-to-top.svg';
import AssignmentIcon from './Icons/assignment.svg';
import BellIcon from './Icons/bell.svg';
import BookmarkRemove from './Icons/bookmark-remove.svg';
import Bookmark from './Icons/bookmark.svg';
import CalendarClockIcon from './Icons/calendar-clock.svg';
import CalendarNegativeIcon from './Icons/calendar-negative.svg';
import CalendarIcon from './Icons/calendar.svg';
import CancelIcon from './Icons/cancel.svg';
import ChannelIcon from './Icons/channel.svg';
import ChartIcon from './Icons/chart.svg';
import CheckOutlined from './Icons/check-outlined.svg';
import CheckIcon from './Icons/check.svg';
import CheckboxRoundIcon from './Icons/checkbox-round.svg';
import ClockFilledIcon from './Icons/clock-filled.svg';
import ClockIcon from './Icons/clock.svg';
import CloneIcon from './Icons/clone.svg';
import CloseIcon from './Icons/close.svg';
import CompareArrowsIcon from './Icons/compare-arrows.svg';
import DeleteIcon from './Icons/delete.svg';
import DocIcon from './Icons/doc.svg';
import DoubleIcon from './Icons/double.svg';
import DownIcon from './Icons/down.svg';
import DownloadIcon from './Icons/download.svg';
import EditIcon from './Icons/edit.svg';
import ElipsisIcon from './Icons/elipsis.svg';
import EmbedIcon from './Icons/embed.svg';
import EraseIcon from './Icons/erase.svg';
import ExclamationIcon from './Icons/exclamation.svg';
import ExternalIcon from './Icons/external.svg';
import FastForwardIcon from './Icons/fast-forward.svg';
import FilterIcon from './Icons/filter.svg';
import FlagIcon from './Icons/flag.svg';
import FlashIcon from './Icons/flash.svg';
import GlobeIcon from './Icons/globe.svg';
import GridViewIcon from './Icons/grid-view.svg';
import GroupIcon from './Icons/group.svg';
import HamburgerIcon from './Icons/hamburger.svg';
import HashtagIcon from './Icons/hashtag.svg';
import HideIcon from './Icons/hide.svg';
import ImageIcon from './Icons/image.svg';
import ImportIcon from './Icons/import.svg';
import IncomingIcon from './Icons/incoming.svg';
import InfoIcon from './Icons/info.svg';
import InlineIcon from './Icons/inline.svg';
import IntegrationInstructionsIcon from './Icons/integration-instructions.svg';
import InternalIcon from './Icons/internal.svg';
import LeftIcon from './Icons/left.svg';
import LevelIcon from './Icons/level.svg';
import LibraryIcon from './Icons/library.svg';
import LightbulbIcon from './Icons/lightbulb.svg';
import LinkIcon from './Icons/link.svg';
import ListOutlined from './Icons/list-outlined.svg';
import ListViewIcon from './Icons/list-view.svg';
import LoadingAltIcon from './Icons/loading-alt.svg';
import LoadingIcon from './Icons/loading.svg';
import LocationIcon from './Icons/location.svg';
import LockIcon from './Icons/lock.svg';
import MailIcon from './Icons/mail.svg';
import MegaphoneIcon from './Icons/megaphone.svg';
import MinusIcon from './Icons/minus.svg';
import MoonIcon from './Icons/moon.svg';
import MultipleCloneIcon from './Icons/multiple-clone.svg';
import NumericIcon from './Icons/numeric.svg';
import OnlineIcon from './Icons/online.svg';
import OutgoingIcon from './Icons/outgoing.svg';
import PencilIcon from './Icons/pencil.svg';
import PersonIcon from './Icons/person.svg';
import PersonsIcon from './Icons/persons.svg';
import PlayIcon from './Icons/play.svg';
import PlusIcon from './Icons/plus.svg';
import QuestionMark from './Icons/question-mark.svg';
import RadarIcon from './Icons/radar.svg';
import RefreshIcon from './Icons/refresh.svg';
import ResetIcon from './Icons/reset.svg';
import RightIcon from './Icons/right.svg';
import RoomIcon from './Icons/room.svg';
import RuleOutlinedIcon from './Icons/rule-outlined.svg';
import RulesIcon from './Icons/rules.svg';
import ScheduledTrackIcon from './Icons/scheduled-track.svg';
import SearchIcon from './Icons/search.svg';
import SectionIcon from './Icons/section.svg';
import SettingsCheckmark from './Icons/settings-checkmark.svg';
import SettingsIcon from './Icons/settings.svg';
import ShareIcon from './Icons/share.svg';
import SlackIcon from './Icons/slack.svg';
import StarIcon from './Icons/star.svg';
import SuitcaseIcon from './Icons/suitcase.svg';
import SupportIcon from './Icons/support.svg';
import SurveyIcon from './Icons/survey.svg';
import TagIcon from './Icons/tag.svg';
import TaskIcon from './Icons/task.svg';
import TeachIcon from './Icons/teach.svg';
import TextIcon from './Icons/text.svg';
import TicketIcon from './Icons/ticket.svg';
import TransferIcon from './Icons/transfer.svg';
import Unarchive from './Icons/unarchive.svg';
import UpIcon from './Icons/up.svg';
import VideoIcon from './Icons/video.svg';
import ViewIcon from './Icons/view.svg';
import WorldIcon from './Icons/world.svg';
import XRoundIcon from './Icons/x-round.svg';
import ZipFileFillIcon from './Icons/zip-file-fill.svg';
import ZipFileIcon from './Icons/zip-file.svg';

/**
 * To add a icon, download the SVG from Figma, make sure the viewBox attribute is 0 0 20 20.
 *
 * If not, go to https://www.iloveimg.com/resize-image/resize-svg and resize it to 20x20
 * Make sure the width and height are also 20, unitless
 *  final result should be <svg ... width="20" height="20" viewBox="0 0 20 20" ... />
 *
 * Then, add the SVG file to the /Icons/ folder, after, import the SVG here and add it to
 * the iconMapping object. Use the same name as the file on Zeplin
 */

interface MuiIconProps {
  width: number | string;
  height: number | string;
  className: string;
}

const iconMapping = {
  add: AddIcon,
  'added-to-track': AddedToTrack,
  'add-event': AddEventIcon,
  analytics: AnalyticsIcon,
  announce: AnnounceIcon,
  archive: Archive,
  archived: Archived,
  'arrow-to-bottom': ArrowToBottom,
  'arrow-to-top': ArrowToTop,
  assignment: AssignmentIcon,
  bell: BellIcon,
  'bookmark-remove': BookmarkRemove,
  bookmark: Bookmark,
  calendar: CalendarIcon,
  'calendar-negative': CalendarNegativeIcon,
  'calendar-clock': CalendarClockIcon,
  cancel: CancelIcon,
  chart: ChartIcon,
  check: CheckIcon,
  'check-outlined': CheckOutlined,
  'checkbox-round': CheckboxRoundIcon,
  clock: ClockIcon,
  'clock-filled': ClockFilledIcon,
  clone: CloneIcon,
  'multiple-clone': MultipleCloneIcon,
  close: CloseIcon,
  'compare-arrows': CompareArrowsIcon,
  delete: DeleteIcon,
  doc: DocIcon,
  double: DoubleIcon,
  down: DownIcon,
  download: DownloadIcon,
  edit: EditIcon,
  elipsis: ElipsisIcon,
  embed: EmbedIcon,
  erase: EraseIcon,
  exclamation: ExclamationIcon,
  external: ExternalIcon,
  'fact-check': ({ width, height, className }: MuiIconProps) => (
    <FactCheckIcon sx={{ height: `${height}px`, width: `${width}px` }} className={className} />
  ),
  'fast-forward': FastForwardIcon,
  filter: FilterIcon,
  flag: FlagIcon,
  flash: FlashIcon,
  'grid-view': GridViewIcon,
  group: GroupIcon,
  globe: GlobeIcon,
  hamburger: HamburgerIcon,
  hashtag: HashtagIcon,
  hide: HideIcon,
  internal: InternalIcon,
  image: ImageIcon,
  import: ImportIcon,
  incoming: IncomingIcon,
  'integration-instructions': IntegrationInstructionsIcon,
  info: InfoIcon,
  inline: InlineIcon,
  left: LeftIcon,
  level: LevelIcon,
  library: LibraryIcon,
  lightbulb: LightbulbIcon,
  link: LinkIcon,
  'list-view': ListViewIcon,
  'list-outlined': ListOutlined,
  loading: LoadingIcon,
  'loading-alt': LoadingAltIcon,
  location: LocationIcon,
  lock: LockIcon,
  mail: MailIcon,
  megaphone: MegaphoneIcon,
  minus: MinusIcon,
  moon: MoonIcon,
  numeric: NumericIcon,
  online: OnlineIcon,
  outgoing: OutgoingIcon,
  pencil: PencilIcon,
  person: PersonIcon,
  persons: PersonsIcon,
  play: PlayIcon,
  plus: PlusIcon,
  'question-mark': QuestionMark,
  radar: RadarIcon,
  refresh: RefreshIcon,
  reset: ResetIcon,
  right: RightIcon,
  room: RoomIcon,
  rules: RulesIcon,
  'rule-outlined': RuleOutlinedIcon,
  'scheduled-track': ScheduledTrackIcon,
  search: SearchIcon,
  section: SectionIcon,
  'settings-checkmark': SettingsCheckmark,
  settings: SettingsIcon,
  share: ShareIcon,
  source: ({ width, height, className }: MuiIconProps) => (
    <SourceIcon sx={{ height: `${height}px`, width: `${width}px` }} className={className} />
  ),
  star: StarIcon,
  suitcase: SuitcaseIcon,
  support: SupportIcon,
  survey: SurveyIcon,
  tag: TagIcon,
  task: TaskIcon,
  teach: TeachIcon,
  text: TextIcon,
  ticket: TicketIcon,
  transfer: TransferIcon,
  unarchive: Unarchive,
  up: UpIcon,
  video: VideoIcon,
  view: ViewIcon,
  world: WorldIcon,
  slack: SlackIcon,
  channel: ChannelIcon,
  'x-round': XRoundIcon,
  'zip-file': ZipFileIcon,
  'zip-file-fill': ZipFileFillIcon,
  log: ({ width, height, className }: MuiIconProps) => (
    <ManageSearchIcon sx={{ height: `${height}px`, width: `${width}px` }} className={className} />
  ),
};

interface InnerIconProps {
  name: string;
  width?: number | string;
  height?: number | string;
  className?: string;
}

const InnerIcon = ({ name, height, width, className }: InnerIconProps) => {
  const IconComponent = iconMapping[name];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent className={className} width={width} height={height} viewBox="0 0 20 20" />;
};

export interface IconProps extends Omit<InnerIconProps, 'className'> {
  color?: string;
  noTransition?: boolean;
}

const Icon = styled(InnerIcon)<IconProps>`
  path {
    ${({ noTransition }) => (!noTransition ? 'transition: all .2s;' : '')}
    ${({ color }) => color && `fill: ${color};`}
  }
`;

Icon.defaultProps = {
  width: 23,
  height: 23,
};

export default Icon;
