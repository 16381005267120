import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const WarnDoubleBookingModal = ({ requestedStatus, updateEnrollmentStatus, handleClose, show }) => {
  const handleEnrollClick = () => {
    updateEnrollmentStatus(requestedStatus);
    handleClose();
  };

  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Conflict"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <ModalBodyWrapper>
          <Text size="h4">
            This event conflicts with one or more events in your in your Google Calendar. Please
            confirm you are available before proceeding.
          </Text>
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleEnrollClick}>Enroll</ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

WarnDoubleBookingModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  requestedStatus: PropTypes.string,
  updateEnrollmentStatus: PropTypes.func,
};

export default WarnDoubleBookingModal;
