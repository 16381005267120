import { useState } from 'react';

import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import TestimonialFormModal from 'testimonials/components/TestimonialFormModal';
import { Button } from 'vendor/mui';

interface WriteTestimonialButtonProps {
  receiverId: number;
  receiverName: string;
  onSuccessCallback?: () => void;
}

export const WriteTestimonialButton = ({
  receiverId,
  receiverName,
  onSuccessCallback,
}: WriteTestimonialButtonProps) => {
  const [showWriteModal, setShowWriteModal] = useState(false);

  const handleWriteModalToggle = () => {
    setShowWriteModal(!showWriteModal);
  };

  const handleWriteSuccess = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    handleWriteModalToggle();
    toast.success(
      'Testimonial sent successfully',
      `Thank you for writing a testimonial. ${receiverName} will be notified soon.`
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ color: colors.success600 }}
        onClick={handleWriteModalToggle}
        data-testid="testimonials_write_button"
      >
        Write a testimonial
      </Button>
      {showWriteModal && (
        <TestimonialFormModal
          receiverId={receiverId}
          receiverName={receiverName}
          handleClose={handleWriteModalToggle}
          onSuccessCallback={handleWriteSuccess}
        />
      )}
    </>
  );
};
