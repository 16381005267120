import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CONTENT_TYPES } from 'app/catalog/constants';
import { Event } from 'event/interfaces';
import { queries } from 'queries';
import { filter, forEach, get, has, map, includes } from 'vendor/lodash';

export const useRefreshEvent = (id: number, viewMode = 'default') => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: queries.events.detail(id, viewMode).queryFn,
    onSuccess: (newEvent: Event) => {
      // Get all ['events' | 'my_upcoming', 'list'] queries
      const queryCache = queryClient.getQueryCache();
      const queries = filter(
        queryCache.getAll(),
        (query) =>
          includes(['events', 'my_upcoming'], get(query, 'queryKey.0')) &&
          get(query, 'queryKey.1', '') === 'list'
      );
      forEach(queries, (query) => {
        const { data: currentData } = query.state;
        // The normal query hook store in the format:
        // { results... }
        // The infinite query hook store the results following the structure:
        // { pageParams: [], pages: [{ results... }, ...] }
        // More info here: https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        // So it is necessary to check which type of query we are handling to update it properly

        const replaceEvent = (results: Event[]) => {
          return map(results, (event) => {
            if (
              get(event, 'id') === newEvent.id &&
              get(event, 'content_type') === CONTENT_TYPES.event
            )
              return newEvent;
            return event;
          });
        };

        if (has(currentData, 'pages')) {
          query.setData({
            ...(currentData as object),
            pages: map(get(currentData, 'pages'), (page) => ({
              ...page,
              results: replaceEvent(get(page, 'results')),
            })),
          });
        } else {
          query.setData({
            ...(currentData as object),
            results: replaceEvent(get(currentData, 'results')),
          });
        }
      });
    },
  });
};
