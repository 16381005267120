export const MENTOR_PREFERENCE_TAG_TYPE = 'tag_type_8';
export const MENTEE_PREFERENCE_TAG_TYPE = 'tag_type_9';

// Local Storage
export const MENTORSHIP_KEY_LOCAL_STORAGE = 'baseMentorshipStickyFilter';

const AVAILABILITY_FILTER_VALUES = {
  // corresponds to backend/coaching/constants.AVAILABILITY_METHOD
  IS_LOCAL: 'local',
  IS_ONLINE: 'online',
  IS_FRIENDLY_TO_MY_TIME: 'friendly_to_my_time',
};

export const MENTOR_AVAILABILITY_OPTIONS = [
  {
    name: 'In Person',
    value: AVAILABILITY_FILTER_VALUES.IS_LOCAL,
  },
  {
    name: 'Online',
    value: AVAILABILITY_FILTER_VALUES.IS_ONLINE,
  },
  {
    name: 'Friendly to My Time',
    value: AVAILABILITY_FILTER_VALUES.IS_FRIENDLY_TO_MY_TIME,
  },
];
