import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { OldIcon } from 'shared/components/Icon';
import { useLabels } from 'shared/hooks';

const MentorshipStats = styled.div`
  border-top: 1px solid ${colors.neutral100};
  padding: 16px;
  color: ${colors.neutral900};
`;

const StatsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Stat = styled.div``;

const StyledIcon = styled(OldIcon)`
  margin-right: 8px;
`;

const MentorStats = (props) => {
  const { profileData } = props;
  const mentorName = profileData.name?.split(' ')[0];
  const hoursOfMentorship =
    Math.round(moment.duration(profileData.mentorship_hours_as_host).asHours() * 100) / 100;
  const { label_mentorship_sessions_creator_title_single: hostLabel } = useLabels();

  if (
    !profileData.mentorship_birthday &&
    !profileData.mentorship_former_mentees_count > 0 &&
    !profileData.mentorship_hosted_sessions_count > 0 &&
    !profileData.mentorship_hours_as_host
  ) {
    return (
      <MentorshipStats>
        <StatsTitle>
          {mentorName} as {hostLabel}
        </StatsTitle>
        <div>We don&apos;t have any stats for this {hostLabel.toLowerCase()} yet.</div>
      </MentorshipStats>
    );
  }

  return (
    <MentorshipStats>
      <StatsTitle>
        {mentorName} as {hostLabel}
      </StatsTitle>
      {profileData.mentorship_hours_as_host && (
        <Stat>
          <StyledIcon name="clock" color={colors.neutral900} />
          {hoursOfMentorship} {hoursOfMentorship === '1' ? 'hour' : 'hours'} of mentorship given
        </Stat>
      )}
      {profileData.mentorship_hosted_sessions_count !== 0 && (
        <Stat>
          <StyledIcon name="calendar-blank" color={colors.neutral900} />
          {profileData.mentorship_hosted_sessions_count} mentorship
          {profileData.mentorship_hosted_sessions_count === 1 ? ' session' : ' sessions'} hosted
        </Stat>
      )}
      {profileData.mentorship_former_mentees_count > 0 && (
        <Stat>
          <StyledIcon name="account" color={colors.neutral900} />
          {profileData.mentorship_former_mentees_count}{' '}
          {profileData.mentorship_former_mentees_count === 1 ? 'person' : 'people'} mentored
        </Stat>
      )}
      {profileData.mentorship_birthday && (
        <Stat>
          <StyledIcon name="flag-variant" color={colors.neutral900} />
          {hostLabel} since {moment(profileData.mentorship_birthday).format('MMMM')}{' '}
          {moment(profileData.mentorship_birthday).format('YYYY')}
        </Stat>
      )}
    </MentorshipStats>
  );
};

MentorStats.propTypes = {
  profileData: PropTypes.object,
};

export default MentorStats;
