import { Card as MUICard } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import { useContentTypeRoutes } from 'app/catalog/hooks';
import { ContentItem } from 'app/shared-content-item/interfaces';
import { LEARNING_TYPES, CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from 'catalog/constants';
import colors from 'services/colors';
import { get } from 'vendor/lodash';

import { ContentItemCardProvider } from './ContentItemCardContext';

const Card = styled(MUICard)(() => ({
  height: '480px',
  width: '312px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.neutral0,
  border: '1px solid',
  borderColor: colors.neutral200,
  borderRadius: '8px',
}));

const defaultLearningType = LEARNING_TYPES.articles;

type ContentItemCardRootProps = {
  contentItem: ContentItem;
  children: React.ReactNode;
};

export function ContentItemCardRoot(props: ContentItemCardRootProps) {
  const { contentItem, children } = props;

  const contentType = get(contentItem, 'content_type');
  const userAssignment = get(contentItem, 'user_assignment', get(contentItem, 'assignment'));

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType] || defaultLearningType;

  const contentTypesRoutes = useContentTypeRoutes();
  const detailsRoute = get(contentTypesRoutes, [contentType, 'details']);
  const detailsRouteUrl = detailsRoute(contentItem);

  return (
    <ContentItemCardProvider
      contentItem={contentItem}
      learningType={learningType}
      userAssignment={userAssignment}
      route={detailsRouteUrl}
    >
      <Card variant="outlined">{children}</Card>
    </ContentItemCardProvider>
  );
}
