import { PropTypes } from 'prop-types';
import React from 'react';

import ConfluenceViewPage from '../toolbar/ConfluenceDocUpload/ConfluenceViewPage';

const ConfluencePageRenderer = ({ blockProps }) => {
  return <ConfluenceViewPage blockProps={blockProps} showBiggerPage />;
};

ConfluencePageRenderer.propTypes = {
  blockProps: PropTypes.object,
};

export default ConfluencePageRenderer;
