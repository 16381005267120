import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DropdownFilter } from 'backoffice/components/Dashboard/Filters';
import { useEntities } from 'entities/utils';
import { isUUID } from 'services/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import {
  isEmpty,
  includes,
  size,
  get,
  isInteger,
  parseInt,
  map,
  split,
  filter,
  head,
  isNil,
} from 'vendor/lodash';

import RQLDropdownFilter from '../Filters/RQLDropdownFilter';

const LazyDropdownFilter = ({
  actionFunc,
  getFetchExtraParams,
  optionsFormatter,
  isRQL,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [fetch, { status }] = useEntities(actionFunc, ({ status, data }) => {
    if (status === STATUS_DONE) {
      // The user endpoint returns 'display_name' instead of 'name'
      setOptions(
        map(get(data, 'results', []), (item) => ({ ...item, name: item.display_name || item.name }))
      );
    }
  });

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptions = ({ q, includeValues }) => {
    const options = {
      o: 'name',
      q: q || undefined,
      page_size: size(includeValues) + 10,
      view_mode: 'filter_options',
      ...getFetchExtraParams(q, includeValues),
    };
    // includeValues is in the format "<item>,<item>,<item>"
    const firstIncludedId = head(filter(split(includeValues, ','), (item) => size(item) > 0));
    if (!isNil(firstIncludedId)) {
      if (isUUID(firstIncludedId)) {
        options.include_public_ids = includeValues;
      } else if (isInteger(parseInt(firstIncludedId))) {
        options.include_ids = includeValues;
      } else {
        options.include_slugs = includeValues;
      }
    }
    fetch(options, { skipSchema: true });
  };

  const Dropdown = isRQL ? RQLDropdownFilter : DropdownFilter;

  return (
    <Dropdown
      options={isEmpty(options) ? [] : optionsFormatter(options)}
      loading={loading}
      fetchOptions={fetchOptions}
      {...props}
    />
  );
};

LazyDropdownFilter.propTypes = {
  actionFunc: PropTypes.func,
  getFetchExtraParams: PropTypes.func,
  optionsFormatter: PropTypes.func,
  isRQL: PropTypes.bool,
};

LazyDropdownFilter.defaultProps = {
  getFetchExtraParams: () => ({}),
  optionsFormatter: (options) => options,
  isRQL: false,
};

export default LazyDropdownFilter;
