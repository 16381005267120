import React from 'react';

import Button from 'shared/components/Button';

import BulkAddUsersFlow from './BulkAddUsersFlow';

export class BulkAddUsersButton extends React.Component {
  constructor(props) {
    super(props);
    this.bulkAddUsersModalManager = React.createRef();
  }

  openModal = (e) => {
    e.nativeEvent.preventDefault();
    this.bulkAddUsersModalManager.current.startFlow();
  };

  render = () => (
    <React.Fragment>
      <Button size="small" onClick={this.openModal} {...this.props}>
        Bulk Add People
      </Button>
      <BulkAddUsersFlow ref={this.bulkAddUsersModalManager} />
    </React.Fragment>
  );
}

export default BulkAddUsersButton;
