import { useLocation } from 'react-router-dom';

import { filter, head, isEmpty, isNil, isNumber, keys, reduce, replace, size } from 'vendor/lodash';
import { get_parser } from 'vendor/rql/parser';
import { transform } from 'vendor/rql/transformer';

export const rqlExpressionToObject = (expression) => {
  const parser = get_parser();
  try {
    const tree = parser.parse(expression);
    return transform(tree);
  } catch {
    // Ignoring invalid expressions to avoid crashing the application.
    return {};
  }
};

export const rqlHasValue = (filter) => {
  // A filter without value is like this:
  // {name: null}
  // {name: {$ilike: ''}}
  // {name: {$not: {$ilike: ''}}
  const filterName = head(keys(filter));

  const hasInnerValue = (operation) => {
    if (isNil(operation)) return false;
    const operatorName = head(keys(operation));
    if (operatorName === '$not') return hasInnerValue(operation.$not);
    return !isEmpty(operation[operatorName]) || isNumber(operation[operatorName]);
  };

  return hasInnerValue(filter[filterName]);
};

// Receives a list of RQL objects and return the RQL object
export const rqlListToObject = (filters, logicalOperator = '$and') => {
  const filtersWithValue = filter(filters, (obj) => rqlHasValue(obj));
  if (size(filtersWithValue) === 0) return {};
  if (size(filtersWithValue) === 1) return filtersWithValue[0];
  return { [logicalOperator]: filtersWithValue };
};

export const useCurrentExpressionWithoutPage = () => {
  const location = useLocation();
  const search = isEmpty(location.search) ? '' : location.search.slice(1); // remove the ? symbol
  // page and page_size params
  return replace(replace(search, /(&?)page=(\d*)/g, ''), /(&?)page_size=(\d*)/g, '');
};

export const escapeURL = (url) => {
  // This converts the a encoded URL to a valid RQL expression format.
  // Why not use decodeURI? Because the decodeURI will try to decode the whole URL,
  // even the values inside the quotes, this can be a problem, since the user may
  // want to search using the % character and it can cause a malformed URI error.
  // in(name,(%22test%20name%22,%22name%20test%22)) =>  in(name,("test name","name test"))
  const regexMapping = {
    ',"': /,%22/g,
    '",': /%22,/g,
    '("': /\(%22/g,
    '")': /%22\)/g,
    ' ': /%20/g,
  };
  return reduce(regexMapping, (acc, value, key) => replace(acc, value, key), url);
};
