import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CONTENT_TYPES } from 'app/catalog/constants';
import ConfirmationModal from 'app/shared/components/ConfirmationModal';
import { SWAP_EVENT_MOVE_ENROLLMENTS, SWAP_EVENT_KEEP_ENROLLMENTS } from 'app/tracks/contants';
import RadioInput from 'inputs/components/RadioInput';
import colors from 'services/colors';
import { displayDatetime, formatDuration } from 'services/datetime';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import ScheduleTrackEventSelect from 'tracks/components/ScheduleTrackModal/ScheduleTrackEventSelect';
import { get } from 'vendor/lodash';
import { Alert, Box, Typography } from 'vendor/mui';

const RadioInputWrapper = styled.div`
  margin: 8px 0;
`;

const ConfirmSwapEnrollments = ({ selectedEnrollmentsAction, onSelectEnrollmentsAction }) => {
  const handleOnChange = (value) => {
    onSelectEnrollmentsAction(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '20px',
      }}
    >
      <Typography sx={{ fontSize: 16, color: colors.neutral600, paddingBottom: '16px' }}>
        Select action to be take on the event enrollments
      </Typography>
      <RadioInputWrapper>
        <RadioInput
          inputValue={selectedEnrollmentsAction}
          name="enrollments_action"
          value={SWAP_EVENT_KEEP_ENROLLMENTS}
          onChange={handleOnChange}
          label="Keep existing enrollments with the old event"
        />
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput
          inputValue={selectedEnrollmentsAction}
          name="enrollments_action"
          value={SWAP_EVENT_MOVE_ENROLLMENTS}
          onChange={handleOnChange}
          label="Move existing enrollments to the swapped event"
        />
      </RadioInputWrapper>

      <Alert severity="info" variant="outlined" sx={{ marginBottom: '4px' }}>
        Enrollments swapped from an Event will preserve the attendance type of the original
        enrollment.
      </Alert>
      <Alert severity="info" variant="outlined" sx={{ marginBottom: '4px' }}>
        Enrollments swapped from an EventType will default to online enrollment.
      </Alert>
    </Box>
  );
};

ConfirmSwapEnrollments.propTypes = {
  selectedEnrollmentsAction: PropTypes.string,
  onSelectEnrollmentsAction: PropTypes.func,
};

const CreatingModalFooterButtons = ({ hasSwapped, handleClose, handleSubmit, isEventType }) => {
  return (
    <>
      <Modal.FooterButton color="error" onClick={handleClose}>
        Cancel
      </Modal.FooterButton>
      <Modal.FooterButton onClick={handleSubmit} disabled={!hasSwapped}>
        {isEventType ? 'Schedule' : 'Swap'}
      </Modal.FooterButton>
    </>
  );
};

CreatingModalFooterButtons.propTypes = {
  hasSwapped: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isEventType: PropTypes.bool,
};

const EditingModalFooterButtons = ({
  hasSwapped,
  isOnReviewMode,
  isEventType,
  setIsOnReviewMode,
  handleClose,
  handleSubmit,
}) => {
  if (isOnReviewMode) {
    return (
      <>
        <Modal.FooterButton color="error" onClick={() => setIsOnReviewMode(false)}>
          Back
        </Modal.FooterButton>
        <Modal.FooterButton onClick={handleSubmit}>
          {isEventType ? 'Schedule' : 'Swap'}
        </Modal.FooterButton>
      </>
    );
  }

  return (
    <>
      <Modal.FooterButton color="error" onClick={handleClose}>
        Cancel
      </Modal.FooterButton>
      <Modal.FooterButton onClick={() => setIsOnReviewMode(true)} disabled={!hasSwapped}>
        Next
      </Modal.FooterButton>
    </>
  );
};

function parseSelectedEvent(selectedEvent) {
  const humanizedDate = `${displayDatetime(
    get(selectedEvent, 'local_time', selectedEvent.utc_datetime),
    selectedEvent.timezone
  )} - ${formatDuration(selectedEvent.duration)}`;

  return {
    ...selectedEvent,
    humanizedDate,
  };
}

EditingModalFooterButtons.propTypes = {
  hasSwapped: PropTypes.bool,
  isOnReviewMode: PropTypes.bool,
  setIsOnReviewMode: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isEventType: PropTypes.bool,
};

const ScheduledTrackSwapEventModal = (props) => {
  const { item, index, isEditing, trackEndsAt, handleUpdate, handleClose } = props;

  const { content_item: contentItem } = item;
  const isEventType = contentItem.content_type === CONTENT_TYPES.eventtype;
  let eventType;
  if (isEventType) {
    eventType = contentItem;
  } else {
    eventType = contentItem.event_type;
  }
  const initialEvent = isEventType ? null : contentItem;
  const [selectedEvent, setSelectedEvent] = useState(initialEvent);
  const [selectedEnrollmentsAction, setSelectedEnrollmentsAction] = useState(
    SWAP_EVENT_KEEP_ENROLLMENTS
  );
  const [hasSwapped, setHasSwapped] = useState(false);
  const [isOnReviewMode, setIsOnReviewMode] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const handleSetSelectedEvent = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
    setHasSwapped(selectedEvent.id !== contentItem.id);
  };

  const handleSubmit = () => {
    const parsedEvent = parseSelectedEvent(selectedEvent);
    handleUpdate(parsedEvent, selectedEnrollmentsAction, index);
    setIsOnReviewMode(false);
    handleClose();
  };

  const handleSubmitSelectedOrConfirm = () => {
    const eventIsOutOfDateRange = moment(selectedEvent.starts_at_tz_aware).isAfter(
      moment(trackEndsAt)
    );

    if (eventIsOutOfDateRange) {
      setDisplayConfirmation(true);
      return;
    }

    handleSubmit();
  };

  const handleCloseConfirmation = () => {
    setDisplayConfirmation(false);
  };

  const handleAcceptConfirmation = () => {
    setDisplayConfirmation(false);
    handleSubmit();
  };

  return (
    <Modal
      title={isEventType ? `Schedule ${contentItem.name}` : `Swap ${contentItem.name}`}
      width={window.innerWidth > 1024 ? (isOnReviewMode ? 500 : 940) : null}
      minBodyHeight={600}
      overflow="hidden"
      handleClose={handleClose}
    >
      <ModalBody fullSize scrollable={!isOnReviewMode}>
        {isOnReviewMode ? (
          <ConfirmSwapEnrollments
            selectedEnrollmentsAction={selectedEnrollmentsAction}
            onSelectEnrollmentsAction={(action) => setSelectedEnrollmentsAction(action)}
          />
        ) : (
          <ScheduleTrackEventSelect
            eventType={eventType}
            selectedEvent={selectedEvent}
            onSelectEvent={handleSetSelectedEvent}
            isSwappingEvent
          />
        )}
      </ModalBody>

      <ModalFooter variant="buttons">
        {isEditing ? (
          <EditingModalFooterButtons
            hasSwapped={hasSwapped}
            isOnReviewMode={isOnReviewMode}
            isEventType={isEventType}
            setIsOnReviewMode={setIsOnReviewMode}
            handleClose={handleClose}
            handleSubmit={handleSubmitSelectedOrConfirm}
          />
        ) : (
          <CreatingModalFooterButtons
            hasSwapped={hasSwapped}
            handleClose={handleClose}
            isEventType={isEventType}
            handleSubmit={handleSubmitSelectedOrConfirm}
          />
        )}
      </ModalFooter>

      <ConfirmationModal
        open={displayConfirmation}
        onConfirmClick={handleAcceptConfirmation}
        handleClose={handleCloseConfirmation}
        deleteModalTitle="Confirmation"
        confirmButtonColor="warning"
        confirmText="Continue"
      >
        <Typography variant="subtitle2">
          This event has a later date than the Scheduled Track ending date.
        </Typography>
      </ConfirmationModal>
    </Modal>
  );
};

ScheduledTrackSwapEventModal.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isEditing: PropTypes.bool,
  handleUpdate: PropTypes.func,
  handleClose: PropTypes.func,
  trackEndsAt: PropTypes.string,
};

export default ScheduledTrackSwapEventModal;
