import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';

const MediaPointJSX = ({ type, children }) => {
  let minWidth;

  switch (type) {
    case 'mobileOnly':
      return <MediaQuery maxWidth={768}>{children}</MediaQuery>;
    case 'mobileLg':
      minWidth = 414;
      break;
    case 'tablet':
      minWidth = 768;
      break;
    case 'desktopSm':
      minWidth = 1024;
      break;
    case 'desktopLg':
      minWidth = 1366;
      break;
    case 'desktopXl':
      minWidth = 1650;
      break;
    default:
      break;
  }

  return <MediaQuery minWidth={minWidth}>{children}</MediaQuery>;
};

MediaPointJSX.propTypes = {
  type: PropTypes.oneOf(['mobileOnly', 'mobileLg', 'tablet', 'desktopSm', 'desktopLg', 'desktopXl'])
    .isRequired,
  children: PropTypes.node,
};

export default MediaPointJSX;
