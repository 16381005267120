import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import { map, includes } from 'vendor/lodash';

import AutocompleteField from '../AutocompleteField';

const ProgramSelectField = (props) => {
  const [options, setOptions] = useState([]);

  const { usePublicId } = props;

  const [fetch, { status }] = useEntities(actions.program.retrieveList, ({ status, data }) => {
    if (status === STATUS_DONE) {
      setOptions(
        map(data.results, ({ id, value, name }) => ({
          value: usePublicId ? value : id,
          label: name,
        }))
      );
    }
  });

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch(
      {
        view_mode: 'filter_options',
        page_size: 50,
        q: q || undefined,
        ...(usePublicId
          ? { include_public_ids: includeValues || undefined }
          : { include_ids: includeValues || undefined }),
      },
      { skipSchema: true }
    );
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

ProgramSelectField.defaultProps = {
  usePublicId: true,
};

ProgramSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  usePublicId: PropTypes.bool,
};

export default ProgramSelectField;
