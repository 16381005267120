import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import * as React from 'react';

import { useCompleteQuestion } from 'features/assessments/hooks/useCompleteQuestion';
import { TextQuestion, Assessment } from 'features/assessments/types';
import {
  getIsLastQuestionInAssessment,
  getIsFirstQuestionInAssessment,
  getShouldDisplayAssessmentFeedback,
} from 'features/assessments/utils/helpers';
import { useTrackContentConsumptionCtx } from 'features/tracks/contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from 'features/tracks/contexts/TrackContentNavigationCtx';
import Button from 'shared/components/Button';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { isEmpty, get } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

import { QuestionDetail } from './QuestionDetail';
import { TextInputFeedbackState, TextQuestionInput } from './TextQuestionInput';

interface TextQuestionDetailProps {
  question: TextQuestion;
}

export const TextQuestionDetail = (props: TextQuestionDetailProps) => {
  const { question } = props;

  const questionAssignment = question.user_assignment;
  const submittedTextResponse = questionAssignment?.text_response;
  const isQuestionCompleted = submittedTextResponse != null;

  const [textResponse, setTextResponse] = React.useState(submittedTextResponse || '');

  const { onGoBack, onAdvance, onExitCurrentTrack } = useTrackContentNavigationCtx();
  const { currentTrackNode } = useTrackContentConsumptionCtx();

  const assessment = currentTrackNode.track as Assessment;

  const shouldDisplayAssessmentFeedback = getShouldDisplayAssessmentFeedback(assessment);
  const isFirstQuestionInAssessment = getIsFirstQuestionInAssessment(question.id, assessment);
  const isLastQuestionInAssessment = getIsLastQuestionInAssessment(question.id, assessment);

  const handleResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextResponse(e.target.value);
  };

  const { completeQuestion, isLoading } = useCompleteQuestion();

  const handleSubmit = () => {
    completeQuestion({
      type: 'text',
      content_type: question.content_type,
      text_response: textResponse,
    });
  };

  const feedbackState: TextInputFeedbackState = React.useMemo(() => {
    if (!isQuestionCompleted) {
      return 'none';
    }

    if (!shouldDisplayAssessmentFeedback) {
      return 'indeterminate';
    }

    return questionAssignment?.is_success ? 'correct' : 'incorrect';
  }, [isQuestionCompleted, shouldDisplayAssessmentFeedback, questionAssignment]);

  const [displayFeedbackText, feedbackText] = React.useMemo(() => {
    let feedbackText;

    if (feedbackState === 'correct') {
      feedbackText = get(question, 'feedback_text_on_success');
    } else if (feedbackState === 'incorrect') {
      feedbackText = get(question, 'feedback_text_on_fail');
    }

    const shouldDisplayFeedbackText = feedbackText && shouldDisplayAssessmentFeedback;

    return [shouldDisplayFeedbackText, feedbackText] as const;
  }, [feedbackState, question, shouldDisplayAssessmentFeedback]);

  const disableSubmitButton = isEmpty(questionAssignment) || isEmpty(textResponse) || isLoading;

  return (
    <QuestionDetail question={question}>
      <Box display="flex" justifyContent="space-between" marginTop={1.5} pb={2.5}>
        <TextQuestionInput
          value={textResponse}
          disabled={isQuestionCompleted}
          handleChange={handleResponseChange}
          feedbackState={feedbackState}
        />
      </Box>

      {displayFeedbackText && (
        <Box padding={1.5}>
          <Typography variant="caption">Feedback</Typography>
          <ContentDescription description={feedbackText} noPadding />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between">
        <Button onClick={onGoBack} disabled={isFirstQuestionInAssessment}>
          Previous
        </Button>

        {!isQuestionCompleted ? (
          <Button
            color="primary"
            variant="contained"
            disabled={disableSubmitButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : !isLastQuestionInAssessment ? (
          <Button
            color="primary"
            variant="contained"
            endIcon={<ChevronRightIcon />}
            onClick={onAdvance}
          >
            Next
          </Button>
        ) : (
          <Button color="primary" variant="contained" onClick={onExitCurrentTrack}>
            Finish
          </Button>
        )}
      </Box>
    </QuestionDetail>
  );
};
