import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { ApiURLs, fetchURL } from 'services/requests-base';
import { isEmpty } from 'vendor/lodash';

import { listQueryKeyFactory } from '../utils';

export const assignments = createQueryKeys('assignments', {
  list: listQueryKeyFactory({ endpointName: 'assignments_api:list_create' }),
  listFilters: (options?: string) => ({
    queryKey: ['api_content_items:assignments_rql_filters'],
    queryFn: async () => {
      const url = ApiURLs['api_content_items:assignments_rql_filters']();
      const queryParams = !isEmpty(options) ? `?${options}` : '';
      const { data } = await fetchURL(`${url}${queryParams}`);
      return { results: data };
    },
  }),
});

export type AssessmentsQueryKeys = inferQueryKeys<typeof assignments>;
