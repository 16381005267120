import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getUserImage } from 'services/utils';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import UserItem from 'shared/components/UserItem';
import { map } from 'vendor/lodash';

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const UserStyling = styled.div`
  margin: 4px;
  padding: 0;
  display: block;
`;

const MessageSection = styled.div`
  margin: 20px 0;
  padding: 0 30px;
`;

const Message = styled.span`
  font-size: 17px;
`;

const UserList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const EventUserListModal = (props) => {
  const { modalTitle, closeButtonText, invitedUsers, invitedEmails, message, handleClose } = props;

  return (
    <Modal handleClose={handleClose} title={modalTitle} width={600}>
      <ModalBody>
        <ModalContainer>
          <UserList>
            {map(invitedUsers, (item, i) => (
              <UserStyling key={`user-${i}`}>
                <Link to={`/users/${item.id}/`}>
                  <UserItem
                    userId={item.id}
                    userName={item.name || item.email}
                    userImage={getUserImage(item)}
                    size={32}
                  />
                </Link>
              </UserStyling>
            ))}
            {map(invitedEmails, (item, i) => (
              <UserStyling key={`email-${i}`}>
                <UserItem userEmail={item} size={32} />
              </UserStyling>
            ))}
          </UserList>
          <MessageSection>{message && <Message>{message}</Message>}</MessageSection>
        </ModalContainer>
      </ModalBody>
      {closeButtonText && (
        <ModalFooter variant="buttons">
          <ModalFooterButton onClick={handleClose}>{closeButtonText}</ModalFooterButton>
        </ModalFooter>
      )}
    </Modal>
  );
};

EventUserListModal.propTypes = {
  modalTitle: PropTypes.string,
  closeButtonText: PropTypes.string,

  invitedUsers: PropTypes.array,
  invitedEmails: PropTypes.array,

  message: PropTypes.string,

  handleClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  invitedUsers: state.eventShared.invitedUsers,
  invitedEmails: state.eventShared.invitedEmails,
});

export default connect(mapStateToProps, null)(EventUserListModal);
