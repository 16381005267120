import { useContext } from 'react';

import { ContentItemListenerContext } from 'app/stand-alone-shared/context-providers/ContentItemListenerContext';
import {
  LEARNING_TYPES,
  ASSIGNED_STATUS,
  COMPLETED_STATUS,
  INCOMPLETE_STATUS,
  UNASSIGNED_STATUS,
  CONTENT_TYPES,
} from 'catalog/constants';
import actions from 'entities/actions';
import {
  articleSchema,
  videoSchema,
  trackSchema,
  codelabSchema,
  courseSchema,
  eventTypeSchema,
  taskSchema,
  scheduledTrackSchema,
} from 'entities/schema';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';
import { get, includes, isInteger } from 'vendor/lodash';

export const useStandaloneActions = (content, type) => {
  const { onChange } = useContext(ContentItemListenerContext);
  const { trackActivity } = useMetrics();

  const [updateContent, { status, error }] = useEntities(
    actions.contentAssignment.updateSubmit,
    ({ data, status, error }) => {
      const errorMessage = get(error, 'message', null);
      if (status === STATUS_DONE) {
        // If we receive an integer here, it means we are creating, not updating it
        const goToFirstPage =
          isInteger(data) &&
          includes([CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track], content.content_type);
        onChange?.({ goToFirstPage });
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('', errorMessage);
      }
    }
  );

  const schemas = {
    [LEARNING_TYPES.articles]: articleSchema,
    [LEARNING_TYPES.codelabs]: codelabSchema,
    [LEARNING_TYPES.courses]: courseSchema,
    [LEARNING_TYPES.event_types]: eventTypeSchema,
    [LEARNING_TYPES.scheduled_tracks]: scheduledTrackSchema,
    [LEARNING_TYPES.tasks]: taskSchema,
    [LEARNING_TYPES.tracks]: trackSchema,
    [LEARNING_TYPES.videos]: videoSchema,
  };

  const contentSchema = get(schemas, type, null);

  const assign = ({ firstAccess = false, eventEnrollmentOptions = {} } = {}) => {
    updateContent(content.id, {
      assignment_status: ASSIGNED_STATUS,
      first_access: firstAccess,
      event_enrollment_options: eventEnrollmentOptions,
      contentSchema,
    });
    trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_TODO, {
      contentItemId: content.id,
      contentItemType: content.content_type,
    });
  };

  const unassign = ({ force_drop_track_item = false } = {}) => {
    updateContent(content.id, {
      assignment_status: UNASSIGNED_STATUS,
      contentSchema,
      force_drop_track_item,
    });
    trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_UNTODO, {
      contentItemId: content.id,
      contentItemType: content.content_type,
    });
  };

  const complete = () => {
    updateContent(content.id, {
      completion_status_for_write: COMPLETED_STATUS,
      contentSchema,
    });
  };

  const undoCompletion = () => {
    updateContent(content.id, {
      completion_status_for_write: INCOMPLETE_STATUS,
      contentSchema,
    });
  };

  const updateCompletion = ({ played }) => {
    updateContent(content.id, {
      progress: played,
      contentSchema,
    });
  };

  const startCompletion = () => {
    updateContent(content.id, {
      first_access: true,
      contentSchema,
    });
  };

  return {
    assign,
    unassign,
    complete,
    undoCompletion,
    updateCompletion,
    status,
    error,
    startCompletion,
  };
};
