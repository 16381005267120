import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BaseFilter from 'backoffice/components/Dashboard/Filters/BaseFilter';
import CompositeTextInput from 'inputs/components/CompositeTextInput';
import { useDebounce } from 'shared/hooks';
import { isNil, keys, head, includes } from 'vendor/lodash';

import { filterInputSx } from '../styles';

// {$gt: 1} => {op: 'gt', value: 1}
const rqlToInput = (filter) => {
  if (isNil(filter)) return { op: '$eq', value: '' };
  const op = head(keys(filter));
  if (includes(['$gt', '$ge', '$lt', '$le', '$eq', '$ne'], op)) return { op, value: filter[op] };
  return { op: '$eq', value: filter[op] || '' };
};

// {op: '$gt', value: 1} => {$gt: 1}
const inputToRQL = ({ op, value }) => {
  return { [op]: value };
};

const RQLCompositeNumberFilter = ({
  filter,
  onChange,
  // Base Filter
  label,
  handleRemoveFilter,
  inputWidth,
  // CompositeTextInput
  disabled,
}) => {
  const filterName = head(keys(filter));
  const { op: currentSelectionFilter, value: currentTextFilter } = rqlToInput(filter[filterName]);

  const [currentFilterValue, setCurrentFilterValue] = useState({
    selection: currentSelectionFilter,
    text: currentTextFilter,
  });
  const debouncedValue = useDebounce(currentFilterValue);

  const options = [
    { value: '$gt', label: 'Greater than' },
    { value: '$ge', label: 'Greater than or equal to' },
    { value: '$lt', label: 'Less than' },
    { value: '$le', label: 'Less than or equal to' },
    { value: '$eq', label: 'Equal to' },
    { value: '$ne', label: 'Not equal to' },
  ];

  useEffect(() => {
    setCurrentFilterValue({ selection: currentSelectionFilter, text: currentTextFilter });
  }, [currentTextFilter, currentSelectionFilter]);

  useEffect(() => {
    const { selection, text } = debouncedValue;
    if (text !== currentTextFilter || selection !== currentSelectionFilter) {
      // Updates url when values change
      onChange({ [filterName]: inputToRQL({ op: selection, value: text }) });
    } else if (!currentSelectionFilter) {
      // Adds default value fo selection once it's added to the filter list
      onChange({ [filterName]: inputToRQL({ op: '$eq', value: '' }) });
    }
  }, [debouncedValue]);

  return (
    <BaseFilter
      label={label}
      handleRemoveFilter={handleRemoveFilter}
      inputWidth={inputWidth}
      renderInput={() => (
        <CompositeTextInput
          label={label}
          aria-label={label}
          value={currentFilterValue.text}
          selection={currentFilterValue.selection}
          options={options}
          onChange={({ selection, value }) => {
            setCurrentFilterValue({ selection, text: value });
          }}
          disabled={disabled}
          sx={{
            ...(handleRemoveFilter && filterInputSx),
          }}
          type="number"
        />
      )}
    />
  );
};

RQLCompositeNumberFilter.propTypes = {
  filter: PropTypes.object, // {name: {$eq: 'text'}}
  onChange: PropTypes.func, // The onChange function will receive the field updated in the RQL Object format
  // BaseFilter
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
  inputWidth: PropTypes.string,
  // CompositeTextInput
  disabled: PropTypes.bool,
};

export default RQLCompositeNumberFilter;
