import React from 'react';
import { Field } from 'redux-form';

import TextField from 'inputs/components/TextField';
import { FormFieldGroup } from 'shared/components/Form';

const EnrollmentCapacitySection = () => {
  return (
    <FormFieldGroup>
      <Field
        name="capacity"
        label="Enrollment Cap"
        component={TextField}
        props={{ type: 'number', inputProps: { min: 0 } }}
        subtext="Empty or '0' means unlimited spots. This capacity will take precendence over capacities of all included events."
      />
    </FormFieldGroup>
  );
};

export default EnrollmentCapacitySection;
