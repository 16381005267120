import { Box } from '@mui/material';

import { CARD_LIST_WIDTH_THRESHOLDS } from 'app/content-items/components/CardListWrapper/constants';
import { useNewRqlFilters } from 'app/filters/hooks';
import PageTitle from 'app/shared/components/PageTitle';
import { isEmpty } from 'vendor/lodash';

import { useChannelHomeCurrentTab } from '../hooks/useChannelHomeCurrentTab';
import { Channel } from '../types';

import { ChannelHeader } from './ChannelHeader/ChannelHeader';
import { ChannelHeaderLite } from './ChannelHeaderLite/ChannelHeaderLite';
import { ChannelHomeCatalog } from './ChannelHome/ChannelHomeCatalog';
import { ChannelHomeDiscover } from './ChannelHome/ChannelHomeDiscover';
import { ChannelHomeEvents } from './ChannelHome/ChannelHomeEvents';
import { ChannelHomePeople } from './ChannelHome/ChannelHomePeople';

interface ChannelDetailProps {
  channel: Channel;
}

const ChannelDetailPage = (props: ChannelDetailProps) => {
  const { channel } = props;

  const currentTab = useChannelHomeCurrentTab();

  const { filters } = useNewRqlFilters();
  const isFiltering = !isEmpty(filters);

  return (
    <Box position="relative">
      <PageTitle title={channel.name} />

      <Box pt={3} pb={8} px={3}>
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: CARD_LIST_WIDTH_THRESHOLDS,
          }}
        >
          {!isFiltering && currentTab == null ? (
            <ChannelHeader channel={channel} />
          ) : (
            <ChannelHeaderLite channel={channel} />
          )}

          {currentTab == null ? <ChannelHomeDiscover channel={channel} /> : null}

          {currentTab === 'events' ? <ChannelHomeEvents channel={channel} /> : null}

          {currentTab === 'content' ? <ChannelHomeCatalog channel={channel} /> : null}

          {currentTab === 'people' ? <ChannelHomePeople channel={channel} /> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ChannelDetailPage;
