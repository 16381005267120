import { useState } from 'react';

import { CONTENT_TYPES, PUBLISHED_STATUS } from 'catalog/constants';
import { useGetContentTypeLabel } from 'catalog/hooks';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import VisibilityChip from 'shared-content-item/components/VisibilityChip';
import { TableSelectionMode } from 'shared/components/constants';
import ItemPicker from 'shared/components/ItemPicker';
import Modal, { ModalBody } from 'shared/components/Modal';
import { ActionCallback, DynamicFilter } from 'shared/components/types';
import { useLabels, useToggles } from 'shared/hooks';
import TrackCreateContentModal from 'tracks/components/TrackCreateContentModal';
import { trackTypes } from 'tracks/interfaces';
import { map, filter, includes, size } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';
import rql from 'vendor/rql';

import CreateButton from './CreateButton';

interface TrackItemAddContentModalProps {
  handleClose: () => void;
  callback: ActionCallback;
  trackType: trackTypes;
  trackItemsIds?: string[] | number[];
  trackFormName: string;
  trackId?: number;
}

const transformFilters = (
  dynamicFilters: DynamicFilter[],
  toggleComposableTracks: boolean
): DynamicFilter[] => {
  return map(dynamicFilters, (dynamicFilter) => {
    // Remove the content_type invalid options
    if (dynamicFilter.filter === 'content_type') {
      const { input } = dynamicFilter;
      const excludedOptions = [
        CONTENT_TYPES.scheduled_track,
        CONTENT_TYPES.event,
        CONTENT_TYPES.program,
        CONTENT_TYPES.officehour_program,
        CONTENT_TYPES.mentorship_program,
        CONTENT_TYPES.question,
        CONTENT_TYPES.multiple_choice_question,
        CONTENT_TYPES.text_question,
        ...[toggleComposableTracks ? null : CONTENT_TYPES.track],
      ];
      return {
        ...dynamicFilter,
        input: {
          ...input,
          options: filter(input.options, (option) => !includes(excludedOptions, option.value)),
        },
      };
    }
    return dynamicFilter;
  });
};

const PAGE_SIZE = 5;

const TrackItemAddContentModal = ({
  handleClose,
  callback,
  trackType,
  trackItemsIds = [],
  trackFormName,
  trackId,
}: TrackItemAddContentModalProps) => {
  const { toggle_composable_tracks: toggleComposableTracks } = useToggles();
  const getLabel = useGetContentTypeLabel();

  const [showCreateContentModal, setShowCreateContentModal] = useState(false);
  const [contentType, setContentType] = useState('');
  const { label_track: labelTrack } = useLabels();
  const handleSelectContent = (newContentType: string) => {
    setContentType(newContentType);
    setShowCreateContentModal(true);
  };

  const showVisibilityChip = trackType !== CONTENT_TYPES.assessment;

  const columns = [
    {
      field: 'name',
      headerName: 'Content',
      filterable: false,
      sortable: true,
      editable: false,
      width: 310,
    },
    {
      field: 'facilitators',
      headerName: 'Owner(s)',
      filterable: false,
      sortable: false,
      editable: false,
      width: 160,
      valueFormatter: ({ value }) => {
        const namesList = map(value, (facilitator) => facilitator.user?.display_name);
        return namesList.join(', ');
      },
    },
    {
      field: 'content_type',
      headerName: 'Type',
      filterable: false,
      sortable: false,
      editable: false,
      width: 120,
      valueFormatter: ({ value }) => getLabel(value),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      filterable: false,
      sortable: false,
      editable: false,
      width: 120,
    },
    {
      field: 'average_feedback_rating',
      headerName: 'Rating',
      filterable: false,
      sortable: false,
      editable: false,
      width: 80,
      valueFormatter: ({ value }) => Math.round(value * 10) / 10,
    },
    ...(showVisibilityChip
      ? [
          {
            field: 'visibility',
            headerName: 'Visibility',
            filterable: false,
            sortable: false,
            editable: false,
            flex: 1,
            renderCell: ({ row: content }) => (
              <VisibilityChip content={content} size="small" sx={{ height: '20px' }} />
            ),
          },
        ]
      : []),
  ];

  if (showCreateContentModal) {
    return (
      <TrackCreateContentModal
        contentType={contentType}
        trackFormName={trackFormName}
        onAdd={callback}
        handleClose={() => setShowCreateContentModal(false)}
      />
    );
  }

  return (
    <Modal
      title={`Add Content to ${labelTrack}`}
      width={1000}
      handleClose={handleClose}
      handleBack={handleClose}
      disableEscapeKeyDown
      disableCloseOnBackdropClick
    >
      <ModalBody>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <Typography>
            Add content to the {labelTrack} either by selecting something from the Content Item
            Catalog or by creating a new one:
          </Typography>
          <CreateButton handleSelectContent={handleSelectContent} />
        </Box>
        <ItemPicker
          fetchAction={actions.content.retrieveListRql}
          fetchActionOptions={rql({
            view_mode: 'track_item_picker',
            content_type: {
              $out: [
                CONTENT_TYPES.event,
                CONTENT_TYPES.scheduled_track,
                CONTENT_TYPES.program,
                CONTENT_TYPES.officehour_program,
                CONTENT_TYPES.mentorship_program,
                CONTENT_TYPES.question,
                CONTENT_TYPES.multiple_choice_question,
                CONTENT_TYPES.text_question,
                ...(toggleComposableTracks ? [] : [CONTENT_TYPES.track]),
              ],
            },
            status: PUBLISHED_STATUS,
            // Exclude the track from the request to not override the redux state.
            ...(trackId
              ? {
                  id: {
                    $out: [trackId],
                  },
                }
              : {}),
          })}
          schema={contentSchema}
          filtersAction={actions.content.retrieveFilters}
          defaultOrdering="name"
          columns={columns}
          pageSize={PAGE_SIZE}
          actions={[
            {
              label: `Add to ${labelTrack}`,
              callback,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
          ]}
          filterBarInputWidth="240px"
          disabledItemsIds={trackItemsIds}
          transformFilters={(dynamicFilters) =>
            transformFilters(dynamicFilters, toggleComposableTracks)
          }
          selectionMode={TableSelectionMode.multiple}
        />
      </ModalBody>
    </Modal>
  );
};

export default TrackItemAddContentModal;
