import { useEffect, useState, useRef, useMemo } from 'react';

import { RQLComp } from 'shared/components/types';
import { noop, get, toString, debounce } from 'vendor/lodash';
import { TextField, InputAdornment } from 'vendor/mui';
import { SearchIcon } from 'vendor/mui-icons';

interface RQLSearchBarProps {
  value: RQLComp | null;
  label?: string;
  placeholder?: string;
  onChange?: (newValue: RQLComp | null) => void;
  width?: number | string;
  backgroundColor?: string;
}

const RQLSearchBar = (props: RQLSearchBarProps) => {
  const { value, label, placeholder, onChange = noop, width = 380, backgroundColor = null } = props;

  const initialTextFieldValue = get(value, '$eq', '');
  const [textFieldValue, setTextFieldValue] = useState(initialTextFieldValue);

  // Inspiration taken from: https://www.developerway.com/posts/debouncing-in-react
  // We need a stable reference to the onChange function
  // Otherwise, the debouncing the function will not work as expected
  // Since each time the function changes, the debounced function will be recreated
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const debouncedHandleChangeSearchFilter = useMemo(() => {
    return debounce((newValue: string) => {
      onChangeRef.current({ $eq: newValue });
    }, 500);
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = toString(event?.target.value);
    setTextFieldValue(newValue);
    debouncedHandleChangeSearchFilter(newValue);
  };

  // If the value is changed by a different component (by removing the filter pill for example), the text field should reflect the change.
  useEffect(() => {
    setTextFieldValue(initialTextFieldValue);
  }, [initialTextFieldValue]);

  return (
    <TextField
      type="text"
      variant="outlined"
      size="small"
      label={label}
      placeholder={placeholder}
      value={textFieldValue}
      onChange={handleChangeInput}
      sx={{ width, flex: 1, minWidth: 100, backgroundColor: backgroundColor, borderRadius: 2 }}
      InputProps={{
        style: { resize: 'vertical' },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true, // https://mui.com/material-ui/react-text-field/#shrink
      }}
    />
  );
};

export default RQLSearchBar;
