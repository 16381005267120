import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';

const Container = styled.div`
  margin-bottom: 16px;
  height: 40px;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 2px;
  align-self: center;
  border: solid 2px ${colors.neutral500};
  background-color: transparent;
  display: flex;
  justify-content: ${({ mainText, subText }) => (mainText && subText ? 'space-between' : 'center')};
  align-items: center;
  padding: 0 20px;
`;

const PrimaryText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SecondaryText = styled(Text)`
  @media (max-width: 350px),
    (min-width: 614px) and (max-width: 685px),
    (min-width: 992px) and (max-width: 1200px) {
    font-size: 10px;
  }
`;

const DisabledButton = ({ mainText, subText, icon }) => (
  <Container>
    <TextWrapper alt={mainText || subText} mainText={mainText} subText={subText}>
      {mainText && (
        <PrimaryText size="h4" medium align="center" color={colors.neutral900}>
          {icon && (
            <i className={`mdi mdi-${icon} mdi-24px`} aria-hidden="true">
              &nbsp;
            </i>
          )}
          {mainText}
        </PrimaryText>
      )}
      {subText && (
        <SecondaryText align="center" color={colors.neutral400} size="h5" medium>
          {subText}
        </SecondaryText>
      )}
    </TextWrapper>
  </Container>
);

DisabledButton.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  icon: PropTypes.string,
};

export default DisabledButton;
