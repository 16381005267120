import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Modal } from 'shared';
import Button from 'shared/components/Button/Button';
import InfoBox from 'shared/components/InfoBox';
import { ModalBody, ModalFooter } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const RemoveSurveyWithResponsesModal = ({ responsesCount, handleRemove, handleClose }) => {
  return (
    <Modal title="Remove Survey & Delete Responses" handleClose={handleClose} width={500}>
      <ModalBody>
        <StyledText>
          You&apos;re about to delete a survey with {responsesCount}{' '}
          {responsesCount === 1 ? 'response' : 'responses'} from attendees.
        </StyledText>
        <StyledText>Are you sure you want to do this?</StyledText>
        <InfoBox
          content='All changes will only be saved after clicking the "Save" button on the event&apos;s edit form.'
          type="warning"
        />
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <CancelButton color="secondary" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button color="error" onClick={handleRemove}>
            Remove & Delete Responses
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

RemoveSurveyWithResponsesModal.propTypes = {
  responsesCount: PropTypes.number,
  handleClose: PropTypes.func,
  handleRemove: PropTypes.func,
};

export default RemoveSurveyWithResponsesModal;
