import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EventItemHorizontal from 'event-shared/components/EventItemHorizontal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { ApiURLs } from 'services/requests-base';
import Text from 'shared/components/Text';
import { map, slice, get } from 'vendor/lodash';
import pluralize from 'vendor/pluralize';

const EventsContainer = styled.div`
  height: 515px;
  overflow: auto;
`;

const EventItemContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.neutral100};

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${colors.neutral50};
  }
`;

const TabTitleContainer = styled.div`
  display: flex;
  padding: 20px 20px 16px;
  border-bottom: 1px solid ${colors.neutral100};
`;

export const EventsTab = (props) => {
  const {
    userId,
    events,
    pastEvents,
    userName,
    userTimezone,
    singleCreatorTitle,
    multipleCreatorsTitle,
  } = props;

  const pastPage = 10;
  const morePast = get(pastEvents, 'length', 0) > pastPage;
  const allEventsUrl = `${ApiURLs['events:list']()}?(eq(facilitator,${userId}))`;
  return (
    <div>
      {events && (
        <TabTitleContainer>
          <Text medium size="h5" lineHeight={20} color={colors.neutral900}>
            {events.length} upcoming {pluralize('event', events.length)} presented by {userName}
          </Text>
        </TabTitleContainer>
      )}
      <EventsContainer>
        {map(events, (event) => (
          <Link
            key={event.public_id}
            to={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
          >
            <EventItemContainer>
              <EventItemHorizontal
                event={event}
                userTimezone={userTimezone}
                singleCreatorTitle={singleCreatorTitle}
                multipleCreatorsTitle={multipleCreatorsTitle}
              />
            </EventItemContainer>
          </Link>
        ))}
        {pastEvents && (
          <TabTitleContainer>
            <Text medium size="h5" lineHeight={20} color={colors.neutral900}>
              {morePast ? pastPage : pastEvents.length} past {pluralize('event', pastEvents.length)}{' '}
              presented by {userName}.{morePast && <Link to={allEventsUrl}> See All</Link>}
            </Text>
          </TabTitleContainer>
        )}
        {map(slice(pastEvents, 0, pastPage), (event) => (
          <Link
            key={event.public_id}
            to={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
          >
            <EventItemContainer>
              <EventItemHorizontal
                event={event}
                userTimezone={userTimezone}
                singleCreatorTitle={singleCreatorTitle}
                multipleCreatorsTitle={multipleCreatorsTitle}
              />
            </EventItemContainer>
          </Link>
        ))}
      </EventsContainer>
    </div>
  );
};

EventsTab.propTypes = {
  userId: PropTypes.string,
  events: PropTypes.array,
  pastEvents: PropTypes.array,
  userName: PropTypes.string,
  userTimezone: PropTypes.string,
  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
};

export default EventsTab;
