import { useMemo } from 'react';

import { CONTENT_TYPES } from 'app/catalog/constants';
import { useLabels } from 'app/shared/hooks';
import { TrackItem } from 'features/tracks/types';
import { getUID } from 'services/utils';
import Modal from 'shared/components/Modal';
import { capitalize, get, isEmpty, map, pick, sortBy, omit } from 'vendor/lodash';

import AssessmentQuestionForm, { QuestionTypes } from './AssessmentQuestionForm';

const getIsEdit = (initialValuesParams?: TrackItem) => {
  return !isEmpty(initialValuesParams);
};

export interface AssessmentQuestionModalProps {
  handleSubmit: (payload: any, isEdit: boolean) => void;
  handleClose: () => void;
  questionType: QuestionTypes;
  initialValuesParams?: TrackItem;
}

const getInitialValues = (initialValuesParams?: TrackItem) => {
  if (isEmpty(initialValuesParams)) {
    return {
      option_set: [
        { id: getUID(), value: '', selected: true, is_archived: false },
        { id: getUID(), value: '', selected: false, is_archived: false },
      ],
    };
  }

  const initialValues = pick(initialValuesParams?.content_item, [
    'content_body',
    'explanation',
    'feedback_text_on_success',
    'feedback_text_on_fail',
    'id',
    'accepted_answers_value',
    'accepted_answers_operator',
  ]);

  const optionSet = map(
    sortBy(get(initialValuesParams, 'content_item.option_set'), 'order'),
    (option) => {
      return {
        ...omit(option, 'is_correct'),
        selected: option.is_correct,
      };
    }
  );

  return { ...initialValues, option_set: optionSet };
};

export const AssessmentQuestionModal = ({
  handleSubmit,
  handleClose,
  questionType,
  initialValuesParams,
}: AssessmentQuestionModalProps) => {
  const { label_question: labelQuestion, label_choice: labelChoice } = useLabels();

  const initialValues = useMemo(() => getInitialValues(initialValuesParams), [initialValuesParams]);

  const isEdit = getIsEdit(initialValuesParams);

  const modalTitle = `${isEdit ? 'Edit' : 'Add'} ${capitalize(labelQuestion)} (${
    questionType === CONTENT_TYPES.multiple_choice_question
      ? `Multiple ${capitalize(labelChoice)}`
      : 'Short Answer'
  })`;

  const AssessmentQuestionFormWithoutTypeChecks: any = AssessmentQuestionForm;

  return (
    <Modal
      title={modalTitle}
      handleClose={handleClose}
      handleBack={handleClose}
      width="820px"
      maxWidth="820px"
      maxBodyHeight="640px"
      height="640px"
    >
      <AssessmentQuestionFormWithoutTypeChecks
        initialValues={initialValues}
        isEdit={isEdit}
        questionType={questionType}
        onSubmitSuccessHandler={(result) => handleSubmit(result, isEdit)}
        handleCancel={handleClose}
      />
    </Modal>
  );
};

export default AssessmentQuestionModal;
