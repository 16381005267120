import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { scrollToTop } from 'services/utils';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import {
  transformFromJSONEditorToSerializerFormat,
  transformFromJSONEditorToPreviewFormat,
} from 'surveys/services';
import { isEmpty, noop } from 'vendor/lodash';

import SurveyCreationPreview from './SurveyCreationPreview';

const Container = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

const FormContainer = styled.div`
  max-width: 580px;
  margin: 0px auto;
  padding: 20px;
  background-color: ${colors.neutral0};
  border-radius: 8px;

  // Custom style for json-editor
  // !important necessary to override style
  * {
    font-family: 'Roboto', Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  h3 {
    margin: 0;
    font-size: 18px;
  }

  div > label {
    font-size: 14px;
  }

  label {
    margin: 5px 0px;
    font-weight: normal;
    color: ${colors.neutral900};
  }

  i {
    font-family: 'FontAwesome';
  }

  .btn-group {
    & button:first-child {
      margin-bottom: 16px;
    }

    button + button {
      margin-left: 16px !important;
      margin-bottom: 16px;
    }
  }

  button {
    // medium
    height: 32px;
    padding: 0 12px;
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;

    &.json-editor-btntype-delete span {
      display: none;
    }

    &.json-editor-btntype-delete:after {
      content: 'Remove Question';
    }

    // primary
    &.json-editor-btn-add,
    &.json-editor-btntype-add {
      border-color: ${colors.action600};
      color: ${colors.action600};

      &:hover,
      &:focus {
        background-color: ${colors.action600};
        color: ${colors.action600TextColor};
      }
    }

    // secondary
    &.json-editor-btn-moveup,
    &.json-editor-btn-movedown {
      border-color: ${colors.action700};
      color: ${colors.action700};

      &:hover,
      &:focus {
        background-color: ${colors.action700};
        color: ${colors.action700TextColor};
      }
    }

    // error
    &.json-editor-btn-delete,
    &.json-editor-btntype-delete,
    &.json-editor-btn-subtract {
      border-color: ${colors.error600};
      color: ${colors.error600};

      &:focus {
        background-color: ${colors.error600};
        color: ${colors.error600TextColor};
      }

      &:hover,
      &:focus {
        background-color: ${colors.error600};
        color: ${colors.error600TextColor};
      }
    }
  }

  .form-group {
    margin-bottom: 16px;
  }

  .text-input,
  .select-input {
    width: 100%;
    display: flex;
    padding: 4px 8px;
    border: 1px solid;
    border-radius: 2px;
    font-size: 16px;
    border-color: ${colors.neutral400};

    &:focus {
      outline: none;
      border-color: ${colors.action600};
    }

    &:disabled {
      background-color: ${colors.neutral100};
    }

    ::placeholder {
      color: ${colors.neutral400};
    }

    ::-ms-input-placeholder {
      color: ${colors.neutral400};
    }
  }

  td {
    .json-editor-btn-delete i {
      display: block;
    }
    .json-editor-btn-delete:after {
      display: none;
    }
  }

  .select-input {
    background-color: ${colors.neutral0};
  }

  .form-check {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    margin-right: 8px;
    height: 20px;
    width: 20px;
  }

  // Question details
  [data-schemapath='root.questions'] {
    > h3 {
      display: block !important;
      margin: 32px 0px 16px;

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        width: 100%;
        margin-bottom: 16px;
        border-top: 1px solid ${colors.neutral200};
      }
    }

    // Question item
    > .card.card-body > div > div {
      margin-bottom: 16px;
      padding: 16px;
      border: 1px solid ${colors.neutral200};

      h3 {
        font-size: 14px;
      }

      // Options items
      .table {
        padding: 0;
        margin: 0;
      }
    }
  }

  .invalid-feedback {
    color: ${colors.error600};
  }
`;

const TitleContainer = styled.h1`
  max-width: 580px;
  margin: 16px auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 580px;
  margin: 36px auto 20px;
`;

const PrimaryButtons = styled.div`
  display: flex;

  > * + * {
    margin-left: 20px;
  }
`;

const HeaderContainer = styled.div`
  max-width: 580px;
  margin: 0px auto;
`;

const SurveyForm = ({
  value,
  title,
  schema,
  isLoading,
  renderHeader,
  onSubmit,
  redirectionRoute,
}) => {
  const [isLoadingScript, setIsLoadingScript] = useState(true);
  const [seePreview, setSeePreview] = useState(false);
  const [surveyPreview, setSurveyPreview] = useState(null);

  const editor = useRef(null);
  const element = useRef(null);

  // If value is provided, update form value when it changes
  useEffect(() => {
    if (!isLoadingScript && value) {
      // From https://github.com/json-editor/json-editor/issues/610#issuecomment-728758100
      editor.current.editors.root.setValue(value, true);
    }
  }, [isLoadingScript, value]);

  // Add the json-editor script and instanciate the form.
  useEffect(() => {
    const handleScriptLoad = () => {
      setIsLoadingScript(false);

      window.JSONEditor.defaults.languages.en.error_notempty = 'This field is required';

      editor.current = new window.JSONEditor(element.current, {
        schema,
        theme: 'bootstrap4',
        iconlib: 'fontawesome4',
        show_errors: 'interaction',
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
      });
    };

    if (!window.JSONEditor) {
      const script = document.createElement('script');
      script.src =
        'https://cdn.jsdelivr.net/npm/@json-editor/json-editor@latest/dist/jsoneditor.min.js';
      script.async = true;
      script.addEventListener('load', () => handleScriptLoad());

      // eslint-disable-next-line unicorn/prefer-node-append
      document.body.appendChild(script);
    } else {
      handleScriptLoad();
    }
  }, []);

  const handleErrors = ({ errorMessage }) => {
    const errors = editor.current.validate();

    if (!isEmpty(errors)) {
      toast.error(errorMessage);
      // Necessary to show errors in the UI
      editor.current.options.show_errors = 'always';
      editor.current.onChange();
    }

    return errors;
  };

  const handleOnSubmit = () => {
    if (!editor.current) return;

    const errors = handleErrors({ errorMessage: 'Submission failed, fix errors and try again.' });
    if (!isEmpty(errors)) return;

    const payload = transformFromJSONEditorToSerializerFormat(editor.current.getValue());
    onSubmit(payload);
  };

  const handleOpenPreview = () => {
    if (!editor.current) return;

    const errors = handleErrors({ errorMessage: 'Fix form errors before seeing the preview.' });
    if (!isEmpty(errors)) return;

    const survey = transformFromJSONEditorToPreviewFormat(editor.current.getValue());
    setSurveyPreview(survey);
    setSeePreview(true);
    scrollToTop();
  };

  const handleClosePreview = () => setSeePreview(false);

  const showLoading = isLoadingScript || isLoading;

  return (
    <>
      {showLoading && <Loading />}

      {seePreview && (
        <SurveyCreationPreview survey={surveyPreview} handleClosePreview={handleClosePreview} />
      )}

      {/*
        The form (ref={element}) must be hidden with display none so it will not be removed from the DOM.
        This way some json-editor rerender issues are avoided.
      */}
      <Container hidden={showLoading || seePreview}>
        <TitleContainer>
          <Text size="h1" color={colors.neutral400}>
            {title}
          </Text>
        </TitleContainer>

        <HeaderContainer>{renderHeader?.()}</HeaderContainer>

        <FormContainer>
          <div ref={element} />
        </FormContainer>

        <ButtonsContainer>
          <Button type="button" color="error" size="large" route={redirectionRoute}>
            Back
          </Button>

          <PrimaryButtons>
            <Button type="button" size="large" onClick={handleOpenPreview}>
              Preview
            </Button>
            <Button type="submit" size="large" onClick={handleOnSubmit}>
              Save
            </Button>
          </PrimaryButtons>
        </ButtonsContainer>
      </Container>
    </>
  );
};

SurveyForm.propTypes = {
  value: PropTypes.object,
  schema: PropTypes.object,
  onSubmit: PropTypes.func,
  redirectionRoute: PropTypes.string,
  isLoading: PropTypes.bool,
  renderHeader: PropTypes.func,
  title: PropTypes.string,
};

SurveyForm.defaultProps = {
  renderHeader: noop,
};

export default SurveyForm;
