import { number, string, arrayOf, shape, bool, oneOfType, any } from 'prop-types';

const surveyQuestion = shape({
  id: number,
  text: string,
  kind: string,
  is_required: bool,
  upper_range: number,
  lower_range: number,
  label_1: string,
  label_2: string,
  options: oneOfType([arrayOf(any)]),
});

const survey = shape({
  id: number,
  title: string,
  description: string,
});

const surveyRelationship = shape({
  id: number,
  survey,
  event: number,
  event_type: number,
  program: number,
  track: number,
  session: number,
  survey_cutoff_period_value: number,
  survey_cutoff_period_option: string,
  survey_schedule_period_value: number,
  survey_schedule_period_option: string,
  survey_schedule_kind: string,
  questions: arrayOf(surveyQuestion),
  is_available_for_answer: bool,
  is_answered_by_user: bool,
});

const surveyProptypes = {
  surveyQuestion,
  survey,
  surveyRelationship,
};

export default surveyProptypes;
