import PropTypes from 'prop-types';
import React from 'react';

import { mapRoute } from 'services/requests';

import BasePersonCard from './BasePersonCard';

const PersonCard = ({ person, disableAllClicks, q }) => {
  const { id } = person;

  const userProfileUrl = mapRoute('userProfile', { id });

  return (
    <BasePersonCard
      user={person}
      route={userProfileUrl}
      disableAllClicks={disableAllClicks}
      q={q ? `?q=${q}` : ''}
    />
  );
};

PersonCard.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.number,
    free_hosting_sessions_count: PropTypes.number,
    badges: PropTypes.array,
    name: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.object,
    skills: PropTypes.array,
  }),
  disableAllClicks: PropTypes.bool,
  q: PropTypes.string,
};

export { PersonCard };
