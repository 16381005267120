import { Box } from '@mui/material';
import { FC } from 'react';

import ButtonLink from 'shared/components/ButtonLink';

interface HelpLinkProps {
  url: string;
  block?: boolean;
}

const HelpLink: FC<HelpLinkProps> = ({ url, children, block }) => {
  return (
    <Box sx={{ ml: 0.5, display: block ? 'inline-block' : 'inline' }}>
      <ButtonLink size="medium" target="_blank" url={url} icon="support" iconPlacement="left">
        {children}
      </ButtonLink>
    </Box>
  );
};

export default HelpLink;
