import PropTypes from 'prop-types';
import React from 'react';

import { FACILITATOR_ROLES } from 'catalog/constants';
import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import TrackForm from 'tracks/components/TrackForm/';
import { has, omit, filter, map, isEmpty } from 'vendor/lodash';

const getInitialValues = ({
  currentUser,
  defaultSettings,
  clonedTrack,
  track,
  defaultChannelId,
}) => {
  const initialValues = clonedTrack
    ? omit(
        {
          ...clonedTrack,
          name: `Duplicate of ${clonedTrack.name}`,
        },
        ['id', 'public_id', 'public_id_and_slug', 'track_items_names']
      )
    : { ...track };

  if (clonedTrack) {
    initialValues.track_items_names = [];
  }

  initialValues.maintainers_ids = map(
    filter(initialValues.facilitators, ['role', FACILITATOR_ROLES.author]),
    ({ user }) => user.id
  );

  // Facilitators
  if (isEmpty(initialValues.maintainers_ids)) {
    initialValues.maintainers_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Settings
  if (!has(initialValues, 'settings')) {
    initialValues.settings = defaultSettings;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

const TrackFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { track },
}) => {
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const initialValues = getInitialValues({
    currentUser,
    defaultChannelId,
    ...initialValuesParams,
  });

  const isEdit = Boolean(track);
  const form = `newTrack${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <TrackForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) =>
          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          })
        }
      />
    </>
  );
};

TrackFormPage.defaultProps = {
  initialValuesParams: {},
};

TrackFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};

export default TrackFormPage;
