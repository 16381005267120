import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ENROLLMENT_STATUS_NOT_GOING } from 'event-shared/constants';
import InfoBox from 'shared/components/InfoBox';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const DeregistrationModal = ({ updateEnrollmentStatus, show, event, handleClose }) => {
  const handleUnenrollClick = () => {
    updateEnrollmentStatus(ENROLLMENT_STATUS_NOT_GOING);
    handleClose();
  };

  const buttonText = event.external_link ? 'Unenroll Externally' : 'Unenroll';

  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Unenroll"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <ModalBodyWrapper>
          <Text size="h4">We will miss you!</Text>
          <InfoBox content={event.external_link_description} />
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleUnenrollClick} alt={buttonText} color="error">
          {buttonText}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DeregistrationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  event: PropTypes.object,

  updateEnrollmentStatus: PropTypes.func,
};

export default DeregistrationModal;
