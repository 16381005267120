import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { reduxForm } from 'redux-form';

import { OnChangeListenerContext } from 'app/settings/context-providers/OnChangeListenerContext';
import { prepareSettingsValuesForSubmission } from 'app/settings/services';
import actions from 'entities/actions';
import { toast } from 'notifications/components/NotificationCenter';
import userActions from 'users/actions';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import SettingsForm from './SettingsForm';

export const SettingsFormPage = ({
  form,
  menuItems,
  settings,
  change,
  invalid,
  handleSubmit,
  error,
  submitting,
  canResetToDefault,
  loading,
}) => {
  const listenerValue = useRef({
    onChange: change,
    canResetToDefault,
  });

  return (
    <OnChangeListenerContext.Provider value={listenerValue.current}>
      <SettingsForm
        form={form}
        menuItems={menuItems}
        settings={settings}
        invalid={invalid}
        loading={loading}
        handleSubmit={handleSubmit}
        error={error}
        submitting={submitting}
      />
    </OnChangeListenerContext.Provider>
  );
};

SettingsFormPage.defaultProps = {
  canResetToDefault: false,
};

SettingsFormPage.propTypes = {
  form: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.object,
  menuItems: PropTypes.array,
  settings: PropTypes.object,
  submitting: PropTypes.bool,
  canResetToDefault: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

const ConnectedSettingsFormPage = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false, // Needs to be false. Otherwise, fields values will not prefill in case they are inside sections with defaultOpen as false.
  onSubmit: (values, dispatch, { formName, currentContext }) => {
    const actionName = actions.customer.update.toString();
    return onSubmitActions(actionName, (values) => {
      const payload = {
        settings: prepareSettingsValuesForSubmission(values.settings),
      };

      return {
        key: formName,
        body: payload,
        settingsContext: currentContext,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch) => {
    toast.success('Settings saved successfully');
    dispatch(userActions.currentUserRequestSubmit());
  },
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Settings submission failed. Check the flagged fields and try again.');
  },
})(SettingsFormPage);

export default ConnectedSettingsFormPage;
