import { createActions } from 'redux-actions';

export default createActions(
  {},

  'USER_ALERT_LIST_FETCH_REQUEST',
  'USER_ALERT_LIST_FETCH_REQUEST_SUBMIT',
  'USER_ALERT_LIST_FETCH_REQUEST_SUCCESS',
  'USER_ALERT_LIST_FETCH_REQUEST_FAILURE',

  'USER_ALERT_CREATE_REQUEST',
  'USER_ALERT_CREATE_REQUEST_SUBMIT',
  'USER_ALERT_CREATE_REQUEST_SUCCESS',
  'USER_ALERT_CREATE_REQUEST_FAILURE',

  'USER_ALERT_DELETE_REQUEST',
  'USER_ALERT_DELETE_REQUEST_SUBMIT',
  'USER_ALERT_DELETE_REQUEST_SUCCESS',
  'USER_ALERT_DELETE_REQUEST_FAILURE'
);
