import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const DownloadIcon = ({ width, height, fill }) => {
  const d =
    'M0 11.333h2.5v5.563c0 .562.375.937.938.937h13.124c.563 0 .938-.375.938-.937v-5.563H20V17c0 ' +
    '1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3v-5.667zm14.329-4.5l1.6 1.556-5.715 5.444L4.5 8.39l1.6-1.55' +
    '6 2.971 2.778V.5h2.286v9.111l2.972-2.778z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

DownloadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default DownloadIcon;
