import React from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import colors from 'services/colors';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { SectionTrackItemSummary } from 'tracks/components/SectionTrackItemSummary';
import { isEmpty } from 'vendor/lodash';
import { Container, Divider, Paper, Typography } from 'vendor/mui';

import { TrackDetailBodyExtra } from './TrackDetailBodyExtra';

export const TrackBodyTitle = (props: React.ComponentProps<typeof Typography>) => (
  <Typography fontSize="18px" color={colors.emphasis700} {...props} />
);

const TrackDetailBodyDescriptionAndSummary = ({ track }: { track: any }) => {
  const showSummary = track.content_type !== CONTENT_TYPES.assessment && !isEmpty(track.sections);

  return (
    <Container sx={{ padding: '30px' }}>
      <TrackBodyTitle>Description</TrackBodyTitle>
      <ContentDescription description={track.content_body} noPadding />
      {showSummary && <SectionTrackItemSummary track={track} />}
    </Container>
  );
};

export const TrackDetailBody = ({ track }: { track: any }) => {
  return (
    <Paper>
      <TrackDetailBodyDescriptionAndSummary track={track} />
      <Divider variant="fullWidth" />
      <TrackDetailBodyExtra track={track} />
    </Paper>
  );
};
