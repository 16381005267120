import { ReactNode } from 'react';

import { map } from 'vendor/lodash';
import { Grid } from 'vendor/mui';

interface Props<T> {
  items?: T[];
  renderItem?: (item: T) => ReactNode;
  columns?: { [key: string]: number | null };
}

const CardList = <T,>({
  items,
  renderItem,
  columns = { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
}: Props<T>) => {
  return (
    <Grid container spacing={3} columns={columns}>
      {map(items, (item, index) => (
        <Grid item xs={1} key={index}>
          {renderItem?.(item)}
        </Grid>
      ))}
    </Grid>
  );
};

export default CardList;
