import { all, put, take, race, call } from 'redux-saga/effects';

import { FORM_SUBMIT } from './common';

const formSubmitSaga = (SubmissionError) =>
  function* formSubmitSaga() {
    while (true) {
      // eslint-disable-line no-constant-condition
      const {
        meta: { successActionType, failureActionType, resolve, reject },
        payload,
      } = yield take(FORM_SUBMIT);

      let response;

      if (all) {
        response = yield all([
          race({
            success: take(successActionType),
            failure: take(failureActionType),
          }),
          put(payload),
        ]);
      } else {
        response = yield [
          race({
            success: take(successActionType),
            failure: take(failureActionType),
          }),
          put(payload),
        ];
      }
      const [{ success, failure }] = response;

      if (success) {
        yield call(resolve, success.payload);
      } else {
        yield call(reject, new SubmissionError(failure.payload));
      }
    }
  };

export default formSubmitSaga;
