import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import { ATTENDANCE_METHODS, ATTENDANCE_METHODS_LABELS } from 'enrollments/constants';
import { MY_UPCOMING_TYPES_ICONS, MY_UPCOMING_TYPES_LABELS } from 'features/my_upcoming/constants';
import { useGetAllCachedOptions } from 'filters/hooks';
import { PillsDefinitionProps } from 'filters/types';
import {
  dateRangeRQLToDateRangeInput,
  getDateRangeDateRangeInputFormat,
  getDateRangeText,
} from 'filters/utils';
import { RQLDateRange } from 'shared/components/types';
import { get, sortBy, filter, keys, includes, map, concat } from 'vendor/lodash';

const useMyUpcomingPillsDefinition = ({ filters }: PillsDefinitionProps) => {
  // Cached options
  const allTags = useGetAllCachedOptions({ keyPrefix: 'tags' });
  const allUsers = useGetAllCachedOptions({ keyPrefix: 'old_users' });
  const allLocations = useGetAllCachedOptions({ keyPrefix: 'locations', key: 'list' });
  // Values
  const searchValue = get(filters, 'q.$eq', '');
  const selectedTypes = rqlToInput(get(filters, 'type'));
  const selectedAttendanceMethod = get(rqlToInput(get(filters, 'attendance_method')), '0', null);
  const selectedFacilitators = rqlToInput(get(filters, 'facilitator'));
  const selectedAttendees = rqlToInput(get(filters, 'attendee'));
  const selectedCategories = rqlToInput(get(filters, 'category'));
  // Dates
  const { startDate, endDate } = dateRangeRQLToDateRangeInput(
    get(filters, 'start_time', null) as RQLDateRange | null
  );
  const format = getDateRangeDateRangeInputFormat({ startDate, endDate }) || 'relative';
  const dateMessage = getDateRangeText({ startDate, endDate, format });
  const selectedLocations = rqlToInput(get(filters, 'location'));
  // Tags and flexible filters
  const otherFilters = sortBy(
    filter(
      keys(filters),
      (key: string) =>
        !includes(
          [
            'q',
            'type',
            'attendance_method',
            'facilitator',
            'attendee',
            'start_time',
            'location',
            'category',
            'view_density',
          ],
          key
        )
    )
  );
  return {
    pills: [
      ...(searchValue
        ? [
            {
              id: `search-${searchValue}`,
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),
      ...map(selectedTypes, (value: string) => ({
        id: `type-${value}`,
        value: value,
        label: MY_UPCOMING_TYPES_LABELS[value],
        icon: MY_UPCOMING_TYPES_ICONS[value],
        filterName: 'type',
      })),
      ...(selectedAttendanceMethod
        ? [
            {
              id: `attendance-method-${selectedAttendanceMethod}`,
              value: selectedAttendanceMethod,
              label: ATTENDANCE_METHODS_LABELS[selectedAttendanceMethod],
              icon: selectedAttendanceMethod === ATTENDANCE_METHODS.local ? 'location' : 'online',
              filterName: 'attendance_method',
            },
          ]
        : []),
      ...map(selectedFacilitators, (value: string) => ({
        id: `owner-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'facilitator',
      })),
      ...map(selectedAttendees, (value: string) => ({
        id: `attendee-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'attendee',
      })),
      ...(startDate || endDate
        ? [
            {
              id: 'start-time-{startDate}-{endDate}',
              value: null,
              label: dateMessage,
              icon: 'calendar',
              filterName: 'start_time',
            },
          ]
        : []),
      ...map(selectedLocations, (value: string) => ({
        id: `location-${value}`,
        value: value,
        label: get(allLocations, value, { name: value }).name,
        icon: 'location',
        filterName: 'location',
      })),
      ...map(selectedCategories, (value: string) => ({
        id: `category-${value}`,
        value: value,
        label: get(allTags, value, { name: value }).name,
        icon: 'hashtag',
        filterName: 'category',
      })),
      ...concat(
        [],
        ...map(otherFilters, (filterName: string) =>
          map(rqlToInput(get(filters, filterName)), (value: string) => ({
            id: `${filterName}-${value}`,
            value: value,
            label: get(allTags, value, { name: value }).name,
            icon: 'tag',
            filterName,
          }))
        )
      ),
    ],
  };
};

export default useMyUpcomingPillsDefinition;
