import { handleActions } from 'redux-actions';

import { STATUS_LOADING, STATUS_DONE, STATUS_ERROR } from 'shared/constants';

import actions from './actions';

const defaultState = {
  enrollments: {},
  events: {},
  users: {},

  eventsResult: [],
  eventsRequestResponse: null,
  eventsRequestStatus: STATUS_LOADING,

  enrollmentsResult: [],
  enrollmentsRequestResponse: null,
  enrollmentsRequestStatus: STATUS_LOADING,

  eventTypesResult: [],
  eventTypesRequestResponse: null,
  eventTypesRequestStatus: STATUS_LOADING,
};

export default handleActions(
  {
    [actions.eventsFetchRequestSubmit]: (state) => ({
      ...state,
      eventsRequestStatus: STATUS_LOADING,
    }),
    [actions.eventsFetchRequestSuccess]: (state, action) => ({
      ...state,
      eventsResult: action.payload.results,
      eventsRequestResponse: action.payload.response,
      eventsRequestStatus: STATUS_DONE,
    }),
    [actions.eventsFetchRequestFailure]: (state, action) => ({
      ...state,
      eventsRequestResponse: action.payload,
      eventsRequestStatus: STATUS_ERROR,
    }),

    [actions.enrollmentsFetchRequestSubmit]: (state) => ({
      ...state,
      enrollmentsRequestStatus: STATUS_LOADING,
    }),
    [actions.enrollmentsFetchRequestSuccess]: (state, action) => ({
      ...state,
      enrollmentsResult: action.payload.results,
      enrollmentsRequestResponse: action.payload.response,
      enrollmentsRequestStatus: STATUS_DONE,
    }),
    [actions.enrollmentsFetchRequestFailure]: (state, action) => ({
      ...state,
      enrollmentsRequestResponse: action.payload,
      enrollmentsRequestStatus: STATUS_ERROR,
    }),

    [actions.eventTypesFetchRequestSubmit]: (state) => ({
      ...state,
      eventTypesRequestStatus: STATUS_LOADING,
    }),
    [actions.eventTypesFetchRequestSuccess]: (state, action) => ({
      ...state,
      eventTypesResult: action.payload.results,
      eventTypesRequestResponse: action.payload.response,
      eventTypesRequestStatus: STATUS_DONE,
    }),
    [actions.eventTypesFetchRequestFailure]: (state, action) => ({
      ...state,
      eventTypesRequestResponse: action.payload,
      eventTypesRequestStatus: STATUS_ERROR,
    }),
  },
  defaultState
);
