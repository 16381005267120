import { useCallback, useEffect } from 'react';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { Assignment } from 'shared-content-item/interfaces';
import { STATUS_DONE, STATUS_NOT_REQUESTED } from 'shared/constants';
import { get } from 'vendor/lodash';

import AssignmentStateChip from '../AssignmntStateChip';

export interface ExemptedProps {
  assignment: Assignment;
}

export const Exempted = ({ assignment }: ExemptedProps) => {
  const { id, state, exemption } = assignment;
  const isExemptionComplete = get(exemption, 'exempted_by.name') && get(exemption, 'exempted_at');
  const [fetchExemption, { data: fetchedExemption, status }] = useEntities(
    actions.assignment.exemptionRetrieve,
    null,
    {}
  );

  const loadExemption = useCallback(() => {
    if (
      state === ASSIGNMENT_STATES.exempted &&
      status === STATUS_NOT_REQUESTED &&
      !isExemptionComplete // Only fetch the exemption if the exemption does not exist in the current assignment object
    ) {
      fetchExemption(id, { view_mode: 'full' });
    }
  }, [fetchExemption, status, state, isExemptionComplete, id]);

  useEffect(() => {
    loadExemption();
  }, [loadExemption]);

  if (isExemptionComplete) return <AssignmentStateChip assignment={assignment} />;
  if (state !== ASSIGNMENT_STATES.exempted) return null;
  if (status !== STATUS_DONE) return null;

  return <AssignmentStateChip assignment={{ ...assignment, exemption: fetchedExemption }} />;
};

export default Exempted;
