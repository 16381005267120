import PropTypes from 'prop-types';
import React from 'react';

import RadioInput from 'inputs/components/RadioInput';

const RadioField = ({ input, disabled, label, optionValue, noMargin, ...rest }) => (
  <RadioInput
    name={input.name}
    inputValue={input.value}
    onChange={input.onChange}
    disabled={disabled}
    label={label}
    value={optionValue}
    noMargin={noMargin}
    {...rest}
  />
);

RadioField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default RadioField;
