import PropTypes from 'prop-types';
import React from 'react';

import DisabledButton from 'event-shared/components/AttendanceActionManager/DisabledButton';
import {
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_UNANSWERED,
} from 'event-shared/constants';
import {
  allowEnrollment,
  allowCancellation,
  allowSelfCheckin,
  hasPassed,
} from 'event-shared/services';
import { EVENT_PERMISSIONS } from 'event/constants';
import * as permissions from 'shared/permissions';
import { constant, includes, get, isEmpty, noop } from 'vendor/lodash';

import EnrollmentActionButton from './EnrollmentActionButton';

export class AttendanceActionElement extends React.Component {
  handleEnrollmentButtonClick = (newStatus) => {
    const { onEnrollmentStatusChange } = this.props;

    onEnrollmentStatusChange(newStatus);
  };

  handleCheckinButtonClick = () => {
    this.handleEnrollmentButtonClick(ENROLLMENT_STATUS_ATTENDED);
  };

  handleUnenrollButtonClick = () => {
    this.handleEnrollmentButtonClick(ENROLLMENT_STATUS_NOT_GOING);
  };

  render() {
    const {
      event,

      renderRating,
      renderDisabledStatusChange,
      renderCheckin,
      renderHasAttended,
      renderEnrollmentClosed,
      renderEventCancelled,

      renderWaitlist,
      renderEnrolled,
      renderProspect,
      renderUncancellableEnroll,
      renderFullCapacity,

      onEnrollmentStatusChange,
    } = this.props;

    const enrollmentStatus = get(event, 'enrollment.status', ENROLLMENT_STATUS_UNANSWERED);
    const enrollmentPreStatus = get(event, 'enrollment.pre_status', ENROLLMENT_STATUS_UNANSWERED);

    const userIsNotGoing = includes(
      [ENROLLMENT_STATUS_UNANSWERED, ENROLLMENT_STATUS_NOT_GOING],
      enrollmentStatus
    );
    const userHasEnrolledStatus = !userIsNotGoing;
    const userIsGoing = includes(
      [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE],
      enrollmentStatus
    );
    const userHasAttended = enrollmentStatus === ENROLLMENT_STATUS_ATTENDED;

    const userWentOnline = enrollmentPreStatus === ENROLLMENT_STATUS_GOING_ONLINE;

    const userCanCheckin = allowSelfCheckin(event) && userIsGoing;
    const userCanNotCheckin = !allowSelfCheckin(event);
    const userCanCancel = allowCancellation(event) && userIsGoing;

    const eventAllowsEnrollment = allowEnrollment(event);
    const eventHasPassed = hasPassed(event);

    const shouldShowRatingArea =
      eventHasPassed &&
      (userHasAttended ||
        (!eventAllowsEnrollment && ((userCanNotCheckin && !userCanCancel) || userIsNotGoing)));

    const hasGroupAccessPermission = includes(
      event.permissions,
      permissions.GROUP_ACCESS_PERMISSION
    );
    const canChangeStatus =
      hasGroupAccessPermission ||
      userHasEnrolledStatus ||
      includes(get(event, 'permissions'), EVENT_PERMISSIONS.access);

    // The event has passed and the user is not going or the user can't check in and can't cancel
    if (shouldShowRatingArea) {
      return renderRating();
    }

    if (!canChangeStatus) {
      return renderDisabledStatusChange();
    }

    if (userCanCheckin || (userHasAttended && !eventHasPassed && userWentOnline)) {
      return renderCheckin({
        event,
        onEnrollmentStatusChange,
        onCheckinClick: this.handleCheckinButtonClick,
        onUnenrollClick: this.handleUnenrollButtonClick,
      });
    }

    // TODO Migrate this to architecture, made in a hotfix
    if (userHasAttended) {
      if (renderHasAttended) return renderHasAttended();
      return <DisabledButton mainText="You are checked in!" icon="check" />;
    }

    // User is able to Enroll/Unenroll (externally or not) and Join/Drop Waitlist,
    // seeing the cutoff/remaining spots messages when applicable
    if (eventAllowsEnrollment || (!userIsNotGoing && userCanCancel)) {
      if (isEmpty(event)) return null;

      return (
        <EnrollmentActionButton
          event={event}
          enrollmentStatus={enrollmentStatus}
          onEnrollmentStatusChange={this.handleEnrollmentButtonClick}
          allowCancellation={userCanCancel}
          renderProspect={renderProspect}
          renderWaitlist={renderWaitlist}
          renderEnrolled={renderEnrolled}
          renderEventCancelled={renderEventCancelled}
          renderUncancellableEnroll={renderUncancellableEnroll}
          renderFullCapacity={renderFullCapacity}
        />
      );
    }

    return renderEnrollmentClosed();
  }
}

AttendanceActionElement.defaultProps = {
  renderRating: constant(null),
  renderDisabledStatusChange: constant(null),
  renderCheckin: constant(null),
  renderEnrollmentClosed: constant(null),
  renderEventCancelled: constant(null),
  onEnrollmentStatusChange: noop,
};

AttendanceActionElement.propTypes = {
  event: PropTypes.object,
  onEnrollmentStatusChange: PropTypes.func,

  renderWaitlist: PropTypes.func,
  renderEnrolled: PropTypes.func,
  renderProspect: PropTypes.func,
  renderUncancellableEnroll: PropTypes.func,
  renderFullCapacity: PropTypes.func,

  renderRating: PropTypes.func,
  renderDisabledStatusChange: PropTypes.func,
  renderCheckin: PropTypes.func,
  renderHasAttended: PropTypes.func,
  renderEnrollmentClosed: PropTypes.func,
  renderEventCancelled: PropTypes.func,
};

export default AttendanceActionElement;
