import { Chip, Tooltip } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';

const TooltipChip = (props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [title, setTitle] = useState('');
  const textElementRef = useRef();
  useEffect(() => {
    // The tag text is inside the span, the Chip element only render one span
    const span = textElementRef.current.querySelector('span');
    setIsOverflow(span.scrollWidth > span.clientWidth);
    setTitle(span.textContent);
  }, []);
  return (
    <Tooltip title={title} arrow disableHoverListener={!isOverflowed}>
      <Chip ref={textElementRef} {...props} />
    </Tooltip>
  );
};

export default TooltipChip;
