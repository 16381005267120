import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import LazyReadOnlyTextEditor from 'inputs/components/LazyReadOnlyTextEditor';
import { BlockWrapper } from 'inputs/components/LazyTextEditor/renderers/Wrapped';
import colors from 'services/colors';
import ReactMarkdown from 'vendor/react-markdown';

const TextEditorDescriptionContainer = styled.div`
  // Necessary to override the default styling of the react-draft-wysiwyg editor
  .public-DraftEditor-content {
    overflow-wrap: anywhere !important;
  }

  ${({ noPadding }) => (noPadding ? 'padding: 0' : 'padding: 0 20px')};

  ${BlockWrapper} {
    margin: 0;

    h1 {
      font-size: 28px;
    }

    h2 {
      color: ${colors.neutral600};
      font-size: 24px;
      font-weight: normal;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      color: ${colors.neutral600};
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      color: ${colors.neutral600};
      font-size: 12px;
    }

    a {
      color: ${colors.emphasis600};
      text-decoration: underline;
    }

    ul,
    ol {
      color: ${colors.neutral600};
      li > div {
        margin: 5px 0;
      }
    }
  }

  .rdw-image-alignment {
    justify-content: center;
  }

  blockquote {
    color: ${colors.neutral400};
    padding: 4px 0 4px 20px;
    font-style: italic;
    margin: 20px 0;
    border-left: 0px;
    position: relative;

    ::before {
      content: ' ';
      position: absolute;
      top: 0;
      background-color: ${colors.neutral200};
      height: 100%;
      width: 4px;
      border-radius: 4px;
    }

    div {
      margin: 0;
      margin-left: 24px;
    }
  }

  pre {
    color: ${colors.neutral600};
    font-size: 14px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentDescription = ({ description, noPadding }) => {
  try {
    return (
      <TextEditorDescriptionContainer noPadding={noPadding}>
        <LazyReadOnlyTextEditor initialValue={description} />
      </TextEditorDescriptionContainer>
    );
  } catch (err) {
    return (
      <DescriptionContainer>
        <ReactMarkdown>{description}</ReactMarkdown>
      </DescriptionContainer>
    );
  }
};

ContentDescription.propTypes = {
  description: PropTypes.string,
  noPadding: PropTypes.bool,
};
export default ContentDescription;
