import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import OldFieldError from 'inputs/components/OldFieldError';
import TimeInput, { INVALID_TIME_MESSAGE } from 'inputs/components/TimeInput';
import { TIME_FORMAT } from 'shared/constants';
import { every, isEmpty, isNil } from 'vendor/lodash';

const Wrapper = styled.div`
  flex-grow: 1;
`;

const TimeField = ({ input, meta, label }) => {
  const { value } = input;

  const [initialValue, setInitialValue] = useState(value);

  const [time, setTime] = useState(initialValue.clone());
  const [timeTouched, setTimeTouched] = useState(false);
  const [timeError, setTimeError] = useState(undefined);

  const error = (timeTouched && timeError) || meta.error;

  const handleTimeChange = (value) => {
    const momentTime = moment(value, TIME_FORMAT, true);
    const isValid = momentTime.isValid();

    setTime(isValid ? momentTime : null);
    setTimeTouched(true);

    if (isEmpty(value)) {
      setTimeError('This field is required.');
      return;
    }

    setTimeError(isValid ? null : INVALID_TIME_MESSAGE);
  };

  // Changes from value update component internals
  useEffect(() => {
    const momentValue = moment(value).utcOffset(0);

    if (!value) {
      return;
    }

    if (momentValue.hour() === time.hour() && momentValue.minute() === time.minute()) {
      return;
    }

    setInitialValue(momentValue);
    setTime(momentValue.clone());
  }, [value]);

  // Changes from component internals update value
  useEffect(() => {
    const { onChange, onBlur } = input;

    // Check timeError to avoid submitting when the time is not null but has an invalid format
    if (!every([time, isNil(timeError)])) {
      onBlur();
      onChange(null);
      return;
    }

    const momentValue = moment(value).utcOffset(0);
    if (momentValue.hour() === time.hour() && momentValue.minute() === time.minute()) {
      return;
    }

    const datetime = initialValue.clone().hour(time.hour()).minute(time.minute());
    onChange(datetime.toISOString());
    onBlur();
  }, [time, timeError]);

  return (
    <Wrapper>
      <TimeInput
        inputId="time"
        label={label}
        currentValue={time}
        searchPlaceholder="Select"
        handleTimeChange={handleTimeChange}
        error={error}
      />
      {error && <OldFieldError>{error}</OldFieldError>}
    </Wrapper>
  );
};

TimeField.defaultProps = {
  label: 'Time*',
};

TimeField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
};

export default TimeField;
