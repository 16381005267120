import React from 'react';

import { map } from 'vendor/lodash';

import { FormSectionContainer } from '../Form';

export const renderSectionsList = (sectionsList) => {
  return map(sectionsList, ({ section, label, description, id, sectionProps }) => {
    if (sectionProps?.hasFormSectionContainer) {
      return section;
    }

    return (
      <FormSectionContainer
        {...sectionProps}
        key={id}
        id={id}
        title={label}
        description={description}
      >
        {section}
      </FormSectionContainer>
    );
  });
};
