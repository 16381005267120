import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import CodelabFormModal from 'codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import { previewCodelabRequest } from 'codelab/services';
import { ContentItemContextMenu } from 'content-items/components/interfaces';
import actions from 'entities/actions';
import { useArchiveRestore } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import ContentSurveyModal from 'shared/components/ContentSurveyModal';
import DropDownMenu from 'shared/components/DropDownMenu';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { get, includes } from 'vendor/lodash';

const CodelabContextMenu = (props: ContentItemContextMenu) => {
  const { content, refreshContent } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showCodelabFormModal, setShowCodelabFormModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const history = useHistory();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const queryClient = useQueryClient();

  const handleRefreshContent = () => {
    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(content.public_id).queryKey,
    });

    refreshContent?.();
  };

  const { archiveItem, restoreItem } = useArchiveRestore(actions.codelab, handleRefreshContent);

  const {
    id,
    public_id_and_slug: publicIdAndSlug,
    content_type: contentType,
    permissions,
  } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  const handlePreviewCodelabClick = async () => {
    await previewCodelabRequest(id);
  };

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        {contentType === CONTENT_TYPES.codelab && (
          <>
            <DropDownMenu.Item
              route={contentTypeRoutes[CONTENT_TYPES.codelab].edit({
                public_id_and_slug: publicIdAndSlug,
              })}
              title="Edit"
              icon="pencil"
            />

            <DropDownMenu.Item
              onClick={() => setShowCodelabFormModal(true)}
              title="Import new version"
              icon="refresh"
            />

            <DropDownMenu.Item onClick={handlePreviewCodelabClick} title="Preview" icon="view" />

            {canAssign && (
              <DropDownMenu.Item
                route={mapRoute('contentItemManageAssignments', {
                  public_id_and_slug: publicIdAndSlug,
                })}
                title="Manage Assignments"
                icon="group"
              />
            )}
            <DropDownMenu.Item
              onClick={() => setShowSurveyModal(true)}
              title="Manage Surveys"
              icon="survey"
            />
            {!isArchived && (
              <DropDownMenu.Item onClick={() => archiveItem(id)} title="Archive" icon="archive" />
            )}
            {isArchived && (
              <DropDownMenu.Item onClick={() => restoreItem(id)} title="Restore" icon="unarchive" />
            )}
            {isArchived && (
              <DropDownMenu.Item
                onClick={() => setShowDeleteModal(true)}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            )}
            {canAssign && (
              <DropDownMenu.Item
                onClick={() => setShowLogModal(true)}
                title="View Logs"
                icon="log"
              />
            )}
          </>
        )}
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showCodelabFormModal && (
        <CodelabFormModal
          handleClose={() => setShowCodelabFormModal(false)}
          codelabId={id}
          handleUploadCompleted={(publicIdAndSlug: string) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new codelab version.'
            );
            history.push(
              mapRoute('standAloneCodelabDetail', {
                public_id_and_slug: publicIdAndSlug,
              })
            );
            setShowCodelabFormModal(false);
          }}
        />
      )}

      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default CodelabContextMenu;
