import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { EDIT_EVENT_PERMISSION } from 'app/shared/permissions';
import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from 'shared/constants';
import { useOriginRoute, useSetDetailedObject, usePublicIdFromURL } from 'shared/hooks';
import { includes } from 'vendor/lodash';

import EventFormPage from './EventFormPage';

const EventEdit = () => {
  const { public_id_and_slug: publicIdAndSlug } = useParams();
  const { publicId } = usePublicIdFromURL();
  const [userCanSee, setUserCanSee] = useState(false);

  const eventsListRoute = mapRoute('eventsList');
  const eventDetailRoute = mapRoute('eventDetails', { public_id_and_slug: publicIdAndSlug });
  const backRoute = useOriginRoute(eventDetailRoute);

  const [fetchEvent, { data: event, status }] = useEntities(
    actions.event.retrieveDetails,
    ({ data: event, status }) => {
      if (status === STATUS_DONE) {
        if (includes(event.permissions, EDIT_EVENT_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: eventSchema,
    }
  );

  useEffect(() => {
    fetchEvent(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'events', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { event };

  return (
    <EventFormPage
      pageTitle="Edit Event"
      topBarActionName="Editing Event"
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: 'Events',
          link: eventsListRoute,
        },
        {
          label: event.name,
          link: eventDetailRoute,
        },
        {
          label: 'Edit event',
        },
      ]}
    />
  );
};

export default EventEdit;
