import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import RadioField from 'inputs/components/RadioField';
import TextInput from 'inputs/components/TextInput';
import { map, get } from 'vendor/lodash';

const SingleSelectionOtherContainer = styled.div`
  margin-top: 16px;

  > * + * {
    margin-top: 20px;
  }
`;

const OtherInput = styled(TextInput)`
  max-width: 320px;
`;

const OtherOptionContainer = styled.div`
  display: flex;
  margin-top: 14px;

  ${OtherInput} {
    ${({ checked }) => !checked && 'visibility: hidden'};
    margin-left: 16px;
  }
`;

const SurveyQuestionSingleSelectionOther = ({ input, question }) => {
  const { options } = question;

  const otherValue = 'other';

  const [otherChecked, setOtherChecked] = useState(false);
  const [other, setOther] = useState({
    isOther: true,
    value: '',
  });
  const [selected, setSelected] = useState({
    isOther: false,
    value: '',
  });

  useEffect(() => {
    const newValue = otherChecked ? other : selected;

    input.onChange(newValue);
  }, [selected, other, otherChecked]);

  const handleInputChange = (option) => {
    setSelected({ ...selected, value: option });
    setOtherChecked(false);
  };

  const handleOtherCheckboxChange = () => {
    setOtherChecked(true);
    setOther({ ...other, value: '' });
    setSelected({ ...selected, value: '' });
  };

  return (
    <SingleSelectionOtherContainer onBlur={() => input.onBlur()}>
      {map(options, (option) => (
        <RadioField
          key={option}
          optionValue={option}
          label={option}
          input={{
            ...input,
            value: get(selected, 'value', ''),
            onChange: () => handleInputChange(option),
          }}
        />
      ))}
      <OtherOptionContainer checked={otherChecked}>
        <RadioField
          label="Other"
          optionValue={otherValue}
          input={{
            ...input,
            value: otherChecked ? otherValue : '',
            onChange: handleOtherCheckboxChange,
          }}
        />
        <OtherInput
          value={get(other, 'value', '')}
          onChange={(e) => setOther({ ...other, value: e.target.value })}
        />
      </OtherOptionContainer>
    </SingleSelectionOtherContainer>
  );
};

SurveyQuestionSingleSelectionOther.propTypes = {
  input: PropTypes.object,
  question: PropTypes.object,
};

export default SurveyQuestionSingleSelectionOther;
