import { useGetContentTypeLabel } from 'catalog/hooks';
import { Event } from 'event/interfaces';
import { displayDatetimeRange } from 'services/datetime';
import { useCurrentUser } from 'shared/hooks';
import { isNil } from 'vendor/lodash';

export const useGetEventLabelWithDatetimeRange = (event: Event): string => {
  const { timezone: userTimezone } = useCurrentUser();
  const getContentTypeLabel = useGetContentTypeLabel();
  const contentLabel = getContentTypeLabel(event.content_type);
  const {
    starts_at_tz_aware: startsAt,
    ends_at_tz_aware: endsAt,
    timezone,
    is_online: isOnline,
  } = event;

  if (isNil(startsAt) || isNil(endsAt)) return contentLabel;

  const datetimeRange = displayDatetimeRange(
    startsAt,
    endsAt,
    timezone,
    userTimezone,
    true,
    isOnline
  );
  return `${contentLabel} • ${datetimeRange}`;
};
