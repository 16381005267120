import ReactPlayer from 'react-player';

import { isHttpsUrl } from 'services/utils';
import { replace, trim, lastIndexOf, split, includes, isEmpty, isNil, size } from 'vendor/lodash';

export function getYoutubeVideoId(url) {
  const regEx =
    '^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?.com|youtu.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)';
  const matches = url.match(regEx);
  if (!matches) return null;
  return matches[1];
}

export function isGoogleSlides(url) {
  const publicRegEx = '^(?:https?:)?//docs.google.com/presentation/d/e/';
  const privateRegEx = '^(?:https?:)?//docs.google.com/a/.+/presentation/d/e/';
  const matches = url.match(publicRegEx);
  const privateMatches = url.match(privateRegEx);
  if (!matches && !privateMatches) return false;
  return true;
}

export function isGoogleDocs(url) {
  const publicRegEx = '^(?:https?:)?//docs.google.com/document/d/e/';
  const privateRegEx = '^(?:https?:)?//docs.google.com/a/.+/document/d/e/';
  const matches = url.match(publicRegEx);
  const privateMatches = url.match(privateRegEx);
  if (!matches && !privateMatches) return false;
  return true;
}

export function isConfluenceLink(url) {
  // https://test.atlassian.net/wiki/spaces/MA/pages/163937/How-to+article
  const publicRegEx = '^(?:https?:)?//([A-Za-z0-9]+).atlassian.net/';
  const matches = url.match(publicRegEx);
  if (!matches) return false;
  return true;
}

export function isGoogleSheets(url) {
  const publicRegEx = '^(?:https?:)?//docs.google.com/spreadsheets/d/e/';
  const privateRegEx = '^(?:https?:)?//docs.google.com/a/.+/spreadsheets/d/e/';
  const matches = url.match(publicRegEx);
  const privateMatches = url.match(privateRegEx);
  if (!matches && !privateMatches) return false;
  return true;
}

// Could be an image or a video
export function isGoogleFile(url) {
  if (!url) return false;
  const publicRegEx = '^(?:https?:)?//drive.google.com/file/d/';
  const matches = url.match(publicRegEx);
  if (!matches) return false;
  return true;
}

export function isImage(url) {
  if (isEmpty(url) || isNil(url)) return false;
  const publicRegEx = '^(http)?s?:?(//[^"\']*.(?:png|jpg|jpeg|gif|svg))';
  const matches = url.match(publicRegEx) || includes(url, window.MEDIA_FILE_HOST);
  if (!matches) return false;
  return true;
}

export function isHtml(content) {
  return /<\/?[a-z][\s\S]*>/i.test(content);
}

export function createHtml(content) {
  if (isHtml(content)) {
    const root = document.createElement('html');
    root.innerHTML = content;
    return root;
  }

  return null;
}

export function getIframe(html) {
  const [iframe] = html.getElementsByTagName('iframe');
  return iframe;
}

export function getEmbed(html) {
  const [embed] = html.getElementsByTagName('embed');
  return embed;
}

export function extractEmbedUrl(embedContent) {
  if (isEmpty(embedContent) || isNil(embedContent)) return embedContent;

  if (isHtml(embedContent)) {
    const html = createHtml(embedContent);
    const iframe = getIframe(html);

    if (iframe) {
      return iframe.src;
    }

    const embed = getEmbed(html);

    if (embed) {
      return embed.src;
    }
  }

  return embedContent;
}

export function isPanoptoUrl(url) {
  return !!url && !!url.match('^(?:https?:)?//[^/]*panopto.com/.*(Embed|Viewer).*');
}

export function isDescriptUrl(url) {
  return !!url && !!url.match('^(?:https?:)?//[^/]*descript.com/embed/.*');
}

export function isMicrosoftStreamVideoUrl(url) {
  return !!url && !!url.match('^(?:https?:)?//[^/]*.microsoftstream.com/embed/video/.*');
}

export function isLoomVideoUrl(url) {
  return !!url && !!url.match('^(?:https?:)?//[^/]*.loom.com/embed/.*');
}

export function isAllowedVideoUrl(url, allowedVideoUrlPattern) {
  try {
    return !!url && !!allowedVideoUrlPattern && !!url.match(allowedVideoUrlPattern);
  } catch {
    // invalid regular expression pattern
    return false;
  }
}

export function isEmbeddedVideoUrl(url, allowedVideoUrlPattern = null) {
  return (
    isGoogleFile(url) ||
    isPanoptoUrl(url) ||
    isDescriptUrl(url) ||
    isMicrosoftStreamVideoUrl(url) ||
    isLoomVideoUrl(url) ||
    isAllowedVideoUrl(url, allowedVideoUrlPattern)
  );
}

export const getVideoEmbedLink = (url) => {
  if (!ReactPlayer.canPlay(url)) return null;
  return url;
};

export const getYoutubeEmbedLink = (url) => {
  const id = getYoutubeVideoId(trim(url));
  if (!id) return null;
  return `https://youtube.com/embed/${id}`;
};

export const getGoogleSlidesEmbedLink = (url) => {
  if (!isGoogleSlides(url)) return null;
  return replace(url, '/pub?', '/embed?');
};

export const getGoogleDocsEmbedLink = (url) => {
  if (!isGoogleDocs(url)) return null;
  return `${url}?embedded=true`;
};

export const getConfluencePageProps = (url) => {
  // Valid confluence link must have domain, space key and content id
  const confluencePageRegex =
    /(?:https?:\/\/)?([A-Za-z0-9.]+)\.atlassian\.net\/wiki\/spaces\/([A-Za-z0-9]+)\/(?:pages\/([\d]+)|blog\/[\d]+\/[\d]+\/[\d]+\/([\d]+))/;

  const matches = url.match(confluencePageRegex);

  // If it doesn't match more than 3 groups, it's not a valid URL.
  if (size(matches) < 4) {
    return null;
  }

  const hostName = `${matches[1]}.atlassian.net`;
  const spaceKey = matches[2];
  const contentId = matches[3] || matches[4];

  return { hostName, spaceKey, contentId };
};

export const getGoogleSheetsEmbedLink = (url) => {
  if (!isGoogleSheets(url)) return null;
  const lastSlashIndex = lastIndexOf(url, '/');
  return `${url.slice(0, Math.max(0, lastSlashIndex + 1))}pubhtml`;
};

export const getGoogleImagesEmbedLink = (url) => {
  if (!isGoogleFile(url)) return null;
  const linkId = split(url, '/')[5];
  return `https://drive.google.com/file/d/${linkId}/preview?usp=sharing`;
};

export const getImageEmbedLink = (url) => {
  if (!isImage(url)) return null;
  return url;
};

export const getGenericEmbedLink = (url) => {
  if (!isHttpsUrl(url)) return null;
  return url;
};
