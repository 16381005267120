import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { formatDuration } from 'services/datetime';
import Pill from 'shared/components/Pill';
import { isEmpty, map } from 'vendor/lodash';

const Container = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 280px;
`;

const Tags = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  border-right: solid 16px rgba(0, 0, 0, 0);
  gap: 4px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 70%, white);
    pointer-events: none;
  }
`;

const DurationAndTags = ({ duration, emphasisTags, tags }) => {
  const formattedDuration = !isEmpty(duration) ? formatDuration(duration) : '';
  const extraTags = formattedDuration ? [formattedDuration, ...emphasisTags] : emphasisTags;

  return (
    <Container>
      {!isEmpty(extraTags) && (
        <>
          {map(extraTags, (emphasisTag) => (
            <Pill key={emphasisTag} variant="lightGray" size="small" label={emphasisTag} />
          ))}
        </>
      )}
      {!isEmpty(tags) && (
        <Tags>
          {map(tags, (tag) => (
            <Pill key={tag} variant="lighterGray" size="small" label={tag} />
          ))}
        </Tags>
      )}
    </Container>
  );
};

DurationAndTags.propTypes = {
  duration: PropTypes.string,
  tags: PropTypes.array,
  emphasisTags: PropTypes.array,
};

DurationAndTags.defaultProps = {
  emphasisTags: [],
};

export default DurationAndTags;
