import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DatePickerInput from 'inputs/components/DatePickerInput';
import SelectField from 'inputs/components/SelectField';
import { apiGet } from 'services/requests';
import { getNextInstanceOfGivenWeekday } from 'services/utils';
import Form from 'shared/components/OldForm';
import { RECURRENCE_OPTIONS } from 'shared/constants';
import { every } from 'vendor/lodash';

import OldFieldError from '../OldFieldError';

const LeftFieldContainer = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-right: 15px;
  margin-top: 10px;
`;

const RightFieldContainer = styled(LeftFieldContainer)`
  padding-right: 0px;
  padding-left: 15px;
  margin-top: 10px;
`;

const DatePickerInputContainer = styled.div`
  > div {
    margin: 0px;
  }
`;

export const validateRecurrentDates = Form.validations.required({
  message:
    'Could not calculate recurrent dates. ' +
    'Location, Date, Time, Duration and Repeat Until fields are required.',
});
const RecurrentDatesField = ({
  startsAt,
  baseItemTime,
  locationId,
  duration,
  input,
  itemLabel,
  meta: { touched, error, submitFailed },
  fetchRecurrentDates,
  timezone,
}) => {
  const [recurrenceValue, setRecurrenceValue] = useState(RECURRENCE_OPTIONS[1].value);
  const [repeatUntilValue, setRepeatUntilValue] = useState(null);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(startsAt);
  const [userChangedDate, setUserChangedDate] = useState(false);

  const DAYS_MAPPING = {
    [RECURRENCE_OPTIONS[0].value]: 1,
    [RECURRENCE_OPTIONS[1].value]: 7,
    [RECURRENCE_OPTIONS[2].value]: 14,
    [RECURRENCE_OPTIONS[3].value]: 28,
  };

  useEffect(() => {
    if (userChangedDate) {
      return;
    }

    const now = moment().tz(timezone);

    if (startsAt.isBefore(now)) {
      const startDateOnSameWeek = getNextInstanceOfGivenWeekday(startsAt)
        .tz(timezone)
        .set(baseItemTime);
      setStartDate(startDateOnSameWeek);
      return;
    }

    // We need to clone to avoid changing the original moment object
    setStartDate(startsAt.clone().add(DAYS_MAPPING[recurrenceValue], 'days'));
  }, [recurrenceValue]);

  useEffect(() => {
    if (!every([startsAt, locationId, duration, repeatUntilValue], Boolean)) {
      input.onChange(null);
      return;
    }
    fetchRecurrentDates(recurrenceValue, repeatUntilValue, startDate, locationId, duration).then(
      // eslint-disable-next-line promise/always-return
      (response) => {
        input.onChange(response.data.results);
      }
    );
  }, [startDate, locationId, duration, recurrenceValue, repeatUntilValue]);

  const handleDateChange = (value) => {
    if (!value) {
      setDate(null);
      setRepeatUntilValue(null);
      return;
    }
    setDate(value);
    setRepeatUntilValue(value.toISOString());
  };

  const handleStartDateChange = (value) => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(value.set(baseItemTime));
    setUserChangedDate(true);
  };

  return (
    <>
      <Form.FieldGroup>
        <SelectField
          input={{
            onChange: (value) => setRecurrenceValue(value),
            onFocus: input.onFocus,
            value: recurrenceValue,
            name: 'recurring-sessions',
          }}
          label="Repeat *"
          meta={{}}
          placeholder="Select"
          options={RECURRENCE_OPTIONS}
        />
      </Form.FieldGroup>

      <div>
        <LeftFieldContainer>
          <Form.FieldGroup>
            <DatePickerInputContainer>
              <DatePickerInput
                label={`First ${itemLabel} starts from *`}
                value={startDate}
                onChange={handleStartDateChange}
                maxDate={date}
                InputProps={{
                  id: 'date_starts_at',
                  name: 'date_starts_at',
                }}
                clearable
              />
            </DatePickerInputContainer>
          </Form.FieldGroup>
        </LeftFieldContainer>

        <RightFieldContainer>
          <Form.FieldGroup>
            <DatePickerInputContainer>
              <DatePickerInput
                label="Repeat until *"
                value={date}
                onChange={handleDateChange}
                minDate={startDate}
                InputProps={{
                  id: 'date_repeat_until',
                  name: 'date_repeat_until',
                }}
                clearable
              />
            </DatePickerInputContainer>
          </Form.FieldGroup>
        </RightFieldContainer>
        {error && (submitFailed || touched) && <OldFieldError>{error}</OldFieldError>}
      </div>
    </>
  );
};

RecurrentDatesField.propTypes = {
  startsAt: PropTypes.string,
  baseItemTime: PropTypes.object,
  locationId: PropTypes.number,
  duration: PropTypes.string,
  itemLabel: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  fetchRecurrentDates: PropTypes.func,
  timezone: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  fetchRecurrentDates: (recurrenceValue, repeatUntilValue, startsAt, locationId, duration) =>
    apiGet('api_datetimes:recurrence', null, {
      repeat_until: repeatUntilValue,
      starts_at: startsAt.format(),
      recurrence: recurrenceValue,
      location_id: locationId,
      duration,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurrentDatesField);
