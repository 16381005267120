import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import { ApiURLs, fetchURL } from 'services/requests-base';
import { removePrefixFromId } from 'shared/services';
import {
  map,
  sortBy,
  filter,
  slice,
  isEmpty,
  concat,
  get,
  includes,
  join,
  split,
} from 'vendor/lodash';

import AutocompleteField from '../AutocompleteField';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["formatOptions", "mergedGroupsAndPeopleValues"] }] */
export class PeopleGroupsMultiselectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, options: [] };
  }

  getOnActionsForPeopleAndGroups = () => {
    const { groups_ids: groupsIds, people_ids: peopleIds } = this.props;
    return {
      onBlur: (...params) => {
        if (groupsIds) {
          groupsIds.input.onBlur(...params);
        }
        if (peopleIds) {
          peopleIds.input.onBlur(...params);
        }
      },
      onChange: (...params) => {
        if (groupsIds) {
          const rawList = filter(...params, (item) => includes(item, 'group-'));
          const processedList = removePrefixFromId(rawList, 'group-');
          groupsIds.input.onChange(processedList);
        }
        if (peopleIds) {
          const rawList = filter(...params, (item) => includes(item, 'user-'));
          const processedList = removePrefixFromId(rawList, 'user-');
          peopleIds.input.onChange(processedList);
        }
      },
      onDragStart: (...params) => {
        if (groupsIds) {
          groupsIds.input.onDragStart(...params);
        }
        if (peopleIds) {
          peopleIds.input.onDragStart(...params);
        }
      },
      onDrop: (...params) => {
        if (groupsIds) {
          groupsIds.input.onDrop(...params);
        }
        if (peopleIds) {
          peopleIds.input.onDrop(...params);
        }
      },
      onFocus: (...params) => {
        if (groupsIds) {
          groupsIds.input.onFocus(...params);
        }
        if (peopleIds) {
          peopleIds.input.onFocus(...params);
        }
      },
    };
  };

  getMeta = () => {
    const { groups_ids: groupsIds, people_ids: peopleIds } = this.props;
    return get(groupsIds, 'meta') || get(peopleIds, 'meta');
  };

  formatOptions = (items) => {
    const privateGroups = sortBy(filter(items, ['dataType', 'private-group']), ['label']);
    const publicGroups = sortBy(filter(items, ['dataType', 'public-group']), ['label']);
    const people = sortBy(filter(items, ['dataType', 'user']), ['label']);

    let options = [];
    let groupsLimit = 20;
    let peopleLimit = 20;

    if (
      (!isEmpty(people) && !isEmpty(publicGroups)) ||
      (!isEmpty(publicGroups) && !isEmpty(privateGroups)) ||
      (!isEmpty(people) && !isEmpty(privateGroups))
    ) {
      groupsLimit = 10;
      peopleLimit = 10;
    }

    if (!isEmpty(people) && !isEmpty(publicGroups) && !isEmpty(privateGroups)) {
      groupsLimit = 5;
      peopleLimit = 10;
    }

    if (!isEmpty(privateGroups)) {
      options.push({
        label: 'MY GROUPS',
        value: '------------',
        isDisabled: true,
      });

      options = concat(
        options,
        map(slice(privateGroups, 0, groupsLimit), (group) => ({
          label: `${group.label} (${group.membersCount})`,
          value: group.value,
          dataType: group.dataType,
        }))
      );

      if (!isEmpty(privateGroups) && privateGroups.length - groupsLimit > 0) {
        const groupsVerbose = publicGroups.length - groupsLimit === 1 ? 'group' : 'groups';
        options.push({
          label: `and ${privateGroups.length - groupsLimit} more ${groupsVerbose}`,
          value: '------------',
          isDisabled: true,
        });
      }
    }

    if (!isEmpty(publicGroups)) {
      options.push({
        label: 'PUBLIC GROUPS',
        value: '------------',
        isDisabled: true,
      });

      options = concat(
        options,
        map(slice(publicGroups, 0, groupsLimit), (group) => ({
          label: `${group.label} (${group.membersCount})`,
          value: group.value,
          dataType: group.dataType,
        }))
      );

      if (!isEmpty(publicGroups) && publicGroups.length - groupsLimit > 0) {
        const groupsVerbose = publicGroups.length - groupsLimit === 1 ? 'group' : 'groups';
        options.push({
          label: `and ${publicGroups.length - groupsLimit} more ${groupsVerbose}`,
          value: '------------',
          isDisabled: true,
        });
      }
    }

    if (!isEmpty(people)) {
      options.push({
        label: 'PEOPLE',
        value: '------------',
        isDisabled: true,
      });

      options = concat(
        options,
        map(slice(people, 0, peopleLimit), (person) => ({
          label: person.label,
          value: person.value,
          image: person.image,
          dataType: person.dataType,
        }))
      );

      if (!isEmpty(people) && people.length - peopleLimit > 0) {
        options.push({
          label: `and ${people.length - peopleLimit} more ${
            people.length === 1 ? 'person' : 'people'
          }`,
          value: '------------',
          isDisabled: true,
        });
      }
    }

    return options;
  };

  fetchOptions = ({ q: query, includeValues, selectedOptions }) => {
    const {
      fetchUserOptions,
      fetchPrivateGroupsOptions,
      fetchPublicGroupsOptions,
      showGroups,
      showUsers,
      currentUser,
    } = this.props;
    this.setState({
      loading: true,
    });

    const includeGroups = (isPrivate) => {
      const filteredOptions = isEmpty(selectedOptions)
        ? filter(split(includeValues, ','), (value) => includes(value, 'group'))
        : map(
            filter(
              selectedOptions,
              (option) => includes(option.value, 'group') && option.isPrivate === isPrivate
            ),
            (option) => option.value
          );

      return removePrefixFromId(filteredOptions, 'group-');
    };

    const fetchesList = [];
    if (showGroups) {
      fetchesList.push(fetchPrivateGroupsOptions(query, currentUser, includeGroups(true)));
      fetchesList.push(fetchPublicGroupsOptions(query, includeGroups(false)));
    }
    if (showUsers) {
      fetchesList.push(fetchUserOptions(query));
    }
    const promise = Promise.all(fetchesList).then((responses) => {
      this.setState({
        loading: false,
      });
      let users = [];
      let groups = [];
      if (showUsers) {
        users = map(responses.pop(), (user) => ({
          ...user,
          dataType: 'user',
          group: 'People',
        }));
      }

      if (showGroups) {
        groups = [
          ...map(responses[0], (privateGroup) => ({
            ...privateGroup,
            dataType: 'private-group',
            group: 'Private Groups',
          })),
          ...map(responses[1], (publicGroup) => ({
            ...publicGroup,
            dataType: 'public-group',
            group: 'Public Groups',
          })),
        ];
      }
      this.setState({
        options: [...groups, ...users],
      });
      return [...groups, ...users];
    });
    return promise;
  };

  mergedGroupsAndPeopleValues = (groupsValues, peopleValues) => {
    const groupsList = map(groupsValues, (value) => `group-${value}`);
    const peopleList = map(peopleValues, (value) => `user-${value}`);
    return concat(groupsList, peopleList);
  };

  buildInput = () => {
    const { groups_ids: groupsIds, people_ids: peopleIds, inputId } = this.props;

    const actionsList = this.getOnActionsForPeopleAndGroups();

    return {
      name: inputId,
      value: this.mergedGroupsAndPeopleValues(
        get(groupsIds, 'input.value', []),
        get(peopleIds, 'input.value', [])
      ),
      ...actionsList,
    };
  };

  render() {
    const { showUsers, showGroups, required, label } = this.props;
    const { loading, options } = this.state;
    let placeholder;
    if (showUsers && showGroups) {
      placeholder = 'Search people and groups';
    } else if (showUsers && !showGroups) {
      placeholder = 'Search people';
    } else {
      placeholder = 'Search groups';
    }

    const meta = this.getMeta();

    const labelSuffix = required ? ' *' : '';

    return (
      <AutocompleteField
        label={label + labelSuffix}
        placeholder={placeholder}
        fetchOptions={this.fetchOptions}
        options={options}
        loading={loading}
        input={this.buildInput()}
        meta={meta}
        multiple
        groupBy={(option) => option.group}
      />
    );
  }
}

PeopleGroupsMultiselectField.propTypes = {
  fetchPrivateGroupsOptions: PropTypes.func,
  fetchPublicGroupsOptions: PropTypes.func,
  fetchUserOptions: PropTypes.func,
  showGroups: PropTypes.bool,
  showUsers: PropTypes.bool,
  people_ids: PropTypes.object,
  groups_ids: PropTypes.object,
  currentUser: PropTypes.object,
  inputId: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch, props) => {
  const { people_ids: peopleIds } = props;
  const includeUsersIds = get(peopleIds, 'input.value');
  return {
    fetchPublicGroupsOptions: (query, includeGroups) => {
      let url = `${ApiURLs['api_internalgroups:list_create']()}?visibility=public&q=${query}`;
      const includeGroupStr = join(includeGroups, ',');

      if (includeGroupStr) {
        url = `${url}&include_ids=${includeGroupStr}`;
      }

      return fetchURL(url).then((response) =>
        filter(
          map(response.data?.results, (i) => ({
            value: `group-${i.id}`,
            icon: 'world',
            label: `${i.name} (${i.members_count})`,
            membersCount: i.members_count,
            isPrivate: i.is_private,
            owner: i.owner,
          })),
          // Workaround when `includeGroups` don't distinguish between public and private groups
          (option) => !option.isPrivate
        )
      );
    },
    fetchPrivateGroupsOptions: (query, currentUser, includeGroups) => {
      let url = `${ApiURLs['api_internalgroups:list_create']()}?visibility=private&q=${query}`;
      const includeGroupStr = join(includeGroups, ',');

      if (includeGroupStr) {
        url = `${url}&include_ids=${includeGroupStr}`;
      }

      return fetchURL(url).then((response) =>
        filter(
          map(response.data?.results, (i) => ({
            value: `group-${i.id}`,
            icon: 'lock',
            label: `${i.name} (${i.members_count})`,
            membersCount: i.members_count,
            isPrivate: i.is_private,
            owner: i.owner,
          })),
          // Workaround when `includeGroups` don't distinguish between public and private groups
          (option) => option.isPrivate
        )
      );
    },
    fetchUserOptions: (query) => {
      const includeIds = includeUsersIds ? join(includeUsersIds, ',') : undefined;

      const queryStringData = {
        q: query || undefined,
        include_ids: includeIds,
        page_size: 15,
      };

      let url = `${ApiURLs['api_users:users']()}?`;
      url += queryString.stringify(queryStringData);

      return fetchURL(url).then((response) =>
        map(response.data.results, (i) => ({
          value: `user-${i.id}`,
          label: i.name || i.email,
          image: i.profile_image,
        }))
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleGroupsMultiselectField);
