import { useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { map } from 'vendor/lodash';

import AssignDueDateModal, { AssignDueDatePayload } from '../AssignDueDateModal/AssignDueDateModal';

import ContentItemPickerModal from './ContentItemPickerModal';

export interface AssignContentModalProps {
  assignee: {
    id: number;
    name: string;
  };
  handleClose: () => void;
}

const AssignContentModal = ({ assignee, handleClose }: AssignContentModalProps) => {
  const { trackActivity } = useMetrics();
  const [activeStep, setActiveStep] = useState(0);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [assignmentFormData, setAssignmentFormData] = useState<AssignDueDatePayload>({
    assignmentType: 'prescribed',
    dueDate: null,
  });

  const [bulkAssign, { status }] = useEntities(
    actions.contentAssignment.bulkAssign,
    ({ status }) => {
      if (status === STATUS_DONE) {
        toast.success(
          `Creating ${selectedItems.length} assignments to ${assignee.name}. This may take a few moments.`
        );
        handleClose();
      }
    }
  );

  const updateContentsToAssignList = (newValue) => {
    setSelectedItems(newValue);
    setActiveStep(1);
  };

  const handleSubmit = (newAssignmentFormData) => {
    setAssignmentFormData(newAssignmentFormData);

    const contentIds = map(selectedItems, (entry) => entry.content_item.id);
    bulkAssign({
      content_ids: contentIds,
      select_users_ids: [assignee.id],
      due_date: newAssignmentFormData.dueDate,
    });

    trackActivity(METRICS_ACTIVITIES.MANAGER_ASSIGN_CONTENT, {
      contentIds,
      assignmentType: newAssignmentFormData.assignmentType,
      dueDate: newAssignmentFormData.dueDate,
    });
  };

  return (
    <>
      {activeStep === 0 && (
        <ContentItemPickerModal
          onAdd={updateContentsToAssignList}
          assignee={assignee}
          handleClose={handleClose}
          initialSelectedItems={selectedItems}
        />
      )}
      {activeStep === 1 && (
        <AssignDueDateModal
          title={`Assigning to ${assignee.name}`}
          handleClose={handleClose}
          handleBack={() => setActiveStep(0)}
          onSubmit={handleSubmit}
          initialValues={assignmentFormData}
          loading={status === STATUS_LOADING}
        />
      )}
    </>
  );
};

export default AssignContentModal;
