import PropTypes from 'prop-types';
import React from 'react';

import TextInput from 'inputs/components/TextInput';

const SettingTokenField = ({ input, type, ariaLabel }) => {
  return (
    <TextInput
      value={input.value.value}
      onChange={(e) => input.onChange({ ...input.value, value: e.target.value, inherited: false })}
      type={type}
      disabled="disabled"
      aria-label={ariaLabel}
      fullWidth
    />
  );
};

SettingTokenField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default SettingTokenField;
