import { useState } from 'react';

import { TrackItem, trackTypes } from 'app/tracks/interfaces';
import { useRouterQueryUtils } from 'shared/hooks';
import TrackItemCard from 'tracks/components/TrackItemCard';
import { chunk, isEmpty, map, size } from 'vendor/lodash';
import { Box, Button, Stack } from 'vendor/mui';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from 'vendor/mui-icons';

interface TrackItemCardListProps {
  items?: TrackItem[];
  getTrackItemIndex: (trackId: string, contentItemId: string) => number;
  isSummary?: boolean;
  pageSize?: number;
  trackType?: trackTypes;
}

export const TrackItemCardList = ({
  items,
  getTrackItemIndex,
  isSummary = false,
  pageSize = 9,
  trackType = 'track',
}: TrackItemCardListProps) => {
  const { addToQueryString } = useRouterQueryUtils();

  const buttonSx = {
    boxShadow: '0px 4px 15px 0px #0000001A',
    borderRadius: '100px',
    paddingY: 1,
    paddingX: 2,
    marginX: 1,
  };
  const paginatedItems = chunk(items, pageSize);

  const [currentPage, setCurrentPage] = useState(0);
  const [loadedItems, setLoadedTrackItems] = useState(paginatedItems[currentPage]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(size(items) > pageSize);
  const [showLoadLessButton, setShowLoadLessButton] = useState(false);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const nextItems = paginatedItems[nextPage];

    if (!isEmpty(nextItems)) {
      setLoadedTrackItems([...loadedItems, ...nextItems]);
    }

    setCurrentPage(nextPage);
    setShowLoadMoreButton(nextPage < size(paginatedItems) - 1);
    setShowLoadLessButton(nextPage > 0);
  };

  const handleLoadLess = () => {
    const firstPage = 0;

    setLoadedTrackItems(paginatedItems[firstPage]);
    setCurrentPage(firstPage);
    setShowLoadMoreButton(true);
    setShowLoadLessButton(false);
  };

  return (
    <Stack spacing={2}>
      {map(loadedItems, (item) => {
        const index = getTrackItemIndex(item.track_id!, item.content_item.public_id);

        return (
          <TrackItemCard
            key={item.content_item.public_id}
            item={item}
            onClick={() => addToQueryString({ page: index + 1 })}
            isSummary={isSummary}
            trackType={trackType}
          />
        );
      })}
      <Box textAlign="center">
        {showLoadLessButton && (
          <Button endIcon={<KeyboardArrowUpIcon />} onClick={handleLoadLess} sx={buttonSx}>
            Show less
          </Button>
        )}
        {showLoadMoreButton && (
          <Button endIcon={<KeyboardArrowDownIcon />} onClick={handleLoadMore} sx={buttonSx}>
            Show more
          </Button>
        )}
      </Box>
    </Stack>
  );
};
