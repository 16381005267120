import { includes, filter, isString, forEach, reduce, get } from 'vendor/lodash';

export function removeLinks(contentString) {
  if (!contentString || !isString(contentString) || !includes(contentString, 'atomic')) {
    return contentString; // Return early!
  }

  try {
    const content = JSON.parse(contentString);
    const { blocks, entityMap } = content;

    // Filter out any blocks that are of type 'atomic' (usually used for media like videos)
    const filteredBlocks = filter(blocks, (block) => block.type !== 'atomic');

    // Create a set of entity keys to keep
    const keptEntityKeys = new Set();
    forEach(filteredBlocks, (block) => {
      forEach(block.entityRanges, (range) => keptEntityKeys.add(range.key.toString()));
    });

    const filteredEntityMap = reduce(
      Object.entries(entityMap),
      (acc, entity, key) => {
        const entityInfo = entity[1];
        const entityType = get(entityInfo, 'type');

        if (keptEntityKeys.has(key) && entityType !== 'LINK') {
          acc[key] = entity;
        }

        return acc;
      },
      {}
    );

    const newContent = {
      ...content,
      blocks: filteredBlocks,
      entityMap: filteredEntityMap,
    };

    // We need to use stringify because the ContentDescription component expects a string
    return JSON.stringify(newContent);
  } catch (error) {
    return contentString;
  }
}
