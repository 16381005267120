import { ISO_UNIT_OPTIONS } from 'inputs/constants';
import { constant, get, find, includes } from 'vendor/lodash';

import { DATE_RANGE_EXACT_MODE, DATE_RANGE_RELATIVE_MODE } from './constants';

export const getDateString = (mode, date) => {
  if (!date) return '';
  if (mode === DATE_RANGE_RELATIVE_MODE) return date;
  if (mode === DATE_RANGE_EXACT_MODE) {
    return date.format('YYYY-MM-DD');
  }

  return '';
};

const extractParamsFromISOString = (isoDateString) => {
  const unitString = includes(isoDateString, 'T') ? 'T' : 'P';
  const offset = isoDateString.slice(isoDateString.indexOf(unitString) + 1, -1);
  const unit = find(
    ISO_UNIT_OPTIONS,
    ({ isoIdentifier }) => isoIdentifier === isoDateString[isoDateString.length - 1]
  )?.label;
  const coefficient = isoDateString[0] === '-' ? 'ago' : 'from now';

  return [offset, unit, coefficient];
};

const formatISODateInputText = (isoDateString) => {
  if (!isoDateString) return '';
  const [offset, unit, coefficient] = extractParamsFromISOString(isoDateString);

  if (offset === '0') return 'now';

  return `${offset} ${unit} ${coefficient}`;
};

const flexibleDateRangeInputFormatters = {
  exact: (startDate, endDate) => {
    const startDateText = startDate && startDate.format('LL');
    const endDateText = endDate && endDate.format('LL');

    if (startDate && endDate) {
      return `${startDateText} - ${endDateText}`;
    }
    if (startDate) {
      return startDateText === 'now' ? 'Upcoming' : `From ${startDateText}`;
    }
    if (endDate) {
      return endDateText === 'now' ? 'Past' : `Until ${endDateText}`;
    }

    return '';
  },
  relative: (startDate, endDate) => {
    const startDateText = formatISODateInputText(startDate);
    const endDateText = formatISODateInputText(endDate);

    if (startDate && endDate) {
      return `${startDateText} - ${endDateText}`;
    }
    if (startDate) {
      return startDateText === 'now' ? 'Upcoming' : `From ${startDateText}`;
    }
    if (endDate) {
      return endDateText === 'now' ? 'Past' : `Until ${endDateText}`;
    }

    return '';
  },
};

export const formatFlexibleDateRangeInputText = (mode) => {
  return get(flexibleDateRangeInputFormatters, mode, () => constant(''));
};
