import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import SessionBulkCopyModal from 'app/program-shared/components/SessionBulkDuplicateModal';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import {
  sessionStartsAtHasPassed,
  userIsAttendee as currentUserIsAttendee,
} from 'program/services';
import colors from 'services/colors';
import confirmAlert from 'services/confirm-alert';
import { mapRoute } from 'services/requests';
import DropDownMenu from 'shared/components/DropDownMenu';
import Text from 'shared/components/Text';
import { useCurrentUser, useEncodedCurrentRoute, useToggles } from 'shared/hooks';
import { EDIT_SESSION_PERMISSION } from 'shared/permissions';
import { includes } from 'vendor/lodash';

export const SessionSettingsContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
`;

const ConfirmationDialogContent = () => {
  return (
    <Text block size="h4" color={colors.neutral900}>
      You&apos;re force syncing this session to calendar. This action will <b>notify users</b>. Are
      you sure you want to force sync this session?
    </Text>
  );
};

const SessionSettings = ({ session, onDeleteSession, onShowRoster, onClickOpenDetails }) => {
  const currentRoute = useEncodedCurrentRoute();
  const [showBulkDuplicateModal, setShowBulkDuplicateModal] = useState(false);

  const currentUser = useCurrentUser();

  const {
    sync_to_google_calendar: syncToGoogleCalendar,
    sync_to_outlook_calendar: syncToOutlookCalendar,
    include_ics_file_in_event_emails: includeIcsFileInEventEmails,
  } = currentUser;

  const { toggle_calendar_force_sync: toggleCalendarForceSync } = useToggles();

  const hasAnySyncToCalendarOn =
    syncToGoogleCalendar || syncToOutlookCalendar || includeIcsFileInEventEmails;

  const meetingUrl = session.meeting_url;
  const [resyncResource] = useEntities(actions.calendar.resyncResourceToCalendar, null);

  const handleResyncResource = () => {
    resyncResource({
      pk: session.id,
      type: 'session',
    });
    toast.success('Force Synced successfully!');
  };

  const editSessionRoute = session.program_id
    ? `${mapRoute('sessionEdit', { public_id: session.public_id })}?origin=${currentRoute}`
    : `${mapRoute('sessionMentorshipEdit', {
        public_id: session.public_id,
      })}?origin=${currentRoute}`;
  const cloneSessionRoute = session.program_id
    ? `${mapRoute('sessionCreate')}?cloned=${session.public_id}&origin=${currentRoute}`
    : `${mapRoute('sessionMentorshipCreate')}?cloned=${session.public_id}&origin=${currentRoute}`;

  const canEditSession = includes(session.permissions, EDIT_SESSION_PERMISSION);
  const userIsAttendee = currentUserIsAttendee(session, currentUser);
  const isPastSession = sessionStartsAtHasPassed(session, currentUser.timezone);

  const showManageAttendees = session.attendance_limit === 0 || session.attendance_limit > 1;
  // Host/Mentor/Admin and Attendee/Mentee for past session
  const showDetails = isPastSession && (canEditSession || userIsAttendee);
  // Host/Mentor and Mentee for past session
  const hasItemsToShow = canEditSession || showDetails;

  if (!hasItemsToShow) return null;

  const forceSyncToCalendar = () => {
    if (syncToOutlookCalendar || includeIcsFileInEventEmails) {
      return confirmAlert({
        title: 'Confirm Calendar Force Sync?',
        content: () => <ConfirmationDialogContent />,
        onConfirm: handleResyncResource,
        confirmLabel: 'Confirm Force Sync',
        isDangerSecondaryAction: true,
      });
    }
    return handleResyncResource();
  };

  return (
    <SessionSettingsContainer>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Session Settings">
        {canEditSession && (
          <>
            <DropDownMenu.Item route={editSessionRoute} title="Edit" icon="pencil" />
            <DropDownMenu.Item route={cloneSessionRoute} title="Duplicate" icon="clone" />
            <DropDownMenu.Item
              onClick={() => setShowBulkDuplicateModal(true)}
              title="Bulk Duplicate"
              icon="multiple-clone"
            />
            {toggleCalendarForceSync && hasAnySyncToCalendarOn && (
              <DropDownMenu.Item
                onClick={forceSyncToCalendar}
                title="Force Calendar Sync"
                icon="loading"
              />
            )}
            {showManageAttendees && (
              <>
                <DropDownMenu.Separator />
                <DropDownMenu.Item onClick={onShowRoster} title="Manage Attendance" icon="group" />
              </>
            )}
            {meetingUrl && (
              <DropDownMenu.Item title="Join Online" icon="info" url={meetingUrl} target="_blank" />
            )}
            <DropDownMenu.Separator />
            <DropDownMenu.Item
              onClick={() =>
                confirmAlert({
                  title: 'Delete Session?',
                  content: () => <span>Would you like to delete this session?</span>,
                  confirmLabel: 'Yes',
                  secondaryActionLabel: 'No',
                  onConfirm: () => onDeleteSession(),
                  isDangerAction: true,
                })
              }
              title="Delete"
              icon="delete"
              color={colors.error600}
              textColor={colors.error600}
            />
          </>
        )}

        {showDetails && (
          <DropDownMenu.Item onClick={onClickOpenDetails} title="View Details" icon="view" />
        )}
        {showBulkDuplicateModal && (
          <SessionBulkCopyModal
            session={session}
            handleClose={() => setShowBulkDuplicateModal(false)}
            handleConfirm={() => setShowBulkDuplicateModal(false)}
          />
        )}
      </DropDownMenu>
    </SessionSettingsContainer>
  );
};

SessionSettings.propTypes = {
  session: PropTypes.object,
  onDeleteSession: PropTypes.func,

  onShowRoster: PropTypes.func,
  onClickOpenDetails: PropTypes.func,
};

export default SessionSettings;
