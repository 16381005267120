import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { map } from 'vendor/lodash';

import SessionGroupListItem from './SessionGroupListItem';

const GroupsExtraCountWrapper = styled.div`
  padding: 12px 59px 12px 20px;
  color: ${colors.neutral600};
`;

const GroupsCheckinListWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const GroupsExtraCount = ({ count }) => (
  <GroupsExtraCountWrapper>
    and {count} more {count === 1 ? 'group' : 'groups'}
  </GroupsExtraCountWrapper>
);

GroupsExtraCount.propTypes = {
  count: PropTypes.number,
};

const SessionGroupsList = ({ session, groups, groupsTotalCount, refreshEnrollments }) => (
  <div>
    <GroupsCheckinListWrapper>
      {map(groups, (group) => (
        <SessionGroupListItem
          key={group.id}
          session={session}
          group={group}
          refreshEnrollments={refreshEnrollments}
        />
      ))}
    </GroupsCheckinListWrapper>
    {groupsTotalCount - groups.length > 0 && (
      <GroupsExtraCount count={groupsTotalCount - groups.length} />
    )}
  </div>
);

SessionGroupsList.propTypes = {
  session: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  groupsTotalCount: PropTypes.number,
  refreshEnrollments: PropTypes.func,
};

export default SessionGroupsList;
