import { useQuery } from '@tanstack/react-query';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { useSettingsSectionsList } from 'app/settings/hooks';
import { prepareSettingsValuesForSubmission } from 'app/settings/services';
import AccessControlSection from 'app/shared/components/NewContentForm/sections/AccessControlSection';
import { FormSection } from 'app/shared/components/types';
import ChannelColorSelectField from 'inputs/components/ChannelColorSelectField';
import CoverImageField from 'inputs/components/CoverImageField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import { FormFieldGroup } from 'shared/components/Form';
import InfoBox from 'shared/components/InfoBox';
import NewContentForm from 'shared/components/NewContentForm';
import Form from 'shared/components/OldForm';
import { useFormSelector, useFormPreventTransition, useLabels } from 'shared/hooks';
import { toLower, pick, replace, isEmpty } from 'vendor/lodash';

type ChannelFormProps = {
  form: string;

  // Redux Form props
  invalid: boolean;
  error: object;
  submitting: boolean;
  change: (field: string, value: any) => void;
  handleSubmit: (event: any) => void;
  initialValues: any;

  isEdit: boolean;

  breadcrumbsItemList: object[];

  topBarActionName: string;
  backRoute: string;
  channelId?: number;

  settingsContext: string;
};

interface ChannelPayload {
  name: string;
  content_body: object;
  cover: string;
  owners_ids: number[];
  slug: string;
  color: string;
  is_hidden: boolean;
  groups_ids: number[];
  settings?: object;
}

const CoverContainer = styled.div`
  display: flex;
`;

const validateRequired = Form.validations.required();

function normalizeSlug(value) {
  return value ? replace(toLower(value), /[^a-z0-9]/g, '-') : '';
}

export const ChannelForm = (props: ChannelFormProps) => {
  const {
    form,
    invalid,
    error,
    submitting,
    change,
    handleSubmit,
    isEdit,
    breadcrumbsItemList,
    topBarActionName,
    backRoute,
    channelId,
    settingsContext,
    initialValues,
  } = props;
  const { label_channel: labelChannel, label_channel_plural: labelChannelPlural } = useLabels();

  const channelName = useFormSelector(form, 'name');
  const channelSlug = useFormSelector(form, 'slug');

  const infoPanelText = `${labelChannelPlural} allow you to manage team-specific content`;

  const { data: channelWithSameSlug } = useQuery({
    ...queries.channels.detail(channelSlug, 'lite'), // view_mode=lite
    enabled: !!channelSlug,
  });

  useFormPreventTransition(form);

  const HCArticleURL = undefined;

  const [settingsSectionsList] = useSettingsSectionsList(settingsContext, form) as Array<
    FormSection[]
  >;

  const channelSectionList = [
    {
      id: 'channel-details',
      label: 'Details',
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              label="Title"
              name="name"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelChannel)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>

          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="video_covers"
              />
            </CoverContainer>
          </FormFieldGroup>

          {isEdit && (
            <FormFieldGroup>
              <Field
                name="slug"
                label="URL Slug"
                required
                component={TextField}
                normalize={(value) => normalizeSlug(value)}
                validate={[validateRequired]}
                placeholder={`Give your ${toLower(labelChannel)} a slug`}
                type="text"
                warning={
                  channelWithSameSlug &&
                  channelId != channelWithSameSlug.id && (
                    <InfoBox
                      margin="8px 0"
                      type="warning"
                      content={`Another ${toLower(
                        labelChannel
                      )} with the same slug already exists.`}
                    />
                  )
                }
              />
            </FormFieldGroup>
          )}

          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <ChannelColorSelectField />
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <FacilitatorField
          label="Owner(s)"
          infoText="People who can make Settings changes including privileges and subscriptions."
          name="owners_ids"
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showInfobox
          // This is used in the banner
          contentNameSingular="content item"
          // This is used in the access control preference info text
          accessLevelContentName={`${toLower(labelChannel)} and its included content`}
          isAccessLevelContentNamePlural
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          showHideEnrolleesSection={false}
          showAttendeesSection={false}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];
  return (
    <NewContentForm
      contentNameSingular={`${labelChannel} settings`}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCArticleURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={channelName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={channelSectionList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={false}
    />
  );
};

const ConnectedChannelForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (values, _dispatch, { onSubmitHandler, defaultColor }) => {
    const payload: ChannelPayload = {
      ...pick(values, [
        'name',
        'content_body',
        'cover',
        'owners_ids',
        'slug',
        'color',
        'is_hidden',
        'groups_ids',
      ]),
    };

    if (!payload.color) {
      payload.color = defaultColor;
    }

    payload.settings = prepareSettingsValuesForSubmission(values?.settings);

    return await onSubmitHandler(payload);
  },
  onSubmitSuccess: (result, _dispatch, { onSubmitSuccessHandler, isEdit }) => {
    onSubmitSuccessHandler(result, isEdit);
  },
  onSubmitFail: (values) => {
    window.scrollTo(0, 0);
    toast.error(`${values.name} submission failed. Check the flagged fields and try again.`);
  },
})(ChannelForm);

export default ConnectedChannelForm;
