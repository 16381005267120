import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import EventCard from 'app/event-list/components/EventCard/EventCard';
import { HR, Button } from 'app/shared';
import CardCarousel from 'app/shared-cards/components/CardCarousel';
import MediaPoint from 'app/shared/components/MediaPoint';
import Text from 'app/shared/components/Text';
import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { getEnrollmentOpensCutoff } from 'event-shared/services';
import RequestEventModalWrapper from 'event-types/components/RequestEventModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_DONE } from 'shared/constants';
import { usePrevious } from 'shared/hooks';
import { join, isEmpty, difference, isNil, size, filter, get } from 'vendor/lodash';
import { Link } from 'vendor/mui';
import pluralize from 'vendor/pluralize';

const RelatedEventContainer = styled.div`
  // Container related styling
  &:hover {
    background-color: ${colors.neutral50};
  }
  border: 1px solid ${colors.neutral200};
  width: 290px;
  border-radius: 4px;
  margin: 8px;

  ${MediaPoint.Tablet} {
    width: 300px;
  }
`;

const UpcomingContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 20px auto 0px;
`;

const CarouselWrapper = styled.div`
  margin: 0px auto;
  width: 90%;
`;

const EventCardWrapper = styled.div`
  height: 325px;
`;

const MoreEventCardWrapper = styled.div`
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RequestEventContent = styled.div`
  ${(emptyEventList) =>
    emptyEventList
      ? css`
          margin: 10px 0px 0px 30px;
        `
      : null}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  padding: 10px 0px 10px;
`;

const ActionButtonWrapper = styled.div`
  margin-right: 20px;
`;

const RequestContainer = styled.div`
  margin-top: 20px;
`;

const UpcomingEventsCount = styled.div`
  margin: 20px 20px 8px;
`;

const TextWrapper = styled(Text)`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TextContent = styled.div`
  margin-left: -20px;
`;

export const UpcomingEvents = (props) => {
  const {
    event,
    eventType,
    excludeEventsIds,
    includePrivate,
    insideTrack,
    showEnrollmentAction,
    pageSize,
    justifyContent,
    title,
    emptyListText,
  } = props;

  const toggleRequestEvent = get(event, 'settings.toggle_request_event.value', false);
  const toggleAskForTeamSize = get(
    event,
    'settings.toggle_ask_for_team_size_request_an_event.value',
    false
  );

  const previousExcludeEventsIds = usePrevious(excludeEventsIds);

  const [fetchEventList, { data: events, status: eventsRequestStatus, count }] = useEntities(
    actions.event.retrieveList,
    null,
    {
      schema: [eventSchema],
    }
  );

  const defaultPageSize = insideTrack ? 2 : 19;

  const refreshEvents = () => {
    fetchEventList({
      event_types: [eventType.id],
      include_private: includePrivate ? 'true' : 'false',
      period: 'upcoming',
      status: ['published'],
      view_mode: showEnrollmentAction ? 'default' : 'lite',
      exclude_events: excludeEventsIds ? join(excludeEventsIds, ',') : null,
      page_size: pageSize ?? defaultPageSize,
    });
  };

  useEffect(() => {
    if (eventType.id) refreshEvents();
  }, [eventType.id]);

  useEffect(() => {
    const excludeEventsIdsChanged = !isEmpty(
      difference(excludeEventsIds, previousExcludeEventsIds)
    );

    if (excludeEventsIdsChanged) refreshEvents();
  }, [excludeEventsIds]);

  const upcomingEventsOpenedEnrollment = filter(events, (event) => {
    const enrollmentOpeningDate = getEnrollmentOpensCutoff(event);
    return isNil(enrollmentOpeningDate) || moment().isSameOrAfter(enrollmentOpeningDate);
  });
  const upcomingEventsCount = size(upcomingEventsOpenedEnrollment);

  return (
    <UpcomingContainer>
      <UpcomingEventsCount>
        <TextWrapper size="h4" lineHeight={20} medium>
          <Text>{title}</Text>
          {upcomingEventsCount > 0 && (
            <Link
              href={`${mapRoute('eventsList')}?event_types=${
                upcomingEventsOpenedEnrollment[0].event_type.id
              }&period=upcoming`}
              variant="body2"
              noWrap
            >
              View all {upcomingEventsCount} {pluralize('event', upcomingEventsCount)}
            </Link>
          )}
        </TextWrapper>
        {isEmpty(upcomingEventsOpenedEnrollment) &&
          eventsRequestStatus === STATUS_DONE &&
          toggleRequestEvent && (
            <RequestEventContent emptyEventList>
              <TextContent>
                <Text color={colors.neutral500}>{emptyListText}</Text>
              </TextContent>
              <ActionButtonWrapper>
                <RequestEventModalWrapper
                  eventType={eventType}
                  toggleAskForTeamSize={toggleAskForTeamSize}
                  size="medium"
                  inflate
                  primaryButton
                />
              </ActionButtonWrapper>
            </RequestEventContent>
          )}
      </UpcomingEventsCount>
      {eventsRequestStatus !== STATUS_DONE && <Loading />}
      {!isEmpty(upcomingEventsOpenedEnrollment) && eventsRequestStatus === STATUS_DONE && (
        <>
          <CarouselWrapper>
            <CardCarousel
              cardWidth={280}
              margin={10}
              list={upcomingEventsOpenedEnrollment}
              upcoming
              renderMore={count > 19}
              justifyContent={justifyContent}
              renderMoreComponent={() => {
                return (
                  <RelatedEventContainer>
                    <MoreEventCardWrapper>
                      <Title>
                        <Text size="h2" color={colors.neutral600} block>
                          <Link
                            href={`${mapRoute('eventsList')}?event_types=${
                              upcomingEventsOpenedEnrollment[0].event_type.id
                            }&period=upcoming`}
                            variant="body2"
                            noWrap
                          >
                            View all {`+${count - 19} ${pluralize('event', count)}`}
                          </Link>
                        </Text>
                        <Button
                          variant="text"
                          size="medium"
                          route={`${mapRoute('eventsList')}?event_types=${
                            upcomingEventsOpenedEnrollment[0].event_type.id
                          }&period=upcoming`}
                        >
                          View All
                        </Button>
                      </Title>
                    </MoreEventCardWrapper>
                  </RelatedEventContainer>
                );
              }}
              renderItem={(event) => {
                return (
                  <RelatedEventContainer key={event.id}>
                    <EventCardWrapper>
                      <EventCard
                        event={event}
                        showCover={false}
                        cardHeight="325"
                        paddingSize="10"
                        showPersonalizedMaxLength
                      />
                    </EventCardWrapper>
                  </RelatedEventContainer>
                );
              }}
            />
          </CarouselWrapper>
          {toggleRequestEvent && (
            <RequestContainer>
              <HR color={colors.neutral200} />
              <RequestEventContent>
                <Text color={colors.neutral500}>Can&apos;t find an event that works for you?</Text>
                <ActionButtonWrapper>
                  <RequestEventModalWrapper
                    eventType={eventType}
                    toggleAskForTeamSize={toggleAskForTeamSize}
                    size="medium"
                    inflate
                  />
                </ActionButtonWrapper>
              </RequestEventContent>
            </RequestContainer>
          )}
        </>
      )}
    </UpcomingContainer>
  );
};

UpcomingEvents.propTypes = {
  event: PropTypes.object,
  eventType: PropTypes.object,

  includePrivate: PropTypes.bool,
  excludeEventsIds: PropTypes.array,
  showEnrollmentAction: PropTypes.bool,

  insideTrack: PropTypes.bool,

  pageSize: PropTypes.number,

  justifyContent: PropTypes.bool,

  title: PropTypes.string,
  emptyListText: PropTypes.string,
};

UpcomingEvents.defaultProps = {
  includePrivate: false,
  showEnrollmentAction: false,
  justifyContent: false,
  insideTrack: false,
  title: 'Upcoming events',
  emptyListText: 'No upcoming events available currently.',
};

export default UpcomingEvents;
