import PropTypes from 'prop-types';
import React from 'react';

import { FilterOptionsList } from 'navigation/components/FilterBar/FilterOptions';

// TODO merge with GroupsFilter
const WidgetMultiSelectFilter = ({
  param,
  selecteds,
  disabled,
  onChange,
  options,
  getSelectedOnChange,
}) => {
  const handleOnChange = (selected) => {
    const newSelected = getSelectedOnChange(param, selecteds, selected);

    onChange({
      ...selecteds,
      [param]: newSelected,
    });
  };

  const paramSelecteds = selecteds[param] || [];

  return (
    <FilterOptionsList
      onChange={handleOnChange}
      options={options}
      selecteds={paramSelecteds}
      disabled={disabled}
      clearWhenSelectingAll={false}
    />
  );
};

WidgetMultiSelectFilter.defaultProps = {
  getSelectedOnChange: (_, __, newValue) => newValue,
};

WidgetMultiSelectFilter.propTypes = {
  param: PropTypes.string,
  selecteds: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getSelectedOnChange: PropTypes.func,
};

export default WidgetMultiSelectFilter;
