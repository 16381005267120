import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from 'alerts/actions';
import RadioField from 'inputs/components/RadioField';
import SelectField from 'inputs/components/SelectField';
import TextField from 'inputs/components/TextField';
import TopicsInputField from 'inputs/components/TopicsInputField';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Form from 'shared/components/OldForm';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import { ALERT_FREQUENCIES } from './constants';

const Content = styled.div`
  width: 260px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 12px;
  }
`;

const FilterFieldGroup = styled.div`
  margin-top: 16px;
`;

const FieldGroup = styled.div`
  margin-top: 12px;
`;

const ALERT_TYPE = {
  TERM: 'term',
  TOPIC: 'topic',
};

export const AlertFormModal = (props) => {
  const { handleClose, handleSubmit, invalid, error, submitting, alertType } = props;

  return (
    <Modal title="New Alert" handleClose={handleClose} overflow="initial" width={496}>
      <ModalBody>
        <Form.ErrorContainer invalid={invalid} error={error} submitting={submitting} />
        <Content>
          <StyledForm>
            <RadioInputWrapper>
              <Field
                component={RadioField}
                name="type"
                label="Tag"
                optionValue={ALERT_TYPE.TOPIC}
              />
            </RadioInputWrapper>
            <RadioInputWrapper>
              <Field
                component={RadioField}
                name="type"
                label="Keyword"
                optionValue={ALERT_TYPE.TERM}
              />
            </RadioInputWrapper>

            {alertType === ALERT_TYPE.TERM && (
              <FilterFieldGroup>
                <Field name="term" component={TextField} max={140} placeholder="Type a keyword" />
              </FilterFieldGroup>
            )}

            {alertType === ALERT_TYPE.TOPIC && (
              <FilterFieldGroup>
                <Field name="topic_name" component={TopicsInputField} multiple={false} />
              </FilterFieldGroup>
            )}

            <FieldGroup>
              <Form.FieldLabel tooltipTitle="A period for you to be notified of new events">
                Frequency
              </Form.FieldLabel>
              <Field name="frequency" component={SelectField} options={ALERT_FREQUENCIES} />
            </FieldGroup>
          </StyledForm>
        </Content>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit}>Create Alert</ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

AlertFormModal.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.object,

  alertType: PropTypes.string,

  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

const validate = (values) => {
  const errors = {};

  if (values.type === ALERT_TYPE.TOPIC && !values.topic_name) {
    errors.topic_name = 'Required';
  } else if (values.type === ALERT_TYPE.TERM && !values.term) {
    errors.term = 'Required';
  }

  return errors;
};

const transform = (values) => {
  const payload = { ...values };

  if (payload.type === ALERT_TYPE.TOPIC) {
    payload.term = '';
  } else if (payload.type === ALERT_TYPE.TERM) {
    payload.topic_name = '';
  }

  return payload;
};

const AlertFormModalForm = reduxForm({
  form: 'alerts',
  onSubmit: onSubmitActions(actions.userAlertCreateRequest.toString(), transform),
  validate,
})(AlertFormModal);

const formSelector = formValueSelector('alerts');
const mapStateToProps = (state) => ({
  alertType: formSelector(state, 'type'),
});

export default connect(mapStateToProps)(AlertFormModalForm);
