import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { CONTENT_TYPES } from 'app/catalog/constants';
import Loading from 'app/shared/components/Loading';
import { STATUS_DONE } from 'app/shared/constants';
import { usePublicIdFromURL } from 'app/shared/hooks';
import { useFetchDescendantTrackItems } from 'app/tracks/hooks';
import { queries } from 'queries';
import { filter, map, noop, size } from 'vendor/lodash';

import { Track, TrackSectionOrItemNode, TrackItem, TrackSectionWithItems } from '../types';
import { buildComposableTrackData } from '../utils/buildComposableTrackData';
import { getFirstUncompletedTrackItem, isItemTrackSection } from '../utils/helpers';

type ComposableTrackCtxType = {
  /**
   * This is the root track that is being consumed.
   */
  rootTrack: Track;
  /**
   * List of sections and items in the root track in order.
   */
  nonEmptySectionsAndItemsOrderedList: TrackSectionOrItemNode[];
  /**
   * List of sections with items in the root track.
   */
  nonEmptySectionsWithItems: TrackSectionWithItems[];
  /**
   * Total count of non-empty sections and items in the root track.
   */
  totalCount: number;

  /**
   * Returns the track items of a given track.
   */
  filterTrackItems: (trackId: string) => TrackItem[];
  /**
   * Returns the track sections of a given track.
   */
  filterTrackSections: (trackId: string) => TrackSectionWithItems[];
  /**
   * Returns the index of a given section or item in the track
   */
  getSectionOrItemIndex: (trackId: string, sectionOrItemId: string) => number;
  /**
   * Returns track item and its index to resume from.
   */
  getTrackItemToResume: (trackId: string) => readonly [number, TrackItem];
  onRefreshDescendantTrackItems: () => void;
  onRefreshRootTrack: () => void;
};

const defaultComposableTrackCtx: ComposableTrackCtxType = {
  rootTrack: {} as Track,
  nonEmptySectionsAndItemsOrderedList: [],
  nonEmptySectionsWithItems: [],
  totalCount: 0,

  filterTrackItems: noop as ComposableTrackCtxType['filterTrackItems'],
  filterTrackSections: noop as ComposableTrackCtxType['filterTrackSections'],
  getSectionOrItemIndex: noop as ComposableTrackCtxType['getSectionOrItemIndex'],
  getTrackItemToResume: noop as ComposableTrackCtxType['getTrackItemToResume'],
  onRefreshRootTrack: noop,
  onRefreshDescendantTrackItems: noop,
};

const ComposableTrackCtx = React.createContext<ComposableTrackCtxType>(defaultComposableTrackCtx);

type ComposableTrackProviderProps = {
  track: Track;
  children: React.ReactNode;
};

export function ComposableTrackProvider(props: ComposableTrackProviderProps) {
  const { track, children } = props;

  const { publicId: trackId } = usePublicIdFromURL();
  const queryClient = useQueryClient();

  // TODO: refactor to react query
  const { fetchDescendantTrackItems, descendantTrackItems, fetchDescendantTrackItemsStatus } =
    useFetchDescendantTrackItems();

  const isTrack =
    track.content_type === CONTENT_TYPES.track ||
    track.content_type === CONTENT_TYPES.scheduled_track;

  const {
    nonEmptySectionsAndItemsOrderedList,
    nonEmptySectionsWithItems,
    sectionsAndItemsIndexesMapping,
  } = React.useMemo(() => {
    const trackItems = !isTrack
      ? map(track.track_items, (item) => ({ ...item, track_id: track.public_id }))
      : (descendantTrackItems as TrackItem[]);

    return buildComposableTrackData(track, trackItems);
  }, [track, descendantTrackItems]);

  const handleFetchDescendantTrackItems = React.useCallback(() => {
    // Don't fetch descendant track items for assessments
    if (!isTrack) {
      return;
    }

    fetchDescendantTrackItems(trackId);
  }, [fetchDescendantTrackItems, isTrack, trackId]);

  React.useEffect(() => {
    handleFetchDescendantTrackItems();
  }, [handleFetchDescendantTrackItems]);

  const handleFilterTrackItems = React.useCallback(
    (trackId: string) => {
      const trackItems = filter(
        nonEmptySectionsAndItemsOrderedList,
        (item) => !isItemTrackSection(item) && item.track_id === trackId
      ) as TrackItem[];

      return trackItems;
    },
    [nonEmptySectionsAndItemsOrderedList]
  );

  const handleFilterTrackSections = React.useCallback(
    (trackId: string) => {
      const trackSections = filter(nonEmptySectionsWithItems, (item) => item.track_id === trackId);

      return trackSections;
    },
    [nonEmptySectionsWithItems]
  );

  const handleGetSectionOrItemIndex = React.useCallback(
    (trackPublicId: string, sectionOrItemId: string) => {
      return sectionsAndItemsIndexesMapping[`${trackPublicId}_${sectionOrItemId}`];
    },
    [sectionsAndItemsIndexesMapping]
  );

  const handleGetTrackItemToResume = React.useCallback(
    (trackPublicId: string) => {
      const trackItems = handleFilterTrackItems(trackPublicId);
      const firstUncompletedItem = getFirstUncompletedTrackItem(trackItems);

      if (!firstUncompletedItem) {
        return [0, trackItems[0]] as const;
      }

      const firstUncompletedItemIdx = handleGetSectionOrItemIndex(
        trackPublicId,
        firstUncompletedItem.content_item.public_id
      );

      return [firstUncompletedItemIdx, firstUncompletedItem] as const;
    },
    [handleFilterTrackItems, handleGetSectionOrItemIndex]
  );

  const handleRefreshRootTrack = React.useCallback(() => {
    if (!trackId) {
      return;
    }

    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(trackId).queryKey,
    });
  }, [trackId]);

  const ctxValue: ComposableTrackCtxType = React.useMemo(() => {
    return {
      rootTrack: track,
      nonEmptySectionsAndItemsOrderedList,
      nonEmptySectionsWithItems,
      totalCount: size(nonEmptySectionsAndItemsOrderedList),
      onRefreshRootTrack: handleRefreshRootTrack,
      onRefreshDescendantTrackItems: handleFetchDescendantTrackItems,
      filterTrackItems: handleFilterTrackItems,
      filterTrackSections: handleFilterTrackSections,
      getSectionOrItemIndex: handleGetSectionOrItemIndex,
      getTrackItemToResume: handleGetTrackItemToResume,
    };
  }, [
    track,
    nonEmptySectionsAndItemsOrderedList,
    nonEmptySectionsWithItems,
    handleRefreshRootTrack,
    handleFetchDescendantTrackItems,
    handleFilterTrackItems,
    handleFilterTrackSections,
    handleGetSectionOrItemIndex,
    handleGetTrackItemToResume,
  ]);

  if (isTrack && fetchDescendantTrackItemsStatus !== STATUS_DONE) {
    return <Loading />;
  }

  return <ComposableTrackCtx.Provider value={ctxValue}>{children}</ComposableTrackCtx.Provider>;
}

export function useComposableTrackCtx() {
  const ctx = React.useContext(ComposableTrackCtx);
  return ctx;
}
