import RQLFilterBar from 'app/filters/components/RQLFilterBar/RQLFilterBar';
import { useNewRqlFilters } from 'app/filters/hooks';
import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import ViewAllContent from 'catalog/components/ViewAllContent';
import { useCatalogFetchData } from 'catalog/hooks';
import { translatorFactory } from 'feature-parity/utils';
import useCatalogPillsDefinition from 'features/contentitems/hooks/useCatalogPillsDefinition';
import { useGetAllCatalogFiltersDefinition } from 'features/contentitems/hooks/useGetAllCatalogFiltersDefinition';
import { FiltersDefinitionProps, PillsDefinitionProps } from 'filters/types';
import { cleanRQLExpression, getOrderedFiltersDefinition } from 'filters/utils';
import FilterPills from 'navigation/components/FilterPills';
import CardList from 'shared-cards/components/CardList';
import { OptimalContentItemCard } from 'shared/components/Card';
import Loading from 'shared/components/Loading';
import { useCurrentUser, useOrderingLabel } from 'shared/hooks';
import { get, isEmpty, omit } from 'vendor/lodash';
import { Box, useMediaQuery, grey, Typography } from 'vendor/mui';
import rql from 'vendor/rql';

import { Channel } from '../../types';

const filtersTranslator = translatorFactory({
  translationMap: {
    type: 'learning_types',
    q: 'q',
    category: 'main_topics',
    facilitator: 'owners',
    tag: 'topics',
  },
  defaultOrdering: 'relevance',
  orderingOptions: {
    relevance: 'relevance',
    upcoming: 'created',
    newest: '-created',
    '-feedback_rating': '-avg_feedback_rating',
    name: 'name',
  },
});

type UseFiltersDefOptions = Omit<FiltersDefinitionProps, 'fixedFilters' | 'includeFilterChannels'>;

function useFiltersDef(options: UseFiltersDefOptions) {
  const { filters, ordering, updateFilter, setOrdering } = options;

  const currentUser = useCurrentUser();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const allFilters = useGetAllCatalogFiltersDefinition({
    filters,
    ordering,
    setOrdering,
    updateFilter,
  });

  const filtersToUse = omit(allFilters, ['Channels']);

  const defaultFiltersOrder = [
    'SearchBar',
    'Content Types',
    'Categories',
    'Sort',
    'Tags',
    'Ownership',
    'Status',
  ];

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_catalog',
    defaultFiltersOrder,
    allFilters: filtersToUse,
    filterWidth: '200px',
    fixedFilters: [],
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
    includeFilterChannels: false,
  });
}

function usePillsDefinition(options: PillsDefinitionProps) {
  const { filters } = options;

  const channelPillsDef = { pills: [] };

  const cleanedFilters = omit(filters, ['tab']);
  const catalogPillsDef = useCatalogPillsDefinition({ filters: cleanedFilters });

  return {
    pills: [...channelPillsDef.pills, ...catalogPillsDef.pills],
  };
}

type ChannelHomeCatalogProps = {
  channel: Channel;
};

export function ChannelHomeCatalog(props: ChannelHomeCatalogProps) {
  const { channel } = props;

  const { filters, rqlExpression, ordering, updateFilter, setOrdering, resetFilters, removeValue } =
    useNewRqlFilters({
      initialFiltersState: { status: { $eq: 'published' } },
      initialOrdering: 'relevance',
      translator: filtersTranslator,
    });

  const filtersDef = useFiltersDef({
    filters,
    ordering,
    updateFilter,
    setOrdering,
  });

  const pillsDef = usePillsDefinition({ filters });

  const selectedLearningTypes = rqlToInput(get(filters, 'type'));

  const cleanedRQLExpression = cleanRQLExpression(rqlExpression, ['type', 'tab']);
  const searchRQLExpression = rql({
    channel: channel.id,
  });
  const searchQuery = `${cleanedRQLExpression}&${searchRQLExpression}`;

  const {
    data: catalogItems,
    fetchMore,
    status: fetchStatus,
    count,
    hasNextPage,
    isLoading,
  } = useCatalogFetchData({
    selectedLearningTypes,
    rqlExpression: searchQuery,
  });

  const handleRemoveFilter = (filter: any) => {
    const filterName = get(filter, 'filterName', '');

    if (filterName === 'tab') {
      resetFilters(true);
      window.location.hash = '';
      return;
    }

    removeValue(filterName, get(filter, 'value', ''));
  };

  const orderingLabel = useOrderingLabel(ordering);

  return (
    <Box pt={2}>
      <Typography variant="h5" sx={{ color: grey['900'] }} fontWeight={500}>
        Catalog
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <RQLFilterBar
          filters={filtersDef.filters}
          moreFilters={filtersDef.moreFilters}
          onClearAll={resetFilters}
        />

        <Box pb={isEmpty(pillsDef.pills) ? 1 : 0}>
          <FilterPills pills={pillsDef.pills} onRemove={handleRemoveFilter} />
        </Box>
      </Box>

      {isLoading && <Loading />}

      {!isLoading && count === 0 && <Box>No results found</Box>}

      <ViewAllContent
        fetchStatus={fetchStatus}
        fetchNextPage={fetchMore}
        hasNextPage={hasNextPage}
        totalResults={count}
        tooltipMessage={orderingLabel}
      >
        <CardList<Record<string, unknown>>
          items={catalogItems}
          renderItem={(item) => (
            <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
          )}
        />
      </ViewAllContent>
    </Box>
  );
}
