import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { apiPost } from 'services/requests';
import { ApiURLs, fetchURL } from 'services/requests-base';

import { listQueryKeyFactory } from '../utils';

export const createBulkUserLoad = async ({
  force_dry_run = false,
}: {
  force_dry_run?: boolean;
}) => {
  const body = { force_dry_run };
  const { data: response } = await apiPost('users_api:start_bulk_user_load', null, body);
  return response;
};

export const createUserLoad = async ({
  user_id,
  force_dry_run = false,
}: {
  user_id: number;
  force_dry_run?: boolean;
}) => {
  const body = { force_dry_run };
  const { data: response } = await apiPost('users_api:start_user_load', { user_id }, body);
  return response;
};

export const user_loads = createQueryKeys('user_loads', {
  list: listQueryKeyFactory({ endpointName: 'users_api:user_load_list' }),
  listFilters: () => ({
    queryKey: ['users_api:user_load_rql_filters'],
    queryFn: async () => {
      const url = ApiURLs['users_api:user_load_rql_filters']();
      const { data } = await fetchURL(url);
      return { results: data };
    },
  }),
});

export type UserLoadsQueryKeys = inferQueryKeys<typeof user_loads>;
