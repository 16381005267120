import { useEffect } from 'react';
import { useParams } from 'react-router';

import { CONTENT_ITEM_PERMISSIONS } from 'app/shared/permissions';
import { LEARNING_TYPES } from 'catalog/constants';
import { PublicIdParam } from 'common/types';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Page404 from 'scenes/404';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, HTTP_404_NOT_FOUND } from 'shared/constants';
import { useOriginRoute, useSetDetailedObject, useLabels } from 'shared/hooks';
import { includes, toLower } from 'vendor/lodash';

import AssessmentFormPage from './AssessmentFormPage';

const AssessmentEdit = () => {
  const { public_id: publicId } = useParams<PublicIdParam>();
  const { label_assessment: labelAssessment, label_assessment_plural: labelAssessmentPlural } =
    useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.assessments
  }&o=upcoming`;
  const assessmentDetailRoute = mapRoute('assessmentDetails', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(assessmentDetailRoute);

  const [fetchAssessment, { data: assessment, status, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ data: assessment, status }) => {
      if (
        status === STATUS_DONE &&
        !includes(assessment.permissions, CONTENT_ITEM_PERMISSIONS.manage)
      ) {
        permissionDeniedRedirectAlert();
      }
    },
    {
      schema: contentSchema,
    }
  );

  useSetDetailedObject({ id: publicId }, 'assessments', [publicId]);

  useEffect(() => {
    fetchAssessment(publicId);
  }, [fetchAssessment, publicId]);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (status !== STATUS_DONE) return <Loading />;

  const initialValuesParams = { assessment };
  return (
    <AssessmentFormPage
      isEdit
      pageTitle={`Edit ${labelAssessment}`}
      topBarActionName={`Editing ${labelAssessment}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelAssessmentPlural,
          link: catalogRoute,
        },
        {
          label: assessment.name,
          link: assessmentDetailRoute,
        },
        {
          label: `Edit ${toLower(labelAssessment)}`,
        },
      ]}
    />
  );
};

export default AssessmentEdit;
