import React, { useEffect, useState } from 'react';

import { EDIT_CODELAB_PERMISSION } from 'app/shared/permissions';
import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { codelabSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import {
  useOriginRoute,
  useSetDetailedObject,
  useQueryParams,
  usePublicIdFromURL,
} from 'shared/hooks';
import { toLower } from 'vendor/lodash';

import CodelabFormPage from './CodelabFormPage';

const CodelabEdit = () => {
  const { publicId } = usePublicIdFromURL();
  const [userCanSee, setUserCanSee] = useState(false);

  const queryParams = useQueryParams();
  const isPublishing = queryParams.is_publishing === 'true';

  const labelCodelab = 'Codelab';
  const labelCodelabPlural = 'Codelabs';

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.codelabs
  }&o=upcoming`;
  const codelabDetailRoute = mapRoute('standAloneCodelabDetail', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(isPublishing ? catalogRoute : codelabDetailRoute);

  const [fetchCodelab, { data: codelab, status }] = useEntities(
    actions.codelab.retrieveDetails,
    ({ data: codelab, status }) => {
      if (status === STATUS_DONE) {
        if (codelab.permissions?.includes(EDIT_CODELAB_PERMISSION)) {
          setUserCanSee(true);
        } else {
          permissionDeniedRedirectAlert();
        }
      }
    },
    {
      schema: codelabSchema,
    }
  );

  useEffect(() => {
    fetchCodelab(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'codelabs', [publicId]);

  if (status === STATUS_LOADING || !userCanSee) return <Loading />;

  const initialValuesParams = { codelab };

  return (
    <>
      {isPublishing && (
        <CodelabFormPage
          pageTitle={`Publish ${labelCodelab}`}
          topBarActionName={`Publishing ${labelCodelab}`}
          backRoute={backRoute}
          initialValuesParams={initialValuesParams}
          breadcrumbsItemList={[
            {
              label: labelCodelabPlural,
              link: catalogRoute,
            },
            {
              label: `New ${toLower(labelCodelab)}`,
            },
          ]}
        />
      )}
      {!isPublishing && (
        <CodelabFormPage
          pageTitle={`Edit ${labelCodelab}`}
          topBarActionName={`Editing ${labelCodelab}`}
          backRoute={backRoute}
          initialValuesParams={initialValuesParams}
          breadcrumbsItemList={[
            {
              label: labelCodelabPlural,
              link: catalogRoute,
            },
            {
              label: codelab.name,
              link: codelabDetailRoute,
            },
            {
              label: `Edit ${toLower(labelCodelab)}`,
            },
          ]}
        />
      )}
    </>
  );
};

export default CodelabEdit;
