import PropTypes from 'prop-types';
import React from 'react';

import LazyDropdownFilter from 'backoffice/components/Dashboard/LazyFilters/LazyDropdownFilter';
import actions from 'entities/actions';

const TopicFilter = ({ filterType, ...props }) => {
  const getFetchExtraParams = (q, includeIds) => {
    const fetchParams = {
      include_slugs: includeIds,
      include_ids: undefined,
    };

    if (!filterType) return fetchParams;
    if (filterType === 'all_tags') return { all_tags: true, ...fetchParams };
    return { tag_type: filterType, ...fetchParams };
  };

  return (
    <LazyDropdownFilter
      actionFunc={actions.topic.retrieveList}
      getFetchExtraParams={getFetchExtraParams}
      {...props}
    />
  );
};

TopicFilter.propTypes = {
  filterType: PropTypes.string,
};

export default TopicFilter;
