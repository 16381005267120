import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import LinkHover from 'shared/components/LinkHover';
import { map } from 'vendor/lodash';

import './style.scss';

const Cover = (props) => {
  const { coverLink, topics, coverImage, fallbackImage, topicLink, borderRadius = true } = props;
  const coverUrl = coverImage || fallbackImage;

  const coverClassNames = classnames('cover-image', { 'cover-image-border-radius': borderRadius });

  return (
    <div className="cover-component">
      <div className="cover-container">
        <div className="topics-container">
          {coverLink ? (
            <LinkHover to={coverLink}>
              <div className={coverClassNames} style={{ backgroundImage: `url(${coverUrl})` }} />
            </LinkHover>
          ) : (
            <div className={coverClassNames} style={{ backgroundImage: `url(${coverUrl})` }} />
          )}
          <ul className="topic-list">
            {topics &&
              map(topics, (topic) => (
                <li key={topic.id}>
                  <a href={`${topicLink}?topics=${topic.id}`}>
                    <span className="cover-topic">{topic.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

Cover.propTypes = {
  coverImage: PropTypes.string,
  fallbackImage: PropTypes.string,
  coverLink: PropTypes.string,
  borderRadius: PropTypes.bool,

  topics: PropTypes.arrayOf(PropTypes.object),
  topicLink: PropTypes.string,
};

export default Cover;
