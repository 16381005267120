import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { makeStyles } from 'vendor/mui';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: 0,
    '& > *:first-child': {
      fontSize: 19,
    },
  },
  root: ({ isSelected }) => ({
    color: !isSelected ? theme.palette.primary.main : theme.palette.primary.contrastText,
    '&:hover, &:active, &:focus': {
      color: !isSelected ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
  }),
}));

const SegmentedControls = ({ item, isSelected, onClick, disabled, ...props }) => {
  const handleClick = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onClick(item.value);
  };

  const isDisabled = disabled || item.disabled;
  const classes = useStyles({ isSelected });
  return (
    <Button
      classes={{
        // If item doesn't have a name, apply a different styling to the button
        ...(!item.name && { iconSizeMedium: classes.iconButton }),
        root: classes.root,
      }}
      startIcon={item.icon && <Icon name={item.icon} />}
      variant={isSelected ? 'contained' : 'outlined'}
      disabled={isDisabled}
      // Only pass onClick to the MUI button if it was defined
      {...(onClick && { onClick: handleClick })}
      route={item.route}
      {...props}
      // Fixes button size when using icons
      size={item.icon ? 'medium' : 'small'}
      sx={{ maxHeight: 40 }}
    >
      {item.name}
    </Button>
  );
};

SegmentedControls.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SegmentedControls;
