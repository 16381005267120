import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'services/colors';
import { normalizeDatetime } from 'services/datetime';
import Icon from 'shared/components/Icon';
import { useCurrentUser } from 'shared/hooks';
import { get, first, last, size } from 'vendor/lodash';
import { Stack, Divider, Typography, Box } from 'vendor/mui';
import { PlaceIcon } from 'vendor/mui-icons';

function getEventLocationTypeLabel(event) {
  const isOnline = get(event, 'is_online', false);
  const isLocal = get(event, 'is_local', false);

  if (isOnline && isLocal) {
    return 'In-person or online';
  } else if (isLocal) {
    return 'In-person';
  } else if (isOnline) {
    return 'Online';
  }

  return '';
}

function getEventLocationName(event) {
  const isLocal = get(event, 'is_local', false);

  if (!isLocal) {
    return '';
  }

  return get(event, 'location.name', '');
}

function EventInfoLocation(props) {
  const { event } = props;

  const locationTypeLabel = getEventLocationTypeLabel(event);
  const locationName = getEventLocationName(event);

  return (
    <Box display="flex" alignItems="center" gap="12px" paddingY="4px">
      <PlaceIcon fontSize="small" sx={{ color: colors.neutral600 }} />
      <Typography variant="body2">
        <strong>{locationTypeLabel} event</strong> {locationName}
      </Typography>
    </Box>
  );
}

EventInfoLocation.propTypes = {
  event: PropTypes.object,
};

function getTimeslotDisplayDatetime({ datetime, timezone, userTimezone, isOnline }) {
  const { datetime: date } = normalizeDatetime(datetime, timezone, userTimezone, isOnline);

  const isSameYear = moment().year() === date.year();
  const formattedDatetime = date.format(
    isSameYear ? 'ddd, MMM D, h:mm A' : 'ddd, MMM D, YYYY h:mm A'
  );

  return formattedDatetime;
}

function EventInfoTimeslotRange(props) {
  const { event } = props;

  const { timezone: userTimezone } = useCurrentUser();

  const { timeslots, timezone, is_online: isOnline } = event;
  const earliest = first(timeslots);
  const latest = last(timeslots);

  const earliestDisplay = getTimeslotDisplayDatetime({
    datetime: earliest?.starts_at_tz_aware,
    timezone,
    userTimezone,
    isOnline,
  });
  const latestDisplay =
    size(timeslots) > 1
      ? getTimeslotDisplayDatetime({
          datetime: latest?.ends_at_tz_aware,
          timezone,
          userTimezone,
          isOnline,
        })
      : null;

  return (
    <Box display="flex" alignItems="center" gap="12px" paddingY="4px">
      <Icon name="calendar-clock" height={20} width={20} color={colors.neutral600} />
      <Typography variant="body2">
        {earliestDisplay} {latestDisplay != null && ` - ${latestDisplay}`}
      </Typography>
    </Box>
  );
}

EventInfoTimeslotRange.propTypes = {
  event: PropTypes.object,
};

const EventInfoContainerContent = (props) => {
  const { content } = props;

  return (
    <Stack direction="column" divider={<Divider />}>
      <EventInfoLocation event={content} />
      <EventInfoTimeslotRange event={content} />
    </Stack>
  );
};

EventInfoContainerContent.propTypes = {
  content: PropTypes.object,
};

export default EventInfoContainerContent;
