import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useUID } from 'app/shared/hooks';
import colors from 'services/colors';
import InfoText from 'shared/components/InfoText';
import Text from 'shared/components/Text';

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  margin-bottom: 0;
`;

const LabelAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.div`
  position: relative;
`;

const OnIcon = styled.div`
  position: absolute;
  width: 1px;
  height: 10px;
  top: 11px;
  left: 16px;
  background-color: white;
`;

const OffIcon = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;
  border: 1px solid white;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;

const getBackgroundColor = ({ checked, disabled }) => {
  if (disabled) {
    return colors.neutral200;
  }

  if (checked) {
    return colors.action600;
  }

  return colors.neutral400;
};

const getCirclePosition = ({ checked }) => {
  return checked ? 'left: 26px;' : 'left: 2px;';
};

const StyledInput = styled.input`
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 48px;
  height: 24px;
  background-color: ${getBackgroundColor};
  transition: background-color 0.3s;
  border-radius: 18px;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    background-color: ${({ disabled }) => (disabled ? colors.neutral50 : 'white')};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: left 0.3s;
    ${getCirclePosition}
  }
`;

const LabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  width: fit-content;
`;

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  align-self: ${({ $alignSelf }) => $alignSelf || 'center'};
`;

const CheckboxInput = ({
  label,
  labelWeight,
  infoText,
  infoTextAlignment,
  labelSize,
  tourId,
  ...inputProps
}) => {
  const { checked, disabled } = inputProps;

  const uid = useUID();

  return (
    <CheckboxWrapper>
      <LabelAndSubtitleContainer>
        <LabelContainer htmlFor={uid}>
          <Text size={labelSize} bold={labelWeight === 'bold'} medium={labelWeight === 'medium'}>
            {label}
          </Text>
        </LabelContainer>
        <SubtitleContainer $alignSelf={infoTextAlignment}>
          {infoText && <InfoText top={4} bottom={8} content={infoText} />}
        </SubtitleContainer>
      </LabelAndSubtitleContainer>
      <CheckboxContainer>
        <StyledInput
          id={uid}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...inputProps}
        />
        {checked ? <OnIcon /> : <OffIcon />}
      </CheckboxContainer>
    </CheckboxWrapper>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  labelWeight: PropTypes.oneOf(['bold', 'medium']),
  labelSize: PropTypes.string,
  infoText: PropTypes.string,
  infoTextAlignment: PropTypes.string,
  tourId: PropTypes.number,
};

export default CheckboxInput;
