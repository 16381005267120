import { Moment } from 'moment';
import { useState } from 'react';

import DatePickerInput from 'inputs/components/DatePickerInput';
import RadioInput from 'inputs/components/RadioInput';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { isNil } from 'vendor/lodash';
import { InputLabel, Stack } from 'vendor/mui';

export interface AssignDueDatePayload {
  assignmentType: 'suggested' | 'prescribed';
  dueDate: string | null;
}

export interface AssignDueDateModalProps {
  onSubmit: (payload: AssignDueDatePayload) => void;
  loading: boolean;
  handleClose: () => void;
  handleBack: () => void;
  title: string;
  initialValues?: Partial<AssignDueDatePayload>;
  nextButtonLabel?: string;
}

const AssignDueDateModal = ({
  handleClose,
  handleBack,
  title,
  onSubmit,
  initialValues,
  loading,
  nextButtonLabel = 'Assign',
}: AssignDueDateModalProps) => {
  let initialAssignmentType = initialValues?.assignmentType;
  if (!initialAssignmentType) {
    initialAssignmentType = initialValues?.dueDate ? 'prescribed' : 'suggested';
  }
  const [assignmentType, setAssignmentType] = useState(initialAssignmentType);
  const [dueDate, setDueDate] = useState<string | null>(initialValues?.dueDate ?? null);

  const handleChoiceChangeClick = (e) => {
    setAssignmentType(e);
  };

  const isAssignmentDataValid = () => {
    return !(assignmentType === 'prescribed' && isNil(dueDate));
  };

  const handleNext = () => {
    const payload = {
      assignmentType,
      dueDate: assignmentType === 'suggested' ? null : dueDate,
    };
    onSubmit(payload);
  };

  const handleDateChange = (value: Moment) => {
    if (value.isValid()) {
      setDueDate(value?.format('YYYY-MM-DD'));
    } else {
      setDueDate(null);
    }
  };

  const isDueDatePickerDisabled = () => {
    return assignmentType === 'suggested';
  };

  return (
    <Modal title={title} overflow="hidden" handleClose={handleClose} handleBack={handleBack}>
      <ModalBody fullSize scrollable>
        <Stack padding={2} spacing={3} direction="column">
          <Stack spacing={0.5} direction="column">
            <InputLabel htmlFor="type">Assignment Type</InputLabel>
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              <RadioInput
                key="type-prescribed"
                inputValue={assignmentType}
                name="type"
                value="prescribed"
                label="Prescribed"
                labelSize="h4"
                onChange={handleChoiceChangeClick}
              />
              <DatePickerInput
                label="Due date"
                value={dueDate}
                onChange={handleDateChange}
                InputProps={{
                  placeholder: 'No due date',
                }}
                disabled={isDueDatePickerDisabled()}
                inputProps={{}}
                clearable
              />
            </Stack>
            <RadioInput
              key="type-suggested"
              inputValue={assignmentType}
              name="type"
              value="suggested"
              label="Suggested"
              labelSize="h4"
              onChange={handleChoiceChangeClick}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        {loading && <Loading hasMargin={false} />}
        {!loading && (
          <ModalFooterButton onClick={handleNext} disabled={!isAssignmentDataValid()}>
            {nextButtonLabel}
          </ModalFooterButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AssignDueDateModal;
