import SegmentedControlsInput from 'inputs/components/SegmentedControlsInput';
import { find } from 'vendor/lodash';

interface EventViewDensityProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
}

const EventViewDensity = ({ value, onChange, disabled }: EventViewDensityProps) => {
  const availableViewDensityOptions = [
    {
      icon: 'grid-view',
      value: 'cozy',
    },
    {
      icon: 'calendar',
      value: 'calendar',
      hideOnMobile: true,
    },
  ];
  const isSelectedViewDensityAvailable = find(availableViewDensityOptions, ['value', value]);

  if (!isSelectedViewDensityAvailable) {
    const defaultViewDensity = availableViewDensityOptions[0].value;
    onChange(defaultViewDensity);
  }

  return (
    <SegmentedControlsInput
      items={availableViewDensityOptions}
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={{
        height: '100%',
      }}
    />
  );
};

export default EventViewDensity;
