import PropTypes from 'prop-types';
import React from 'react';

import Pill from 'shared/components/Pill/Pill';

import UserProfileInitials from '../UserAvatar/UserProfileInitials';

export class UserItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleClick() {
    const { userId, userEmail, onClick } = this.props;

    if (onClick) {
      onClick(userId, userEmail);
    }
  }

  handleRemoveClick() {
    const { userId, userEmail, onRemoveClick } = this.props;

    if (onRemoveClick) {
      onRemoveClick(userId, userEmail);
    }
  }

  render() {
    const { userEmail, userName, userImage, onRemoveClick } = this.props;

    const name = userName || userEmail;

    return (
      <Pill
        size="large"
        onClick={this.handleClick}
        imageSrc={userImage}
        imagePlaceHolder={<UserProfileInitials userName={name} size="small" />}
        label={name}
        onEdit={onRemoveClick ? this.handleRemoveClick : undefined}
        showLabelOnTooltip={false}
      />
    );
  }
}

UserItem.propTypes = {
  userEmail: PropTypes.string,

  userId: PropTypes.number,
  userName: PropTypes.string,
  userImage: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onClick: PropTypes.func,
};

export default UserItem;
