import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import TextInput from 'inputs/components/TextInput';
import { toast } from 'notifications/components/NotificationCenter';
import { MENTORSHIP_REQUEST_KIND_SESSION_REQUEST } from 'program/constants';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { FormFieldGroup } from 'shared/components/Form';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';

const ModalContent = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;

const RequestSessionModal = ({ handleClose, host, program }) => {
  const [note, setNote] = useState('');
  const { trackActivity } = useMetrics();

  const [requestMentorshipSession, { status: requestMentorshipSessionStatus }] = useEntities(
    actions.mentorshipRequest.create,
    ({ status, error }) => {
      if (status === STATUS_DONE) {
        toast.success('Session request sent!', `${host.name} will be notified of your request.`);
        handleClose();
        trackActivity(METRICS_ACTIVITIES.MENTORSHIP_SESSION_REQUEST, {
          mentorId: host.id,
          messageLength: note ? note.length : 0,
        });
      } else if (status === STATUS_ERROR) {
        toast.error('Request failed!', error?.detail);
      }
    }
  );

  const handleSessionRequest = () => {
    requestMentorshipSession({
      note,
      receiver_id: host.id,
      kind: MENTORSHIP_REQUEST_KIND_SESSION_REQUEST,
      program_id: program?.id,
    });
  };

  return (
    <Modal title={`Request a session with ${host.name}`} handleClose={handleClose} width={500}>
      <ModalBody>
        <ModalContent>
          <Text size="h4">
            We will notify {host.name} of your request, so they can book a{' '}
            {program ? `${program.name} ` : ''}session with you, once they are available.
          </Text>
          <FormFieldGroup>
            <TextInput
              label="Note"
              multiline
              rows={4}
              inputProps={{
                id: 'mentorship_request_note',
                name: 'mentorship_request_note',
                'aria-label': 'Note',
                maxLength: 255,
                value: note,
                onChange: (event) => setNote(event.target.value),
              }}
              placeholder={`Let ${host.name} know why you would like a session with them, and what time works best for you.`}
              helperText={`${note.length}/255 characters`}
            />
          </FormFieldGroup>
        </ModalContent>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          onClick={handleSessionRequest}
          disabled={requestMentorshipSessionStatus === STATUS_LOADING}
        >
          Send Request
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

RequestSessionModal.propTypes = {
  host: PropTypes.object,
  handleClose: PropTypes.func,
  program: PropTypes.object,
};

export default RequestSessionModal;
