import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { UserAvatar } from 'shared';
import DropDownMenu from 'shared/components/DropDownMenu';
import Pill from 'shared/components/Pill';
import { Item, Section, TextContainer } from 'shared/components/SurveyItem';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID, useCopyToClipboard } from 'shared/hooks';
import {
  getSurveyExportResponsesCsvUrl,
  parseCutoffInfo,
  parseScheduleInfo,
} from 'surveys/services';
import { map } from 'vendor/lodash';

export const SurveyModalItem = ({ surveyRelationship, isLast }) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const titleTooltip = useTooltipUID();

  const {
    survey: { title },
    user_survey_count: userSurveyCount,
  } = surveyRelationship;

  const { copyToClipboard } = useCopyToClipboard(5000);

  const copyURL = () => {
    const { survey_link: surveyLink } = surveyRelationship;
    copyToClipboard(surveyLink);
    toast.success('Survey URL copied successfully!');
  };

  return (
    <Item>
      <Section>
        <TextContainer>
          <Link
            target="_blank"
            to={mapRoute('surveyPreview', { id: `${surveyRelationship.survey_id}` })}
          >
            <Text
              size="h5"
              color={colors.neutral900}
              medium
              ellipsisOnOverflow
              ref={nodeRef}
              {...titleTooltip.targetProps}
            >
              {title}
            </Text>
            <Tooltip id={titleTooltip.uid} hide={!hasEllipsis}>
              {title}
            </Tooltip>
          </Link>
        </TextContainer>
        <DropDownMenu
          icon="elipsis"
          fontSize={20}
          buttonAriaLabel={`Actions for ${title}`}
          isLast={isLast}
        >
          {!surveyRelationship.event_type && (
            <DropDownMenu.Item title="Copy Link to Survey" icon="link" onClick={copyURL} />
          )}
          <DropDownMenu.Item
            title="Preview Survey"
            target="_blank"
            route={mapRoute('surveyPreview', { id: `${surveyRelationship.survey_id}` })}
            icon="view"
          />
          <DropDownMenu.Item
            title="Manage Survey"
            icon="external"
            target="_blank"
            route={mapRoute('dashboardSurveyInstances', {
              id: `${surveyRelationship.survey_id}`,
            })}
          />

          {userSurveyCount > 0 && (
            <>
              <DropDownMenu.Separator />
              <DropDownMenu.Item
                title={`Export ${userSurveyCount === 1 ? 'Response' : 'Responses'}`}
                icon="download"
                url={getSurveyExportResponsesCsvUrl({
                  survey_relationship_id: surveyRelationship.id,
                })}
                disabled={userSurveyCount === 0}
              />
            </>
          )}
        </DropDownMenu>
      </Section>

      <Section>
        <Pill
          icon="clock-filled"
          variant="lighterGrayWithDarkerTextColor"
          label={`Opens ${parseScheduleInfo(surveyRelationship)}`}
        />
        <Pill
          icon="clock-filled"
          variant="lighterGrayWithDarkerTextColor"
          label={`Closes ${parseCutoffInfo(surveyRelationship)} after opening`}
        />
      </Section>

      <Section>
        <Pill
          variant="lighterGrayWithDarkerTextColor"
          label={
            userSurveyCount === 0
              ? 'No responses'
              : `${userSurveyCount} ${userSurveyCount === 1 ? 'response' : 'responses'}`
          }
        />
        {map(surveyRelationship.last_five_respondent_users, (user) => (
          <UserAvatar user={user} size="small" showPopover sendEmailCTA />
        ))}{' '}
        {userSurveyCount > 5 && (
          <Pill
            variant="lighterGrayWithDarkerTextColor"
            size="medium"
            label={`+${userSurveyCount - 5}`}
          />
        )}
      </Section>
    </Item>
  );
};

SurveyModalItem.propTypes = {
  attendeesCount: PropTypes.number,
  surveyRelationship: PropTypes.object,
  isLast: PropTypes.bool,
  assignmentId: PropTypes.number,
  contentType: PropTypes.string,
};

export default SurveyModalItem;
