import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EventCover from 'event-shared/components/EventCover';
import EventDateLocation from 'event-shared/components/EventDateLocation';
import { getEventInterval } from 'event-shared/services';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import CardAvatar from 'shared-cards/components/CardAvatar';
import CardCreatorName from 'shared-cards/components/CardCreatorName';
import Text from 'shared/components/Text';
import { isNil, isEmpty } from 'vendor/lodash';

const AttendeesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  white-space: nowrap;
  height: 60px;
`;

const GoingCountContainer = styled.div`
  float: right;
  width: 100px;
  text-align: center;
`;

const SpeakerInfo = styled.div`
  position: relative;
  height: 112px;
  padding: 0 12px;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const StyledCardAvatar = styled(CardAvatar)`
  flex: 0 0 112px;
`;

const StyledCardCreatorName = styled(CardCreatorName)`
  height: fit-content;
  margin-left: 10px;
  font-size: 20px;
  background-color: ${colors.emphasis600};
  color: ${colors.emphasis600TextColor};
`;

const presenterImageSize = 112;
const borderRadius = 5;

const Wrapper = styled.div`
  width: auto;
  height: auto;

  ${SpeakerInfo} {
    position: relative;
    height: ${presenterImageSize}px;
    padding: 0 12px;
    bottom: 0;

    ${StyledCardAvatar} {
      height: ${presenterImageSize}px;
      width: ${presenterImageSize}px;
    }
  }
`;

const EventInfo = styled.div`
  background-color: ${colors.neutral0};
  padding-top: ${presenterImageSize / 2 + 15}px;
  border-radius: ${borderRadius}px;
  margin-top: -56px;

  ${EventDateLocation.Location} {
    font-size: 1.5em;
    max-height: 33px;
  }
  ${EventDateLocation.MonthDaysDisplay.Day} {
    width: 50px;
    height: 50px;
    line-height: inherit;
  }

  ${EventDateLocation.MonthDaysDisplay.Wrapper} {
    font-size: 2em;
  }

  ${EventCover.Image} {
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
  }
`;

const LinkStyled = styled.a`
  color: inherit;
`;

const EventTitleWrapper = styled.h1`
  margin: 0 10px;
  height: 150px;
  overflow: hidden;
`;
const EventTitle = styled(Text)`
  font-size: 40px;
`;

export const PosterCard = ({ event }) => {
  if (isEmpty(event)) return null;

  const interval = getEventInterval(event);
  if (isNil(interval)) return null;

  const { startsAt, endsAt, timezone } = interval;

  return (
    <Wrapper>
      <SpeakerInfo>
        <StyledCardAvatar size="extra-large" creators={event.presenters} />
        <StyledCardCreatorName
          singleCreatorTitle="Presenter"
          multipleCreatorsTitle="Presenters"
          creators={event.presenters}
        />
      </SpeakerInfo>
      <EventInfo>
        <LinkStyled
          href={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
        >
          <EventTitleWrapper>
            <EventTitle ellipsisOnLine={3}> {event.name}</EventTitle>
          </EventTitleWrapper>
        </LinkStyled>
        <EventDateLocation
          timeslots={event.timeslots}
          eventStartsAt={startsAt}
          eventEndsAt={endsAt}
          displayTimezone={timezone}
          locationName={event.location_name}
        />
        <AttendeesContainer>
          <GoingCountContainer>
            <span>{event.going_local_enrollments_count} enrolled</span>
          </GoingCountContainer>
        </AttendeesContainer>
        <EventCover
          eventPublicIdAndSlug={event.public_id_and_slug}
          eventCover={event.cover}
          eventType={event.event_type}
          isOnline={event.is_online}
        />
      </EventInfo>
    </Wrapper>
  );
};

PosterCard.propTypes = {
  event: PropTypes.object,
};

export default PosterCard;
