import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ImageField from 'inputs/components/ImageField';
import { isImage } from 'services/embed';
import InfoText from 'shared/components/InfoText';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';

const ImageFieldContainer = styled.div`
  display: flex;
`;

const ImageModal = (props) => {
  const { handleClose, handleInsertClick, handleUploadImage, uploadedImageUrl, canRender } = props;

  const handleSubmit = (target) => {
    const { value } = target;
    handleInsertClick(value);
  };

  return (
    <Modal handleClose={handleClose} title="Upload Image">
      <ModalBody>
        <ImageFieldContainer>
          <ImageField
            input={{
              onChange: handleUploadImage,
              value: canRender && isImage(uploadedImageUrl) ? uploadedImageUrl : null,
            }}
            name="image"
            filePath="article_images"
            imageHeight="175px"
            emptyMessage="No image selected"
            allowsInputUpdate
          />
        </ImageFieldContainer>
        <InfoText top={8} fontSize={12} content="Supported formats: JPG, PNG, GIF" />
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error" size="large">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} size="large" disabled={!uploadedImageUrl}>
          Upload
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ImageModal.propTypes = {
  handleClose: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleInsertClick: PropTypes.func,
  uploadedImageUrl: PropTypes.string,
  canRender: PropTypes.bool,
};

export default ImageModal;
