import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import InputLabel from 'inputs/components/InputLabel';
import SelectField from 'inputs/components/SelectField';
import SurveySelectField from 'inputs/components/SurveySelectField';
import TextField from 'inputs/components/TextField';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import Pill from 'shared/components/Pill';
import { required } from 'shared/formValidations';
import RemoveSurveyWithResponsesModal from 'surveys/components/NewSurvey/RemoveSurveyWithResponsesModal';
import * as constants from 'surveys/constants';
import { isNil } from 'vendor/lodash';

const fieldRequired = required();

const FieldGroupWrapper = styled.div`
  margin-bottom: 16px;
`;

const SurveyOpenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SurveyCloseWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  > button {
    margin-left: auto;
  }
`;

const SurveyFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
`;

const StyledPill = styled(Pill)`
  margin-right: 20px;
`;

const ValueFieldWrapper = styled.div`
  flex-basis: 10%;
  margin-right: 8px;

  > div {
    margin-top: 4px;
  }
`;

const SelectFieldWrapper = styled.div`
  flex-basis: 44%;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const NewSurveyBoxContent = ({
  name,
  survey,
  surveyRelationship,
  assignmentsCount,
  handleOnChange,
  handleOnRemove,
  matterRelationOptions,
  isEditing,
}) => {
  const [showRemoveWithResponsesConfirmation, setShowRemoveWithResponsesConfirmation] =
    useState(false);
  const surveyResponsesCount = surveyRelationship.user_survey_count;
  const hasResponses = surveyResponsesCount > 0;

  return (
    <>
      <FieldGroupWrapper>
        <Field
          name={`${name}.survey_id`}
          label="Survey Template"
          component={SurveySelectField}
          validate={fieldRequired}
          value={survey?.id}
          onChange={handleOnChange}
        />
        {survey && (
          <InfoBox
            content={`Survey template used in ${survey.relationships_count} different items`}
            actionText="Preview survey"
            route={mapRoute('surveyPreview', { id: survey.id })}
            target="_blank"
            hideIcon
          />
        )}
      </FieldGroupWrapper>
      <FieldGroupWrapper>
        <InputLabel>Survey Opens</InputLabel>
        <InfoText
          top={2}
          bottom={12}
          content="When your survey is going to be automatically sent by email to people enrolled."
        />
        <SurveyOpenWrapper>
          <ValueFieldWrapper>
            <Field
              name={`${name}.survey_schedule_period_value`}
              component={TextField}
              type="number"
              inputProps={{
                min: 0,
              }}
              validate={fieldRequired}
            />
          </ValueFieldWrapper>
          <SelectFieldWrapper>
            <Field
              name={`${name}.survey_schedule_period_option`}
              component={SelectField}
              options={constants.periodOptions}
              // ariaLabel="Option"
            />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <Field
              name={`${name}.survey_schedule_kind`}
              component={SelectField}
              options={matterRelationOptions}
            />
          </SelectFieldWrapper>
        </SurveyOpenWrapper>
        <InfoText content="Up to 15-minute delay" top={4} />
      </FieldGroupWrapper>
      <FieldGroupWrapper>
        <InputLabel>Survey Closes</InputLabel>
        <InfoText
          top={2}
          bottom={12}
          content="How long this survey is going to be available to respond after it opens."
        />
        <SurveyCloseWrapper>
          <ValueFieldWrapper>
            <Field
              name={`${name}.survey_cutoff_period_value`}
              component={TextField}
              type="number"
              inputProps={{
                min: 1,
              }}
              validate={fieldRequired}
            />
          </ValueFieldWrapper>
          <SelectFieldWrapper>
            <Field
              name={`${name}.survey_cutoff_period_option`}
              component={SelectField}
              options={constants.periodOptions}
            />
          </SelectFieldWrapper>
        </SurveyCloseWrapper>
        <SurveyFooter>
          {isEditing && !isNil(surveyResponsesCount) && (
            <StyledPill
              label={`${
                surveyResponsesCount === 0 ? 'No' : `${surveyResponsesCount}/${assignmentsCount}`
              } ${assignmentsCount === 1 ? 'response' : 'responses'}`}
              lighterGrayWithDarkerTextColor
            />
          )}
          <Button
            size="small"
            type="button"
            onClick={
              hasResponses ? () => setShowRemoveWithResponsesConfirmation(true) : handleOnRemove
            }
            color="error"
          >
            {hasResponses ? 'Remove & Delete Responses' : 'Remove Survey'}
          </Button>
        </SurveyFooter>
        {showRemoveWithResponsesConfirmation && (
          <RemoveSurveyWithResponsesModal
            responsesCount={surveyResponsesCount}
            handleClose={() => setShowRemoveWithResponsesConfirmation(false)}
            handleRemove={handleOnRemove}
          />
        )}
      </FieldGroupWrapper>
    </>
  );
};

NewSurveyBoxContent.propTypes = {
  name: PropTypes.string,
  survey: PropTypes.object,
  surveyRelationship: PropTypes.object,
  handleOnChange: PropTypes.func,
  handleOnRemove: PropTypes.func,
  assignmentsCount: PropTypes.number,
  matterRelationOptions: PropTypes.array,
  isEditing: PropTypes.bool,
};

export default NewSurveyBoxContent;
