import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';

import { ENROLLMENT_STATUS_GOING_ONLINE } from 'app/event-shared/constants';
import {
  ASSIGN_CONTENT_ITEM_TO_USER,
  ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK,
  OPERATION_OPTIONS,
  OPERATION_PARAMETERS_MAP,
  OVERCAPACITY_POLICY_FORCE_ENROLL,
  TIME_TO_COMPLETE_INHERIT,
} from 'automated-rule/constants';
import { useOperationInfo } from 'automated-rule/hooks';
import SelectField from 'inputs/components/SelectField';
import { FormFieldGroup } from 'shared/components/Form';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import Form from 'shared/components/OldForm';
import { useToggles, useFormSelector } from 'shared/hooks';
import { includes, sortBy, filter } from 'vendor/lodash';

import ParametersFields from './ParametersFields';

const validateRequired = Form.validations.required();

const getActionOptions = (toggleScheduledTracks) => {
  if (toggleScheduledTracks) return OPERATION_OPTIONS;
  return filter(
    OPERATION_OPTIONS,
    (option) => option.value !== ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK
  );
};

export const ActionSection = ({ form, change }) => {
  const { toggle_scheduled_tracks: toggleScheduledTracks } = useToggles();
  const initialOperation = useFormSelector(form, 'operation');
  const initialTimeToComplete = useFormSelector(form, 'time_to_complete');
  const initialAttendanceType = useFormSelector(form, 'attendance_type');
  const initialPreferredAttendanceType = useFormSelector(form, 'preferred_attendance_type');
  const initialOvercapacityPolicy = useFormSelector(form, 'overcapacity_policy');

  const [selectedOperation, setSelectedOperation] = useState('');
  const operationInfo = useOperationInfo(selectedOperation);

  useEffect(() => {
    if (initialOperation) setSelectedOperation(initialOperation);
  }, [initialOperation]);

  useEffect(() => {
    if (selectedOperation === ASSIGN_CONTENT_ITEM_TO_USER) {
      // Only change the time_to_complete if it's the first time rendering the field
      if (!initialTimeToComplete) change('time_to_complete', TIME_TO_COMPLETE_INHERIT);
      return;
    }
    change('time_to_complete', '');
  }, [selectedOperation]);

  useEffect(() => {
    if (
      includes(OPERATION_PARAMETERS_MAP[selectedOperation], 'attendance_type') &&
      !initialAttendanceType
    ) {
      // Set a default value if the field value starts empty
      change('attendance_type', ENROLLMENT_STATUS_GOING_ONLINE);
    }

    if (
      includes(OPERATION_PARAMETERS_MAP[selectedOperation], 'preferred_attendance_type') &&
      !initialPreferredAttendanceType
    ) {
      // Set a default value if the field value starts empty
      change('preferred_attendance_type', ENROLLMENT_STATUS_GOING_ONLINE);
    }

    if (
      includes(OPERATION_PARAMETERS_MAP[selectedOperation], 'overcapacity_policy') &&
      !initialOvercapacityPolicy
    ) {
      // Set a default value if the field value starts empty
      change('overcapacity_policy', OVERCAPACITY_POLICY_FORCE_ENROLL);
    }

    if (
      includes(
        OPERATION_PARAMETERS_MAP[selectedOperation],
        'scheduled_track_overcapacity_policy'
      ) &&
      !initialOvercapacityPolicy
    ) {
      // Set a default value if the field value starts empty
      change('scheduled_track_overcapacity_policy', OVERCAPACITY_POLICY_FORCE_ENROLL);
    }
  }, [selectedOperation]);

  return (
    <>
      <FormFieldGroup>
        <Field
          name="operation"
          label="Action"
          required
          component={SelectField}
          options={sortBy(getActionOptions(toggleScheduledTracks), ['label'])}
          validate={[validateRequired]}
          onChange={(value) => setSelectedOperation(value)}
        />
        <InfoText content="Select the action to take for this rule." top={4} />
        {selectedOperation && <InfoBox content={operationInfo} hideIcon />}
      </FormFieldGroup>
      {selectedOperation && <ParametersFields form={form} operation={selectedOperation} />}
    </>
  );
};

ActionSection.propTypes = {
  form: PropTypes.string,
  // Redux form props
  change: PropTypes.func,
};
