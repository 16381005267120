import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';

import OldFilterHeaderActionArea from './OldFilterHeaderActionArea';
import OldFilterPageSearch from './OldFilterPageSearch';

export const FilterPageTitle = styled.span`
  position: relative;
  float: left;
  color: ${colors.emphasis600};
  font-size: 24px;
  padding: 0;
  margin-top: 0;
  margin-right: 24px;
`;

const FilterPageHeaderWrapper = styled.header`
  padding: 26px 24px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${colors.neutral100};
`;

export const OldFilterPageHeader = ({
  title,
  searchParam,
  searchPlaceholder,
  searchOnChange,
  selecteds,
  actions,
}) => (
  <FilterPageHeaderWrapper>
    <FilterPageTitle>{title}</FilterPageTitle>
    <OldFilterPageSearch
      param={searchParam || 'q'}
      placeholder={searchPlaceholder}
      onChange={searchOnChange}
      selecteds={selecteds}
    />
    <OldFilterHeaderActionArea>{actions}</OldFilterHeaderActionArea>
  </FilterPageHeaderWrapper>
);

OldFilterPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  searchParam: PropTypes.string,
  searchPlaceholder: PropTypes.string.isRequired,
  searchOnChange: PropTypes.func.isRequired,
  selecteds: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.node),
};

export default OldFilterPageHeader;
