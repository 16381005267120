import { useCallback, useState } from 'react';
import { ActionFunction } from 'redux-actions';

import { getErrorMessage } from 'assignments/hooks';
import { getBulkAsyncJobErrors } from 'assignments/services';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { ApiURLs } from 'services/requests-base';
import { ActionCallbackProps } from 'shared/components/types';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';
import { useCopyToClipboard, useCurrentUser } from 'shared/hooks';
import { get, size, map, toLower, join, replace, noop, isNil } from 'vendor/lodash';
import pluralize from 'vendor/pluralize';
import rql from 'vendor/rql';

import { Exporter } from './components/AssignmentsRoster/types';

interface NormalizedSelectedData {
  total: number;
  expression: string;
}

// This is useful when the component has a "Copied" state
// https://github.com/wsmd/use-clipboard-copy#displaying-a-temporary-success-state
const CLIPBOARD_TIMEOUT_IN_MS = 2000;

/***
There are two scenarios when selecting items from the roster:
  - The user selects one or more items.
  - The user selects all items.
In the first scenario we need to use the search expression
plus the IDs of the selected users, in the second scenario
we only need the expression used in the search.
This function handles it, returning the total of items
selected and the search expression adapted to the scenario.
***/
export const getNormalizedSelectedData = (
  selectedData?: ActionCallbackProps,
  idKey = 'pk'
): NormalizedSelectedData => {
  if (isNil(selectedData)) return { total: 0, expression: '' };
  const selectedItems = get(selectedData, 'selectedItems');
  const selectAll = get(selectedData, 'selectAll', false);
  const [total, expression] =
    size(selectedItems) > 0 && !selectAll
      ? [
          size(selectedItems),
          `${selectedData.expression}&${rql({
            [idKey]: { $in: map(selectedItems, (item) => get(item, 'id', 0)) },
          })}`,
        ]
      : [selectedData.rowCount, selectedData.expression];
  return { total, expression };
};

interface CreatePostAssignCallbackProps {
  total: number;
  assignmentLabel: string;
  handleBack: () => void;
}

type PostAssignCallback = ({ status, data, error }) => void;
/*
The rosters bulk create assignment operations accept a function
to be executed when the bulk operation ends successfully, this
function creates a callback that can be used for this operation
that is adapted to the kind of assignment. For example, in
Scheduled Track and Event, Enrollment is used instead of Assignment.
* */
export const createPostAssignCallback = ({
  total,
  assignmentLabel,
  handleBack,
}: CreatePostAssignCallbackProps): PostAssignCallback => {
  return ({ status, data, error }) => {
    if (status === STATUS_DONE && size(getBulkAsyncJobErrors(data)) === 0) {
      toast.success(`${total} ${pluralize(toLower(assignmentLabel), total)} created`);
      handleBack();
    }

    if (status === STATUS_ERROR) {
      const errorMessage = getErrorMessage(error);
      toast.error(
        'Oops. We failed to schedule your request for processing. Please try again.',
        errorMessage
      );
    }
  };
};

const removePagination = (expression: string): string => {
  return replace(replace(expression, /(&?)page=(\d*)/g, ''), /(&?)page_size=(\d*)/g, '');
};

const generatRqlExpression = ({
  selectedItems,
  expression,
  selectAll,
}: {
  selectedItems: any[];
  expression: string;
  selectAll: boolean;
}): string => {
  return selectAll
    ? expression
    : `${expression}&${rql({
        pk: { $in: map(selectedItems, (item) => item.id) },
      })}`;
};

export const useCopyEmailsToClipboard = (
  action: ActionFunction<any>,
  defaultFetchItemsOptions: object
) => {
  const [emails, setEmails] = useState<string[]>([]);
  const { copyToClipboard } = useCopyToClipboard(CLIPBOARD_TIMEOUT_IN_MS);
  const { copy_emails_default_separator: copyEmailsDefaultSeparator } = useCurrentUser();

  const [fetchEmails] = useEntities(action, ({ status, data }) => {
    if (status === STATUS_DONE) {
      const results = get(data, 'results', []);
      const allEmails = [...emails, ...results];

      if (get(data, 'next') === null) {
        const count = size(allEmails);
        const emailsStr = join(allEmails, copyEmailsDefaultSeparator);
        copyToClipboard(emailsStr);
        toast.success(`${count} email${count > 1 ? 's' : ''} copied`);
        setEmails([]);
      } else {
        setEmails(allEmails);
      }
    }
  });
  const copyEmailsToClipboard = useCallback(
    (expression: string) => {
      setEmails([]);
      fetchEmails(`${removePagination(expression)}&page_size=1000`, { fetchAll: true });
    },
    [fetchEmails]
  );
  const copyEmailsActionCallback = useCallback(
    ({ selectedItems, expression, selectAll }: ActionCallbackProps) => {
      const rql_expression = generatRqlExpression({ selectedItems, expression, selectAll });
      copyEmailsToClipboard(`${rql_expression}&${rql(defaultFetchItemsOptions)}`);
    },
    [copyEmailsToClipboard, defaultFetchItemsOptions]
  );
  return { emails, copyToClipboard, copyEmailsToClipboard, copyEmailsActionCallback };
};

export const useExportCSVActionCallback = (
  endpointName: string,
  defaultFetchItemsOptions: object,
  exporter: Exporter
) => {
  const exportCSVActionCallback = useCallback(
    ({ selectedItems, expression, selectAll }: ActionCallbackProps) => {
      const rql_expression = generatRqlExpression({ selectedItems, expression, selectAll });
      const baseUrl = ApiURLs[endpointName]();
      const url = `${baseUrl}?${rql_expression}&${rql(
        defaultFetchItemsOptions
      )}&exporter=${exporter}`;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_self';
      link.click();
    },
    [defaultFetchItemsOptions, endpointName, exporter]
  );
  return { exportCSVActionCallback };
};

interface CreatePostActionCallbackProps {
  label: string;
  action?: string;
  total: number;
  setErrors: (errors: any[]) => void;
  onSuccess?: () => void;
  onFinished?: () => void;
}

export const createPostActionCallback =
  ({
    label,
    action = 'updated',
    total,
    setErrors,
    onSuccess = noop,
    onFinished = noop,
  }: CreatePostActionCallbackProps) =>
  ({ status, data, error }) => {
    if (status === STATUS_DONE) {
      const errors = getBulkAsyncJobErrors(data);
      setErrors(errors);
      if (size(errors) === 0) {
        toast.success(`${pluralize(label, total, true)} ${action}.`);
        onSuccess();
      }
      onFinished();
    }
    if (status === STATUS_ERROR) {
      const errorMessage = getErrorMessage(error);
      toast.error(
        'Oops. We failed to schedule your request for processing. Please try again.',
        errorMessage
      );
      onFinished();
    }
  };
