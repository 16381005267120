import { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'app/shared/components/Loading';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from 'app/shared/constants';
import { useCurrentUser } from 'app/shared/hooks';
import { IdParam } from 'common/types';
import actions from 'entities/actions';
import { testimonialSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import { includes, isEmpty } from 'vendor/lodash';
import { Typography, Stack, Button, Box } from 'vendor/mui';

import { ProfileDataContext } from '../../types';

import { TestimonialsModal } from './TestimonialsModal';
import { TestimonialsQuickList } from './TestimonialsQuickList';
import { WriteTestimonialButton } from './WriteTestimonialButton';

export const TestimonialsSection = () => {
  const viewMode = 'profile_widget';
  const { id: userId } = useParams<IdParam>();
  const [isModalOpen, setModalOpen] = useState(false);
  const { display_name: receiverName, id: receiverId } = useContext(ProfileDataContext);
  const { id: currentUserId } = useCurrentUser();
  const isViewerTheOwner = receiverId === currentUserId;

  const [fetch, { status, data: testimonials, nextPage }, loadMoreTestimonials] = useEntities(
    actions.testimonial.retrieveList,
    null,
    {
      schema: [testimonialSchema],
      loadMoreAction: actions.testimonial.retrieveListLoadMore,
    }
  );

  const loadProfileUserTestimonials = useCallback(() => {
    fetch({
      receiver: userId,
      view_mode: viewMode,
      page_size: 14,
      o: '-created',
    });
  }, [fetch, userId]);

  useEffect(() => {
    loadProfileUserTestimonials();
  }, [loadProfileUserTestimonials]);

  const ReadMoreButton = () => {
    return (
      <Button
        sx={{ padding: 0, marginTop: -1.5 }}
        onClick={() => setModalOpen(true)}
        variant="text"
      >
        Read More
      </Button>
    );
  };

  const loading = includes([STATUS_NOT_REQUESTED, STATUS_LOADING], status);

  // prettier-ignore
  const emptyPhrase = isViewerTheOwner
    ? 'You don\'t have any testimonials.'
    : `${receiverName} doesn't have any testimonials.`;

  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h6" gutterBottom data-testid="testimonials_title_typography">
        Testimonials
      </Typography>
      {loading ? (
        <Loading />
      ) : isEmpty(testimonials) ? (
        <>
          <Typography
            variant="body1"
            color={colors.neutral400}
            data-testid="testimonials_empty_phrase"
          >
            {emptyPhrase}
          </Typography>
          {!isViewerTheOwner && (
            <WriteTestimonialButton
              receiverId={receiverId}
              receiverName={receiverName}
              onSuccessCallback={loadProfileUserTestimonials}
            />
          )}
        </>
      ) : (
        <>
          <TestimonialsQuickList testimonials={testimonials} Appendix={ReadMoreButton} />
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              sx={{ width: 155, backgroundColor: colors.neutral50, color: colors.action700 }}
              onClick={() => setModalOpen(true)}
              data-testid="testimonials_view_all_button"
            >
              View all testimonials
            </Button>
          </Box>
          {isModalOpen && (
            <TestimonialsModal
              handleClose={() => setModalOpen(false)}
              reloadTestimonials={loadProfileUserTestimonials}
              {...{ receiverName, testimonials, receiverId, loadMoreTestimonials, nextPage }}
            />
          )}
        </>
      )}
    </Stack>
  );
};
