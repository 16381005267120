import type { ValueOf } from 'type-fest';

import Dashboard from 'backoffice/components/Dashboard';
import { LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import { useLabels } from 'shared/hooks';
import { Stack, Typography } from 'vendor/mui';

interface BulkDeleteContentItemConfirmationModalProps {
  showDeleteModal: boolean;
  handleCloseDeleteModal: () => void;
  handleConfirmDelete: () => void;
  selectedItemsCount: number;
  learningType: ValueOf<typeof LEARNING_TYPES>;
}

const BulkDeleteContentItemConfirmationModal = ({
  showDeleteModal,
  handleCloseDeleteModal,
  handleConfirmDelete,
  selectedItemsCount,
  learningType,
}: BulkDeleteContentItemConfirmationModalProps) => {
  const learningTypeLabelSingular = useLearningTypeLabels()[learningType];
  const learningTypeLabelPlural = useLearningTypeLabels({ isPlural: true })[learningType];
  const { label_track: labelTrack } = useLabels();

  return (
    <Dashboard.ConfirmationModal
      open={showDeleteModal}
      handleClose={handleCloseDeleteModal}
      onConfirmClick={handleConfirmDelete}
      confirmText={`Delete ${learningTypeLabelPlural}`}
      deleteModalTitle={`Delete ${learningTypeLabelPlural}`}
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          You are about to delete{' '}
          <b>
            {selectedItemsCount}{' '}
            {selectedItemsCount === 1 ? learningTypeLabelSingular : learningTypeLabelPlural}
          </b>
          .
        </Typography>

        <Typography variant="body2">
          When an {learningTypeLabelSingular} is removed, all references to that{' '}
          {learningTypeLabelSingular} are also removed, such as {labelTrack} associations and all
          outstanding assignments.
        </Typography>

        <Typography variant="body2">
          Are you sure you want to proceed? This action cannot be undone.
        </Typography>
      </Stack>
    </Dashboard.ConfirmationModal>
  );
};

export default BulkDeleteContentItemConfirmationModal;
