import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import {
  articleSchema,
  assignmentSchema,
  codelabSchema,
  contentSchema,
  courseSchema,
  eventTypeSchema,
  taskSchema,
  videoSchema,
} from 'entities/schema';
import { useEntities } from 'entities/utils';
import { ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE } from 'event-shared/constants';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import { ContentItem } from 'shared-content-item/interfaces';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { get, includes, isNil, noop } from 'vendor/lodash';

type EventEnrollmentOptions = {
  [eventPublicId: string]: typeof ENROLLMENT_STATUS_GOING | typeof ENROLLMENT_STATUS_GOING_ONLINE;
};

interface CreateBody {
  is_completed?: boolean;
  is_exempted?: boolean;
  event_enrollment_options?: EventEnrollmentOptions;
  reason?: string;
  is_reassign?: boolean;
}

interface UpdateBody {
  due_at?: string;
  expires_at?: string;
  is_watched?: boolean;
}

interface DropBody {
  force?: boolean;
  reason?: string;
}

interface RateBody {
  rating: number;
  feedback: string;
}

export interface AssignmentsActions {
  create: (body?: CreateBody) => void;
  createStatus: string;
  update: (body: UpdateBody) => void;
  drop: (body?: DropBody) => void;
  dropStatus: string;
  dropError: string;
  exempt: () => void;
  undoExemption: () => void;
  complete: (body?: RateBody) => void;
  undoCompletion: () => void;
  rate: (body: RateBody) => void;
  rateStatus: string;
  acknowledgeCompletion: () => void;
  isLoading: boolean;
}

export const getErrorMessage = (error: any): string => {
  // if it's an application error from the old code, it will be an object with the error key.
  // if it's an application error from the CA-2.0, it will be an object with the detail key.
  // if the error is a data validation, it will be an array.
  // As CA-2.0 is a WIP, it can be changed, so the error message handle it is centralized here.
  return (
    get(error, 'error', null) || // Old aplication error
    get(error, 'detail', null) || // CA2.0 aplication error
    get(error, 0, null) || // Data validation can be a array
    get(error, 'non_field_errors.[0]', null) // or a non_field_errors key with an array
  );
};

const getContentSchema = (content: ContentItem) => {
  const schemas = {
    [CONTENT_TYPES.article]: articleSchema,
    [CONTENT_TYPES.assessment]: contentSchema,
    [CONTENT_TYPES.linkedcontent]: contentSchema,
    [CONTENT_TYPES.codelab]: codelabSchema,
    [CONTENT_TYPES.course]: courseSchema,
    [CONTENT_TYPES.eventtype]: eventTypeSchema,
    [CONTENT_TYPES.scheduled_track]: contentSchema,
    [CONTENT_TYPES.task]: taskSchema,
    [CONTENT_TYPES.track]: contentSchema,
    [CONTENT_TYPES.video]: videoSchema,
  };
  return get(schemas, content.content_type, null);
};

export const useAssignmentsActions = (
  // The content is only necessary to create assignments, it is not necessary for the other assignments' actions.
  content?: ContentItem,
  assignmentId?: number,
  postActionCallback?: (id?: number, filters?: Record<string, any> | string) => void,
  view_mode?: string
): AssignmentsActions => {
  // It is expected that the assignment will be updated after each action, even if
  // an error occurs because the cause of the error may be the outdated information.
  // E.g., if an organizer marks an assignment as complete while the user still has
  // the content page open, the option to mark it as complete will still be available
  // to the user, and if the user tries to mark the content as complete, an error will
  // be raised, but right after the information will be updated, then the application
  // will show the correct information.

  const retrieveViewMode = view_mode ?? 'default';

  const [create, { status: createStatus }] = useEntities(
    actions.assignment.createSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to create the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [retrieve, { status: retrieveStatus }] = useEntities(
    actions.assignment.retrieve,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to retrieve the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const queryClient = useQueryClient();

  const handleRefreshContentAssignment = (assignmentId: number) => {
    retrieve(assignmentId, { view_mode: retrieveViewMode });

    const contentPublicId = get(content, 'public_id');
    if (contentPublicId) {
      queryClient.invalidateQueries({
        queryKey: queries.content_items.details(contentPublicId).queryKey,
      });
    }
  };

  const handlePostAction = (assignmentId?: number) => {
    if (assignmentId) {
      handleRefreshContentAssignment(assignmentId);
    }

    postActionCallback?.(assignmentId, { view_mode: retrieveViewMode });
  };

  const [update, { status: updateStatus }] = useEntities(
    actions.assignment.updateSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to update the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [drop, { status: dropStatus, error: dropError }] = useEntities(
    actions.assignment.dropSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to drop the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [exempt, { status: exemptStatus }] = useEntities(
    actions.assignment.exemptionSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to exempt the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [undoExemption, { status: undoExemptionStatus }] = useEntities(
    actions.assignment.exemptionRemove,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to undo the exemption of the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [complete, { status: completeStatus }] = useEntities(
    actions.assignment.completionSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to complete the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [undoCompletion, { status: undoCompletionStatus }] = useEntities(
    actions.assignment.completionRemove,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to undo the completion of the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [rate, { status: rateStatus }] = useEntities(
    actions.assignment.ratingSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to complete the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const [acknowledgeCompletion, { status: acknowledgeCompletionStatus }] = useEntities(
    actions.assignment.completionAcknowledgementSubmit,
    ({ status, error }) => {
      const errorMessage = getErrorMessage(error);
      if (status === STATUS_DONE || status === STATUS_ERROR) {
        handlePostAction(assignmentId);
      }
      if (status === STATUS_ERROR && errorMessage) {
        toast.error('Failed to acknowledge the completion of the assignment', errorMessage);
      }
    },
    { schema: assignmentSchema }
  );

  const isLoading = includes(
    [
      retrieveStatus,
      createStatus,
      updateStatus,
      dropStatus,
      exemptStatus,
      undoExemptionStatus,
      completeStatus,
      undoCompletionStatus,
      rateStatus,
      acknowledgeCompletionStatus,
    ],
    STATUS_LOADING
  );

  return {
    create: isNil(content)
      ? noop
      : (body?: CreateBody) => {
          create({
            // contentId and contentSchema will not be sent to the backend,
            // check the getUpdateSideEffectSaga function for more information
            contentId: content.id,
            contentSchema: getContentSchema(content),
            content_item_id: content.id,
            is_completed: get(body, 'is_completed'),
            is_exempted: get(body, 'is_exempted'),
            event_enrollment_options: get(body, 'event_enrollment_options', null),
            reason: get(body, 'reason', ''),
            is_reassign: get(body, 'is_reassign', null),
          });
        },
    createStatus,
    update: (body: UpdateBody) => update(assignmentId, body),
    drop: (body?: DropBody) =>
      drop(assignmentId, { force: get(body, 'force', false), reason: get(body, 'reason', '') }),
    dropStatus,
    dropError,
    exempt: () => exempt(assignmentId),
    undoExemption: () => undoExemption(assignmentId),
    complete: (body?: RateBody) =>
      complete(assignmentId, {
        rating: get(body, 'rating'),
        feedback: get(body, 'feedback'),
      }),
    undoCompletion: () => undoCompletion(assignmentId),
    rate: (body: RateBody) =>
      rate(assignmentId, { rating: get(body, 'rating', 5), feedback: get(body, 'feedback', '') }),
    rateStatus,
    acknowledgeCompletion: () => acknowledgeCompletion(assignmentId),
    isLoading,
  };
};

interface AssignmentsBulkActionsProps {
  postActionCallback?: ({ status, data, error }) => void;
}

interface BulkActionBody {
  assignment_rql: string;
  reason?: string;
}

interface BulkUpdateBody extends BulkActionBody {
  due_at?: moment.Moment | null;
  expires_at?: moment.Moment | null;
}

interface BulkCreateBody {
  content_item_public_ids: string[];
  user_rql: string;
  due_at: moment.Moment | null;
  expires_at: moment.Moment | null;
  is_reassign?: boolean;
  event_enrollment_options?: EventEnrollmentOptions;
  reason?: string;
}

export const useAssignmentsBulkActions = ({
  postActionCallback = noop,
}: AssignmentsBulkActionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bulkCreate, { status: bulkCreateStatus, data: bulkCreateResponse }] = useEntities(
    actions.assignment.bulk,
    postActionCallback
  );
  const [bulkUpdate, { status: bulkUpdateStatus, data: bulkUpdateResponse }] = useEntities(
    actions.assignment.bulkUpdate,
    postActionCallback
  );
  const [bulkComplete, { status: bulkCompleteStatus, data: bulkCompleteResponse }] = useEntities(
    actions.assignment.bulkCompletion,
    postActionCallback
  );
  const [bulkUndoComplete, { status: bulkUndoCompleteStatus, data: bulkUndoCompleteResponse }] =
    useEntities(actions.assignment.bulkUndoCompletion, postActionCallback);

  const [bulkExempt, { status: bulkExemptStatus, data: bulkExemptResponse }] = useEntities(
    actions.assignment.bulkExemption,
    postActionCallback
  );

  const [bulkUndoExempt, { status: bulkUndoExemptStatus, data: bulkUndoExemptResponse }] =
    useEntities(actions.assignment.bulkUndoExemption, postActionCallback);

  const [bulkDrop, { status: bulkDropStatus, data: bulkDropResponse }] = useEntities(
    actions.assignment.bulkDrop,
    postActionCallback
  );

  const [bulkExpire, { status: bulkExpireStatus, data: bulkExpireResponse }] = useEntities(
    actions.assignment.bulkExpire,
    postActionCallback
  );

  useEffect(() => {
    setIsLoading(
      includes(
        [
          bulkCreateStatus,
          bulkUpdateStatus,
          bulkCompleteStatus,
          bulkUndoCompleteStatus,
          bulkExemptStatus,
          bulkUndoExemptStatus,
          bulkDropStatus,
          bulkExpireStatus,
        ],
        STATUS_LOADING
      )
    );
  }, [
    bulkCreateStatus,
    bulkUpdateStatus,
    bulkCompleteStatus,
    bulkUndoCompleteStatus,
    bulkExemptStatus,
    bulkUndoExemptStatus,
    bulkDropStatus,
    bulkExpireStatus,
  ]);
  // dueAt should be in date format until we migrate it: https://app.asana.com/0/inbox/1201514913686300/1204192721538903/1204198645443247
  return {
    bulkCreate: ({ due_at: dueAt, ...body }: BulkCreateBody) =>
      bulkCreate({ due_at: isNil(dueAt) ? null : dueAt.format('YYYY-MM-DD'), ...body }),
    bulkCreateStatus,
    bulkCreateResponse,
    bulkUpdate: ({ due_at: dueAt, ...body }: BulkUpdateBody) =>
      bulkUpdate({ due_at: isNil(dueAt) ? null : dueAt.format('YYYY-MM-DD'), ...body }),
    bulkUpdateStatus,
    bulkUpdateResponse,
    bulkComplete: (body: BulkActionBody) => bulkComplete(body),
    bulkCompleteStatus,
    bulkCompleteResponse,
    bulkUndoComplete: (body: BulkActionBody) => bulkUndoComplete(body),
    bulkUndoCompleteStatus,
    bulkUndoCompleteResponse,
    bulkExempt: (body: BulkActionBody) => bulkExempt(body),
    bulkExemptStatus,
    bulkExemptResponse,
    bulkUndoExempt: (body: BulkActionBody) => bulkUndoExempt(body),
    bulkUndoExemptStatus,
    bulkUndoExemptResponse,
    bulkDrop: (body: BulkActionBody) => bulkDrop(body),
    bulkDropStatus,
    bulkDropResponse,
    bulkExpire: (body: BulkActionBody) => bulkExpire(body),
    bulkExpireStatus,
    bulkExpireResponse,
    isLoading,
  };
};
