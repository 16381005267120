import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import { useGetAllCachedOptions } from 'filters/hooks';
import { PillsDefinitionProps } from 'filters/types';
import { get, map, filter, keys, sortBy, includes, concat } from 'vendor/lodash';

export const usePeopleDirectoryPillsDefinition = ({ filters }: PillsDefinitionProps) => {
  // Cached options
  const allTags = useGetAllCachedOptions({ keyPrefix: 'tags' });
  const allTitles = useGetAllCachedOptions({ keyPrefix: 'users', key: 'titles' });
  const allDepartments = useGetAllCachedOptions({ keyPrefix: 'users', key: 'departments' });
  const allLocations = useGetAllCachedOptions({ keyPrefix: 'locations', key: 'list' });
  const allGroups = useGetAllCachedOptions({ keyPrefix: 'groups', key: 'list' });
  const allUsers = useGetAllCachedOptions({ keyPrefix: 'old_users' });
  // Values
  const searchValue = get(filters, 'q.$eq', '');
  const selectedTitles = rqlToInput(get(filters, 'title'));
  const selectedDepartments = rqlToInput(get(filters, 'department'));
  const selectedLocations = rqlToInput(get(filters, 'location'));
  const selectedGroups = rqlToInput(get(filters, 'group'));
  const selectedOrgLeads = rqlToInput(get(filters, 'org_lead'));
  const selectedManagers = rqlToInput(get(filters, 'manager'));
  const selectedTeammates = rqlToInput(get(filters, 'teammate'));
  const myAudience = get(rqlToInput(get(filters, 'my_audience')), '0', null) === 'true';
  const myAttendance = get(rqlToInput(get(filters, 'my_attendance')), '0', null) === 'true';
  const myExperts = get(rqlToInput(get(filters, 'my_experts')), '0', null) === 'true';
  // Tags and flexible filters
  const otherFilters = sortBy(
    filter(
      keys(filters),
      (key: string) =>
        !includes(
          [
            'q',
            'title',
            'department',
            'location',
            'group',
            'org_lead',
            'manager',
            'teammate',
            'my_audience',
            'my_attendance',
            'my_experts',
          ],
          key
        )
    )
  );
  return {
    pills: [
      ...(searchValue
        ? [
            {
              id: `search-${searchValue}`,
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),
      ...(myAudience
        ? [
            {
              id: 'my-audience',
              value: 'true',
              label: 'My Audience',
              filterName: 'my_audience',
            },
          ]
        : []),
      ...(myAttendance
        ? [
            {
              id: 'my-attendance',
              value: 'true',
              label: 'My Attendance',
              filterName: 'my_attendance',
            },
          ]
        : []),
      ...(myExperts
        ? [
            {
              id: 'my-experts',
              value: 'true',
              label: 'My Experts',
              filterName: 'my_experts',
            },
          ]
        : []),
      ...map(selectedTitles, (value: string) => ({
        id: `title-${value}`,
        value: value,
        label: get(allTitles, value, { name: value }).name,
        icon: 'suitcase',
        filterName: 'title',
      })),
      ...map(selectedDepartments, (value: string) => ({
        id: `department-${value}`,
        value: value,
        label: get(allDepartments, value, { name: value }).name,
        icon: 'room',
        filterName: 'department',
      })),
      ...map(selectedLocations, (value: string) => ({
        id: `location-${value}`,
        value: value,
        label: get(allLocations, value, { name: value }).name,
        icon: 'location',
        filterName: 'location',
      })),
      ...map(selectedGroups, (value: string) => ({
        id: `group-${value}`,
        value: value,
        label: get(allGroups, value, { name: value }).name,
        icon: 'group',
        filterName: 'group',
      })),
      ...map(selectedOrgLeads, (value: string) => ({
        id: `org-lead-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'org_lead',
      })),
      ...map(selectedManagers, (value: string) => ({
        id: `manager-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'manager',
      })),
      ...map(selectedTeammates, (value: string) => ({
        id: `teammate-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'teammate',
      })),
      ...concat(
        [],
        ...map(otherFilters, (filterName: string) =>
          map(rqlToInput(get(filters, filterName)), (value: string) => ({
            id: `${filterName}-${value}`,
            value: value,
            label: get(allTags, value, { name: value }).name,
            icon: 'tag',
            filterName,
          }))
        )
      ),
    ],
  };
};
