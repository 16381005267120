import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as permissionConstants from 'shared/permissions';
import { UserAddButton } from 'users';

function Container(props) {
  const { permissions } = props;

  if (!permissions.includes(permissionConstants.IS_ORGANIZER_PERMISSION)) {
    return null;
  }

  return (
    <div>
      <UserAddButton />
    </div>
  );
}

Container.propTypes = {
  permissions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  permissions: state.user.currentUser.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
