import Exempted from 'assignments/components/Exempted/Exempted';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from 'catalog/constants';
import { ContentRatingActions } from 'common/components/ContentRatingActions';
import { AssignmentActionsProvider } from 'features/contentitems/components/AssignmentActionsProvider';
import { useIsRenderedWithinTrack } from 'features/tracks/hooks/useIsRenderedWithinTrack';
import DefaultPrimaryActionButton from 'shared-content-item/components/PrimaryActionButton';
import DefaultSecondaryActionButton from 'shared-content-item/components/SecondaryActionButton';
import { ContentItem } from 'shared-content-item/interfaces';
import { get, isNil } from 'vendor/lodash';
import { Stack, Box } from 'vendor/mui';

import { ContentItemHeaderTimeline } from './ContentItemHeaderTimeline';

export interface ContentItemHeaderFooterProps {
  content: ContentItem;
  isArchived?: boolean;
  primaryActionButton?: React.ComponentType<{
    content: any;
    contentType?: string;
  }>;
  secondaryActionButton?: React.ComponentType<{ content: any; contentType?: string }>;
  isPreviewMode?: boolean;
}

// The use of the default value in the component signature caused the children to re-render
// and lose the reference, resulting in modals not opening. E.g.:
// primaryActionButton: PrimaryActionButton = (props) => (<DefaultPrimaryActionButton {...props} />)
export const ContentItemHeaderFooter = (props: ContentItemHeaderFooterProps) => {
  const {
    content,
    primaryActionButton: PrimaryActionButton,
    secondaryActionButton: SecondaryActionButton,
    isArchived,
    isPreviewMode = false,
  } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[content.content_type];

  const PrimaryActionButtonComponent = PrimaryActionButton || DefaultPrimaryActionButton;
  const SecondaryActionButtonComponent = SecondaryActionButton || DefaultSecondaryActionButton;

  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignment = content?.user_assignment || content?.assignment;
  const hasAssignment = !isNil(assignment);
  const assignmentState = get(assignment, 'state');

  const canDisplayActions = !isRenderedWithinTrack && !isArchived;
  const canDisplaySecondaryActionButton = SecondaryActionButtonComponent != null;
  const canDisplayPrimaryActionButton = !isPreviewMode && PrimaryActionButtonComponent != null;

  return (
    <Stack
      direction="row"
      flexGrow={1}
      height="52px"
      justifyContent="space-between"
      paddingX="20px"
      paddingY={1}
    >
      <Box display="flex" gap={1} alignItems="center">
        {hasAssignment && (
          <>
            <ContentItemHeaderTimeline contentItem={content} assignment={assignment} />
            {assignmentState === ASSIGNMENT_STATES.exempted && <Exempted assignment={assignment} />}
          </>
        )}
      </Box>

      {canDisplayActions && (
        <Stack direction="row" gap="12px" alignItems="center">
          <AssignmentActionsProvider content={content} assignment={assignment}>
            {canDisplaySecondaryActionButton && (
              <SecondaryActionButtonComponent content={content} contentType={learningType} />
            )}

            {assignmentState === ASSIGNMENT_STATES.completed && assignment && (
              <ContentRatingActions content={content} assignment={assignment} />
            )}

            {canDisplayPrimaryActionButton && <PrimaryActionButtonComponent content={content} />}
          </AssignmentActionsProvider>
        </Stack>
      )}
    </Stack>
  );
};
