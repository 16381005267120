import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import { STATUS_LOADING_MORE } from 'shared/constants';
import { Typography, grey, Box, Tooltip, Stack } from 'vendor/mui';
import { HelpOutlineIcon } from 'vendor/mui-icons';

const LoadMoreButtonWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

const ViewAllContent = ({
  children,
  totalResults,
  fetchStatus,
  fetchNextPage,
  hasNextPage,
  tooltipMessage,
}) => (
  <>
    {totalResults > 0 && (
      <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color={colors.neutral500} fontWeight={600}>
            Suggested for you
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ color: grey[600], marginRight: 0.2 }}>
            {pluralize('result', totalResults, true)}
          </Typography>

          {tooltipMessage && (
            <Tooltip title={tooltipMessage}>
              <HelpOutlineIcon sx={{ fontSize: 14, color: grey[600] }} />
            </Tooltip>
          )}
        </Box>
      </Stack>
    )}

    {children}
    <LoadMoreButtonWrapper>
      <LoadMoreButton
        isLoading={fetchStatus === STATUS_LOADING_MORE}
        show={hasNextPage}
        onClick={fetchNextPage}
        size="large"
      />
    </LoadMoreButtonWrapper>
  </>
);

ViewAllContent.propTypes = {
  children: PropTypes.node,
  totalResults: PropTypes.number,
  fetchStatus: PropTypes.string,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export default ViewAllContent;
