import {
  ConnectWithoutContactIcon,
  EventIcon,
  GroupsIcon,
  PeopleOutlineIcon,
} from 'vendor/mui-icons';

export enum MY_UPCOMING_TYPES {
  event = 'event',
  mentorship = 'mentorship',
  one_on_one_session = 'one_on_one_session',
  group_session = 'group_session',
}

export const MY_UPCOMING_TYPES_LABELS = {
  [MY_UPCOMING_TYPES.event]: 'Event',
  [MY_UPCOMING_TYPES.mentorship]: 'Mentorship',
  [MY_UPCOMING_TYPES.one_on_one_session]: '1:1',
  [MY_UPCOMING_TYPES.group_session]: 'Group Session',
};

export const MY_UPCOMING_TYPES_ICONS = {
  [MY_UPCOMING_TYPES.event]: EventIcon,
  [MY_UPCOMING_TYPES.mentorship]: ConnectWithoutContactIcon,
  [MY_UPCOMING_TYPES.one_on_one_session]: PeopleOutlineIcon,
  [MY_UPCOMING_TYPES.group_session]: GroupsIcon,
};
