import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { isEqual } from 'vendor/lodash';

const Container = styled.div`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const UserBadge = (props) => {
  const { className, image, size } = props;

  const getSize = () => {
    let value = 32;
    if (isEqual(size, 'medium')) {
      value = 24;
    } else if (isEqual(size, 'small')) {
      value = 16;
    } else if (isEqual(size, 'profileLarge')) {
      value = 40;
    }
    return value;
  };

  return <Container className={className} image={image} size={getSize()} />;
};

UserBadge.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default UserBadge;
