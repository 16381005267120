import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';

import { UserItem } from 'shared';

export class UserSelectItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleClick(e) {
    const { userId, userEmail, onClick } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(userId, userEmail);
    }
  }

  handleRemoveClick(e) {
    const { userId, userEmail, onRemoveClick } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (onRemoveClick) {
      onRemoveClick(userId, userEmail);
    }
  }

  render() {
    return (
      <HotKeys
        keyMap={{ handleClick: ['space', 'enter'], handleRemoveClick: ['backspace', 'del'] }}
        handlers={{ handleClick: this.handleClick, handleRemoveClick: this.onRemoveClick }}
      >
        <UserItem {...this.props} />
      </HotKeys>
    );
  }
}

UserSelectItem.propTypes = {
  userEmail: PropTypes.string,

  userId: PropTypes.number,
  userName: PropTypes.string,
  userImage: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onClick: PropTypes.func,
};

export default UserSelectItem;
