import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { map } from 'vendor/lodash';

import FAQItem from './FAQItem';

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  background-color: ${colors.neutral50};
  border-bottom: 1px solid ${colors.neutral200};
  a {
    color: ${colors.action600};
    &:hover {
      color: ${colors.action700};
      text-decoration: underline;
    }
  }
`;

const DocsModal = ({ title, FAQItems, handleClose, height }) => (
  <Modal handleClose={handleClose} title={title} width={600} height={height}>
    <ModalBody fullSize>
      {map(FAQItems, (item) => (
        <FAQItem key={item.index} {...item} />
      ))}

      <InfoBox>
        <Text size="h5" color={colors.neutral900}>
          <strong>Need more info?</strong>&nbsp;Please visit our&nbsp;
          <a href="https://help.plusplus.app/en/">Help Center</a>
        </Text>
      </InfoBox>
    </ModalBody>
    <ModalFooter variant="buttons">
      <ModalFooterButton onClick={handleClose}>Got it</ModalFooterButton>
    </ModalFooter>
  </Modal>
);

DocsModal.propTypes = {
  title: PropTypes.string,
  FAQItems: PropTypes.array,
  handleClose: PropTypes.func,
  height: PropTypes.string,
};

export default DocsModal;
