import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MENTORSHIP_REQUEST_KIND_LABEL_MAP } from 'program/constants';
import colors from 'services/colors';
import { Modal } from 'shared';
import { ModalBody } from 'shared/components/Modal';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import UserHighlight from 'shared/components/UserHighlight';
import { DATE_FORMAT } from 'shared/constants';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * + * {
    margin-top: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MentorshipRequestViewMoreModal = ({
  user,
  mentorshipRequest: { kind, note, modified },
  statusMessage,
  handleClose,
}) => {
  return (
    <Modal title={MENTORSHIP_REQUEST_KIND_LABEL_MAP[kind]} maxWidth={600} handleClose={handleClose}>
      <ModalBody>
        <Content>
          <Header>
            <UserHighlight user={user} />
            <Pill
              icon="calendar"
              label={moment(modified).format(DATE_FORMAT)}
              variant="lighterGrayWithDarkerTextColor"
            />
          </Header>
          <Text size="h4" medium color={colors.neutral900}>
            {statusMessage}
          </Text>
          <Text size="h4" color={colors.neutral900}>
            {note}
          </Text>
        </Content>
      </ModalBody>
    </Modal>
  );
};

MentorshipRequestViewMoreModal.propTypes = {
  user: PropTypes.object,
  mentorshipRequest: PropTypes.object,
  statusMessage: PropTypes.string,
  handleClose: PropTypes.func,
};

export default MentorshipRequestViewMoreModal;
