import PropTypes from 'prop-types';
import React from 'react';

import NavBar from './NavBar';

function Container(props) {
  const { location, match } = props;

  return <NavBar location={location.pathname} match={match} />;
}

Container.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default Container;
