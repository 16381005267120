import PropTypes from 'prop-types';
import React from 'react';

import Pill from 'shared/components/Pill';

export const OpeningsPill = ({ openings, text, isUnlimited }) => {
  const label = `${openings > 0 ? openings : 'No'} ${text}`;
  const variant = openings > 0 || isUnlimited ? 'success200' : 'error200';

  return <Pill label={isUnlimited ? 'Unlimited spots' : label} variant={variant} />;
};

OpeningsPill.propTypes = {
  openings: PropTypes.number.isRequired,
  isUnlimited: PropTypes.bool,
  text: PropTypes.string,
};

OpeningsPill.defaultProps = {
  text: 'spots left',
  isUnlimited: false,
};
