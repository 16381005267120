import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory } from 'data-fetching/utils';
import { ApiURLs, fetchURL } from 'services/requests-base';

export const badges = createQueryKeys('badges', {
  list: listQueryKeyFactory({ endpointName: 'badges_api:list_create' }),
  listFilters: {
    queryKey: null,
    queryFn: async () => {
      const url = ApiURLs['badges_api:rql_filters']();
      const { data } = await fetchURL(url);
      return { results: data };
    },
  },
});

export type ContentItemsQueryKeys = inferQueryKeys<typeof badges>;
