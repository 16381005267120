import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { toLower } from 'vendor/lodash';

const ModalBodyContainer = styled.div`
  padding: 16px;
`;

const RelaunchCourseModal = ({ title, handleRelaunch, isCourseLaunchLoading, handleClose }) => {
  const courseLabel = useLearningTypeLabels()[LEARNING_TYPES.courses];
  const courseLabelPlural = useLearningTypeLabels({ isPlural: true })[LEARNING_TYPES.courses];
  return (
    <Modal
      title={`Relaunch ${title}`}
      handleClose={handleClose}
      width={window.innerWidth > 600 ? 600 : null}
      overflow="hidden"
    >
      <ModalBody fullSize scrollable>
        <ModalBodyContainer>
          <Text size="h5" block>
            Some {toLower(courseLabelPlural)} might reset progress on relaunch. If this is your
            concern, check with the {toLower(courseLabel)} maintainers. Proceed anyway?
          </Text>
        </ModalBodyContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton type="button" color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          type="button"
          color="primary"
          disabled={isCourseLaunchLoading}
          onClick={handleRelaunch}
        >
          {isCourseLaunchLoading ? 'Launching...' : 'Continue'}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

RelaunchCourseModal.propTypes = {
  title: PropTypes.string,
  handleRelaunch: PropTypes.func,
  isCourseLaunchLoading: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default RelaunchCourseModal;
