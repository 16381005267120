import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { FormSectionContainer } from 'shared/components/Form';
import IconButton from 'shared/components/IconButton';
import Text from 'shared/components/Text';
import { map } from 'vendor/lodash';

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

const ListItemAnchor = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const ToggleListSection = ({ sectionKey, sectionTitle, toggleList }) => {
  return (
    <FormSectionContainer defaultOpen key={sectionKey} id={sectionKey} title={sectionTitle}>
      {map(toggleList, ([key, value]) => (
        <ListItem id={key} key={key}>
          <ListItemAnchor>
            <IconButton
              iconName="link"
              title={key}
              color={colors.neutral200}
              height={12}
              width={12}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}${mapRoute('applicationToggles')}#${key}`
                );
                toast.success('Link copied to clipboard');
              }}
            />
            <Text size="h3" color={colors.neutral500}>
              {key}
            </Text>
          </ListItemAnchor>
          <Text>{value.description}</Text>
        </ListItem>
      ))}
      {toggleList.length === 0 && (
        <Text size="h3" color={colors.neutral500}>
          This section currently has no toggles
        </Text>
      )}
    </FormSectionContainer>
  );
};

ToggleListSection.propTypes = {
  sectionKey: PropTypes.string,
  sectionTitle: PropTypes.string,
  toggleList: PropTypes.array,
};

export default ToggleListSection;
