import React, { Children, useEffect, useLayoutEffect, useRef, useState } from 'react';

import colors from 'services/colors';
import { map } from 'vendor/lodash';
import { Box, Link, Breadcrumbs as MUIBreacrumbs, Tooltip, Typography } from 'vendor/mui';
import { KeyboardArrowRightIcon } from 'vendor/mui-icons';

import CoverImage from '../CoverImage';

export interface BreadcrumbItemProps {
  label: string;
  color?: string;
  link?: string;
  onClick?: () => void;
  image?: {
    src: string;
    alt: string;
  };
  linkProps?: React.ComponentProps<typeof Link>;
  tootltipProps?: React.ComponentProps<typeof Tooltip>;
  typographyProps?: React.ComponentProps<typeof Typography>;
}

export const BreadcrumbItem = ({
  label,
  link,
  image,
  onClick,
  linkProps,
  tootltipProps,
  typographyProps,
}: BreadcrumbItemProps) => {
  return (
    <Link underline="hover" color="inherit" {...{ to: link, onClick, ...linkProps }}>
      <Tooltip title={label} arrow {...tootltipProps}>
        <Box display="flex" alignItems="center" gap="16px">
          {image && (
            <CoverImage hasBorder imageUrl={image.src} alt={image.alt} width="40px" height="25px" />
          )}
          <Typography noWrap variant="body2" {...typographyProps}>
            {label}
          </Typography>
        </Box>
      </Tooltip>
    </Link>
  );
};

export interface BaseBreadcrumbsProps {
  variant?: 'default' | 'alternate';
  hasMargin?: boolean;
  breadcrumbsProps?: React.ComponentProps<typeof MUIBreacrumbs>;
}

export interface BreadcrumbsWithItemsProps extends BaseBreadcrumbsProps {
  items: {
    label: string;
    link?: string;
    onClick?: () => void;
  }[];
}

export interface BreadcrumbsWithChildrenProps extends BaseBreadcrumbsProps {
  children: React.ReactNode;
}

export const Breadcrumbs: React.FC<BreadcrumbsWithItemsProps> &
  React.FC<BreadcrumbsWithChildrenProps> = ({
  hasMargin,
  breadcrumbsProps,
  variant,
  ...props
}: BreadcrumbsWithItemsProps | BreadcrumbsWithChildrenProps) => {
  let itemElements;
  if ('children' in props) {
    itemElements = props.children;
  } else {
    itemElements = map(props.items, (item, index) => (
      <BreadcrumbItem
        key={`${index}-${item.label}`}
        typographyProps={{
          color: variant === 'alternate' ? colors.neutral0 : colors.neutral600,
        }}
        {...item}
      />
    ));
  }

  const defaultMaxItems = 'children' in props ? Children.count(props.children) : props.items.length;
  const [maxItems, setMaxItems] = useState(defaultMaxItems);
  const itemsBeforeCollapse = Math.ceil(maxItems / 2);
  const itemsAfterCollapse = maxItems - itemsBeforeCollapse;

  const breadcrumbsContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setMaxItems(defaultMaxItems);
  }, [defaultMaxItems]);

  useLayoutEffect(() => {
    if (breadcrumbsContainerRef.current) {
      const hasOverflowingChildren = breadcrumbsContainerRef.current.scrollHeight > 25;

      if (hasOverflowingChildren) {
        setMaxItems((maxItems) => (maxItems > 1 ? maxItems - 1 : 1));
      }
    }
  }, [
    itemElements,
    breadcrumbsContainerRef.current?.offsetHeight,
    breadcrumbsContainerRef.current?.scrollHeight,
    breadcrumbsContainerRef.current?.offsetWidth,
    breadcrumbsContainerRef.current?.scrollWidth,
  ]);

  return (
    <MUIBreacrumbs
      ref={breadcrumbsContainerRef}
      separator={
        <KeyboardArrowRightIcon
          fontSize="small"
          htmlColor={variant === 'alternate' ? colors.neutral0 : colors.neutral400}
        />
      }
      sx={{
        width: '100%',
        marginBottom: hasMargin ? '24px' : null,
      }}
      maxItems={maxItems}
      itemsBeforeCollapse={itemsBeforeCollapse}
      itemsAfterCollapse={itemsAfterCollapse}
      {...breadcrumbsProps}
    >
      {itemElements}
    </MUIBreacrumbs>
  );
};

export default Breadcrumbs;
