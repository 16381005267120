import { useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { ContentItemContextMenu } from 'content-items/components/interfaces';
import actions from 'entities/actions';
import { useArchiveUnarchive } from 'entities/utils';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import ContentSurveyModal from 'shared/components/ContentSurveyModal';
import DropDownMenu from 'shared/components/DropDownMenu';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { get, includes } from 'vendor/lodash';

const VideoContextMenu = ({ content }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { archiveItem, unarchiveItem } = useArchiveUnarchive(actions.video.archive);

  const {
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    content_type: contentType,
    permissions,
  } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  const handleDeleteClick = () => setShowDeleteModal(true);

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        {contentType === CONTENT_TYPES.video && (
          <>
            <DropDownMenu.Item
              route={contentTypeRoutes[CONTENT_TYPES.video].edit({ public_id: publicId })}
              title="Edit"
              icon="pencil"
            />
            {canAssign && (
              <DropDownMenu.Item
                route={mapRoute('contentItemManageAssignments', {
                  public_id_and_slug: publicIdAndSlug,
                })}
                title="Manage Assignments"
                icon="group"
              />
            )}
            <DropDownMenu.Item
              onClick={() => setShowSurveyModal(true)}
              title="Manage Surveys"
              icon="survey"
            />
            {!isArchived && (
              <DropDownMenu.Item
                onClick={() => archiveItem(publicId)}
                title="Archive"
                icon="archive"
              />
            )}
            {isArchived && (
              <>
                <DropDownMenu.Item
                  onClick={() => unarchiveItem(publicId)}
                  title="Unarchive"
                  icon="unarchive"
                />
                <DropDownMenu.Item
                  onClick={handleDeleteClick}
                  title="Delete"
                  icon="delete"
                  color={colors.error600}
                  textColor={colors.error600}
                />
              </>
            )}
            {canAssign && (
              <DropDownMenu.Item
                onClick={() => setShowLogModal(true)}
                title="View Logs"
                icon="log"
              />
            )}
          </>
        )}
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default VideoContextMenu;
