import React from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import { mapRoute } from 'services/requests';
import { useCurrentUser, useLabels, useOriginRoute } from 'shared/hooks';
import { has } from 'vendor/lodash';

import TaskFormPage from './TaskFormPage';

const TaskCreate = () => {
  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;

  const { label_task: labelTask } = useLabels();
  const backRoute = useOriginRoute(catalogRoute);
  const currentUser = useCurrentUser();

  const initialValues = {
    duration: null,
    is_completable: true,
  };

  // Facilitators
  if (!has(initialValues, 'facilitators_ids')) {
    initialValues.facilitators_ids = [currentUser.id];
  }

  return (
    <TaskFormPage
      pageTitle={`Create ${labelTask}`}
      topBarActionName={`Creating ${labelTask}`}
      backRoute={backRoute}
      initialValues={initialValues}
    />
  );
};

export default TaskCreate;
