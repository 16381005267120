import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { queries } from 'queries';
import Page404 from 'scenes/404';
import Loading from 'shared/components/Loading';

import ChannelDetailPage from '../components/ChannelDetailPage';

interface RouteChannelParams {
  slug: string;
}

const ChannelDetail = () => {
  const { slug: channelSlug } = useParams<RouteChannelParams>();

  const { data, isLoading, isError } = useQuery(queries.channels.detail(channelSlug || ''));

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  return <ChannelDetailPage channel={data} />;
};

export default ChannelDetail;
