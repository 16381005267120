import {
  Widget,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
  WidgetContent,
} from 'common/components/widget';
import { mapRoute } from 'services/requests';
import { get, map, size } from 'vendor/lodash';

import {
  UsersWidgetRow,
  UsersWidgetLoadingContent,
  UsersWidgetEmptyContent,
} from '../components/BaseUsersAsyncWidget';
import { useUsersWidgetQuery } from '../hooks/useUsersWidgetQuery';

const myExpertsSearchQuery = 'my_experts=true&ordering(name)';
const viewAllUrl = `${mapRoute('peopleList')}?${myExpertsSearchQuery}`;

const allExpertsSearchQuery = 'ordering(-unique_people_impacted_count)';
const exploreAllUrl = `${mapRoute('peopleList')}?${allExpertsSearchQuery}`;

type MyExpertsWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MyExpertsWidget(props: MyExpertsWidgetProps) {
  const { hideOnEmpty = false } = props;

  const { data, isLoading: isLoadingMyExperts } = useUsersWidgetQuery(myExpertsSearchQuery);
  const { data: exploreCountData, isLoading: isLoadingAllExperts } =
    useUsersWidgetQuery(allExpertsSearchQuery);

  const isLoading = isLoadingMyExperts || isLoadingAllExperts;

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  const exploreCount = get(exploreCountData, 'total', 0);

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="My Experts"
      tooltip="Experts who created Content I consumed, and presented at Events I participated"
      action={
        resultsTotal > 3 ? (
          <WidgetLinkAction label={`View all ${resultsTotal}`} url={viewAllUrl} />
        ) : exploreCount > 0 ? (
          <WidgetLinkAction label={`Explore all ${exploreCount}`} url={exploreAllUrl} />
        ) : null
      }
    >
      {isLoading || displayResults ? (
        <WidgetContent>
          <WidgetContentList>
            {displayResults ? (
              map(results, (row) => (
                <WidgetContentListItem key={row.id}>
                  <UsersWidgetRow user={row} />
                </WidgetContentListItem>
              ))
            ) : (
              <UsersWidgetLoadingContent />
            )}
          </WidgetContentList>
        </WidgetContent>
      ) : (
        <UsersWidgetEmptyContent
          label={"You didn't complete any content or attended any event yet"}
        />
      )}
    </Widget>
  );
}
