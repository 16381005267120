import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import {
  CODELAB_VERSION_STATUS_PENDING,
  CODELAB_VERSION_STATUS_FAILED,
  CODELAB_VERSION_STATUS_PUBLISHED,
} from 'codelab/constants';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import CodelabUploadField from 'inputs/components/CodelabUploadField';
import GoogleDrivePicker from 'inputs/components/GoogleDrivePicker';
import SegmentedControlsInput from 'inputs/components/SegmentedControlsInput';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { ApiURLs, fetchURL } from 'services/requests-base';
import ButtonLink from 'shared/components/ButtonLink';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';
import InfoBox from 'shared/components/InfoBox';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { useLabels, useToggles } from 'shared/hooks';
import { toLower } from 'vendor/lodash';
import { Box } from 'vendor/mui';

const CodelabImportWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.p`
  padding: 1em 0em;
`;

const ModalContent = styled.div`
  position: relative;
  max-height: 60vh;
  overflow: auto;
  display: flex;
`;

const ImportLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImportLoadingPlaceholderWrapper = styled.div`
  width: ${({ width }) => width || '440px'};
  height: ${({ height }) => height || '160px'};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImportLoadingPlaceholder = styled.div`
  background-color: transparent;
  border: solid 2px ${colors.action600};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  & > * {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    z-index: 1;
    animation: fill 2s ease infinite;

    @keyframes fill {
      0% {
        height: 0;
        background-color: ${colors.action200};
      }

      70% {
        height: 100%;
        background-color: ${colors.action200};
      }

      95% {
        height: 100%;
        background-color: transparent;
      }

      100% {
        height: 0;
      }
    }
  }
`;

const SelectGoogleDocCtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const GOOGLE_DOC_IMPORT_TYPE = 'google_doc';
const MARKDOWN_ZIP_IMPORT_TYPE = 'markdown_zip';

const delay = async (durationMillis) => {
  return new Promise((resolve) => setTimeout(resolve, durationMillis));
};

const CodelabImportFormModal = (props) => {
  const { codelabId, fromChannel, handleClose, handleUploadCompleted } = props;

  const { label_codelab: labelCodelab, label_catalog: labelCatalog } = useLabels();
  const { toggle_google_drive: toggleGoogleDrive } = useToggles();

  const history = useHistory();

  const [importType, setImportType] = useState(MARKDOWN_ZIP_IMPORT_TYPE);
  const [codelabInput, setCodelabInput] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [codelabVersion, setCodelabVersion] = useState(null);

  const [uploadCodelab, { data: uploadedCodelab, status: uploadCodelabStatus }] = useEntities(
    actions.codelab.upload,
    null
  );

  const refetchCodelabVersion = async () => {
    if (!codelabVersion) {
      return;
    }

    const codelabVersionUrl = ApiURLs['codelabs_api:version_retrieve']({
      codelab_id: codelabVersion.codelab_id,
      codelab_version_id: codelabVersion.id,
    });

    try {
      const res = await fetchURL(`${codelabVersionUrl}?view_mode=full`);
      setCodelabVersion(res.data);
    } catch (_e) {
      toast.error(
        `Oops. Encountered an issue while checking on the progress of the ${toLower(
          labelCodelab
        )} import. Will try again in a few moments.`
      );
    }
  };

  const handleDeleteCodelab = async (codelabId) => {
    try {
      const deleteCodelabUrl = ApiURLs['codelabs_api:retrieve_update_destroy'](codelabId);
      await fetchURL(deleteCodelabUrl, {
        method: 'DELETE',
      });
    } catch (_e) {
      // ignored
    }
  };

  /**
   * Check the codelab upload status
   */
  useEffect(() => {
    switch (uploadCodelabStatus) {
      case STATUS_LOADING: {
        if (!isUploading) {
          setIsUploading(true);
        }

        break;
      }
      case STATUS_ERROR: {
        setIsUploading(false);
        setCodelabInput(null);
        toast.error('Import failed. Please check your input and try again.');

        break;
      }
      case STATUS_DONE: {
        setCodelabVersion(uploadedCodelab);

        break;
      }
      default:
      // ignored
    }
  }, [uploadCodelabStatus]);

  /**
   * After the codelab was uploaded, check the codelab version status.
   * The codelab version contains the actual codelab data.
   */
  useEffect(() => {
    const handleVerifyCodelabVersion = async () => {
      if (!codelabVersion) {
        return;
      }

      switch (codelabVersion.status) {
        case CODELAB_VERSION_STATUS_PENDING: {
          await delay(3000);
          refetchCodelabVersion();

          break;
        }
        case CODELAB_VERSION_STATUS_FAILED: {
          setIsUploading(false);
          setCodelabInput(null);
          toast.error(`Failed to import ${toLower(labelCodelab)}.`, codelabVersion.log);

          /**
           * If this was a new codelab, delete it.
           */
          if (!codelabId) {
            await handleDeleteCodelab(codelabVersion.codelab_id);
          }

          break;
        }
        case CODELAB_VERSION_STATUS_PUBLISHED: {
          setIsUploading(false);

          if (handleUploadCompleted) {
            handleUploadCompleted(codelabVersion.codelab.public_id_and_slug);
          } else {
            const queryParams = {};

            if (fromChannel) {
              queryParams.channel = fromChannel.slug;
            }

            const queryString = qs.stringify(queryParams);

            const route = mapRoute('codelabEdit', {
              public_id_and_slug: codelabVersion.codelab.public_id_and_slug,
            });
            history.push(`${route}${queryString ? `?${queryString}` : ''}`);
          }

          break;
        }
        default:
        // ignored
      }
    };

    handleVerifyCodelabVersion();
  }, [codelabVersion, fromChannel]);

  const handleUploadCodelab = () => {
    setCodelabVersion(null);

    const payload = {
      codelab_id: codelabId,
      codelab_input: codelabInput,
      access_token: accessToken,
    };

    if (fromChannel) {
      payload.channel_id = fromChannel.id;
    }

    uploadCodelab(payload);

    setIsUploading(true);
  };

  const codelabHelpArticleUrl = 'http://help.plusplus.app/en/articles/6275332-importing-codelab';
  const codelabFormatUrl = 'https://github.com/googlecodelabs/tools/blob/main/FORMAT-GUIDE.md';

  return (
    <Modal
      title={`${isUploading ? 'Importing' : 'Import'} new ${toLower(labelCodelab)}${
        codelabId ? ' version' : ''
      }`}
      height={isUploading ? '300px' : '520px'}
      handleClose={handleClose}
      width="600px"
      zIndex="1000" // lowering (from 1500) so that the Google Drive Picker shows up on the top
    >
      <ModalBody>
        <ModalContent>
          <CodelabImportWrapper>
            {!isUploading && (
              <>
                {toggleGoogleDrive && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <SegmentedControlsInput
                      items={[
                        { value: MARKDOWN_ZIP_IMPORT_TYPE, name: 'Markdown (ZIP)' },
                        { value: GOOGLE_DOC_IMPORT_TYPE, name: 'Google Docs' },
                      ]}
                      value={importType}
                      onChange={(newValue) => {
                        setImportType(newValue);
                        setCodelabInput(null); // explicitly reset input when input type is changed
                      }}
                      fullWidth
                    />
                  </Box>
                )}

                {(importType === MARKDOWN_ZIP_IMPORT_TYPE || !toggleGoogleDrive) && (
                  <>
                    <TextWrapper>
                      <Text size="h4">
                        Import from a single ZIP file containing{' '}
                        <ButtonLink url={codelabFormatUrl} target="_blank" rel="noreferrer">
                          Markdown-formatted {toLower(labelCodelab)}
                        </ButtonLink>{' '}
                        and media assets. Up to 100 MB.
                      </Text>
                    </TextWrapper>
                    <Field
                      name="codelab_file"
                      component={CodelabUploadField}
                      filePath="codelab_files"
                      acceptedFileExtensions=".zip"
                      showEditButton
                      hasUploadError={
                        codelabVersion && codelabVersion.status === CODELAB_VERSION_STATUS_FAILED
                      }
                      onChange={setCodelabInput}
                    />
                  </>
                )}

                {importType === GOOGLE_DOC_IMPORT_TYPE && toggleGoogleDrive && (
                  <>
                    <TextWrapper>
                      <Text size="h4">
                        Import from a single{' '}
                        <ButtonLink url={codelabFormatUrl} target="_blank" rel="noreferrer">
                          Google Docs-formatted {toLower(labelCodelab)} document
                        </ButtonLink>{' '}
                        containing all content and media assets.
                      </Text>
                    </TextWrapper>
                    <GoogleDrivePicker
                      onAccessToken={setAccessToken}
                      onPicked={setCodelabInput}
                      initialAccessToken={accessToken}
                      makeSelectionCta={
                        <SelectGoogleDocCtaWrapper>
                          <Icon name="doc" height={30} width={22} color={colors.action600} />
                          <Text color={colors.action600} size="h5" medium>
                            Select a Google Doc
                          </Text>
                        </SelectGoogleDocCtaWrapper>
                      }
                      width="100%"
                      height="100px"
                    />
                  </>
                )}

                <InfoBox
                  content={
                    <>
                      First time importing a {toLower(labelCodelab)}? Check out the{' '}
                      <ButtonLink url={codelabHelpArticleUrl} target="_blank">
                        complete step-by-step guide
                      </ButtonLink>
                      .
                    </>
                  }
                  margin="16px 0 0 0"
                />
              </>
            )}
            {isUploading && (
              <ImportLoadingWrapper>
                <ImportLoadingPlaceholderWrapper>
                  <ImportLoadingPlaceholder>
                    <Icon name="doc" height={30} width={22} color={colors.action600} />
                    <Text bold color={colors.action600}>
                      Importing your {toLower(labelCodelab)} may take a few minutes
                    </Text>
                  </ImportLoadingPlaceholder>
                </ImportLoadingPlaceholderWrapper>
                <InfoBox
                  content={
                    <>
                      It is safe to close this dialog. Once successfully imported, your
                      {toLower(labelCodelab)} will show up in the {toLower(labelCatalog)}.{' '}
                      <Clicker
                        onClick={handleClose}
                        display="inline-block"
                        color={colors.action600}
                      >
                        <Text bold>Back to {labelCatalog}</Text>.
                      </Clicker>
                    </>
                  }
                  margin="16px 0 0 0"
                />
              </ImportLoadingWrapper>
            )}
          </CodelabImportWrapper>
        </ModalContent>
      </ModalBody>
      {!isUploading && (
        <ModalFooter variant="buttons" justifyContent="spaceBetween">
          <ModalFooterButton color="error" type="button" onClick={handleClose}>
            Cancel
          </ModalFooterButton>
          {isUploading && <Loading hasMargin={false} />}
          {!isUploading && (
            <ModalFooterButton type="button" disabled={!codelabInput} onClick={handleUploadCodelab}>
              Import
            </ModalFooterButton>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

CodelabImportFormModal.propTypes = {
  handleClose: PropTypes.func,
  codelabId: PropTypes.number,
  handleUploadCompleted: PropTypes.func,
  fromChannel: PropTypes.object,
};

export default reduxForm({ form: 'CodelabImportFormModal' })(CodelabImportFormModal);
