import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useEffect, useState, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useRefreshEvent } from 'app/data-fetching/mutations/events';
import { enrollmentSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
} from 'event-shared/constants';
import eventActions from 'event/actions';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import {
  getAttendanceMethodLabel,
  getEnrollmentStatusLabel,
  METRICS_ACTIVITIES,
  useMetrics,
} from 'services/metrics';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { usePrevious } from 'shared/hooks';
import { ContentItemListenerContext } from 'stand-alone-shared/context-providers/ContentItemListenerContext';
import { includes, pick } from 'vendor/lodash';

import EventRegistrationManager from '../EventRegistrationManager';

import AttendanceActionElement from './AttendanceActionElement';

const AttendanceActionManager = (props) => {
  const dispatch = useDispatch();
  const { onChange } = useContext(ContentItemListenerContext);

  const { event, updateEnrollmentRequestStatus, updatedEnrollment, shouldRefreshEvent } = props;
  const previousStatus = usePrevious(updateEnrollmentRequestStatus);
  const previousUpdatedEnrollment = usePrevious(updatedEnrollment);
  const { trackActivity } = useMetrics();

  const queryClient = useQueryClient();

  const invalidateUpcomingEvents = useCallback(() => {
    const queryKey = queries.my_upcoming.list().queryKey;
    queryClient.invalidateQueries({ queryKey: queryKey[0] });
  }, [queryClient]);

  const [currentEnrollmentStatus, setCurrentEnrollmentStatus] = useState(event.enrollment?.status);

  const refreshEvent = useRefreshEvent(event.id, 'listing');

  useEffect(() => {
    // Triggers only when an enrollment is being checked in
    if (
      currentEnrollmentStatus !== event.enrollment?.status &&
      event.enrollment?.status === 'attended'
    ) {
      onChange?.();
    }
    setCurrentEnrollmentStatus(event.enrollment?.status);
  }, [event.enrollment?.status]);

  // Update event when enroll finishes
  useEffect(() => {
    if (previousStatus === STATUS_LOADING && updateEnrollmentRequestStatus === STATUS_DONE) {
      handleEnrollmentComplete();
      invalidateUpcomingEvents();
      if (shouldRefreshEvent) {
        refreshEvent.mutate();
      } else {
        dispatch(eventActions.eventFetchRequestSubmit({ public_id: event.public_id }));
      }
    }
  }, [updateEnrollmentRequestStatus]);

  const registrationManagerRef = useRef(null);
  const handleEnrollmentStatusChange = useCallback(
    (newStatus) => registrationManagerRef.current.registrationStatusChanged(newStatus),
    []
  );

  const handleEnrollmentComplete = () => {
    const { status } = updatedEnrollment;
    const { status: previousStatus } = previousUpdatedEnrollment;

    if (status === ENROLLMENT_STATUS_NOT_GOING) {
      trackActivity(METRICS_ACTIVITIES.EVENT_UNENROLL, {
        eventId: event.id,
        enrollmentId: updatedEnrollment.id,
        priorEnrollmentStatus: getEnrollmentStatusLabel(previousStatus),
        priorAttendanceMethod: getAttendanceMethodLabel(previousStatus),
      });
    }

    // Exclude non-enrollments
    if (
      !includes(
        [
          ENROLLMENT_STATUS_GOING,
          ENROLLMENT_STATUS_GOING_ONLINE,
          ENROLLMENT_STATUS_WAIT_LIST,
          ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
        ],
        status
      )
    ) {
      return;
    }

    const attendanceMethod = getAttendanceMethodLabel(status);
    const enrollmentStatus = getEnrollmentStatusLabel(status);
    trackActivity(METRICS_ACTIVITIES.EVENT_ENROLL, {
      eventId: event.id,
      enrollmentId: updatedEnrollment.id,
      attendanceMethod,
      enrollmentStatus,
    });

    toast.success('Successfully enrolled', 'See you there!');
  };

  return (
    <React.Fragment>
      <AttendanceActionElement
        {...pick(props, [
          'event',

          'renderRating',
          'renderDisabledStatusChange',
          'renderCheckin',
          'renderEnrollmentClosed',
          'renderEventCancelled',
          'renderHasAttended',

          'renderEnrolled',
          'renderWaitlist',
          'renderProspect',

          'renderUncancellableEnroll',
          'renderFullCapacity',
        ])}
        onEnrollmentStatusChange={handleEnrollmentStatusChange}
      />
      <EventRegistrationManager ref={registrationManagerRef} event={event} />
    </React.Fragment>
  );
};

AttendanceActionManager.propTypes = {
  event: PropTypes.object,
  shouldRefreshEvent: PropTypes.bool,

  renderRating: PropTypes.func,
  renderDisabledStatusChange: PropTypes.func,
  renderCheckin: PropTypes.func,
  renderEnrollmentClosed: PropTypes.func,
  renderEventCancelled: PropTypes.func,

  renderEnrolled: PropTypes.func,
  renderWaitlist: PropTypes.func,
  renderProspect: PropTypes.func,
  renderUncancellableEnroll: PropTypes.func,
  renderFullCapacity: PropTypes.func,

  updatedEnrollment: PropTypes.object,
  updateEnrollmentRequestStatus: PropTypes.string,
};

const mapStateToProps = (state, { event }) => {
  const updateEnrollmentRequestState = getDataFromState(
    `enrollmentStatusUpdate${event.public_id}`,
    state,
    enrollmentSchema
  );

  return {
    updatedEnrollment: updateEnrollmentRequestState.data,
    updateEnrollmentRequestStatus: updateEnrollmentRequestState.status,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceActionManager);
