import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';
import TopBar from 'shared/components/TopBar';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const DashboardTopBar = ({ title, subTitle, children }) => (
  <TopBar>
    <Content>
      <TextContainer>
        {subTitle && (
          <Text size="h5" lineHeight={20} color={colors.neutral500}>
            {subTitle}
          </Text>
        )}
        <Text size="h4" lineHeight={20} medium>
          {title}
        </Text>
      </TextContainer>
      <ContentContainer>{children}</ContentContainer>
    </Content>
  </TopBar>
);

DashboardTopBar.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.node,
};

export default DashboardTopBar;
