import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import propTypes from 'services/prop-types';
import { apiPost } from 'services/requests';
import { generateGuid } from 'services/utils';
import DropDownMenu from 'shared/components/DropDownMenu';
import { List } from 'shared/components/DropDownMenu/DropDownMenu';
import { STATUS_NOT_REQUESTED, STATUS_LOADING, STATUS_DONE, STATUS_ERROR } from 'shared/constants';
import { isEmpty, forEach, keys } from 'vendor/lodash';

import Preview from './Preview';

const Container = styled.div`
  position: relative;
  width: ${({ width }) => width || '500px'};
  height: ${({ height }) => height || '392px'};
`;

const StyledFileInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
`;

const ActionContainer = styled.div`
  background-color: ${colors.neutral0};
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.3);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid ${colors.neutral200};
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 10px;
  }

  ${List} {
    border-radius: 4px;
    overflow: hidden;
    min-width: unset;
    border: 1px solid ${colors.neutral200};
  }
`;

const ImageField = ({
  input,
  filePath,
  className,
  Previewer,
  imageWidth,
  imageHeight,
  setError,
  previewExtraProps,
}) => {
  const [uploadStatus, setUploadStatus] = useState(STATUS_NOT_REQUESTED);
  const [imageLoaded, setImageLoaded] = useState(!isEmpty(input.value));
  const inputGuid = generateGuid();

  const onFileInputOpen = () => {
    const inputTag = document.getElementById(inputGuid);
    inputTag.click();
  };

  const onFileInputChange = (e) => {
    const file = e.target.files[0];
    // Just validate the file if it's not falsy
    const isValid = Boolean(file) && validateUpload(file);
    if (!isValid) {
      e.preventDefault();
      return;
    }
    uploadFile(file);
  };

  const onFileInputRemove = () => {
    const inputTag = document.getElementById(inputGuid);
    inputTag.value = null;

    uploadFile(null);
  };

  const validateUpload = (file) => {
    const sizeLimit = 2000000; // 2MB
    const { size: fileSize } = file;

    if (fileSize > sizeLimit && setError) {
      setError('File is too large (max. 2MB)');
      return false;
    }

    return true;
  };

  const uploadFile = (file) => {
    setUploadStatus(STATUS_LOADING);

    /* eslint-disable promise/always-return */
    apiPost('api_integrations:get-signed-upload-url', null, { path: filePath }).then((response) => {
      const {
        data: {
          upload_data: { url, fields },
          read_url: readUrl,
        },
      } = response;

      const imageUrl = file ? readUrl : null;

      const formData = new FormData();
      forEach(keys(fields), (field) => {
        formData.append(field, fields[field]);
      });

      formData.append('file', file);

      /* eslint-disable promise/no-nesting */
      fetch(url, { method: 'POST', body: formData }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          input.onChange(imageUrl);
          setUploadStatus(STATUS_DONE);
          if (setError) {
            setError('');
          }
        } else {
          setUploadStatus(STATUS_ERROR);
        }

        if (imageUrl) {
          setImageLoaded(true);
        } else {
          setImageLoaded(false);
        }
      });
    });
  };

  const PreviewComponent = Previewer || Preview;

  return (
    <Container
      role="button"
      className={className}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.charCode === 13) onFileInputOpen();
      }}
      height={imageHeight}
      width={imageWidth}
    >
      {imageLoaded && (
        <ActionContainer>
          <DropDownMenu icon="pencil" fontSize={16} buttonAriaLabel="Settings">
            <DropDownMenu.Item
              title="Replace"
              icon="image"
              onClick={onFileInputOpen}
              type="button"
            />
            <DropDownMenu.Item
              title="Remove"
              icon="delete"
              color={colors.error600}
              textColor={colors.error600}
              onClick={onFileInputRemove}
              type="button"
            />
          </DropDownMenu>
        </ActionContainer>
      )}
      <PreviewComponent
        imageUrl={input.value}
        height={imageHeight}
        width={imageWidth}
        isUploading={uploadStatus === STATUS_LOADING}
        onClick={onFileInputOpen}
        {...previewExtraProps}
      />
      <StyledFileInput
        id={inputGuid}
        type="file"
        onChange={onFileInputChange}
        accept=".jpg, .png, .jpeg, .gif"
      />
    </Container>
  );
};

ImageField.propTypes = {
  Previewer: propTypes.anyChildren,

  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  setError: PropTypes.func,

  input: PropTypes.object,
  className: PropTypes.string,
  filePath: PropTypes.string,

  previewExtraProps: PropTypes.object,
};

export default ImageField;
