import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { noop, get, constant, forEach, filter } from 'vendor/lodash';

type EventHandler = (payload?: any) => void;

type EventName = 'filtersChange' | 'expressionChange' | 'forceReload';

export interface EventSubscription {
  id?: string; // Set an ID in case you need to unsubscribe from an event
  eventName: EventName;
  handler: EventHandler;
}

interface Cache {
  getCachedFilters: (cacheKey: string) => object;
  setCachedFilters: (cacheKey: string, filters: object) => void;
  isInitialFiltersUpdated: (cacheKey: string) => boolean;
  setInitialFiltersUpdated: (cacheKey: string) => void;
  isDefaultFiltersUpdated: (cacheKey: string) => boolean;
  setDefaultFiltersUpdated: (cacheKey: string) => void;
  getCachedExpression: (cacheKey: string) => string;
  setCachedExpression: (cacheKey: string, expression: string) => void;
  publishEvent: (cacheKey: string, name: EventName, payload?: any) => void;
  subscribeEvent: (cacheKey: string, subscription: EventSubscription) => () => void;
  unsubscribeEvent: (cacheKey: string, subscription: EventSubscription) => void;
}

const Context = createContext<Cache>({
  getCachedFilters: constant({}),
  setCachedFilters: noop,
  isInitialFiltersUpdated: constant(false),
  setInitialFiltersUpdated: noop,
  isDefaultFiltersUpdated: constant(false),
  setDefaultFiltersUpdated: noop,
  getCachedExpression: constant(''),
  setCachedExpression: noop,
  publishEvent: noop,
  subscribeEvent: constant(noop),
  unsubscribeEvent: noop,
});

export const useRQLFiltersContext = () => useContext(Context);

interface RQLFiltersProviderProps {
  children: React.ReactNode;
}

export const RQLFiltersProvider = ({ children }: RQLFiltersProviderProps) => {
  const [cachedExpressions, setCachedExpressions] = useState<Record<string, string>>({});
  const [filtersCache, setFilterCache] = useState<Record<string, object>>({});
  const [hasUpdatedInitialFilters, setHasUpdatedInitialFilters] = useState<Record<string, boolean>>(
    {}
  );
  const [hasUpdatedDefaultFilters, setHasUpdatedDefaultFilters] = useState<Record<string, boolean>>(
    {}
  );
  const [subscriptions, setSubscriptions] = useState<
    Record<string, Record<string, EventSubscription[]>>
  >({});
  const setCachedExpression = useCallback((cacheKey: string, expression: string) => {
    setCachedExpressions((prevState) => ({ ...prevState, [cacheKey]: expression }));
  }, []);
  const getCachedExpression = useCallback(
    (cacheKey: string): string => get(cachedExpressions, cacheKey, ''),
    [cachedExpressions]
  );
  const setCachedFilters = useCallback((cacheKey: string, filters: object) => {
    setFilterCache((prevState) => ({ ...prevState, [cacheKey]: filters }));
  }, []);
  const getCachedFilters = useCallback(
    (cacheKey: string): object => get(filtersCache, cacheKey, {}),
    [filtersCache]
  );
  const setInitialFiltersUpdated = useCallback((cacheKey: string) => {
    setHasUpdatedInitialFilters((prevState) => ({ ...prevState, [cacheKey]: true }));
  }, []);
  const isInitialFiltersUpdated = useCallback(
    (cacheKey: string): boolean => get(hasUpdatedInitialFilters, cacheKey, false),
    [hasUpdatedInitialFilters]
  );
  const setDefaultFiltersUpdated = useCallback((cacheKey: string) => {
    setHasUpdatedDefaultFilters((prevState) => ({ ...prevState, [cacheKey]: true }));
  }, []);
  const isDefaultFiltersUpdated = useCallback(
    (cacheKey: string): boolean => get(hasUpdatedDefaultFilters, cacheKey, false),
    [hasUpdatedDefaultFilters]
  );
  const publishEvent = useCallback(
    (cacheKey: string, eventName: EventName, payload?: any) => {
      forEach(
        get(subscriptions, `${cacheKey}.${eventName}`, []) as EventSubscription[],
        (subscription: EventSubscription) => {
          subscription.handler(payload || {});
        }
      );
    },
    [subscriptions]
  );
  const unsubscribeEvent = useCallback((cacheKey: string, subscription: EventSubscription) => {
    const eventName: EventName = subscription.eventName;
    const id = subscription.id;
    setSubscriptions((prevState) => ({
      ...prevState,
      [cacheKey]: {
        ...get(prevState, cacheKey),
        [eventName]: filter(
          get(prevState, `${cacheKey}.${eventName}`, []) as EventSubscription[],
          (subscription) => subscription.id != id
        ),
      },
    }));
  }, []);
  const subscribeEvent = useCallback(
    (cacheKey: string, subscription: EventSubscription) => {
      const eventName: EventName = subscription.eventName;
      subscription.id = subscription.id ?? uuid4();
      setSubscriptions((prevState) => ({
        ...prevState,
        [cacheKey]: {
          ...get(prevState, cacheKey),
          [eventName]: [
            ...(get(prevState, `${cacheKey}.${eventName}`, []) as EventSubscription[]),
            subscription,
          ],
        },
      }));
      return () => unsubscribeEvent(cacheKey, subscription);
    },
    [unsubscribeEvent]
  );
  return (
    <Context.Provider
      value={{
        getCachedFilters,
        setCachedFilters,
        isInitialFiltersUpdated,
        setInitialFiltersUpdated,
        isDefaultFiltersUpdated,
        setDefaultFiltersUpdated,
        getCachedExpression,
        setCachedExpression,
        publishEvent,
        subscribeEvent,
        unsubscribeEvent,
      }}
    >
      {children}
    </Context.Provider>
  );
};
