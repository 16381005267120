import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import propTypes from 'services/prop-types';
import { isFunction, get } from 'vendor/lodash';

const Data = styled.td`
  color: ${colors.neutral600};
  vertical-align: middle;
  height: 60px;
  padding: 7px 16px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ wordBreak }) =>
    wordBreak &&
    `
    white-space: normal;
  `}

  // padding-right includes distance between this content and the one on the right
  ${({ alignRight }) =>
    alignRight &&
    `
    text-align: right;
    padding-right: 40px;
  `}

  ${({ $highlighted }) =>
    $highlighted &&
    `
    font-weight: 500;
    color: ${colors.neutral900} !important;
  `}

  ${({ alignTop }) =>
    alignTop &&
    `
    vertical-align: top;
    padding-top: 20px;
  `}
`;

const Column = (props) => {
  const {
    children: element,
    row,
    accessor,
    width,
    className,
    wordBreak,
    alignRight,
    highlighted,
    size,
    header,
    alignTop,
  } = props;

  let content = null;

  const elementProps = {
    size,
    header,
    ...props,
    ...(accessor ? { data: get(row, accessor) } : { data: row }),
  };

  if (isFunction(element)) {
    content = element(elementProps);
  } else if (React.isValidElement(element)) {
    content = React.cloneElement(element, elementProps);
  } else {
    content = get(row, accessor);
  }

  return (
    <Data
      className={className}
      width={width}
      wordBreak={wordBreak}
      alignRight={alignRight}
      $highlighted={highlighted}
      alignTop={alignTop}
    >
      {content}
    </Data>
  );
};

Column.propTypes = {
  children: propTypes.anyChildren,
  row: PropTypes.object,
  accessor: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  wordBreak: PropTypes.bool,
  alignRight: PropTypes.bool,
  highlighted: PropTypes.bool,
  alignTop: PropTypes.bool,
  size: PropTypes.string,
  header: PropTypes.element,
};

export default Column;
