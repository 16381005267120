import * as React from 'react';

import { useContentCreationPermissions } from 'common/hooks/useContentCreationPermissions';
import { buildWidgetsGrid } from 'common/utils/buildWidgetsGrid';
import { WidgetsPermissionsCtx } from 'common/utils/widgetPermissions';
import { useUsersWidgetQuery } from 'features/users/hooks/useUsersWidgetQuery';
import { useCurrentUser, useToggles } from 'shared/hooks';
import { get, split, map, trim, replace, toLower, filter, has, size, every } from 'vendor/lodash';
import { Box, Stack } from 'vendor/mui';

import { EmptyWidget } from '../EmptyWidget';

import { settingConfiguredWidgets } from './utils';

// Will normalize widget names in settings
// "My Upcoming" -> "my_upcoming"
const normalizeWidgetName = (widget: string) => toLower(replace(trim(widget), /\s/g, '_'));

function useSettingConfiguredWidgetsGrid(orderSetting: string) {
  const currentUser = useCurrentUser();
  const { canCreateAny } = useContentCreationPermissions();

  const teamSearch = `eq(teammate,${get(currentUser, 'id')})&ordering(name)`;
  const { data, isLoading } = useUsersWidgetQuery(teamSearch);
  const teamTotal = get(data, 'total', 0);

  const widgetsGrid = React.useMemo(() => {
    const settingWidgetsNames: string[] = map(
      split(get(currentUser, orderSetting, ''), ','),
      normalizeWidgetName
    );

    const filteredWidgetsNames = filter(settingWidgetsNames, (widget) =>
      has(settingConfiguredWidgets, widget)
    );
    const settingWidgets = map(filteredWidgetsNames, (widget) => {
      return {
        key: widget,
        ...settingConfiguredWidgets[widget],
      };
    });

    const permissionsCtx: WidgetsPermissionsCtx = {
      canCreateAnyContent: canCreateAny,
      user: currentUser,
      teamSize: teamTotal,
    };

    const availableWidgets = filter(settingWidgets, (widget) => {
      if (!widget.permissions || size(widget.permissions) === 0) {
        return true;
      }

      return every(widget.permissions, (func) => func(permissionsCtx));
    });

    return buildWidgetsGrid(availableWidgets);
  }, [currentUser, orderSetting, canCreateAny, teamTotal]);

  return {
    isLoading,
    widgetsGrid,
  };
}

type SettingConfiguredWidgetListProps = {
  // system setting with the widgets order
  orderSetting: string;
};

export function SettingConfiguredWidgetList(props: SettingConfiguredWidgetListProps) {
  const { orderSetting } = props;

  const { isLoading, widgetsGrid } = useSettingConfiguredWidgetsGrid(orderSetting);
  const toggles = useToggles();

  const toggleWidgets = get(toggles, 'toggle_widgets', false);
  if (!toggleWidgets) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      {!isLoading ? (
        map(widgetsGrid, (row, idx) => (
          <Box
            key={idx}
            display="flex"
            gap={3}
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              flexWrap: {
                xs: 'wrap',
                md: 'nowrap',
              },
            }}
          >
            {map(row, (item) => {
              const WidgetComponent = item.component;

              return (
                <Box key={item.key} display="flex" flex={1} minWidth={0}>
                  <WidgetComponent />
                </Box>
              );
            })}
          </Box>
        ))
      ) : (
        <Stack direction="row" spacing={3}>
          <EmptyWidget isLoading />
          <EmptyWidget isLoading />
          <EmptyWidget isLoading />
        </Stack>
      )}
    </Box>
  );
}
