import { useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { ContentItemContextMenu } from 'content-items/components/interfaces';
import actions from 'entities/actions';
import { useArchiveUnarchive } from 'entities/utils';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import DropDownMenu from 'shared/components/DropDownMenu';
import { useEncodedCurrentRoute } from 'shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { get, includes } from 'vendor/lodash';

const ProgramSessionsContextMenu = ({ content }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();
  const { archiveItem, unarchiveItem } = useArchiveUnarchive(actions.program.archive);

  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.program].edit({
              public_id_and_slug: publicIdAndSlug,
            })}
            title="Edit"
            icon="pencil"
          />
          <DropDownMenu.Item
            route={`${mapRoute('sessionCreate')}?origin=${currentRoute}&program_id=${publicId}`}
            title="Schedule session"
            icon="add"
          />
          {!isArchived && (
            <DropDownMenu.Item
              onClick={() => archiveItem(publicId)}
              title="Archive"
              icon="archive"
            />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={() => unarchiveItem(publicId)}
                title="Unarchive"
                icon="unarchive"
              />
              <DropDownMenu.Item
                onClick={() => setShowDeleteModal(true)}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            </>
          )}
          {canAssign && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default ProgramSessionsContextMenu;
