import React from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useGetContentTypeLabel } from 'catalog/hooks';
import { displayDatetime, formatDuration } from 'services/datetime';
import { useCurrentUser } from 'shared/hooks';
import { TrackItem } from 'tracks/interfaces.js';
import { isEmpty, get } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

const BULLET_POINT = '•';

interface TrackItemCardSubheaderProps {
  item: TrackItem;
}

export const TrackItemCardSubheader = (props: TrackItemCardSubheaderProps) => {
  const { item } = props;

  const { content_item: contentItem } = item;
  const { content_type: contentType, duration } = contentItem;

  const contentTypeLabel = useGetContentTypeLabel()(contentType);
  const { timezone: currentUserTimezone } = useCurrentUser();

  const isEvent = contentType === CONTENT_TYPES.event;

  const humanizedContentType = React.useMemo(() => {
    if (!isEvent) return contentTypeLabel;

    const dateTime = displayDatetime(
      contentItem.utc_datetime,
      contentItem.timezone,
      currentUserTimezone,
      contentItem.is_online
    );

    return `${contentTypeLabel} ${BULLET_POINT} ${dateTime}`;
  }, [contentItem, contentTypeLabel, currentUserTimezone, isEvent]);

  const formattedDuration = !isEmpty(duration)
    ? ` ${BULLET_POINT} ${formatDuration(duration)}`
    : '';

  const isRequired = get(item, 'is_required', true);

  const optionalLabel = !isRequired ? ` ${BULLET_POINT} Optional` : '';

  return (
    <Box sx={{ display: 'flex', columnGap: 0.5, alignItems: 'center' }}>
      <Typography variant="body2" sx={{ fontSize: 12 }}>
        {humanizedContentType} {formattedDuration} {optionalLabel}
      </Typography>
    </Box>
  );
};
