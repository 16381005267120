import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';
import { usePrevious } from 'shared/hooks';
import { isEmpty, difference } from 'vendor/lodash';

const Container = styled.div`
  padding: 12px 20px;
`;

const TitleContainer = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr 12px;
  align-items: center;

  ${({ isOpen }) =>
    isOpen &&
    `
      margin-bottom: 16px;
    `}
`;

const FilterBarWidgetTitle = styled(Text)`
  text-transform: uppercase;
`;

const FilterBarWidget = ({ title, children, icon, isOpen }) => {
  const [isOpenLocal, setIsOpenLocal] = useState(isOpen);

  const previousSelecteds = usePrevious(children?.props?.selecteds?.[children?.props?.param]);
  const selecteds = children?.props?.selecteds?.[children?.props?.param];

  // Even if isOpen is false, show the filter bar as open if filters are selected.
  useEffect(() => {
    const hasChanges = !isEmpty(difference(selecteds, previousSelecteds));
    if (!hasChanges) return;

    const hasSelecteds = !isEmpty(selecteds);
    setIsOpenLocal(hasSelecteds || isOpen);
  }, [selecteds]);

  const handleToggleClick = () => {
    setIsOpenLocal(!isOpenLocal);
  };

  const arrowIcon = isOpenLocal ? 'up' : 'down';
  return (
    <Container>
      <TitleContainer isOpen={isOpenLocal} onClick={handleToggleClick}>
        <div>
          <Icon name={icon} width={12} height={12} color={colors.neutral400} />
        </div>
        <FilterBarWidgetTitle color={colors.neutral600}>{title}</FilterBarWidgetTitle>
        <div>
          <Icon name={arrowIcon} width={12} height={12} color={colors.neutral400} />
        </div>
      </TitleContainer>
      {isOpenLocal && <div>{children}</div>}
    </Container>
  );
};

FilterBarWidget.defaultProps = {
  isOpen: true,
};

FilterBarWidget.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FilterBarWidget;
