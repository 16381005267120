import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { map } from 'vendor/lodash';

const Wrapper = styled.div`
  text-align: center;
`;

const Month = styled.span`
  display: block;
  margin: 2px;
`;

const dayHeight = 30;
const Day = styled.div`
  width: ${dayHeight}px;
  height: ${dayHeight}px;
  font-weight: 500;
  line-height: ${dayHeight}px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  padding: 0;
  margin: 0 2px 0 2px;
  background-color: #506a85;
  color: ${colors.neutral0};

  ${({ theme }) =>
    theme === 'red' &&
    `
    background-color: white;
    color: red;
    border: solid 2px red;
    font-weight: 700;
  `}
`;

const MonthDaysDisplay = (props) => {
  const { monthName, days, theme, className } = props;

  return (
    <Wrapper className={className}>
      <Month>{monthName}</Month>
      {map(days, (item, i) => (
        <Day key={i} theme={theme}>
          {item}
        </Day>
      ))}
    </Wrapper>
  );
};

MonthDaysDisplay.propTypes = {
  className: PropTypes.string,
  monthName: PropTypes.string,
  days: PropTypes.array,
  theme: PropTypes.string,
};

MonthDaysDisplay.Wrapper = Wrapper;
MonthDaysDisplay.Month = Month;
MonthDaysDisplay.Day = Day;

export default MonthDaysDisplay;
