import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import Modal from 'shared/components/Modal';

import FeedbackForm from '../FeedbackForm';

export const FeedbackModal = (props) => {
  const {
    feedbackIsPublic,
    feedbackRating,
    feedbackText,
    fieldLabels,
    fieldPlaceholders,
    handleClose,
    id,
    publicId,
    onSubmitFunc,
    onSubmitActionName,
    onSubmitSuccessDispatchAction,
    onSubmitSuccessCallback,
    user,
    schema,
  } = props;

  const dispatch = useDispatch();

  const handleFormSubmitSuccess = () => {
    if (onSubmitSuccessDispatchAction) {
      dispatch(
        onSubmitSuccessDispatchAction(
          publicId ? { public_id: publicId, idKey: 'public_id' } : { id }
        )
      );
    }

    if (onSubmitSuccessCallback) {
      onSubmitSuccessCallback();
    }

    handleClose();
  };

  if (!id && !publicId) {
    return null;
  }

  const initial = {
    id: publicId || id,
    feedback_rating: feedbackRating || 5,
    feedback_text: feedbackText,
    ...(user && { user_id: user.id }),
  };

  return (
    <Modal title="Share Your Feedback" handleClose={handleClose} height="290px" width={500}>
      <FeedbackForm
        user={user}
        initialValues={initial}
        onSubmitFunc={onSubmitFunc}
        onSubmitSuccess={handleFormSubmitSuccess}
        actionName={onSubmitActionName}
        fieldLabels={fieldLabels}
        fieldPlaceholders={fieldPlaceholders}
        feedbackIsPublic={feedbackIsPublic}
        schema={schema}
      />
    </Modal>
  );
};

FeedbackModal.propTypes = {
  id: PropTypes.number,
  publicId: PropTypes.number,
  user: PropTypes.object,
  feedbackText: PropTypes.string,
  feedbackRating: PropTypes.number,
  feedbackIsPublic: PropTypes.bool,

  handleClose: PropTypes.func,
  onSubmitFunc: PropTypes.func,
  onSubmitActionName: PropTypes.string,
  onSubmitSuccessDispatchAction: PropTypes.func,
  onSubmitSuccessCallback: PropTypes.func,

  fieldLabels: PropTypes.object,
  fieldPlaceholders: PropTypes.object,
  schema: PropTypes.object,
};

export default FeedbackModal;
