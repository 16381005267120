import { ContentCopy as CopyIcon, Launch as LaunchIcon } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';

import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from 'app/catalog/constants';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentActionsCtx } from 'features/contentitems/contexts/AssignmentActionsCtx';
import { useIsRenderedWithinTrack } from 'features/tracks/hooks/useIsRenderedWithinTrack';
import ConfirmOpenLinkModal from 'linkedcontent/components/ConfirmOpenLinkModal/ConfirmOpenLinkModal';
import { COMPLETION_POLICY } from 'linkedcontent/constants';
import { LinkedContent } from 'linkedcontent/interfaces';
import Button from 'shared/components/Button';
import { useCurrentUser, useLocalStorage } from 'shared/hooks';
import { get, isNil } from 'vendor/lodash';
import { CheckIcon } from 'vendor/mui-icons';

type LinkActionButtonsProps = {
  content: LinkedContent;
  onOpenLink: () => void;
};

function LinkActionButtons(props: LinkActionButtonsProps) {
  const { content, onOpenLink } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const [openTooltip, setOpenTooltip] = useState(false);

  const assignmentState = get(content, 'user_assignment.state');
  const isCompleted = assignmentState === ASSIGNMENT_STATES.completed;

  const displayAsPagePrimaryAction = !isRenderedWithinTrack || !isCompleted;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(content.url);
    setOpenTooltip(true);
  };

  return (
    <Box display="flex" alignItems="center" gap="12px">
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied!"
      >
        <Button
          variant={displayAsPagePrimaryAction ? 'outlined' : 'text'}
          onClick={handleCopyLink}
          startIcon={<CopyIcon />}
        >
          Copy link
        </Button>
      </Tooltip>

      <Button
        variant={displayAsPagePrimaryAction ? 'contained' : 'outlined'}
        onClick={onOpenLink}
        startIcon={<LaunchIcon />}
      >
        Open
      </Button>
    </Box>
  );
}

interface PrimaryActionButtonProps {
  content: LinkedContent;
  contentType?: string;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const currentUser = useCurrentUser();
  const { create, complete, isLoading } = useAssignmentActionsCtx();

  const [showLinkInfoModal, setShowLinkInfoModal] = useState(false);

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[content.content_type];
  const [skipInfoModal, setSkipInfoModal] = useLocalStorage(
    `skip-${content.completion_policy}-${currentUser.id}-${learningType}`
  );

  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');
  const infoModalContent =
    {
      [COMPLETION_POLICY.complete_when_opened]:
        'Opening this external link will mark it as complete.',
      [COMPLETION_POLICY.mark_completion]:
        'Remember to return to “Mark complete” after finishing this external content.',
    }[content.completion_policy] || '';

  const onMarkCompleteClick =
    isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped
      ? () => create({ is_completed: true })
      : complete;

  const handleOpenLink = () => {
    if (
      content.completion_policy === COMPLETION_POLICY.complete_when_opened &&
      assignmentState !== ASSIGNMENT_STATES.completed &&
      isCompletable
    )
      onMarkCompleteClick();
    window.open(content.url, '_blank');
  };

  const handleLinkInfoModalOpen = () => {
    if (assignmentState === ASSIGNMENT_STATES.completed || !isCompletable) {
      handleOpenLink();
      return;
    }

    if (skipInfoModal) {
      handleOpenLink();
    } else {
      setShowLinkInfoModal(true);
    }
  };

  const handleLinkInfoModalClose = (dismiss) => {
    setSkipInfoModal(dismiss);
    setShowLinkInfoModal(false);
    handleOpenLink();
  };

  if (!isCompletable) {
    return <LinkActionButtons content={content} onOpenLink={handleLinkInfoModalOpen} />;
  }

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    return (
      <>
        <LinkActionButtons content={content} onOpenLink={handleLinkInfoModalOpen} />

        {showLinkInfoModal && (
          <ConfirmOpenLinkModal
            handleConfirm={handleLinkInfoModalClose}
            handleClose={() => setShowLinkInfoModal(false)}
          >
            {infoModalContent}
          </ConfirmOpenLinkModal>
        )}
      </>
    );
  }

  return (
    <>
      <Stack direction="row" spacing="12px" alignItems="center">
        {content.completion_policy === COMPLETION_POLICY.mark_completion && (
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => onMarkCompleteClick()}
            disabled={isLoading}
          >
            Mark Complete
          </Button>
        )}

        <LinkActionButtons content={content} onOpenLink={handleLinkInfoModalOpen} />
      </Stack>
      {showLinkInfoModal && (
        <ConfirmOpenLinkModal
          handleConfirm={handleLinkInfoModalClose}
          handleClose={() => setShowLinkInfoModal(false)}
        >
          {infoModalContent}
        </ConfirmOpenLinkModal>
      )}
    </>
  );
};

export default PrimaryActionButton;
