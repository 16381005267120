import { ReactNode, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { isString } from 'vendor/lodash';
import { Box, FormControl, FormHelperText, InputLabel, styled } from 'vendor/mui';

import LazyTextEditor from '../LazyTextEditor';
import { convertTextToDraftJSBlocks } from '../LazyTextEditor/services';

import { TextEditorContainer, TextEditorContainerProps } from './layout/TextEditorContainer';

// Simulates the MUI floating input while background.
// This is CSS from the InputLabel with some adjustments to render a white line instead
// of the label text while keeping the positioning similar.
const CustomInputBackground = styled(Box)(() => ({
  position: 'absolute',
  top: '6px',
  left: '-4px',
  maxWidth: 'calc(133% - 24px)',
  transform: 'translate(14px, -9px) scale(0.75)',
  transformOrigin: 'top left',
  lineHeight: '1.4375em',
  letterSpacing: '0.00938em',
  color: 'transparent',
  backgroundColor: '#fff',
  paddingLeft: '12px',
  height: '6px',
}));

export interface TextEditorInputProps {
  value?: object | string;
  label?: string;
  placeholder?: string;
  helperText?: ReactNode;
  id?: string;
  required?: boolean;
  isError?: boolean;
  onChange?: (any) => any;
  toolbarOnFocus?: boolean;
  handleAppendToEditor?: (any) => any;
  beforeChange?: () => void;
  afterChange?: () => void;
  readOnly?: boolean;
  allowGenericLinks?: boolean;
  allowImageButton?: boolean;
  allowCloudDocButton?: boolean;
  allowConfluenceDocButton?: boolean;
  allowVideoButton?: boolean;
  allowWebsiteButton?: boolean;
  ContainerProps?: TextEditorContainerProps;
  acceptRawTextInput?: boolean;
}

/**
 * Contains the initial content logic from the old component
 * Ideally, this would be refactored to make this behavior more explicit,
 * since the expected would be that updating the value prop would update
 * the input value
 */
const parseInitialContent = (value, acceptRawTextInput) => {
  if (value) {
    if (!isString(value)) return value;

    try {
      return JSON.parse(value);
    } catch (exception) {
      if (acceptRawTextInput) return convertTextToDraftJSBlocks(value);

      console.error('Got a invalid initial value on text editor', { exception, value });
      (window as any).Sentry.captureException(exception);
    }
  }
};

const TextEditorInput = ({
  value,
  label,
  id,
  required,
  isError,
  onChange,
  toolbarOnFocus,
  ContainerProps,
  acceptRawTextInput,
  readOnly = false,
  helperText,
  ...props
}: TextEditorInputProps) => {
  const [hasText, setHasText] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (value) => {
    // Make sure hasText is up to date before calling onChange in any child component,
    // otherwise the next line may evaluate wrongly the current editor state
    onChange?.(hasText ? JSON.stringify(value) : '');
  };

  const labelText = required ? `${label} *` : label;

  const customId = id ?? `rich-text-editor-input${uuid4()}`;

  const initialContent = parseInitialContent(value, acceptRawTextInput);

  return (
    <FormControl fullWidth>
      <TextEditorContainer {...ContainerProps} isFocused={isFocused} isError={isError}>
        {label && (
          <span>
            <CustomInputBackground>{labelText}</CustomInputBackground>
            <InputLabel htmlFor={customId} variant="outlined" shrink>
              {labelText}
            </InputLabel>
          </span>
        )}
        <LazyTextEditor
          {...props}
          input={{ value }} // Temporary workaround to support embedding external content (like videos and images)
          id={customId}
          toolbarOnFocus={toolbarOnFocus}
          initialContent={initialContent}
          onChange={handleChange}
          setHasText={setHasText}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          readOnly={readOnly}
        />
      </TextEditorContainer>
      <FormHelperText error={isError}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TextEditorInput;
