import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { sessionEnrollmentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SearchInput from 'inputs/components/SearchInput';
import SelectField from 'inputs/components/SelectField';
import SessionAttendeeList from 'program/components/SessionAttendeeList';
import { ATTENDANCE_METHOD_LOCAL, ATTENDANCE_METHOD_ONLINE } from 'program/constants';
import { getSessionsAvailableCount } from 'program/services';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { useDebounce, useLabels } from 'shared/hooks';
import { filter, get, toLower } from 'vendor/lodash';

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
`;

const ControlsArea = styled.div`
  flex: 0 0 72px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: 20px;
  }
`;

const SelectFieldWrapper = styled.div`
  flex: 1 1;
`;

const SearchFieldWrapper = styled.div`
  flex: 1 1 96px;

  & > div {
    height: 32px;
  }

  & > div > div {
    height: 30px;
  }
`;

const AddAttendeesButtonWrapper = styled.div`
  flex: 1 1;
`;

const StyledHR = styled.hr`
  color: gray;
  width: 100%;
  margin: 0;
`;

const ENROLLMENT_TYPE_OPTIONS = {
  0: [ATTENDANCE_METHOD_LOCAL, ATTENDANCE_METHOD_ONLINE],
  1: [ATTENDANCE_METHOD_LOCAL],
  2: [ATTENDANCE_METHOD_ONLINE],
};

const SessionRosterAttendees = ({ session, onAddAttendeeClick }) => {
  const [searchText, setSearchText] = useState('');
  const [preferredAttendanceMethod, setPreferredAttendanceMethod] = useState(0);

  const {
    label_mentorship_sessions_attendee_title_plural: mentorshipAttendeesLabel,
    label_program_sessions_attendee_title_plural: programAttendeesLabel,
  } = useLabels();

  const attendeesLabel = session.program_id ? programAttendeesLabel : mentorshipAttendeesLabel;

  const debouncedSearchText = useDebounce(searchText, 500);

  const [
    getEnrollments,
    { status: getEnrollmentsStatus, data: enrollments, nextPage },
    loadMoreEnrollments,
  ] = useEntities(actions.sessionEnrollment.retrieveList, null, {
    schema: [sessionEnrollmentSchema],
    loadMoreAction: actions.sessionEnrollment.retrieveListLoadMore,
  });

  const refreshEnrollments = () => {
    getEnrollments({
      q: debouncedSearchText,
      session: session.public_id,
      attendance_method: ENROLLMENT_TYPE_OPTIONS[preferredAttendanceMethod],
      page_size: 5,
    });
  };

  useEffect(() => {
    refreshEnrollments();
  }, [preferredAttendanceMethod, debouncedSearchText]);

  const allEnrolledCount = session.enrollments ? session.enrollments.length : 0;
  const onlineEnrollments = filter(
    session.enrollments,
    (enrollment) => get(enrollment, 'attendance_method') === ATTENDANCE_METHOD_ONLINE
  );
  const localEnrollments = filter(
    session.enrollments,
    (enrollment) => get(enrollment, 'attendance_method') === ATTENDANCE_METHOD_LOCAL
  );

  const handlePreferredAttendanceMethod = (value) => setPreferredAttendanceMethod(value);
  const handleOnSearchTextChange = (value) => setSearchText(value);

  const selectFieldOptions = filter(
    [
      {
        label: `All (${allEnrolledCount})`,
        value: 0,
        show: true,
      },
      {
        label: `In-Person (${localEnrollments.length})`,
        value: 1,
        show: get(session, 'allows_local', false),
      },
      {
        label: `Online (${onlineEnrollments.length})`,
        value: 2,
        show: get(session, 'allows_online', false),
      },
    ],
    'show'
  );

  const isSessionAvailable =
    session.attendance_limit === 0 || getSessionsAvailableCount(session) > 0;

  return (
    <ContentContainer>
      <ControlsArea>
        <SelectFieldWrapper>
          <SelectField
            options={selectFieldOptions}
            input={{
              value: preferredAttendanceMethod,
              onChange: handlePreferredAttendanceMethod,
            }}
          />
        </SelectFieldWrapper>
        <SearchFieldWrapper>
          <SearchInput
            placeholder="Search by name"
            value={searchText}
            onChange={handleOnSearchTextChange}
          />
        </SearchFieldWrapper>
        {onAddAttendeeClick && (
          <AddAttendeesButtonWrapper>
            <Button
              fullWidth
              icon={<Icon name="plus" />}
              size="small"
              onClick={onAddAttendeeClick}
              disabled={!isSessionAvailable}
              title={
                isSessionAvailable
                  ? `Add ${toLower(attendeesLabel)}`
                  : `Cannot add ${toLower(attendeesLabel)} because the session is full`
              }
            >
              Add {attendeesLabel}
            </Button>
          </AddAttendeesButtonWrapper>
        )}
      </ControlsArea>
      <StyledHR />
      <SessionAttendeeList
        session={session}
        filterText={searchText}
        enrollments={enrollments}
        refreshEnrollments={refreshEnrollments}
        getEnrollmentsStatus={getEnrollmentsStatus}
        hasNextPage={Boolean(nextPage)}
        loadMoreEnrollments={loadMoreEnrollments}
      />
    </ContentContainer>
  );
};

SessionRosterAttendees.propTypes = {
  session: PropTypes.object,
  onAddAttendeeClick: PropTypes.func,
};

export default SessionRosterAttendees;
