import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Pill from 'shared/components/Pill';
import { map, isEmpty, pull, cloneDeep, omit, includes } from 'vendor/lodash';

const PILL_Y_SPACING = '8px';
const PILL_X_SPACING = '6px';

const Container = styled.div`
  margin: -${PILL_Y_SPACING} -${PILL_X_SPACING} 20px;
  display: flex;
  flex-wrap: wrap;

  // Pill
  > div {
    margin: ${PILL_Y_SPACING} ${PILL_X_SPACING} 0;
  }
`;

const FilterPills = ({ onChange, selecteds, pills, notListAttributes, onRemove }) => {
  const handleFilterRemoval = (removed) => {
    let filters = cloneDeep(selecteds);

    if (includes(notListAttributes, removed.group)) {
      filters = omit(filters, removed.group);
    } else {
      pull(filters[removed.group], removed.id);
    }

    onChange(filters);
  };

  if (isEmpty(pills)) return null;

  const onFilterRemove = onRemove || handleFilterRemoval;

  return (
    <Container>
      {map(pills, (item) => (
        <Pill
          key={`${item.id}-${item.label}`}
          label={item.label}
          imageSrc={item.imageSrc}
          item={item}
          icon={item.icon}
          onEdit={() => onFilterRemove(item)}
          size="medium"
          variant={item.variant ?? 'lightGray'}
        />
      ))}
    </Container>
  );
};

FilterPills.propTypes = {
  selecteds: PropTypes.object,
  pills: PropTypes.array,
  onChange: PropTypes.func,
  notListAttributes: PropTypes.array,
  onRemove: PropTypes.func,
};

FilterPills.defaultProps = {
  notListAttributes: ['q'],
};

export default FilterPills;
