import { SvgIcon, SxProps } from '@mui/material';
import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';
import MUIPagination, { PaginationProps } from '@mui/material/Pagination';
import { Variant } from '@mui/material/styles/createTypography';
import MUITab, { TabProps } from '@mui/material/Tab';
import MUITabs, { TabsProps } from '@mui/material/Tabs';
import MUITypography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GridActionsCellItem as MUIGridActionsCellItem } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useEllipsisCheck } from 'app/shared/hooks';
import { isNil, isNumber, omit } from 'vendor/lodash';

export * from '@mui/material';
export * from '@mui/material/colors';
export * from '@mui/material/styles';

export { LicenseInfo } from '@mui/x-license-pro';
export {
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridFooterContainer,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  GridCellEditStopReasons,
  GridCellModes,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
export type {
  GridColDef,
  GridColumns,
  GridValidRowModel,
  GridSortModel,
  GridSortItem,
  GridSortDirection,
  GridRowParams,
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridActionsCellItemProps,
  GridRowOrderChangeParams,
  GridPinnedColumns,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';

// The GridActionsCellItem typing it is wrong, so we need to by pass the type checking.
export const GridActionsCellItem = MUIGridActionsCellItem as any;

export function Tabs({ sx, ...props }: TabsProps) {
  return <MUITabs sx={{ minHeight: 'auto', ...sx }} {...props} />;
}

export function Tab({ sx, ...props }: TabProps<typeof MUITab, { component?: typeof MUITab }>) {
  return <MUITab sx={{ textTransform: 'capitalize', minHeight: 'auto', ...sx }} {...props} />;
}

export function LinkTab(props: TabProps<ReactRouterLink, Record<string, never>>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tab component={ReactRouterLink} {...props} />;
}

export interface AvatarProps extends MUIAvatarProps {
  imgGrayscale?: boolean;
}

export const Avatar = ({ imgGrayscale, sx, ...props }: AvatarProps) => {
  const appendSx = imgGrayscale ? { filter: 'grayscale(100%)' } : {};
  return <MUIAvatar sx={{ ...sx, ...appendSx }} {...props} />;
};

interface CollapsableTypographyProps extends React.ComponentProps<typeof MUITypography> {
  collapsedSize?: number;
  ellipsisProps?: {
    lineHeight?: string;
    overflow?: string;
    maxHeight?: string;
    noWrap?: boolean;
  };
}

const CollapsableTypography = ({
  collapsedSize = 80,
  ellipsisProps = {},
  ...props
}: CollapsableTypographyProps) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck({ multipleLines: true });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [typographyEllipsisProps, setTypographyEllipsisProps] = useState(ellipsisProps);

  const boxSx =
    !isCollapsed && hasEllipsis
      ? {
          display: 'flex',
          alignItems: 'end',
          maskImage: 'linear-gradient(to bottom, black 50%, transparent)',
        }
      : {};

  return (
    <>
      <Collapse
        in={isCollapsed}
        collapsedSize={collapsedSize}
        onEnter={() => setTypographyEllipsisProps({})}
        onExited={() => setTypographyEllipsisProps(ellipsisProps)}
      >
        <Box sx={boxSx}>
          <MUITypography ref={nodeRef} {...props} {...typographyEllipsisProps} />
        </Box>
      </Collapse>
      {hasEllipsis && (
        <Button size="small" onClick={() => setIsCollapsed(!isCollapsed)} sx={{ marginTop: -2 }}>
          {isCollapsed ? 'View less' : 'View more'}
        </Button>
      )}
    </>
  );
};

export interface TypographyProps extends React.ComponentProps<typeof MUITypography> {
  sx?: SxProps;
  maxLines?: number;
  lineHeight?: number;
  lineHeightUnity?: string;
  collapsable?: boolean;
  collapsedSize?: number;
  component?: any;
}

export const Typography = ({
  sx,
  noWrap,
  maxLines,
  component,
  lineHeight = 1.43,
  lineHeightUnity = 'em',
  collapsable = false,
  collapsedSize = 80,
  ...props
}: TypographyProps) => {
  const [extraProps, addEllipsis] =
    noWrap && maxLines
      ? [
          {
            lineHeight: lineHeight + lineHeightUnity,
            overflow: 'hidden',
            maxHeight: lineHeight * maxLines + lineHeightUnity,
          },
          true,
        ]
      : [{ noWrap }, false];

  if (!addEllipsis && !collapsable) {
    return <MUITypography sx={sx} component={component} {...props} {...extraProps} />;
  }

  if (collapsable) {
    return (
      <CollapsableTypography
        sx={sx}
        ellipsisProps={extraProps}
        collapsedSize={collapsedSize}
        {...props}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        maskImage: 'linear-gradient(to bottom, black 50%, transparent)',
      }}
    >
      <MUITypography sx={sx} component={component} {...props} {...extraProps} />
    </Box>
  );
};

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value?: number; labelVariant?: Variant }
) {
  const { value, labelVariant } = props;
  const hasNumberValue = !isNil(value) && isNumber(value);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          {...omit(props, ['labelVariant', 'value'])}
          variant="determinate"
          value={hasNumberValue ? value : 0}
        />
      </Box>
      <Box>
        <MUITypography variant={labelVariant || 'body2'} color="text.secondary">
          {hasNumberValue ? `${Math.round(value)}%` : '--%'}
        </MUITypography>
      </Box>
    </Box>
  );
}

export function Pagination(props: PaginationProps) {
  // Only render the pagination component if there are at least two pages
  const { count } = props;
  if (count && count <= 1) {
    return null;
  }
  return <MUIPagination {...props} />;
}

export const RouterLink = (
  props: MUILinkProps<ReactRouterLink, { component: ReactRouterLink }>
) => {
  const defaultProps: MUILinkProps<ReactRouterLink, { component: ReactRouterLink }> = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: ReactRouterLink,
    underline: 'none',
  };

  return <MUILink {...defaultProps} {...props} />;
};

export const Link: React.FC<React.ComponentProps<typeof RouterLink>> &
  React.FC<MUILinkProps<'a'>> = (
  props: React.ComponentProps<typeof RouterLink> | MUILinkProps<'a'>
) => {
  if ('to' in props) {
    return <RouterLink {...props} />;
  }

  return <MUILink {...props} />;
};

export { SvgIcon, Button, IconButton, makeStyles, Chip };
