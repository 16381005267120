import PropTypes from 'prop-types';
import React from 'react';

import WidgetMultiSelectFilter from 'app/navigation/components/FilterBar/WidgetMultiSelectFilter';
import {
  MENTORSHIP_KEY_LOCAL_STORAGE,
  MENTOR_PREFERENCE_TAG_TYPE,
  MENTEE_PREFERENCE_TAG_TYPE,
} from 'mentorship/constants';
import GroupsFilter from 'navigation/components/FilterBar/GroupsFilter';
import LocationFilter from 'navigation/components/FilterBar/LocationFilter';
import TextFilter from 'navigation/components/FilterBar/TextFilter';
import UserFilter from 'navigation/components/FilterBar/UserFilter/UserFilter';
import { FilterBar } from 'navigation/components/FilterPage';
import { FilterPageContentWrapper } from 'shared-layouts/BaseFilterPage';
import ContentTopbarPage from 'shared-layouts/ContentTopbarPage';
import { useLocalStorage, useCurrentUser, useFiltersOrder } from 'shared/hooks';
import { buildFlexibleFiltersWidget, buildFlexibleTagsFieldNameList } from 'topics/services';
import {
  USER_AVAILABILITY_AS_MENTOR_ONLINE,
  USER_AVAILABILITY_AS_MENTOR_FRIENDLY_TO_MY_TIME,
} from 'users/constants';
import {
  filter,
  get,
  includes,
  pull,
  isNil,
  split,
  map,
  isUndefined,
  isEmpty,
  reduce,
} from 'vendor/lodash';

const PeoplePageBaseContainer = ({
  children,
  options = {},
  selectedFilters,
  updateSelectedFilters,
  viewportWidthOptions,
  viewMode,
  setSelectedLocations,
  setSelectedOrgLeads,
  setSelectedManagers,
  setSelectedTeammates,
  setSelectedGroups,
  setSelectedTopics,
}) => {
  const currentUser = useCurrentUser();
  const [currentStateFilter, updateStateFilter] = useLocalStorage(MENTORSHIP_KEY_LOCAL_STORAGE);

  const { label_live_streamed: labelLiveStreamed } = get(currentUser, 'labels');

  const {
    filter_order_mentees: labelAvailableMenteeFilters,
    filter_order_mentors: labelAvailableMentorFilters,
    filter_order_people: labelAvailablePeopleFilters,
  } = useFiltersOrder();

  const getFilterOrder = () => {
    let filterOrder = null;
    switch (viewMode) {
      case 'mentor':
        filterOrder = labelAvailableMentorFilters;
        break;
      case 'mentee':
        filterOrder = labelAvailableMenteeFilters;
        break;
      default:
        filterOrder = labelAvailablePeopleFilters;
    }
    return filterOrder ? split(filterOrder, ', ') : [];
  };

  const toggleTypes = ['toggle_mentorship'];
  const allowedFlexibleFiltersTagTypes = buildFlexibleTagsFieldNameList(currentUser, toggleTypes);

  const handleAvailabilityDependentOptionsChange = (param, selecteds, selected) => {
    if (param !== 'availability_as_mentor') return selected;

    const currentSelected = selecteds[param];
    const newSelected = selected;

    // If "Friendly to My Time" is selected, also selects "Online".
    if (
      includes(newSelected, USER_AVAILABILITY_AS_MENTOR_FRIENDLY_TO_MY_TIME) &&
      !includes(newSelected, USER_AVAILABILITY_AS_MENTOR_ONLINE) &&
      !includes(currentSelected, USER_AVAILABILITY_AS_MENTOR_ONLINE)
    ) {
      newSelected.push(USER_AVAILABILITY_AS_MENTOR_ONLINE);
    }

    // If both "Friendly to My Time" and "Online" are selected and "Online" is deselected,
    // also deselects "Friendly to My Time".
    if (
      includes(currentSelected, USER_AVAILABILITY_AS_MENTOR_FRIENDLY_TO_MY_TIME) &&
      includes(currentSelected, USER_AVAILABILITY_AS_MENTOR_ONLINE) &&
      !includes(newSelected, USER_AVAILABILITY_AS_MENTOR_ONLINE)
    ) {
      pull(newSelected, USER_AVAILABILITY_AS_MENTOR_FRIENDLY_TO_MY_TIME);
    }

    return newSelected;
  };

  let modeWidgets = [];
  const listToObject = (collection) => {
    return reduce(collection, (acc, curr) => ({ ...acc, ...curr }), {});
  };

  if (viewMode === 'mentor') {
    const flexibleTagsFiltersWidgetsMentor = buildFlexibleFiltersWidget({
      currentUser,
      toggleTypes,
      setSelectedTopics,
      allowedTagTypes: MENTOR_PREFERENCE_TAG_TYPE,
      params: {
        toggleHide: false,
        show: true,
        isOpen: false,
      },
    });
    modeWidgets = {
      'Available sessions': {
        show: true,
        title: 'Available sessions',
        icon: 'clock',
        component: (
          <WidgetMultiSelectFilter
            key="availability-as-mentor-filter"
            param="availability_as_mentor"
            options={options.availability_as_mentor}
            getSelectedOnChange={handleAvailabilityDependentOptionsChange}
          />
        ),
      },
      ...listToObject(flexibleTagsFiltersWidgetsMentor),
    };
  } else if (viewMode === 'mentee') {
    const flexibleTagsFiltersWidgetsMentee = buildFlexibleFiltersWidget({
      currentUser,
      toggleTypes,
      setSelectedTopics,
      allowedTagTypes: MENTEE_PREFERENCE_TAG_TYPE,
      params: {
        toggleHide: false,
        show: true,
        isOpen: false,
      },
    });
    modeWidgets = {
      ...listToObject(flexibleTagsFiltersWidgetsMentee),
    };
  }

  const flexibleTagsFiltersWidgets = buildFlexibleFiltersWidget({
    currentUser,
    toggleTypes,
    setSelectedTopics,
    allowedTagTypes: pull(
      allowedFlexibleFiltersTagTypes,
      MENTEE_PREFERENCE_TAG_TYPE,
      MENTOR_PREFERENCE_TAG_TYPE
    ),
  });

  const widgets = {
    ...modeWidgets,
    Name: {
      show: true,
      title: 'Name',
      icon: 'text',
      component: <TextFilter key="name-filter" param="name" placeholder="Search by name" />,
    },
    Title: {
      show: true,
      title: 'Title',
      icon: 'suitcase',
      component: <TextFilter key="title-filter" param="title" placeholder="Search by title" />,
    },
    Department: {
      show: true,
      title: 'Department',
      icon: 'room',
      component: (
        <TextFilter
          key="departtment-filter"
          param="department"
          placeholder="Search by department"
        />
      ),
    },
    Location: {
      show: true,
      title: 'Location',
      icon: 'location',
      component: (
        <LocationFilter
          key="location-filter"
          param="location"
          labelLiveStreamed={labelLiveStreamed}
          setSelectedLocations={setSelectedLocations}
        />
      ),
    },
    OrgLead: {
      show: true,
      title: 'Org Lead',
      icon: 'user',
      component: (
        <UserFilter
          key="dropdown-filter"
          param="org_lead"
          label="Org Lead"
          extraQueryParams={{ has_directs: true }}
          setSelectedUsers={setSelectedOrgLeads}
        />
      ),
    },
    Manager: {
      show: true,
      title: 'Manager',
      icon: 'user',
      component: (
        <UserFilter
          key="dropdown-filter"
          param="manager"
          label="Manager"
          extraQueryParams={{ has_directs: true }}
          setSelectedUsers={setSelectedManagers}
        />
      ),
    },
    Teammate: {
      show: true,
      title: 'Teammate',
      icon: 'user',
      component: (
        <UserFilter
          key="dropdown-filter"
          param="teammate"
          label="Teammate"
          extraQueryParams={{ has_manager: true }}
          setSelectedUsers={setSelectedTeammates}
        />
      ),
    },
    Groups: {
      show: true,
      title: 'Groups',
      icon: 'group',
      component: <GroupsFilter key="dropdown-filter" setSelectedGroups={setSelectedGroups} />,
    },
    ...listToObject(flexibleTagsFiltersWidgets),
  };

  const filters = [];
  const mountAvailableFilters = () => {
    const splittedFixedString = getFilterOrder();
    map(splittedFixedString, (filter) => {
      if (!isUndefined(widgets[filter])) {
        filters.push(widgets[filter]);
      }
    });
    return filters;
  };

  const availableFilters = mountAvailableFilters();

  const renderSidebarContent = isEmpty(selectedFilters)
    ? null
    : ({ closeSidebar }) => (
        <FilterBar
          selecteds={selectedFilters}
          onChange={updateSelectedFilters}
          widgets={filter(availableFilters, ({ show }) => show)}
          closeSidebar={closeSidebar}
          param="q"
        />
      );

  // The filter object must have at least one item, which in this case is the sorting field.
  return (
    <ContentTopbarPage
      renderSidebarContent={renderSidebarContent}
      comesFromContent={!isNil(currentStateFilter) ? currentStateFilter : true}
      updateStateFilter={updateStateFilter}
    >
      <FilterPageContentWrapper viewportWidthOptions={viewportWidthOptions}>
        {children}
      </FilterPageContentWrapper>
    </ContentTopbarPage>
  );
};

PeoplePageBaseContainer.propTypes = {
  viewMode: PropTypes.oneOf(['mentor', 'mentee', 'people']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  options: PropTypes.object,
  updateSelectedFilters: PropTypes.func,
  selectedFilters: PropTypes.object,
  viewportWidthOptions: PropTypes.shape({
    tablet: PropTypes.string,
    desktopSm: PropTypes.string,
    desktopLg: PropTypes.string,
  }),
  setSelectedLocations: PropTypes.func,
  setSelectedOrgLeads: PropTypes.func,
  setSelectedManagers: PropTypes.func,
  setSelectedTeammates: PropTypes.func,
  setSelectedGroups: PropTypes.func,
  setSelectedTopics: PropTypes.func,
};

PeoplePageBaseContainer.defaultProps = {
  viewportWidthOptions: {},
};

export default PeoplePageBaseContainer;
