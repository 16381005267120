import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ISODatetimeOffsetInput from 'inputs/components/ISODatetimeOffsetInput/ISODatetimeOffsetInput';
import colors from 'services/colors';
import Text from 'shared/components/Text';
import { noop } from 'vendor/lodash';

const RelativeDateRangeInput = ({ startDate, endDate, onChange }) => {
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  useEffect(() => {
    if (localStartDate !== startDate || localEndDate !== endDate) {
      onChange({
        ...(localStartDate && { startDate: localStartDate }),
        ...(localEndDate && { endDate: localEndDate }),
      });
    }
  }, [localStartDate, localEndDate]);

  useEffect(() => {
    setLocalStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setLocalEndDate(endDate);
  }, [endDate]);

  return (
    <Stack spacing={2} sx={{ width: '360px' }}>
      <Stack>
        <Text size="h5" color={colors.neutral900}>
          From
        </Text>
        <ISODatetimeOffsetInput
          label="From"
          value={localStartDate}
          onChange={(newValue) => {
            setLocalStartDate(newValue);
          }}
        />
      </Stack>
      <Stack>
        <Text size="h5" color={colors.neutral900}>
          Until
        </Text>
        <ISODatetimeOffsetInput
          label="Until"
          value={localEndDate}
          onChange={(newValue) => {
            setLocalEndDate(newValue);
          }}
        />
      </Stack>
    </Stack>
  );
};

RelativeDateRangeInput.defaultProps = {
  onChange: noop,
};

RelativeDateRangeInput.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func,
};

export default RelativeDateRangeInput;
