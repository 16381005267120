import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import actions from 'entities/actions';
import { surveyPreviewSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import BaseContainer from 'navigation/components/BaseContainer';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import InfoBox from 'shared/components/InfoBox';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';

import SurveyPreviewContent from './SurveyPreviewContent';

const Content = styled.div`
  margin-top: 32px;
`;

const BreadcrumbWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const InfoWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const SurveyCard = styled.div`
  background: ${colors.neutral0};
  max-width: 580px;
  margin: 16px auto 48px;
  border-radius: 10px;
`;

const SurveyCardHeader = styled.div`
  padding: 24px 32px;
`;

const SurveyCardBody = styled.div`
  padding: 32px;
  border-top: 1px solid ${colors.neutral200};
`;

const SurveyCardFooter = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.neutral200};

  display: flex;
  justify-content: center;
`;

const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const SurveyPreview = () => {
  const { trackActivity } = useMetrics();

  const [request, { status, error, data: survey }] = useEntities(
    actions.survey.preview,
    ({ status, data: survey }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.SURVEY_PREVIEW, {
          surveyId: survey.id,
        });
      }
    },
    {
      schema: surveyPreviewSchema,
    }
  );

  const { id } = useParams();
  useEffect(() => {
    request(id);
  }, []);

  if (status === STATUS_ERROR) {
    let errorMessage = error;
    if (error.detail) {
      errorMessage = error.detail;
    }
    return (
      <BaseContainer backgroundPanel>
        <Content>
          <SurveyCard>
            <SurveyCardHeader>
              <Text size="h2">{errorMessage}</Text>
            </SurveyCardHeader>
          </SurveyCard>
        </Content>
      </BaseContainer>
    );
  }

  if (status !== STATUS_DONE) {
    return <Loading hasMargin />;
  }

  return (
    <BaseContainer backgroundPanel>
      <Content>
        <BreadcrumbWrapper>
          <Breadcrumbs
            items={[
              { link: mapRoute('dashboardSurveys'), label: 'Surveys Dashboard' },
              {
                link: mapRoute('dashboardSurveyInstances', { id: `${survey.id}` }),
                label: `Survey: ${survey.title}`,
              },
              { label: 'Survey Form Preview' },
            ]}
            variant="alternate"
          />
        </BreadcrumbWrapper>
        <InfoWrapper>
          <InfoBox
            type="warning"
            fontSize="14px"
            content="This page is just a preview. To collect responses for this survey, attach it to your events or content."
          />
        </InfoWrapper>
        <SurveyCard>
          <SurveyCardHeader>
            <Text size="h1" block>
              {survey.title}
            </Text>
            <Subtitle>
              <Text color={colors.neutral600} size="h3" medium>
                Survey: {survey.title}
              </Text>
            </Subtitle>
          </SurveyCardHeader>
          <SurveyCardBody>
            <SurveyPreviewContent survey={survey} />
          </SurveyCardBody>
          <SurveyCardFooter />
        </SurveyCard>
      </Content>
    </BaseContainer>
  );
};

export default SurveyPreview;
