import StarIcon from '@mui/icons-material/Star';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import colors from 'services/colors';
import { get } from 'vendor/lodash';

import { useContentItemCardCtx } from './ContentItemCardContext';

export const FooterContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: ${colors.neutral500};
`;

const RatingContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.rating700};
`;

function getRating(contentItem) {
  const rating =
    contentItem.avg_feedback_rating ||
    contentItem.average_feedback_rating ||
    contentItem.feedback_rating ||
    0;

  if (rating === 0) {
    return null;
  }

  return Math.round(rating * 10) / 10;
}

export function ContentItemCardFooter() {
  const { contentItem } = useContentItemCardCtx();

  const assignmentsCount = get(
    contentItem,
    'total_assignments',
    get(contentItem, 'assignments_count', 0)
  );
  const rating = getRating(contentItem);

  const canDisplay = assignmentsCount > 0 || rating;

  if (!canDisplay) {
    return null;
  }

  return (
    <>
      <Divider />
      <FooterContainer>
        <Box display="flex" alignItems="center">
          {assignmentsCount > 0 && (
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: '0.875rem',
              }}
            >
              {assignmentsCount} {assignmentsCount > 1 ? 'people' : 'person'} engaged
            </Typography>
          )}
        </Box>

        {rating && (
          <RatingContainer>
            <StarIcon sx={{ fontSize: 12, color: colors.rating600 }} />
            <Typography variant="body2" component="span">
              {rating}
            </Typography>
          </RatingContainer>
        )}
      </FooterContainer>
    </>
  );
}
