import { ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY } from 'shared/constants';
import { includes } from 'vendor/lodash';

export const hasEditPermission = (role) => {
  return includes([ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY], role);
};

export const hasCreatePermission = (role) => {
  return includes([ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY], role);
};

export const hasReadPermission = (role) => {
  return includes([ADMIN_ROLE_KEY, ORGANIZER_ROLE_KEY], role);
};
