import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './style.scss';

export class Option extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { disabled, onClick, value } = this.props;
    if (!disabled) {
      onClick(value);
    }
  }

  render() {
    const { children, className, activeClassName, active, disabled, title } = this.props;
    /* eslint-disable jsx-a11y/onclick-has-role */
    return (
      <div
        tabIndex="0"
        className={classNames('rdw-option-wrapper', className, {
          [`rdw-option-active ${activeClassName}`]: active,
          'rdw-option-disabled': disabled,
        })}
        onClick={this.onClick}
        aria-selected={active}
        title={title}
      >
        {children}
      </div>
    );
    /* eslint-enable jsx-a11y/onclick-has-role */
  }
}

Option.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
  value: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default Option;
