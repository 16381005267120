/* @flow */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './style.scss';

export class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlighted: -1,
    };

    this.onChange = this.onChange.bind(this);
    this.setHighlighted = this.setHighlighted.bind(this);
    this.toggleExpansion = this.toggleExpansion.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
  }

  componentWillReceiveProps(props) {
    if (this.props.expanded && !props.expanded) {
      this.setState({
        highlighted: -1,
      });
    }
  }

  onChange(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
    this.toggleExpansion();
  }

  setHighlighted(highlighted) {
    this.setState({
      highlighted,
    });
  }

  toggleExpansion() {
    const { doExpand, doCollapse, expanded } = this.props;
    if (expanded) {
      doCollapse();
    } else {
      doExpand();
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  render() {
    const {
      expanded,
      children,
      className,
      optionWrapperClassName,
      ariaLabel,
      onExpandEvent,
      title,
    } = this.props;
    const { highlighted } = this.state;
    const options = children.slice(1, children.length);
    /* eslint-disable jsx-a11y/onclick-has-role */
    return (
      <div
        className={classNames('rdw-dropdown-wrapper', className)}
        aria-expanded={expanded}
        aria-label={ariaLabel || 'rdw-dropdown'}
      >
        <a tabIndex="0" className="rdw-dropdown-selectedtext" onClick={onExpandEvent} title={title}>
          {children[0]}
          <div
            className={classNames({
              'rdw-dropdown-carettoclose': expanded,
              'rdw-dropdown-carettoopen': !expanded,
            })}
          />
        </a>
        {expanded ? (
          <ul
            tabIndex="0"
            className={classNames('rdw-dropdown-optionwrapper', optionWrapperClassName)}
            onClick={this.stopPropagation}
          >
            {React.Children.map(options, (option, index) => {
              const temp =
                option &&
                React.cloneElement(option, {
                  onSelect: this.onChange,
                  highlighted: highlighted === index,
                  setHighlighted: this.setHighlighted,
                  index,
                });
              return temp;
            })}
          </ul>
        ) : (
          undefined
        )}
      </div>
    );
    /* eslint-enable jsx-a11y/onclick-has-role */
  }
}

Dropdown.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  doExpand: PropTypes.func,
  doCollapse: PropTypes.func,
  onExpandEvent: PropTypes.func,
  optionWrapperClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
};

export default Dropdown;
