import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, Fields, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import { CONTENT_TYPES, FACILITATOR_ROLES } from 'catalog/constants';
import { parseResources } from 'content-resources/services';
import actions from 'entities/actions';
import { programSelector } from 'entities/selectors';
import CoverImageField from 'inputs/components/CoverImageField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import InputLabel from 'inputs/components/InputLabel';
import LocationSelectField from 'inputs/components/LocationSelectField';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import SectionTrackItemFields from 'inputs/components/SectionTrackItemFields';
import SingleDatePickerField from 'inputs/components/SingleDatePickerField';
import TagTypesInputFields from 'inputs/components/TagTypesInputFields';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import * as scheduledTracksService from 'services/scheduled-tracks';
import { useSettingsSectionsList } from 'settings/hooks';
import { prepareSettingsValuesForSubmission } from 'settings/services';
import Button from 'shared/components/Button';
import { FormFieldGroup } from 'shared/components/Form';
import InfoText from 'shared/components/InfoText';
import NewContentForm from 'shared/components/NewContentForm';
import AccessControlSection from 'shared/components/NewContentForm/sections/AccessControlSection';
import EnrollmentCapacitySection from 'shared/components/NewContentForm/sections/EnrollmentCapacitySection';
import ResourcesSection from 'shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from 'shared/components/NewContentForm/sections/SurveysSection';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { FormSection } from 'shared/components/types';
import { useFormPreventTransition, useFormSelector, useLabels } from 'shared/hooks';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from 'topics/services';
import TrackItemAddContentModal from 'tracks/components/TrackItemAddContentModal';
import TrackItemsForm from 'tracks/components/TrackItemsForm';
import { useCreateTrackSection, useAddTrackItems } from 'tracks/hooks';
import { getTrackSectionsAndItemsCountText } from 'tracks/services';
import {
  capitalize,
  cloneDeep,
  concat,
  findKey,
  forEach,
  get,
  isEmpty,
  isNil,
  map,
  omit,
  omitBy,
  pick,
  set,
  toLower,
} from 'vendor/lodash';
import { Box, IconButton, Stack, Alert } from 'vendor/mui';
import { MenuOpenOutlinedIcon, SplitscreenIcon } from 'vendor/mui-icons';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import { AssessmentQuestionModalsContext } from '../TrackForm';

type ScheduledTrackFormAlertsProps = {
  form: string;
  onChange: (fieldName: string, value: any) => void;
};

function ScheduledTrackFormAlerts(props: ScheduledTrackFormAlertsProps) {
  const { form, onChange } = props;

  const trackItems = useFormSelector(form, 'track_items');
  const unorderedItemsAlert = useFormSelector(form, 'unordered_items_alert');
  const itemsOutOfDateRangeAlert = useFormSelector(form, 'items_out_of_date_range_alert');

  const handleOrderItemsChronologically = () => {
    const clonedTrackItems = cloneDeep(trackItems);

    clonedTrackItems.sort((a, b) => {
      const { base, target } = scheduledTracksService.getItemDatesForComparison(a, b);
      return base.diff(target);
    });

    forEach(trackItems, (item, idx) => {
      const oldTrackItemSection = get(item, 'section');
      set(clonedTrackItems, [idx, 'section'], oldTrackItemSection);
    });

    onChange('track_items', clonedTrackItems);
    onChange('unordered_items_alert.display', false);
  };

  const handleDismissAlert = (alertName: string) => {
    onChange(`${alertName}.display`, false);
    onChange(`${alertName}.dismissed`, true);
  };

  const displayUnorderedItemsAlert = get(unorderedItemsAlert, 'display', false);
  const displayItemsOutOfDateRangeAlert = get(itemsOutOfDateRangeAlert, 'display', false);

  const shouldDisplayAlerts = displayUnorderedItemsAlert || displayItemsOutOfDateRangeAlert;

  if (!shouldDisplayAlerts) return null;

  return (
    <>
      {displayUnorderedItemsAlert && (
        <Alert
          severity="warning"
          action={
            <Stack direction="row" gap={1}>
              <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={handleOrderItemsChronologically}
                type="button"
              >
                Reorder events
              </Button>
              <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => handleDismissAlert('unordered_items_alert')}
                type="button"
              >
                Dismiss
              </Button>
            </Stack>
          }
        >
          The order of track item events do not correspond with their chronological order.
        </Alert>
      )}

      {displayItemsOutOfDateRangeAlert && (
        <Alert
          severity="warning"
          action={
            <Button
              variant="text"
              color="inherit"
              size="small"
              onClick={() => handleDismissAlert('items_out_of_date_range_alert')}
              type="button"
            >
              Dismiss
            </Button>
          }
        >
          Some track items are out of the date range of the scheduled track.
        </Alert>
      )}
    </>
  );
}

const validateRequired = Form.validations.required();

const ACTION_BUTTON_SX = {
  color: colors.emphasis700,
  borderColor: colors.emphasis700,
  '&:hover': {
    color: colors.emphasis800,
    borderColor: colors.emphasis800,
  },
};

const CoverContainer = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  > * {
    flex: 1;
  }
`;

interface SurveyRelationship {
  id?: number;
  survey_id: any;
  survey_cutoff_period_value: any;
  survey_cutoff_period_option: any;
  survey_schedule_period_value: any;
  survey_schedule_period_option: any;
  survey_schedule_kind: any;
}

interface SlackChannel {
  name: string;
}

interface ScheduledTrackPayload {
  name: string;
  original_track_id: number;
  content_body: object;
  topics: any[];
  cover: string;
  track_items: any[];
  maintainers_ids: any[];
  capacity: number;
  is_hidden: boolean;
  hide_attendees: boolean;
  groups_ids: number[];
  external_survey_link: string;
  survey_relationships: any[];
  due_date_period_value: number;
  due_date_period_option: string;
  office_hour_id: number;
  resources: any[];
  slack_channel?: SlackChannel | null;
  starts_at: string;
  ends_at: string;
  location_id: number;
  is_online: boolean;
  is_local: boolean;
  sections: any[];
  tags?: any[];
  facilitators?: any[];
  settings?: any;
  swapped_events_map?: any;
}

interface InitialValues {
  assignments_count: number;
  survey_relationships: any[];
  external_survey_link: string;
  is_hidden: boolean;
  groups_ids: number[];
}

interface TrackFormProps {
  form: any;
  topBarActionName?: string;
  isEdit?: boolean;
  backRoute?: string;
  change: (fieldName: string, value: any) => void;
  invalid?: boolean;
  error?: any;
  handleSubmit?: () => void;
  submitting?: boolean;
  isModalForm?: boolean;
  initialValues: InitialValues;
  breadcrumbsItemList?: any[];
  selectedOfficeHour?: object;
  currentUser?: any;
}

const TrackForm = (props: TrackFormProps) => {
  const {
    form,
    topBarActionName,
    isEdit,
    backRoute,
    change,
    invalid,
    error,
    handleSubmit,
    submitting,
    isModalForm,
    initialValues,
    breadcrumbsItemList,
    selectedOfficeHour,
    currentUser,
  } = props;

  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();
  const trackName = useFormSelector(form, 'name');
  const trackSections = useFormSelector(form, 'sections');
  const trackItems = useFormSelector(form, 'track_items');
  const startsAtFormValue = useFormSelector(form, 'starts_at');
  const endsAtFormValue = useFormSelector(form, 'ends_at');
  const startsAt = startsAtFormValue ? moment(startsAtFormValue) : null;
  const endsAt = endsAtFormValue ? moment(endsAtFormValue) : null;
  const swappedEventsMap = useFormSelector(form, 'swapped_events_map') || {};
  const unorderedItemsAlert = useFormSelector(form, 'unordered_items_alert');
  const itemsOutOfDateRangeAlert = useFormSelector(form, 'items_out_of_date_range_alert');

  useFormPreventTransition(form);
  const [showAddContentModal, setShowAddContentModal] = useState<boolean | string>(false);
  const [contentModalSectionContext, setContentModalSectionContext] = useState<any>(null);
  const [contentModalTrackIdsContext, setContentModalTrackIdsContext] = useState([]);

  const showContentModal = (type, options: any = {}) => {
    const { section = null } = options;
    const trackIds = options.trackIds || [];
    setShowAddContentModal(type);
    setContentModalTrackIdsContext(trackIds ? trackIds : []);
    setContentModalSectionContext(section ? { section } : null);
  };

  const [deleteModalContent, setDeleteModalContent] = useState(false);
  const [onDeleteContent, setOnDeleteContent] = useState();

  const showDeleteModal = (modalContent, onDelete?) => {
    setOnDeleteContent(onDelete ? () => onDelete : undefined);
    setDeleteModalContent(modalContent);
  };

  const handleAddTrackItems = useAddTrackItems(trackItems || [], change);

  /**
   * Manage displaying the unordered items alert.
   *
   * The alert will display if the track items are not
   * in chronological order, and the user has not dismissed it.
   */
  useEffect(() => {
    if (isEmpty(trackItems)) return;

    const hasBeenDismissed = get(unorderedItemsAlert, 'dismissed', false);
    const isDisplaying = get(unorderedItemsAlert, 'display', false);

    if (isDisplaying || hasBeenDismissed) return;

    const areAllItemsInOrder =
      scheduledTracksService.checkIfAllItemsAreInChronologicalOrder(trackItems);

    if (!areAllItemsInOrder) {
      change('unordered_items_alert.display', true);
    }
  }, [trackItems, unorderedItemsAlert, change]);

  /**
   * Manage displaying the items out of date range alert.
   */
  useEffect(() => {
    if (isEmpty(trackItems)) return;

    const hasBeenDismissed = get(itemsOutOfDateRangeAlert, 'dismissed', false);
    if (hasBeenDismissed) return;

    const areAllItemsInDateRange = scheduledTracksService.checkIfAllItemsAreInDateRange(
      trackItems,
      startsAt,
      endsAt
    );

    const isDisplaying = get(itemsOutOfDateRangeAlert, 'display', false);

    if (!areAllItemsInDateRange && !isDisplaying) {
      change('items_out_of_date_range_alert.display', true);
    } else if (areAllItemsInDateRange && isDisplaying) {
      change('items_out_of_date_range_alert.display', false);
    }
  }, [trackItems, startsAt, endsAt, itemsOutOfDateRangeAlert, change]);

  const [settingsSectionsList] = useSettingsSectionsList('track/form', form) as Array<
    FormSection[]
  >;

  // TODO: Update with the correct article
  // https://app.asana.com/0/1201424258438511/1203199379418501/f
  const HCArticleURL =
    'https://help.plusplus.app/en/articles/6057221-plusplus-101-offer-sequential-learning-playbooks-with-tracks';
  const infoPanelText = `Scheduled ${labelTrackPlural} are experiences meant for participants to learn
                    together through a mix of live events and self paced content.`;

  const handleAppendToEditor = (url: string) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const handleSwappedEventsMap = ({ prevEvent, newEvent, enrollmentsAction }) => {
    // For the cases where the user swaps the event more than once
    const initialEventId =
      findKey(swappedEventsMap, (item) => item.id === prevEvent.id) || prevEvent.id;

    const updatedSwappedEventsMap = {
      ...swappedEventsMap,
      [initialEventId]: { id: newEvent.id, enrollments_action: enrollmentsAction },
    };

    // For the cases where the user swaps back to the initial event
    const filteredSwappeventsMap = omitBy(
      updatedSwappedEventsMap,
      (value, key) => key === `${value.id}`
    );

    change('swapped_events_map', filteredSwappeventsMap);
  };

  const handleAddSection = useCreateTrackSection(trackSections, trackItems, change);

  const handleRenderAlert = () => {
    return <ScheduledTrackFormAlerts form={form} onChange={change} />;
  };

  const lowerLabelTrack = toLower(labelTrack);
  const lowerLabelTrackPlural = toLower(labelTrackPlural);

  const trackSectionsList: FormSection[] = [
    {
      id: 'track-details',
      label: `Scheduled ${labelTrack} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="track_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${lowerLabelTrack} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowVideoButton
                validate={[validateRequired]}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_tracks']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_tracks']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'facilitators',
      label: 'Facilitators',
      icon: 'persons',
      section: (
        <FacilitatorField
          label="Maintainer(s)"
          name="maintainers_ids"
          infoText={`Responsible for maintaining the content, able to delete the scheduled
          ${lowerLabelTrack} and change access restrictions. Whenever the scheduled
          ${lowerLabelTrack} maintainers are updated, the new ones are automatically added as
          maintainers to the inline items while the removed ones are not removed.`}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'enrollment_capacity',
      label: 'Enrollment Capacity',
      icon: 'ticket',
      section: <EnrollmentCapacitySection />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'location_and_time',
      label: 'Location & Time',
      icon: 'location',
      section: (
        <>
          <FormFieldGroup>
            <Field name="location_id" label="Location" component={LocationSelectField} />
            <InfoText
              top={4}
              bottom={16}
              content="The system will calculate local time at other locations for online events."
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Row>
              <Field
                name="starts_at"
                label="Starts at"
                component={SingleDatePickerField}
                required
                validate={[validateRequired]}
                InputProps={{
                  maxDate: endsAt,
                }}
              />
              <Field
                name="ends_at"
                label="Ends at"
                component={SingleDatePickerField}
                required
                validate={[validateRequired]}
                InputProps={{
                  minDate: startsAt,
                }}
              />
            </Row>
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'resources',
      label: 'Resources',
      icon: 'teach',
      section: (
        <ResourcesSection
          formName={form}
          selectedOfficeHour={selectedOfficeHour}
          showSlackResource
          usePublicId={false}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList: FormSection[] = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={CONTENT_TYPES.track}
          formName={form}
          assignmentsCount={initialValues.assignments_count}
          isEditing={isEdit}
        />
      ),
      sectionProps: {
        defaultOpen:
          !isEmpty(initialValues.survey_relationships) ||
          !isEmpty(initialValues.external_survey_link),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={`Scheduled ${labelTrack}`}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          hideAttendeesInfoText={`Only admins, maintainers, and assignees see who is assigned to
          this scheduled ${lowerLabelTrack}. Remember scheduled ${lowerLabelTrackPlural} don't
          automatically propagate policies to its nested track items.`}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  const trackSectionsAndItemsCountText = getTrackSectionsAndItemsCountText(
    trackSections,
    trackItems
  );
  return (
    <AssessmentQuestionModalsContext.Provider value={{ showContentModal, showDeleteModal }}>
      <NewContentForm
        contentNameSingular={`Scheduled ${labelTrack}`}
        contentInfoPanelText={infoPanelText}
        contentHCArticleURL={HCArticleURL}
        invalid={invalid}
        handleSubmit={handleSubmit}
        error={error}
        change={change}
        submitting={submitting}
        contentTitle={trackName}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        contentSectionsList={trackSectionsList}
        advancedSettingsList={advancedSettingsList}
        isModalForm={isModalForm}
        // Show details tab if there is a hash in the URL
        // Show 'track-items' tab  by default if there's track items or sections
        defaultSelectedTab={
          isEmpty(location?.hash) && (!isEmpty(trackItems) || !isEmpty(trackSections))
            ? 'track-items'
            : undefined
        }
        renderAlert={handleRenderAlert}
        tabsConfig={[
          {
            id: 'track-items',
            label: `Scheduled ${capitalize(labelTrack)} Items`,
            renderSidebar: ({ closeSidebar }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '12px',
                  justifyContent: 'center',
                  p: '16px',
                }}
              >
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="8px">
                  <IconButton size="small" onClick={closeSidebar}>
                    <MenuOpenOutlinedIcon />
                  </IconButton>
                  <Text size="h6" color={colors.neutral500}>
                    {trackSectionsAndItemsCountText}
                  </Text>
                </Box>

                {isEmpty(trackItems) && isEmpty(trackSections) && (
                  <Button
                    startIcon={<SplitscreenIcon />}
                    sx={ACTION_BUTTON_SX}
                    onClick={handleAddSection}
                  >
                    Add section
                  </Button>
                )}

                <Fields
                  names={['sections', 'track_items']}
                  component={SectionTrackItemFields}
                  isEditing={isEdit}
                  trackType={CONTENT_TYPES.scheduled_track}
                  handleSwappedEventsMap={handleSwappedEventsMap}
                  trackStartsAt={startsAtFormValue}
                  trackEndsAt={endsAtFormValue}
                />
              </Box>
            ),
            renderContent: () => (
              <TrackItemsForm
                sections={trackSections || []}
                items={trackItems || []}
                trackType={CONTENT_TYPES.scheduled_track}
                renderAlert={handleRenderAlert}
                handleSwappedEventsMap={handleSwappedEventsMap}
                trackStartsAt={startsAtFormValue}
                trackEndsAt={endsAtFormValue}
                {...{ error, invalid, submitting, change, isEdit }}
              />
            ),
          },
        ]}
      />
      {showAddContentModal === 'trackItem' && (
        <TrackItemAddContentModal
          handleClose={() => setShowAddContentModal(false)}
          callback={({ selectedItems: newItems }) => {
            handleAddTrackItems(contentModalSectionContext?.section ?? {}, newItems);
            setShowAddContentModal(false);
          }}
          trackType={CONTENT_TYPES.scheduled_track}
          trackItemsIds={contentModalTrackIdsContext}
          trackFormName={form}
          trackId={get(initialValues, 'id')}
        />
      )}
      {deleteModalContent && (
        <DeleteStandAloneModal
          content={deleteModalContent}
          handleClose={() => showDeleteModal(false)}
          onDelete={onDeleteContent}
        />
      )}
    </AssessmentQuestionModalsContext.Provider>
  );
};

const ConnectedScheduledTrackForm = reduxForm({
  // Not passing 'form' here, pass it as a prop to the component
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { formName, currentUser, initialValues, isEdit }) => {
    const actionName = actions.scheduledTrack[isEdit ? 'update' : 'create'].toString();
    return onSubmitActions(actionName, () => {
      const payload: ScheduledTrackPayload = pick(values, [
        'name',
        'original_track_id',
        'content_body',
        'topics',
        'cover',
        'track_items',
        'maintainers_ids',
        'capacity',
        'is_hidden',
        'hide_attendees',
        'groups_ids',
        'external_survey_link',
        'survey_relationships',
        'due_date_period_value',
        'due_date_period_option',
        'office_hour_id',
        'resources',
        'starts_at',
        'ends_at',
        'location_id',
        'is_online',
        'is_local',
        'sections',
      ]);

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_tracks'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const topics = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

      payload.tags = concat(topics, customTopicsList);

      payload.sections = map(get(payload, 'sections', []), (section) => omit(section, ['items']));
      payload.track_items = map(get(payload, 'track_items', []), (item) => ({
        content_item_id: get(item, 'content_item.id'),
        section: get(item, 'section'),
        assignment_due_date: get(item, 'assignment_due_date') || payload.ends_at,
        is_required: get(item, 'is_required', true),
        content_item_is_inline: get(item, 'content_item.is_inline'),
      }));

      payload.facilitators = map(get(payload, 'maintainers_ids', []), (maintainerId) => ({
        user_id: maintainerId,
        role: FACILITATOR_ROLES.author,
      }));

      // Surveys
      let { survey_relationships: surveyRelationships } = payload;
      const { external_survey_link: surveyLink } = payload;

      surveyRelationships = map(surveyRelationships, (sr) => {
        const surveyRelationship: SurveyRelationship = pick(sr, [
          'survey_id',
          'survey_cutoff_period_value',
          'survey_cutoff_period_option',
          'survey_schedule_period_value',
          'survey_schedule_period_option',
          'survey_schedule_kind',
        ]);

        if (isEdit) surveyRelationship.id = sr.id;

        return surveyRelationship;
      });

      payload.external_survey_link = surveyLink;
      payload.survey_relationships = surveyRelationships;

      // Resources Links
      payload.resources = parseResources(payload.resources, isEdit);
      payload.slack_channel = values.slack_channel ? { name: values.slack_channel } : null;

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      payload.swapped_events_map = isEdit ? get(values, 'swapped_events_map') : undefined;

      return {
        ...(initialValues.id && { id: initialValues.id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result: any, _dispatch: any, { onSubmitSuccessHandler }) =>
    onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    toast.error('Submission failed, fix errors and try again.');
    window.scrollTo(0, 0);
  },
})(TrackForm);

const mapStateToProps = (state: any, { form, ...props }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);
  return {
    selectedOfficeHour,
    currentUser: get(state, 'user.currentUser'),
    ...props,
  };
};

export default connect(mapStateToProps)(ConnectedScheduledTrackForm);
