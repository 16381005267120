import React from 'react';

import { EventsMissingFeedback } from 'feedbacks';
import PageTitle from 'shared/components/PageTitle';

function Container() {
  const pageTitle = 'Event feedback';
  return (
    <React.Fragment>
      <PageTitle title={pageTitle} />
      <EventsMissingFeedback />
    </React.Fragment>
  );
}

export default Container;
