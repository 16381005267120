import { filter, findIndex, noop, size } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';

import { useQueryParams, useRouterQueryUtils } from 'app/shared/hooks';

import { isItemTrackSection } from '../utils/helpers';

import { useComposableTrackCtx } from './ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from './TrackContentConsumptionCtx';

type NavigationState = {
  page: number;
  totalPages: number;
};

export type TrackContentNavigationCtxType = {
  current: NavigationState;
  global: NavigationState;
  onAdvance: () => void;
  onGoBack: () => void;
  onGoToPageIndex: (targetPageIndex: number) => void;
  onExitCurrentTrack: () => void;
};

const defaultTrackContentNavigationCtx: TrackContentNavigationCtxType = {
  current: {
    page: 1,
    totalPages: 1,
  },
  global: {
    page: 1,
    totalPages: 1,
  },
  onAdvance: noop,
  onGoBack: noop,
  onGoToPageIndex: noop,
  onExitCurrentTrack: noop,
};

const TrackContentNavigationCtx = React.createContext<TrackContentNavigationCtxType>(
  defaultTrackContentNavigationCtx
);

type TrackContentNavigationProviderProps = {
  children: React.ReactNode;
};

export function TrackContentNavigationProvider(props: TrackContentNavigationProviderProps) {
  const { children } = props;

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { totalCount, nonEmptySectionsAndItemsOrderedList, getSectionOrItemIndex } =
    useComposableTrackCtx();
  const { currentTrackNode, isInNestedTrack, currentTrackItem, currentSection } =
    useTrackContentConsumptionCtx();

  const { addToQueryString, getUrlWithoutQueryString } = useRouterQueryUtils();
  const history = useHistory();

  /*
    Compute the current navigation state taking into account if the current item
    is in a nested track or not.
  */

  const trackItemsAndSections = React.useMemo(() => {
    const allItemsAndSections = filter(nonEmptySectionsAndItemsOrderedList, (item) => {
      return item.track_id === currentTrackNode.track.public_id;
    });

    return allItemsAndSections;
  }, [currentTrackNode, nonEmptySectionsAndItemsOrderedList]);

  const currentNavigation: NavigationState = React.useMemo(() => {
    const currentIdx = findIndex(trackItemsAndSections, (item) => {
      if (currentTrackItem != null) {
        return (
          !isItemTrackSection(item) &&
          item.content_item.public_id === currentTrackItem.content_item.public_id
        );
      }

      if (currentSection != null) {
        return isItemTrackSection(item) && item.id === currentSection.id;
      }

      return false;
    });

    const trackPage = currentIdx + 1;
    const trackTotalCount = size(trackItemsAndSections);

    return {
      page: trackPage,
      totalPages: trackTotalCount,
    };
  }, [trackItemsAndSections, currentTrackItem, currentSection]);

  const goToPage = React.useCallback(
    (targetPage) => {
      addToQueryString({ page: targetPage });
    },
    [addToQueryString]
  );

  const handleGoToPageIndex = React.useCallback(
    (targetPageIndex: number) => {
      goToPage(targetPageIndex + 1);
    },
    [goToPage]
  );

  /*
    When navigating to the next or previous page, we need to be sure that the next or previous
    item is an actual item or section in the current track. This is to avoid navigating into a
    nested track or to a question inside an assessment
  */

  const handleGoToNextPage = () => {
    const nextItem = trackItemsAndSections[currentNavigation.page];

    const nextItemId = isItemTrackSection(nextItem) ? nextItem.id : nextItem.content_item.public_id;
    const nextItemIndex = getSectionOrItemIndex(currentTrackNode.track.public_id, nextItemId);

    handleGoToPageIndex(nextItemIndex);
  };

  const handleGoToPreviousPage = () => {
    const previousItem = trackItemsAndSections[currentNavigation.page - 2];

    const previousItemId = isItemTrackSection(previousItem)
      ? previousItem.id
      : previousItem.content_item.public_id;
    const previousItemIndex = getSectionOrItemIndex(
      currentTrackNode.track.public_id,
      previousItemId
    );

    handleGoToPageIndex(previousItemIndex);
  };

  const handleExitCurrentTrack = React.useCallback(() => {
    if (isInNestedTrack) {
      handleGoToPageIndex(currentTrackNode.index);
    } else {
      history.push(getUrlWithoutQueryString(['page']));
    }
  }, [isInNestedTrack, history, currentTrackNode, handleGoToPageIndex, getUrlWithoutQueryString]);

  const ctxValues: TrackContentNavigationCtxType = {
    current: currentNavigation,
    global: {
      page,
      totalPages: totalCount,
    },
    onAdvance: handleGoToNextPage,
    onGoBack: handleGoToPreviousPage,
    onGoToPageIndex: handleGoToPageIndex,
    onExitCurrentTrack: handleExitCurrentTrack,
  };

  return (
    <TrackContentNavigationCtx.Provider value={ctxValues}>
      {children}
    </TrackContentNavigationCtx.Provider>
  );
}

export const useTrackContentNavigationCtx = () => {
  return React.useContext(TrackContentNavigationCtx);
};
