import PropTypes from 'prop-types';
import React from 'react';

import ButtonLink from 'shared/components/ButtonLink';
import SplitButton from 'shared/components/SplitButton';

import FormTopBar from './FormTopBar';

const EditFormTopBar = ({
  action,
  title,
  backRoute,
  loading,
  disableSave,
  onSaveAndDuplicateHandler,
  onSaveAndBulkDuplicateHandler,
  onSaveAndApplyHandler,
}) => (
  <FormTopBar action={action} title={title} loading={loading}>
    {backRoute && (
      <ButtonLink variant="error" size="medium" route={backRoute}>
        Cancel
      </ButtonLink>
    )}
    <SplitButton
      label="Save"
      type="submit"
      disabled={disableSave}
      secondaryActionOptionList={[
        {
          label: 'Save & Duplicate',
          onClick: onSaveAndDuplicateHandler,
          hidden: !onSaveAndDuplicateHandler,
        },
        {
          label: 'Save & Bulk Duplicate',
          onClick: onSaveAndBulkDuplicateHandler,
          hidden: !onSaveAndBulkDuplicateHandler,
        },
        {
          label: 'Save & Apply',
          onClick: onSaveAndApplyHandler,
          hidden: !onSaveAndApplyHandler,
        },
      ]}
    />
  </FormTopBar>
);

EditFormTopBar.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string,
  backRoute: PropTypes.string,
  loading: PropTypes.bool,
  disableSave: PropTypes.bool,
  onSaveAndDuplicateHandler: PropTypes.func,
  onSaveAndBulkDuplicateHandler: PropTypes.func,
  onSaveAndApplyHandler: PropTypes.func,
};

export default EditFormTopBar;
