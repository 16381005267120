import PropTypes from 'prop-types';
import React from 'react';

import colors from 'services/colors';
import DocsModal from 'shared-modals/components/DocsModal';
import Text from 'shared/components/Text';

const ExternalRole = () => (
  <Text size="h5" color={colors.neutral600}>
    A placeholder so that a profile can be built for individuals. In some cases, users are
    configured to receive information from the system but not participate directly.
  </Text>
);

const RegularRole = () => (
  <Text size="h5" color={colors.neutral600}>
    A regular user is your typical employee looking to discover and sign up for events. Such person
    may be able to create an event, if enabled in the system settings.
  </Text>
);

const OrganizerRole = () => (
  <Text size="h5" color={colors.neutral600}>
    An Organizer user can schedule and edit various events.
  </Text>
);

const AdminRole = () => (
  <Text size="h5" color={colors.neutral600}>
    An Admin user can do everything an organizer can plus update people, locations, and system
    settings. Admin can also assign roles to other people.
  </Text>
);

const userRolesFAQItems = [
  {
    index: '01',
    title: 'External',
    content: <ExternalRole />,
  },
  {
    index: '02',
    title: 'Regular',
    content: <RegularRole />,
  },
  {
    index: '03',
    title: 'Organizer',
    content: <OrganizerRole />,
  },
  {
    index: '04',
    title: 'Admin',
    content: <AdminRole />,
  },
];

const AccountRoleDocsModal = ({ handleClose }) => (
  <DocsModal
    title="How account role works"
    FAQItems={userRolesFAQItems}
    handleClose={handleClose}
  />
);

AccountRoleDocsModal.propTypes = {
  handleClose: PropTypes.func,
};

export default AccountRoleDocsModal;
