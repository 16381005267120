export const EDIT_EVENT_PERMISSION = 'edit_event';
export const EDIT_EVENT_TYPE_PERMISSION = 'edit_event_type';
export const EDIT_ENROLLMENT_PERMISSION = 'edit_enrollment';
export const MANAGE_EVENT_ATTENDANCE_PERMISSION = 'manage_event_attendance';
export const EDIT_USER_PROFILE_PERMISSION = 'edit_user_profile';

export const IS_ORGANIZER_PERMISSION = 'is_organizer';
export const IS_ADMIN_PERMISSION = 'is_admin';
export const IS_SUPERUSER_PERMISSION = 'is_superuser';

export const CREATE_EVENT_PERMISSION = 'create_event';
export const CREATE_EVENT_TYPE_PERMISSION = 'create_event_type';
export const SEE_EVENT_FEEDBACKS_PERMISSION = 'see_event_feedbacks';
export const GROUP_ACCESS_PERMISSION = 'group_access';

export const CREATE_ARTICLE_PERMISSION = 'create_article';
export const EDIT_ARTICLE_PERMISSION = 'edit_article';
export const MANAGE_ARTICLE_ASSIGNMENT_PERMISSION = 'manage_article_assignment';
export const SEE_ARTICLE_FEEDBACKS = 'see_article_feedbacks';

export const CREATE_TASK_PERMISSION = 'create_task';
export const EDIT_TASK_PERMISSION = 'edit_task';
export const MANAGE_TASK_ASSIGNMENT_PERMISSION = 'manage_task_assignment';
export const SEE_TASK_FEEDBACKS = 'see_task_feedbacks';

export const CREATE_VIDEO_PERMISSION = 'create_video';
export const EDIT_VIDEO_PERMISSION = 'edit_video';
export const MANAGE_VIDEO_ASSIGNMENT_PERMISSION = 'manage_video_assignment';
export const SEE_VIDEO_FEEDBACKS = 'see_video_feedbacks';

export const CREATE_TRACK_PERMISSION = 'create_track';
export const EDIT_TRACK_PERMISSION = 'edit_track';
export const MANAGE_TRACK_ASSIGNMENT_PERMISSION = 'manage_track_assignment';
export const SEE_TRACK_FEEDBACKS = 'see_track_feedbacks';

export const CREATE_ASSESSMENT_PERMISSION = 'create_assessment';
export const EDIT_ASSESSMENT_PERMISSION = 'edit_assessment';
export const MANAGE_ASSESSMENT_ASSIGNMENT_PERMISSION = 'manage_assessment_assignment';
export const SEE_ASSESSMENT_FEEDBACKS = 'see_assessment_feedbacks';

export const CREATE_LINKEDCONTENT_PERMISSION = 'create_linkedcontent';
export const EDIT_LINKEDCONTENT_PERMISSION = 'edit_linkedcontent';
export const MANAGE_LINKEDCONTENT_ASSIGNMENT_PERMISSION = 'manage_linkedcontent_assignment';
export const SEE_LINKEDCONTENT_FEEDBACKS = 'see_linkedcontent_feedbacks';

export const CREATE_CODELAB_PERMISSION = 'create_codelab';
export const EDIT_CODELAB_PERMISSION = 'edit_codelab';
export const MANAGE_CODELAB_ASSIGNMENTS_PERMISSION = 'manage_codelab_assignments';
export const SEE_CODELAB_FEEDBACK = 'see_codelab_feedback';

export const CREATE_COURSE_PERMISSION = 'create_course';
export const EDIT_COURSE_PERMISSION = 'edit_course';
export const MANAGE_COURSE_REGISTRATION_PERMISSION = 'manage_course_registration';
export const SEE_COURSE_FEEDBACKS = 'see_course_feedbacks';
export const PREVIEW_COURSE_PERMISSION = 'preview_course';

export const CREATE_PROGRAM_PERMISSION = 'create_program';
export const EDIT_PROGRAM_PERMISSION = 'edit_program';
export const CREATE_SESSION_PERMISSION = 'create_session';
export const CREATE_MENTORSHIP_SESSION_PERMISSION = 'create_mentorship_session';
export const CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION =
  'create_mentorship_session_for_others';
export const CREATE_PROGRAM_SESSION_PERMISSION = 'create_program_session';
export const CAN_SEE_SESSIONS_ENROLLMENTS = 'can_see_sessions_enrollments';
export const CREATE_SESSION_FOR_OTHERS_PERMISSION = 'create_session_for_others';
export const EDIT_SESSION_PERMISSION = 'edit_session';
export const EDIT_SESSION_ENROLLMENT_PERMISSION = 'edit_session_enrollment';
export const EDIT_SESSION_FEEDBACK_PERMISSION = 'edit_session_feedback';
export const SEE_MENTORSHIP_PROGRAM_FEEDBACK = 'see_mentorship_program_feedback';
export const CAN_JOIN_AS_MENTEE = 'join_as_mentee';
export const CAN_JOIN_AS_MENTOR = 'join_as_mentor';

export const EDIT_TESTIMONIAL_PERMISSION = 'edit_testimonial';
export const UPDATE_TESTIMONIAL_HIDDEN_STATUS_PERMISSION = 'update_testimonial_hidden_status';

export const CREATE_GROUP_PERMISSION = 'create_group';
export const CREATE_PUBLIC_GROUP_PERMISSION = 'create_public_group';
export const LIST_GROUPS_PERMISSION = 'list_groups';
export const EDIT_GROUP_PERMISSION = 'edit_group';
export const READ_GROUP_PERMISSION = 'read_group';
export const VIEW_GROUP_MEMBERS_PERMISSION = 'view_group_members';
export const CHANGE_GROUP_PRIVACY_PERMISSION = 'change_group_privacy';

// TODO: Auto generate strings from backend
export const CREATE_CHANNEL_PERMISSION = 'create_channel';
export const MANAGE_CHANNEL_PERMISSION = 'manage';
export const ADD_CHANNEL_CONTENT_PERMISSION = 'add_content';

// Content Items
export const CONTENT_ITEM_PERMISSIONS = {
  access: 'access',
  assign: 'assign',
  manage: 'manage',
  see_assignees: 'see_assignees',
  see_feedback: 'see_feedback',
};

export const COURSE_PERMISSIONS = {
  ...CONTENT_ITEM_PERMISSIONS,
  preview: 'preview',
};

// Calendar
export const ALLOW_SWITCHING_CALENDAR_OWNERSHIP_IN_EVENTS =
  'allow_switching_calendar_ownership_in_events';
