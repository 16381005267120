import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from 'entities/actions';
import { locationSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import { map, includes } from 'vendor/lodash';

import AutocompleteField from '../AutocompleteField';

const LocationSelectField = (props) => {
  const [options, setOptions] = useState([]);

  const [fetch, { status }] = useEntities(
    actions.location.retrieveList,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setOptions(
          map(data, ({ id, name, deleted_at }) => ({
            value: id,
            label: deleted_at ? `${name} (Deleted)` : name,
          }))
        );
      }
    },
    {
      schema: [locationSchema],
    }
  );

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch({
      page_size: 50,
      include_ids: includeValues || undefined,
      q: q || undefined,
    });
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

LocationSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default LocationSelectField;
