import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
} from 'app/event-shared/constants';
import colors from 'services/colors';

export const ENROLL_USER_ON_NEXT_EVENT_OF_EVENT_TYPE = 'enroll_user_on_next_event_of_event_type';
export const ENROLL_USER_ON_NEXT_EVENT_OF_RECURRING_EVENT_TYPE =
  'enroll_user_on_next_event_of_recurring_event_type';
export const ADD_REMOVE_BADGE = 'add_remove_badge';
export const ENROLL_USER_ON_NEXT_EVENTS_OF_ALL_EVENT_TYPES_IN_TRACK =
  'enroll_user_on_next_events_of_all_event_types_in_track';
export const ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK =
  'enroll_user_on_next_scheduled_offering_of_track';
export const ASSIGN_CONTENT_ITEM_TO_USER = 'assign_content_item_to_user';
export const SYNC_GROUP_FROM_SEGMENT = 'sync_group_from_segment';
export const SYNC_GROUP_TEMPLATE_FROM_SEGMENT = 'sync_group_template_from_segment';

export const OPERATION_PARAMETERS_MAP = {
  [ENROLL_USER_ON_NEXT_EVENT_OF_EVENT_TYPE]: [
    'event_type_public_id',
    'location_ids',
    'days_offset',
    'attendance_type',
    'overcapacity_policy',
  ],
  [ENROLL_USER_ON_NEXT_EVENT_OF_RECURRING_EVENT_TYPE]: [
    'event_type_public_id',
    'location_ids',
    'days_offset',
    'attendance_type',
    'overcapacity_policy',
  ],
  [ENROLL_USER_ON_NEXT_EVENTS_OF_ALL_EVENT_TYPES_IN_TRACK]: [
    'track_public_id',
    'location_ids',
    'days_offset',
    'attendance_type',
    'overcapacity_policy',
  ],
  [ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK]: [
    'track_public_id',
    'location_ids',
    'days_offset',
    'preferred_attendance_type',
    'scheduled_track_overcapacity_policy',
    'enrolled_by_id',
  ],
  [ASSIGN_CONTENT_ITEM_TO_USER]: [
    'content_item_public_id',
    'assigner_id',
    'time_to_complete',
    'time_to_complete_days_offset',
  ],
  [SYNC_GROUP_FROM_SEGMENT]: ['group_id'],
  [SYNC_GROUP_TEMPLATE_FROM_SEGMENT]: ['group_name_template', 'group_name_delimiter'],
  [ADD_REMOVE_BADGE]: ['add_or_remove', 'badge_id'],
};

export const OPERATION_OPTIONS = [
  {
    value: ADD_REMOVE_BADGE,
    label: 'Add/remove badges from users',
  },
  {
    value: ENROLL_USER_ON_NEXT_EVENT_OF_EVENT_TYPE,
    label: 'Enroll to an upcoming event of an event type',
  },
  {
    value: ENROLL_USER_ON_NEXT_EVENT_OF_RECURRING_EVENT_TYPE,
    label: 'Enroll to an upcoming event of a recurring event type',
  },
  {
    value: ENROLL_USER_ON_NEXT_EVENTS_OF_ALL_EVENT_TYPES_IN_TRACK,
    label: 'Enroll into all upcoming events of a track',
  },
  {
    value: ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK,
    label: 'Enroll into an upcoming scheduled offering of a track',
  },
  { value: ASSIGN_CONTENT_ITEM_TO_USER, label: 'Assign a content item' },
  { value: SYNC_GROUP_FROM_SEGMENT, label: 'Assign to a group' },
  { value: SYNC_GROUP_TEMPLATE_FROM_SEGMENT, label: 'Assign to group(s) by template' },
];

export const OVERCAPACITY_POLICY_FORCE_ENROLL = 'force_enroll';
export const OVERCAPACITY_POLICY_ENTER_WAITLIST = 'enter_waitlist';
export const OVERCAPACITY_POLICY_ENROLL_IN_NEXT_EVENT = 'enroll_in_next_event';
export const OVERCAPACITY_POLICY_ENROLL_IN_NEXT_SCHEDULED_TRACK = 'enroll_in_next_scheduled_track';
export const OVERCAPACITY_POLICY_ENTER_WAITLIST_OR_ENROLL_IN_NEXT_EVENT =
  'enter_waitlist_or_enroll_in_next_event';
export const OVERCAPACITY_POLICY_ENTER_SKIP = 'skip';

export const ADD_REMOVE_OPTIONS = [
  { value: 'add', label: 'Add' },
  { value: 'remove', label: 'Remove' },
];

export const OVERCAPACITY_POLICY_OPTIONS = [
  { value: OVERCAPACITY_POLICY_FORCE_ENROLL, label: 'Automatically increase event capacity' },
  { value: OVERCAPACITY_POLICY_ENTER_WAITLIST, label: 'Attempt to waitlist' },
  {
    value: OVERCAPACITY_POLICY_ENROLL_IN_NEXT_EVENT,
    label: 'Look for the next event with sufficient capacity',
  },
  {
    value: OVERCAPACITY_POLICY_ENTER_WAITLIST_OR_ENROLL_IN_NEXT_EVENT,
    label: 'Attempt to waitlist or look for the next event with sufficient capacity',
  },
  { value: OVERCAPACITY_POLICY_ENTER_SKIP, label: 'Skip enrollment when full' },
];

export const SCHEDULED_TRACK_OVERCAPACITY_POLICY_OPTIONS = [
  { value: OVERCAPACITY_POLICY_FORCE_ENROLL, label: 'Automatically increase capacity' },
  {
    value: OVERCAPACITY_POLICY_ENROLL_IN_NEXT_SCHEDULED_TRACK,
    label: 'Look for the next scheduled track with sufficient capacity',
  },
  { value: OVERCAPACITY_POLICY_ENTER_SKIP, label: 'Skip enrollment when full' },
];

export const TIME_TO_COMPLETE_INHERIT = 'inherit';
export const TIME_TO_COMPLETE_CUSTOM = 'custom';

export const TIME_TO_COMPLETE_OPTIONS = [
  { value: TIME_TO_COMPLETE_INHERIT, label: 'Inherit from Content Item Settings' },
  { value: TIME_TO_COMPLETE_CUSTOM, label: 'Custom Duration' },
];

export const ATTENDANCE_METHOD_OPTIONS = [
  { label: 'Online', value: ENROLLMENT_STATUS_GOING_ONLINE },
  { label: 'In-Person', value: ENROLLMENT_STATUS_GOING },
];

export const PREFERRED_ATTENDANCE_METHOD_OPTIONS = ATTENDANCE_METHOD_OPTIONS;

export const OPERATION_ON_USER_APPLIED = 'applied';
export const OPERATION_ON_USER_SKIPPED = 'skipped';
export const OPERATION_ON_USER_NOT_APPLIED = 'not_applied';
export const OPERATION_ON_USER_FAILED = 'failed';

export const OPERATION_ON_USER_STATUS_DISPLAY_MAP = {
  [OPERATION_ON_USER_APPLIED]: 'Applied',
  [OPERATION_ON_USER_SKIPPED]: 'Skipped',
  [OPERATION_ON_USER_NOT_APPLIED]: 'Not applied',
  [OPERATION_ON_USER_FAILED]: 'Failed',
  'N/A': 'N/A',
};

export const OPERATION_ON_USER_STATUS_COLOR_MAP = {
  [OPERATION_ON_USER_APPLIED]: colors.success600,
  [OPERATION_ON_USER_SKIPPED]: colors.alert400,
  [OPERATION_ON_USER_NOT_APPLIED]: colors.error400,
  [OPERATION_ON_USER_FAILED]: colors.error600,
  'N/A': colors.neutral700,
};
