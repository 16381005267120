import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { toast } from 'notifications/components/NotificationCenter';
import { getErrorMessageFromResponse } from 'services/requests';
import { ApiURLs, fetchURL } from 'services/requests-base';
import { noop } from 'vendor/lodash';

import { CODELAB_VERSION_STATUS_PUBLISHED } from './constants';

class NoLatestVersionError extends Error { }

async function getLatestVersion(codelabId) {
  const response = await fetchURL(ApiURLs['codelabs_api:version_list']({ codelab_id: codelabId }));
  const versions = response.data;
  if (versions.length > 0) {
    const version = versions[0];
    if (version.status === CODELAB_VERSION_STATUS_PUBLISHED) {
      return version;
    }
  }
  throw new NoLatestVersionError();
}

async function fetchAssignmentData(assignment_id, options = {}) {
  const qs = options.method === 'PATCH' ? "?simple_update=true" : ""
  const response = await fetchURL(
    ApiURLs['assignments_api:retrieve_update_destroy']({ pk: assignment_id }) + qs,
    options
  );
  return response.data;
}

async function getAssignment(codelab) {
  const isValidAssignment = (assignment) => (
    assignment && assignment.state !== ASSIGNMENT_STATES.dropped
  );
  if (!codelab) {
    throw new Error("No codelab to get assignment from");
  }
  if (isValidAssignment(codelab.user_assignment)) { // assignment 2.0
    return Promise.resolve(codelab.user_assignment);
  }
  if (isValidAssignment(codelab.assignment)) { // assignment 1.0
    return await fetchAssignmentData(codelab.assignment.id); // promote to assignment 2.0
  }
  // create a new assignment (this call is atomic, and will fail with HTTP 409 on conflict)
  const response = await fetchURL(ApiURLs['assignments_api:list_create'](), {
    method: 'POST', body: JSON.stringify({ content_item_id: codelab.id })
  });
  return response.data;
}

async function getLaunchUrl(assignment) {
  if (assignment.launch_url) {
    return Promise.resolve(assignment.launch_url);
  }
  const version = await getLatestVersion(assignment.content_item_id); // fails if none available
  assignment = await fetchAssignmentData(assignment.id, { // assign this version to the assignment
    method: 'PATCH', body: JSON.stringify({ version_id: version.id })
  });
  assignment = await fetchAssignmentData(assignment.id); // refetch to get the launch_url
  return assignment.launch_url;
}

export const launchCodelabRequest = (codelab, onRequestEnd = noop) => {
  getAssignment(codelab).then(getLaunchUrl)
    .then(launchUrl => {
      if (onRequestEnd) {
        onRequestEnd();
      }
      //  eslint-disable-next-line lodash/prefer-lodash-method
      window.location.replace(launchUrl);
      return launchUrl; // useless return
    }).catch((error) => {
      if (error instanceof NoLatestVersionError) {
        toast.warning('Oops', 'There are no published versions to launch.');
      } else {
        const errorMessage = getErrorMessageFromResponse(error);
        toast.error('Error', errorMessage);
      }
    });
};

export const previewCodelabRequest = (codelabId) => {
  getLatestVersion(codelabId)
    //  eslint-disable-next-line lodash/prefer-lodash-method
    .then(version => window.location.replace(version.launch_url))
    .catch((error) => {
      if (error instanceof NoLatestVersionError) {
        toast.warning('Oops', 'There are no published versions to preview.');
      } else {
        const errorMessage = getErrorMessageFromResponse(error);
        toast.error('Error', errorMessage);
      }
    });
};
