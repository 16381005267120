import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const WorldIcon = ({ width, height, fill }) => {
  const d =
    'M10 2c-.645 0-1.265.077-1.858.22a7.974 7.974 0 0 0-4.723 3.238A7.968 7.968 0 0 0 2 10c0 4.413 3.587 8 8 8s8-3.587 8-8-3.587-8-8-8zm3.303 13.355c-.064.516-.8.774-.955.22-.27-1.02-.993-.723-.967-1.678.025-.723-1.458-.155-1.846-.736-.387-.567.684-1.122.104-1.832-.58-.71-1.742.271-3.136-.206-.62-.22-1.213-.723-1.626-1.226-.554-.671-.748-1.471-.98-2.297-.026-.077-.039-.168-.065-.245a6.753 6.753 0 0 1 3.703-3.6c.31.426 1.304.22 1.936.284.323.026.555.129.568.413a.71.71 0 0 1-.039.206c-.194.53-.916 1.458-.465 1.807.336.258.568-.233.762.503.09.335.129 1.032-.245 1.277-.362.232-1.265-.697-2.375-.18-.606.296-.696 1.69.142 1.767.49.039.878-.929 1.342-.464.374.374.697 1.393 1.574 1.432.775.039 2.259-.439 3.24.916.58.826-.491 1.484-.685 2.271-.167.645.065.98.013 1.368z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

WorldIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default WorldIcon;
