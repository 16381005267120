import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import TopBar from 'shared/components/TopBar';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: calc(95%);
  overflow: hidden;

  > * + * {
    margin-top: 4px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 20px;
  }
`;

const FormTopBar = ({ action, title, loading, children }) => (
  <TopBar>
    <Content>
      <TextContainer>
        <Text size="h5" lineHeight={20} color={colors.neutral500}>
          {action}
        </Text>
        <Text ellipsisOnOverflow size="h4" lineHeight={20} medium>
          {title}
        </Text>
      </TextContainer>
      <ButtonContainer>
        {loading && <Loading hasMargin={false} />}
        {!loading && children}
      </ButtonContainer>
    </Content>
  </TopBar>
);

FormTopBar.propTypes = {
  action: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default FormTopBar;
