import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const ExclamationIcon = ({ width, height, fill }) => {
  const d =
    'm 17.296312,2.1496814 c -0.118256,0.023581 -0.197028,0.055873 -0.197028,0.055873 C 10.326511,6.8969141 7.555387,6.3034893 5.7452471,6.3372363 H 5.3717784 L 5.2394469,6.3489991 C 4.8927644,6.4119426 4.6277816,6.7164187 4.6277816,7.081233 v 5.425589 l 0.011763,0.132332 c 0.062937,0.346773 0.3674197,0.608724 0.732234,0.608724 h 0.3734687 l 0.1499756,0.0059 c 0.6193956,0.0088 1.4511986,-0.06321 2.8760032,0.197027 v 5.30502 l 0.00882,0.09998 c 0.045046,0.220391 0.2394944,0.385231 0.4734526,0.385233 h 2.2672783 l 0.09998,-0.0088 c 0.220267,-0.0451 0.385232,-0.242661 0.385232,-0.476393 V 14.45359 l 0.367586,0.161739 c 1.363842,0.610275 2.885321,1.49197 4.725703,2.767196 0,0 1.358603,0.570607 1.358603,-1.361542 V 3.5641575 l -0.0059,-0.2381966 C 18.370917,2.0915484 17.651077,2.0789375 17.296312,2.1496814 Z m -14.2300414,4.625719 -0.185264,0.00588 C 1.3002139,6.8904357 0.00500324,8.1280446 0.00500324,9.7337427 L 0.01382535,9.9601771 C 0.12912711,11.461752 1.3754897,12.660769 2.8810066,12.771485 l 0.1793826,0.0059 0.1058651,-0.0088 C 3.4359802,12.719596 3.6466492,12.50623 3.6955801,12.236306 L 3.7073429,12.11865 V 7.4341169 L 3.6955801,7.316489 C 3.6466495,7.0465644 3.4359802,6.8331958 3.1662543,6.7842225 Z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

ExclamationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default ExclamationIcon;
