import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
  LEARNING_TYPE_ICONS_MAPPING,
} from 'catalog/constants';
import ContentTypePill from 'content-items/components/ContentTypePill';
import colors from 'services/colors';
import { formatDuration } from 'services/datetime';
import BaseContentSelect from 'shared-modals/components/BaseContentSelect';
import CoverImage from 'shared/components/CoverImage';
import Icon from 'shared/components/Icon';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { ACCESS_LEVEL_MAPPING } from 'shared/constants';
import { useEllipsisCheck, useTooltipUID } from 'shared/hooks';
import { getColorByType } from 'shared/services';
import { isNil, get, map, round } from 'vendor/lodash';

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > * + * {
    margin-top: 8px;
  }
  width: 100%;
`;

const PillContainer = styled.div`
  margin-right: 4px;
  display: inline-block;
`;

const Title = styled.div`
  display: flex;
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 4px;
  }
`;

const ContentSelect = ({
  content,
  alreadySelected,
  newlySelected,
  handleCheckboxChange,
  isExistingContentModal,
}) => {
  const {
    name,
    cover,
    default_cover,
    duration,
    content_type: contentType,
    access_level: accessLevel,
    tags,
    id,
  } = content;
  const rating =
    content.avg_feedback_rating || content.average_feedback_rating || content.feedback_rating || 0;
  const upcomingEventsCount = get(content, 'upcoming_events_count', 0);
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType];
  const hasDuration = !isNil(duration) && duration !== '00:00:00';

  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const tooltip = useTooltipUID();

  return (
    <BaseContentSelect
      content={content}
      alreadySelected={alreadySelected}
      newlySelected={newlySelected}
      handleCheckboxChange={handleCheckboxChange}
      minHeight={80}
      hasEllipsis={hasEllipsis}
      borderless
    >
      <CoverImage
        hasBorder
        imageUrl={cover || default_cover}
        width="100px"
        height="56px"
        defaultBgColor={getColorByType(learningType, 600)}
        renderDefaultIcon={() => (
          <Icon
            name={LEARNING_TYPE_ICONS_MAPPING[learningType]}
            width={20}
            height={20}
            color={colors.neutral900}
          />
        )}
      />
      <ItemInfo ref={nodeRef}>
        <Title>
          <PillContainer>
            <ContentTypePill item={content} size="medium" />
          </PillContainer>
          <Text medium size="h4" color={colors.neutral900}>
            {name}
          </Text>
        </Title>
        {alreadySelected ? (
          <Pill variant="primary" label="Already added" />
        ) : (
          <SecondRow>
            {isExistingContentModal && (
              <>
                <Pill
                  icon={ACCESS_LEVEL_MAPPING[accessLevel].icon}
                  size="medium"
                  variant={ACCESS_LEVEL_MAPPING[accessLevel].variant}
                  {...tooltip.targetProps}
                  round
                />
                <Tooltip id={tooltip.uid}>{ACCESS_LEVEL_MAPPING[accessLevel].tooltipText}</Tooltip>
              </>
            )}
            {isExistingContentModal && rating > 0 && (
              <Pill icon="star" size="medium" variant="rating200" label={round(rating, 1)} />
            )}
            {upcomingEventsCount > 0 && (
              <Pill
                variant="lightGray"
                label={`${upcomingEventsCount} ${upcomingEventsCount === 1 ? 'event' : 'events'}`}
              />
            )}

            {hasDuration && <Pill variant="lightGray" label={formatDuration(duration)} />}
            {isExistingContentModal &&
              map(tags, (tag) => (
                <Pill key={`${id}-tag-${tag}`} variant="lighterGray" label={tag} />
              ))}
          </SecondRow>
        )}
      </ItemInfo>
    </BaseContentSelect>
  );
};

ContentSelect.propTypes = {
  content: PropTypes.object,
  alreadySelected: PropTypes.bool,
  newlySelected: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  isExistingContentModal: PropTypes.bool,
};

export default ContentSelect;
