import PropTypes from 'prop-types';
import styled from 'styled-components';

const Clicker = styled.button`
  border: 0;
  margin: 0;
  padding: ${({ padding }) => padding || '0'};
  background: transparent;
  text-align: left;
  display: ${({ display }) => display || 'flex'};
  align-items: center;
  ${({ fullWidth }) => fullWidth && 'width: 100%; height: 100%'}
  cursor: ${({ cursor }) => cursor || 'pointer'};
  color: ${({ color }) => color || 'inherit'};
  font-family: inherit;
`;

Clicker.propTypes = {
  fullWidth: PropTypes.bool,
  padding: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  display: PropTypes.string,
  onClick: PropTypes.func,
};

Clicker.defaultProps = {
  type: 'button',
};

export default Clicker;
