import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BookmarkBorderOutlined from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlined from '@mui/icons-material/BookmarkOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CodeIcon from '@mui/icons-material/Code';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DvrIcon from '@mui/icons-material/Dvr';
import EditIcon from '@mui/icons-material/Edit';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineFilledIcon from '@mui/icons-material/ErrorOutline';
import EventIcon from '@mui/icons-material/Event';
import EventAvailable from '@mui/icons-material/EventAvailable';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventOutlined from '@mui/icons-material/EventOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import MailIcon from '@mui/icons-material/Mail';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PlaceIcon from '@mui/icons-material/Place';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import RedoOutlined from '@mui/icons-material/RedoOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RuleIcon from '@mui/icons-material/Rule';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';
import SourceIcon from '@mui/icons-material/Source';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import StarIcon from '@mui/icons-material/Star';
import StartOutlined from '@mui/icons-material/StartOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import TodayIcon from '@mui/icons-material/Today';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';

import SlackOutline from 'shared/components/Icon/Icons/slack.svg';
import { SvgIcon, SvgIconProps } from 'vendor/mui';

import ExemptionOutline from './icons/ExemptionOutline.svg';
import OptionalOutline from './icons/OptionalOutline.svg';
import RequiredOutline from './icons/RequiredOutline.svg';
import UnExemptionOutline from './icons/UnExemptionOutline.svg';

const ExemptionOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <ExemptionOutline />
  </SvgIcon>
);

const OptionalOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <OptionalOutline />
  </SvgIcon>
);

const RequiredOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <RequiredOutline />
  </SvgIcon>
);

const UnExemptionOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <UnExemptionOutline />
  </SvgIcon>
);

const SlackIcon = (props: SvgIconProps) => (
  <SvgIcon inheritViewBox {...props}>
    <SlackOutline />
  </SvgIcon>
);

export {
  AccessTimeIcon,
  AddCircleOutlineIcon,
  AddIcon,
  AddToPhotosIcon,
  ArchiveIcon,
  ArrowBack,
  ArrowForwardIcon,
  AssignmentTurnedInIcon,
  BookmarkBorderOutlined,
  BookmarkOutlined,
  CalendarMonthIcon,
  CalendarTodayIcon,
  CancelIcon,
  CheckIcon,
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  CheckCircleRoundedIcon,
  ChevronRightIcon,
  ClearAllOutlinedIcon,
  CloseOutlinedIcon,
  CodeIcon,
  ConnectWithoutContactIcon,
  ContactSupportOutlinedIcon,
  DeleteIcon,
  DescriptionIcon,
  DoNotDisturbAltOutlinedIcon,
  DownloadIcon,
  DragIndicatorIcon,
  DvrIcon,
  EditAttributesIcon,
  EventAvailable,
  EditIcon,
  ErrorIcon,
  ErrorOutlineFilledIcon,
  EventIcon,
  EventOutlined,
  EventNoteIcon,
  ExemptionOutlineIcon,
  FactCheckIcon,
  FilterListOutlinedIcon,
  GroupsIcon,
  HelpOutlineIcon,
  HighlightOffIcon,
  HighlightOffOutlinedIcon,
  HistoryToggleOffOutlinedIcon,
  InfoOutlinedIcon,
  InventoryIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
  KeyboardArrowUpIcon,
  LinearScaleOutlinedIcon,
  LinkedInIcon,
  LinkIcon,
  LocationOnIcon,
  LockIcon,
  MailIcon,
  ManageSearchIcon,
  MenuOpenOutlinedIcon,
  MoreVertIcon,
  NoAccountsOutlinedIcon,
  NorthOutlinedIcon,
  OpenInNewOutlinedIcon,
  OptionalOutlineIcon,
  PeopleIcon,
  PeopleOutlineIcon,
  PersonAddAlt1Icon,
  PersonPinCircleIcon,
  PersonRemoveAlt1Icon,
  PlaceIcon,
  PlayCircleIcon,
  PlayCircleOutlinedIcon,
  PrintOutlinedIcon,
  ReduceCapacityOutlinedIcon,
  RefreshIcon,
  RemoveCircleOutlineIcon,
  RedoOutlined,
  RequiredOutlineIcon,
  RuleIcon,
  RuleOutlinedIcon,
  SearchIcon,
  SettingsIcon,
  ShortTextOutlinedIcon,
  SlackIcon,
  SourceIcon,
  SouthOutlinedIcon,
  SplitscreenIcon,
  StarIcon,
  StartOutlined,
  SwapHorizOutlinedIcon,
  TodayIcon,
  TrendingFlatIcon,
  UnarchiveIcon,
  UnExemptionOutlineIcon,
  UnpublishedOutlinedIcon,
  VerifiedOutlinedIcon,
  VerticalAlignBottomOutlinedIcon,
  VerticalAlignTopOutlinedIcon,
  VideoLibraryIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  WarningAmberOutlinedIcon,
  WarningOutlinedIcon,
  WifiOutlinedIcon,
};
