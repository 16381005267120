import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import actions from 'entities/actions';
import { sessionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SessionBulkCopyModal from 'program-shared/components/SessionBulkDuplicateModal';
import SessionForm from 'program/components/SessionForm';
import { onSessionFormSubmitSuccess } from 'program/components/SessionForm/SessionFormHelpers';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import { getNextHalfHour, isGoogleMeetLink } from 'services/utils';
import Loading from 'shared/components/Loading';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { NAIVE_DATETIME_FORMAT, STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { useCurrentUser, useLabels, useOriginRoute, useQueryParams } from 'shared/hooks';
import { CREATE_SESSION_FOR_OTHERS_PERMISSION } from 'shared/permissions';
import { includes, isEmpty, toLower, toInteger, omit } from 'vendor/lodash';

const SessionMentorshipCreate = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();

  const { cloned: duplicatedSessionId, host: hostId, attendee: attendeeId } = useQueryParams();
  const { label_mentorship_tab: labelMentorship } = useLabels();

  const originRoute = useOriginRoute();
  const mentorsRoute = `${mapRoute('mentorList')}?o=next_session_date`;
  const backRoute = originRoute || mentorsRoute;

  const canCreateForOthers = includes(
    currentUser.permissions,
    CREATE_SESSION_FOR_OTHERS_PERMISSION
  );

  const defaultInitialValues = {
    starts_at: getNextHalfHour().format(NAIVE_DATETIME_FORMAT),
    duration: '01:00:00',
    allows_local: false,
    allows_online: true,
    room: '',
    meeting_url: '',
    google_calendar_resource_id: '',
    google_calendar_resource_email: '',
    google_calendar_calendar_owner: currentUser,
    extra_info: '',
    online_extra_info: '',
    attendee_id: null,
    host_id: canCreateForOthers ? null : currentUser.id,
    location_id: canCreateForOthers ? null : currentUser.location_id,
  };

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isPrefillingWithLastSession, setIsPrefillingWithLastSession] = useState(false);
  const [showPrefillBox, setShowPrefillBox] = useState(false);
  const [prefilledValuesCleaned, setPrefilledValuesCleaned] = useState(false);
  const [sessionToBulkDuplicate, setSessionToBulkDuplicate] = useState({});

  const [fetchSessionToDuplicate, { status: duplicatedSessionStatus }] = useEntities(
    actions.sessionMentorship.retrieveDetails,
    ({ status, data, statusCode }) => {
      if (statusCode === 403) {
        permissionDeniedRedirectAlert();
      }

      if (status === STATUS_DONE) {
        setSessionToCopy(data);
        setIsDuplicating(true);
        setShowPrefillBox(true);
      }
    },
    {
      schema: sessionSchema,
    }
  );

  const [fetchLastSession, { status: lastSessionsStatus }] = useEntities(
    actions.sessionMentorship.retrieveList,
    ({ status, data }) => {
      if (status === STATUS_DONE && !isEmpty(data)) {
        setSessionToCopy(data[0]);
        setIsPrefillingWithLastSession(true);
        setShowPrefillBox(true);
      }
    },
    {
      schema: [sessionSchema],
    }
  );

  const setSessionToCopy = (session) => {
    setInitialValues({
      ...omit(session, ['id', 'public_id']),
      meeting_url: isGoogleMeetLink(session.meeting_url) ? '' : session.meeting_url,
    });
  };

  const cleanPrefilledValues = () => {
    setInitialValues(defaultInitialValues);
    setIsDuplicating(false);
    setIsPrefillingWithLastSession(false);
    setShowPrefillBox(true);
    setPrefilledValuesCleaned(true);
  };

  const undoCleanPrefilledValues = () => {
    // We're only reloading the page for now because our BaseAsyncSelectField needs to be refactored.
    window.location.reload(true);
  };

  useEffect(() => {
    if (duplicatedSessionId) {
      fetchSessionToDuplicate(duplicatedSessionId);
      return;
    }

    if (hostId || attendeeId) {
      setInitialValues({
        ...defaultInitialValues,
        host_id: toInteger(hostId),
        attendee_id: toInteger(attendeeId),
      });
      return;
    }

    fetchLastSession({
      o: '-created',
      created_by: currentUser.id,
      page_size: 1,
    });
  }, [duplicatedSessionId]);

  const isFetchingDuplicatedSession =
    duplicatedSessionId && duplicatedSessionStatus === STATUS_LOADING;
  const isFetchingLastSession = !duplicatedSessionId && lastSessionsStatus === STATUS_LOADING;

  if (isFetchingDuplicatedSession || isFetchingLastSession) return <Loading />;

  return (
    <>
      <PageTitle
        title={`${duplicatedSessionId ? 'Duplicate' : 'Create'} ${labelMentorship} Session`}
      />
      <SessionForm
        isMentorshipSession
        form="newSessionMentorshipCreateForm"
        contentName={`${labelMentorship} Session`}
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={`${duplicatedSessionId ? 'Duplicating' : 'Creating'} Session`}
        isDuplicating={isDuplicating}
        isPrefillingWithLastSession={isPrefillingWithLastSession}
        showPrefillBox={showPrefillBox}
        prefilledValuesCleaned={prefilledValuesCleaned}
        cleanPrefilledValues={cleanPrefilledValues}
        undoCleanPrefilledValues={undoCleanPrefilledValues}
        backRoute={backRoute}
        breadcrumbsItemList={[
          {
            label: labelMentorship,
            link: mentorsRoute,
          },
          {
            label: `${duplicatedSessionId ? 'Duplicate' : 'New'} ${toLower(
              labelMentorship
            )} session`,
          },
        ]}
        onSubmitSuccessHandler={(result, saveAndDuplicate, saveAndBulkDuplicate) => {
          const { public_id: publicId } = result;
          const saveAndDuplicateRoute = `${mapRoute(
            'sessionMentorshipCreate'
          )}?cloned=${publicId}&from_previous=true`;

          onSessionFormSubmitSuccess(
            result,
            saveAndDuplicate,
            saveAndBulkDuplicate,
            setSessionToBulkDuplicate,
            history,
            false,
            backRoute,
            saveAndDuplicateRoute
          );
        }}
      />
      {!isEmpty(sessionToBulkDuplicate) && (
        <SessionBulkCopyModal
          session={sessionToBulkDuplicate}
          handleClose={() => {
            setSessionToBulkDuplicate({});
            history.push(
              mapRoute('sessionMentorshipEdit', { public_id: sessionToBulkDuplicate.public_id })
            );
          }}
          handleConfirm={() => {
            setSessionToBulkDuplicate({});
            history.push(
              originRoute || mapRoute('userProfile', { id: sessionToBulkDuplicate.host_id })
            );
          }}
        />
      )}
    </>
  );
};

export default SessionMentorshipCreate;
