import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { ActionSection } from 'automated-rule/components/AutomatedRuleForm/ActionSection';
import { OPERATION_PARAMETERS_MAP } from 'automated-rule/constants';
import actions from 'entities/actions';
import { toast } from 'notifications/components/NotificationCenter';
import { mapRoute } from 'services/requests';
import NewContentForm from 'shared/components/NewContentForm';
import { useFormPreventTransition, useFormSelector, useOriginRoute } from 'shared/hooks';
import { get, pick, reduce } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import { ConditionSection } from './ConditionSection';
import { DetailsSection } from './DetailsSection';

const AutomatedRuleForm = ({
  form,
  topBarActionName,
  isEdit,
  invalid,
  handleSubmit,
  error,
  submitting,
  change,
}) => {
  const backRoute = useOriginRoute(mapRoute('dashboardAutomatedRules'));
  const automatedRuleName = useFormSelector(form, 'name');
  useFormPreventTransition(form);

  const infoPanelText =
    'Automate away your repetitive workflows on PlusPlus with automated rules. ' +
    'Rules run daily, starting around midnight (Pacific Time), ' +
    'in the order defined on the Rules dashboard page.';

  const contentHCArticleURL =
    'https://help.plusplus.app/en/articles/4887753-plusplus-101-create-automated-rules-for-repetitive-workflows';

  const sections = [
    {
      id: 'automated-rule-details',
      label: 'Details',
      icon: 'info',
      section: <DetailsSection />,
      sectionProps: { defaultOpen: true },
    },
    {
      id: 'automated-rule-segment',
      label: 'Condition',
      icon: 'rule-outlined',
      section: <ConditionSection />,
      sectionProps: { defaultOpen: true },
    },
    {
      id: 'automated-rule-action',
      label: 'Action',
      icon: 'fast-forward',
      section: <ActionSection form={form} change={change} />,
      sectionProps: { defaultOpen: true },
    },
  ];

  return (
    <NewContentForm
      contentNameSingular="Automated Rule"
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={contentHCArticleURL}
      contentTitle={automatedRuleName}
      backRoute={backRoute}
      contentSectionsList={sections}
      advancedSettingsList={[]}
      topBarActionName={topBarActionName}
      invalid={invalid}
      submitting={submitting}
      error={error}
      handleSubmit={handleSubmit}
      isEdit={isEdit}
    />
  );
};

AutomatedRuleForm.propTypes = {
  form: PropTypes.string,
  topBarActionName: PropTypes.string,
  isEdit: PropTypes.bool,
  // Redux Form props
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, { form, isEdit, initialValues }) => {
    const actionName = actions.automatedRule[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const parametersFieldNames = OPERATION_PARAMETERS_MAP[values.operation];
      const parameters = reduce(
        parametersFieldNames,
        (acc, fieldName) => ({ ...acc, [fieldName]: get(values, fieldName, null) }),
        {}
      );

      const payload = {
        ...pick(values, ['is_enabled', 'name', 'description', 'segment_id', 'operation']),
        parameters,
      };

      const publicId = get(initialValues, 'public_id');

      return {
        ...(publicId && { id: publicId }),
        key: form,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Automated rule submission failed. Check the flagged field and try again.');
  },
})(AutomatedRuleForm);
