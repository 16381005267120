import { ComponentProps, ReactNode } from 'react';

import { Button } from 'shared';
import { Box, Typography, Link, Tooltip, Stack, Paper, grey } from 'vendor/mui';
import { HelpOutlineIcon, ArrowForwardIcon, AddIcon } from 'vendor/mui-icons';

interface WidgetProps {
  title: string;
  tooltip?: ReactNode;
  action?: ReactNode;
  children?: ReactNode;
}

export function Widget(props: WidgetProps) {
  const { title, tooltip, action, children } = props;

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2.5,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: 0,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Stack gap={1} direction="row" alignItems="center">
          <Typography variant="overline" noWrap gutterBottom={false}>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <HelpOutlineIcon sx={{ fontSize: 14, color: grey[600] }} />
            </Tooltip>
          )}
        </Stack>

        {action && <Box sx={{ display: 'flex', alignItems: 'center' }}>{action}</Box>}
      </Box>

      {children}
    </Paper>
  );
}

type WidgetLinkActionProps = {
  label: string;
  url: string;
  arrow?: boolean;
};

export function WidgetLinkAction(props: WidgetLinkActionProps) {
  const { label, url, arrow = true } = props;

  return (
    <Link href={url} variant="body2" noWrap display="inline-flex" gap={0.5}>
      {label} {arrow ? <ArrowForwardIcon fontSize="small" /> : null}
    </Link>
  );
}

type WidgetCreateActionProps = {
  label: string;
  onClick: () => void;
};

export function WidgetCreateAction(props: WidgetCreateActionProps) {
  const { label, onClick } = props;

  return (
    <Button variant="outlined" startIcon={<AddIcon />} size="small" onClick={onClick}>
      {label}
    </Button>
  );
}

type WidgetContentProps = ComponentProps<typeof Box>;

export function WidgetContent(props: WidgetContentProps) {
  return <Box paddingTop={1.5} {...props} />;
}

type WidgetContentListProps = {
  children: ReactNode;
};

export function WidgetContentList(props: WidgetContentListProps) {
  const { children } = props;

  return (
    <Box display="flex" flexDirection="column" rowGap={1.5}>
      {children}
    </Box>
  );
}

type WidgetContentListItemProps = {
  children: ReactNode;
};

export function WidgetContentListItem(props: WidgetContentListItemProps) {
  const { children } = props;

  return (
    <Box height={44} overflow="hidden">
      {children}
    </Box>
  );
}
