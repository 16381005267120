import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

import BaseFilter from 'backoffice/components/Dashboard/Filters/BaseFilter';
import FlexibleDateRangeInput from 'inputs/components/FlexibleDateRangeInput';
import {
  DATE_RANGE_EXACT_MODE,
  DATE_RANGE_RELATIVE_MODE,
} from 'inputs/components/FlexibleDateRangeInput/constants';
import { getDateString } from 'inputs/components/FlexibleDateRangeInput/utils';
import { get, isNil, isEmpty, includes, head, keys, debounce } from 'vendor/lodash';

import { filterInputSx } from '../styles';

// {$range: {min: '2022-07-01', max: '2022-07-31' } => {startDate: '2022-07-01', endDate: '2022-07-31'}
const rqlToInput = (filter) => {
  if (isNil(filter)) return { startDate: null, endDate: null };
  const op = head(keys(filter));
  if (op === '$range')
    return { startDate: get(filter[op], 'min', null), endDate: get(filter[op], 'max', null) };
  if (op === '$gt') return { startDate: filter[op], endDate: null };
  if (op === '$lt') return { startDate: null, endDate: filter[op] };
  return { startDate: null, endDate: null };
};

// {startDate: '2022-07-01', endDate: '2022-07-31'} => {$range: {min: '2022-07-01', max: '2022-07-31'}
const inputToRQL = ({ startDate, endDate }) => {
  if (!isEmpty(startDate) && !isEmpty(endDate)) return { $range: { min: startDate, max: endDate } };
  if (!isEmpty(startDate)) return { $gt: startDate };
  if (!isEmpty(endDate)) return { $lt: endDate };
  return null;
};

const getCurrentMode = ({ startDate, endDate, currentMode }) => {
  if (startDate && !includes(['P', '-'], startDate[0])) return DATE_RANGE_EXACT_MODE;
  if (endDate && !includes(['P', '-'], endDate[0])) return DATE_RANGE_EXACT_MODE;
  return currentMode;
};

const extractDataFromCurrentState = (rqlFilter, currentMode = DATE_RANGE_RELATIVE_MODE) => {
  const { startDate, endDate } = rqlToInput(rqlFilter);
  const mode = getCurrentMode({ startDate, endDate, currentMode });

  return [
    mode,
    mode === DATE_RANGE_EXACT_MODE && startDate ? moment(startDate) : startDate,
    mode === DATE_RANGE_EXACT_MODE && endDate ? moment(endDate) : endDate,
  ];
};

const RQLDateRangeFilter = (props) => {
  const {
    filter,
    onChange,
    // Base Filter
    label,
    handleRemoveFilter,
    inputWidth,
  } = props;

  const filterName = head(keys(filter));
  const rqlFilter = filter[filterName];

  const [currentMode, setCurrentMode] = useState(DATE_RANGE_RELATIVE_MODE);

  const [mode, startDate, endDate] = extractDataFromCurrentState(rqlFilter, currentMode);

  const handleChangeDateFilter = (values = {}) => {
    const { mode, startDate, endDate } = values;

    const formattedStartDate = getDateString(mode, startDate);
    const formattedEndDate = getDateString(mode, endDate);

    const newFilterValue = inputToRQL({ startDate: formattedStartDate, endDate: formattedEndDate });

    onChange({
      [filterName]: newFilterValue,
    });
    setCurrentMode(mode);
  };

  const debouncedHandleChangeDateFilter = useMemo(
    () => debounce(handleChangeDateFilter, 250),
    [onChange, filterName]
  );

  return (
    <BaseFilter
      label={label}
      handleRemoveFilter={handleRemoveFilter}
      inputWidth={inputWidth}
      renderInput={() => (
        <FlexibleDateRangeInput
          label={label}
          mode={mode}
          startDate={startDate}
          endDate={endDate}
          textInputSx={filterInputSx}
          onChange={debouncedHandleChangeDateFilter}
        />
      )}
    />
  );
};

RQLDateRangeFilter.defaultProps = {};

RQLDateRangeFilter.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  // BaseFilter
  label: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func,
  inputWidth: PropTypes.string,
};

export default RQLDateRangeFilter;
