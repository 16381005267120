import {
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
  LEARNING_TYPE_ICONS_MAPPING,
} from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import Pill from 'shared/components/Pill';
import { SizeType } from 'shared/components/Pill/Pill';
import { Tooltip } from 'vendor/mui';

interface ContentTypePillProps {
  item: any;
  size?: SizeType;
  helpTextRenderMode?: 'tooltip' | 'label';
}

const ContentTypePill = ({
  item,
  size = 'medium',
  helpTextRenderMode = 'tooltip',
}: ContentTypePillProps) => {
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[item.content_type];
  const label = useLearningTypeLabels()[learningType];

  return (
    <Tooltip title={label} disableHoverListener={helpTextRenderMode !== 'tooltip'}>
      <div>
        <Pill
          icon={LEARNING_TYPE_ICONS_MAPPING[learningType]}
          variant={`lightLearningType${learningType}`}
          size={size}
          label={helpTextRenderMode === 'label' ? label : ''}
          round
        />
      </div>
    </Tooltip>
  );
};

export default ContentTypePill;
