import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import actions from 'entities/actions';
import { eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
} from 'event-shared/constants';
import { getEarliestTimeslot, getUserEnrollmentStatus } from 'event-shared/services';
import colors from 'services/colors';
import { displayDatetime } from 'services/datetime';
import { mapRoute } from 'services/requests';
import InfoBox from 'shared/components/InfoBox';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { STATUS_DONE } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { map, includes } from 'vendor/lodash';

const ModalContainer = styled.div`
  > ${Text} + ${Text} {
    margin-top: 8px;
  }
`;

const getUserCurrentEnrollStatus = (enrollment) => {
  const {
    userIsOnLocalWaitlist,
    userIsOnOnlineWaitlist,
    userIsEnrolledLocal,
    userIsEnrolledOnline,
  } = getUserEnrollmentStatus(enrollment.status);

  if (userIsOnLocalWaitlist) {
    return { type: 'waitlist', text: 'Spot at in-person waitlist' };
  }
  if (userIsOnOnlineWaitlist) {
    return { type: 'waitlist', text: 'Spot at online waitlist' };
  }
  if (userIsEnrolledLocal) {
    return { type: 'enrollment', text: 'In-person enrollment' };
  }
  if (userIsEnrolledOnline) {
    return { type: 'enrollment', text: 'Online enrollment' };
  }

  return null;
};

const getProspectEventStatus = (prospectEnrollmentStatus) => {
  if (
    includes([ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE], prospectEnrollmentStatus)
  ) {
    return 'enrollment';
  }

  if (
    includes(
      [ENROLLMENT_STATUS_WAIT_LIST, ENROLLMENT_STATUS_WAIT_LIST_ONLINE],
      prospectEnrollmentStatus
    )
  ) {
    return 'waitlist';
  }

  return null;
};

const DoubleEnrollPerTypeWarnModal = ({
  prospectEvent,
  prospectEnrollmentStatus,
  updateEnrollmentStatus,
  updateEnrollmentRequestStatus,
  handleClose,
}) => {
  const [currentEvents, setCurrentEvents] = useState(null);

  const { id: userId } = useCurrentUser();

  const [getEvents] = useEntities(
    actions.event.retrieveList,
    ({ status, data: upcomingEvents }) => {
      if (status === STATUS_DONE && upcomingEvents.length > 0) {
        setCurrentEvents(upcomingEvents);
      }
    },
    {
      schema: [eventSchema],
    }
  );

  const getUpcomingEnrolledEventPerType = () => {
    getEvents(
      {
        event_types: [prospectEvent.event_type.id],
        engaged_users: [userId],
        engagement_type: [
          ENROLLMENT_STATUS_GOING,
          ENROLLMENT_STATUS_GOING_ONLINE,
          ENROLLMENT_STATUS_WAIT_LIST,
          ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
        ],
        period: 'upcoming',
        o: 'date',
      },
      { fetchAll: true }
    );
  };

  useEffect(() => {
    getUpcomingEnrolledEventPerType();
  }, []);

  useEffect(() => {
    if (updateEnrollmentRequestStatus === STATUS_DONE) {
      // Close modal and fetch upcoming events to reload its info
      // e.g1: refresh enrollment buttons of events cards in the events list page
      // e.g2: refresh enrollment buttons of upcoming events cards in the event type detail page
      handleClose();
      getEvents({
        event_types: [prospectEvent.event_type.id],
        period: 'upcoming',
        view_mode: 'detail',
      });
    }
  }, [updateEnrollmentRequestStatus]);

  const handleRemoveDoubleEnrollmentsPerType = () =>
    updateEnrollmentStatus(prospectEnrollmentStatus, {
      remove_double_enrollment_per_type: true,
    });

  if (!currentEvents || currentEvents.length === 0)
    return (
      <Modal title="Double Enrollment Warning" handleClose={handleClose} width={600}>
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );

  const {
    event_type: { name: eventTypeName },
  } = prospectEvent;

  const displayEventDatetime = (event) =>
    displayDatetime(getEarliestTimeslot(event).starts_at_tz_aware, event.timezone);
  const getEventRoute = (event) =>
    mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug });
  const getUserCurrentEnrollStatusText = (event) =>
    getUserCurrentEnrollStatus(event.enrollment)?.text;
  const prospectEventStatus = getProspectEventStatus(prospectEnrollmentStatus);

  return (
    <Modal title="Double Enrollment Warning" handleClose={handleClose} width={500}>
      <ModalBody>
        <ModalContainer>
          <Text size="h5" colors={colors.neutral900} block>
            You&apos;re already enrolled at a &quot;{eventTypeName}&quot; event. You cannot enroll
            in more than one upcoming event from &quot;{eventTypeName}&quot;.
          </Text>

          <Text size="h5" colors={colors.neutral900} block>
            You can keep your current:
            <ul>
              {map(currentEvents, (event) => {
                const userCurrentEnrollStatusText = getUserCurrentEnrollStatusText(event);

                return (
                  <li key={event.id}>
                    {userCurrentEnrollStatusText && `${getUserCurrentEnrollStatusText(event)} at `}
                    <Link target="_blank" to={getEventRoute(event)}>
                      {displayEventDatetime(event)}
                    </Link>
                  </li>
                );
              })}
            </ul>
            Or change
            {prospectEventStatus === 'enrollment' ? ' enrollment to ' : ` to waitlist on `}
            this event.
          </Text>

          <InfoBox
            content={`By changing ${
              prospectEventStatus === 'enrollment' ? 'enrollment' : `to waitlist`
            } you will be removed from your current enrollments or spots on the waitlist and ${
              prospectEventStatus === 'enrollment' ? 'enrolled in' : `join the waitlist for`
            } this event.`}
            margin="16px 0 0"
          />
        </ModalContainer>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton color="secondary" onClick={handleClose}>
          Keep Current Enrollments
        </ModalFooterButton>
        <ModalFooterButton onClick={() => handleRemoveDoubleEnrollmentsPerType()}>
          Change {prospectEventStatus === 'enrollment' ? 'Enrollment' : `to Waitlist`}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DoubleEnrollPerTypeWarnModal.propTypes = {
  prospectEvent: PropTypes.object,
  prospectEnrollmentStatus: PropTypes.string,
  updateEnrollmentStatus: PropTypes.func,
  updateEnrollmentRequestStatus: PropTypes.string,
  handleClose: PropTypes.func,
};

export default DoubleEnrollPerTypeWarnModal;
