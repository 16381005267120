import React, { useEffect } from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { useCreateContentFromChannelParams } from 'features/channels/hooks/useCreateContentFromChannelParams';
import Page404 from 'scenes/404';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import { useParentSettings } from 'settings/hooks';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, HTTP_404_NOT_FOUND } from 'shared/constants';
import {
  useCurrentUser,
  useLabels,
  useOriginRoute,
  useQueryParams,
  useSetDetailedObject,
} from 'shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import { get, includes, isEmpty, toLower } from 'vendor/lodash';

import TrackFormPage from './TrackFormPage';

const TrackCreate = () => {
  const currentUser = useCurrentUser();
  const parentSettingsFlat = useParentSettings('track/form', 'flat');
  const { label_track: labelTrack, label_track_plural: labelTrackPlural } = useLabels();

  const { cloned: duplicatedTrackPublicId } = useQueryParams();
  const isDuplicating = Boolean(duplicatedTrackPublicId);

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.tracks
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  const [fetchTrack, { data: clonedTrack, status, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ data: clonedTrack, status }) => {
      if (
        status === STATUS_DONE &&
        !includes(clonedTrack.permissions, CONTENT_ITEM_PERMISSIONS.manage)
      ) {
        permissionDeniedRedirectAlert();
      }
    },
    { schema: contentSchema }
  );

  useSetDetailedObject({ id: null }, 'tracks', []);

  useEffect(() => {
    if (duplicatedTrackPublicId) {
      fetchTrack(duplicatedTrackPublicId);
    }
  }, [duplicatedTrackPublicId]);

  if (duplicatedTrackPublicId && statusCode === HTTP_404_NOT_FOUND) {
    return <Page404 />;
  }
  if (
    (duplicatedTrackPublicId && status !== STATUS_DONE) ||
    (isFromChannel && isLoadingChannelData)
  ) {
    return <Loading />;
  }

  const initialValuesParams = {};

  if (duplicatedTrackPublicId) {
    initialValuesParams.clonedTrack = clonedTrack;
  }
  if (!duplicatedTrackPublicId && !isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  } else {
    initialValuesParams.defaultChannelId = get(currentUser, 'default_channel_id');
  }

  return (
    <TrackFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelTrack}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelTrack}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelTrackPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelTrack)}`,
        },
      ]}
    />
  );
};

export default TrackCreate;
