import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from 'inputs/components/TextField';
import { getGenericEmbedLink } from 'services/embed';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Form from 'shared/components/OldForm';
import { useFormSelector } from 'shared/hooks';
import { trim } from 'vendor/lodash';

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const validateRequired = Form.validations.required();

const validateUrl = (values, allValues, { embedLink }) => {
  if (!embedLink) return 'Sorry, the URL you entered is not embeddable. Please insert a valid URL.';
  return undefined;
};

const formName = 'websiteModal';

const WebsiteModal = ({ handleClose, handleSubmit, invalid, pristine }) => {
  const URLSource = useFormSelector(formName, 'url');

  return (
    <Modal handleClose={handleClose} title="Insert URL" width={550}>
      <ModalBody>
        <Form.FieldGroup>
          <Field
            label="Website URL"
            placeholder="Insert URL"
            name="url"
            component={TextField}
            validate={[validateRequired, validateUrl]}
          />
        </Form.FieldGroup>
      </ModalBody>
      {URLSource && (
        <PreviewWrapper>
          <iframe
            width="426px"
            height="240px"
            allowFullScreen
            frameBorder="0"
            src={URLSource}
            title={URLSource}
          />
        </PreviewWrapper>
      )}
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error" size="large">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} size="large" disabled={invalid || pristine}>
          Insert
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

WebsiteModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,

  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

const WebsiteModalForm = reduxForm({
  form: formName,
  onSubmit: (values, dispatch, { url, handleInsertClick }) => {
    handleInsertClick(url);
  },
})(WebsiteModal);

const formSelector = formValueSelector(formName);
const mapStateToProps = (state) => {
  const url = formSelector(state, 'url');
  const parsedUrl = trim(url);
  return {
    url: parsedUrl,
    embedLink: getGenericEmbedLink(parsedUrl),
  };
};

export default connect(mapStateToProps)(WebsiteModalForm);
