import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(RouterLink)`
  &:hover,
  &:focus {
    &,
    > * {
      opacity: ${(props) => props.theme.defaultLinkHoverOpacity};
    }
  }
`;

const LinkHover = (props) => <StyledLink {...props} />;

export default LinkHover;
