import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { detailQueryKeyFactory, listQueryKeyFactory } from '../utils';

export const programs = createQueryKeys('programs', {
  list: listQueryKeyFactory({ endpointName: 'programs_api:list_create' }),
  detail: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
});

export type ProgramsQueryKeys = inferQueryKeys<typeof programs>;
