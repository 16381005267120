import { useEffect } from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import { useCreateContentFromChannelParams } from 'features/channels/hooks/useCreateContentFromChannelParams';
import { useLinkedContentFetchMetadata } from 'linkedcontent/hooks';
import { mapRoute } from 'services/requests';
import { useParentSettings } from 'settings/hooks';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING } from 'shared/constants';
import { useOriginRoute, useQueryParams, useSetDetailedObject, useLabels } from 'shared/hooks';
import { isEmpty, toLower } from 'vendor/lodash';

import LinkedContentFormPage from './LinkedContentFormPage';

const LinkedContentCreate = () => {
  const parentSettingsFlat = useParentSettings('linkedcontent/form', 'flat');

  const { is_publishing, url: urlParam } = useQueryParams();
  const url = urlParam ? String(urlParam) : '';

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const {
    metadata,
    status: fetchMetadataStatus,
    fetch: fetchMetadata,
  } = useLinkedContentFetchMetadata();

  const {
    label_linkedcontent: labelLinkedContent,
    label_linkedcontent_plural: labelLinkedContentPlural,
  } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.linkedcontent
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  const isPublishing = is_publishing === 'true';

  useEffect(() => {
    if (isPublishing && url) {
      fetchMetadata({ url });
    }
  }, [isPublishing, url, fetchMetadata]);

  useSetDetailedObject({ id: null }, 'linkedcontent', []);

  if (fetchMetadataStatus === STATUS_LOADING || (isFromChannel && isLoadingChannelData)) {
    return <Loading />;
  }

  const initialValuesParams: Record<string, any> = {};

  if (!isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }
  if (!isEmpty(url)) {
    initialValuesParams.url = url;
  }
  if (!isEmpty(metadata)) {
    initialValuesParams.metadata = metadata;
  }

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  }

  return (
    <LinkedContentFormPage
      pageTitle={`Create ${labelLinkedContent}`}
      topBarActionName={`Creating ${labelLinkedContent}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelLinkedContentPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelLinkedContent)}`,
        },
      ]}
    />
  );
};

export default LinkedContentCreate;
