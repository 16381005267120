import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { STATUS_DONE } from 'app/shared/constants';
import actions from 'entities/actions';
import { sessionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SessionModalManager, {
  useSessionModalManagerState,
} from 'program/components/SessionModalManager';
import {
  userHasRole,
  userIsAttendee as currentUserIsAttendee,
  userIsHost as currentUserIsHost,
  useSessionEnrollmentManager,
} from 'program/services';
import { useCurrentUser, useRouterQueryUtils } from 'shared/hooks';
import { isEmpty } from 'vendor/lodash';

const SessionDetailRouteListener = () => {
  const currentUser = useCurrentUser();
  const { removeFromQueryString } = useRouterQueryUtils();
  const { search } = useLocation();
  const { mentorship_public_id: mentorshipPublicId, program_public_id: programPublicId } =
    queryString.parse(search);
  const [session, setSession] = useState({});

  const [fetchMentorshipSession] = useEntities(
    actions.sessionMentorship.retrieveDetails,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setSession(data);
      }
    },
    {
      schema: sessionSchema,
    }
  );
  const [fetchProgramSession] = useEntities(
    actions.sessionProgram.retrieveDetails,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setSession(data);
      }
    },
    {
      schema: sessionSchema,
    }
  );

  useEffect(() => {
    if (mentorshipPublicId) {
      fetchMentorshipSession(mentorshipPublicId);
    } else if (programPublicId) {
      fetchProgramSession(programPublicId);
    }
  }, [mentorshipPublicId, programPublicId]);

  const { modalManagerState, modalManagerSetters, openDetailsModal } =
    useSessionModalManagerState();

  // Open details modal when session is loaded
  useEffect(() => {
    if (session.public_id) {
      const userIsHost = currentUserIsHost(session, currentUser);
      const userIsAttendee = currentUserIsAttendee(session, currentUser);
      const userIsAdmin = userHasRole(currentUser, 'admin');

      // Permission check
      if (!isEmpty(session.enrollments) && (userIsAttendee || userIsHost || userIsAdmin)) {
        openDetailsModal();
      }
    }
  }, [session.public_id]);

  const { dropSession } = useSessionEnrollmentManager(session);

  return (
    <SessionModalManager
      session={session}
      state={modalManagerState}
      setters={modalManagerSetters}
      handleDropSession={dropSession}
      onClose={() => {
        removeFromQueryString(['mentorship_public_id', 'program_public_id']);
      }}
    />
  );
};

export default SessionDetailRouteListener;
