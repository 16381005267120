import PropTypes from 'prop-types';
import React from 'react';

import FacilitatorField from 'inputs/components/FacilitatorField';
import InfoBox from 'shared/components/InfoBox';

const FacilitatorsSection = ({ infoBoxContent }) => (
  <>
    <InfoBox content={infoBoxContent} hideIcon />

    <span data-testid="organizer-field">
      <FacilitatorField
        multiple={false}
        name="organizer_id"
        label="Organizer"
        infoText="Receives events notification and it’s mentioned on notification sent to attendees."
      />
    </span>

    <FacilitatorField
      name="co_organizers_ids"
      label="Co-organizer(s)"
      infoText="Responsible for event logistics and respond to attendee questions."
    />

    <FacilitatorField
      name="presenters_ids"
      label="Presenter(s)"
      infoText="Responsible for event presentation or facilitation."
      last
    />
  </>
);

FacilitatorsSection.propTypes = {
  infoBoxContent: PropTypes.string,
};

export default FacilitatorsSection;
