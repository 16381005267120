import PropTypes from 'prop-types';
import React from 'react';

const EmbeddedLinkRenderer = ({ block, contentState, blockProps }) => {
  const { preview } = blockProps;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { src, height, width } = entity.getData();

  if (preview) {
    return <a href={src}>{src}</a>;
  }

  return (
    <iframe
      height={height}
      width={width}
      src={src}
      frameBorder="0"
      allowFullScreen
      title="Wysiwyg Embedded Content"
    />
  );
};

EmbeddedLinkRenderer.propTypes = {
  block: PropTypes.object,
  contentState: PropTypes.object,
  blockProps: PropTypes.object,
};

export default EmbeddedLinkRenderer;
