import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Pill from 'shared/components/Pill';

const StyledPill = styled(Pill)`
  > * {
    margin-right: 4px;
  }
`;

const CancelledPill = ({ variant, size }) => {
  return <StyledPill icon="archived" variant={variant} label="Cancelled" size={size} />;
};

CancelledPill.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
};

CancelledPill.defaultProps = {
  variant: 'lightGray',
  size: 'large',
};

export default CancelledPill;
