import { Box } from 'vendor/mui';

const AccessRestricted = () => (
  <div>
    <Box sx={{ fontSize: '40px', color: 'neutral' }}>
      You probably don&apos;t have access to this page.
    </Box>
    <Box sx={{ marginTop: '15px', fontSize: '25px', color: 'neutral' }}>
      If you think something&apos;s wrong <a href="https://plusplus.co/support/">contact support</a>
      .
    </Box>
  </div>
);

export default AccessRestricted;
