import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';

import LazyDropdownFilter from './LazyDropdownFilter';

const LocationFilter = ({ param, ...props }) => {
  return (
    <LazyDropdownFilter
      label="Location"
      param={param}
      actionFunc={actions.location.retrieveList}
      {...props}
    />
  );
};

LocationFilter.propTypes = {
  param: PropTypes.string,
};

LocationFilter.defaultProps = {
  param: 'location',
};

export default LocationFilter;
