import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DisabledButton from 'event-shared/components/AttendanceActionManager/DisabledButton';
import { getEnrollmentClosesCutoff, getEnrollmentOpensCutoff } from 'event-shared/services';
import { displayDatetime } from 'services/datetime';
import { isNil } from 'vendor/lodash';

const EnrollmentClosed = (props) => {
  const { event, userTimezone } = props;
  const opens = getEnrollmentOpensCutoff(event);
  const closes = getEnrollmentClosesCutoff(event);
  if (isNil(opens) && isNil(closes)) return null;

  const displayOpens = isNil(opens)
    ? null
    : displayDatetime(opens, event.timezone, userTimezone, event.is_online);
  const displayCloses = isNil(closes)
    ? null
    : displayDatetime(closes, event.timezone, userTimezone, event.is_online);

  if (!isNil(closes) && moment().isAfter(closes)) {
    return <DisabledButton subText={`Enrollments closed on ${displayCloses}`} />;
  }
  if (!isNil(opens) && !isNil(closes)) {
    return <DisabledButton subText={`Enroll from ${displayOpens} until ${displayCloses}`} />;
  }
  return null;
};

EnrollmentClosed.propTypes = {
  event: PropTypes.object,
  userTimezone: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userTimezone: state.user.currentUser.timezone,
});

export const ConnectedEnrollmentClosed = connect(mapStateToProps)(EnrollmentClosed);
