export const CARD_LIST_WIDTH_THRESHOLDS = {
  /*
   * These media queries are specific for the content items card list
   * sm: 2 cards or less
   * md: 3 cards
   * lg: 4 cards
   * xl: 5 cards or more
   */
  sm: 648,
  md: 984,
  lg: 1320,
  xl: 1992,
};
