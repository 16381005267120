import PropTypes from 'prop-types';
import React, { useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { STATUS_DONE, STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import { map, includes } from 'vendor/lodash';

import AutocompleteField from '../AutocompleteField';

const SurveySelectField = (props) => {
  const [options, setOptions] = useState([]);

  const [fetch, { status }] = useEntities(actions.survey.retrieveList, ({ status, data }) => {
    if (status === STATUS_DONE) {
      setOptions(
        map(data.results, ({ id, title }) => ({
          value: id,
          label: title,
        }))
      );
    }
  });

  const loading = includes([STATUS_LOADING, STATUS_LOADING_MORE], status);

  const fetchOptionsCb = ({ q, includeValues }) => {
    fetch(
      {
        view_mode: 'filter_options',
        page_size: 50,
        include_id: includeValues || undefined,
        o: 'title',
        q: q || undefined,
      },
      { skipSchema: true }
    );
  };

  return (
    <AutocompleteField
      options={options}
      loading={loading}
      fetchOptions={fetchOptionsCb}
      {...props}
    />
  );
};

SurveySelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default SurveySelectField;
