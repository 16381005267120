export const ENTER_KEY = 13;
export const DATETIME_TIMECONSTRAINTS = {
  minutes: {
    step: 15,
  },
};

export const ISO_UNIT_OPTIONS = [
  {
    label: 'hour(s)',
    value: 'hours',
    isoIdentifier: 'H',
    type: 'time',
  },
  {
    label: 'day(s)',
    value: 'days',
    isoIdentifier: 'D',
    type: 'date',
  },
  {
    label: 'week(s)',
    value: 'weeks',
    isoIdentifier: 'W',
    type: 'date',
  },
  {
    label: 'month(s)',
    value: 'months',
    isoIdentifier: 'M',
    type: 'date',
  },
];
