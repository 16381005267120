import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import actions from 'entities/actions';
import { groupSchema, userSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import PrivacyField from 'groups/components/PrivacyField';
import InputLabel from 'inputs/components/InputLabel';
import SearchInput from 'inputs/components/SearchInput';
import TextInput from 'inputs/components/TextInput';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import AccessRestricted from 'shared/components/AccessRestricted';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import MediaPoint from 'shared/components/MediaPoint';
import FormPanel from 'shared/components/OldForm/FormPanel';
import { STATUS_LOADING, STATUS_LOADING_MORE, STATUS_ERROR, STATUS_DONE } from 'shared/constants';
import { useDebounce } from 'shared/hooks';
import { EDIT_GROUP_PERMISSION } from 'shared/permissions';
import { isEmpty, includes, join, get, map } from 'vendor/lodash';

import GroupUserList from './GroupUserList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  ${MediaPoint.DesktopSm} {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  align-self: center;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;

  ${MediaPoint.Tablet} {
    width: 500px;
    flex-direction: row;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.h2`
  color: ${colors.neutral400};
  justify-self: flex-start;
`;

const FormPanelContainer = styled.div`
  background-color: white;
  width: 300px;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 30px;
  ${MediaPoint.Tablet} {
    width: 500px;
  }
  ${MediaPoint.DesktopSm} {
    width: 600px;
    margin-bottom: 60px;
  }
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const GroupPrivacyOptions = styled.div`
  margin-bottom: 16px;
`;

const NameArea = styled.div`
  margin-bottom: 16px;
`;

const EditButtonContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  ${MediaPoint.DesktopSm} {
    flex-basis: 20%;
  }
`;

const Content = styled.div``;

const GroupDetail = () => {
  const { id } = useParams();

  const [group, setGroup] = useState({});
  const [regularMembers, setRegularMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [memberSearchText, setMemberSearchText] = useState('');
  const [ownerSearchText, setOwnerSearchText] = useState('');

  const { trackActivity } = useMetrics();

  const handleOnMemberSearchTextChange = (value) => setMemberSearchText(value);
  const handleOnOwnerSearchTextChange = (value) => setOwnerSearchText(value);

  const debouncedMemberSearchText = useDebounce(memberSearchText, 500);
  const debouncedOwnerSearchText = useDebounce(ownerSearchText, 500);

  const [fetchGroup, { status: fetchGroupStatus }] = useEntities(
    actions.groups.retrieveDetails,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        trackActivity(METRICS_ACTIVITIES.GROUP_VIEW, {
          groupId: id,
        });
        setGroup(data);
      }
    },
    {
      schema: groupSchema,
    }
  );

  const [
    fetchRegularMembers,
    { status: fetchRegularMembersStatus, nextPage: membersListMoreLink },
    fetchMembersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setRegularMembers(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  const [
    fetchOwners,
    { status: fetchOwnersStatus, nextPage: ownersListMoreLink },
    fetchOwnersNextPage,
  ] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setOwners(data);
      }
    },
    {
      schema: [userSchema],
      loadMoreAction: actions.userData.retrieveListLoadMore,
    }
  );

  useEffect(() => {
    fetchGroup(id, { view_mode: 'detail' });
  }, []);

  useEffect(() => {
    if (isEmpty(group.regular_members)) return;

    fetchRegularMembers({
      group: group.id,
      page_size: 20,
      q: debouncedMemberSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedMemberSearchText]);

  useEffect(() => {
    if (isEmpty(group.owners_ids)) return;

    const ownersIDs = join(group.owners_ids, ',');
    fetchOwners({
      id__in: ownersIDs,
      page_size: 20,
      q: debouncedOwnerSearchText,
      view_mode: 'with_location',
    });
  }, [group, debouncedOwnerSearchText]);

  if (fetchGroupStatus === STATUS_LOADING) return <Loading />;

  if (fetchGroupStatus === STATUS_ERROR) {
    return (
      <Container>
        <Header>
          <AccessRestricted />
        </Header>
      </Container>
    );
  }

  const { permissions } = group;
  const queryParams = `?origin=${mapRoute('groupDetails', { id: `${group.id}` })}`;
  const contentItems = get(group, 'content_items', []);

  return (
    <Container>
      <Header>
        <HeaderTitle>View {group.name}</HeaderTitle>
        {includes(permissions, EDIT_GROUP_PERMISSION) && (
          <EditButtonContainer>
            <Button
              route={`${mapRoute('groupEdit', {
                id: `${group.id}`,
              })}${queryParams}`}
              type="button"
              size="small"
            >
              Edit Group
            </Button>
          </EditButtonContainer>
        )}
      </Header>
      <FormPanelContainer>
        <FormPanel title="Group Details">
          <Content>
            <NameArea>
              <TextInput label="Name" value={group.name} disabled />
            </NameArea>
            <GroupPrivacyOptions>
              <InputLabel>Group Privacy</InputLabel>
              <PrivacyField disabled input={{ value: group.is_private }} label="Private" />
            </GroupPrivacyOptions>
            {group.source === 'integration' && <SimpleInfo>Created by Integration</SimpleInfo>}
            <SimpleInfo>Last Edited: {moment(group.modified).format('MMM DD, YYYY')}</SimpleInfo>
            <SimpleInfo>Active since: {moment(group.created).format('MMM DD, YYYY')}</SimpleInfo>
          </Content>
        </FormPanel>
        <FormPanel title={`Content (${group.content_items_count || 0})`} padding={0}>
          {map(contentItems, (contentItem) => (
            <div key={contentItem.public_id_and_slug}>
              <a href={contentItem.url} target="_blank" rel="noreferrer">
                {contentItem.name}
              </a>
              <br />
            </div>
          ))}
        </FormPanel>
        <FormPanel title={`Owners (${group.owners_count || 0})`} padding={0}>
          <SearchInput
            placeholder="Search group owners"
            value={ownerSearchText}
            onChange={handleOnOwnerSearchTextChange}
          />
          {isEmpty(owners) && fetchOwnersStatus === STATUS_LOADING ? (
            <Loading />
          ) : (
            <GroupUserList
              users={owners}
              fetchNextPage={fetchOwnersNextPage}
              isLoadingMore={fetchOwnersStatus === STATUS_LOADING_MORE}
              loadMoreLink={ownersListMoreLink}
            />
          )}
        </FormPanel>
        <FormPanel title={`Members (${group.members_count || 0})`} padding={0}>
          <SearchInput
            placeholder="Search group members"
            value={memberSearchText}
            onChange={handleOnMemberSearchTextChange}
          />
          {isEmpty(regularMembers) && fetchRegularMembersStatus === STATUS_LOADING ? (
            <Loading />
          ) : (
            <GroupUserList
              users={regularMembers}
              fetchNextPage={fetchMembersNextPage}
              isLoadingMore={fetchRegularMembersStatus === STATUS_LOADING_MORE}
              loadMoreLink={membersListMoreLink}
            />
          )}
        </FormPanel>
      </FormPanelContainer>
    </Container>
  );
};

export default GroupDetail;
