import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const PlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const VideoRenderer = ({ block, contentState }) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { src, width } = entity.getData();
  return (
    <PlayerWrapper>
      <ReactPlayer url={src} width={width} controls />
    </PlayerWrapper>
  );
};

VideoRenderer.propTypes = {
  block: PropTypes.object,
  contentState: PropTypes.object,
};

export default VideoRenderer;
