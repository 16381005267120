import {
  ASSIGNMENT_ACTIONS_ICONS,
  ASSIGNMENT_ACTIONS_LABELS,
  ASSIGNMENT_PERMISSIONS,
  ASSIGNMENT_STATES,
} from 'assignments/constants';
import { useAssignmentsActions } from 'assignments/hooks';
import colors from 'services/colors';
import { Assignment } from 'shared-content-item/interfaces';
import { includes } from 'vendor/lodash';
import { GridActionsCellItem, GridRowParams, GridActionsCellItemProps } from 'vendor/mui';

interface AssignmentsActionsParams extends GridRowParams {
  hideEdit?: boolean;
  handleRowEditClick: (assignment: Assignment) => void;
  handleRowViewProgressClick?: (assignment: Assignment) => void;
}

const AssignmentsActions = ({
  row,
  hideEdit = false,
  handleRowEditClick,
  handleRowViewProgressClick,
}: AssignmentsActionsParams): React.ReactElement<GridActionsCellItemProps>[] => {
  const { id, permissions, state } = row;
  const { drop, exempt, undoExemption, undoCompletion, complete, isLoading } =
    useAssignmentsActions(undefined, id, undefined, 'roster');
  const actions: JSX.Element[] = [];

  if (
    !hideEdit &&
    includes(permissions, ASSIGNMENT_PERMISSIONS.manage) &&
    !includes(
      [ASSIGNMENT_STATES.dropped, ASSIGNMENT_STATES.completed, ASSIGNMENT_STATES.exempted],
      state
    )
  ) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.update;
    actions.push(
      <GridActionsCellItem
        key={`edit-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.update}
        icon={<Icon />}
        onClick={() => handleRowEditClick(row)}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (handleRowViewProgressClick) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.view_progress;
    actions.push(
      <GridActionsCellItem
        key={`view-progress-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.view_progress}
        icon={<Icon />}
        onClick={() => handleRowViewProgressClick(row)}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ASSIGNMENT_PERMISSIONS.undo_exempt)) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.undo_exempt;
    actions.push(
      <GridActionsCellItem
        key={`undo-exempt-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.undo_exempt}
        icon={<Icon />}
        onClick={undoExemption}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ASSIGNMENT_PERMISSIONS.exempt)) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.exempt;
    actions.push(
      <GridActionsCellItem
        key={`exempt-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.exempt}
        icon={<Icon />}
        onClick={exempt}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ASSIGNMENT_PERMISSIONS.undo_complete)) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.undo_complete;
    actions.push(
      <GridActionsCellItem
        key={`undo-complete-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.undo_complete}
        icon={<Icon />}
        onClick={undoCompletion}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ASSIGNMENT_PERMISSIONS.complete)) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.complete;
    actions.push(
      <GridActionsCellItem
        key={`complete-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.complete}
        icon={<Icon />}
        onClick={complete}
        disabled={isLoading}
        showInMenu
      />
    );
  }
  if (includes(permissions, ASSIGNMENT_PERMISSIONS.drop)) {
    const Icon = ASSIGNMENT_ACTIONS_ICONS.drop;
    actions.push(
      <GridActionsCellItem
        key={`drop-assignment-${id}`}
        label={ASSIGNMENT_ACTIONS_LABELS.drop}
        icon={<Icon sx={{ color: colors.error600 }} />}
        onClick={() => drop({ force: true })}
        disabled={isLoading}
        sx={{ color: colors.error600 }}
        showInMenu
      />
    );
  }
  return actions;
};

export default AssignmentsActions;
