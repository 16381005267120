import { useHistory } from 'react-router';
import type { ValueOf } from 'type-fest';

import { CellLink } from 'backoffice/components/Dashboard/Cells';
import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import ContentTypePill from 'content-items/components/ContentTypePill';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import { Button } from 'shared';
import ConditionalWrapper from 'shared/components/ConditionalWrapper';
import Modal, { ModalBody } from 'shared/components/Modal';
import ProgressBar from 'shared/components/ProgressBar';
import Text from 'shared/components/Text';
import { STATUS_LOADING } from 'shared/constants';
import { useLabels } from 'shared/hooks';
import { useFetchTrackItems } from 'tracks/hooks';
import { includes } from 'vendor/lodash';
import { DataGrid, GridActionsCellItem, Stack, Tooltip, GridColDef } from 'vendor/mui';
import { RuleIcon } from 'vendor/mui-icons';

const TrackItemCompletionStatsModal = ({ track, handleClose }: any) => {
  const history = useHistory();

  const { content_type } = track;

  const {
    label_track: labelTrack,
    label_assessment: labelAssessment,
    label_question: labelQuestion,
  } = useLabels();

  const { label, parentFilterName, viewMode } =
    {
      [CONTENT_TYPES.track]: {
        label: labelTrack,
        rosterURL: 'contentItemManageAssignments',
        parentFilterName: 'parent_track',
        viewMode: 'completion_stats',
      },
      [CONTENT_TYPES.scheduled_track]: {
        label: `Scheduled ${labelTrack}`,
        rosterURL: 'contentItemManageEnrollments',
        parentFilterName: 'parent_scheduled_track',
        viewMode: 'completion_stats',
      },
      [CONTENT_TYPES.assessment]: {
        label: labelAssessment,
        rosterURL: 'contentItemManageAssignments',
        parentFilterName: 'assessment',
        viewMode: 'completion_stats_with_success',
      },
    }[track.content_type] || {};

  const getContentTypeConstants = (rowContentType: ValueOf<typeof CONTENT_TYPES>) =>
    ({
      [CONTENT_TYPES.event]: {
        rosterURL: 'eventManageAttendance',
      },
    }[rowContentType] || { rosterURL: 'contentItemManageAssignments' });

  const contentTypeRoutes = useContentTypeRoutes();
  const hasIsCorrectColumn = includes([CONTENT_TYPES.assessment], track.content_type);

  const canViewProgressDetails = includes(
    [CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track],
    track.content_type
  );

  const hasActionsColumn = canViewProgressDetails;

  const { trackItems, status, count } = useFetchTrackItems({
    trackId: track.id,
    trackType: content_type,
    viewMode,
    skipSchema: true,
  });

  return (
    <Modal
      handleClose={handleClose}
      title={`${label} progress`}
      width={window.innerWidth > 1024 ? 1024 : '80%'}
    >
      <ModalBody>
        <DataGrid
          autoHeight
          sx={{
            border: 'none',
          }}
          loading={status === STATUS_LOADING}
          columns={[
            {
              field: 'order',
              headerName: '#',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1,
              renderCell: ({ row: trackItem }) => trackItem.order + 1,
            },
            {
              field: 'content_type',
              headerName: 'Type',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 3,
              renderCell: ({ row: trackItem }) =>
                trackItem.content_item && (
                  <ContentTypePill item={trackItem.content_item} helpTextRenderMode="label" />
                ),
            },
            {
              field: 'name',
              headerName:
                track.content_type === CONTENT_TYPES.assessment ? labelQuestion : 'Content Item',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 8,
              renderCell: ({ row: trackItem }) => {
                const route = contentTypeRoutes[trackItem.content_item?.content_type]?.details;

                if (!route) return trackItem.content_item?.name;

                return (
                  <CellLink
                    to={contentTypeRoutes[trackItem.content_item?.content_type]?.details?.({
                      public_id_and_slug: trackItem.content_item?.public_id_and_slug,
                    })}
                    $highlighted
                  >
                    {trackItem.content_item?.name}
                  </CellLink>
                );
              },
            },
            {
              field: 'assignments',
              headerName: 'Assignments',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 3,
              renderCell: ({ row: trackItem }) => (
                <Tooltip
                  title="View Progress by Assignee"
                  disableHoverListener={!canViewProgressDetails}
                >
                  <ConditionalWrapper
                    condition={canViewProgressDetails}
                    wrapper={(children) => (
                      <Button
                        variant="text"
                        fullWidth
                        sx={{
                          p: 0,
                          m: 0,
                          color: 'inherit',
                          fontWeight: 'inherit',
                        }}
                        route={
                          canViewProgressDetails
                            ? `${mapRoute(
                                getContentTypeConstants(trackItem.content_item.content_type)
                                  .rosterURL,
                                {
                                  public_id_and_slug: trackItem.content_item.public_id_and_slug,
                                }
                              )}?eq(${parentFilterName},${track.id})`
                            : undefined
                        }
                      >
                        {children}
                      </Button>
                    )}
                  >
                    <Stack sx={{ width: '100%' }} alignItems="start">
                      <ProgressBar
                        completedProgress={
                          (trackItem.assignments_completed_count / trackItem.assignments_count) *
                          100
                        }
                        completedColor={colors.success600}
                      />
                      <Text>
                        {trackItem.assignments_completed_count}/{trackItem.assignments_count}{' '}
                        completed
                      </Text>
                    </Stack>
                  </ConditionalWrapper>
                </Tooltip>
              ),
            },
            ...(hasIsCorrectColumn
              ? [
                  {
                    field: 'correct_assignments',
                    headerName: 'Correct answers',
                    filterable: false,
                    sortable: false,
                    editable: false,
                    flex: 3,
                    renderCell: ({ row: trackItem }) => (
                      <Stack sx={{ width: '100%' }} alignItems="start">
                        <ProgressBar
                          completedProgress={
                            (trackItem.assignments_completed_with_success_count /
                              trackItem.assignments_count) *
                            100
                          }
                          completedColor={colors.success600}
                        />
                        <Text>
                          {trackItem.assignments_completed_with_success_count}/
                          {trackItem.assignments_count} correct
                        </Text>
                      </Stack>
                    ),
                  },
                ]
              : []),
            ...(hasActionsColumn
              ? [
                  {
                    field: 'actions',
                    type: 'actions',
                    width: 20,
                    sortable: false,
                    editable: false,
                    getActions: ({ row: trackItem }) =>
                      canViewProgressDetails
                        ? [
                            <GridActionsCellItem
                              key={`view-track-iem-roster-${trackItem.id}`}
                              label="View Progress by Assignee"
                              icon={<RuleIcon sx={{ color: colors.neutral600 }} />}
                              onClick={() =>
                                history.push(
                                  `${mapRoute(
                                    getContentTypeConstants(trackItem.content_item.content_type)
                                      .rosterURL,
                                    {
                                      public_id_and_slug: trackItem.content_item.public_id_and_slug,
                                    }
                                  )}?eq(${parentFilterName},${track.id})`
                                )
                              }
                              showInMenu
                            />,
                          ]
                        : [],
                  } as GridColDef,
                ]
              : []),
          ]}
          rows={trackItems || []}
          rowCount={count}
          pagination
          rowsPerPageOptions={[6]}
          pageSize={6}
          disableSelectionOnClick
        />
        <Stack direction="row" justifyContent="end">
          <Button
            variant="contained"
            sx={{ mb: 1.5 }}
            onClick={() =>
              history.push(
                mapRoute('contentItemManageAssignments', {
                  public_id_and_slug: track.public_id_and_slug,
                })
              )
            }
          >
            View Assignments
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  );
};

export default TrackItemCompletionStatsModal;
