import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory } from 'data-fetching/utils';
import { ApiURLs, fetchURL } from 'services/requests-base';

const fetchChannel = async (slug: string, viewMode?: string) => {
  let url = ApiURLs['channels_api:retrieve_update_destroy']({ slug });

  if (viewMode) {
    url += `?view_mode=${viewMode}`;
  }

  const { data: response } = await fetchURL(url);
  return response;
};

export const channels = createQueryKeys('channels', {
  list: listQueryKeyFactory({ endpointName: 'channels_api:list_create' }),
  detail: (slug: string, viewMode?: string) => ({
    queryKey: [slug, viewMode],
    queryFn: () => fetchChannel(slug, viewMode),
  }),
});

export type SegmentsQueryKeys = inferQueryKeys<typeof channels>;
