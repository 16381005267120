import { CONTENT_TYPES } from 'app/catalog/constants';
import { LinkedContent } from 'app/linkedcontent/interfaces';
import { ContentItem } from 'app/shared-content-item/interfaces';
import CodelabPrimaryActionButton from 'scenes/CodelabDetail/PrimaryActionButton';
import CoursePrimaryActionButton from 'scenes/CourseDetail/PrimaryActionButton';
import EventTypePrimaryActionButton from 'scenes/EventTypeDetail/PrimaryActionButton';
import LinkedContentPrimaryActionButton from 'scenes/LinkedContentDetail/PrimaryActionButton';
import VideoPrimaryActionButton from 'scenes/VideoDetail/PrimaryActionButton';
import DefaultPrimaryActionButton from 'shared-content-item/components/PrimaryActionButton';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';

import { TrackPrimaryActionButton } from './TrackPrimaryActionButton';

type TrackItemPrimaryActionSwitcherProps = {
  contentItem: ContentItem;
};

export function TrackItemPrimaryActionSwitcher(props: TrackItemPrimaryActionSwitcherProps) {
  const { contentItem } = props;

  const { currentTrackItemContentType: contentType } = useTrackContentConsumptionCtx();

  return (
    <>
      {contentType === CONTENT_TYPES.eventtype && (
        <EventTypePrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.article && (
        <DefaultPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.linkedcontent && (
        <LinkedContentPrimaryActionButton content={contentItem as LinkedContent} />
      )}
      {contentType === CONTENT_TYPES.video && <VideoPrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.codelab && (
        <CodelabPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.course && <CoursePrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.task && <DefaultPrimaryActionButton content={contentItem} />}
      {contentType === CONTENT_TYPES.assessment && (
        <TrackPrimaryActionButton content={contentItem} />
      )}
      {contentType === CONTENT_TYPES.track && <TrackPrimaryActionButton content={contentItem} />}
    </>
  );
}
