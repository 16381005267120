import React, { useCallback, useEffect } from 'react';

import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { CONTENT_TYPES } from 'app/catalog/constants';
import CircularProgressWithLabel from 'app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';
import actions from 'entities/actions';
import { userProfileSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import { formatDate } from 'services/datetime';
import { Assignment, ContentItem } from 'shared-content-item/interfaces';
import { get, isNil, isNumber, replace, size, toNumber, trimEnd } from 'vendor/lodash';
import { Avatar, Box, Typography, Stack } from 'vendor/mui';
import { TrendingFlatIcon, CheckCircleIcon } from 'vendor/mui-icons';

const useGetAssignmentData = (contentItem: ContentItem) => {
  const [fetchUser, { data: assignedBy }] = useEntities(actions.userData.retrieveDetails, null, {
    schema: userProfileSchema,
  });

  const createdById = get(contentItem, 'user_assignment.created_by_id', null);

  const fetchAssignedBy = useCallback(() => {
    if (isNil(createdById)) return;
    fetchUser(createdById, { view_mode: 'lite' });
  }, [createdById, fetchUser]);

  useEffect(() => {
    fetchAssignedBy();
  }, [fetchAssignedBy]);

  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  if (contentItem?.user_assignment) {
    const assignment = contentItem?.user_assignment;
    return {
      assignment,
      assignedBy,
      assignedDate: get(assignment, 'created_at', null),
      startedDate: get(assignment, 'progress.started_at', null),
      dueDate: get(assignment, 'due_at', null),
      completedDate: get(assignment, 'completion.completed_at', null),
      droppedDate: get(assignment, 'drop.dropped_at', null),
    };
  }

  const assignment = contentItem?.assignment;
  return {
    assignment,
    assignedBy: get(assignment, 'created_by', null),
    assignedDate: get(assignment, 'created', null),
    startedDate: get(assignment, 'started_at', null),
    dueDate: get(assignment, 'due_date', null),
    completedDate: get(assignment, 'completed_datetime', null),
    droppedDate: get(assignment, 'drop.dropped_at', null),
  };
};

function getAssignmentProgress(assignment?: Assignment) {
  return get(assignment, 'progress.progress', 0) * 100;
}

function TimelineText(props: React.ComponentProps<typeof Typography>) {
  return <Typography variant="body1" fontSize="13px" {...props} />;
}

export interface ContentItemHeaderTimelineProps {
  contentItem: ContentItem;
  assignment: Assignment;
}

export const ContentItemHeaderTimeline = (props: ContentItemHeaderTimelineProps) => {
  const { contentItem, assignment } = props;
  const { content_type: contentType } = contentItem;

  const { assignedBy, assignedDate, startedDate, dueDate, completedDate, droppedDate } =
    useGetAssignmentData(contentItem);

  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const isEvent = contentType === CONTENT_TYPES.event;

  const assignmentScore = toNumber(get(assignment, 'completion.score', '0')) * 100;
  const assignmentState = get(assignment, 'state');
  const assignmentProgress = getAssignmentProgress(assignment);

  const timeline: React.ReactElement[] = [];

  if (assignedDate) {
    if (isEvent) {
      timeline.push(
        <TimelineText key="assignedDate">{`Enrolled ${formatDate(assignedDate)}`}</TimelineText>
      );
    } else {
      timeline.push(
        <TimelineText key="assignedDate">{`Assigned ${formatDate(assignedDate)}`}</TimelineText>
      );
    }
  }

  if (startedDate) {
    if (isEvent) {
      timeline.push(
        <TimelineText key="startedDate">{`Attended ${formatDate(startedDate)}`}</TimelineText>
      );
    } else {
      timeline.push(
        <TimelineText key="startedDate">{`Started ${formatDate(startedDate)}`}</TimelineText>
      );
    }
  }

  if (assignmentState !== ASSIGNMENT_STATES.completed && assignmentProgress) {
    timeline.push(
      <CircularProgressWithLabel
        variant="determinate"
        size={30}
        value={assignmentProgress}
        title="15"
      />
    );
  }

  if (droppedDate) {
    timeline.push(
      <TimelineText key="droppedDate">{`Dropped ${formatDate(droppedDate)}`}</TimelineText>
    );
  }

  if (dueDate && !completedDate) {
    timeline.push(<TimelineText key="dueDate">{`Due ${formatDate(dueDate)}`}</TimelineText>);
  }

  if (completedDate) {
    timeline.push(
      <React.Fragment key="completedDate">
        <CheckCircleIcon fontSize="small" htmlColor={colors.success700} />
        <TimelineText fontWeight={700} color={colors.success700}>{`Completed ${formatDate(
          completedDate
        )}`}</TimelineText>
      </React.Fragment>
    );
  }

  if (isAssessment && completedDate && isNumber(assignmentScore)) {
    timeline.push(
      <TimelineText key="score">{`Score: ${replace(
        trimEnd(assignmentScore.toFixed(2), '0'),
        /\.$/,
        ''
      )}%`}</TimelineText>
    );
  }

  return (
    <Box width="auto" display="flex" alignItems="center" padding="20px 20px 20px 0px">
      {assignedBy && (
        <Avatar
          src={assignedBy.profile_image}
          alt={assignedBy.display_name}
          sx={{ width: 24, height: 24, marginRight: '4px' }}
        />
      )}

      {size(timeline) > 0 && (
        <Stack
          direction="row"
          divider={
            <TrendingFlatIcon
              fontSize="small"
              htmlColor={colors.neutral400}
              sx={{ margin: '0 4px' }}
            />
          }
          alignItems="center"
        >
          {timeline}
        </Stack>
      )}
    </Box>
  );
};
