import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const EditIcon = ({ width, height, fill }) => {
  const d =
    'M13.98 3.885l3.131 3.132a.34.34 0 0 1 0 .48L9.528 15.08l-3.222.357a.675.675 0 0 1-.747-.746l' +
    '.358-3.222L13.5 3.885a.34.34 0 0 1 .48 0zm5.624-.795L17.91 1.396a1.358 1.358 0 0 0-1.917 0l-' +
    '1.23 1.229a.34.34 0 0 0 0 .48l3.133 3.131a.34.34 0 0 0 .479 0l1.23-1.23a1.358 1.358 0 0 0 0-' +
    '1.916zm-5.842 9.48v4.363H1.82V5.013h8.795a.427.427 0 0 0 .296-.12l.974-.962a.417.417 0 0 0-.' +
    '295-.712H1.667C.747 3.219 0 3.965 0 4.885v12.223c0 .92.747 1.666 1.667 1.666h12.222c.92 0 1.' +
    '667-.746 1.667-1.666v-5.48a.417.417 0 0 0-.712-.295l-.96.942a.427.427 0 0 0-.122.296z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default EditIcon;
