import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SurveyPreviewContent from 'scenes/SurveyPreview/SurveyPreviewContent';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import Clicker from 'shared/components/Clicker';
import InfoBox from 'shared/components/InfoBox';
import Text from 'shared/components/Text';

const Content = styled.div`
  margin-top: 32px;
`;

const SurveyCard = styled.div`
  background: ${colors.neutral0};
  max-width: 580px;
  margin: 16px auto 48px;
  border-radius: 10px;
`;

const SurveyCardHeader = styled.div`
  padding: 24px 32px;
`;

const SurveyCardBody = styled.div`
  padding: 32px;
  border-top: 1px solid ${colors.neutral200};
`;

const SurveyCardFooter = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.neutral200};

  display: flex;
  justify-content: center;
`;

const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const StyledClicker = styled(Clicker)`
  display: inline;
  color: ${colors.emphasis600};
  &:hover {
    color: ${colors.neutral900};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  max-width: 580px;
  margin: 36px auto 20px;
`;

const SurveyCreationPreview = ({ survey, handleClosePreview }) => {
  return (
    <Content>
      <SurveyCard>
        <SurveyCardHeader>
          <Text size="h1" block>
            {survey.title}
          </Text>
          <Subtitle>
            <Text color={colors.neutral600} size="h3" medium>
              Survey
            </Text>
          </Subtitle>
          <InfoBox
            content={
              <Text size="h5" color={colors.neutral900}>
                This page is a preview of the survey you are currently creating. If you wish to make
                any additional changes, go back to the{' '}
                <StyledClicker onClick={handleClosePreview}>survey creation form</StyledClicker>.
              </Text>
            }
          />
        </SurveyCardHeader>
        <SurveyCardBody>
          <SurveyPreviewContent survey={survey} />
        </SurveyCardBody>
        <SurveyCardFooter />
      </SurveyCard>

      <ButtonsContainer>
        <Button type="button" color="error" size="large" onClick={handleClosePreview}>
          Back
        </Button>
      </ButtonsContainer>
    </Content>
  );
};

SurveyCreationPreview.propTypes = {
  survey: PropTypes.object,
  handleClosePreview: PropTypes.func,
};

export default SurveyCreationPreview;
