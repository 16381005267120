import { handleActions } from 'redux-actions';

import {
  STATUS_LOADING,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
} from '../shared/constants';

import actions from './actions';

const defaultState = {
  currentUser: {
    toggles: {},
    labels: {},
    module_toggles: {},
    module_labels: {},
    permissions: [],
  },
  currentUserResponse: null,
  currentUserStatus: STATUS_NOT_REQUESTED,
  currentUserUpdateStatus: STATUS_NOT_REQUESTED,
};

export default handleActions(
  {
    [actions.currentUserRequestSubmit]: (state) => ({
      ...state,
      currentUserStatus: STATUS_LOADING,
    }),
    [actions.currentUserRequestSuccess]: (state, action) => ({
      ...state,
      currentUser: action.payload,
      currentUserResponse: action.payload,
      currentUserStatus: STATUS_DONE,
    }),
    [actions.currentUserRequestFailure]: (state, action) => ({
      ...state,
      currentUser: { ...defaultState.currentUser },
      currentUserResponse: action.payload,
      currentUserStatus: STATUS_ERROR,
    }),

    [actions.currentUserUpdateRequestSubmit]: (state) => ({
      ...state,
      currentUserUpdateStatus: STATUS_LOADING,
    }),
    [actions.currentUserUpdateRequestSuccess]: (state, action) => ({
      ...state,
      currentUser: action.payload,
      currentUserResponse: action.payload,
      currentUserUpdateStatus: STATUS_DONE,
    }),
    [actions.currentUserUpdateRequestFailure]: (state, action) => ({
      ...state,
      currentUserResponse: action.payload,
      currentUserUpdateStatus: STATUS_ERROR,
    }),
  },
  defaultState
);
