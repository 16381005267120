import PropTypes from 'prop-types';
import React from 'react';

import propTypes from 'services/prop-types';

const LINK_REGEX = /(https?:\/\/\S+)/g;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  let start;

  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function linkStrategy(contentBlock, callback, contentState) {
  // This first statement looks for LINK blocks. Those are the links
  // added via the wysiwyg editor. Code was extracted from:
  // https://github.com/jpuri/react-draft-wysiwyg/blob
  // /1c8e85c45e466884b1e4494b50242442de27aa6e/src
  // /decorators/Link/index.js#L6
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);

  // This accounts for loosen links in the text and makes them clicable.
  findWithRegex(LINK_REGEX, contentBlock, callback);
}

const LinkDecorator = (props) => {
  const { decoratedText, children, entityKey, contentState } = props;

  // Loosen links in the text will show up in the decoratedText param.
  // Presence of an entityKey means that this is a LINK block.
  let url = decoratedText;
  if (entityKey) {
    ({ url } = contentState.getEntity(entityKey).getData());
  }

  return (
    <a rel="noopener noreferrer" href={url} target="_blank">
      {children}
    </a>
  );
};

LinkDecorator.propTypes = {
  decoratedText: PropTypes.string,
  children: propTypes.anyChildren,
  entityKey: PropTypes.string,
  contentState: PropTypes.object,
};

export const linkDecorator = {
  strategy: linkStrategy,
  component: LinkDecorator,
};
