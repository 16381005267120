import { createActions } from 'redux-actions';

export default createActions(
  {},
  'EVENTS_FETCH_REQUEST',
  'EVENTS_FETCH_REQUEST_SUBMIT',
  'EVENTS_FETCH_REQUEST_SUCCESS',
  'EVENTS_FETCH_REQUEST_FAILURE',

  'ENROLLMENTS_FETCH_REQUEST',
  'ENROLLMENTS_FETCH_REQUEST_SUBMIT',
  'ENROLLMENTS_FETCH_REQUEST_SUCCESS',
  'ENROLLMENTS_FETCH_REQUEST_FAILURE',

  'EVENT_TYPES_FETCH_REQUEST',
  'EVENT_TYPES_FETCH_REQUEST_SUBMIT',
  'EVENT_TYPES_FETCH_REQUEST_SUCCESS',
  'EVENT_TYPES_FETCH_REQUEST_FAILURE'
);
