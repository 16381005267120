import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import actions from 'entities/actions';
import { surveyPreviewSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import BaseContainer from 'navigation/components/BaseContainer';
import { toast } from 'notifications/components/NotificationCenter';
import { useEntityMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import HelpLink from 'shared/components/HelpLink';
import InfoBox from 'shared/components/InfoBox';
import PageTitle from 'shared/components/PageTitle';
import { STATUS_DONE, STATUS_ERROR } from 'shared/constants';
import { useOriginRoute, useUnsavedChangesPrompt } from 'shared/hooks';
import SurveyForm from 'surveys/components/SurveyForm';
import { editSurveyJSONSchema } from 'surveys/components/SurveyForm/surveyJSONSchema';
import { transformFromPreviewToJSONEditorFormat } from 'surveys/services';

const SurveyEdit = () => {
  const { id: surveyId } = useParams();
  const [value, setValue] = useState(null);
  const history = useHistory();
  const { trackEntityActivity } = useEntityMetrics();

  const redirectionRoute = useOriginRoute(mapRoute('dashboardSurveys'));

  const [fetchSurvey, { status: fetchSurveyStatus, data: survey }] = useEntities(
    actions.survey.preview,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        setValue(transformFromPreviewToJSONEditorFormat(data, true));
      } else if (status === STATUS_ERROR) {
        toast.error(`Fetching survey ${surveyId} failed.`);
      }
    },
    {
      schema: surveyPreviewSchema,
    }
  );

  const [submitSurvey, { status: submitSurveyStatus }] = useEntities(
    actions.survey.update,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        toast.success('Survey successfully edited!');
        trackEntityActivity({
          id: data,
          isEdit: true,
          entityType: 'survey',
        });
        history.push(redirectionRoute);
      } else if (status === STATUS_ERROR) {
        toast.error('Submission failed, fix errors and try again.');
      }
    }
  );

  useUnsavedChangesPrompt(
    submitSurveyStatus !== STATUS_DONE,
    'Your survey has not been saved. Are you sure you wish to leave this form without saving your work?'
  );

  const handleSubmit = (payload) => {
    submitSurvey(surveyId, payload);
  };

  useEffect(() => {
    fetchSurvey(surveyId);
  }, [surveyId]);

  return (
    <BaseContainer>
      <PageTitle title="Edit Survey" />
      <SurveyForm
        value={value}
        title="Edit Survey"
        schema={editSurveyJSONSchema}
        isLoading={fetchSurveyStatus !== STATUS_DONE}
        onSubmit={handleSubmit}
        redirectionRoute={redirectionRoute}
        renderHeader={() =>
          survey.responses_count > 0 && (
            <InfoBox
              hideIcon
              margin="16px 0"
              type="warning"
              content={
                <>
                  Please note that altering the meaning of the questions, or changing the single /
                  multiple-choice options may make it harder to reason about the responses that have
                  been submitted this far.
                  <HelpLink url="https://help.plusplus.app/en/articles/5538317-how-to-edit-surveys">
                    Learn more about editing surveys.
                  </HelpLink>
                </>
              }
            />
          )
        }
      />
    </BaseContainer>
  );
};

export default SurveyEdit;
