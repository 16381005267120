import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RadioField from 'inputs/components/RadioField';
import { toString } from 'vendor/lodash';

const InputContainer = styled.div`
  display: flex;

  :first-of-type {
    margin-left: ${({ block }) => (block ? '15' : '0')}px;
  }

  span {
    margin-left: 4px;
  }
`;

const RadioContainer = styled.div`
  margin-right: 20px;
`;

export const PrivacyField = (props) => {
  const { input, disabled, label } = props;

  input.value = toString(input.value);

  const privacyLabel = label || 'Hidden';

  return (
    <InputContainer>
      <RadioContainer>
        <RadioField label={privacyLabel} input={input} optionValue="true" disabled={disabled} />
      </RadioContainer>
      <RadioContainer>
        <RadioField label="Public" input={input} optionValue="false" disabled={disabled} />
      </RadioContainer>
    </InputContainer>
  );
};

PrivacyField.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default PrivacyField;
