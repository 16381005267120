import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { UserPopover } from 'app/shared/components/UserAvatar';
import colors from 'services/colors';
import { getUserImage } from 'services/utils';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import UserItem from 'shared/components/UserItem';
import { map } from 'vendor/lodash';

const UserList = styled.div`
  background-color: ${colors.neutral0};
  display: flex;
  flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
  margin: 5px;
`;

const MessageSection = styled.div`
  margin: 20px 0;
  padding: 0 30px;
`;

const Message = styled.span`
  font-size: 17px;
  color: #025a83;
`;

export const UserListModalShared = (props) => {
  const { modalTitle, users, message, handleClose } = props;

  return (
    <Modal
      handleClose={handleClose}
      title={modalTitle}
      height={40}
      width={window.innerWidth > 600 ? 600 : null}
    >
      <ModalBody>
        <UserList>
          {map(users, (item, i) =>
            item.id ? (
              <ItemWrapper key={i}>
                <Link to={`/users/${item.id}/`}>
                  <UserPopover
                    showPopover
                    sendEmailCTA
                    user={item}
                    renderPopoverHoverable={
                      <UserItem
                        userId={item.id}
                        userName={item.name || item.email}
                        userImage={getUserImage(item)}
                        size={32}
                      />
                    }
                  />
                </Link>
              </ItemWrapper>
            ) : (
              <ItemWrapper key={i}>
                <UserItem userEmail={item.email} size={32} />
              </ItemWrapper>
            )
          )}
          <MessageSection>{message && <Message>{message}</Message>}</MessageSection>
        </UserList>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

UserListModalShared.propTypes = {
  modalTitle: PropTypes.string,

  users: PropTypes.array,
  message: PropTypes.string,

  handleClose: PropTypes.func,
};

export default UserListModalShared;
