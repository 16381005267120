import pluralize from 'pluralize';

import { Assessment } from 'assessments/interfaces';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { useLabels } from 'shared/hooks';
import { toLower } from 'vendor/lodash';
import { Typography } from 'vendor/mui';

interface RetryAssessmentModalProps {
  assessment: Assessment;
  handleClose: () => void;
  handleConfirm: () => void;
}

const RetryAssessmentModal = ({
  assessment,
  handleClose,
  handleConfirm,
}: RetryAssessmentModalProps) => {
  const { label_assessment: labelAssessment } = useLabels();
  const lowerLabelAssessment = toLower(labelAssessment);

  const { remaining_attempts: remainingAttempts } = assessment;

  return (
    <Modal
      title="Retry Assessment"
      handleClose={handleClose}
      width={window.innerWidth > 580 ? 580 : null}
      minBodyHeight={120}
    >
      <ModalBody>
        <Typography>Are you sure you want retake this {lowerLabelAssessment}?</Typography>
        <Typography>
          You may retry this {lowerLabelAssessment} <b>{remainingAttempts}</b>{' '}
          {pluralize('more time', remainingAttempts)}.
        </Typography>
      </ModalBody>

      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton variant="text" color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="button" color="primary" onClick={handleConfirm}>
          Confirm
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default RetryAssessmentModal;
