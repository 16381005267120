import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  ATTENDANCE_METHOD_ONLINE,
  SESSION_ROSTER_AGENDA_TAB,
  SESSION_ROSTER_DEFAULT_TAB,
} from 'program/constants';
import {
  getEnrollmentsByMethod,
  getSessionAttendanceMethod,
  getUserEnrollment,
  sessionStartsAtHasPassed,
  sessionEndsAtHasPassed,
  userIsAttendee,
  userIsHost,
  userHasRole,
  getSessionCancellationLimit,
} from 'program/services';
import colors from 'services/colors';
import ButtonLink from 'shared/components/ButtonLink';
import Datetime from 'shared/components/Datetime';
import Modal, { ModalBody, ModalFooter, ModalFooterButtonLink } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { useCurrentUser, useLabels } from 'shared/hooks';
import { isEmpty, get, includes, size, toLower } from 'vendor/lodash';

const ModalContent = styled.div`
  position: relative;
  max-height: 60vh;
  overflow: auto;
`;

export const ModalSection = styled.div`
  margin-bottom: 8px;
  position: relative;
  left: 0;

  span {
    text-align: left;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AttendeesText = styled.span`
  margin-right: 8px;
`;

const CancellationCopy = styled.div`
  margin-top: 8px;
`;

export const SessionDetailsModal = ({
  handleClose,
  handleDropSession: dropSession,
  onOpenRoster,
  session,
  userTimezone,
}) => {
  const { enrollments } = session;

  const {
    label_mentorship_sessions_creator_title_single: mentorshipHostLabel,
    label_program_sessions_creator_title_single: programSessionHostLabel,
    label_mentorship_sessions_attendee_title_single: mentorshipAttendeeLabel,
    label_mentorship_sessions_attendee_title_plural: mentorshipAttendeesLabel,
    label_program_sessions_attendee_title_single: programAttendeeLabel,
    label_program_sessions_attendee_title_plural: programAttendeesLabel,
    label_program: labelProgram,
  } = useLabels();
  const currentUser = useCurrentUser();
  const currentUserIsHost = userIsHost(session, currentUser);
  const currentUserIsAttendee = userIsAttendee(session, currentUser);
  const currentUserIsAdmin = userHasRole(currentUser, 'admin');

  const hasSingleAttendees = enrollments.length === 1;
  const sessionEnrollmentMethod = getSessionAttendanceMethod(session, currentUser);
  const userEnrollment = getUserEnrollment(session, currentUser);
  const attendRemotely =
    userEnrollment && userEnrollment.attendance_method === ATTENDANCE_METHOD_ONLINE;
  const hostLabel = session.program_id ? programSessionHostLabel : mentorshipHostLabel;
  const attendeeLabel = session.program_id ? programAttendeeLabel : mentorshipAttendeeLabel;
  const attendeesLabel = session.program_id ? programAttendeesLabel : mentorshipAttendeesLabel;

  const openMeetingURL = () => {
    window.open(session.meeting_url, '_blank');
  };

  const handleDropSession = () => {
    dropSession();
    handleClose();
  };

  const renderAttendees = () => {
    if (!session.program_id && !isEmpty(enrollments)) {
      return enrollments[0].attendee.name;
    }

    if (userEnrollment) {
      return hasSingleAttendees
        ? 'You are attending'
        : `You +${enrollments.length - 1} are attending`;
    }

    if (size(enrollments) === 1) {
      return enrollments[0].attendee.name;
    }

    if (size(enrollments) > 1) {
      return `${enrollments.length} people`;
    }

    // "No Attendee" should not happen because the modal is only accessible for booked sessions
    // It's here as a fallback
    return 'No attendee';
  };

  const renderAgenda = () => {
    if (enrollments.length === 1) {
      if (!isEmpty(enrollments) && enrollments[0].agenda) {
        return enrollments[0].agenda;
      }
      return null;
    }

    return currentUserIsAdmin || currentUserIsHost ? (
      <ButtonLink onClick={() => onOpenRoster(SESSION_ROSTER_AGENDA_TAB)}>View agenda</ButtonLink>
    ) : null;
  };

  const hasAnyOnlineEnrollment = !isEmpty(
    getEnrollmentsByMethod(session, ATTENDANCE_METHOD_ONLINE)
  );

  const isUpcomingSession = !sessionStartsAtHasPassed(session, userTimezone);
  const sessionHasEnded = sessionEndsAtHasPassed(session, userTimezone);

  const showDropButton =
    currentUserIsAdmin || currentUserIsHost || (currentUserIsAttendee && isUpcomingSession);
  const showJoinOnlineButton =
    !sessionHasEnded &&
    (attendRemotely || (hasAnyOnlineEnrollment && (currentUserIsHost || currentUserIsAdmin)));

  const manageAttendeesLink =
    isUpcomingSession &&
    session.attendance_limit !== 1 &&
    (currentUserIsAdmin || currentUserIsHost) ? (
      <ButtonLink onClick={() => onOpenRoster(SESSION_ROSTER_DEFAULT_TAB)}>
        Manage {toLower(attendeesLabel)}
      </ButtonLink>
    ) : null;

  const sessionAgenda = renderAgenda();

  const { preCutoff, cancellationDateLimit } = getSessionCancellationLimit(currentUser, session);

  const getCutoffLabel = (preCutoff) => {
    if (includes(preCutoff, '1:')) {
      return `${preCutoff} hour`;
    }
    if (includes(preCutoff, 'day')) {
      return preCutoff;
    }

    return `${preCutoff} hours`;
  };

  const cancellationCopy = `This session requires a ${getCutoffLabel(
    preCutoff
  )} cancellation notice. After
          ${cancellationDateLimit}, please contact the organizer.`;

  return (
    <Modal title="Session Details" height="420px" width={500} handleClose={handleClose}>
      <ModalBody>
        <ModalContent>
          <ModalSection>
            <Text size="h5" block color={colors.neutral600}>
              {hostLabel}:
            </Text>
            <Text size="h4" block>
              {session.host.name} {currentUserIsHost && ' (You)'}
            </Text>
          </ModalSection>
          <ModalSection>
            <Text size="h5" block color={colors.neutral600}>
              {hasSingleAttendees ? `${attendeeLabel}:` : `${attendeesLabel}:`}
            </Text>
            <Text size="h4" block>
              <AttendeesText>{renderAttendees()}</AttendeesText>
              {session.program_id && manageAttendeesLink}
            </Text>
          </ModalSection>
          {session.program_id && (
            <ModalSection>
              <Text size="h5" block color={colors.neutral600}>
                {labelProgram}:
              </Text>
              <Text size="h4" block>
                {session.program_name || session.name}
              </Text>
            </ModalSection>
          )}
          <ModalSection>
            <Text size="h5" block color={colors.neutral600}>
              Date and Time:
            </Text>
            <Text size="h4" block>
              <Datetime.Range
                key={session.starts_at_tz_aware}
                start={session.starts_at_tz_aware}
                end={session.ends_at_tz_aware}
                timezone={session.timezone}
                displayTimezone={userTimezone}
                isOnline={session.allows_online}
              />
            </Text>
          </ModalSection>
          <ModalSection>
            <Text size="h5" block color={colors.neutral600}>
              Location:
            </Text>
            <Text size="h4" block>
              {get(session, 'location.name', '')}
            </Text>
          </ModalSection>
          {sessionEnrollmentMethod && (
            <ModalSection>
              <Text size="h5" block color={colors.neutral600}>
                Enrollment Method:
              </Text>
              <Text size="h4" block>
                {sessionEnrollmentMethod}
              </Text>
            </ModalSection>
          )}
          {!isEmpty(session.extra_info) && !attendRemotely && (
            <ModalSection>
              <Text size="h5" block color={colors.neutral600}>
                Extra Info:
              </Text>
              <Text size="h4" block>
                {session.extra_info}
              </Text>
            </ModalSection>
          )}
          {!isEmpty(session.online_extra_info) && attendRemotely && (
            <ModalSection>
              <Text size="h5" block color={colors.neutral600}>
                Extra Info (Online):
              </Text>
              <Text size="h4" block>
                {session.online_extra_info}
              </Text>
            </ModalSection>
          )}
          {sessionAgenda !== null && (
            <ModalSection>
              <Text size="h5" block color={colors.neutral600}>
                Agenda:
              </Text>
              {sessionAgenda}
            </ModalSection>
          )}
          {cancellationDateLimit && <CancellationCopy>{cancellationCopy}</CancellationCopy>}
        </ModalContent>
      </ModalBody>

      {(showDropButton || showJoinOnlineButton) && (
        <ModalFooter variant="links" justifyContent="spaceBetween">
          {showDropButton && (
            <ModalFooterButtonLink onClick={handleDropSession} variant="error">
              {currentUserIsAttendee ? 'Unenroll' : 'Delete Session'}
            </ModalFooterButtonLink>
          )}
          {showJoinOnlineButton && (
            <ModalFooterButtonLink onClick={openMeetingURL}>
              Join Online Meeting
            </ModalFooterButtonLink>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

SessionDetailsModal.propTypes = {
  session: PropTypes.object,
  handleClose: PropTypes.func,
  handleDropSession: PropTypes.func,
  onOpenRoster: PropTypes.func,
  userTimezone: PropTypes.string,
};

export default SessionDetailsModal;
