export const { DEFAULT_EVENT_COVER } = window;

export const EVENT_STATUS_SEATS_AVAILABLE = 'seats_available';
export const EVENT_STATUS_WAIT_LIST_AVAILABLE = 'wait_list_available';
export const EVENT_STATUS_FULL = 'full';

export const ENROLLMENT_ATTENDANCE_STATUS_ENROLLED = 'enrolled';
export const ENROLLMENT_ATTENDANCE_STATUS_WAITLISTED = 'waitlisted';
export const ENROLLMENT_ATTENDANCE_STATUS_DROPPED = 'dropped';
export const ENROLLMENT_ATTENDANCE_STATUS_NO_SHOW = 'no_show';
export const ENROLLMENT_ATTENDANCE_STATUS_CHECKED_IN = 'checked_in';
export const ENROLLMENT_ATTENDANCE_STATUS_CANCELLED = 'cancelled';
export const ENROLLMENT_STATUS_UNANSWERED = 'unanswered';
export const ENROLLMENT_STATUS_NOT_GOING = 'not_going';
export const ENROLLMENT_STATUS_GOING = 'going';
export const ENROLLMENT_STATUS_GOING_ONLINE = 'going_online';
export const ENROLLMENT_STATUS_WAIT_LIST = 'wait_list';
export const ENROLLMENT_STATUS_WAIT_LIST_ONLINE = 'wait_list_online';
export const ENROLLMENT_STATUS_ATTENDED = 'attended';
export const ENROLLMENT_STATUS_EXIT_WAIT_LIST = 'exit_wait_list';
export const ENROLLMENT_STATUS_CHECKIN_UNDONE = 'checkin_undone';
export const ENROLLMENT_CHANGE_CALENDAR_SOURCE = 'calendar';
export const ENROLLMENT_CHANGE_INTEGRATION_SOURCE = 'integration';
export const ENROLLMENT_CHANGE_SYSTEM_SOURCE = 'system';
export const ENROLLMENT_CHANGE_USER_SOURCE = 'user';

export const UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL = 'unenrollment_is_final';
export const UNENROLLMENT_POLICY_SAME_AS_ENROLLMENT = 'same_link_as_enrollment';

export const EVENT_TYPE_CHANGES_UPCOMING_EVENTS = 'upcoming_events';
export const EVENT_TYPE_CHANGES_ALL_EVENTS = 'all_events';
export const EVENT_TYPE_CHANGES_PREVIOUS_EVENTS = 'previous_events';

export const LIVE_STREAMED_DEFAULT_LABEL = 'Online';
export const LOCAL_DEFAULT_LABEL = 'In Person';

export const PREFERRED_EVENT_ATTENDANCE_METHOD_LOCAL = 'local';
export const PREFERRED_EVENT_ATTENDANCE_METHOD_ONLINE = 'online';
export const PREFERRED_EVENT_ATTENDANCE_METHOD_NEITHER = 'neither';

export const CONVERT_EVENT_LOCAL_TO_ONLINE = 'local_to_online';
export const CONVERT_EVENT_ONLINE_TO_LOCAL = 'online_to_local';
export const CONVERT_EVENT_MERGE_TO_LOCAL = 'merge_to_local';
export const CONVERT_EVENT_MERGE_TO_ONLINE = 'merge_to_online';

export const DOUBLE_ENROLLMENT_PER_TYPE_NOT_GRANTED_EXCEPTION_NAME =
  'double_enrollment_per_type_not_granted_exception';

export const CANT_UNENROLL_EVENT_OF_SCHEDULED_TRACK_CODE =
  'cant_unenroll_event_that_belongs_to_scheduled_track_error';

export const WATCH_LINK_METHOD = { custom: 'custom', google: 'google' };
