export const PERIOD_OPTIONS = [
  { value: 'hours', label: 'hour(s)' },
  { value: 'days', label: 'day(s)' },
  { value: 'weeks', label: 'week(s)' },
  { value: 'months', label: 'month(s)' },
];

export const SECONDS_IN_A_HOUR = 3600;
export const SECONDS_IN_A_DAY = SECONDS_IN_A_HOUR * 24;
export const SECONDS_IN_A_WEEK = SECONDS_IN_A_DAY * 7;
export const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * 30;
