import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

import { CONTENT_TYPES } from 'app/catalog/constants';
import colors from 'services/colors';
import VisibilityChip from 'shared-content-item/components/VisibilityChip';
import Text from 'shared/components/Text';
import { useEllipsisCheck } from 'shared/hooks';
import { TrackItem } from 'tracks/interfaces';
import { getTrackItemBorderStyle } from 'tracks/services';
import { includes, size } from 'vendor/lodash';
import { amber, Box, grey, IconButton, Tooltip } from 'vendor/mui';
import { DragIndicatorIcon, WarningAmberOutlinedIcon } from 'vendor/mui-icons';

interface TrackItemSidebarItemProps {
  buttonAriaLabel?: string;
  dropDownButtonAriaLabel?: string;
  id: string;
  label: string;
  trackItem: TrackItem;
  onReorder?: () => void;
  settingItems?: React.ReactElement;
  showWarning?: boolean;
  title?: React.ReactElement;
  subLabel?: string;
  contentTypeLabel?: string;
  warningText?: string;
}

interface SortableElementProps {
  transform?: string;
  transition?: string;
}

const SortableElement = styled.div<SortableElementProps>`
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
`;

function getVariantStyle(item: TrackItem, showWarning: boolean) {
  if (item.archival) {
    return {
      bg: colors.neutral100,
      bgHover: colors.neutral50,
    };
  }

  if (item.content_item.content_type === CONTENT_TYPES.eventtype) {
    return {
      color: amber[700],
    };
  }

  if (item.content_item.content_type === CONTENT_TYPES.event) {
    return {
      color: colors.success700,
    };
  }

  if (showWarning) {
    return {
      color: amber[700],
      bg: amber[50],
      bgHover: amber[100],
    };
  }

  return {
    bg: colors.neutral0,
    bgHover: colors.neutral50,
  };
}

const TrackItemSidebarItem = (props: TrackItemSidebarItemProps) => {
  const {
    buttonAriaLabel = 'Button',
    id,
    label,
    trackItem,
    onReorder,
    // showWarning = false,
    title,
    subLabel,
    contentTypeLabel,
    warningText,
  } = props;

  const { nodeRef, hasEllipsis } = useEllipsisCheck({ multipleLines: true });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const showWarning = warningText != null && size(warningText) > 0;

  const variantStyle = getVariantStyle(trackItem, showWarning);

  const isQuestion = includes(
    [CONTENT_TYPES.question, CONTENT_TYPES.text_question, CONTENT_TYPES.multiple_choice_question],
    trackItem.content_item.content_type
  );

  return (
    <SortableElement
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
    >
      <Box
        aria-label={buttonAriaLabel}
        key={id}
        id={id}
        sx={{
          backgroundColor: variantStyle.bg,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 8px 8px 0',
          border: `1px solid ${colors.neutral200}`,
          borderRadius: '2px',
          borderColor: grey['400'],
          ...getTrackItemBorderStyle(trackItem),

          '&:hover': {
            backgroundColor: variantStyle.bgHover,
          },
        }}
      >
        {onReorder && (
          <Box {...attributes} {...listeners}>
            <IconButton size="small" sx={{ color: colors.neutral200 }}>
              <DragIndicatorIcon cursor="grab" />
            </IconButton>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          alignSelf="stretch"
          paddingY="4px"
          paddingRight="8px"
        >
          <Box>
            <Text size="h6" bold color={colors.neutral600}>
              {contentTypeLabel}
            </Text>
          </Box>
          <Box>
            {title && (
              <Text size="h4" bold color={colors.neutral900}>
                {title}
              </Text>
            )}

            <Tooltip title={label} disableHoverListener={!hasEllipsis} arrow>
              <Text
                ref={nodeRef}
                size="h5"
                wrapAnywhere
                ellipsisOnLine={2}
                color={colors.neutral900}
              >
                {label}
              </Text>
            </Tooltip>
          </Box>

          {!isQuestion && (
            <>
              <Box display="flex" alignItems="center" gap={0.75} sx={{ maxWidth: '180px' }}>
                {showWarning && (
                  <Tooltip arrow title={warningText}>
                    <WarningAmberOutlinedIcon sx={{ fontSize: '18px', color: amber[700] }} />
                  </Tooltip>
                )}

                <Text
                  size="h6"
                  bold
                  ellipsisOnLine={2}
                  color={variantStyle.color || colors.neutral600}
                >
                  {subLabel}
                </Text>
              </Box>
              <Box display="flex" alignItems="center" gap={0.75} sx={{ maxWidth: '180px' }}>
                <VisibilityChip
                  content={trackItem.content_item}
                  showIcon
                  showLabel
                  bg_color="white"
                  size="small"
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </SortableElement>
  );
};

export default TrackItemSidebarItem;
