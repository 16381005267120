import {
  AddIcon,
  CheckCircleRoundedIcon,
  DoNotDisturbAltOutlinedIcon,
  ErrorOutlineFilledIcon,
  ExemptionOutlineIcon,
  HighlightOffOutlinedIcon,
  HistoryToggleOffOutlinedIcon,
  LocationOnIcon,
  NoAccountsOutlinedIcon,
  PersonAddAlt1Icon,
  PersonRemoveAlt1Icon,
  RemoveCircleOutlineIcon,
  WifiOutlinedIcon,
} from 'vendor/mui-icons';

export enum ENROLLMENT_VIEW_MODES {
  lite = 'lite',
  roster = 'roster',
  engaged_people = 'engaged_people',
  event_detail = 'event_detail',
  dashboard = 'dashboard',
  'default' = 'default',
  full = 'full',
}

export enum ATTENDANCE_STATUSES {
  enrolled = 'enrolled',
  waitlisted = 'waitlisted',
  checked_in = 'checked_in',
  dropped = 'dropped',
  no_show = 'no_show',
  cancelled = 'cancelled',
  unknown = 'unknown',
}

export enum ATTENDANCE_METHODS {
  local = 'local',
  online = 'online',
  unknown = 'unknown',
}

export const ATTENDANCE_METHODS_ICONS = {
  [ATTENDANCE_METHODS.online]: WifiOutlinedIcon,
  [ATTENDANCE_METHODS.local]: LocationOnIcon,
};

export const ATTENDANCE_STATUSES_LABELS = {
  [ATTENDANCE_STATUSES.enrolled]: 'Enrolled',
  [ATTENDANCE_STATUSES.waitlisted]: 'Waitlisted',
  [ATTENDANCE_STATUSES.checked_in]: 'Checked-in',
  [ATTENDANCE_STATUSES.dropped]: 'Dropped',
  [ATTENDANCE_STATUSES.no_show]: 'No-show',
  [ATTENDANCE_STATUSES.cancelled]: 'Cancelled',
  [ATTENDANCE_STATUSES.unknown]: 'Unknown',
};

export const ATTENDANCE_METHODS_LABELS = {
  [ATTENDANCE_METHODS.local]: 'In Person',
  [ATTENDANCE_METHODS.online]: 'Online',
  [ATTENDANCE_METHODS.unknown]: 'Unknown',
};

export const ATTENDANCE_STATUSES_ICONS = {
  [ATTENDANCE_STATUSES.enrolled]: ExemptionOutlineIcon,
  [ATTENDANCE_STATUSES.waitlisted]: HistoryToggleOffOutlinedIcon,
  [ATTENDANCE_STATUSES.checked_in]: CheckCircleRoundedIcon,
  [ATTENDANCE_STATUSES.dropped]: HighlightOffOutlinedIcon,
  [ATTENDANCE_STATUSES.cancelled]: DoNotDisturbAltOutlinedIcon,
  [ATTENDANCE_STATUSES.no_show]: NoAccountsOutlinedIcon,
  [ATTENDANCE_STATUSES.unknown]: ErrorOutlineFilledIcon,
};

export enum ENROLLMENT_ACTIONS {
  create = 'create',
  checkin = 'checkin',
  undo_checkin = 'undo_checkin',
  promote = 'promote',
  demote = 'demote',
  switch_to_online = 'switch_to_online',
  switch_to_local = 'switch_to_local',
  switch_to_online_waitlist = 'switch_to_online_waitlist',
  switch_to_local_waitlist = 'switch_to_local_waitlist',
  drop = 'drop',
}

export const ENROLLMENT_ACTIONS_LABELS = {
  [ENROLLMENT_ACTIONS.create]: 'Enroll',
  [ENROLLMENT_ACTIONS.checkin]: 'Force check-in',
  [ENROLLMENT_ACTIONS.undo_checkin]: 'Undo check-in',
  [ENROLLMENT_ACTIONS.promote]: 'Promote from waitlist',
  [ENROLLMENT_ACTIONS.demote]: 'Demote to waitlist',
  [ENROLLMENT_ACTIONS.switch_to_online]: 'Switch and promote to online',
  [ENROLLMENT_ACTIONS.switch_to_local]: 'Switch and promote to in-person',
  [ENROLLMENT_ACTIONS.switch_to_online_waitlist]: 'Switch to online waitlist',
  [ENROLLMENT_ACTIONS.switch_to_local_waitlist]: 'Switch to in-person waitlist',
  [ENROLLMENT_ACTIONS.drop]: 'Drop',
};

export const ENROLLMENT_ACTIONS_ICONS = {
  [ENROLLMENT_ACTIONS.create]: AddIcon,
  [ENROLLMENT_ACTIONS.checkin]: CheckCircleRoundedIcon,
  [ENROLLMENT_ACTIONS.undo_checkin]: RemoveCircleOutlineIcon,
  [ENROLLMENT_ACTIONS.promote]: PersonAddAlt1Icon,
  [ENROLLMENT_ACTIONS.demote]: PersonRemoveAlt1Icon,
  [ENROLLMENT_ACTIONS.switch_to_online]: WifiOutlinedIcon,
  [ENROLLMENT_ACTIONS.switch_to_local]: LocationOnIcon,
  [ENROLLMENT_ACTIONS.switch_to_online_waitlist]: PersonRemoveAlt1Icon,
  [ENROLLMENT_ACTIONS.switch_to_local_waitlist]: PersonRemoveAlt1Icon,
  [ENROLLMENT_ACTIONS.drop]: RemoveCircleOutlineIcon,
};

export enum ENROLLMENT_REASONS {
  roster_bulk_enrollment_create = 'roster_bulk_enrollment_create',
  roster_bulk_enrollment_promote = 'roster_bulk_enrollment_promote',
  roster_bulk_enrollment_demote = 'roster_bulk_enrollment_demote',
  roster_bulk_enrollment_checkin = 'roster_bulk_enrollment_checkin',
  roster_bulk_enrollment_undo_checkin = 'roster_bulk_enrollment_undo_checkin',
  roster_bulk_enrollment_drop = 'roster_bulk_enrollment_drop',
}

export const ENROLLMENT_ROSTER_ACTIONS_REASONS = {
  [ENROLLMENT_ACTIONS.create]: ENROLLMENT_REASONS.roster_bulk_enrollment_create,
  [ENROLLMENT_ACTIONS.promote]: ENROLLMENT_REASONS.roster_bulk_enrollment_promote,
  [ENROLLMENT_ACTIONS.demote]: ENROLLMENT_REASONS.roster_bulk_enrollment_demote,
  [ENROLLMENT_ACTIONS.checkin]: ENROLLMENT_REASONS.roster_bulk_enrollment_checkin,
  [ENROLLMENT_ACTIONS.undo_checkin]: ENROLLMENT_REASONS.roster_bulk_enrollment_undo_checkin,
  [ENROLLMENT_ACTIONS.drop]: ENROLLMENT_REASONS.roster_bulk_enrollment_drop,
};

export enum ENROLLMENT_POLICIES {
  try_enroll_then_try_waitlist = 'try_enroll_then_try_waitlist',
  force_enroll = 'force_enroll',
  try_enroll_then_force_waitlist = 'try_enroll_then_force_waitlist',
}

export const ENROLLMENT_POLICIES_LABELS = {
  [ENROLLMENT_POLICIES.try_enroll_then_try_waitlist]: 'Attempt to enroll then attempt to waitlist',
  [ENROLLMENT_POLICIES.force_enroll]: 'Force enroll (auto-increase enrollment capacity)',
  [ENROLLMENT_POLICIES.try_enroll_then_force_waitlist]:
    'Attempt to enroll then force waitlist (auto-enable/increase waitlist capacity)',
};

export enum ENROLLMENT_PERMISSIONS {
  access = 'access',
  manage = 'manage',
  switch_to_local_enrollment = 'switch_to_local_enrollment',
  switch_to_local_waitlist = 'switch_to_local_waitlist',
  switch_to_online_enrollment = 'switch_to_online_enrollment',
  switch_to_online_waitlist = 'switch_to_online_waitlist',
  drop = 'drop',
  checkin = 'checkin',
  undo_checkin = 'undo_checkin',
  rate = 'rate',
}
