import {
  TextField,
  Autocomplete,
  ListItem,
  ListItemButton,
  Typography,
  menuItemClasses,
} from '@mui/material';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled, darken } from '@mui/material/styles';
import { useEffect, useState, ElementType } from 'react';
import { Field } from 'redux-form';

import { FormFieldGroup } from 'shared/components/Form';
import InfoText from 'shared/components/InfoText';
import { find } from 'vendor/lodash';

const COLORS = [
  { label: 'Indigo', value: '#E8EAF6' },
  { label: 'Green', value: '#F1F8E9' },
  { label: 'Grey', value: '#FAFAFA' },
  { label: 'Red', value: '#FEEBEE' },
  { label: 'Yellow', value: '#FFF3E0' },
];

const ChannelListItem = styled(ListItem)`
  padding: 0px !important;
  border-radius: 6px;
  background-color: transparent !important;
`;

interface ChannelListOptionProps extends ListItemButtonProps {
  to?: string;
  component?: ElementType;
  backgroundColor?: string;
}

const ChannelListOption = styled(ListItemButton)<ChannelListOptionProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 54px;
  border-radius: 8px;
  margin: 5px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }

  &.${menuItemClasses.selected} {
    border: 1px solid;
    border-color: ${({ backgroundColor }) =>
      backgroundColor ? darken(backgroundColor, 0.2) : '#00000061'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }
`;

interface ColorPickerProps {
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta?: any;
}

const ColorPicker = (props: ColorPickerProps) => {
  const {
    input: { value, onChange },
    ...rest
  } = props;

  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  useEffect(() => {
    const colorExists = find(COLORS, (color) => color.value === value);
    setSelectedColor(colorExists ? value : null);
  }, [value]);

  const handleChangeColor = (_event, value) => {
    const currentValue = value ? value.value : null;

    onChange(currentValue);
    setSelectedColor(currentValue);
  };

  return (
    <Autocomplete
      size="small"
      id="color-select"
      options={COLORS}
      onChange={handleChangeColor}
      getOptionLabel={(option) => option.label}
      value={find(COLORS, (color) => color.value === value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Color"
          sx={{
            backgroundColor: selectedColor,
            borderRadius: 1,
          }}
        />
      )}
      renderOption={(props, option) => (
        <ChannelListItem {...props} key={option.value}>
          <ChannelListOption backgroundColor={option?.value}>
            <Typography variant="body1" component="span">
              {option.label}
            </Typography>
          </ChannelListOption>
        </ChannelListItem>
      )}
      {...rest}
    />
  );
};

const ChannelColorSelectField = () => {
  return (
    <FormFieldGroup>
      <span data-testid="channel-field">
        <Field label="Color" name="color" multiple={false} component={ColorPicker} />
      </span>
      <InfoText
        top={4}
        bottom={4}
        content="Select a color for your Channel. Default will use your platform's main primary color."
      />
    </FormFieldGroup>
  );
};

export default ChannelColorSelectField;
