import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import actions from 'entities/actions';
import { sessionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SessionBulkCopyModal from 'program-shared/components/SessionBulkDuplicateModal';
import SessionForm from 'program/components/SessionForm';
import { onSessionFormSubmitSuccess } from 'program/components/SessionForm/SessionFormHelpers';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { STATUS_DONE } from 'shared/constants';
import { useCurrentUser, useLabels, useOriginRoute } from 'shared/hooks';
import { get, isEmpty } from 'vendor/lodash';

const SessionMentorshipEdit = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { public_id: sessionPublicId } = useParams();
  const { label_mentorship_tab: labelMentorship } = useLabels();

  const [initialValues, setInitialValues] = useState({});
  const [sessionToBulkDuplicate, setSessionToBulkDuplicate] = useState({});

  const [fetchSession, { status: fetchSessionStatus }] = useEntities(
    actions.sessionMentorship.retrieveDetails,
    ({ status, data, statusCode }) => {
      if (statusCode === 403) {
        permissionDeniedRedirectAlert();
      }

      if (status === STATUS_DONE) {
        setInitialValues({ ...data, attendee_id: get(data, 'attendees_ids.0', null) });
      }
    },
    {
      schema: sessionSchema,
    }
  );

  useEffect(() => {
    fetchSession(sessionPublicId);
  }, []);

  const originRoute = useOriginRoute();
  const mentorsRoute = `${mapRoute('mentorList')}?o=next_session_date`;
  const backRoute = originRoute || mentorsRoute;

  if (fetchSessionStatus !== STATUS_DONE) return <Loading />;

  return (
    <>
      <PageTitle title="Edit Session" />
      <SessionForm
        isMentorshipSession
        isEdit
        form="newSessionMentorshipEditForm"
        contentName={`${labelMentorship} Session`}
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName="Editing Session"
        backRoute={backRoute}
        breadcrumbsItemList={[
          {
            label: labelMentorship,
            link: mentorsRoute,
          },
          {
            label: 'Edit session',
          },
        ]}
        onSubmitSuccessHandler={(result, saveAndDuplicate, saveAndBulkDuplicate) => {
          const { public_id: publicId } = result;
          const saveAndDuplicateRoute = `${mapRoute(
            'sessionMentorshipCreate'
          )}?cloned=${publicId}&from_previous=true`;

          onSessionFormSubmitSuccess(
            result,
            saveAndDuplicate,
            saveAndBulkDuplicate,
            setSessionToBulkDuplicate,
            history,
            true,
            backRoute,
            saveAndDuplicateRoute
          );
        }}
      />
      {!isEmpty(sessionToBulkDuplicate) && (
        <SessionBulkCopyModal
          session={sessionToBulkDuplicate}
          handleClose={() => {
            setSessionToBulkDuplicate({});
            history.push(
              mapRoute('sessionMentorshipEdit', { public_id: sessionToBulkDuplicate.public_id })
            );
          }}
          handleConfirm={() => {
            setSessionToBulkDuplicate({});
            history.push(
              originRoute || mapRoute('userProfile', { id: sessionToBulkDuplicate.host_id })
            );
          }}
        />
      )}
    </>
  );
};

export default SessionMentorshipEdit;
