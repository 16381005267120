import styled from 'styled-components';

import colors from 'services/colors';

const BackgroundPanel = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || '280px'};
  background-color: ${colors.emphasis600};
  color: ${colors.emphasis600TextColor};
  position: absolute;
  top: 0px;
  z-index: -1;
`;

export default BackgroundPanel;
