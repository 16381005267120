/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { RenderPropSticky } from 'react-sticky-el';

import Breadcrumbs from 'app/shared/components/Breadcrumbs';
import { useTrackSidebarCtx } from 'features/tracks/contexts/TrackSidebarCtx';
import { useTrackContentBreadcrumbs } from 'features/tracks/hooks/useTrackContentBreadcrumbs';
import colors from 'services/colors';
import { useContentSidebarContext } from 'shared-layouts/ContentTopbarPage';

type TopBarContainerProps = {
  isSidebarOnMobileOpen?: boolean;
};

const TopBarContainer = styled(Box)<TopBarContainerProps>`
  height: 52px;
  background-color: ${colors.neutral0};
  position: relative;
  border-bottom: 1px solid #0000001f;
  display: flex;
  align-items: center;

  ${({ isSidebarOnMobileOpen }) =>
    isSidebarOnMobileOpen &&
    `
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  `}
`;

type TopBarProps = {
  children: React.ReactNode;
};

function TopBar(props: TopBarProps) {
  const { children } = props;

  const [isSidebarOpen] = useContentSidebarContext();
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobileOrTablet && isSidebarOpen) {
    return <TopBarContainer isSidebarOnMobileOpen>{children}</TopBarContainer>;
  }

  return (
    <RenderPropSticky>
      {({ wrapperStyles, wrapperRef, holderStyles, holderRef }) => {
        return (
          <div ref={holderRef} style={{ ...holderStyles, ...{ zIndex: 2, position: 'relative' } }}>
            <div style={wrapperStyles} ref={wrapperRef}>
              <TopBarContainer>{children}</TopBarContainer>
            </div>
          </div>
        );
      }}
    </RenderPropSticky>
  );
}

function TrackConsumptionTopBar() {
  const breadcrumbs = useTrackContentBreadcrumbs();
  const { isSidebarOpen, onToggleSidebar } = useTrackSidebarCtx();

  return (
    <TopBar>
      <Box display="flex" alignItems="center" gap={1} flex={1} padding="8px 16px">
        <IconButton onClick={onToggleSidebar}>
          {isSidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>

        <Box display="flex" alignItems="center" gap={2}>
          <Breadcrumbs
            breadcrumbsProps={{ maxItems: 5, 'aria-label': 'Track consumption breadcrumb' }}
            items={breadcrumbs}
          />
        </Box>
      </Box>
    </TopBar>
  );
}

export default TrackConsumptionTopBar;
