import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Clicker from 'shared/components/Clicker';
import Text from 'shared/components/Text';

const getAccordionRadioLineColor = ({ isOpen, disabled, hasError, isNewForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (hasError) {
    return colors.error600;
  }

  if (isOpen && isNewForm) {
    return colors.action600;
  }

  if (isOpen) {
    return colors.emphasis600;
  }

  return 'transparent';
};

const getAccordionRadioBGColor = ({ isOpen, disabled, hasError, isNewForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (isOpen && hasError) {
    return colors.error600;
  }

  if (isOpen && isNewForm) {
    return colors.action600;
  }

  if (isOpen) {
    return colors.emphasis600;
  }

  return 'transparent';
};

const getAccordionRadioFocusColor = ({ isOpen, disabled, hasError, isNewForm }) => {
  if (disabled && isOpen) {
    return colors.neutral500;
  }

  if (disabled && !isOpen) {
    return 'transparent';
  }

  if (hasError) {
    return colors.error600;
  }

  if (isNewForm) {
    return colors.action600;
  }

  return colors.emphasis600;
};

const getAccordionFontColor = ({ disabled, isOpen, isNewForm }) => {
  if (isOpen && isNewForm) {
    return colors.action600TextColor;
  }

  if (isOpen) {
    return colors.neutral0;
  }

  if (disabled) {
    return colors.neutral400;
  }

  return colors.neutral900;
};

const AccordionRadioWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => getAccordionRadioLineColor(props)};

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid ${(props) => getAccordionRadioFocusColor(props)};
  }
`;

AccordionRadioWrapper.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewForm: PropTypes.bool,
};

const AccordionRadioHeader = styled(Clicker)`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 32px;
  padding: 6px 15px;
  background-color: ${(props) => getAccordionRadioBGColor(props)};
  color: ${(props) => getAccordionFontColor(props)};

  &:hover,
  &:focus {
    ${({ disabled }) => !disabled && 'opacity: 0.9;'}
  }
`;

AccordionRadioHeader.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewForm: PropTypes.bool,
};

const AccordionRadioContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  ${({ show }) => (show ? 'display: block;' : 'display: none;')}
`;

const getRadioColor = ({ checked, disabled, hasError, isNewForm }) => {
  if (hasError) {
    return colors.error600;
  }

  if (checked && disabled) {
    return colors.neutral500;
  }

  if (checked && isNewForm) {
    return colors.action600;
  }

  if (checked) {
    return colors.emphasis600;
  }

  return colors.neutral200;
};

const Radio = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: ${({ checked }) => (checked ? '2px solid white' : '1px solid gray')};
  justify-content: center;
  background-color: ${(props) => (props.checked ? getRadioColor(props) : 'white')};

  &::after {
    content: '';
    display: ${({ checked }) => (checked ? 'inline-block' : 'none')};
    position: relative;
    background-color: white;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-top: 2px;
  }
`;

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewForm: PropTypes.bool,
};

const AccordionRadioLabel = styled.label`
  padding: 0;
  margin: 0;
  margin-left: 6px;
  font-weight: normal;

  &:hover {
    cursor: inherit;
  }
`;

AccordionRadioLabel.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

// FIXME: this should use a real input element, see RadioInput.js
const AccordionRadioInput = ({ label, value, disabled, hasError, children, input, isNewForm }) => {
  const { value: inputValue, onChange } = input;
  const handleClick = () => {
    if (!disabled) {
      onChange(value);
    }
  };

  return (
    <AccordionRadioWrapper
      disabled={disabled}
      isOpen={value === inputValue}
      hasError={hasError}
      isNewForm={isNewForm}
    >
      <AccordionRadioHeader
        disabled={disabled}
        isOpen={value === inputValue}
        hasError={hasError}
        onClick={handleClick}
        tabIndex={disabled ? '-1' : null}
        isNewForm={isNewForm}
      >
        <Radio
          checked={value === inputValue}
          disabled={disabled}
          hasError={hasError}
          isNewForm={isNewForm}
        />
        <AccordionRadioLabel isOpen={value === inputValue} disabled={disabled}>
          <Text>{label}</Text>
        </AccordionRadioLabel>
      </AccordionRadioHeader>
      <AccordionRadioContent show={value === inputValue && children}>
        {' '}
        {children}{' '}
      </AccordionRadioContent>
    </AccordionRadioWrapper>
  );
};

AccordionRadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  input: PropTypes.object,
  isNewForm: PropTypes.bool,
};

AccordionRadioInput.defaultProps = {
  value: '',
  hasError: false,
  disabled: false,
  isNewForm: false,
};

export default AccordionRadioInput;
