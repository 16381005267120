import PropTypes from 'prop-types';
import React from 'react';

import Autocomplete from 'inputs/components/Autocomplete';

const AutocompleteField = ({ input: { name, value, onChange }, meta, ...props }) => {
  return (
    <Autocomplete name={name} value={value} onChange={onChange} error={meta?.error} {...props} />
  );
};

AutocompleteField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default AutocompleteField;
