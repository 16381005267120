import { Box, Avatar, Chip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { LEARNING_TYPE_FACILITATOR_MAIN_ROLE } from 'app/catalog/constants';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import UserProfileInitials from 'shared/components/UserAvatar/UserProfileInitials';
import { filter, get, map, size, take } from 'vendor/lodash';

import { useContentItemCardCtx } from './ContentItemCardContext';

const PeopleChip = styled(Chip)<
  ChipProps & { component: ElementType; to: string; disableRipple?: boolean }
>(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: colors.neutral600,
  fontSize: '0.75rem',
  paddingRight: '5px',
  minWidth: 0,

  ['&:hover, &:focus, &:active, &:visited']: {
    color: `${colors.neutral900} !important`,
  },

  ['& .MuiChip-avatar']: {
    width: '24px',
    height: '24px',
    marginLeft: '0px',
    marginRight: '0px',
  },

  ['& .MuiChip-label']: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
}));

const MoreOwnersChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: colors.neutral600,
  fontSize: '0.75rem',
}));

type PeoplePillProps = {
  image: string;
  name: string;
  route: string;
};

export function PeoplePill(props: PeoplePillProps) {
  const { image, name, route } = props;

  return (
    <PeopleChip
      avatar={
        <Avatar src={image}>
          <UserProfileInitials userName={name} />
        </Avatar>
      }
      label={name}
      size="small"
      component={RouterLink}
      to={route}
      clickable
      disableRipple
    />
  );
}

function getOwners(facilitators: any[], learningType: string) {
  const mainRoleForLearningType = LEARNING_TYPE_FACILITATOR_MAIN_ROLE[learningType];

  const mainOwners = mainRoleForLearningType
    ? filter(facilitators, ['role', mainRoleForLearningType])
    : facilitators;

  const coMainOwners = mainRoleForLearningType
    ? filter(facilitators, ['role', `co_${mainRoleForLearningType}`])
    : [];

  return map([...mainOwners, ...coMainOwners], 'user');
}

const MAX_OWNERS_DISPLAY = 2;

export function ContentItemCardOwners() {
  const { contentItem, learningType } = useContentItemCardCtx();

  const facilitators = get(contentItem, 'facilitators', []);
  const owners = getOwners(facilitators, learningType);

  const ownersCount = size(owners);
  const ownersToDisplay = take(owners, MAX_OWNERS_DISPLAY);
  const hasMoreOwners = ownersCount > MAX_OWNERS_DISPLAY;
  const hasMoreCount = hasMoreOwners ? ownersCount - MAX_OWNERS_DISPLAY : 0;

  if (ownersCount === 0) {
    return null;
  }

  return (
    <Box display="flex" gap="8px">
      <Box display="flex" gap="8px" minWidth={0}>
        {map(ownersToDisplay, (owner: any) => (
          <PeoplePill
            key={owner.id}
            image={owner.profile_image}
            name={owner.display_name}
            route={mapRoute('userProfile', { id: owner.id })}
          />
        ))}
      </Box>

      {hasMoreCount ? <MoreOwnersChip size="small" label={`+${hasMoreCount}`} /> : null}
    </Box>
  );
}
