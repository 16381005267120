import React from 'react';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import colors from 'services/colors';
import { Assignment } from 'shared-content-item/interfaces';
import { isBoolean, isNil } from 'vendor/lodash';
import { amber, green, grey, lightBlue, orange } from 'vendor/mui';

type FeedbackState = 'none' | 'indeterminate' | 'correct' | 'incorrect' | 'overdue';
type GetFeedbackStateParams = {
  assignment?: Assignment;
  showAssignmentState?: boolean;
};

const getFeedbackState = (params: GetFeedbackStateParams): FeedbackState => {
  const { assignment, showAssignmentState } = params;

  const isCompleted = assignment?.state === ASSIGNMENT_STATES.completed;
  const isSuccess = isBoolean(assignment?.is_success) ? assignment?.is_success : null;
  const isOverdue = assignment?.is_overdue && !isCompleted;

  if (!isNil(isSuccess)) {
    if (showAssignmentState) {
      return isSuccess ? 'correct' : 'incorrect';
    }

    return 'indeterminate';
  }

  if (isCompleted) {
    return 'correct';
  }

  if (isOverdue) {
    return 'overdue';
  }

  return 'none';
};

export type TrackItemCardStyle = {
  backgroundColor?: string;
  borderColor: string;
};

export type UseTrackItemCardStyleParams = {
  assignment?: Assignment;
  isSelected?: boolean;
  showAssignmentState?: boolean;
};

export const useTrackItemCardStyle = (params: UseTrackItemCardStyleParams): TrackItemCardStyle => {
  const { assignment, isSelected, showAssignmentState } = params;

  const feedbackState = getFeedbackState({ assignment, showAssignmentState });

  const bgColor = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return isSelected ? green[100] : green[50];
    }

    if (feedbackState === 'incorrect') {
      return isSelected ? amber[100] : orange[50];
    }

    if (feedbackState === 'indeterminate') {
      return isSelected ? lightBlue[100] : lightBlue[50];
    }

    return colors.neutral0;
  }, [feedbackState, isSelected]);

  const borderColor = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return isSelected ? colors.success700 : green[100];
    }

    if (feedbackState === 'incorrect') {
      return isSelected ? amber[800] : amber[200];
    }

    if (feedbackState === 'indeterminate') {
      return isSelected ? lightBlue[700] : lightBlue[200];
    }

    if (feedbackState === 'overdue') {
      return isSelected ? colors.alert400 : colors.alert200;
    }

    return isSelected ? '#000000' : grey[300];
  }, [feedbackState, isSelected]);

  return {
    backgroundColor: bgColor,
    borderColor: borderColor,
  };
};
