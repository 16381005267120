import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { userSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import AddPeopleModal from 'groups/components/AddPeopleModal';
import SearchInput from 'inputs/components/SearchInput';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { isEmpty, join, uniq, map, reject } from 'vendor/lodash';

import GroupUserListField from './GroupUserListField';

const UserListContainer = styled.div`
  margin-top: 24px;
`;

const UserListHeader = styled.div`
  display: flex;
`;

const AddPeopleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: 16px;
    flex-basis: 32%;
  }
`;

const SimpleInfo = styled.div`
  color: ${colors.neutral500};
  font-size: 14px;
  margin-bottom: 8px;
`;

const GroupOwnerListFields = ({
  new_owners: ownersInput,
  remove_owners: removeOwnersInput,
  currentOwnersList,
  fetchNextPage,
  isLoadingMore,
  loadMoreLink,
  searchText,
  handleOnSearchTextChange,
}) => {
  const [showAddOwnerModal, setShowAddOwnerModal] = useState(false);
  const [newOwnersList, setNewOwnersList] = useState([]);

  const [fetchOwners, { status: fetchOwnersStatus }] = useEntities(
    actions.userData.retrieveList,
    ({ data, status }) => {
      if (status === STATUS_DONE) {
        setNewOwnersList(data);
      }
    },
    {
      schema: [userSchema],
    }
  );

  const handleAddOwners = (ownersIds, newOwners) => {
    const newOwnersIds = uniq(
      !isEmpty(newOwners)
        ? [...ownersIds, ...map(newOwners, (owner) => owner.id.toString())]
        : ownersIds
    );

    ownersInput.input.onChange(newOwnersIds);

    if (!isEmpty(newOwnersIds)) {
      fetchOwners({ id__in: join(newOwnersIds, ',') });
    }

    setShowAddOwnerModal(false);
  };

  const handleUndoAddOwners = (ownerId) => {
    setNewOwnersList(reject(newOwnersList, (owner) => owner.id === ownerId));
  };

  return (
    <>
      <UserListHeader>
        <AddPeopleWrapper>
          <SearchInput
            placeholder="Search group owners"
            value={searchText}
            onChange={handleOnSearchTextChange}
          />
          <Button
            startIcon={<Icon name="plus" />}
            size="small"
            onClick={() => setShowAddOwnerModal(true)}
            type="button"
          >
            Add Owners
          </Button>
        </AddPeopleWrapper>
      </UserListHeader>

      <UserListContainer>
        {showAddOwnerModal && (
          <AddPeopleModal
            handleClose={() => setShowAddOwnerModal(false)}
            handleAddPeople={handleAddOwners}
            initialValues={{ people_ids: ownersInput.input.value }}
          />
        )}
        {fetchOwnersStatus === STATUS_LOADING && <Loading />}
        {!isEmpty(newOwnersList) && (
          <GroupUserListField
            users={newOwnersList}
            handleUndoAddPeople={handleUndoAddOwners}
            fetchNextPage={fetchNextPage}
            isLoadingMore={isLoadingMore}
            loadMoreLink={loadMoreLink}
            additionEnabled
          />
        )}
        {isEmpty(newOwnersList) &&
          isEmpty(currentOwnersList) &&
          'You will be the owner of the group.'}
      </UserListContainer>
      {Boolean(removeOwnersInput) && (
        <UserListContainer>
          <SimpleInfo>CURRENT OWNERS</SimpleInfo>
          <SimpleInfo>Changes will be applied upon saving.</SimpleInfo>
          <GroupUserListField
            users={currentOwnersList}
            removalInput={removeOwnersInput.input}
            removalEnabled
          />
        </UserListContainer>
      )}
    </>
  );
};

GroupOwnerListFields.propTypes = {
  new_owners: PropTypes.object,
  remove_owners: PropTypes.object,
  currentOwnersList: PropTypes.array,
  fetchNextPage: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  loadMoreLink: PropTypes.string,
  searchText: PropTypes.string,
  handleOnSearchTextChange: PropTypes.func,
};

export default GroupOwnerListFields;
