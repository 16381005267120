import pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';
import { matchPath } from 'react-router';

import { ApiURLs, fetchURL } from 'services/requests-base';
import { get, includes, isString, map, omit } from 'vendor/lodash';

import { addTrailingSlashToUrl } from './utils';

function callFetchUrlWithBody(method, urlName, urlParams, body) {
  let url = ApiURLs[urlName]();
  if (urlParams) {
    url = ApiURLs[urlName](urlParams);
  }
  const options = {
    method,
    body: JSON.stringify(body),
  };

  return fetchURL(url, options);
}

export function apiDelete(urlName, urlParams, body) {
  return callFetchUrlWithBody('DELETE', urlName, urlParams, body);
}

export function apiPost(urlName, urlParams, body) {
  return callFetchUrlWithBody('POST', urlName, urlParams, body);
}

export function apiPatch(urlName, urlParams, body) {
  return callFetchUrlWithBody('PATCH', urlName, urlParams, body);
}

export function apiPut(urlName, urlParams, body) {
  return callFetchUrlWithBody('PUT', urlName, urlParams, body);
}

export function apiGet(urlName, urlParams, filters) {
  let baseUrl = ApiURLs[urlName]();
  if (urlParams) {
    baseUrl = ApiURLs[urlName](urlParams);
  }
  const formattedQueryString = queryString.stringify(omit(filters, ['id']));
  const url = `${baseUrl}?${formattedQueryString}`;

  return fetchURL(url);
}

// Do NOT use this to interpolate query strings.
//  i.e. don't do '/a/tracks/?parent=:id'
export const internalURLs = {
  home: '/',
  calendarAuthRedirect: '/auth/calendar-auth-redirect/',

  trackAssignmentsExport: '/endpoints/tracks/:public_id/export/',
  scheduledTrackAssignmentsExport: '/endpoints/scheduled-tracks/:public_id/export/',
  eventTypeAssignmentsExport: '/endpoints/event-types/:public_id/export/',
  assignmentsExport: '/endpoints/content_items/:public_id/export/',

  trackCreate: '/a/tracks/create/',
  trackDetails: '/a/tracks/:public_id_and_slug/',
  trackEdit: '/a/tracks/:public_id/edit/',

  scheduledTrackCreate: '/a/scheduled-tracks/create/',
  scheduledTrackDetails: '/a/scheduled-tracks/:public_id_and_slug/',
  scheduledTrackEdit: '/a/scheduled-tracks/:public_id/edit/',

  assessmentCreate: '/a/assessments/create/',
  assessmentDetails: '/a/assessments/:public_id_and_slug/',
  assessmentEdit: '/a/assessments/:public_id/edit/',

  linkedContentCreate: '/a/linkedcontent/create/',
  linkedContentDetails: '/a/linkedcontent/:public_id_and_slug/',
  linkedContentEdit: '/a/linkedcontent/:public_id/edit/',

  questionCreate: '/a/questions/create/',
  questionEdit: '/a/questions/:public_id/edit/',

  eventsList: '/events/',
  eventDetails: '/events/:public_id_and_slug/',
  eventNew: '/a/events/new/',
  // FIXME remove querystring interpolation
  eventNewFromTemplate: '/a/events/new/?template=:public_id',
  eventNewFromClone: '/a/events/new/?cloned=:public_id',
  eventEdit: '/a/events/:public_id_and_slug/edit/',
  eventDelete: '/events/:public_id/delete/',
  eventManageAttendance: '/a/events/:public_id_and_slug/attendance/',

  groupDetails: '/a/groups/:id/',
  groupCreate: '/a/groups/create/',
  groupEdit: '/a/groups/:id/edit/',
  groupDelete: '/a/groups/:id/delete/',

  feedback: '/feedback/',

  userProfile: '/users/:id/',
  userEngagement: '/users/:id/engagement',
  userImpact: '/users/:id/impact',
  userEdit: '/a/users/:id/edit/',
  userDelete: '/users/:id/delete/',

  logout: '/logout/',

  programCreate: '/a/programs/create/',
  programEdit: '/a/programs/:public_id_and_slug/edit/',
  programDetails: '/a/programs/:public_id_and_slug/',

  mentorshipProgramCreate: '/a/mentorship_programs/create/',
  mentorshipProgramEdit: '/a/mentorship_programs/:public_id_and_slug/edit/',
  mentorshipProgramDetails: '/a/mentorship_programs/:public_id_and_slug/',
  mentorshipProgramManageMentors: '/a/mentorship_programs/:public_id_and_slug/mentors',
  mentorshipProgramManageMentees: '/a/mentorship_programs/:public_id_and_slug/mentees',
  mentorshipSessionCreate: '/a/mentorship_programs/:public_id_and_slug/sessions/create',
  mentorshipSessionsEdit: '/a/mentorship_programs/sessions/:public_id/edit',

  articleCreate: '/a/articles/create/',
  articleEdit: '/a/articles/:public_id/edit/',

  taskCreate: '/a/tasks/create/',
  taskEdit: '/a/tasks/:public_id/edit/',

  codelabImport: '/a/codelabs/import/',
  codelabEdit: '/a/codelabs/:public_id_and_slug/edit/',
  codelabVersionDetails: '/endpoints/codelabs/:public_id/versions/:version/',

  courseImport: '/a/courses/import/',
  courseEdit: '/a/courses/:public_id_and_slug/edit/',
  courseRegistrationsExport: '/endpoints/courses/:public_id/export/',

  videoCreate: '/a/videos/create/',
  videoEdit: '/a/videos/:public_id/edit/',

  contentItemManageAssignments: '/a/contents/:public_id_and_slug/assignments/',

  sessionFilter: '/a/sessions/',
  sessionCreate: '/a/sessions/create/',
  sessionEdit: '/a/sessions/:public_id/edit/',

  sessionMentorshipCreate: '/a/sessions/mentorship/create/',
  sessionMentorshipEdit: '/a/sessions/mentorship/:public_id/edit/',

  myEvents: '/a/my-events/',
  mySessions: '/a/my-sessions/',
  myMentorshipRequestsReceived: '/a/my-mentorship-requests/received/',
  myMentorshipRequestsSent: '/a/my-mentorship-requests/sent/',
  myLearningContent: '/a/my-learning-content/',
  myGroups: '/a/my-groups/',
  checkin: '/checkin/',

  mentorList: '/a/mentors/',
  menteeList: '/a/mentees/',
  peopleList: '/a/connections/',

  myTeam: '/a/my-team/',
  teamAssignments: '/a/team-assignments/',

  backofficeEvents: '/backoffice/events',
  backofficeEnrollments: '/a/backoffice/enrollments/',

  channelCreate: '/channels/create/',
  channelDetail: '/channels/:slug/',
  channelEdit: '/channels/:slug/edit/',

  // Make sure the URLs below match the paths in in DASHBOARD_TYPES_REVERSE_URL_MAPPING
  dashboard: '/a/dashboard/analytics/',
  dashboardPeople: '/a/dashboard/people/:public_id?/',
  dashboardBulkUserLoads: '/a/dashboard/bulk_user_loads/:public_id?/',
  dashboardUserLoads: '/a/dashboard/bulk_user_loads/user_loads/',
  dashboardEvents: '/a/dashboard/events/:public_id?/',
  dashboardEventTypes: '/a/dashboard/event-types/:public_id?/',
  dashboardEnrollments: '/a/dashboard/enrollments/:public_id?/',
  dashboardSurveyInstances: '/a/dashboard/surveys/:id/instances',
  dashboardSurveyResponses: '/a/dashboard/surveys/:id/responses',
  dashboardSurveys: '/a/dashboard/surveys/:public_id?/',
  dashboardLocations: '/a/dashboard/locations/:public_id?/',
  dashboardGroups: '/a/dashboard/groups/:public_id?/',
  dashboardAnalytics: '/a/dashboard/analytics/',
  dashboardMentorships: '/a/dashboard/mentorships/:public_id?/',
  dashboardAssignments: '/a/dashboard/assignments/:public_id?/',
  dashboardPrograms: '/a/dashboard/programs/:public_id?/',
  dashboardProgramSessionEnrollments: '/a/dashboard/program-session-enrollments/:public_id?/',
  dashboardAutomatedRules: '/a/dashboard/automated-rules/',
  dashboardAutomatedRuleDetail: '/a/dashboard/automated-rules/:public_id/',
  dashboardScheduledTracks: '/a/dashboard/scheduled-tracks/:public_id?/',
  dashboardContentItems: '/a/dashboard/content-items/:public_id?/',
  dashboardAssessments: '/a/dashboard/assessments/:public_id?/',
  dashboardAssessmentAssignments: '/a/dashboard/assessments/:id?/assignments/',
  dashboardLogs: '/a/dashboard/logs/:public_id?/',
  dashboardBadges: '/a/dashboard/badges/',

  dashboardSegment: '/a/dashboard/:content/:public_id?/',

  eventReportSegment: '/a/dashboard/event/report/:public_id?/',
  assignmentReportSegment: '/a/dashboard/assignment/report/:public_id/',
  peopleReportSegment: '/a/dashboard/people/report/:public_id?/',
  eventEnrollmentReportSegment: '/a/dashboard/enrollment/report/:public_id?/',

  dashboardActiveUsers: '/a/dashboard/active-users/',

  charts: '/a/charts/content-item',
  chartsMentorship: '/a/charts/mentorship',
  chartsContentItem: '/a/charts/content-item',
  chartsEvent: '/a/charts/event',
  chartsProgram: '/a/charts/program',
  chartsUserEngagement: '/a/charts/user-engagement',

  chartsContentItemTypeCountByTimeInterval:
    '/a/charts-content-item/content-item-type-count-by-time-interval',
  chartsContentItemCountByTimeInterval:
    '/a/charts-content-item/content-item-count-by-time-interval',
  chartsContentItemCountByFacilitator: '/a/charts-content-item/content-item-count-by-facilitator',
  chartsContentItemCountByTag: '/a/charts-content-item/content-item-count-by-tag',
  chartsContentItemCountByCategory: '/a/charts-content-item/content-item-count-by-category',
  chartsContentItemCountByFlexibleFilter:
    '/a/charts-content-item/content-item-count-by-flexible-filter',
  chartsContentItemAssignmentCountByTimeInterval:
    '/a/charts-content-item/content-item-assignment-count-by-time-interval',
  chartsContentItemAssignmentStateCountByTimeInterval:
    '/a/charts-content-item/content-item-assignment-state-count-by-time-interval',
  chartsContentItemAssignmentRatingByTimeInterval:
    '/a/charts-content-item/content-item-assignment-rating-by-time-interval',
  chartsContentItemAssignmentRatingCountByTimeInterval:
    '/a/charts-content-item/content-item-assignment-rating-count-by-time-interval',
  chartsContentItemAssignmentCountByFacilitator:
    '/a/charts-content-item/content-item-assignment-count-by-facilitator',
  chartsContentItemAssignmentCountByTag:
    '/a/charts-content-item/content-item-assignment-count-by-tag',
  chartsMentorshipSessionEnrollmentCountHourByTimeInterval:
    '/a/charts-mentorship/session-enrollment-count-hour-by-time-interval',
  chartsMentorshipFeedbackByTimeInterval:
    '/a/charts-mentorship/session-mentee-rating-by-time-interval',
  chartsMentorshipEnrollmentCountHourByMentorTitle:
    '/a/charts-mentorship/session-enrollment-count-hour-by-mentor-title',
  chartsMentorshipEnrollmentCountHourByMentorDepartment:
    '/a/charts-mentorship/session-enrollment-count-hour-by-mentor-department',
  chartsMentorshipEnrollmentCountHourByMenteeTitle:
    '/a/charts-mentorship/session-enrollment-count-hour-by-mentee-title',
  chartsMentorshipEnrollmentCountHourByMenteeDepartment:
    '/a/charts-mentorship/session-enrollment-count-hour-by-mentee-department',
  chartsEventCountHourByTimeInterval: '/a/charts-events/count-hour-by-time-interval',
  chartsEventEnrollmentCountHoursByTimeInterval:
    '/a/charts-events/enrollment-count-hours-by-time-interval',
  chartsEventEnrollmentRatingsByTimeInterval:
    '/a/charts-events/enrollment-ratings-by-time-interval',
  chartsEventEnrollmentStatusByTimeInterval: '/a/charts-events/enrollment-status-by-time-interval',
  chartsEventEnrollmentCountHourByPresenterDepartment:
    '/a/charts-events/enrollment-count-hour-by-presenter-department',
  chartsEventEnrollmentCountHourByPresenterTitle:
    '/a/charts-events/enrollment-count-hour-by-presenter-title',
  chartsEventEnrollmentCountHourByEnrolleeDepartment:
    '/a/charts-events/enrollment-count-hour-by-enrollee-department',
  chartsEventEnrollmentCountHourByEnrolleeTitle:
    '/a/charts-events/enrollment-count-hour-by-enrollee-title',
  chartsEventCountHoursByAttribute: '/a/charts-events/count-hours-by-attribute',
  chartsProgramSessionEnrollmentCountHourByTimeInterval:
    '/a/charts-programs/session-enrollment-count-hour-by-time-interval',
  chartsProgramSessionAttendeeRatingsByTimeInterval:
    '/a/charts-programs/session-attendee-ratings-by-time-interval',
  chartsProgramSessionEnrollmentCountHourByHostTitle:
    '/a/charts-programs/session-enrollment-count-hour-by-host-title',
  chartsProgramSessionEnrollmentCountHourByHostDepartment:
    '/a/charts-programs/session-enrollment-count-hour-by-host-department',
  chartsProgramSessionEnrollmentCountHourByAttendeeTitle:
    '/a/charts-programs/session-enrollment-count-hour-by-attendee-title',
  chartsProgramSessionEnrollmentCountHourByAttendeeDepartment:
    '/a/charts-programs/session-enrollment-count-hour-by-attendee-department',
  chartsUserEngagementByTimeInterval: '/a/charts-engagement/user-engagement-by-time-interval',

  eventTypeDetails: '/event-types/:public_id_and_slug/',
  eventTypeEdit: '/a/event-types/:public_id_and_slug/edit/',
  eventTypeCreate: '/a/event-types/create/',
  eventTypeSettings: '/backoffice/event-type/:public_id_and_slug/settings/',
  eventTypeNotificationSettings:
    '/backoffice/event-type/:public_id_and_slug/notification-settings/',
  eventTypeDelete: '/backoffice/event-type/:id/delete/',

  locationEdit: '/backoffice/location/:id/edit/',
  locationCreate: '/backoffice/location/new/',
  locationDelete: '/backoffice/location/:id/delete/',

  alertsList: '/a/notifications/alerts/',

  surveyPreview: '/a/surveys/:id/preview/',
  surveyEdit: '/a/surveys/:id/edit/',
  surveyAnswer: '/a/surveys/:id/',
  surveyInsights: '/a/surveys/:id/insights/',
  surveyCreate: '/a/surveys/create/',

  unifiedCatalogList: '/a/content/',

  myHub: '/a/myhub/',

  standAloneArticleDetail: '/a/articles/:public_id_and_slug',
  standAloneCodelabDetail: '/a/codelabs/:public_id_and_slug',
  standAloneCourseDetail: '/a/courses/:public_id_and_slug',
  standAloneVideoDetail: '/a/videos/:public_id_and_slug',

  bulkImportEventType: '/a/event-types/bulk-import/',

  bulkImportDownloadTemplate: '/endpoints/csv-import/download-template/',

  settings: '/a/system-settings/:context/',
  applicationToggles: '/a/application-toggles/',

  automatedRuleCreate: '/a/automated-rules/create/',
  automatedRuleEdit: '/a/automated-rules/:public_id/edit/',
  myUpcoming: '/a/my-upcoming/',
  graphQl: '/graphql',
};

export function mapRoute(urlName, params = {}) {
  const toPath = pathToRegexp.compile(internalURLs[urlName]);
  return toPath(params);
}

export function safeMapRoute(urlName, params = {}) {
  try {
    return mapRoute(urlName, params);
  } catch (e) {
    console.error(`Failed to generate the path to endpoint "${urlName}": ${e}`);
    return mapRoute('unifiedCatalogList');
  }
}

function getAppUrlVerifier(appsUrlNames) {
  return (location) => {
    const locationWithTrailingSlash = addTrailingSlashToUrl(location);
    const parametizedUrls = map(appsUrlNames, (urlName) => {
      const match = matchPath(locationWithTrailingSlash, {
        path: internalURLs[urlName],
        exact: true,
        strict: false,
      });
      const params = get(match, 'params', {});
      try {
        return mapRoute(urlName, params);
        // eslint-disable-next-line no-catch-shadow
      } catch (TypeError) {
        return internalURLs[urlName];
      }
    });

    return includes(parametizedUrls, locationWithTrailingSlash);
  };
}

const EVENT_APP_URL_NAMES = [
  'eventsList',
  'eventDetails',
  'eventNew',
  'eventNewFromTemplate',
  'eventNewFromClone',
  'eventEdit',
  'eventDelete',
  'feedback',
  'myEvents',
];

const SNIPPETS_APP_URL_NAMES = [
  'snippetCreate',
  'snippetDetails',
  'snippetEdit',
  'trackCreate',
  'trackDetails',
  'trackEdit',
  'myLearningContent',
];

const CATALOG_APP_URL_NAMES = [
  'eventTypeDetails',
  'eventTypeEdit',
  'eventTypeCreate',
  'eventTypeSettings',
  'eventTypeNotificationSettings',
  'eventTypeDelete',
];

const COACHING_APP_URL_NAMES = [
  'programCreate',
  'programEdit',
  'programDetails',
  'sessionFilter',
  'sessionCreate',
  'sessionEdit',
];

const MENTORS_APP_URL_NAMES = [
  'mentorList',
  'menteeList',
  'mySessions',
  'sessionMentorshipCreate',
  'sessionMentorshipEdit',
];

const PEOPLE_APP_URL_NAMES = ['peopleList'];
const MY_UPCOMING_APP_URL_NAMES = ['myUpcoming'];

const STAND_ALONE_APP_URL_NAMES = ['standAloneArticleDetail', 'articleCreate', 'articleEdit'];

const UNIFIED_CATALOG_APP_URL_NAMES = [
  ...['unifiedCatalogList', 'unifiedCatalogListByTopic'],
  'trackCreate',
  'trackDetails',
  'trackEdit',
  'myLearningContent',
  'eventTypeDetails',
  'eventTypeEdit',
  'eventTypeCreate',
  'eventTypeSettings',
  'eventTypeNotificationSettings',
  'eventTypeDelete',
  'programCreate',
  'programEdit',
  'programDetails',
  'sessionFilter',
  'sessionCreate',
  'sessionEdit',
  ...STAND_ALONE_APP_URL_NAMES,
];

const MY_HUB_APP_URL_NAMES = ['myHub'];

export const isEventsAppUrl = getAppUrlVerifier(EVENT_APP_URL_NAMES);
export const isSnippetsAppUrl = getAppUrlVerifier(SNIPPETS_APP_URL_NAMES);
export const isCatalogAppUrl = getAppUrlVerifier(CATALOG_APP_URL_NAMES);
export const isCoachingAppUrl = getAppUrlVerifier(COACHING_APP_URL_NAMES);
export const isMentorsAppUrl = getAppUrlVerifier(MENTORS_APP_URL_NAMES);
export const isPeopleAppUrl = getAppUrlVerifier(PEOPLE_APP_URL_NAMES);
export const isUnifiedCatalogAppUrl = getAppUrlVerifier(UNIFIED_CATALOG_APP_URL_NAMES);
export const isStandAloneAppUrl = getAppUrlVerifier(STAND_ALONE_APP_URL_NAMES);
export const isMyUpcomingAppUrl = getAppUrlVerifier(MY_UPCOMING_APP_URL_NAMES);
export const isMyHubAppUrl = getAppUrlVerifier(MY_HUB_APP_URL_NAMES);

const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';
export function getErrorMessageFromResponse(response) {
  let errorMessage = get(response.data, 'detail', '');

  if (!errorMessage) {
    if (isString(response.data)) {
      errorMessage = response.data;
    } else {
      errorMessage = DEFAULT_ERROR_MESSAGE;
    }
  }

  return errorMessage;
}
