import PropTypes from 'prop-types';
import React from 'react';

class ModalManager extends React.Component {
  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentWillMount() {
    this.setState({
      show: false,
    });
  }

  show() {
    this.setState({ show: true });
  }

  hide() {
    this.setState({ show: false });
  }

  render() {
    const { children } = this.props;

    return React.cloneElement(children, { show: this.state.show, handleClose: this.hide });
  }
}

ModalManager.propTypes = {
  children: PropTypes.object,
};

export default ModalManager;
