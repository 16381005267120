import PropTypes from 'prop-types';
import React from 'react';

import ButtonLink from 'shared/components/ButtonLink';
import Rating from 'shared/components/Rating';
import UserAvatar from 'shared/components/UserAvatar';

import './style.scss';

export const FeedbackItem = (props) => {
  const { user, onEdit, feedbackText, feedbackRating } = props;

  return (
    <div className="feedback-item">
      <div className="user-image">
        <UserAvatar className="feedback-item-user-avatar" user={user} showPopover sendEmailCTA />
      </div>
      <div className="feedback-content">
        <div className="feedback-header">
          <span className="user-name">{user.name}</span>
          {onEdit && <ButtonLink onClick={onEdit}>Edit</ButtonLink>}
        </div>
        <p className="feedback-text">{feedbackText}</p>
        <div className="rating-section">
          <Rating value={feedbackRating} readOnly />
        </div>
      </div>
    </div>
  );
};

FeedbackItem.propTypes = {
  user: PropTypes.object,
  feedbackText: PropTypes.string,
  feedbackRating: PropTypes.number,
  onEdit: PropTypes.func,
};

export default FeedbackItem;
