import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import FeedbackItem from 'shared/components/FeedbackItem';
import { map, reject } from 'vendor/lodash';

import './style.scss';

export function FeedbackList(props) {
  const {
    feedbacks,
    currentUser,
    feedbackText,
    feedbackRating,
    onEdit,
    showAttendeeFeedbacks,
  } = props;

  let feedbacksToDisplay;
  if (currentUser && feedbacks) {
    feedbacksToDisplay = reject(feedbacks, { user: { id: currentUser.id } });
  } else {
    feedbacksToDisplay = feedbacks.slice();
  }

  return (
    <div>
      <div className="section-header">
        <span className="section-title">Feedback</span>
      </div>

      <div className="feedback-list">
        {feedbackRating > 0 && (
          <FeedbackItem
            user={currentUser}
            feedbackText={feedbackText}
            feedbackRating={feedbackRating}
            onEdit={onEdit}
          />
        )}
        {showAttendeeFeedbacks &&
          map(feedbacksToDisplay, (item, i) => (
            <FeedbackItem
              key={i}
              user={item.user}
              feedbackText={item.feedback_text}
              feedbackRating={item.feedback_rating}
            />
          ))}
      </div>
    </div>
  );
}

FeedbackList.propTypes = {
  currentUser: PropTypes.object,
  feedbacks: PropTypes.array,
  feedbackText: PropTypes.string,
  feedbackRating: PropTypes.number,
  showAttendeeFeedbacks: PropTypes.bool,
  onEdit: PropTypes.func,
};

FeedbackList.defaultProps = {
  feedbacks: [],
  feedbackRating: 0,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList);
