import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useCurrentUser, useOriginRoute, useToggles } from 'app/shared/hooks';
import actions from 'entities/actions';
import { userProfileSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import MentorshipRequestBanner from 'program/components/MentorshipRequestBanner';
import Page404 from 'scenes/404';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import BackgroundPanel from 'shared/components/BackgroundPanel';
import Loading from 'shared/components/Loading';
import PageTitle from 'shared/components/PageTitle';
import { STATUS_DONE } from 'shared/constants';
import { isEqual, startsWith } from 'vendor/lodash';
import { ChevronRightIcon } from 'vendor/mui-icons';
import {
  Tabs,
  LinkTab,
  Grid,
  Paper,
  Container,
  Box,
  Breadcrumbs,
  RouterLink,
} from 'vendor/mui.tsx';

import { Engagement } from './Engagement.tsx';
import { Profile } from './Profile.tsx';
import { ProfileDataContext } from './types';
import UserHeader from './UserHeader';

const CustomBackgroundPanel = styled(BackgroundPanel)`
  left: 0px;
  top: 60px;
`;

const Page = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const origin = useOriginRoute();
  const { id: currentUserId, role: currentUserRole } = useCurrentUser();

  const fetchProfile = () => {
    dispatch(actions.userData.retrieveDetails(`userProfile${id}`, id));
  };

  const { status: profileStatus, data: profileData } = useSelector((state) =>
    getDataFromState(`userProfile${id}`, state, userProfileSchema)
  );

  const {
    toggle_extend_manager_portal_profile_to_regular_users: toggleManagerPortalProfileToRegularUsers,
  } = useToggles();

  const userHasPermissionToSeeTabs =
    toggleManagerPortalProfileToRegularUsers ||
    currentUserRole === 'admin' ||
    currentUserRole === 'organizer' ||
    currentUserId === profileData.id ||
    currentUserId === profileData.manager_id;

  const showManagerPortalTabs = userHasPermissionToSeeTabs;

  const { trackActivity } = useMetrics();

  useEffect(() => {
    fetchProfile();
  }, [id]);

  useEffect(() => {
    if (profileStatus === STATUS_DONE) {
      trackActivity(METRICS_ACTIVITIES.USER_PROFILE_VIEW, {
        userProfileId: profileData.id,
      });
    }
  }, [profileStatus]);

  if (!isEqual(profileStatus, STATUS_DONE)) {
    return <Loading />;
  }

  return (
    <ProfileDataContext.Provider value={profileData}>
      <MentorshipRequestBanner profileId={profileData.id} />
      <PageTitle title="User Profile" />
      <CustomBackgroundPanel height="120px" />
      <Container>
        {startsWith(origin, '/a/my-team/') ? (
          <Breadcrumbs
            sx={{ marginTop: '44px', position: 'relative' }}
            separator={<ChevronRightIcon htmlColor={colors.neutral0} sx={{ opacity: 0.5 }} />}
          >
            <RouterLink color={colors.neutral0} to={mapRoute('myTeam')}>
              My team
            </RouterLink>
            <RouterLink color={colors.neutral0} to="#">
              {profileData.name}
            </RouterLink>
          </Breadcrumbs>
        ) : (
          <Box marginTop="68px" />
        )}
        <Box>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {showManagerPortalTabs && (
              <Grid item xs={4} sm={8} md={12} justifyContent="center" sx={{ display: 'flex' }}>
                <Paper elevation={2} sx={{ display: 'inline-flex', maxWidth: '100%' }}>
                  <Tabs
                    value={location.pathname}
                    aria-label="User Pages Tabs"
                    sx={{ width: 'auto' }}
                    centered
                  >
                    <LinkTab
                      label="Profile"
                      to={mapRoute('userProfile', { id })}
                      value={mapRoute('userProfile', { id })}
                    />
                    <LinkTab
                      label="Engagement"
                      to={mapRoute('userEngagement', { id })}
                      value={mapRoute('userEngagement', { id })}
                    />
                  </Tabs>
                </Paper>
              </Grid>
            )}
            <Grid item xs={4} sm={8} md={12}>
              <UserHeader profileData={profileData} />
            </Grid>
            <Switch>
              <Route
                exact
                path={decodeURIComponent(mapRoute('userProfile', { id: ':id' }))}
                render={() => <Profile />}
              />
              {showManagerPortalTabs && (
                <Route
                  exact
                  path={decodeURIComponent(mapRoute('userEngagement', { id: ':id' }))}
                  render={() => <Engagement />}
                />
              )}
              <Route>
                <Page404 />
                {decodeURIComponent(mapRoute('userImpact', { id: ':id' }))}
              </Route>
            </Switch>
          </Grid>
        </Box>
      </Container>
    </ProfileDataContext.Provider>
  );
};

export default Page;
