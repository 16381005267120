import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { PUBLISHED_STATUS } from 'catalog/constants';
import actions from 'entities/actions';
import { scheduledTrackSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import UpcomingSection from 'shared/components/UpcomingSection';
import { STATUS_DONE } from 'shared/constants';
import { size, findIndex } from 'vendor/lodash';

import ScheduledTrackCard from '../ScheduledTrackCard';

const ContentCardAdapter = ({ content }) => {
  return <ScheduledTrackCard scheduledTrack={content} />;
};

ContentCardAdapter.propTypes = {
  content: PropTypes.object,
};

const DescriptionContainer = styled.div`
  width: 100%;
  margin-top: 16px;

  ${({ showContainer }) =>
    showContainer &&
    `
      background-color: ${colors.neutral0};
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
  `}
`;

export const UpcomingScheduledTracks = ({ trackId, showContainer }) => {
  const [
    fetchScheduledTracks,
    { data: scheduledTracks, status: scheduledTracksStatus, count: scheduledTracksCount },
  ] = useEntities(actions.scheduledTrack.retrieveList, null, {
    schema: [scheduledTrackSchema]
  });

  useEffect(() => {
    fetchScheduledTracks(
      `eq(track,${trackId})&ordering(starts_at)&status=${PUBLISHED_STATUS}&page_size=50&view_mode=upcoming`,
      {"fetchAll": true}
    );
  }, []);

  if (scheduledTracksStatus !== STATUS_DONE || size(scheduledTracks) === 0) return null;

  const firstUpcomingIndex = findIndex(scheduledTracks, (track) =>
    moment().utc().isBefore(moment(track.starts_at))
  );
  const upcomingCount = firstUpcomingIndex === -1 ? 0 : scheduledTracksCount - firstUpcomingIndex;
  const subTitle =
    upcomingCount === scheduledTracksCount
      ? `${upcomingCount} upcoming`
      : `${upcomingCount} upcoming • ${scheduledTracksCount} total`;
  return (
    <DescriptionContainer showContainer={showContainer}>
      <UpcomingSection
        title="Scheduled offerings"
        subTitle={subTitle}
        contentList={scheduledTracks}
        ContentCard={ContentCardAdapter}
        startIndex={firstUpcomingIndex === -1 ? 0 : firstUpcomingIndex}
      />
    </DescriptionContainer>
  );
};

UpcomingScheduledTracks.propTypes = {
  trackId: PropTypes.number,
  showContainer: PropTypes.bool,
};

UpcomingScheduledTracks.defaultProps = {
  showContainer: true,
};
