import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'redux-form';

import CheckboxField from 'inputs/components/CheckboxField';
import TextField from 'inputs/components/TextField';
import { FormFieldGroup } from 'shared/components/Form';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import * as formValidations from 'shared/formValidations';

const ExternalRegistrationSection = ({
  showInfobox,
  contentNameSingular,
  initialShowExternalRegistration,
  externalRegistrationHelpText,
}) => {
  const [showExternalRegistration, setShowExternalRegistration] = useState(
    initialShowExternalRegistration
  );
  return (
    <>
      {showInfobox && (
        <InfoBox
          content={`Organizers will be able to override external registration settings on their ${contentNameSingular}s.`}
        />
      )}
      <FormFieldGroup>
        <Field
          name="has_external_registration"
          label="External Registration"
          infoText={externalRegistrationHelpText}
          labelSize="h5"
          labelWeight="medium"
          component={CheckboxField}
          onChange={() => setShowExternalRegistration(!showExternalRegistration)}
        />
      </FormFieldGroup>
      {showExternalRegistration && (
        <>
          <FormFieldGroup>
            <Field
              label="External Registration URL"
              name="external_link"
              component={TextField}
              validate={[formValidations.url]}
              placeholder="https://"
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <InfoText
              top={4}
              bottom={8}
              content="Optionally, type instructions for how attendees should register in the external tool."
            />
            <Field
              name="external_link_description"
              label="External Registration instructions"
              component={TextField}
              multiline
              rows={3}
            />
          </FormFieldGroup>
        </>
      )}
    </>
  );
};

ExternalRegistrationSection.propTypes = {
  showInfobox: PropTypes.bool,
  contentNameSingular: PropTypes.string,
  externalRegistrationHelpText: PropTypes.string,
  initialShowExternalRegistration: PropTypes.bool,
};

export default ExternalRegistrationSection;
