import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const GroupIcon = ({ width, height, fill }) => {
  const d =
    'M5.439 1.556c1.831 0 3.255 1.432 3.255 3.274 0 1.432-.813 2.557-2.034 3.069.576' +
    '.165 1.152.398 1.62.752-1.853 1.333-2.416 3.443-2.416 5.489v2.092c-2.035.205-3.' +
    '783.205-5.614-1.023v-2.297c0-2.455 1.73-4.4 3.968-5.013-1.221-.512-2.137-1.637-' +
    '2.137-3.07 0-1.841 1.526-3.273 3.358-3.273zM13.944.5c2.032 0 3.677 1.825 3.677 ' +
    '3.954 0 1.622-.967 3.042-2.322 3.65 2.516.609 4.451 3.042 4.451 5.88v4.3c-3.87 ' +
    '1.622-7.74 1.622-11.611 0v-4.3c0-2.838 1.838-5.271 4.354-5.88-1.355-.608-2.322-' +
    '2.028-2.322-3.65C10.17 2.325 11.816.5 13.944.5z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

GroupIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default GroupIcon;
