import PropTypes from 'prop-types';
import { useState } from 'react';

import ContentItemPicker from 'shared/components/ContentItemPicker';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { isEmpty } from 'vendor/lodash';

const ContentItemPickerModal = ({ onAdd, handleClose, assignee, initialSelectedItems }) => {
  // This state does not contain the "items" because it is not possible to uncheck an item that is already added to a track.
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const handleAdd = () => {
    onAdd(selectedItems);
  };

  return (
    <Modal
      title={`Assign to ${assignee.name}`}
      // These widths will be refactored once we standardize them in our other dialogs
      // Asana ticket: https://app.asana.com/0/1131357843180351/1203438257839143
      width={window.innerWidth > 600 ? 600 : null}
      maxWidth={window.innerWidth > 600 ? 600 : null}
      height={80}
      overflow="hidden"
      handleClose={handleClose}
    >
      <ModalBody fullSize scrollable>
        <ContentItemPicker
          contentType="all"
          initialSelectedItems={selectedItems}
          alreadyAddedItems={[]}
          onAdd={setSelectedItems}
          handleClose={handleClose}
          showCreateNewButton={false}
          assignee={assignee}
        />
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleAdd} disabled={isEmpty(selectedItems)}>
          Next
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ContentItemPickerModal.propTypes = {
  onAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  assignee: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  initialSelectedItems: PropTypes.array,
};

export default ContentItemPickerModal;
