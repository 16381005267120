import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetContentTypeLabel } from 'app/catalog/hooks';
import AssignmentTraitIcon from 'app/content-items/components/AssignmentTraitIcon';
import Dashboard from 'backoffice/components/Dashboard';
import DashboardTopBar from 'backoffice/components/Dashboard/DashboardTopBar';
import RQLDashboard from 'backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from 'backoffice/hooks';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { queries } from 'queries';
import Page404 from 'scenes/404';
import ListTrackItemAssignmentsModal from 'scenes/Dashboard/Assignments/ListTrackItemAssignmentsModal';
import ProgressCell from 'scenes/Dashboard/Assignments/ProgressCell';
import {
  alphabeticalAscendingText,
  alphabeticalDescendingText,
  DASHBOARD_EVENTS,
} from 'scenes/Dashboard/constants';
import { getContentRouteBasedOnType } from 'services/contents';
import { mapRoute } from 'services/requests';
import { ApiURLs } from 'services/requests-base';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from 'shared/constants';
import { usePublicIdFromURL } from 'shared/hooks';
import { map, get } from 'vendor/lodash';
import { Stack } from 'vendor/mui';

const PAGE_SIZE = 15;

type AssignmentSectionProp = {
  segmentPublicId: string;
  filterObj: any;
  updateOrdering: any;
  updatePagination: any;
};

const AssignmentSection = (props: AssignmentSectionProp) => {
  const { segmentPublicId, filterObj, updateOrdering, updatePagination } = props;

  const [targetUser, setTargetUser] = React.useState(null);
  const [targetTrack, setTargetTrack] = React.useState(null);
  const [showTrackItemAssignmentListModal, setShowTrackItemAssignmentListModal] =
    React.useState(false);

  const queryParams = useLocation();
  const searchParams = queryParams.search;
  const getContentTypeLabel = useGetContentTypeLabel();

  const { data, isLoading, isError, refetch } = useQuery(
    queries.segments.assignment(segmentPublicId, searchParams)
  );

  useEffect(() => {
    refetch();
  }, [segmentPublicId, searchParams, refetch]);

  const handleViewProgressClose = () => {
    setTargetUser(null);
    setTargetTrack(null);
    setShowTrackItemAssignmentListModal(false);
  };

  const handleRowViewProgressClick = React.useCallback((assignment) => {
    setTargetUser(assignment.user);
    setTargetTrack(assignment.deconstructed_content_item);
    setShowTrackItemAssignmentListModal(true);
  }, []);

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  if (!data) return null;

  const { results, count } = data;

  return (
    <>
      <RQLDashboard
        dashboardName={DASHBOARD_EVENTS}
        rows={results}
        pageSize={PAGE_SIZE}
        totalCount={count}
        requestStatus={isLoading ? STATUS_LOADING : STATUS_DONE}
        refreshDashboard={queries.segments.report}
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      >
        <Dashboard.Column
          size="15"
          header={
            <Dashboard.Header
              title="Assignee"
              orderingKey="user_name"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => <Dashboard.UserCell data={row.user} showAvatar highlighted />}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={
            <Dashboard.Header
              title="Type"
              orderingKey="content_item_type"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => getContentTypeLabel(get(row.deconstructed_content_item, 'content_type'))}
        </Dashboard.Column>
        <Dashboard.Column
          size="20"
          header={
            <Dashboard.Header
              title="Content"
              orderingKey="content_item_name"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => {
            const contentTypeRoute = getContentRouteBasedOnType(
              get(row.deconstructed_content_item, 'content_type')
            );
            const contentItemName = get(row.deconstructed_content_item, 'name');

            return (
              <>
                {!contentTypeRoute && contentItemName}
                {contentTypeRoute && (
                  <Dashboard.CellLink
                    to={mapRoute(contentTypeRoute, {
                      public_id_and_slug: `${get(
                        row.deconstructed_content_item,
                        'public_id_and_slug'
                      )}`,
                    })}
                  >
                    {contentItemName}
                  </Dashboard.CellLink>
                )}
              </>
            );
          }}
        </Dashboard.Column>
        <Dashboard.Column
          size="15"
          header={
            <Dashboard.Header
              title="Assigned by"
              orderingKey="created_by"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => <Dashboard.UserCell data={row.created_by} showAvatar highlighted />}
        </Dashboard.Column>
        <Dashboard.Column
          size="15"
          header={<Dashboard.Header title="Assigned on" orderingKey="created" />}
        >
          {({ row }) => <Dashboard.DateCell data={row.created} />}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Due on" orderingKey="due_date" />}
        >
          {({ row }) => (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Dashboard.DateCell data={row.due_date} />
              {row.is_overdue && <AssignmentTraitIcon trait="overdue" />}
            </Stack>
          )}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Expires on" orderingKey="expires_at" />}
        >
          {({ row }) => (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Dashboard.DateCell data={row.expires_at} />
              {row.is_expired && <AssignmentTraitIcon trait="expired" />}
            </Stack>
          )}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Progress" orderingKey="progress" />}
        >
          {({ row }) => (
            <ProgressCell row={row} handleRowViewProgressClick={handleRowViewProgressClick} />
          )}
        </Dashboard.Column>
        <Dashboard.Column size="12" header={<Dashboard.Header title="Status" />}>
          {({ row }) => <Dashboard.AssignmentStateCell data={row} />}
        </Dashboard.Column>
      </RQLDashboard>
      {showTrackItemAssignmentListModal && targetUser && targetTrack && (
        <ListTrackItemAssignmentsModal
          handleClose={handleViewProgressClose}
          handleBack={handleViewProgressClose}
          user={targetUser}
          contenItem={targetTrack}
        />
      )}
    </>
  );
};

const AssignmentReport = () => {
  const { publicId: segmentPublicId } = usePublicIdFromURL();

  const [fetchFilters, { data: filters, status: filterStatus }] = useEntities(
    actions.userData.retrieveFilters,
    null
  );

  const { filterObj, updateOrdering, updatePagination } = useRQLRouteFilters(
    'user_name',
    map(filters, (item) => item.filter),
    PAGE_SIZE
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const {
    data: segment,
    isLoading,
    isError,
  } = useQuery(queries.segments.report(segmentPublicId || ''));

  const buildExportUrl = (publicId) => {
    return ApiURLs['api_segments:assignment-export'](publicId);
  };

  if (isError) return <Page404 />;

  if (isLoading || filterStatus === STATUS_LOADING) return <Loading />;

  if (!segment) return null;

  return (
    <>
      <DashboardTopBar title={segment.name} subTitle={segment.description}>
        <Dashboard.ExportCsvButton
          dashboardName="Assignment-Report"
          buttonUrl={buildExportUrl(segment.public_id)}
        />
      </DashboardTopBar>
      <AssignmentSection
        segmentPublicId={segment.public_id}
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      />
    </>
  );
};

export default AssignmentReport;
