import PropTypes from 'prop-types';
import React from 'react';

function DisplayFormErrors(props) {
  const { errors } = props;

  return (
    <div className="display-form-errors">
      {(() => {
        if (errors) {
          return Object.keys(errors).map((key, i) => (
            <span key={i} className="form-error-message">
              {errors[key]}
            </span>
          ));
        }
        return '';
      })()}
    </div>
  );
}

DisplayFormErrors.propTypes = {
  errors: PropTypes.object,
};

export default DisplayFormErrors;
