import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import CheckboxInput, { Box } from 'inputs/components/SquareCheckboxInput';
import colors from 'services/colors';

const VARIANT_MAPPING = {
  default: `
    background-color: ${colors.neutral0};
    border: 1px solid ${colors.neutral200};

    &:hover, &:focus {
      background-color: ${colors.neutral100};
    }
    `,
  alreadySelected: `
    background-color: ${colors.neutral50};
    border: 1px solid ${colors.neutral200};


    ${Box} {
      background-color: ${colors.neutral200};
    }
    `,

  newlySelected: `
    background-color: ${colors.action100};
    border: 1px solid ${colors.action100};
    `,
};

const VARIANT_OVERLAY_MAPPING = {
  default: `
   background: linear-gradient(to left, ${colors.neutral0}, transparent 12%);

    &:hover, &:focus {
      background: linear-gradient(to left, ${colors.neutral100}, transparent 12%);
    }
  `,
  newlySelected: `
    background: linear-gradient(to left, ${colors.action100}, transparent 12%);
  `,
};

const getVariantStyle = ({ variant, variantMapping }) => {
  if (!variant) {
    return variantMapping.default;
  }

  return variantMapping[variant];
};

const ContentSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  min-height: ${({ minHeight }) => minHeight || 100}px;
  pointer-events: all;
  overflow: hidden;

  > * + * {
    margin-left: 12px;
  }

  ${getVariantStyle}

  ${({ borderless }) => borderless && `border: none;`}
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  ${getVariantStyle}
`;

const BaseContentSelect = ({
  content,
  alreadySelected,
  newlySelected,
  handleCheckboxChange,
  children,
  minHeight,
  hasEllipsis,
  borderless,
}) => {
  const { name, display_name } = content;

  return (
    <ContentSelectWrapper
      minHeight={minHeight}
      variant={
        (alreadySelected && 'alreadySelected') || (newlySelected && 'newlySelected') || 'default'
      }
      variantMapping={VARIANT_MAPPING}
      borderless={borderless}
    >
      <CheckboxInput
        aria-label={`Select ${name || display_name}`}
        disabled={alreadySelected}
        checked={alreadySelected || newlySelected}
        onChange={() => handleCheckboxChange(content)}
        variant="action"
      />
      {children}
      {hasEllipsis && (
        <Overlay
          variant={(newlySelected && 'newlySelected') || 'default'}
          variantMapping={VARIANT_OVERLAY_MAPPING}
        />
      )}
    </ContentSelectWrapper>
  );
};

BaseContentSelect.propTypes = {
  content: PropTypes.object,
  alreadySelected: PropTypes.bool,
  newlySelected: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  children: PropTypes.any,
  minHeight: PropTypes.number,
  borderless: PropTypes.bool,
  hasEllipsis: PropTypes.bool,
};

BaseContentSelect.defaultProps = {
  borderless: false,
  hasEllipsis: false,
};

export default BaseContentSelect;
