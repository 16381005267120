import { isNil } from 'lodash';
import * as React from 'react';

import { CONTENT_SIDEBAR_MIN_WIDTH } from 'app/shared/ui-constants';

type TrackSidebarCtxType = {
  isSidebarOpen: boolean;
  sidebarWidth: number;
  onSidebarResize: React.Dispatch<React.SetStateAction<number>>;
  onToggleSidebar: () => void;
};

const TrackSidebarCtx = React.createContext<TrackSidebarCtxType | null>(null);

type TrackSidebarProviderProps = {
  children: React.ReactNode;
};

export function TrackSidebarProvider(props: TrackSidebarProviderProps) {
  const { children } = props;

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [sidebarWidth, setSidebarWidth] = React.useState(CONTENT_SIDEBAR_MIN_WIDTH);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const ctxValue: TrackSidebarCtxType = {
    isSidebarOpen,
    sidebarWidth,
    onToggleSidebar: handleToggleSidebar,
    onSidebarResize: setSidebarWidth,
  };

  return <TrackSidebarCtx.Provider value={ctxValue}>{children}</TrackSidebarCtx.Provider>;
}

export function useTrackSidebarCtx() {
  const ctx = React.useContext(TrackSidebarCtx);

  if (isNil(ctx)) {
    throw new Error('useTrackSidebarCtx must be used within a TrackSidebarProvider');
  }

  return ctx;
}
