import { isEmpty } from 'lodash';

import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';

export function useIsRenderedWithinTrack() {
  const { currentTrackNode } = useTrackContentConsumptionCtx();

  const isRenderedWithinTrack = !isEmpty(currentTrackNode.track);
  return isRenderedWithinTrack;
}
