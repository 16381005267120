import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const AnonymousIcon = ({ width, height, fill }) => {
  const d =
    'M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M500.7,952C333,952,213.9,849.2,212.2,847.6c27.2-15.8,138.6-37.5,164.7-53.9c28.9-18.2,19.6-117.6,19.6-117.6l-19.6-17.2c0,0-3.9-45.5-13.5-61.2c-16.3-26.9-39.2-98-39.2-98s-15.3-11.1-18.9-30.9c-4.8-2-5-9.5-0.6-11.9c1.7-22.7,19.5-35.6,19.5-35.6s-15.3-69.4-15.3-117.6c0-109.6,83.4-156.8,197.6-156.8c114.3,0,177.8,55.5,177.8,156.8c0,48.2-7.3,117.6-7.3,117.6s19.6,14.3,19.6,39.2c0,24.9-19.6,39.2-19.6,39.2s-20.1,61.4-39.2,98c-8.5,16.3-13.5,61.2-13.5,61.2L604.7,676c0,0-9.3,99.4,19.6,117.6c25.7,16.2,134.7,37.5,163.6,53.3C787.8,848.9,668.4,952,500.7,952z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

AnonymousIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default AnonymousIcon;
