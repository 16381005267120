import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const RemoteIcon = ({ width, height, fill }) => {
  const d =
    'M20,6.09259134 L18.3562013,7.73639002 C13.7341345,3.16926569 6.28967428,3.17058023 ' +
    '1.63822266,7.730814 L-3.90798505e-14,6.09259134 C5.56430486,0.637699321 ' +
    '14.468097,0.633906779 20,6.09259134 Z M16.3636364,9.72895498 L14.5454883,11.5471031 ' +
    'C14.539897,11.5414756 14.5342954,11.5358537 14.5286813,11.5302396 C12.0145781,9.01613642 ' +
    '7.94189186,9.01053481 5.42084341,11.5134348 L3.63636364,9.72895498 C7.16090841,6.24055039 ' +
    '12.8390916,6.24055039 16.3636364,9.72895498 Z M11.3636364,16.5471368 C10.6105208,17.3002524 ' +
    '9.3894792,17.3002524 8.63636364,16.5471368 C7.88324807,15.7940212 7.88324807,14.5729796 ' +
    '8.63636364,13.8198641 C9.3894792,13.0667485 10.6105208,13.0667485 11.3636364,13.8198641 ' +
    'C12.1167519,14.5729796 12.1167519,15.7940212 11.3636364,16.5471368 Z';

  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

RemoteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default RemoteIcon;
