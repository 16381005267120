import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';
import { SurveyQuestionKindInitialValueMap } from 'surveys/constants';
import surveyProptypes from 'surveys/types';
import { forEach } from 'vendor/lodash';

import SurveyPreviewForm from './SurveyPreviewForm';

const RequiredCopy = styled.div`
  margin-top: 12px;
  color: ${colors.error600};
`;

const getInitialValues = (survey) => {
  const { questions } = survey;
  const values = {};

  forEach(questions, (question) => {
    values[`question-${question.id}`] = SurveyQuestionKindInitialValueMap[question.kind];
  });

  return values;
};

const SurveyPreviewContent = ({ survey }) => {
  const initialValues = getInitialValues(survey);

  return (
    <React.Fragment>
      <Text>{survey.description}</Text>
      <RequiredCopy>* Required answers</RequiredCopy>
      <SurveyPreviewForm
        initialValues={initialValues}
        questions={survey.questions}
        surveyId={survey.id}
      />
    </React.Fragment>
  );
};

SurveyPreviewContent.propTypes = {
  survey: surveyProptypes.survey,
};

export default SurveyPreviewContent;
