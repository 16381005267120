import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import Pill from 'shared/components/Pill';
import { Stack } from 'vendor/mui';
import { ConnectWithoutContactIcon, HighlightOffIcon, PersonPinCircleIcon } from 'vendor/mui-icons';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const EnrollmentSwitcher = ({ actions, status }) => {
  const { performLocalEnrollment, performOnlineEnrollment, performUnenroll } = actions;
  const { userIsEnrolled, userIsEnrolledLocal, showLocalEnroll, showOnlineEnroll } = status;

  const [isLoading, setIsLoading] = useState(false);

  const canShowLocalEnroll = userIsEnrolled && showLocalEnroll;
  const canShowOnlineEnroll = userIsEnrolledLocal && showOnlineEnroll;

  const enrollOnline = () => {
    setIsLoading(true);
    performOnlineEnrollment();
  };
  const enrollLocal = () => {
    setIsLoading(true);
    performLocalEnrollment();
  };

  const drop = () => {
    performUnenroll();
  };

  useEffect(() => {
    setIsLoading(false);
  }, [userIsEnrolled, userIsEnrolledLocal]);

  if (isLoading) {
    return <Loading hasMargin={false} size={12} />;
  }

  return (
    <Stack direction="row" spacing="12px">
      <Button onClick={drop} startIcon={<HighlightOffIcon />}>
        Drop
      </Button>

      {canShowLocalEnroll && (
        <Button variant="contained" onClick={enrollLocal} startIcon={<PersonPinCircleIcon />}>
          Switch to In-person
        </Button>
      )}

      {canShowOnlineEnroll && (
        <Button
          variant="contained"
          onClick={enrollOnline}
          startIcon={<ConnectWithoutContactIcon />}
        >
          Switch to Online
        </Button>
      )}
    </Stack>
  );
};

EnrollmentSwitcher.propTypes = {
  actions: PropTypes.object,
  status: PropTypes.object,
};

export const EnrolledActionButtons = ({ actions, status }) => {
  const { userIsEnrolledOnline } = status;

  return (
    <Container>
      <Pill
        variant="success200"
        label="Enrolled"
        icon={userIsEnrolledOnline ? 'online' : 'location'}
      />
      <EnrollmentSwitcher actions={actions} status={status} />
    </Container>
  );
};

EnrolledActionButtons.propTypes = {
  actions: PropTypes.object,
  status: PropTypes.object,
};
