import {
  Box,
  Divider,
  Stack,
  Avatar as MUIAvatar,
  AvatarGroup,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { queries } from 'queries';
import colors from 'services/colors';
import { get, map } from 'vendor/lodash';
import rql from 'vendor/rql';

import { Channel } from '../../types';

const Avatar = styled(MUIAvatar)`
  border: none !important;
`;

type StatsItemProps = {
  variant: 'rounded' | 'circular';
  items: any[];
  count: number;
  label: string;
};

function StatsItem(props: StatsItemProps) {
  const { variant, items, count, label } = props;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <AvatarGroup>
        {map(items, (item) => (
          <Avatar
            key={item.id}
            alt={item.name}
            src={item.cover_url}
            variant={variant}
            sx={{ width: 24, height: 24 }}
          >
            {/* Display empty avatar as a fallback */}{' '}
          </Avatar>
        ))}
      </AvatarGroup>
      <Typography
        variant="body2"
        component="span"
        color={colors.neutral700}
        sx={{ fontSize: '10px', fontWeight: 500 }}
      >
        {count} {label}
        {count > 1 ? 's' : ''}
      </Typography>
    </Box>
  );
}

function StatsItemSkeleton() {
  return (
    <Skeleton variant="text" sx={{ fontSize: '1rem' }}>
      <Box display="flex" alignItems="center" gap={1}>
        <AvatarGroup>
          <Avatar sx={{ width: 24, height: 24 }} />
          <Avatar sx={{ width: 24, height: 24 }} />
          <Avatar sx={{ width: 24, height: 24 }} />
          <Avatar sx={{ width: 24, height: 24 }} />
          <Avatar sx={{ width: 24, height: 24 }} />
          <Avatar sx={{ width: 24, height: 24 }} />
        </AvatarGroup>
      </Box>
    </Skeleton>
  );
}

type ChannelStatsProps = {
  channel: Channel;
};

export function ChannelHeaderStats(props: ChannelStatsProps) {
  const { channel } = props;

  const contentItemsFilterExpression = rql({
    page: 1,
    page_size: 5,
    view_mode: 'widget',
    channel: channel.id,
  });

  // Fetch content items stats
  const { data: contentItemsData, isLoading: isLoadingContentItemsData } = useQuery({
    ...queries.content_items.list(contentItemsFilterExpression),
  });

  const contentItemsCount = get(contentItemsData, 'count', 0);
  const contentItems = get(contentItemsData, 'results', []);

  return (
    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
      {isLoadingContentItemsData ? (
        <StatsItemSkeleton />
      ) : contentItemsCount > 0 ? (
        <StatsItem
          variant="rounded"
          items={contentItems}
          count={contentItemsCount}
          label="Content item"
        />
      ) : null}

      {/* TODO: add subscribers stats */}
    </Stack>
  );
}
