import { useEffect, useState, useMemo, useCallback } from 'react';

import Autocomplete from '../Autocomplete';

import {
  generateDurationOptionsIn15MinIntervals,
  normalizeDuration,
  isDurationLengthValid,
  formatDurationToPresent,
  isDurationFormatValid,
  formatDurationToEmit,
} from './helpers';

const durationOptionsIn15MinIntervals = generateDurationOptionsIn15MinIntervals();

function useDropdownSelectInputValue(input: any, isClearable: boolean) {
  const { value, onBlur: onBlurInput, onChange: onChangeInput } = input;
  const [currentValue, setCurrentValue] = useState<any>(null);

  useEffect(() => {
    const normalizedValue = normalizeDuration(value);

    const isLengthValid = isDurationLengthValid({
      value: normalizedValue,
      minLengthInSeconds: isClearable ? 0 : 1,
    });

    if (isLengthValid) {
      setCurrentValue(formatDurationToPresent(normalizedValue));
    }
  }, [value, isClearable]);

  const handleChange = useCallback(
    (value) => {
      onBlurInput();

      const emittedValue =
        value && isDurationFormatValid(value) ? formatDurationToEmit(value) : value;

      onChangeInput(emittedValue);
    },
    [onChangeInput, onBlurInput]
  );

  return [currentValue, handleChange] as const;
}

type DurationFieldProps = {
  input?: any;
  meta?: any;
  inputId?: string;
  disableClearable?: boolean;
  aditionalOptions?: {
    label: string;
    value: string;
  }[];
};

function DurationField(props: DurationFieldProps) {
  const { input, meta, inputId, disableClearable = true, aditionalOptions, ...rest } = props;
  const { error } = meta;

  const [currentValue, handleChange] = useDropdownSelectInputValue(input, !disableClearable);

  const options = useMemo(() => {
    if (aditionalOptions) {
      return [...aditionalOptions, ...durationOptionsIn15MinIntervals];
    }

    return durationOptionsIn15MinIntervals;
  }, [aditionalOptions]);

  return (
    <div data-testid="duration-field">
      <Autocomplete
        {...rest}
        id={inputId}
        name={inputId}
        value={currentValue}
        placeholder="H:MM"
        options={options}
        error={error}
        onChange={handleChange}
        disableClearable={disableClearable}
        freeSolo
        creatable
      />
    </div>
  );
}

export default DurationField;
