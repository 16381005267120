import PropTypes from 'prop-types';
import React from 'react';
import { Field, Fields } from 'redux-form';
import styled from 'styled-components';

import AccordionCheckboxField from 'inputs/components/AccordionCheckboxField';
import CalendarSingleResourceFields from 'inputs/components/CalendarSingleResourceFields';
import TextField from 'inputs/components/TextField';
import InfoText from 'shared/components/InfoText';
import Form from 'shared/components/OldForm';
import { get } from 'vendor/lodash';

const validateLength = Form.validations.maxLength(255);

// TODO move this file to SessionFormShared folder when removing toggle_additional_sessions

const EnrollmentMethodWrapper = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;

const fieldNames = [
  'id',
  'google_calendar_resource_id',
  'google_calendar_resource_email',
  'google_calendar_calendar_owner',
  'room',
  'starts_at',
  'duration',
];

const InPersonCapManager = ({ bookCalendarRooms, locationId, ...props }) => (
  <AccordionCheckboxField {...props} label="In-Person Enrollment" hasNoMaxWidth>
    <EnrollmentMethodWrapper>
      {bookCalendarRooms ? (
        <Fields
          component={CalendarSingleResourceFields}
          label="Room"
          names={fieldNames}
          locationId={locationId}
          idFieldName="session_id"
        />
      ) : (
        <Field name="room" label="Room" component={TextField} type="text" placeholder="Room #" />
      )}

      <Field
        name="extra_info"
        label="Extra Info"
        placeholder="Anything else relevant?"
        component={TextField}
        validate={[validateLength]}
      />
    </EnrollmentMethodWrapper>
  </AccordionCheckboxField>
);

InPersonCapManager.propTypes = {
  bookCalendarRooms: PropTypes.bool,
  locationId: PropTypes.number,
};

const OnlineCapManager = ({
  bookCalendarRooms,
  meetingUrlValidators,
  isNewEventForm,
  input,
  ...props
}) => {
  const isChecked = get(input, 'checked', false);
  const isRequired = isChecked && isNewEventForm && !bookCalendarRooms;

  return (
    <AccordionCheckboxField
      input={input}
      {...props}
      label="Online Enrollment"
      hasNoMaxWidth
      isNewEventForm={isNewEventForm}
    >
      <Field
        name="meeting_url"
        label="Meeting URL"
        required={isRequired}
        component={TextField}
        type="text"
        placeholder="URL"
        validate={meetingUrlValidators}
      />
      <InfoText
        top={4}
        bottom={16}
        content={`Link to the online meeting, such as: Meet, Zoom, Teams, WebEx, etc. ${
          bookCalendarRooms
            ? 'If you don’t enter a link, a Google Meet link will be created automatically.'
            : ''
        }`}
      />

      <Field
        name="online_extra_info"
        label="Extra Info"
        placeholder="Anything else relevant?"
        component={TextField}
        validate={[validateLength]}
      />
      <InfoText top={4} content="For example: Room password: 123456." />
    </AccordionCheckboxField>
  );
};

OnlineCapManager.propTypes = {
  bookCalendarRooms: PropTypes.bool,
  meetingUrlValidators: PropTypes.array,
  isNewEventForm: PropTypes.bool,
  input: PropTypes.object,
};

const SessionEnrollmentMethodsFieldWrapper = ({
  bookCalendarRooms,
  locationId,
  meetingUrlValidators,
  isNewForm,
}) => (
  <EnrollmentMethodWrapper>
    <Field
      name="allows_local"
      type="checkbox"
      bookCalendarRooms={bookCalendarRooms}
      locationId={locationId}
      component={InPersonCapManager}
      isNewEventForm={isNewForm}
    />
    <Field
      name="allows_online"
      type="checkbox"
      bookCalendarRooms={bookCalendarRooms}
      meetingUrlValidators={meetingUrlValidators}
      component={OnlineCapManager}
      isNewEventForm={isNewForm}
    />
  </EnrollmentMethodWrapper>
);

SessionEnrollmentMethodsFieldWrapper.propTypes = {
  bookCalendarRooms: PropTypes.bool,
  locationId: PropTypes.number,
  meetingUrlValidators: PropTypes.array,
  isNewForm: PropTypes.bool,
};

SessionEnrollmentMethodsFieldWrapper.defaultProps = {
  isNewForm: false,
};

export default SessionEnrollmentMethodsFieldWrapper;
