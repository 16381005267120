import PropTypes from 'prop-types';
import React from 'react';

import AttendanceActionManager from 'event-shared/components/AttendanceActionManager';
import CheckinUnenrollButtons from 'event-shared/components/AttendanceActionManager/CheckinUnenrollButtons';
import RatingArea from 'event-shared/components/AttendanceActionManager/RatingArea';
import RestrictedEnrollmentInfoBox from 'event-shared/components/RestrictedEnrollmentInfoBox';
import { allowCancellation, getUserEnrollmentStatus } from 'event-shared/services';

import {
  DetailsAttendanceRenderActions,
  DetailsAttendanceRenderFullCapacity,
  DetailsAttendanceRenderUncancellableEnroll,
} from './DetailsAttendanceActionComponents';
import { ConnectedEnrollmentClosed } from './SharedAttendanceActionElements';

const DetailsAttendanceActionElement = ({
  event,
  enrollmentStatus,
  unenrollLabel,
  onRateClick,
}) => {
  const { userIsEnrolled } = getUserEnrollmentStatus(enrollmentStatus);
  const userCanCancel = allowCancellation(event) && userIsEnrolled;

  const renderActions = (actions, status, spotStatus) => (
    <DetailsAttendanceRenderActions
      actions={actions}
      status={status}
      unenrollLabel={unenrollLabel}
      spotStatus={spotStatus}
    />
  );

  return (
    <AttendanceActionManager
      event={event}
      renderEnrolled={renderActions}
      renderWaitlist={renderActions}
      renderProspect={renderActions}
      renderUncancellableEnroll={() => (
        <DetailsAttendanceRenderUncancellableEnroll unenrollLabel={unenrollLabel} />
      )}
      renderFullCapacity={() => <DetailsAttendanceRenderFullCapacity event={event} />}
      renderRating={() => <RatingArea event={event} onRateClick={onRateClick} viewMode="details" />}
      renderDisabledStatusChange={() => <RestrictedEnrollmentInfoBox event={event} />}
      renderCheckin={({ onCheckinClick, onUnenrollClick }) => {
        return (
          <CheckinUnenrollButtons
            enrollmentStatus={enrollmentStatus}
            allowCancellation={userCanCancel}
            onCheckinButtonClick={onCheckinClick}
            onUnenrollButtonClick={onUnenrollClick}
          />
        );
      }}
      renderEnrollmentClosed={() => <ConnectedEnrollmentClosed event={event} />}
    />
  );
};

DetailsAttendanceActionElement.propTypes = {
  event: PropTypes.object,
  enrollmentStatus: PropTypes.string,
  unenrollLabel: PropTypes.string,
  onRateClick: PropTypes.func,
};

export default DetailsAttendanceActionElement;
