import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { CourseFormRightTitleSection, CourseFormContainer } from 'course/components/CourseShared';
import { COURSE_STATUS_DRAFT } from 'course/constants';
import actions from 'entities/actions';
import FileField from 'inputs/components/FileField';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import InfoBox from 'shared/components/InfoBox';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { useCurrentUser, useInterval, useLabels, useOriginRoute } from 'shared/hooks';
import { pick } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import UploadStatusModal from './UploadStatusModal';

const FormDescription = styled(Text)`
  margin-bottom: 12px;
`;

const formName = 'courseImportForm';

export const OldCourseImportForm = ({
  title,
  handleSubmit,
  fetchCourse,
  course,
  showImportModal,
}) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { label_catalog: labelCatalog } = useLabels();

  const { home_page_url_path: homePath } = currentUser;
  const redirectionRoute = useOriginRoute(homePath);

  const {
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    latest_sync: latestSync,
    status,
  } = course;
  const uploadCompleted = Boolean(latestSync);

  useInterval(() => {
    if (!uploadCompleted && publicId) {
      fetchCourse(publicId);
    }
  }, 5000);

  useEffect(() => {
    if (uploadCompleted && status === COURSE_STATUS_DRAFT) {
      history.push(
        `${mapRoute('courseEdit', { public_id_and_slug: publicIdAndSlug })}?is_publishing=true`
      );
    }
  }, [uploadCompleted]);

  const handleOnSubmit = () => {
    return handleSubmit(
      onSubmitActions(actions.course.upload.toString(), (values) => {
        const payload = {
          ...pick(values, ['course_file']),
        };

        return {
          key: formName,
          body: payload,
        };
      })
    );
  };

  const courseHelpArticleUrl =
    'https://help.plusplus.app/en/articles/5204590-import-elearning-course';

  return (
    <CourseFormContainer>
      <Form
        onSubmit={handleOnSubmit()}
        title={title}
        rightTitleSection={<CourseFormRightTitleSection text="Import" />}
        renderButtons={() => (
          <Form.SubmitButtonsContainer>
            <Button type="submit" size="large">
              Import
            </Button>
            <Button route={redirectionRoute} type="button" color="error" size="large">
              Cancel
            </Button>
          </Form.SubmitButtonsContainer>
        )}
        renderTopInfoBox={() => (
          <InfoBox
            content={
              <>
                First time performing an import?{' '}
                <ButtonLink url={courseHelpArticleUrl} target="_blank">
                  Start here
                </ButtonLink>{' '}
                for a complete step-by-step guide.
              </>
            }
          />
        )}
        renderBottomInfoBox={() =>
          status === 'error' && (
            <InfoBox
              type="error"
              content="Error: we couldn't parse a valid course from the imported file."
            />
          )
        }
      >
        <Form.Panel title="Upload file">
          <FormDescription block size="h4">
            Use this form to import your SCORM 1.2, SCORM 2004, AICC, xAPI, cmi5, and PDF content
            into the {labelCatalog}.
          </FormDescription>
          <Field
            name="course_file"
            component={FileField}
            uploadAreaText={
              <Text size="h4">
                Click to{' '}
                <Text color={colors.success600} size="h4" medium>
                  upload
                </Text>{' '}
                a course file (.zip or .pdf).
              </Text>
            }
            filePath="course_files"
            acceptedFileExtensions=".zip,.pdf"
          />
        </Form.Panel>
      </Form>
      {!uploadCompleted && showImportModal && <UploadStatusModal uploadedCourse={course} />}
    </CourseFormContainer>
  );
};

OldCourseImportForm.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
  fetchCourse: PropTypes.func,
  course: PropTypes.object,
  showImportModal: PropTypes.bool,
};

const ConnectedOldCourseForm = reduxForm({
  form: formName,
  onSubmitSuccess: (result, dispatch, { onCourseSubmit }) => {
    const { public_id } = result;
    onCourseSubmit(public_id);
  },
  onSubmitFail: () => {
    // Display error in the moddal
  },
})(OldCourseImportForm);

export default ConnectedOldCourseForm;
