import PropTypes from 'prop-types';
import React from 'react';

import { get } from 'vendor/lodash';

import { ContentItemCard } from './ContentItemCard';

const EventTypeCard = ({ eventType, disableAllClicks }) => {
  return (
    <ContentItemCard
      contentItem={eventType}
      finishedTypeLabel="Attended"
      finishedProps={{
        singleText: 'person engaged',
        multipleText: 'people engaged',
        amount: get(eventType, 'assignments_count', get(eventType, 'total_assignments')),
      }}
      isHeaderContentDisabled={eventType.upcoming_events_count === 0}
      disableAllClicks={disableAllClicks}
    />
  );
};

EventTypeCard.propTypes = {
  eventType: PropTypes.object,
  disableAllClicks: PropTypes.bool,
};

export { EventTypeCard };
