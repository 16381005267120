import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

const Header = styled(Clicker)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  width: 100%;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  width: 97%;
`;

const SummaryName = styled.div`
  display: flex;
  align-items: center;
  width: 31%;
`;

const SummaryDivisor = styled.div`
  height: 44px;
  width: 1px;
  margin: 0px 16px 0px 16px;
  background-color: ${colors.neutral400};
`;

const SummaryLink = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`;

const ResourceLinkBoxHeader = ({ isOpen, hasError, handleOnClick, link }) => {
  const hasInfo = link && link.name && link.link;
  return (
    <Header isOpen={isOpen} onClick={handleOnClick}>
      {isOpen && (
        <Text size="h4" medium>
          Link
        </Text>
      )}
      {!isOpen && !hasInfo && (
        <Text size="h4" medium color={colors.error600}>
          Add link information...
        </Text>
      )}
      {!isOpen && hasInfo && (
        <Summary>
          <SummaryName>
            <Text size="h4" medium color={hasError ? colors.error600 : null} ellipsisOnLine={2}>
              {link.name}
            </Text>
          </SummaryName>
          <SummaryDivisor />
          <SummaryLink>
            <Text size="h4" block ellipsisOnOverflow>
              {link.link}
            </Text>
          </SummaryLink>
        </Summary>
      )}
      <Icon name={isOpen ? 'up' : 'down'} width={16} height={16} color={colors.neutral900} />
    </Header>
  );
};

ResourceLinkBoxHeader.propTypes = {
  link: PropTypes.object,
  isOpen: PropTypes.bool,
  hasError: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default ResourceLinkBoxHeader;
