import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  detailedObject: {},
  detailedObjectType: null,
  usesDetailedObject: false,
};

export default handleActions(
  {
    [actions.setGlobalDetailedObject]: (state, action) => ({
      ...state,
      detailedObject: action.payload,
    }),
    [actions.setGlobalDetailedObjectType]: (state, action) => ({
      ...state,
      detailedObjectType: action.payload,
    }),
    [actions.setGlobalUsesDetailedObject]: (state, action) => ({
      ...state,
      usesDetailedObject: action.payload,
    }),
  },
  defaultState
);
