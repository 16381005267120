// Enrollment statuses
export const STATUS_AVAILABLE = 'available';
export const STATUS_RESERVED = 'reserved';
export const STATUS_CANCELED = 'canceled';

// Attendance methods
export const ATTENDANCE_METHOD_LOCAL = 'local';
export const ATTENDANCE_METHOD_ONLINE = 'online';

export const SESSION_ROSTER_DEFAULT_TAB = 0;
export const SESSION_ROSTER_AGENDA_TAB = 1;

// Attendance type in session forms
export const ATTENDANCE_TYPE_SINGLE_ATTENDEE = 'single_attendee';
export const ATTENDANCE_TYPE_MULTIPLE_ATTENDEES = 'multiple_attendees';

// Mentorship Request Status
export const MENTORSHIP_REQUEST_STATUS_OPEN = 'open';
export const MENTORSHIP_REQUEST_STATUS_DISMISSED = 'dismissed';
export const MENTORSHIP_REQUEST_STATUS_ACCEPTED = 'accepted';

// Mentorship Request Mode
export const MENTORSHIP_REQUEST_SENT = 'sent';
export const MENTORSHIP_REQUEST_RECEIVED = 'received';

// Mentorship Request Type
export const MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR = 'offer_to_mentor';
export const MENTORSHIP_REQUEST_KIND_SESSION_REQUEST = 'session_request';
export const MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR = 'suggest_mentor';
export const MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE = 'suggest_mentee';

export const MENTORSHIP_REQUEST_KIND_LABEL_MAP = {
  [MENTORSHIP_REQUEST_KIND_OFFER_TO_MENTOR]: 'Offer to Mentor',
  [MENTORSHIP_REQUEST_KIND_SESSION_REQUEST]: 'Session Request',
  [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR]: 'Suggest a Mentor',
  [MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE]: 'Suggest a Mentee',
};

export const ONLINE_AVAILABILITY_METHOD = 'online';
export const LOCAL_AVAILABILITY_METHOD = 'local';
export const FRIENDLY_TO_MY_TIME_AVAILABILITY_METHOD = 'friendly_to_my_time';
