import { titleCase } from 'services/utils';
import ConditionalWrapper from 'shared/components/ConditionalWrapper';
import { map } from 'vendor/lodash';

const formatDateRangeInfo = (date?: string, dateFrom?: string, dateTo?: string) => {
  if (date) {
    return <>({date})</>;
  }

  if (dateFrom && dateTo) {
    return (
      <>
        ({dateFrom} - {dateTo})
      </>
    );
  }

  if (dateFrom && !dateTo) {
    return <>({dateFrom} - Present)</>;
  }

  if (!dateFrom && dateTo) {
    return <>(Until {dateTo})</>;
  }

  return null;
};

interface ProfileDataEntry {
  title: string;
  subtitle?: string;
  description?: string;
  starts_at?: string;
  ends_at?: string;
  date?: string;
  url?: string;
  subtitle_url?: string;
}

interface Props {
  sectionTitle: string;
  data: ProfileDataEntry[];
}

const ProfileDataSection = ({ sectionTitle, data }: Props) => {
  if (!data) return null;

  return (
    <span>
      <h3>{titleCase(sectionTitle)}</h3>
      <ul>
        {map(data, (entry, index) => (
          <li key={`${sectionTitle}-${index}`}>
            <span>
              <ConditionalWrapper
                condition={Boolean(entry?.url)}
                wrapper={(children) => <a href={entry?.url}>{children}</a>}
              >
                {entry.title}
              </ConditionalWrapper>
              &nbsp;{formatDateRangeInfo(entry.date, entry.starts_at, entry.ends_at)}
              {entry.subtitle && (
                <>
                  <br />
                  <ConditionalWrapper
                    condition={Boolean(entry?.subtitle_url)}
                    wrapper={(children) => <a href={entry?.subtitle_url}>{children}</a>}
                  >
                    <span>{entry.subtitle}</span>
                  </ConditionalWrapper>
                </>
              )}
              {entry.description && (
                <>
                  <br />
                  <span>{entry.description}</span>
                </>
              )}
            </span>
          </li>
        ))}
      </ul>
    </span>
  );
};

export default ProfileDataSection;
