import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import SurveyQuestionField from 'surveys/components/SurveyQuestionField';
import { SurveyQuestionKind } from 'surveys/constants';
import surveyProptypes from 'surveys/types';
import { find, forEach, isEmpty, map, noop, isInteger, includes } from 'vendor/lodash';

// From https://codereview.stackexchange.com/a/115887
const getIdfromFieldKey = (key) => map(key.match(/\d+/g), Number)[0];

const validate = (values, { questions }) => {
  const errors = {};

  forEach(values, (value, key) => {
    const fieldId = getIdfromFieldKey(key);
    const question = find(questions, ['id', fieldId]);

    if (question.is_required && question.kind === SurveyQuestionKind.MULTIPLE_CHOICE_AND_OTHER) {
      if (isEmpty(value) || includes(value, '')) {
        errors[key] = 'This answer is required';
      }
    } else if (question.is_required && ((!isInteger(value) && isEmpty(value)) || !value)) {
      errors[key] = 'This answer is required';
    }
  });

  return errors;
};

const SurveyPreviewForm = ({ questions }) => (
  <div>
    {map(questions, (question) => (
      <Field
        key={question.id}
        name={`question-${question.id}`}
        question={question}
        component={SurveyQuestionField}
      />
    ))}
  </div>
);

SurveyPreviewForm.propTypes = {
  questions: PropTypes.arrayOf(surveyProptypes.surveyQuestion),
};

export default reduxForm({
  form: 'surveyPreviewForm',
  onSubmit: noop,
  onSubmitSuccess: noop,
  onSubmitFail: noop,
  validate,
})(SurveyPreviewForm);
