import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { FormFieldError } from 'app/shared/components/Form/FormComponents';
import FileField from 'inputs/components/FileField';
import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';

const UploadAreaText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const CodelabUploadField = (props) => {
  const { allowedExtensions, hasUploadError } = props;

  const [error, setError] = useState(
    hasUploadError
      ? 'Error while uploading the codelab file. Please check your file and try again.'
      : ''
  );

  return (
    <>
      <FieldWrapper>
        <FileField
          width="100%"
          height="100px"
          allowedExtensions={allowedExtensions}
          fileSizeLimit={100 * 1024 * 1024}
          setHelpTextError={setError}
          fileAreaText={(fileName) => (
            <UploadAreaText>
              <Icon name="zip-file-fill" height={30} width={22} color={colors.action600} />
              <Text size="h5" color={colors.action600} medium align="center">
                {fileName}
              </Text>
            </UploadAreaText>
          )}
          uploadAreaText={
            <UploadAreaText>
              <Icon name="zip-file" height={30} width={22} color={colors.action600} />
              <Text color={colors.action600} size="h5" medium>
                Select a ZIP file
              </Text>
            </UploadAreaText>
          }
          {...props}
        />
      </FieldWrapper>
      <FormFieldError customErrorMessage={error} />
    </>
  );
};

CodelabUploadField.propTypes = {
  input: PropTypes.object,
  hasUploadError: PropTypes.bool,
  filePath: PropTypes.string,
  allowedExtensions: PropTypes.object,
};

export default CodelabUploadField;
