// TODO: remove this component after we add facilitators to events
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useEntities } from 'app/entities/utils';
import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { enrollmentSchema } from 'entities/schema';
import {
  ENROLLMENT_ATTENDANCE_STATUS_ENROLLED,
  ENROLLMENT_ATTENDANCE_STATUS_CHECKED_IN,
} from 'event-shared/constants';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import { shouldShowAttendees } from 'shared-content-item/services';
import Text from 'shared/components/Text';
import { useCurrentUser } from 'shared/hooks';
import { map } from 'vendor/lodash';
import { Tooltip } from 'vendor/mui';
import { InfoOutlinedIcon } from 'vendor/mui-icons';

const EventPeopleContainer = ({ event }) => {
  const [fetchEnrollments, { data: enrollments }] = useEntities(
    actions.enrollment.deprecatedRetrieveList,
    null,
    {
      schema: [enrollmentSchema],
    }
  );

  const currentUser = useCurrentUser();

  const shouldFetchEnrollments = shouldShowAttendees(currentUser, event);
  const totalGoingInPerson = event.going_local_enrollments_count || 0;
  const totalGoingOnline = event.going_online_enrollments_count || 0;
  const totalPeopleGoing = totalGoingInPerson + totalGoingOnline;

  useEffect(() => {
    if (!shouldFetchEnrollments) return;

    fetchEnrollments({
      page_size: 22,
      event: event.id,
      attendance_status: [
        ENROLLMENT_ATTENDANCE_STATUS_ENROLLED,
        ENROLLMENT_ATTENDANCE_STATUS_CHECKED_IN,
      ],
    });
  }, []);

  const engagedPeople = map(enrollments, (enrollment) => enrollment.user);
  const organizers = [
    ...(event.organizer_id && event.organizer ? [event.organizer] : []),
    ...(event.co_organizers ? event.co_organizers : []),
  ];

  return (
    <BasePeopleContainer
      contentType={LEARNING_TYPES.events}
      organizers={organizers}
      maintainers={event.presenters}
      engagedPeople={engagedPeople}
      engagedPeopleCount={totalPeopleGoing}
      engagedPeopleHeading={
        <Text display="flex">
          <Tooltip title="These numbers may include deprovisioned users.">
            <InfoOutlinedIcon fontSize="small" sx={{ marginRight: 0.5 }} />
          </Tooltip>{' '}
          {totalPeopleGoing} {totalPeopleGoing > 1 ? 'people' : 'person'} enrolled &bull;{' '}
          {event.waitlist_local_enrollments_count + event.waitlist_going_online_enrollments_count}{' '}
          waitlisted
        </Text>
      }
      withFlexBasis={false}
    />
  );
};

EventPeopleContainer.propTypes = {
  event: PropTypes.object,
};

export default EventPeopleContainer;
