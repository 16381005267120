import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from 'entities/actions';
import UserSelectInput from 'inputs/components/UserSelectInput';
import { toast } from 'notifications/components/NotificationCenter';
import {
  ATTENDANCE_METHOD_LOCAL,
  ATTENDANCE_METHOD_ONLINE,
  STATUS_RESERVED,
} from 'program/constants';
import { getSessionsAvailableCount } from 'program/services';
import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { useFormSelector } from 'shared/hooks';
import { map } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

const ONE_USER_REQUIRED_MESSAGE = 'You must select at least one user to enroll.';
const validateOneUserRequired = Form.validations.minLength(1, {
  message: ONE_USER_REQUIRED_MESSAGE,
});

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 8px;
  }
`;

const SubmitButton = styled(Form.SubmitButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  > ${Icon} {
    margin-right: 8px;
  }
`;

const FullSessionMessageContainer = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: center;
`;

const formName = 'session-user-enroll';

const SessionAttendeeEnrollForm = ({ session, handleSubmit, submitting }) => {
  const [attendanceMethod, setAttendanceMethod] = useState('local');
  const users = useFormSelector(formName, 'enrolled_users');
  const hasEnoughSpots =
    session.attendance_limit === 0 || getSessionsAvailableCount(session) - users.length >= 0;

  const formRef = React.createRef();

  const onSubmit = () => {
    return handleSubmit(
      onSubmitActions(actions.sessionEnrollment.create.toString(), (values) => {
        const attendeesIds = map(values.enrolled_users, (u) => u.id);
        return {
          key: 'userSessionEnrollRequest',
          body: {
            session_public_id: session.public_id,
            attendees_ids: attendeesIds,
            status: STATUS_RESERVED,
            attendance_method: attendanceMethod,
          },
        };
      })
    );
  };

  const onSubmitClick = (attendanceMethod) => {
    setAttendanceMethod(attendanceMethod);
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  return (
    <Form
      formRef={formRef}
      onSubmit={onSubmit()}
      renderButtons={() => {
        if (submitting) {
          return <Loading />;
        }
        return (
          <React.Fragment>
            {!hasEnoughSpots && (
              <FullSessionMessageContainer>
                <Text color={colors.error600}>
                  Session has no spots left to enroll all selected users.
                </Text>
              </FullSessionMessageContainer>
            )}
            <ButtonContainer>
              {session.allows_local && (
                <SubmitButton
                  title="Enroll Locally"
                  onClick={() => onSubmitClick(ATTENDANCE_METHOD_LOCAL)}
                  disabled={!hasEnoughSpots}
                >
                  <Icon height={16} width={16} name="location" />
                  Enroll
                </SubmitButton>
              )}
              {session.allows_online && (
                <SubmitButton
                  title="Enroll Online"
                  onClick={() => onSubmitClick(ATTENDANCE_METHOD_ONLINE)}
                  disabled={!hasEnoughSpots}
                >
                  <Icon height={16} width={16} name="online" />
                  Enroll
                </SubmitButton>
              )}
            </ButtonContainer>
          </React.Fragment>
        );
      }}
    >
      <Field component="input" type="hidden" name="id" />

      <Field
        label="People"
        name="enrolled_users"
        component={UserSelectInput}
        validate={[validateOneUserRequired]}
      />
    </Form>
  );
};

SessionAttendeeEnrollForm.propTypes = {
  session: PropTypes.object,

  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: formName,
  initialValues: {
    enrolled_users: [],
  },
  onSubmitFail: (error) => {
    // If error came from API, not from form validation
    // eslint-disable-next-line no-underscore-dangle
    if (error._error) {
      toast.error(
        'Error',
        `Could not perform the enrollment, please check the session's date and capacity.`
      );
    }
  },
})(SessionAttendeeEnrollForm);
