import { AtomicBlockUtils } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  getVideoEmbedLink,
  getGoogleSlidesEmbedLink,
  getGoogleDocsEmbedLink,
  getGoogleSheetsEmbedLink,
  getGoogleImagesEmbedLink,
  getImageEmbedLink,
  getGenericEmbedLink,
  getConfluencePageProps,
} from 'services/embed';
import { includes, map, padEnd, split, trim } from 'vendor/lodash';

import { entityType } from './constants';

function addEmbeddedBlock(editorState, link, text) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.EMBEDDED_LINK, 'IMMUTABLE', {
      raw: text,
      src: link,
      height: '800px',
      width: '100%',
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

function addVideoBlock(editorState, link, text) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.VIDEO, 'IMMUTABLE', {
      raw: text,
      src: link,
      width: '100%',
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

function addImageBlock(editorState, link, text) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.IMAGE, 'IMMUTABLE', {
      raw: text,
      src: link,
      width: '100%',
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

function addEventTypeBlock(editorState, id, text) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.EVENT_TYPE, 'IMMUTABLE', {
      raw: text,
      id,
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

function addConfluenceDocBlock(editorState, text, hostName, contentId, spaceKey) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.CONFLUENCE_LINK, 'IMMUTABLE', {
      raw: text,
      hostName,
      contentId,
      spaceKey,
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

function addGoogleDriveBlock(editorState, link, text) {
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(entityType.GOOGLE_DRIVE, 'IMMUTABLE', {
      raw: text,
      src: link,
    })
    .getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
}

export function videoEmbedder(text, html, editorState) {
  const link = getVideoEmbedLink(text);
  if (!link) return null;
  return addVideoBlock(editorState, link, text);
}

export function imageEmbedder(text, html, editorState) {
  const link = getImageEmbedLink(text);
  if (!link) return null;
  return addImageBlock(editorState, link, text);
}

export function genericLinkEmbedder(text, html, editorState) {
  const link = getGenericEmbedLink(text);
  if (!link) return null;
  return addEmbeddedBlock(editorState, link, text);
}

export function googleSlidesEmbedder(text, html, editorState) {
  const link = getGoogleSlidesEmbedLink(text);
  if (!link) return null;
  return addGoogleDriveBlock(editorState, link, text);
}

export function googleDocsEmbedder(text, html, editorState) {
  const link = getGoogleDocsEmbedLink(text);
  if (!link) return null;
  return addGoogleDriveBlock(editorState, link, text);
}

export function googleSheetsEmbedder(text, html, editorState) {
  const link = getGoogleSheetsEmbedLink(text);
  if (!link) return null;
  return addGoogleDriveBlock(editorState, link, text);
}

export function googleImagesEmbedder(text, html, editorState) {
  const link = getGoogleImagesEmbedLink(text);
  if (!link) return null;
  return addGoogleDriveBlock(editorState, link, text);
}

export function isLocalUrl(url) {
  return includes(url, window.location.host);
}

export function getEventTypeId(url) {
  if (!includes(url, window.location.host)) return null;

  const path = split(url, window.location.host)?.[1];
  if (!path) return null;

  const urlRegEx =
    /\/event-types\/([\da-f]{8}\b(?:-[\da-f]{4}){3}-\b[\da-f]{12})(_([\da-z]+(?:-[\da-z]+)*)?)?\/$/g;
  if (!path.match(urlRegEx)) return null;

  const uuidRegEx = /([\da-f]{8}\b(?:-[\da-f]{4}){3}-\b[\da-f]{12})/g;
  return path.match(uuidRegEx)[0];
}

export function eventTypeEmbedder(text, html, editorState) {
  // https://test.testplus.co:8000/event-types/48c39bba-5290-4a9d-b9c4-0490472521bb_advanced-java/
  // http://localhost:6006/event-types/48c39bba-5290-4a9d-b9c4-0490472521bb_advanced-java/

  const id = getEventTypeId(text);

  if (!id) return null;

  return addEventTypeBlock(editorState, id, text);
}

export function confluenceDocEmbedder(text, html, editorState) {
  const confluencePageProps = getConfluencePageProps(text);

  if (!confluencePageProps) return null;

  const { hostName, contentId, spaceKey } = confluencePageProps;

  return addConfluenceDocBlock(editorState, text, hostName, contentId, spaceKey);
}

// Used to initialize the creation of a linked content data. At some point, should be unified with convertTextToDraftJSDict
export function convertTextToDraftJSBlocks(text) {
  const sentences = split(text ?? '', '\r\n');

  return {
    blocks: map(sentences, (sentence, i) => ({
      key: `b1ito${padEnd(i, 3, '0')}`,
      text: sentence,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    })),
    entityMap: {},
  };
}

// TODO for some currently unknown reason, this does not work as a replacement for convertTextToDraftJSBlocks
export function convertTextToDraftJSDict(text) {
  const sentences = map(split(text ?? '', '\r\n'), (entry) => <p>{entry}</p>);

  const { contentBlocks, entityMap } = htmlToDraft(renderToStaticMarkup(<span>{sentences}</span>));
  const content = {
    blocks: contentBlocks,
    entityMap,
  };

  return content;
}

export function convertDraftJSToText(blocks) {
  return map(blocks, (block) => (!trim(block.text) && '\n') || block.text).join('\n');
}
