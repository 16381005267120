import { QueryFunction, useQuery } from '@tanstack/react-query';
import { get, forEach, find, findIndex } from 'lodash';
import * as React from 'react';

import { Assignment } from 'app/shared-content-item/interfaces';
import Loading from 'app/shared/components/Loading';
import { useQueryParams } from 'app/shared/hooks';
import { DEFAULT_STALE_TIME } from 'common/api/constants';
import { useEnsureContentItemAssignmentQuery } from 'features/contentitems/hooks/useEnsureContentItemAssignmentQuery';
import { queries } from 'queries';

import {
  Track,
  TrackContentItem,
  TrackItem,
  TrackSectionWithItems,
  TrackSupportedContentItemTypes,
} from '../types';
import { getSupportedContentItemType } from '../utils/getSupportedContentItemType';
import { isItemTrackSection } from '../utils/helpers';

import { useComposableTrackCtx } from './ComposableTrackCtx';

function getUncompletedTrackContentItems(trackItems?: TrackItem[]): TrackContentItem[] {
  const uncompletedTrackContentItems: TrackContentItem[] = [];

  forEach(trackItems, (item) => {
    const userAssignment = get(item, 'content_item.user_assignment');

    if (userAssignment && userAssignment.state !== 'completed') {
      uncompletedTrackContentItems.push(item.content_item);
    }
  });

  return uncompletedTrackContentItems;
}

/**
 * Hydrates the current track node with the latest track data.
 *
 * The track descendants endpoint returns nested tracks as track items,
 * however the data returned is limited, so we need to fetch the full track data.
 */
function useHydratedCurrentTrackNode(currentTrackNode: TrackNode) {
  const currentTrackNodeQuery = queries.content_items.details(currentTrackNode.track.public_id);

  const { data: hydratedCurrentTrackNode, ...restQuery } = useQuery({
    queryKey: currentTrackNodeQuery.queryKey,
    queryFn: currentTrackNodeQuery.queryFn as QueryFunction<Track>,
    staleTime: DEFAULT_STALE_TIME,
    select: (data) => {
      return {
        track: data,
        index: currentTrackNode.index,
      };
    },
  });

  return {
    ...restQuery,
    data: hydratedCurrentTrackNode ?? currentTrackNode,
  };
}

type TrackNode = {
  track: Track;
  index: number;
};

export type TrackContentConsumptionCtxType = {
  /**
   * The current track that is being consumed.
   * In case of nested tracks, this will be the current nested track.
   */
  currentTrackNode: TrackNode;
  isInNestedTrack: boolean;
  currentTrackItem?: TrackItem;
  currentTrackItemContentType?: TrackSupportedContentItemTypes;
  currentSection?: TrackSectionWithItems;
  currentItemAssignment?: Assignment;
};

const defaultCtxValue: TrackContentConsumptionCtxType = {
  currentTrackNode: { track: {} as Track, index: 0 },
  isInNestedTrack: false,
  currentTrackItem: undefined,
  currentTrackItemContentType: undefined,
  currentSection: undefined,
  currentItemAssignment: undefined,
};

const TrackContentConsumptionCtx =
  React.createContext<TrackContentConsumptionCtxType>(defaultCtxValue);

type TrackContentConsumptionProviderProps = {
  children: React.ReactNode;
};

export function TrackContentConsumptionProvider(props: TrackContentConsumptionProviderProps) {
  const { children } = props;

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { rootTrack, nonEmptySectionsAndItemsOrderedList, nonEmptySectionsWithItems } =
    useComposableTrackCtx();

  const currentItem = nonEmptySectionsAndItemsOrderedList[page - 1];
  const currentTrackContentItem = !isItemTrackSection(currentItem)
    ? currentItem.content_item
    : undefined;

  const { data: contentItemWithAssignment } =
    useEnsureContentItemAssignmentQuery(currentTrackContentItem);
  const currentItemAssignment = contentItemWithAssignment?.user_assignment;

  const currentTrackNodeRef = React.useRef<TrackNode>({
    track: rootTrack as Track,
    index: 0,
  });

  const currentTrackNode: TrackNode = React.useMemo(() => {
    if (!currentItem || !currentItem.track_id) {
      return currentTrackNodeRef.current;
    }

    /*
      Find the current nested track
    */
    const currentTrackIdx = findIndex(nonEmptySectionsAndItemsOrderedList, (item) => {
      if (isItemTrackSection(item)) {
        return false;
      }

      return item.content_item.public_id === currentItem.track_id;
    });

    /*
      If the current track is not found in the list,
      it means that the current track is the root track.
    */
    if (currentTrackIdx < 0) {
      currentTrackNodeRef.current = { track: rootTrack, index: 0 };
      return currentTrackNodeRef.current;
    }

    const currentTrack = nonEmptySectionsAndItemsOrderedList[currentTrackIdx] as TrackItem;
    currentTrackNodeRef.current = { track: currentTrack.content_item, index: currentTrackIdx };

    return currentTrackNodeRef.current;
  }, [currentItem, nonEmptySectionsAndItemsOrderedList, rootTrack]);

  const { data: hydratedCurrentTrackNode, status: hydratedCurrentTrackNodeStatus } =
    useHydratedCurrentTrackNode(currentTrackNode);

  const uncompletedLastTrackContentItem = React.useMemo(() => {
    const trackItems = rootTrack.track_items;
    const uncompletedContentItems = getUncompletedTrackContentItems(trackItems);
    const uncompletedLastContentItem =
      uncompletedContentItems.length === 1 ? uncompletedContentItems[0] : undefined;

    return uncompletedLastContentItem;
  }, [rootTrack.track_items]);

  const isInNestedTrack = rootTrack.public_id !== hydratedCurrentTrackNode.track.public_id;

  const ctxValue: TrackContentConsumptionCtxType = React.useMemo(() => {
    const isTrackItem = !isItemTrackSection(currentItem);
    const currentSection = !isTrackItem
      ? find<TrackSectionWithItems>(nonEmptySectionsWithItems, ['id', currentItem.id])
      : undefined;

    return {
      currentTrackNode: hydratedCurrentTrackNode,
      isInNestedTrack,
      currentTrackItem: isTrackItem ? currentItem : undefined,
      currentTrackItemContentType: getSupportedContentItemType(currentTrackContentItem),
      currentSection,
      currentItemAssignment,
    };
  }, [
    hydratedCurrentTrackNode,
    isInNestedTrack,
    currentItem,
    currentItemAssignment,
    currentTrackContentItem,
    uncompletedLastTrackContentItem,
  ]);

  if (hydratedCurrentTrackNodeStatus === 'loading') {
    return <Loading />;
  }

  return (
    <TrackContentConsumptionCtx.Provider value={ctxValue}>
      {children}
    </TrackContentConsumptionCtx.Provider>
  );
}

export function useTrackContentConsumptionCtx() {
  const ctx = React.useContext(TrackContentConsumptionCtx);
  return ctx;
}
