import { normalize, denormalize } from 'normalizr';
import queryString from 'query-string';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { take, cancel, fork, call, put, delay } from 'redux-saga/effects';

import actions from 'entities/actions';
import { toast } from 'notifications/components/NotificationCenter';
import { ApiURLs, fetchURL } from 'services/requests-base';
import {
  STATUS_NOT_REQUESTED,
  STATUS_LOADING,
  STATUS_LOADING_MORE,
  STATUS_DONE,
  STATUS_ERROR,
} from 'shared/constants';
import { useUID } from 'shared/hooks';
import { mergeNormalizedArray } from 'shared/services';
import {
  isArray,
  merge,
  omit,
  includes,
  replace,
  isNil,
  isEmpty,
  get,
  isString,
  reduce,
  size,
  slice,
  join,
  parseInt,
} from 'vendor/lodash';

export { denormalize };

function getReducerKeysForRequest(key) {
  return {
    status: `${key}Status`,
    data: `${key}Data`,
    error: `${key}Error`,
    statusCode: `${key}StatusCode`,
    nextPage: `${key}NextPage`,
    count: `${key}Count`,
    identifier: `${key}Identifier`,
  };
}

export function getFromState(id, state, schema) {
  const { entities } = state;
  return denormalize(id, schema, entities);
}

export function getDataFromState(key, state, schema) {
  const { entities } = state;

  const expectArray = isArray(schema);

  const keys = getReducerKeysForRequest(key);

  let status = STATUS_NOT_REQUESTED;
  if (entities[keys.status]) {
    status = entities[keys.status];
  }

  let nextPage = null;
  if (entities[keys.nextPage]) {
    nextPage = entities[keys.nextPage];
  }

  let count = 0;
  if (entities[keys.count]) {
    count = entities[keys.count];
  }

  let data = expectArray ? [] : {};
  if (!isNil(entities[keys.data])) {
    data = schema ? denormalize(entities[keys.data], schema, entities) : entities[keys.data];
  }

  let error = null;
  if (entities[keys.error]) {
    error = entities[keys.error];
  }

  let statusCode = null;
  if (entities[keys.statusCode]) {
    statusCode = entities[keys.statusCode];
  }

  return {
    status,
    nextPage,
    count,
    data,
    error,
    statusCode,
    identifier: get(entities, keys.identifier, null),
  };
}

function getDebounceByKey() {
  const myDebounceByKeyHash = {};

  // eslint-disable-next-line unicorn/consistent-function-scoping
  function* exec(actionName, saga) {
    while (true) {
      const action = yield take(actionName);

      const { key } = action.payload;

      if (myDebounceByKeyHash[key]) {
        const task = myDebounceByKeyHash[key];
        yield cancel(task);
      }

      // eslint-disable-next-line require-atomic-updates
      myDebounceByKeyHash[key] = yield fork(saga, action);
    }
  }

  return (...args) => fork(exec, ...args);
}

export const debounceByKey = getDebounceByKey();

function getUpdateEntitiesReducer(id, schema, changes) {
  return (state) => {
    let data = denormalize(id, schema, state);
    data = merge(data, changes);

    const normalized = normalize(data, schema);

    return {
      ...state,
      ...mergeNormalizedArray(state, normalized),
    };
  };
}

export function* updateEntitySaga(id, schema, changes) {
  const reducer = getUpdateEntitiesReducer(id, schema, changes);
  yield put(actions.runCustomReducer(reducer));
}

export function* storeEntitiesSaga(action) {
  const { data, schema } = action.payload;

  const reducer = (state) => {
    const normalized = normalize(data, [schema]);

    return {
      ...state,
      ...mergeNormalizedArray(state, normalized),
    };
  };

  yield put(actions.runCustomReducer(reducer));
}

function getSubmitReducer(key, append) {
  const keys = getReducerKeysForRequest(key);

  return (state) => ({
    ...state,
    [keys.nextPage]: null,
    [keys.status]: append ? STATUS_LOADING_MORE : STATUS_LOADING,
  });
}

const extractIdentifies = (url) => {
  const regex = /(\/(?<uuid>[\da-f]{8}\b(?:-[\da-f]{4}){3}-\b[\da-f]{12}))|(\/(?<id>\d+))/; // search by /<uuid> or /<id>
  const matches = url.match(regex);

  if (matches) {
    const publicId = get(matches, 'groups.uuid', null);
    const id = parseInt(get(matches, 'groups.id', '0')) || null;
    return { publicId, id };
  }
  return { publicId: null, id: null };
};

function getSuccessReducer(key, response, schema, append, reducerOptions = {}, url) {
  const keys = getReducerKeysForRequest(key);

  const expectArray = isArray(schema);

  let { data } = response;
  if (expectArray) {
    data = data.results || data;
  }

  let normalized = null;
  if (schema || (isArray(schema) && !isEmpty(schema))) {
    normalized = normalize(data, schema);
  }

  const { skipEntityUpdate } = reducerOptions;

  return (state) => {
    let resultData = normalized ? normalized.result : data;
    if (append) {
      resultData = [...state[keys.data], ...resultData];
    }
    return {
      ...state,
      ...(!skipEntityUpdate && normalized && mergeNormalizedArray(state, normalized)),
      [keys.status]: STATUS_DONE,
      [keys.data]: resultData,
      [keys.error]: null,
      [keys.statusCode]: get(response, 'statusCode'),
      [keys.nextPage]: expectArray ? response.data.next : null,
      [keys.count]: expectArray ? response.data.count : null,
      [keys.identifier]: extractIdentifies(url), // this is useful to be used in the status changed callback
    };
  };
}

function getErrorReducer(key, response) {
  const keys = getReducerKeysForRequest(key);

  return (state) => ({
    ...state,
    [keys.status]: STATUS_ERROR,
    [keys.data]: null,
    [keys.error]: response.data,
    [keys.statusCode]: get(response, 'statusCode'),
    [keys.nextPage]: null,
  });
}

const OVERRIDE_ACTIONS = [
  'ASSESSMENT/RETRIEVE_LIST',
  'ASSIGNMENT/LIST',
  'ASSIGNMENT/LIST_EMAILS',
  'CONTENT/RETRIEVE_LIST_RQL',
  'CONTENT_ASSIGNMENT/RETRIEVE_RQL',
  'ENROLLMENT/RETRIEVE_LIST',
  'ENROLLMENT/LIST_EMAILS',
  'EVENT/RETRIEVE_LIST_RQL',
  'EVENT_TYPE/RETRIEVE_LIST_RQL',
  'QUESTION/RETRIEVE_LIST',
  'SCHEDULED_TRACK/RETRIEVE_LIST',
  'USER_DATA/RETRIEVE_LIST_RQL',
  'LOAD_MORE',
];

const parseURL = (rawURL, rawOptions, actionType) => {
  // It only converts the request if the URL is longer than 1000 characters and the current method is a GET.
  if (
    size(rawURL) < 5000 ||
    get(rawOptions, 'method', 'GET') !== 'GET' ||
    !includes(OVERRIDE_ACTIONS, actionType)
  ) {
    return { url: rawURL, options: rawOptions };
  }
  const url = new URL(rawURL, window.location.origin);
  const options = {
    ...rawOptions,
    method: 'POST',
    body: JSON.stringify({ query_string: join(slice(url.search, 1), '') }),
    headers: { ...get(rawOptions, 'headers'), 'PlusPlus-Http-Method-Override': 'GET' },
  };
  return { url: `${url.pathname}${url.hash}`, options };
};

function* requestSaga(key, schema, rawURL, actionType, append, rawOptions, reducerOptions = {}) {
  const { url, options } = parseURL(rawURL, rawOptions, actionType);
  let response = null;

  let fetchOptions;
  let asyncJob = null;

  if (!isNil(options)) {
    fetchOptions = omit(options, 'asyncJob');
    asyncJob = get(options, 'asyncJob');
  }

  const submitReducer = getSubmitReducer(key, append);
  yield put(actions.runCustomReducer(submitReducer));

  try {
    response = yield call(fetchURL, url, fetchOptions);

    let asyncJobResponse = null;

    if (asyncJob && response.data) {
      asyncJobResponse = yield call(
        fetchURL,
        ApiURLs['api_async_jobs:retrieve']({ public_id: get(response.data, 'async_job_id') })
      );
      while (asyncJobResponse.data?.status === 'pending') {
        yield delay(1000);
        asyncJobResponse = yield call(
          fetchURL,
          ApiURLs['api_async_jobs:retrieve']({ public_id: get(response.data, 'async_job_id') })
        );
      }
      if (
        asyncJobResponse.data?.status === 'completed' ||
        asyncJobResponse.data?.status === 'failed'
      ) {
        response.data.asyncJobOutputParams = get(asyncJobResponse.data, 'output_params');
      }
    }

    const successReducer = getSuccessReducer(
      key,
      response,
      schema,
      append,
      reducerOptions.success,
      url
    );
    yield put(actions.runCustomReducer(successReducer));
    if (includes(actionType, 'SUBMIT')) {
      yield put({
        type: replace(actionType, 'SUBMIT', 'SUCCESS'),
        payload: response.data,
      });
    }
  } catch (e) {
    const errorReducer = getErrorReducer(key, e);
    yield put(actions.runCustomReducer(errorReducer));
    response = e;
    if (includes(actionType, 'SUBMIT')) {
      yield put({
        type: replace(actionType, 'SUBMIT', 'FAILURE'),
        payload: { _error: e.data },
      });
    }
  }
  return response;
}

export function* getSaga(key, schema, url, actionType, append) {
  return yield requestSaga(key, schema, url, actionType, append);
}

export function* patchSaga(key, schema, url, body, actionType, asyncJob, reducerOptions) {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
    asyncJob,
  };

  return yield requestSaga(key, schema, url, actionType, false, options, reducerOptions);
}

export function* putSaga(key, schema, url, body, actionType, asyncJob, reducerOptions) {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    asyncJob,
  };
  return yield requestSaga(key, schema, url, actionType, false, options, reducerOptions);
}

export function* postSaga(key, schema, url, body, actionType, asyncJob) {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    asyncJob,
  };

  return yield requestSaga(key, schema, url, actionType, false, options);
}

export function* deleteSaga(key, schema, url, actionType, asyncJob) {
  const options = {
    method: 'DELETE',
    asyncJob,
  };

  return yield requestSaga(key, schema, url, actionType, false, options);
}

// The parameter idKey can be change because we are also supporting the public_id param
export function getRetrieveSaga(urlName, schema, idKey = 'pk') {
  function* saga(action) {
    const { key, id, params } = action.payload;

    const baseUrl = !isNil(id) ? ApiURLs[urlName]({ [idKey]: id }) : ApiURLs[urlName]();
    // If it's a string, it's because the data has already been processed before and doesn't need to be formatted again.
    const formattedQueryString = isString(params) ? params : queryString.stringify(params);

    const url = `${baseUrl}?${formattedQueryString}`;
    return yield getSaga(key, schema, url, action.type, false);
  }

  return saga;
}

export function getLoadMoreSaga(schema) {
  function* saga(action) {
    const { key, url } = action.payload;
    if (!isNil(url)) {
      return yield getSaga(key, [schema], url, action.type, true);
    }
    return null;
  }

  return saga;
}

export function getUpdateSaga(urlName, schema, idKey = 'pk', asyncJob = false, sagaFn = patchSaga) {
  function* saga(action) {
    const { key, id, body, reducerOptions } = action.payload;

    const url = ApiURLs[urlName]({ [idKey]: id });
    return yield sagaFn(key, schema, url, body, action.type, asyncJob, reducerOptions);
  }

  return saga;
}

// This Saga will update the chosen schema entity and an list of attributes of
// another content that is pointing to that same object.
// For example, if a ContentItem has an Assignment, the value of the assignment
// field of the ContentItem in the Redux State is just the Assignment ID.
// The content of the assignment is stored in the assignments entity at the
// Redux State. So if the assignment changes to another assignment, we should
// update the Assignment ID in the ContentItem too. This also is necessary in cases
// where the ContentItem does not have an assignment and we should add a new one (null -> ID).
export function getUpdateSideEffectSaga(urlName, schema, fieldNames, sagaFn = getCreateSaga) {
  function* saga(action) {
    // NOTE: The content id and content schema should be defined in the body,
    // this information will be not sent to the endpoint.
    const { contentId, contentSchema } = action.payload.body;
    action.payload.body = omit(action.payload.body, ['contentId', 'contentSchema']);

    const request = sagaFn(urlName, schema);
    const response = yield request(action);

    if (!response.data.error) {
      const updateData = reduce(
        fieldNames,
        (acc, fieldName) => ({ ...acc, [fieldName]: response.data?.id }),
        {}
      );
      yield updateEntitySaga(contentId, contentSchema, updateData);
    }
  }
  return saga;
}

export function getPutSaga(urlName, schema, idKey = 'pk', asyncJob = false) {
  return getUpdateSaga(urlName, schema, idKey, asyncJob, putSaga);
}

export function getUpdateAndRetrieveSaga(
  updateUrlName,
  retrieveUrlName,
  schema,
  idKey = 'pk',
  asyncJob = false
) {
  function* saga(action) {
    const { key, id, body, reducerOptions } = action.payload;

    const updateUrl = ApiURLs[updateUrlName]({ [idKey]: id });
    const retrieveUrl = ApiURLs[retrieveUrlName]({ [idKey]: id });

    yield patchSaga(key, schema, updateUrl, body, action.type, asyncJob, reducerOptions);

    return yield getSaga(key, schema, retrieveUrl, action.type);
  }

  return saga;
}

export function getUpdateSagaForCustomerSettings(urlName, schema, asyncJob = false) {
  function* saga(action) {
    const { key, settingsContext, body, reducerOptions } = action.payload;

    const url = ApiURLs[urlName]({ settings_context: settingsContext });

    return yield patchSaga(key, schema, url, body, action.type, asyncJob, reducerOptions);
  }

  return saga;
}

export function getFilterSaga(urlName, schema, idKey = 'pk', useLoadmMore = true) {
  function* saga(action) {
    const { key, id, filters, options } = action.payload;

    const baseUrl = id ? ApiURLs[urlName]({ [idKey]: id }) : ApiURLs[urlName]();
    // The saga can be used passing the filter object or the querystring itself.
    const formattedQueryString = isString(filters)
      ? filters
      : queryString.stringify(omit(filters, ['id']));

    const url = `${baseUrl}?${formattedQueryString}`;

    let useSchema = !isNil(schema) ? [schema] : null;
    if (options?.skipSchema) {
      useSchema = null;
    }

    let response = yield getSaga(key, useSchema, url, action.type);
    let nextPage = get(response, 'data.next');

    if (get(options, 'fetchAll')) {
      const loadMoreSaga = getLoadMoreSaga(schema);
      while (!isNil(nextPage)) {
        if (useLoadmMore) {
          response = yield loadMoreSaga(actions.loadMore({ key, url: nextPage }));
        } else {
          response = yield getSaga(key, useSchema, nextPage, action.type);
        }
        nextPage = get(response, 'data.next');
      }
    }

    return response;
  }

  return saga;
}

// This saga is used for fetching nested resources, for example:
// resource/{public_id}/resource_field/{id}/
// endpoints/integrations/people-integration-rule/13b6064e-aa40-48ac-b58e-00fbc6fc79dd/logs/45/
export function getNestedFilterSaga(urlName, schema) {
  function* saga(action) {
    const { key, public_id, id, filters, options } = action.payload;

    const baseUrl = ApiURLs[urlName]({ public_id, id });
    const formattedQueryString = queryString.stringify(omit(filters, ['id']));
    const url = `${baseUrl}?${formattedQueryString}`;

    let useSchema = !isNil(schema) ? [schema] : null;
    if (options?.skipSchema) useSchema = null;

    let response = yield getSaga(key, useSchema, url, action.type);
    let nextPage = get(response, 'data.next');

    if (get(options, 'fetchAll')) {
      const loadMoreSaga = getLoadMoreSaga(schema);
      while (!isNil(nextPage)) {
        response = yield loadMoreSaga(actions.loadMore({ key, url: nextPage }));
        nextPage = response.data.next;
      }
    }

    return response;
  }

  return saga;
}

export function getExportSaga(urlName, schema) {
  function* saga(action) {
    const { key, id, filters: body } = action.payload;

    const url = id ? ApiURLs[urlName]({ pk: id }) : ApiURLs[urlName]();

    return yield postSaga(key, !isNil(schema) ? [schema] : null, url, body, action.type);
  }

  return saga;
}

export function getCreateSaga(urlName, schema, idKey = 'pk', asyncJob = false, sagaFn = postSaga) {
  function* saga(action) {
    const { key, id, body } = action.payload;
    const url = id ? ApiURLs[urlName]({ [idKey]: id }) : ApiURLs[urlName]();
    return yield sagaFn(key, schema, url, body, action.type, asyncJob);
  }

  return saga;
}

// TODO remove schema
export function getDeleteSaga(urlName, schema, idKey = 'pk', asyncJob = false) {
  function* saga(action) {
    const { key, id } = action.payload;

    const url = ApiURLs[urlName]({ [idKey]: id });
    return yield deleteSaga(key, schema, url, action.type, asyncJob);
  }

  return saga;
}

export function getUpdateSagaForEventEnrollment(urlName, schema) {
  function* saga(action) {
    const { key, id, userId, body } = action.payload;

    const url = ApiURLs[urlName]({ public_id: id, user_pk: userId });
    return yield patchSaga(key, schema, url, body, action.type);
  }

  return saga;
}

export function getUpdateSagaForUserEnrollment(urlName, schema) {
  function* saga(action) {
    const { key, id, userId, body } = action.payload;

    const url = ApiURLs[urlName]({ pk: id, user_pk: userId });
    return yield patchSaga(key, schema, url, body, action.type);
  }

  return saga;
}

/**
 * @param {Object} options -
 *  - key: string to be used as redux key. Will be generated if not present
 *  - schema: normalizr schema to denormalize the data
 *  - loadMoreAction: action to be called to handle paginated requests
 *
 * @returns {Array<*>} An array where:
 *  - first: request function
 *  - second: state object
 *  - third: load more request function
 *
 */

export const useEntities = (actionCreator, statusChanged, options = {}) => {
  const { schema, key, loadMoreAction } = options;

  const generatedUIDKey = useUID();
  const [requested, setRequested] = useState(false);
  const stateKey = key || generatedUIDKey;
  const dispatch = useDispatch();
  const state = useSelector((state) => getDataFromState(stateKey, state, schema));

  useEffect(() => {
    if (requested && statusChanged) statusChanged(state);
  }, [requested, state.status]);

  const request = useCallback(
    (...params) => {
      setRequested(true);
      dispatch(actionCreator(stateKey, ...params));
    },
    [actionCreator, stateKey]
  );

  const loadMoreRequest = (...params) => {
    if (loadMoreAction) {
      dispatch(loadMoreAction(stateKey, state.nextPage, ...params));
    }
  };

  return [request, state, loadMoreRequest];
};

const defaultArchiveLabels = {
  ARCHIVE_ACTION_LABEL: 'Archiving...',
  ARCHIVE_SUCCESS_LABEL: 'Content archived sucessfully.',
  UNARCHIVE_ACTION_LABEL: 'Unarchiving...',
  UNARCHIVE_SUCCESS_LABEL: 'Content restored sucessfully.',
};

/**
 * DEPRECATED
 * See `useArchiveRestore` for the newest implementation.
 * Use this hook only for content items using the deprecated endpoints from `apiinternal`.
 *
 * Provides actions and statuses regarding archive and unarchive actions for content items
 * @param {*} updateAction the `redux` action
 * @param {*} labels optional parameter to override the default labels `defaultArchiveLabels` values
 * @returns an object with the respective actions and request statuses
 */
export const useArchiveUnarchive = (updateAction, labels = defaultArchiveLabels) => {
  const [update, { status }] = useEntities(updateAction);
  const archiveItem = (itemId, params) => {
    toast.success(labels.ARCHIVE_ACTION_LABEL);
    update(itemId, {
      is_archived: true,
      ...params,
    });
  };

  const unarchiveItem = (itemId, params) => {
    toast.success(labels.UNARCHIVE_ACTION_LABEL);
    update(itemId, {
      is_archived: false,
      ...params,
    });
  };

  // Ideally, remove this - check why it was added in first place
  if (status === STATUS_DONE) window.location.reload();
  if (status === STATUS_ERROR) toast.error('Please try again later.');
  const isUpdating = status === STATUS_LOADING;

  return { archiveItem, unarchiveItem, isUpdating, status };
};

/**
 * Provides actions and statuses regarding archive and restore actions for content items
 * @param {*} entityActions an object with the redux actions of the entity
 * @param {*} successCallback function to be called on success
 * @param {*} labels labels used by the toasts. Default: see `defaultArchiveLabels`
 * @returns an object with the respective actions and request statuses
 */
export const useArchiveRestore = (
  entityActions,
  successCallback,
  labels = defaultArchiveLabels
) => {
  const { archive: archiveAction, restore: restoreAction } = entityActions;
  const [archive, { status: archiveStatus }] = useEntities(archiveAction, ({ status }) => {
    if (status === STATUS_DONE) {
      toast.success(labels.ARCHIVE_SUCCESS_LABEL);
      successCallback?.();
    }
  });
  const archiveItem = (itemId) => {
    archive(itemId);
  };

  const [restore, { status: restoreStatus }] = useEntities(restoreAction, ({ status }) => {
    if (status === STATUS_DONE) {
      toast.success(labels.UNARCHIVE_SUCCESS_LABEL);
      successCallback?.();
    }
  });
  const restoreItem = (itemId) => {
    restore(itemId);
  };

  if (includes([archiveStatus, restoreStatus], STATUS_ERROR))
    toast.error('The operation failed. Please try again later.');
  const isUpdating = archiveStatus === STATUS_LOADING || restoreStatus === STATUS_LOADING;

  return { archiveItem, archiveStatus, restoreItem, restoreStatus, isUpdating };
};
