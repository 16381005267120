// import { useEffect } from 'react';

import { useParentSettings } from 'app/settings/hooks';
// import { EDIT_ASSESSMENT_PERMISSION } from 'app/shared/permissions';
import Loading from 'app/shared/components/Loading';
import { LEARNING_TYPES } from 'catalog/constants';
// import actions from 'entities/actions';
// import { assessmentSchema } from 'entities/schema';
// import { useEntities } from 'entities/utils';
// import Page404 from 'scenes/404';
// import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { useCreateContentFromChannelParams } from 'features/channels/hooks/useCreateContentFromChannelParams';
import { mapRoute } from 'services/requests';
// import Loading from 'shared/components/Loading';
// import { STATUS_DONE, HTTP_404_NOT_FOUND } from 'shared/constants';
import {
  useLabels,
  useOriginRoute,
  useSetDetailedObject,
  // useQueryParams,
} from 'shared/hooks';
import { toLower } from 'vendor/lodash';

import AssessmentFormPage from './AssessmentFormPage';

const AssessmentCreate = () => {
  const parentSettingsFlat = useParentSettings('assessment/form', 'flat');
  const { label_assessment: labelAssessment, label_assessment_plural: labelAssessmentPlural } =
    useLabels();
  // const { cloned: duplicatedAssessmentPublicId } = useQueryParams();
  const isDuplicating = false; // Boolean(duplicatedAssessmentPublicId);

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.assessments
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  // Duplicate assessment: https://app.asana.com/0/693011033927822/1203947639446539
  // const [fetchAssessment, { data: clonedAssessment, status, statusCode }] = useEntities(
  //   actions.assessment.retrieveDetails,
  //   ({ data: clonedAssessment, status }) => {
  //     if (status === STATUS_DONE && !includes(clonedAssessment.permissions, EDIT_ASSESSMENT_PERMISSION)) {
  //       permissionDeniedRedirectAlert();
  //     }
  //   },
  //   { schema: assessmentSchema }
  // );

  useSetDetailedObject({ id: null }, 'assessments', []);

  // useEffect(() => {
  //   if (duplicatedAssessmentPublicId) {
  //     fetchAssessment(duplicatedAssessmentPublicId);
  //   }
  // }, [fetchAssessment, duplicatedAssessmentPublicId]);

  // if (duplicatedAssessmentPublicId && statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  // if (duplicatedAssessmentPublicId && status !== STATUS_DONE) return <Loading />;

  if (isFromChannel && isLoadingChannelData) {
    return <Loading />;
  }

  const initialValuesParams: Record<string, any> = {
    defaultSettings: parentSettingsFlat,
    // clonedAssessment: clonedAssessment ? clonedAssessment : null;
  };

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  }

  return (
    <AssessmentFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelAssessment}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelAssessment}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelAssessmentPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelAssessment)}`,
        },
      ]}
    />
  );
};

export default AssessmentCreate;
