import PropTypes from 'prop-types';
import React from 'react';

import SettingShortTemplateField from './SettingShortTemplateField';

const SettingMediumTemplateField = (props) => {
  return <SettingShortTemplateField multiline {...props} label="" />;
};

SettingMediumTemplateField.defaultProps = {
  multiline: true,
  rows: 3,
};

SettingMediumTemplateField.propTypes = {
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default SettingMediumTemplateField;
