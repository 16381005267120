import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import { Button } from 'app/shared';
import Icon from 'app/shared/components/Icon';
import { queries } from 'queries';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import CardList from 'shared-cards/components/CardList';
import { OptimalContentItemCard } from 'shared/components/Card';
import HR from 'shared/components/HR';
import Loading from 'shared/components/Loading';
import { useCurrentUser, useStringFilters } from 'shared/hooks';
import { buildFlexibleTagsFieldNameList } from 'topics/services';
import { isEmpty, pickBy, includes, get, size, slice } from 'vendor/lodash';
import { Box, Stack, Typography, useMediaQuery } from 'vendor/mui';

function useCatalogDiscoverFilters(selectedFilters) {
  const currentUser = useCurrentUser();

  const filterNames = [
    'q',
    'main_topics',
    'topics',
    ...buildFlexibleTagsFieldNameList(currentUser),
  ];

  const getShortenedFilters = () =>
    pickBy(selectedFilters, (value, key) => !isEmpty(value) && includes(filterNames, key));

  const filters = getShortenedFilters();
  const filtersToString = useStringFilters(filters, '&');

  return { hasFilters: !isEmpty(filters), filters, filtersToString };
}

function useMaxNumberOfItemsForBreakpoint() {
  const matchesSmOrLower = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const matchesMdOrLower = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const matchesLgOrLower = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  if (matchesSmOrLower) {
    return 4;
  }

  if (matchesMdOrLower) {
    return 6;
  }

  if (matchesLgOrLower) {
    return 8;
  }

  return 12;
}

const CatalogDiscoverSection = ({ selectedFilters }) => {
  const history = useHistory();

  const { hasFilters, filters, filtersToString } = useCatalogDiscoverFilters(selectedFilters);

  const maxNumberOfItems = useMaxNumberOfItemsForBreakpoint();

  const { data, isLoading } = useQuery({
    ...queries.catalog.discover({ ...filters, page_size: 12 }),
    enabled: hasFilters,
  });

  // The discovery section appears if a user searches for an event (filtering and keywork search)
  if (!hasFilters) return null;

  const handleNavigateViewAll = () => {
    let url = `${mapRoute('unifiedCatalogList')}?`;

    if (!isEmpty(filtersToString)) {
      url = `${url}${filtersToString}`;
    }

    history.push(url);
  };

  if (isLoading) {
    return <Loading />;
  }

  const contentItems = get(data, 'results', []);
  const slicedContentItems = slice(contentItems, 0, maxNumberOfItems);
  const totalItems = get(data, 'count', 0);
  const displayViewAll = totalItems > size(slicedContentItems);

  return (
    <>
      <HR color={colors.neutral200} />

      <Box>
        <Stack mb="24px" direction="row" spacing={2}>
          <Typography variant="h5">Content items matching your search criteria</Typography>

          {displayViewAll && (
            <Button
              variant="text"
              startIcon={<Icon name="list-view" />}
              size="small"
              onClick={handleNavigateViewAll}
            >
              View All {totalItems}
            </Button>
          )}
        </Stack>

        <CardList
          items={slicedContentItems}
          renderItem={(item) => (
            <OptimalContentItemCard key={`${item.content_type}_${item.id}`} contentItem={item} />
          )}
        />
      </Box>
    </>
  );
};

CatalogDiscoverSection.propTypes = {
  selectedFilters: PropTypes.object,
};

export default CatalogDiscoverSection;
