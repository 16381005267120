import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { OldIcon } from 'shared/components/Icon';
import { useLabels } from 'shared/hooks';

const MentorshipStats = styled.div`
  border-top: 1px solid ${colors.neutral100};
  padding: 16px;
  color: ${colors.neutral900};
`;

const StatsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Stat = styled.div``;

const StyledIcon = styled(OldIcon)`
  margin-right: 8px;
`;

const MenteeStats = (props) => {
  const { profileData } = props;
  const attendeeName = profileData.name?.split(' ')[0];
  const hoursOfMentorship =
    Math.round(moment.duration(profileData.mentorship_hours_as_attendee).asHours() * 100) / 100;
  const { label_mentorship_sessions_attendee_title_single: attendeeLabel } = useLabels();

  if (
    !(
      profileData.mentorship_current_mentors_count > 0 ||
      profileData.mentorship_former_mentors_count > 0
    ) &&
    !profileData.mentorship_attended_sessions_count > 0 &&
    !profileData.mentorship_hours_as_attendee
  ) {
    return (
      <MentorshipStats>
        <StatsTitle>
          {attendeeName} as {attendeeLabel}
        </StatsTitle>
        <div>We don&apos;t have any stats for this {attendeeLabel.toLowerCase()} yet.</div>
      </MentorshipStats>
    );
  }

  return (
    <MentorshipStats>
      <StatsTitle>
        {attendeeName} as {attendeeLabel}
      </StatsTitle>
      {profileData.mentorship_hours_as_attendee && (
        <Stat>
          <StyledIcon name="clock" color={colors.neutral900} />
          {hoursOfMentorship} {hoursOfMentorship === '1' ? 'hour' : 'hours'} of mentorship taken
        </Stat>
      )}
      {profileData.mentorship_attended_sessions_count !== 0 && (
        <Stat>
          <StyledIcon name="calendar-blank" color={colors.neutral900} />
          {profileData.mentorship_attended_sessions_count} mentorship
          {profileData.mentorship_attended_sessions_count === 1 ? ' session' : ' sessions'} attended
        </Stat>
      )}
      {(profileData.mentorship_current_mentors_count > 0 ||
        profileData.mentorship_former_mentors_count > 0) && (
        <Stat>
          <StyledIcon name="account" color={colors.neutral900} />
          {profileData.mentorship_current_mentors_count} current{' '}
          {profileData.mentorship_current_mentors_count === 1 ? 'mentor' : 'mentors'}. •{' '}
          {profileData.mentorship_former_mentors_count} former{' '}
          {profileData.mentorship_former_mentors_count === 1 ? 'mentor' : 'mentors'}.
        </Stat>
      )}
    </MentorshipStats>
  );
};

MenteeStats.propTypes = {
  profileData: PropTypes.object,
};

export default MenteeStats;
