import PropTypes from 'prop-types';
import React from 'react';

import SliderInput from 'inputs/components/SliderInput';

const SliderField = (props) => {
  const { meta, input, ...inputProps } = props;
  return <SliderInput {...input} {...inputProps} />;
};

SliderField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default SliderField;
