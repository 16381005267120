import React from 'react';

import actions from 'entities/actions';
import { map } from 'vendor/lodash';

import LazyDropdownFilter from './LazyDropdownFilter';

const UserFilter = (props) => {
  const formatOptions = (options) =>
    map(options, (option) => ({ name: option.display_name, value: option.value }));

  return (
    <LazyDropdownFilter
      actionFunc={actions.userData.retrieveList}
      optionsFormatter={formatOptions}
      {...props}
    />
  );
};

export default UserFilter;
