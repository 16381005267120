import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { includes } from 'vendor/lodash';

const EventDeleteModal = ({ event, handleClose }) => {
  const history = useHistory();

  const { trackActivity } = useMetrics();

  const handleStatusChange = ({ status }) => {
    if (status === STATUS_DONE) {
      trackActivity(METRICS_ACTIVITIES.ENTITY_DELETE, {
        entityType: 'event',
        entityId: event.id,
      });
      history.push(mapRoute('eventsList'));
      handleClose();
      toast.success('Event deleted successfully');
    } else if (status === STATUS_ERROR) {
      toast.error('Error', 'Please try again later');
    }
  };

  const [deleteEvent, { status: deleteStatus }] = useEntities(
    actions.event.remove,
    handleStatusChange
  );

  const handleDeleteClick = () => deleteEvent(event.public_id);

  return (
    <Modal handleClose={handleClose} title="Delete Event">
      <ModalBody>
        <Text block size="h5" colors={colors.neutral900}>
          You&#39;re about to delete &quot;{event.name}&quot;.
        </Text>
        <Text block size="h5" colors={colors.neutral900}>
          Are you sure you want to do this?
        </Text>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={handleDeleteClick}
          disabled={includes([STATUS_LOADING, STATUS_ERROR], deleteStatus)}
        >
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

EventDeleteModal.propTypes = {
  event: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EventDeleteModal;
