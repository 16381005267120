import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '../TextInput';

const DatePickerInput = ({ ...props }) => {
  const { renderInput, label, inputProps, ...remainingProps } = props;
  return (
    <DatePicker
      label={label}
      inputProps={{ 'aria-label': label, ...inputProps }}
      {...remainingProps}
      renderInput={renderInput}
    />
  );
};

DatePickerInput.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
  renderInput: PropTypes.func,
};

DatePickerInput.defaultProps = {
  renderInput: (params) => <TextInput {...params} />,
};

export default DatePickerInput;
