import { normalize } from 'normalizr';
import queryString from 'query-string';
import { call, put, takeLatest } from 'redux-saga/effects';

import entitiesActions from 'entities/actions';
import { ApiURLs, fetchURL } from 'services/requests-base';
import { concat } from 'vendor/lodash';

import actions from './actions';
import { enrollmentsEventSchema, enrollmentsEnrollmentSchema } from './schema';

export function* events() {
  const url = ApiURLs['api:events']();

  try {
    let response = yield call(fetchURL, url);
    let { results } = response.data;

    while (response.data.next) {
      response = yield call(fetchURL, response.data.next);
      results = concat(results, response.data.results);
    }

    const normalized = normalize(results, [enrollmentsEventSchema]);

    yield put(entitiesActions.updateEntities(normalized));

    yield put(
      actions.eventsFetchRequestSuccess({
        results: normalized.result,
        response: response.data,
      })
    );
  } catch (e) {
    yield put(actions.eventsFetchRequestFailure({ _error: e.data }));
  }
}

export function* enrollments(action) {
  const filters = action.payload;

  let url = ApiURLs['api_enrollments:filter']();
  if (filters) {
    const formattedQueryString = queryString.stringify(filters);
    url = `${url}?${formattedQueryString}`;
  }

  try {
    let response = yield call(fetchURL, url);
    let { results } = response.data;

    while (response.data.next) {
      response = yield call(fetchURL, response.data.next);
      results = concat(results, response.data.results);
    }

    const normalized = normalize(results, [enrollmentsEnrollmentSchema]);

    yield put(entitiesActions.updateEntities(normalized));

    yield put(
      actions.enrollmentsFetchRequestSuccess({
        results: normalized.result,
        response: response.data,
      })
    );
  } catch (e) {
    yield put(actions.enrollmentsFetchRequestFailure({ _error: e.data }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.eventsFetchRequestSubmit.toString(), events);
  yield takeLatest(actions.enrollmentsFetchRequestSubmit.toString(), enrollments);
}
