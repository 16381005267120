import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import CheckboxField from 'inputs/components/CheckboxField';
import CheckboxInput from 'inputs/components/CheckboxInput';
import TextInput from 'inputs/components/TextInput';
import { FormFieldGroup } from 'shared/components/Form';
import { useFormSelector, useToggles } from 'shared/hooks';
import { toLower } from 'vendor/lodash';

const CompletionArea = styled.div`
  display: flex;
  margin: 0 0 -16px 0;
`;

const ValueFieldContainer = styled.div`
  margin-bottom: 1em;
`;

const CompletionSection = ({ form, labelContentType, change, optionallyCompletable, renderOptionalSection }) => {
  const isCompletable = useFormSelector(form, 'is_completable') || !optionallyCompletable;
  const timeToComplete = useFormSelector(form, 'time_to_complete');
  const timeToRetake = useFormSelector(form, 'time_to_retake');

  // Handles interactions with the time duration toggles
  const [hasTimeToComplete, toggleTimeToComplete] = useState(!!timeToComplete);
  const [previousTimeToComplete, setPreviousTimeToComplete] = useState(timeToComplete);
  const [hasTimeToRetake, toggleTimeToRetake] = useState(!!timeToRetake);
  const [previousTimeToRetake, setPreviousTimeToRetake] = useState(timeToRetake);

  const onDurationDaysChange = (name, input) => {
    const { value } = input.target;
    if (value && value > 0) {
      change(name, moment.duration(value, 'days').toISOString());
    } else {
      change(name, null);
    }
  };

  useEffect(() => {
    if (hasTimeToComplete) {
      change('time_to_complete', previousTimeToComplete || 'P30D');
    } else {
      setPreviousTimeToComplete(timeToComplete);
      change('time_to_complete', null);
    }
  }, [hasTimeToComplete]);

  useEffect(() => {
    if (hasTimeToRetake) {
      change('time_to_retake', previousTimeToRetake || 'P365D');
    } else {
      setPreviousTimeToRetake(timeToRetake);
      change('time_to_retake', null);
    }
  }, [hasTimeToRetake]);

  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();

  return (
    <>
      {optionallyCompletable && (
        <FormFieldGroup>
          <Field
            name="is_completable"
            label="Completable"
            infoText={`Allow people to Save, Mark as completed, and Rate this ${toLower(
              labelContentType
            )}.`}
            labelSize="h5"
            labelWeight="medium"
            component={CheckboxField}
            onChange={(input) => toggleTimeToComplete(input.target.checked)}
          />
        </FormFieldGroup>
      )}
      {isCompletable && (
        <>
          {renderOptionalSection?.()}
          <FormFieldGroup>
            <CheckboxInput
              label="Time to complete"
              infoText={`Turn on to set the number of days people have to complete this ${toLower(
                labelContentType
              )} after it is assigned to them. This value determines the default due date.`}
              labelSize="h5"
              labelWeight="medium"
              checked={hasTimeToComplete}
              onChange={() => toggleTimeToComplete(!hasTimeToComplete)}
            />
          </FormFieldGroup>
          {hasTimeToComplete && (
            <FormFieldGroup>
              <CompletionArea>
                <ValueFieldContainer>
                  <Field component="input" type="hidden" name="time_to_complete" />
                  <TextInput
                    type="number"
                    label="Days"
                    value={timeToComplete ? moment.duration(timeToComplete).asDays() : ''}
                    inputProps={{
                      min: 1,
                      'aria-label': 'Days to complete',
                    }}
                    onChange={(input) => onDurationDaysChange('time_to_complete', input)}
                  />
                </ValueFieldContainer>
              </CompletionArea>
            </FormFieldGroup>
          )}
          {devToggleContentAssignment2 && (
            <>
              <FormFieldGroup>
                <CheckboxInput
                  label="Time to expiration"
                  infoText={`Turn on to set the number of days until the assignments to this ${toLower(
                    labelContentType
                  )} expire, after which they can be implicitly reassigned. This value determines the default expiration date relative to the assignment date.`}
                  labelSize="h5"
                  labelWeight="medium"
                  checked={hasTimeToRetake}
                  onChange={() => toggleTimeToRetake(!hasTimeToRetake)}
                />
              </FormFieldGroup>
              {hasTimeToRetake && (
                <FormFieldGroup>
                  <CompletionArea>
                    <ValueFieldContainer>
                      <Field component="input" type="hidden" name="time_to_retake" />
                      <TextInput
                        type="number"
                        label="Days"
                        value={timeToRetake ? moment.duration(timeToRetake).asDays() : ''}
                        inputProps={{
                          min: 1,
                          'aria-label': 'Days to retake',
                        }}
                        onChange={(input) => onDurationDaysChange('time_to_retake', input)}
                      />
                    </ValueFieldContainer>
                  </CompletionArea>
                </FormFieldGroup>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

CompletionSection.propTypes = {
  form: PropTypes.string,
  labelContentType: PropTypes.string,
  change: PropTypes.func,
  optionallyCompletable: PropTypes.bool,
  renderOptionalSection: PropTypes.func,
};

CompletionSection.defaultProps = {
  optionallyCompletable: false,
};

export default CompletionSection;