import React from 'react';

import Button from 'shared/components/Button';

import UserAddFlow from './UserAddFlow';

export class UserAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.userAddModalManager = React.createRef();
  }

  openModal = (e) => {
    e.nativeEvent.preventDefault();
    this.userAddModalManager.current.startFlow();
  };

  render = () => (
    <React.Fragment>
      <Button onClick={this.openModal} size="small" {...this.props}>
        Add a Person
      </Button>
      <UserAddFlow ref={this.userAddModalManager} />
    </React.Fragment>
  );
}

export default UserAddButton;
