import MuiTooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const Bubble = ({ children, renderPopoverContent, ...props }) => {
  return (
    <MuiTooltip
      title={renderPopoverContent}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            maxWidth: 'none',
            boxShadow: 2,
            color: 'black',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: 'white',
              border: '1px solid #929292',
            },
          },
        },
      }}
      {...props}
    >
      {/*
        The Tooltip needs to respond to mouse events on its children components,
        so wrapping the children with a div guarantees that.
        See https://stackoverflow.com/questions/48930334/mui-next-tooltip-does-not-show-on-hover
      */}
      <div>{children}</div>
    </MuiTooltip>
  );
};

Bubble.defaultProps = {
  placement: 'top-start',
  /*
    disableFocusListener disables onBlur, which was causing the Tooltip to close when unfocusing an input inside it.
    See https://github.com/mui/material-ui/issues/30973 and
    https://github.com/VEuPathDB/web-eda/issues/897
  */
  disableFocusListener: true,
  arrow: true,
};

Bubble.propTypes = {
  children: PropTypes.node,
  renderPopoverContent: PropTypes.node,
  arrow: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

export default Bubble;
