import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import actions from 'entities/actions';
import { sessionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import SegmentedControlsInput from 'inputs/components/SegmentedControlsInput';
import SessionDetail from 'program/components/SessionDetail';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import HR from 'shared/components/HR';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Text from 'shared/components/Text';
import { STATUS_LOADING, STATUS_LOADING_MORE } from 'shared/constants';
import {
  useCurrentUser,
  useModuleLabels,
  useLabels,
  useModuleToggles,
  useToggles,
  useEncodedCurrentRoute,
} from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { map, includes } from 'vendor/lodash';
import ReactMarkdown from 'vendor/react-markdown';

const SessionsGroupContainer = styled.div`
  margin: 16px auto;
`;

const SessionsGroupTopBlock = styled.div`
  padding: 0 12px;
`;

const SessionCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  &:after {
    content: '';
    flex: 0 0 360px; /* 336px width + margins */
  }
`;

const SessionContainer = styled.div`
  margin: 12px;
  display: inline-block;
  width: 336px;
  height: 272px;

  > * {
    flex: 1;
    border-radius: 8px;
  }
`;

const PageTitle = styled.span`
  color: ${colors.neutral600};
  font-size: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px auto;
  padding: 0 12px;
`;

const TitleContainer = styled.div`
  display: flex;

  > * + * {
    margin-left: 16px;
  }
`;

const NewSessionButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const PageTipContainer = styled.div`
  max-width: 80%;
  padding: 0 12px;
`;

const SessionsSection = (props) => {
  const { fetchParams, headerText, children } = props;

  const [fetchSessions, { data: sessions, count, status }, loadMoreSessions] = useEntities(
    actions.session.retrieveList,
    null,
    {
      schema: [sessionSchema],
      loadMoreAction: actions.session.retrieveListLoadMore,
    }
  );

  const hasSessionsToLoad = status !== STATUS_LOADING && count > sessions?.length;

  useEffect(() => {
    fetchSessions(fetchParams);
  }, []);

  const refreshSection = () => {
    fetchSessions(fetchParams, { fetchAll: true });
  };

  return (
    <SessionsGroupContainer>
      <SessionsGroupTopBlock>
        <Header>
          <Text size="h4" color={colors.neutral600}>
            {headerText}
          </Text>
          <Text size="h5" color={colors.neutral600}>
            {count === 0 ? 'No sessions' : count === 1 ? '1 session' : `${count} sessions`}
          </Text>
        </Header>
        <HR height={2} color={colors.neutral200} />
      </SessionsGroupTopBlock>
      {status === STATUS_LOADING ? (
        <Loading />
      ) : (
        <SessionCardsContainer>
          {map(sessions, (session) => children(session, refreshSection))}
        </SessionCardsContainer>
      )}
      {hasSessionsToLoad && status === STATUS_LOADING_MORE ? (
        <Loading />
      ) : (
        hasSessionsToLoad && (
          <LoadMoreWrapper>
            <Button size="small" onClick={loadMoreSessions}>
              Load More
            </Button>
          </LoadMoreWrapper>
        )
      )}
    </SessionsGroupContainer>
  );
};

SessionsSection.propTypes = {
  headerText: PropTypes.string,
  children: PropTypes.func,
  fetchParams: PropTypes.object,
};

const MySessions = () => {
  const [selectedOption, setSelectedOption] = useState('attendee');
  const currentUser = useCurrentUser();
  const userId = currentUser.id;

  const { mentorship: mentorshipToggle, programs: coachingToggle } = useModuleToggles();
  const { toggle_mentee_preferences: toggleMenteePreferences } = useToggles();
  const { mentorship: mentorshipLabel } = useModuleLabels();
  const { label_program: labelProgram, label_cta_my_sessions_page_tip: labelCtaMySessionsPageTip } =
    useLabels();
  const currentRoute = useEncodedCurrentRoute();

  const handleOptionChange = (e) => {
    setSelectedOption(e);
  };

  const { timezone: userTimezone } = currentUser;

  return (
    <div>
      <HeaderContainer>
        <TitleContainer>
          <PageTitle>My Sessions</PageTitle>
          <SegmentedControlsInput
            items={[
              {
                name: 'Attending',
                value: 'attendee',
              },
              {
                name: 'Hosting',
                value: 'host',
              },
            ]}
            value={selectedOption}
            onChange={handleOptionChange}
          />
        </TitleContainer>
        <NewSessionButtonContainer>
          {coachingToggle &&
            includes(
              currentUser.permissions,
              permissionConstants.CREATE_PROGRAM_SESSION_PERMISSION
            ) && (
              <Button
                size="small"
                href={`${mapRoute('sessionCreate')}?origin=${currentRoute}`}
                startIcon={<Icon name="plus" />}
              >
                New {labelProgram} Session
              </Button>
            )}
          {mentorshipToggle &&
            includes(
              currentUser.permissions,
              permissionConstants.CREATE_MENTORSHIP_SESSION_PERMISSION
            ) && (
              <Button
                size="small"
                href={mapRoute('sessionMentorshipCreate')}
                startIcon={<Icon name="plus" />}
              >
                New {mentorshipLabel} Session
              </Button>
            )}
          {toggleMenteePreferences && mentorshipToggle && (
            <Button size="small" href={mapRoute('myMentorshipRequestsReceived')}>
              My {mentorshipLabel} Messages
            </Button>
          )}
        </NewSessionButtonContainer>
      </HeaderContainer>
      <PageTipContainer size="h4" color={colors.neutral600}>
        <ReactMarkdown linkTarget="_blank">{labelCtaMySessionsPageTip}</ReactMarkdown>
      </PageTipContainer>
      {selectedOption === 'attendee' && (
        <>
          {/* MENTEE AREA */}
          {/* booked upcoming sessions */}
          <SessionsSection
            headerText="Upcoming"
            fetchParams={{
              attendees: userId,
              period: 'upcoming',
              page_size: 25,
            }}
          >
            {(session) => (
              <SessionContainer key={session.id}>
                <SessionDetail
                  session={session}
                  userTimezone={userTimezone}
                  showDate
                  showProgramText
                  componentName="attendingSessions"
                />
              </SessionContainer>
            )}
          </SessionsSection>
          {/* past sessions */}
          <SessionsSection
            headerText="Past"
            fetchParams={{
              attendees: userId,
              period: 'previous',
              page_size: 25,
              o: '-starts_at',
            }}
          >
            {(session) => (
              <SessionContainer key={session.id}>
                <SessionDetail
                  session={session}
                  userTimezone={userTimezone}
                  showDate
                  showProgramText
                  componentName="attendingSessions"
                />
              </SessionContainer>
            )}
          </SessionsSection>
        </>
      )}
      {selectedOption === 'host' && (
        <>
          {/* MENTOR AREA */}
          {/* upcoming sessions */}
          <SessionsSection
            headerText="Upcoming"
            fetchParams={{
              host: userId,
              period: 'upcoming',
              page_size: 25,
            }}
          >
            {(session, refreshSection) => (
              <SessionContainer key={session.id}>
                <SessionDetail
                  session={session}
                  userTimezone={userTimezone}
                  onEnrollmentDelete={refreshSection}
                  showDate
                  showProgramText
                  componentName="hostingSessions"
                />
              </SessionContainer>
            )}
          </SessionsSection>
          {/* past sessions */}
          <SessionsSection
            headerText="Past"
            fetchParams={{
              host: userId,
              period: 'previous',
              page_size: 25,
              o: '-starts_at',
            }}
          >
            {(session) => (
              <SessionContainer key={session.id}>
                <SessionDetail
                  session={session}
                  userTimezone={userTimezone}
                  showDate
                  showProgramText
                  componentName="hostingSessions"
                />
              </SessionContainer>
            )}
          </SessionsSection>
        </>
      )}
    </div>
  );
};

export default MySessions;
