import moment from 'moment-timezone';

import { sessionSchema } from 'app/entities/schema';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import {
  ATTENDANCE_METHOD_LOCAL,
  ATTENDANCE_METHOD_ONLINE,
  STATUS_CANCELED,
  STATUS_RESERVED,
} from 'program/constants';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { ApiURLs } from 'services/requests-base';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { CREATE_PROGRAM_SESSION_PERMISSION, EDIT_PROGRAM_PERMISSION } from 'shared/permissions';
import { filter, find, get, includes, isEmpty, isEqual, isNil, noop } from 'vendor/lodash';

export const sessionStartsAtHasPassed = (session, userTimezone) => {
  const startsAt = moment(session.starts_at_tz_aware).tz(userTimezone);
  const time = moment().tz(userTimezone);
  return startsAt.isBefore(time);
};

export const sessionEndsAtHasPassed = (session, userTimezone) => {
  const startsAt = moment(session.ends_at_tz_aware).tz(userTimezone);
  const time = moment().tz(userTimezone);
  return startsAt.isBefore(time);
};

export const formatSessionLocationString = (session, user) => {
  const roomStr = session.room ? `Room: ${session.room} ` : '';
  const locationName = session.location ? session.location.name : 'Deleted Location';
  let locationStr = session.allows_local ? `${roomStr}@ ${locationName}` : 'Remote Only';

  if (!isEmpty(session.enrollments)) {
    const hasPassed = sessionStartsAtHasPassed(session, user.timezone);
    const prefix = `This session ${hasPassed ? 'happened' : 'will happen'}`;

    const userEnrollment = getUserEnrollment(session, user);

    const localStr = `${prefix} at ${locationName}${
      session.room ? ` (Room: ${session.room})` : ''
    }`;
    const onlineStr = `${prefix} remotely`;

    const userIsHost = user.id === session.host.id;
    const userIsAdmin = user.role === 'admin';

    if (userEnrollment) {
      locationStr = isEqual(get(userEnrollment, 'attendance_method'), ATTENDANCE_METHOD_LOCAL)
        ? localStr
        : onlineStr;
    } else if (userIsHost || userIsAdmin) {
      const hasLocalEnrollments =
        getEnrollmentsByMethod(session, ATTENDANCE_METHOD_LOCAL).length > 0;

      locationStr = hasLocalEnrollments ? localStr : onlineStr;
    }
  }

  return locationStr;
};

export const userIsHost = (session, user) => {
  return isEqual(session.host.id, user.id);
};

export const userIsAttendee = (session, user) => {
  return Boolean(find(session.attendees, ['id', user.id]));
};

export const userHasRole = (user, role) => {
  return isEqual(user.role, role);
};

export const getUserEnrollment = (session, user) => {
  return find(session.enrollments, (enrollment) => enrollment.attendee.id === user.id);
};

export const getEnrollmentsByMethod = (session, enrollmentMethod) => {
  return filter(
    session.enrollments,
    (enrollment) => enrollment.attendance_method === enrollmentMethod
  );
};

export const getSessionAttendanceMethod = (session, user) => {
  const userEnrollment = getUserEnrollment(session, user);

  if (userEnrollment) {
    return get(userEnrollment, 'attendance_method') === ATTENDANCE_METHOD_LOCAL
      ? 'In-person'
      : 'Online';
  }

  const userIsHost = user.id === session.host.id;
  const userIsAdmin = user.role === 'admin';

  if (userIsHost || userIsAdmin) {
    const hasOnlineEnrollments =
      getEnrollmentsByMethod(session, ATTENDANCE_METHOD_ONLINE).length > 0;
    const hasLocalEnrollments = getEnrollmentsByMethod(session, ATTENDANCE_METHOD_LOCAL).length > 0;

    if (hasLocalEnrollments && hasOnlineEnrollments) {
      return 'In-person and Online';
    }

    if (hasLocalEnrollments) {
      return 'In-person';
    }

    if (hasOnlineEnrollments) {
      return 'Online';
    }
  }
  return null;
};

export const getSessionsAvailableCount = (session) => {
  const attendanceLimit = get(session, 'attendance_limit', 1);
  const enrollmentsCont = get(session, 'enrollments', []).length;
  return attendanceLimit - enrollmentsCont;
};

export const getPrintableRosterUrl = (session) => {
  return ApiURLs['coaching:session_printable_roster']({ pk: session.id });
};

// Hooks
// Default override pattern https://gist.github.com/ericelliott/f3c2a53a1d4100539f71
export const useSessionEnrollmentManager = (
  session = {},
  { onChange = noop, onDelete = noop, onUnenroll = noop } = {}
) => {
  const hasProgram = Boolean(session.program_id);
  const currentUser = useCurrentUser();
  const { trackActivity } = useMetrics();

  const [updateEnrollmentRequest, { status: updateEnrollmentStatus }] = useEntities(
    actions.session.enrollmentUpdateSubmit,
    ({ status, data }) => {
      const userEnrollment = getUserEnrollment(data, currentUser);

      if (status === STATUS_DONE) {
        onChange();
        let activityName;
        if (userEnrollment) {
          toast.success(
            'Session booked successfully',
            'You should receive an email shortly with more details.'
          );
          activityName = hasProgram
            ? METRICS_ACTIVITIES.PROGRAM_SESSION_BOOK
            : METRICS_ACTIVITIES.MENTORSHIP_SESSION_BOOK;
        } else {
          toast.success(
            'Session canceled successfully',
            'You should receive an email shortly with more details.'
          );
          activityName = hasProgram
            ? METRICS_ACTIVITIES.PROGRAM_SESSION_CANCEL
            : METRICS_ACTIVITIES.MENTORSHIP_SESSION_CANCEL;
          onUnenroll();
        }

        trackActivity(activityName, {
          sessionId: session.id,
          enrollmentId: userEnrollment ? userEnrollment.id : null,
          attendanceMethod: userEnrollment ? userEnrollment.attendance_method : null,
          messageLength: userEnrollment?.agenda?.length,
          ...(hasProgram ? { hostId: session.host_id } : { mentorId: session.host_id }),
        });
      }

      if (status === STATUS_ERROR && !userEnrollment) {
        toast.error(
          'An error occurred while booking this session',
          'Please refresh this page and try again. Someone might have booked before you.'
        );
      }
    },
    { schema: sessionSchema }
  );

  const [deleteSessionRequest, { status: deleteSessionStatus }] = useEntities(
    hasProgram ? actions.sessionProgram.remove : actions.sessionMentorship.remove,
    ({ status }) => {
      if (status === STATUS_DONE) {
        toast.success('Session removed successfully', '');
        onDelete();
      }
    }
  );

  const updateEnrollment = (body) => {
    updateEnrollmentRequest(session.id, body);
  };

  const submitEnrollment = (attendanceMethod, agenda) => {
    const payload = {
      id: session.id,
      status: STATUS_RESERVED,
      attendance_method: attendanceMethod,
      agenda,
    };
    updateEnrollment(payload);
  };

  const dropSession = () => {
    const currentUserIsAdmin = userHasRole(currentUser, 'admin');
    const currentUserIsHost = userIsHost(session, currentUser);
    const currentUserIsAttendee = userIsAttendee(session, currentUser);

    if (currentUserIsAttendee) {
      const payload = {
        id: session.id,
        status: STATUS_CANCELED,
      };
      updateEnrollment(payload);
      return;
    }

    if (currentUserIsHost || currentUserIsAdmin) {
      deleteSessionRequest(session.public_id);
    }
  };

  return {
    submitEnrollment,
    dropSession,
    isLoading: includes([updateEnrollmentStatus, deleteSessionStatus], STATUS_LOADING),
  };
};

export const getSessionAttendanceLimitsFormatted = (attendanceLimit, attendanceType) => {
  if (!attendanceType || attendanceType === 'single_attendee') {
    return null;
  }
  if (
    (attendanceLimit === '0' || attendanceLimit === '' || attendanceLimit === undefined) &&
    attendanceType === 'multiple_attendees'
  ) {
    return 'Unlimited';
  }
  return attendanceLimit;
};

export function getSessionCancellationLimit(user, session) {
  const { coaching_cancellation_cutoff_minutes: cancellationCutoff } = user;
  const { timezone } = user;

  if (isNil(cancellationCutoff)) {
    return { preCutoff: null, cancellationDateLimit: null };
  }

  const seconds_in_a_day = 86400;

  let preCutoff;
  if (Math.abs(cancellationCutoff) < seconds_in_a_day) {
    // Transform cancellationCutoff in hours and minutes
    preCutoff = moment
      .utc()
      .startOf('day')
      .add(Math.abs(cancellationCutoff), 'seconds')
      .format('H:mm');
  } else {
    // Transform cancellationCutoff in days
    preCutoff = Math.abs(cancellationCutoff / seconds_in_a_day);
    preCutoff = preCutoff === 1 ? `${preCutoff} day` : `${preCutoff} days`;
  }

  const startsAt = moment(session.starts_at_tz_aware).tz(timezone);
  const cancellationDateLimit = `${startsAt
    .subtract(Math.abs(cancellationCutoff), 'seconds')
    .format('MMM D h:mm A')} (${timezone})`;

  return {
    preCutoff,
    cancellationDateLimit,
  };
}

export const canCreateProgramSession = (userPermissions, programPermissions) =>
  includes(userPermissions, CREATE_PROGRAM_SESSION_PERMISSION) ||
  includes(programPermissions, CREATE_PROGRAM_SESSION_PERMISSION);

export const canEditProgram = (userPermissions, programPermissions) =>
  includes(userPermissions, EDIT_PROGRAM_PERMISSION) ||
  includes(programPermissions, EDIT_PROGRAM_PERMISSION);
