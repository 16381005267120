import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as constants from 'catalog/constants';
import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import CodelabForm from 'codelab/components/CodelabForm/CodelabForm';
import { queries } from 'queries';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { INITIAL_DURATIONS } from 'shared/constants';
import { useCurrentUser, useQueryParams } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import { has, omit, filter, find, get, map, isEmpty, slice } from 'vendor/lodash';

const getInitialValues = ({ currentUser, clonedCodelab, codelab, defaultChannelId }) => {
  let initialValues = {
    ...codelab,
  };

  if (clonedCodelab) {
    initialValues = omit(
      {
        ...clonedCodelab,
        name: `Duplicate of ${clonedCodelab.name}`,
      },
      ['public_id', 'public_id_and_slug']
    );
  }

  // Facilitators
  const maintainerId = get(
    find(initialValues.facilitators, ['role', constants.FACILITATOR_ROLES.maintainer]),
    'user.id'
  );
  const coMaintainersIds = map(
    filter(initialValues.facilitators, ['role', constants.FACILITATOR_ROLES.co_maintainer]),
    ({ user }) => user.id
  );

  initialValues.maintainer_id = maintainerId || currentUser.id;
  initialValues.co_maintainers_ids = coMaintainersIds;

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Should publish
  initialValues.should_publish = true;

  // Default duration for Codelab is 30min
  if (!has(initialValues, 'duration') || isEmpty(initialValues.duration)) {
    initialValues.duration = INITIAL_DURATIONS.codelab;
  }

  if (!initialValues.channel_id && defaultChannelId) {
    initialValues.channel_id = defaultChannelId;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

const CodelabFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { codelab },
}) => {
  const currentUser = useCurrentUser();
  const { default_channel_id: defaultChannelId } = currentUser;

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const queryClient = useQueryClient();

  const handleInvalidateCodelabQuery = () => {
    const publicId = get(codelab, 'public_id');
    if (!publicId) {
      return;
    }

    queryClient.invalidateQueries({ queryKey: queries.codelabs.detail(publicId).queryKey });
  };

  const initialValuesState = useState(
    getInitialValues({
      currentUser,
      ...initialValuesParams,
      defaultChannelId,
    })
  );
  const initialValues = initialValuesState[0];

  const queryParams = useQueryParams();
  const isPublishing = queryParams.is_publishing === 'true';

  const isEdit = Boolean(codelab) && !isPublishing;
  const form = `codelab${isEdit ? 'Edit' : 'Publish'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <CodelabForm
        form={form}
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) => {
          if (isEdit) {
            handleInvalidateCodelabQuery();
          }

          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          });
        }}
      />
    </>
  );
};

export default CodelabFormPage;

CodelabFormPage.defaultProps = {
  initialValuesParams: {},
};

CodelabFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
