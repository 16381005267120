import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Menu, Button, ButtonProps, styled } from '@mui/material';
import queryString from 'query-string';
import * as React from 'react';

import CodelabFormModal from 'codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import CourseFormModal from 'course/components/CourseImportFormModal/CourseImportFormModal';
import { Channel } from 'features/channels/types';
import { LearningTypeMenuItem } from 'features/contentitems/components/LearningTypeMenuItem';
import { useCreateLearningPermissions } from 'features/contentitems/hooks/useCreateLearningPermissions';
import NewLinkedContentModal from 'linkedcontent/components/NewLinkedContentModal';
import colors from 'services/colors';

type WrappedCallback = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
type WrapMenuCloseHandler = (
  callback: WrappedCallback
) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;

type ContentTypeFormModal = 'codelab' | 'course' | 'linkedcontent';

const AddContentButton = styled(Button)`
  background-color: ${colors.neutral0};
`;

type ChannelAddContentButtonProps = Omit<ButtonProps, 'onClick'> & {
  channel: Channel;
};

export function ChannelAddContentButton(props: ChannelAddContentButtonProps) {
  const { channel, ...restButtonProps } = props;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const [contentTypeFormModal, setContentTypeFormModal] =
    React.useState<ContentTypeFormModal | null>(null);

  const [canCreateAnyLearningType, permissions] = useCreateLearningPermissions();

  const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const wrapMenuClose: WrapMenuCloseHandler = (callback) => {
    return (event) => {
      handleCloseMenu();
      callback(event);
    };
  };

  if (!canCreateAnyLearningType) {
    return null;
  }

  const channelRouteParams = queryString.stringify({ channel: channel.slug });

  return (
    <>
      <AddContentButton
        id="channel-add-content-button"
        aria-label="Add content"
        variant="outlined"
        color="primary"
        aria-controls={isMenuOpen ? 'channel-add-content-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleOpenMenu}
        startIcon={<AddCircleOutlineIcon />}
        {...restButtonProps}
      >
        Add content
      </AddContentButton>

      <Menu
        id="channel-add-content-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'channel-add-content-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {permissions.canCreateEventType && (
          <LearningTypeMenuItem type="event_types" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateProgram && (
          <LearningTypeMenuItem type="programs" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateMentorshipProgram && (
          <LearningTypeMenuItem type="mentorship_programs" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateTrack && (
          <LearningTypeMenuItem type="tracks" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateArticle && (
          <LearningTypeMenuItem type="articles" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateCodelab && (
          <LearningTypeMenuItem
            type="codelabs"
            onClick={wrapMenuClose(() => {
              setContentTypeFormModal('codelab');
            })}
          />
        )}
        {permissions.canCreateCourses && (
          <LearningTypeMenuItem
            type="courses"
            onClick={wrapMenuClose(() => {
              setContentTypeFormModal('course');
            })}
          />
        )}
        {permissions.canCreateVideo && (
          <LearningTypeMenuItem type="videos" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateAssessment && (
          <LearningTypeMenuItem type="assessments" routeParams={channelRouteParams} />
        )}
        {permissions.canCreateLinkedContent && (
          <LearningTypeMenuItem
            type="linkedcontent"
            onClick={wrapMenuClose(() => {
              setContentTypeFormModal('linkedcontent');
            })}
          />
        )}
      </Menu>

      {contentTypeFormModal === 'codelab' && (
        <CodelabFormModal handleClose={() => setContentTypeFormModal(null)} fromChannel={channel} />
      )}

      {contentTypeFormModal === 'course' && (
        <CourseFormModal
          handleClose={() => setContentTypeFormModal(null)}
          action="New"
          fromChannel={channel}
        />
      )}

      {contentTypeFormModal === 'linkedcontent' && (
        <NewLinkedContentModal
          handleClose={() => setContentTypeFormModal(null)}
          fromChannel={channel}
        />
      )}
    </>
  );
}
