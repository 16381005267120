import { useGetContentTypeLabel } from 'catalog/hooks';
import { formatDuration } from 'services/datetime';
import { ContentItem } from 'shared-content-item/interfaces';

export const useGetContentTypeLabelWithDuration = (content: ContentItem): string => {
  const getContentTypeLabel = useGetContentTypeLabel();
  const contentLabel = getContentTypeLabel(content.content_type);
  const duration = content.duration ? ` • ${formatDuration(content.duration)}` : '';
  return `${contentLabel}${duration}`;
};
