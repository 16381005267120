import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useContentTypeRoutes } from 'app/catalog/hooks';
import {
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPE_COLOR_MAPPING,
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
  CONTENT_TYPES,
} from 'catalog/constants';
import { displayDate } from 'services/datetime';
import SharedIcon from 'shared/components/Icon';
import { includes } from 'vendor/lodash';
import { Box, ListItem, ListItemText, LinearProgressWithLabel, Chip, Typography } from 'vendor/mui';
import { HistoryToggleOffOutlinedIcon, TodayIcon, ErrorIcon } from 'vendor/mui-icons';

interface ContentItem {
  id: number;
  public_id: string;
  public_id_and_slug: string;
  name: string;
  cover: string;
  default_cover: string;
  content_type: string;
}

interface Assignment {
  id: number;
  deconstructed_content_item: ContentItem;
  due_date: string;
  number_completed_items?: number;
  number_completable_track_items?: number;
  progress: number;
}

interface ContentItemRecordProps {
  assignment: Assignment;
}

interface IconProps {
  contentType: string;
}

const GUTTER_SIZE = '16px';

const Cover = styled.img`
  width: 56px;
  height: 40px;

  border-radius: 4px;
  overflow: hidden;
  margin-right: ${GUTTER_SIZE};
`;

const ContentItemIconWrapper = styled.div<{ contentType: string }>`
  display: inline-flex;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-right: ${GUTTER_SIZE};
  background-color: ${({ contentType }) => LEARNING_TYPE_COLOR_MAPPING?.[contentType]?.color200};
`;

const Icon = ({ contentType }: IconProps) => (
  <ContentItemIconWrapper contentType={contentType}>
    <SharedIcon
      name={LEARNING_TYPE_ICONS_MAPPING?.[contentType]}
      width="50%"
      color={LEARNING_TYPE_COLOR_MAPPING?.[contentType]?.color600}
    />
  </ContentItemIconWrapper>
);

const ProgressWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 120px;
`;

interface ProgressProps {
  value: number;
}

const Progress = ({ value }: ProgressProps) => (
  <ProgressWrapper>
    <LinearProgressWithLabel value={value} />
  </ProgressWrapper>
);

interface AssignmentDateProps {
  assignment: Assignment;
}

const AssignmentDateWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const AssignmentDate = ({ assignment }: AssignmentDateProps) => {
  const momentNow = moment();
  const daysDiff = Math.abs(moment(assignment.due_date).diff(momentNow, 'days'));
  const isOverdue = moment(assignment.due_date) <= momentNow;
  const dayString = useMemo(() => {
    if (daysDiff > 2) return null;

    if (moment(assignment.due_date).startOf('day').diff(momentNow.startOf('day'), 'days') === 0) {
      return 'today';
    }

    return isOverdue ? 'yesterday' : 'tomorrow';
  }, [daysDiff, assignment.due_date, momentNow, isOverdue]);

  const dueMessage = `due ${dayString || `${isOverdue ? 'for' : 'in'} ${daysDiff} days`}`;

  const getColor = () => {
    if (isOverdue) {
      return 'error';
    }
    return 'default';
  };

  const getLabel = () => {
    if (
      !isOverdue &&
      ((assignment.number_completed_items && assignment.number_completed_items > 0) ||
        assignment.progress)
    ) {
      return 'In progress';
    }

    return displayDate(assignment.due_date, null, null, null, { includeYear: true });
  };

  const getIcon = () => {
    if (isOverdue) {
      return <ErrorIcon />;
    }
    if (
      (assignment.number_completed_items && assignment.number_completed_items > 0) ||
      assignment.progress
    ) {
      return <HistoryToggleOffOutlinedIcon />;
    }

    return <TodayIcon />;
  };

  return (
    <Box sx={{ width: 200 }}>
      <AssignmentDateWrapper>
        <Chip icon={getIcon()} label={getLabel()} color={getColor()} sx={{ width: 125 }} />
        <Typography variant="caption" align="center" sx={{ color: getColor(), fontWeight: 'bold' }}>
          {dueMessage}
        </Typography>
      </AssignmentDateWrapper>
    </Box>
  );
};

export const ContentItemRecord = ({ assignment }: ContentItemRecordProps) => {
  const { deconstructed_content_item: contentItem } = assignment;
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  if (!contentItem) {
    return null;
  }

  const {
    content_type: contentType,
    cover,
    default_cover: defaultCover,
    name,
    public_id_and_slug: publicIdAndSlug,
  } = contentItem;

  const contentItemDetailLink = contentTypeRoutes[contentType].details?.({
    public_id_and_slug: publicIdAndSlug,
  });
  const showAssignmentProgress = includes(
    [CONTENT_TYPES.assessment, CONTENT_TYPES.scheduled_track, CONTENT_TYPES.track],
    contentType
  );

  return (
    <ListItem alignItems="center">
      <Cover src={cover || defaultCover} alt={name} />
      <Icon contentType={CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING?.[contentType]} />
      <ListItemText>
        <Link to={contentItemDetailLink}>{name}</Link>
      </ListItemText>
      {showAssignmentProgress && (
        <Box sx={{ width: 120 }}>
          <Progress
            value={
              assignment.number_completed_items && assignment.number_completable_track_items
                ? 100 *
                  (assignment.number_completed_items / assignment.number_completable_track_items)
                : 0
            }
          />
        </Box>
      )}
      {assignment?.due_date && <AssignmentDate assignment={assignment} />}
    </ListItem>
  );
};
