import queryString from 'query-string';

import { mapRoute } from 'services/requests';
import { ApiURLs } from 'services/requests-base';
import { surveyJSONSchemaQuestionKind } from 'surveys/components/SurveyForm/surveyJSONSchema';
import {
  CLOCK_MARK,
  CONTENT_ITEMS_CLOCK_MARK,
  PERIOD_OPTION_SHORT_FORM,
  PERIOD_TYPE,
  SurveyRelationshipEntityType,
  SurveyRelationshipStatus,
} from 'surveys/constants';
import { map, pick, omit, get } from 'vendor/lodash';

export const getSurveyRelationshipEntityType = (surveyRelationship) => {
  const { content_type: contentType } = surveyRelationship;

  if (contentType === 'event') {
    return SurveyRelationshipEntityType.EVENT;
  }
  if (contentType === 'eventtype') {
    return SurveyRelationshipEntityType.EVENT_TYPE;
  }
  if (contentType === 'article') {
    return SurveyRelationshipEntityType.ARTICLE;
  }
  if (contentType === 'codelab') {
    return SurveyRelationshipEntityType.CODELAB;
  }
  if (contentType === 'course') {
    return SurveyRelationshipEntityType.COURSE;
  }
  if (contentType === 'track') {
    return SurveyRelationshipEntityType.TRACK;
  }
  if (contentType === 'video') {
    return SurveyRelationshipEntityType.VIDEO;
  }
  return null;
};

export const getSurveyRelationshipStatus = (surveyRelationship) => {
  if (surveyRelationship.survey_status === 'not_started') {
    return SurveyRelationshipStatus.NOT_STARTED;
  }
  if (surveyRelationship.survey_status === 'open') {
    return SurveyRelationshipStatus.OPEN;
  }
  if (surveyRelationship.survey_status === 'closed') {
    return SurveyRelationshipStatus.CLOSED;
  }
  return null;
};

export const getEventSurveyBreadcrumbs = ({
  survey,
  related_matter_title: eventName,
  content_item_public_id_and_slug,
}) => [
  {
    label: 'Events',
    link: mapRoute('eventsList'),
  },
  {
    label: eventName,
    link: mapRoute('eventDetails', { public_id_and_slug: content_item_public_id_and_slug }),
  },
  {
    label: survey.title,
  },
];

export const getContentItemSurveyBreadcrumbs = ({
  survey,
  related_matter_title: contentItemName,
  content_item_public_id_and_slug: id,
  content_type: contentType,
}) => {
  const route = getContentRouteBasedOnType(contentType);

  return [
    {
      label: 'Catalog',
      link: mapRoute('unifiedCatalogList'),
    },
    {
      label: contentItemName,
      link: mapRoute(route, { public_id_and_slug: id }),
    },
    {
      label: survey.title,
    },
  ];
};

export const getContentRouteBasedOnType = (type) => {
  switch (type) {
    case 'article':
      return 'standAloneArticleDetail';
    case 'codelab':
      return 'standAloneCodelabDetail';
    case 'course':
      return 'standAloneCourseDetail';
    case 'eventtype':
      return 'eventTypeDetails';
    case 'video':
      return 'standAloneVideoDetail';
    default:
      return 'trackDetails';
  }
};

// Transform survey JSON schema output to have the structure that the backend expects.
export const transformFromJSONEditorToSerializerFormat = (values) => {
  const questions = map(values.questions, (question) => {
    switch (question.kind) {
      case surveyJSONSchemaQuestionKind.SHORT_TEXT.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.SHORT_TEXT.value,
        };
      case surveyJSONSchemaQuestionKind.PARAGRAPH_TEXT.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.PARAGRAPH_TEXT.value,
        };
      case surveyJSONSchemaQuestionKind.RATING_SCALE.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.RATING_SCALE.value,
        };
      case surveyJSONSchemaQuestionKind.LINEAR_SCALE.label:
        return {
          ...question,
          ...question.linear_scale_settings,
          kind: surveyJSONSchemaQuestionKind.LINEAR_SCALE.value,
        };
      case surveyJSONSchemaQuestionKind.SINGLE_CHOICE.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE.value,
          options: question.single_choice_options,
        };
      case surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.value,
          options: question.single_choice_and_other_options,
        };
      case surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.value,
          options: question.multiple_choice_options,
        };
      case surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.label:
        return {
          ...question,
          kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.value,
          options: question.multiple_choice_and_other_options,
        };
      default:
        return question;
    }
  });

  return {
    ...values,
    questions,
  };
};

// Transform survey JSON schema output to have the structure that the preview component expects.
// It basically adds fake ids so the SurveyPreviewForm component can be reused.
export const transformFromJSONEditorToPreviewFormat = (values) => {
  const survey = transformFromJSONEditorToSerializerFormat(values);

  const questions = map(survey.questions, (question, index) => {
    return { ...question, id: index + 1 };
  });

  return {
    ...survey,
    id: -1,
    questions,
  };
};

// Transform survey preview data (retrieved from backend) to have the structure that the json-editor expects.
export const transformFromPreviewToJSONEditorFormat = (survey, isEdit = false) => {
  const questions = map(survey.questions, (question) => {
    const pickAttrs = ['text', 'description', 'is_required'];
    if (isEdit) {
      pickAttrs.push('id');
    }
    const filtered_question = pick(question, pickAttrs);

    switch (question.kind) {
      case surveyJSONSchemaQuestionKind.SHORT_TEXT.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.SHORT_TEXT.label,
        };
      case surveyJSONSchemaQuestionKind.PARAGRAPH_TEXT.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.PARAGRAPH_TEXT.label,
        };
      case surveyJSONSchemaQuestionKind.RATING_SCALE.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.RATING_SCALE.label,
        };
      case surveyJSONSchemaQuestionKind.LINEAR_SCALE.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.LINEAR_SCALE.label,
          linear_scale_settings: {
            lower_range: get(question, 'settings.lower_range'),
            upper_range: get(question, 'settings.upper_range'),
            label_1: get(question, 'label_1', ''),
            label_2: get(question, 'label_2', ''),
          },
        };
      case surveyJSONSchemaQuestionKind.SINGLE_CHOICE.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE.label,
          single_choice_options: get(question, 'settings.options', []),
        };
      case surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.SINGLE_CHOICE_AND_OTHER.label,
          single_choice_and_other_options: get(question, 'settings.options', []),
        };
      case surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE.label,
          multiple_choice_options: get(question, 'settings.options', []),
        };
      case surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.value:
        return {
          ...filtered_question,
          kind: surveyJSONSchemaQuestionKind.MULTIPLE_CHOICE_AND_OTHER.label,
          multiple_choice_and_other_options: get(question, 'settings.options', []),
        };
      default:
        return filtered_question;
    }
  });

  if (isEdit) {
    return {
      ...omit(survey, ['last_response', 'responses_count']),
      questions,
    };
  }

  return {
    ...omit(survey, ['id', 'last_response', 'responses_count']),
    title: `Duplicate of ${survey.title}`,
    questions,
  };
};

export const parseScheduleInfo = (surveyRelationship) => {
  const {
    survey_schedule_period_value: surveySchedulePeriodValue,
    survey_schedule_period_option: surveySchedulePeriodOption,
    survey_schedule_period_types: surveySchedulePeriodTypes,
    survey_schedule_clock_marks: surveyScheduleClockMarks,
  } = surveyRelationship;

  const periodCharacter = PERIOD_OPTION_SHORT_FORM[surveySchedulePeriodOption];
  const periodTypesText = PERIOD_TYPE[surveySchedulePeriodTypes];
  let clockMarksText = CLOCK_MARK[surveyScheduleClockMarks];
  if (!surveyRelationship.event && !surveyRelationship.eventtype) {
    clockMarksText = CONTENT_ITEMS_CLOCK_MARK[surveyScheduleClockMarks];
  }

  return [`${surveySchedulePeriodValue}${periodCharacter}`, periodTypesText, clockMarksText].join(
    ' '
  );
};

export const parseCutoffInfo = (surveyRelationship) => {
  const {
    survey_cutoff_period_value: surveyCutoffValue,
    survey_cutoff_period_option: surveyCutoffOption,
  } = surveyRelationship;

  const periodCharacter = PERIOD_OPTION_SHORT_FORM[surveyCutoffOption];

  return `${surveyCutoffValue}${periodCharacter}`;
};

export const getSurveyExportResponsesCsvUrl = (params = {}) => {
  const baseUrl = ApiURLs[`api_surveys:export_responses_csv`]();
  return `${baseUrl}?${queryString.stringify(params)}`;
};
