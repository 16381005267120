import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const LocationIcon = ({ width, height, fill }) => {
  const d =
    'M10 0C5.582 0 2 3.624 2 8.095 2 12.565 10 20 10 20s8-7.434 8-11.905C18 3.625 14.418 0 10 ' +
    '0zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

LocationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default LocationIcon;
