import { PropsWithChildren } from 'react';

import { Box } from 'vendor/mui';

import { CARD_LIST_WIDTH_THRESHOLDS } from './constants';

interface CardListWrapperProps {
  alignSelf?: string;
}

const CardListWrapper = ({
  alignSelf = 'center',
  children,
}: PropsWithChildren<CardListWrapperProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '48px 0 8px 0',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5,
          alignSelf,
          width: CARD_LIST_WIDTH_THRESHOLDS,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CardListWrapper;
