import * as React from 'react';

import { isFunction } from 'vendor/lodash';
import {
  InputAdornment,
  OutlinedInputProps,
  OutlinedTextFieldProps,
  TextField,
  colors,
  styled,
} from 'vendor/mui';
import { CancelIcon, CheckCircleIcon } from 'vendor/mui-icons';

interface StyledTextFieldProps extends OutlinedTextFieldProps {
  stateColor?: string;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stateColor',
})<StyledTextFieldProps>(({ stateColor }) =>
  stateColor
    ? {
        '& input': {
          '&.Mui-disabled': {
            WebkitTextFillColor: stateColor,
            fontWeight: '500',
          },
        },
      }
    : {}
);

export type TextInputFeedbackState =
  | 'none' // The user has not submitted an answer
  | 'correct' // The user has submitted an answer and it is correct
  | 'incorrect' // The user has submitted an answer and it is incorrect
  | 'indeterminate'; // The user has submitted an answer and feedback is not yet available

interface TextQuestionInputProps {
  value?: string;
  disabled: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  feedbackState?: TextInputFeedbackState | ((value?: string) => TextInputFeedbackState);
}

export const TextQuestionInput = (props: TextQuestionInputProps) => {
  const { value, disabled, feedbackState: feedbackStateProp, handleChange } = props;

  const feedbackState = React.useMemo(() => {
    if (isFunction(feedbackStateProp)) {
      return feedbackStateProp(value);
    }

    return feedbackStateProp;
  }, [feedbackStateProp, value]);

  const inputProps: Partial<OutlinedInputProps> = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return {
        startAdornment: (
          <InputAdornment position="start" sx={{ color: colors.green[600] }}>
            <CheckCircleIcon />
          </InputAdornment>
        ),
      };
    }

    if (feedbackState === 'incorrect') {
      return {
        startAdornment: (
          <InputAdornment position="start" sx={{ color: colors.orange[600] }}>
            <CancelIcon />
          </InputAdornment>
        ),
      };
    }

    return {};
  }, [feedbackState]);

  const bgColor = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return colors.green[50];
    }

    if (feedbackState === 'incorrect') {
      return colors.orange[50];
    }

    if (feedbackState === 'indeterminate') {
      return colors.lightBlue[50];
    }
  }, [feedbackState]);

  const stateColor = React.useMemo(() => {
    if (feedbackState === 'correct') {
      return colors.green[700];
    }

    if (feedbackState === 'incorrect') {
      return colors.orange[700];
    }

    if (feedbackState === 'indeterminate') {
      return colors.lightBlue[700];
    }
  }, [feedbackState]);

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      InputProps={inputProps}
      stateColor={stateColor}
      sx={{
        flex: 1,
        backgroundColor: bgColor,
        overflow: 'hidden',
      }}
    />
  );
};
