import React from 'react';
import styled from 'styled-components';

import propTypes from 'services/prop-types';
import DropDownMenu from 'shared/components/DropDownMenu';

import Column from './Column';

export const MenuColumnItem = DropDownMenu.Item;

const StyledMenuColumn = styled(Column)`
  padding: 0;
`;

const StyledDropdownMenu = styled(DropDownMenu)`
  margin-top: -8px;
  position: absolute;
`;

const MenuColumn = ({ children, ...props }) => (
  <StyledMenuColumn header="" width="10px" {...props}>
    {(columnProps) => (
      <StyledDropdownMenu buttonAriaLabel="context menu" icon="elipsis" fontSize={16} {...props}>
        {children(columnProps)}
      </StyledDropdownMenu>
    )}
  </StyledMenuColumn>
);

MenuColumn.propTypes = {
  children: propTypes.anyChildren,
};

export default MenuColumn;
