import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FormFieldError } from 'app/shared/components/Form/FormComponents';
import Text from 'app/shared/components/Text';
import InfoText from 'shared/components/InfoText';

const ContentContainer = styled.div`
  span {
    display: block;
  }
`;

const TextWrapper = styled.p``;

const CoverFieldHelpText = ({ helpText, error, left, top }) => {
  return (
    <InfoText
      top={top}
      left={left}
      fontSize={12}
      content={
        <ContentContainer>
          <FormFieldError customErrorMessage={error} />

          {helpText || (
            <>
              <TextWrapper>
                <Text size="h6">Supported formats: JPG, PNG, GIF</Text>
                <Text size="h6">Maximum file size: 2 MB</Text>
                <Text size="h6">Minimum image size: 640 x 360px</Text>
                <Text size="h6">Aspect ratio: 16:9 (widescreen)</Text>
              </TextWrapper>
              <TextWrapper>
                <Text size="h6">A cover placeholder will be rendered</Text>
                <Text size="h6">by default if no image is uploaded.</Text>
              </TextWrapper>
            </>
          )}
        </ContentContainer>
      }
    />
  );
};

CoverFieldHelpText.defaultProps = {
  top: 0,
  left: 16,
};

CoverFieldHelpText.propTypes = {
  helpText: PropTypes.object,
  error: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
};

export default CoverFieldHelpText;
