import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AssignmentStateChip from 'app/assignments/components/AssignmntStateChip';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Bubble from 'shared/components/Bubble';
import Datetime from 'shared/components/Datetime';
import { OldIcon } from 'shared/components/Icon';
import Text from 'shared/components/Text';
import UserAvatar, { UserPopover } from 'shared/components/UserAvatar';
import { useEllipsisCheck, useEncodedCurrentRoute, useTooltipUID } from 'shared/hooks';
import { isEmpty, map, isNil, filter, first, reduce } from 'vendor/lodash';
import { Stack, Tooltip } from 'vendor/mui.tsx';

const LinkWithTooltip = (props) => {
  const { children } = props;
  return (
    <Tooltip title={children}>
      <Link {...props} />
    </Tooltip>
  );
};

LinkWithTooltip.propTypes = {
  children: PropTypes.node,
};

export const CellLink = styled(LinkWithTooltip)`
  color: ${colors.neutral600} !important;
  line-height: 1.7;
  ${({ color }) => `color: ${color || colors.neutral600} !important`};

  ${({ $highlighted }) =>
    $highlighted &&
    `
    font-weight: 500 !important;
    color: ${colors.neutral900} !important;
  `}

  &:hover {
    color: ${colors.emphasis600} !important;
    text-decoration: underline;
  }

  &:active {
    color: ${colors.emphasis600} !important;
    text-decoration: underline;
  }

  &:focus {
    color: ${colors.emphasis600} !important;
    text-decoration: underline;
  }
`;

export const TextArea = styled.div`
  color: ${colors.neutral600} !important;
`;

const UserCellLink = ({ user, highlighted }) => {
  const currentRoute = useEncodedCurrentRoute();
  const notEmpty = (val) => !isEmpty(val);
  const display_name = first(filter([user.name, user.display_name, user.email], notEmpty));
  return (
    <CellLink
      to={`${mapRoute('userProfile', { id: user.id })}?origin=${currentRoute}`}
      $highlighted={highlighted}
    >
      {display_name}
    </CellLink>
  );
};

UserCellLink.propTypes = {
  user: PropTypes.object.isRequired,
  highlighted: PropTypes.bool,
};

const UserAvatarCellLink = ({ user, highlighted, size }) => (
  <Stack direction="row" spacing={1}>
    <UserAvatar user={user} size={size} />
    <UserCellLink user={user} highlighted={highlighted} />
  </Stack>
);

UserAvatarCellLink.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
  highlighted: PropTypes.bool,
};

UserAvatarCellLink.defaultProps = {
  size: 'small',
};

export const UserCell = ({ data, showAvatar, highlighted, size }) => {
  if (isEmpty(data)) return null;

  return (
    <UserPopover
      showPopover
      sendEmailCTA
      user={data}
      renderPopoverHoverable={
        showAvatar ? (
          <UserAvatarCellLink user={data} highlighted={highlighted} size={size} />
        ) : (
          <UserCellLink user={data} highlighted={highlighted} />
        )
      }
    />
  );
};

UserCell.propTypes = {
  data: PropTypes.object,
  showAvatar: PropTypes.bool,
  size: PropTypes.string,
  highlighted: PropTypes.bool,
};

UserCell.defaultProps = {
  size: 'small',
};

const UserPopoverWrapper = styled.div`
  display: flex;
`;

export const MultipleUserCell = ({ data, showAvatar }) => {
  const users = filter(data, (user) => !isEmpty(user));
  if (isEmpty(users)) return null;

  return (
    <>
      {map(users, (u, index) => (
        <UserPopoverWrapper key={u.id + u.name}>
          <UserPopover
            showPopover
            sendEmailCTA
            user={u}
            renderPopoverHoverable={
              showAvatar ? <UserAvatarCellLink user={u} /> : <UserCellLink user={u} />
            }
          />
          {index < data.length - 1 ? ', ' : ''}
        </UserPopoverWrapper>
      ))}
    </>
  );
};

MultipleUserCell.propTypes = {
  data: PropTypes.array,
  showAvatar: PropTypes.bool,
};

const MultipleWrapper = styled.div`
  display: flex;
`;

export const MultipleCell = ({ data }) => {
  if (isEmpty(data)) return null;
  return map(data, (value, index) => (
    <MultipleWrapper key={`${value}-${index}`}>
      {index < data.length - 1 ? `${value}, ` : value}
    </MultipleWrapper>
  ));
};

MultipleCell.propTypes = {
  data: PropTypes.array,
};

export const DateTimeCell = (props) => {
  const { data, timezone, displayTimezone } = props;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <TextArea>
      <Datetime datetime={data} timezone={timezone} displayTimezone={displayTimezone} />
    </TextArea>
  );
};

DateTimeCell.propTypes = {
  data: PropTypes.string,
  timezone: PropTypes.string,
  displayTimezone: PropTypes.string,
};

export const DateCell = ({ data, timezone, displayTimezone, includeYear }) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <TextArea>
      <Datetime.Date
        datetime={data}
        timezone={timezone}
        displayTimezone={displayTimezone}
        includeYear={includeYear}
      />
    </TextArea>
  );
};

DateCell.defaultProps = {
  includeYear: true,
};

DateCell.propTypes = {
  data: PropTypes.string,
  timezone: PropTypes.string,
  displayTimezone: PropTypes.string,
  includeYear: PropTypes.bool,
};

export const DateTimeRangeCell = (props) => {
  const { start, end, timezone, displayTimezone } = props;

  if (isEmpty(start) || isEmpty(end)) {
    return null;
  }

  return (
    <TextArea>
      <Datetime.Range
        start={start}
        end={end}
        timezone={timezone}
        displayTimezone={displayTimezone}
      />
    </TextArea>
  );
};

DateTimeRangeCell.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  timezone: PropTypes.string,
  displayTimezone: PropTypes.string,
};

export const MonthDateCell = (props) => {
  const { date } = props;

  if (isEmpty(date)) {
    return null;
  }

  return (
    <TextArea>
      <Datetime.MonthDate datetime={date} />
    </TextArea>
  );
};

MonthDateCell.propTypes = {
  date: PropTypes.string,
};

export const LocationCell = (props) => {
  const { data } = props;

  let locationName = 'Remote Only';

  if (data.allowsLocal) {
    if (data.location_text) {
      locationName = data.location_text;
    } else if (data.location) {
      locationName = `${data.location.name} ${!isNil(data.location.deleted_at) ? '(Deleted)' : ''}`;
    } else {
      locationName = 'N/A';
    }
  }

  return (
    <TextArea>
      {data.allowsOnline && <OldIcon name="wifi" size={14} color={colors.neutral600} />}{' '}
      {locationName}
    </TextArea>
  );
};

LocationCell.propTypes = {
  data: PropTypes.object,
};

export const TimeslotCell = (props) => {
  const { startsAt, endsAt, timezone } = props;

  return <Datetime.Range start={startsAt} end={endsAt} timezone={timezone} />;
};

TimeslotCell.propTypes = {
  startsAt: PropTypes.string,
  endsAt: PropTypes.string,
  timezone: PropTypes.string,
};

export const BooleanCell = (props) => {
  const { data } = props;

  if (isEmpty(data)) {
    return null;
  }

  return <TextArea>{data}</TextArea>;
};

BooleanCell.propTypes = {
  data: PropTypes.object,
};

export const TextCell = (props) => {
  const { data } = props;

  const { hasEllipsis, nodeRef } = useEllipsisCheck();

  const labelTooltip = useTooltipUID();

  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      {hasEllipsis && (
        <Bubble renderPopoverContent={<Text color={colors.neutral500}>{data}</Text>}>
          <Text {...labelTooltip.targetProps} ellipsisOnOverflow ref={nodeRef}>
            {data}
          </Text>
        </Bubble>
      )}
      {!hasEllipsis && (
        <Text {...labelTooltip.targetProps} ellipsisOnOverflow ref={nodeRef}>
          {data}
        </Text>
      )}
    </>
  );
};

TextCell.propTypes = {
  data: PropTypes.string,
};

const StackedBarContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: 16px;
  max-width: 160px;
`;

const StacedBarItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  ${({ color }) => color && `background-color: ${color};`}
  ${({ width }) => width && `width: ${width};`}
`;

const VerticalPipe = styled.span`
  height: 16px;
  border-right: 2px solid;
  ${({ color }) => color && `border-right-color: ${color};`}
`;

export const HorizontalStackedBarCell = ({ data }) => {
  const tooltip = useTooltipUID();
  const totalValue = reduce(data, (acc, { value }) => acc + value, 0);

  const ceilingValue = Math.ceil(totalValue / 50) * 50;
  return (
    <Bubble
      renderPopoverContent={
        <Stack spacing={0}>
          {map(data, ({ label, color, value }) => (
            <Stack key={label} direction="row" spacing={0.5} alignItems="center">
              <VerticalPipe color={color} />
              <Text bold color={color} size="h4">
                {value}
              </Text>
              <Text color={colors.neutral500}>{label}</Text>
            </Stack>
          ))}
        </Stack>
      }
    >
      <StackedBarContainer {...tooltip.targetProps}>
        {map(data, ({ label, color, value }) => (
          <StacedBarItem key={label} color={color} width={`${(value / ceilingValue) * 100}%`} />
        ))}
      </StackedBarContainer>
    </Bubble>
  );
};

HorizontalStackedBarCell.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export const AssignmentStateCell = (props) => {
  const { data } = props;

  return <AssignmentStateChip assignment={data} />;
};

AssignmentStateCell.propTypes = {
  data: PropTypes.object,
};
