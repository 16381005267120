import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/components/Button';

import ButtonsContainer from './ButtonsContainer';

export const AvailableSessionArea = (props) => {
  const { userIsHost, userIsMentee, handleEnrollmentModalOpen, editSessionRoute, programType } =
    props;

  return (
    <ButtonsContainer noMargin noPadding singleButton>
      {userIsHost && (
        <Button size="small" fullWidth route={editSessionRoute}>
          Edit Session
        </Button>
      )}
      {(programType != 'mentorship_program' || userIsMentee) && (
        <Button size="small" fullWidth onClick={handleEnrollmentModalOpen}>
          Book Session
        </Button>
      )}
    </ButtonsContainer>
  );
};

AvailableSessionArea.propTypes = {
  userIsHost: PropTypes.bool,
  userIsMentee: PropTypes.bool,
  handleEnrollmentModalOpen: PropTypes.func,
  editSessionRoute: PropTypes.string,
  programType: PropTypes.string,
};

export default AvailableSessionArea;
