import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import CodelabFormModal from 'codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import { previewCodelabRequest } from 'codelab/services';
import CourseConfigModal from 'course/components/CourseConfigModal';
import CourseFormModal from 'course/components/CourseImportFormModal/CourseImportFormModal';
import { COURSE_STATUS_DRAFT, COURSE_STATUS_PUBLISHED } from 'course/constants';
import { launchCourse } from 'course/services';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import ContentSurveyModal from 'shared/components/ContentSurveyModal';
import KebabMenu, { KebabMenuItem } from 'shared/components/KebabMenu';
import { useLabels } from 'shared/hooks';
import {
  EDIT_ARTICLE_PERMISSION,
  EDIT_COURSE_PERMISSION,
  EDIT_EVENT_TYPE_PERMISSION,
  EDIT_VIDEO_PERMISSION,
  MANAGE_ARTICLE_ASSIGNMENT_PERMISSION,
  MANAGE_COURSE_REGISTRATION_PERMISSION,
  MANAGE_VIDEO_ASSIGNMENT_PERMISSION,
  EDIT_TASK_PERMISSION,
  MANAGE_TASK_ASSIGNMENT_PERMISSION,
  EDIT_CODELAB_PERMISSION,
  MANAGE_CODELAB_ASSIGNMENTS_PERMISSION,
  EDIT_TRACK_PERMISSION,
  MANAGE_TRACK_ASSIGNMENT_PERMISSION,
  CONTENT_ITEM_PERMISSIONS,
} from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { ConvertToArticleModal, ConvertToTaskModal } from 'task/components/TaskConverterModal';
import TrackItemCompletionStatsModal from 'tracks/components/TrackItemCompletionStatsModal';
import { includes } from 'vendor/lodash';
import { Divider } from 'vendor/mui';
import {
  AddToPhotosIcon,
  AddIcon,
  DescriptionIcon,
  CheckCircleRoundedIcon,
  DeleteIcon,
  EditIcon,
  AssignmentTurnedInIcon,
  PeopleIcon,
  RuleIcon,
  RefreshIcon,
  VisibilityIcon,
  SettingsIcon,
  ManageSearchIcon,
} from 'vendor/mui-icons';

const getPermissionsForType = (content) => {
  const { content_type: contentType } = content;
  if (contentType === CONTENT_TYPES.article)
    return {
      canEdit: includes(content.permissions, EDIT_ARTICLE_PERMISSION),
      canManage: includes(content.permissions, MANAGE_ARTICLE_ASSIGNMENT_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.course)
    return {
      canEdit: includes(content.permissions, EDIT_COURSE_PERMISSION),
      canManage: includes(content.permissions, MANAGE_COURSE_REGISTRATION_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.video)
    return {
      canEdit: includes(content.permissions, EDIT_VIDEO_PERMISSION),
      canManage: includes(content.permissions, MANAGE_VIDEO_ASSIGNMENT_PERMISSION),
    };
  // Event type does not have a dedicated permission to manage assignments
  if (contentType === CONTENT_TYPES.eventtype)
    return {
      canEdit: includes(content.permissions, EDIT_EVENT_TYPE_PERMISSION),
      canManage: includes(content.permissions, EDIT_EVENT_TYPE_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.task)
    return {
      canEdit: includes(content.permissions, EDIT_TASK_PERMISSION),
      canManage: includes(content.permissions, MANAGE_TASK_ASSIGNMENT_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.codelab)
    return {
      canEdit: includes(content.permissions, EDIT_CODELAB_PERMISSION),
      canManage: includes(content.permissions, MANAGE_CODELAB_ASSIGNMENTS_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.track)
    return {
      canEdit: includes(content.permissions, EDIT_TRACK_PERMISSION),
      canManage: includes(content.permissions, MANAGE_TRACK_ASSIGNMENT_PERMISSION),
    };
  if (contentType === CONTENT_TYPES.assessment)
    return {
      canEdit: includes(content.permissions, CONTENT_ITEM_PERMISSIONS.manage),
      canManage: includes(content.permissions, CONTENT_ITEM_PERMISSIONS.manage),
    };
  return {
    canEdit: false,
    canManage: false,
  };
};

const ContentItemContextMenu = ({ content, isTrackItem }) => {
  const contextMenuRef = useRef(null);
  const history = useHistory();

  const permissions = getPermissionsForType(content);
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCourseConfigModal, setShowCourseConfigModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showCodelabFormModal, setShowCodelabFormModal] = useState(false);
  const [showCourseFormModal, setShowCourseFormModal] = useState(false);
  const [showConvertToTaskModal, setShowConvertToTaskModal] = useState(false);
  const [showConvertToArticleModal, setShowConvertToArticleModal] = useState(false);
  const [showTrackCompletionStatsModal, setShowTrackCompletionStatsModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const {
    label_task: labelTask,
    label_article: labelArticle,
    label_track: labelTrack,
  } = useLabels();

  if (!permissions.canEdit) {
    return null;
  }

  const handlePreviewCodelabClick = async () => {
    await previewCodelabRequest(content.id);
  };

  const handlePreviewCourseClick = async () => {
    await launchCourse({
      courseId: content.public_id,
      isLaunchedInsideTrack: isTrackItem,
      showPreview: true,
    });
  };

  const handleDeleteClick = () => setShowDeleteModal(true);

  return (
    <>
      <KebabMenu ref={contextMenuRef}>
        <KebabMenuItem
          variant="link"
          actionText="Edit"
          to={contentTypeRoutes[content.content_type].edit({
            public_id: content.public_id,
            public_id_and_slug: content.public_id_and_slug,
          })}
          ActionIcon={EditIcon}
          color={colors.neutral600}
        />

        {/* Article Menu */}
        {content.content_type === CONTENT_TYPES.article && isTrackItem && (
          <KebabMenuItem
            actionText={`Convert to ${labelTask}`}
            color={colors.neutral600}
            ActionIcon={AssignmentTurnedInIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowConvertToTaskModal(true);
            }}
          />
        )}

        {/* Codelab Menu */}
        {/* The MUI Menu component doesn't accept a Fragment as a child. */}
        {content.content_type === CONTENT_TYPES.codelab && [
          <KebabMenuItem
            key={`${content.public_id}-codelab-import-new-version`}
            actionText="Import new version"
            color={colors.neutral600}
            ActionIcon={RefreshIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowCodelabFormModal(true);
            }}
          />,
          <KebabMenuItem
            key={`${content.public_id}-codelab-preview`}
            actionText="Preview"
            color={colors.neutral600}
            ActionIcon={VisibilityIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              handlePreviewCodelabClick();
            }}
          />,
        ]}

        {/* Course Menu */}
        {/* The MUI Menu component doesn't accept a Fragment as a child. */}
        {content.content_type === CONTENT_TYPES.course && [
          <KebabMenuItem
            key={`${content.public_id}-course-import-new-version`}
            actionText="Import new version"
            color={colors.neutral600}
            ActionIcon={RefreshIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowCourseFormModal(true);
            }}
          />,
          ...[
            includes([COURSE_STATUS_PUBLISHED, COURSE_STATUS_DRAFT], content.status)
              ? [
                  <KebabMenuItem
                    key={`${content.public_id}-course-advanced-settings`}
                    actionText="Advanced Settings"
                    color={colors.neutral600}
                    ActionIcon={SettingsIcon}
                    onClick={(e) => {
                      contextMenuRef?.current?.handleClose(e);
                      setShowCourseConfigModal(true);
                    }}
                  />,
                ]
              : [],
          ],
          <KebabMenuItem
            key={`${content.public_id}-course-preview`}
            actionText="Preview"
            color={colors.neutral600}
            ActionIcon={VisibilityIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              handlePreviewCourseClick();
            }}
          />,
        ]}

        {/* Video Menu only uses the generic options */}

        {/* Event type Menu */}
        {/* The MUI Menu component doesn't accept a Fragment as a child. */}
        {content.content_type === CONTENT_TYPES.eventtype && [
          <KebabMenuItem
            key={`${content.public_id}-event-type-duplicate`}
            variant="link"
            actionText="Duplicate"
            color={colors.neutral600}
            ActionIcon={AddToPhotosIcon}
            to={`${mapRoute('eventTypeCreate')}?cloned=${content.public_id}`}
          />,
          <KebabMenuItem
            key={`${content.public_id}-event-type-schedule`}
            variant="link"
            actionText="Schedule"
            color={colors.neutral600}
            ActionIcon={AddIcon}
            to={mapRoute('eventNewFromTemplate', {
              public_id: content.public_id,
            })}
          />,
        ]}

        {/* Tasks menu */}
        {content.content_type === CONTENT_TYPES.task && isTrackItem && (
          <KebabMenuItem
            actionText={`Convert to ${labelArticle}`}
            color={colors.neutral600}
            ActionIcon={DescriptionIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowConvertToArticleModal(true);
            }}
          />
        )}

        {/* Tracks menu */}
        {content.content_type === CONTENT_TYPES.track && (
          <KebabMenuItem
            actionText={`View ${labelTrack} progress`}
            color={colors.neutral600}
            ActionIcon={CheckCircleRoundedIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowTrackCompletionStatsModal(true);
            }}
          />
        )}

        {(permissions.canManage || content.content_type === CONTENT_TYPES.task) && <Divider />}

        {/* Generic options */}
        {permissions.canManage && (
          <KebabMenuItem
            actionText="Manage Assignments"
            color={colors.neutral600}
            ActionIcon={PeopleIcon}
            onClick={() => {
              history.push(
                mapRoute('contentItemManageAssignments', {
                  public_id_and_slug: content.public_id_and_slug,
                })
              );
            }}
          />
        )}
        {content.content_type !== CONTENT_TYPES.task && (
          <KebabMenuItem
            actionText="Manage Surveys"
            color={colors.neutral600}
            ActionIcon={RuleIcon}
            onClick={(e) => {
              contextMenuRef?.current?.handleClose(e);
              setShowSurveyModal(true);
            }}
          />
        )}
        {permissions.canManage && (
          <KebabMenuItem
            onClick={() => setShowLogModal(true)}
            actionText="View Logs"
            color={colors.neutral600}
            key={`${content.public_id}-view-logs`}
            ActionIcon={ManageSearchIcon}
          />
        )}
        <Divider />
        <KebabMenuItem
          actionText="Delete"
          color={colors.error600}
          ActionIcon={DeleteIcon}
          onClick={(e) => {
            contextMenuRef?.current?.handleClose(e);
            handleDeleteClick();
          }}
        />
      </KebabMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showCourseConfigModal && (
        <CourseConfigModal course={content} handleClose={() => setShowCourseConfigModal(false)} />
      )}
      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}
      {showCodelabFormModal && (
        <CodelabFormModal
          handleClose={() => setShowCodelabFormModal(false)}
          codelabId={content.id}
          handleUploadCompleted={(publicIdAndSlug) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new codelab version.'
            );
            history.push(
              mapRoute('standAloneCodelabDetail', {
                public_id_and_slug: publicIdAndSlug,
              })
            );
            setShowCodelabFormModal(false);
          }}
        />
      )}
      {showCourseFormModal && (
        <CourseFormModal
          handleClose={() => setShowCourseFormModal(false)}
          action="Update"
          courseIdUpdate={content.id}
          handleUploadCompleted={(publicIdAndSlug) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new course version.'
            );
            if (!content.is_inline) {
              history.push(
                mapRoute('standAloneCourseDetail', {
                  public_id_and_slug: publicIdAndSlug,
                })
              );
            }
            setShowCourseFormModal(false);
          }}
        />
      )}

      {showConvertToTaskModal && (
        <ConvertToTaskModal
          content={content}
          handleClose={() => setShowConvertToTaskModal(false)}
        />
      )}

      {showConvertToArticleModal && (
        <ConvertToArticleModal
          content={content}
          handleClose={() => setShowConvertToArticleModal(false)}
        />
      )}

      {showTrackCompletionStatsModal && (
        <TrackItemCompletionStatsModal
          track={content}
          handleClose={() => setShowTrackCompletionStatsModal(false)}
        />
      )}

      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

ContentItemContextMenu.propTypes = {
  content: PropTypes.object.isRequired,
  isTrackItem: PropTypes.bool,
};

export default ContentItemContextMenu;
