import PropTypes from 'prop-types';

// Remove this file when all prop-types that depend on BreadcrumbProptypes are removed

const Item = PropTypes.shape({
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
});

const BreadcrumbProptypes = {
  Item,
};

export default BreadcrumbProptypes;
