import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Text from 'app/shared/components/Text';
import colors from 'services/colors';
import propTypes from 'services/prop-types';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { isEmpty } from 'vendor/lodash';

const Container = styled.div`
  width: ${({ width }) => width || '500px'};
  height: ${({ height }) => height || '392px'};
  cursor: pointer;
  overflow: hidden;
  cursor: pointer;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.neutral400};
  background-position: center;
  background-size: cover;
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
`;

const PlaceholderCenterer = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: fit-content;
`;

const PlaceholderContainer = styled.div`
  border: dashed 2px ${colors.action600};
  border-radius: 5px;
  width: 100%;
  height: 100%;
`;

const DefaultPlaceholder = (props) => (
  <Container {...props}>
    <PlaceholderContainer>
      <PlaceholderCenterer>
        <Icon name="image" width={36} height={32} color={colors.action600} />
        <Text color={colors.neutral400} display="block">
          Click here to{' '}
          <Text color={colors.action600} bold>
            Upload an image
          </Text>
        </Text>
      </PlaceholderCenterer>
    </PlaceholderContainer>
  </Container>
);

const Preview = ({
  Placeholder,
  placeholderImage,
  imageUrl,
  uploadResult,
  isUploading,
  ...rest
}) => {
  if (isUploading) {
    return (
      <Container {...rest}>
        <PlaceholderCenterer>
          <Loading />
        </PlaceholderCenterer>
      </Container>
    );
  }

  const PlaceholderComponent = Placeholder || DefaultPlaceholder;

  if (isEmpty(placeholderImage) && isEmpty(imageUrl) && isEmpty(uploadResult)) {
    return <PlaceholderComponent {...rest} />;
  }

  return (
    <Container {...rest}>
      <Image url={imageUrl || placeholderImage} />
    </Container>
  );
};

Preview.propTypes = {
  Placeholder: propTypes.anyChildren,
  placeholderImage: PropTypes.string,
  imageUrl: PropTypes.string,
  uploadResult: PropTypes.object,
  isUploading: PropTypes.bool,
};

export default Preview;
