import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ContentItemInputCover from 'app/shared-content-item/components/ContentItemInputCover';
import { useContentTypeRoutes } from 'catalog/hooks';
import InfoBox from 'shared/components/InfoBox';
import Text from 'shared/components/Text';

const Container = styled.div`
  margin-top: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CoverWrapper = styled.div`
  height: 56px;
  width: 100px;
  margin-right: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentItemInputInfoBox = ({ content, upcoming, upcomingType, actionText }) => {
  const contentTypeRoutes = useContentTypeRoutes();
  return (
    <Container>
      <InfoBox
        content={
          <InfoWrapper>
            <CoverWrapper>
              <ContentItemInputCover content={content} curvedBorders />
            </CoverWrapper>
            <Info>
              <Text size="h4" medium>
                {content.name}
              </Text>
              {upcomingType && (
                <Text>{`${upcoming} upcoming ${upcomingType}${upcoming > 1 ? 's' : ''}`}</Text>
              )}
            </Info>
          </InfoWrapper>
        }
        actionText={actionText}
        route={contentTypeRoutes[content.content_type].details({
          public_id_and_slug: content.public_id_and_slug,
        })}
        target="_blank"
        hideIcon
      />
    </Container>
  );
};

ContentItemInputInfoBox.propTypes = {
  content: PropTypes.object.isRequired,
  upcoming: PropTypes.number,
  upcomingType: PropTypes.string,
  actionText: PropTypes.string,
};

export default ContentItemInputInfoBox;
