import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import { LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeFilterOptions } from 'catalog/hooks';
import { FilterDef } from 'filters/components/RQLFilterBar/RQLFilterBar';
import { FiltersDefinitionProps } from 'filters/types';
import { getFlexibleFiltersDef } from 'filters/utils';
import { useCurrentUser } from 'shared/hooks';
import { get, isEmpty, map, reduce, toString, values } from 'vendor/lodash';

const toggleTypesMap = {
  [LEARNING_TYPES.articles]: 'toggle_articles',
  [LEARNING_TYPES.assessments]: 'toggle_assessments',
  [LEARNING_TYPES.codelabs]: 'toggle_codelabs',
  [LEARNING_TYPES.courses]: 'toggle_courses',
  [LEARNING_TYPES.event_types]: 'toggle_events',
  [LEARNING_TYPES.linkedcontent]: 'toggle_linkedcontent',
  [LEARNING_TYPES.programs]: 'toggle_coaching',
  [LEARNING_TYPES.tracks]: 'toggle_tracks',
  [LEARNING_TYPES.videos]: 'toggle_videos',
};

type UseGetAllCatalogFiltersDefinitionOptions = Omit<
  FiltersDefinitionProps,
  'fixedFilters' | 'includeFilterChannels'
>;

export function useGetAllCatalogFiltersDefinition(
  options: UseGetAllCatalogFiltersDefinitionOptions
): Record<string, FilterDef> {
  const { filters, ordering, updateFilter, setOrdering } = options;

  const currentUser = useCurrentUser();
  const learningTypesOptions = useLearningTypeFilterOptions();

  const isFixed = get(rqlToInput(get(filters, 'fixed')), '0') === 'true';

  const selectedLearningTypes = rqlToInput(get(filters, 'type'));
  const toggleTypes = !isEmpty(selectedLearningTypes)
    ? map(selectedLearningTypes, (filter) => toggleTypesMap[filter])
    : values(toggleTypesMap);

  const allFilters: Record<string, FilterDef> = {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by titles, tags...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    ...(isFixed
      ? {}
      : {
          'Content Types': {
            type: 'select',
            label: 'Type',
            value: get(filters, 'type', null),
            multiple: true,
            options: learningTypesOptions,
            onChange: (newValue) => updateFilter({ type: newValue }),
          },
        }),
    Categories: {
      type: 'model_select',
      label: 'Categories',
      value: get(filters, 'category', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      queryParams: { tag_type: 'main_topics' },
      onChange: (newValue) => updateFilter({ category: newValue }),
    },
    Sort: {
      type: 'select',
      label: 'Sort by',
      value: { $eq: ordering },
      options: [
        { name: 'Relevance', value: 'relevance' },
        { name: 'Most Engaged', value: '-total_assignments' },
        { name: 'Best Rated', value: '-avg_feedback_rating' },
        { name: 'A-Z', value: 'name' },
        { name: 'Z-A', value: '-name' },
        { name: 'Newest', value: '-created' },
        { name: 'Oldest', value: 'created' },
      ],
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
      onChange: (newValue) => setOrdering(get(newValue, '$eq', 'relevance') as string),
    },
    Tags: {
      type: 'model_select',
      label: 'Tags',
      value: get(filters, 'tag', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      onChange: (newValue) => updateFilter({ tag: newValue }),
    },
    Ownership: {
      type: 'model_select',
      label: 'Ownership',
      value: get(filters, 'facilitator', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ facilitator: newValue }),
    },
    Status: {
      type: 'select',
      label: 'Status',
      value: get(filters, 'status', null),
      options: [
        { name: 'Published', value: 'published' },
        { name: 'Archived', value: 'archived' },
      ],
      onChange: (newValue) => updateFilter({ status: newValue }),
    },
    'Assigned Content': {
      type: 'boolean',
      label: 'Assigned content',
      value: get(filters, 'assigned_by_others', null),
      onChange: (newValue) => updateFilter({ assigned_by_others: newValue }),
    },
    'My Saved Content': {
      type: 'boolean',
      label: 'My saved content',
      value: get(filters, 'self_assigned', null),
      onChange: (newValue) => updateFilter({ self_assigned: newValue }),
    },
    Channels: {
      type: 'model_select',
      label: 'Channels',
      value: get(filters, 'channel', null),
      multiple: true,
      sortOptions: true,
      queryName: 'channels',
      queryParams: { tag_type: 'channel' },
      onChange: (newValue) => updateFilter({ channel: newValue }),
    },
    ...reduce(
      getFlexibleFiltersDef({
        filters,
        updateFilter,
        currentUser,
        toggleTypes,
      }),
      (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
      {}
    ),
  };

  return allFilters;
}
