import PropTypes from 'prop-types';
import React from 'react';

import { CONTENT_TYPES, LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import colors from 'services/colors';
import DropDownMenu from 'shared/components/DropDownMenu';
import { useCurrentUser } from 'shared/hooks';

const AddContentMenuItems = ({ handleDropDownItemClick }) => {
  const learningTypeLabels = useLearningTypeLabels();
  const { scorm_cloud_integration_toggle: toggleCourses } = useCurrentUser();

  return (
    <>
      <DropDownMenu.Item
        icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.event_types]}
        color={colors.learningEventType600}
        title={learningTypeLabels[LEARNING_TYPES.event_types]}
        type="button"
        onClick={() => handleDropDownItemClick(CONTENT_TYPES.eventtype)}
      />
      <DropDownMenu.Item
        icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.articles]}
        color={colors.learningArticle600}
        title={learningTypeLabels[LEARNING_TYPES.articles]}
        type="button"
        onClick={() => handleDropDownItemClick(CONTENT_TYPES.article)}
      />
      <DropDownMenu.Item
        icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.videos]}
        color={colors.learningVideo600}
        title={learningTypeLabels[LEARNING_TYPES.videos]}
        type="button"
        onClick={() => handleDropDownItemClick(CONTENT_TYPES.video)}
      />
      <DropDownMenu.Item
        icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.codelabs]}
        color={colors.learningCodelab600}
        title={learningTypeLabels[LEARNING_TYPES.codelabs]}
        type="button"
        onClick={() => handleDropDownItemClick(CONTENT_TYPES.codelab)}
      />
      {toggleCourses && (
        <DropDownMenu.Item
          icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.courses]}
          color={colors.learningCourse600}
          title={learningTypeLabels[LEARNING_TYPES.courses]}
          type="button"
          onClick={() => handleDropDownItemClick(CONTENT_TYPES.course)}
        />
      )}
      <DropDownMenu.Item
        icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tasks]}
        color={colors.learningTask600}
        title={learningTypeLabels[LEARNING_TYPES.tasks]}
        type="button"
        onClick={() => handleDropDownItemClick(CONTENT_TYPES.task)}
      />
    </>
  );
};

AddContentMenuItems.propTypes = {
  handleDropDownItemClick: PropTypes.func,
};

export default AddContentMenuItems;
