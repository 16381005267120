import { css } from 'styled-components';

const gradientMask = (percentage) => `
  mask-image: -webkit-gradient(
    linear,
    left ${percentage}%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  -webkit-mask-image: -webkit-gradient(
    linear,
    left ${percentage}%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
`;

const outlineStyle = `
  outline: 5px auto -webkit-focus-ring-color;
`;

const styleBackgroundImage = css`
  display: flex;
  justify-content: center;
  align-items: center;

  background-position: center;
  background-size: cover;
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-repeat: no-repeat;

  ${({ imageUrl, bgColor }) =>
    !imageUrl &&
    bgColor &&
    `
    background-color: ${bgColor};
  `}
`;

const mixins = {
  gradientMask,
  outlineStyle,
};

const styledTheme = {
  defaultLinkHoverOpacity: 0.8,
  mixins,
};

export { styleBackgroundImage };

export default styledTheme;
