import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Session } from 'app/shared/components/types';
import MentorshipSessionForm from 'mentorship/components/MentorshipSessionForm';
import SessionBulkCopyModal from 'program-shared/components/SessionBulkDuplicateModal';
import { onSessionFormSubmitSuccess } from 'program/components/SessionForm/SessionFormHelpers';
import { queries } from 'queries';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { mapRoute } from 'services/requests';
import { getNextHalfHour, isGoogleMeetLink } from 'services/utils';
import Loading from 'shared/components/Loading';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { NAIVE_DATETIME_FORMAT } from 'shared/constants';
import { useCurrentUser, useOriginRoute, usePublicIdFromURL, useQueryParams } from 'shared/hooks';
import { CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION } from 'shared/permissions';
import { includes, isEmpty, toLower, toInteger, omit, isArray, slice } from 'vendor/lodash';

const MentorshipProgramSessionCreate = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { cloned: duplicatedSessionIdParam, host: hostId, attendee: attendeeId } = useQueryParams();
  const { publicId } = usePublicIdFromURL();

  const { data: content, isLoading: isContentLoading } = useQuery(
    queries.mentorship_program.detail(publicId)
  );

  const mentorshipProgramRoute = mapRoute('mentorshipProgramDetails', {
    public_id_and_slug: publicId,
  });
  const backRoute = useOriginRoute();

  const canCreateForOthers = isContentLoading
    ? false
    : includes(content?.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION);

  const defaultInitialValues: Session = useMemo(() => {
    return {
      starts_at: getNextHalfHour().format(NAIVE_DATETIME_FORMAT),
      duration: '01:00:00',
      allows_local: false,
      allows_online: true,
      room: '',
      meeting_url: '',
      google_calendar_resource_id: '',
      google_calendar_resource_email: '',
      google_calendar_calendar_owner: currentUser,
      extra_info: '',
      online_extra_info: '',
      attendee_id: null,
      host_id: canCreateForOthers ? null : currentUser.id,
      location_id: canCreateForOthers ? null : currentUser.location_id,
    };
  }, [canCreateForOthers, currentUser]);

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isPrefillingWithLastSession, setIsPrefillingWithLastSession] = useState(false);
  const [showPrefillBox, setShowPrefillBox] = useState(false);
  const [prefilledValuesCleaned, setPrefilledValuesCleaned] = useState(false);
  const [sessionToBulkDuplicate, setSessionToBulkDuplicate] = useState<{ public_id?: string }>({});

  const [duplicatedSessionId, setDuplicatedSessionId] = useState<string>('');

  const setSessionToCopy = (session) => {
    const initialValues = { ...omit(session, ['id', 'public_id']) } as Session;
    initialValues['meeting_url'] = isGoogleMeetLink(session.meeting_url)
      ? ''
      : (session.meeting_url as string);
    setInitialValues(initialValues);
  };

  useEffect(() => {
    if (duplicatedSessionIdParam) {
      if (isArray(duplicatedSessionIdParam)) {
        setDuplicatedSessionId(duplicatedSessionIdParam[0] ?? '');
      } else {
        setDuplicatedSessionId(duplicatedSessionIdParam);
      }
    }
  }, [duplicatedSessionIdParam]);

  const {
    isLoading: duplicatedSessionIsLoading,
    isError: duplicatedSessionHasError,
    data: duplicatedSessionData,
  } = useQuery(queries.mentorship_program.session(duplicatedSessionId));

  if (duplicatedSessionHasError) {
    permissionDeniedRedirectAlert();
  }
  useEffect(() => {
    if (duplicatedSessionData) {
      setSessionToCopy(duplicatedSessionData);
      setIsDuplicating(true);
      setShowPrefillBox(true);
    }
  }, [duplicatedSessionData]);

  const { isLoading: lastSessionIsLoading, data: lastSessionData } = useQuery(
    queries.mentorship_program.sessions(publicId, '')
  );

  useEffect(() => {
    if (!lastSessionIsLoading && !isEmpty(lastSessionData)) {
      setSessionToCopy(lastSessionData[0]);
      setIsPrefillingWithLastSession(true);
      setShowPrefillBox(true);
    }
  }, [lastSessionIsLoading, lastSessionData]);

  const cleanPrefilledValues = () => {
    setInitialValues(defaultInitialValues);
    setIsDuplicating(false);
    setIsPrefillingWithLastSession(false);
    setShowPrefillBox(true);
    setPrefilledValuesCleaned(true);
  };

  const undoCleanPrefilledValues = () => {
    // We're only reloading the page for now because our BaseAsyncSelectField needs to be refactored.
    window.location.reload();
  };

  useEffect(() => {
    if (hostId || attendeeId) {
      setInitialValues({
        ...defaultInitialValues,
        host_id: toInteger(hostId),
        attendee_id: toInteger(attendeeId),
      });
    }
  }, [duplicatedSessionId, hostId, attendeeId, defaultInitialValues]);

  const isFetchingDuplicatedSession = duplicatedSessionId && duplicatedSessionIsLoading;
  const isFetchingLastSession = !duplicatedSessionId && lastSessionIsLoading;

  if (isFetchingDuplicatedSession || isFetchingLastSession || isContentLoading || !content)
    return <Loading />;

  return (
    <>
      <PageTitle
        title={`${duplicatedSessionId ? 'Duplicate' : 'Create'} ${content?.name} Session`}
      />
      <MentorshipSessionForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        content={content}
        isMentorshipSession
        form="newMentorshipSessionCreateForm"
        contentName={`${content?.name} Session`}
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={`${duplicatedSessionId ? 'Duplicating' : 'Creating'} Session`}
        isDuplicating={isDuplicating}
        isPrefillingWithLastSession={isPrefillingWithLastSession}
        showPrefillBox={showPrefillBox}
        prefilledValuesCleaned={prefilledValuesCleaned}
        cleanPrefilledValues={cleanPrefilledValues}
        undoCleanPrefilledValues={undoCleanPrefilledValues}
        backRoute={backRoute}
        breadcrumbsItemList={[
          {
            label: content?.name,
            link: mentorshipProgramRoute,
          },
          {
            label: `${duplicatedSessionId ? 'Duplicate' : 'New'} ${toLower(content?.name)} session`,
          },
        ]}
        onSubmitSuccessHandler={(result, saveAndDuplicate, saveAndBulkDuplicate) => {
          const { public_id: publicId, program_id: publicIdAndSlug } = result;

          const saveAndDuplicateRoute = `${mapRoute('mentorshipSessionCreate', {
            public_id_and_slug: publicIdAndSlug,
          })}?cloned=${publicId}&from_previous=true`;

          queryClient.invalidateQueries({
            queryKey: slice(
              queries.mentorship_program.sessions(publicIdAndSlug, null).queryKey,
              0,
              -1
            ),
          });

          onSessionFormSubmitSuccess(
            result,
            saveAndDuplicate,
            saveAndBulkDuplicate,
            setSessionToBulkDuplicate,
            history,
            false,
            backRoute,
            saveAndDuplicateRoute
          );
        }}
      />
      {!isEmpty(sessionToBulkDuplicate) && (
        <SessionBulkCopyModal
          session={sessionToBulkDuplicate}
          handleClose={() => {
            setSessionToBulkDuplicate({});
            history.push(
              mapRoute('sessionMentorshipEdit', { public_id: sessionToBulkDuplicate.public_id })
            );
          }}
        />
      )}
    </>
  );
};

export default MentorshipProgramSessionCreate;
