import { useState } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { ContentItemContextMenu } from 'content-items/components/interfaces';
import CourseConfigModal from 'course/components/CourseConfigModal';
import CourseFormModal from 'course/components/CourseImportFormModal/CourseImportFormModal';
import { COURSE_STATUS_DRAFT, COURSE_STATUS_PUBLISHED } from 'course/constants';
import { launchCourse } from 'course/services';
import actions from 'entities/actions';
import { useArchiveUnarchive } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import ContentSurveyModal from 'shared/components/ContentSurveyModal';
import DropDownMenu from 'shared/components/DropDownMenu';
import { CONTENT_ITEM_PERMISSIONS, COURSE_PERMISSIONS } from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { includes, isEmpty, get } from 'vendor/lodash';

const CourseContextMenu = ({ content, isRenderedWithinTrack = false }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCourseConfigModal, setShowCourseConfigModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showCourseFormModal, setShowCourseFormModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const history = useHistory();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { archiveItem, unarchiveItem } = useArchiveUnarchive(actions.course.archive);

  const {
    id,
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    permissions,
    status,
    is_inline,
  } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));
  const hasScormCloudID = !isEmpty(get(content, 'scorm_cloud_id'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);
  // We cannot preview CMI5 learning content
  const canPreview = includes(permissions, COURSE_PERMISSIONS.preview);

  if (!canManage) {
    return null;
  }

  const handlePreviewCourseClick = async () => {
    await launchCourse({
      courseId: publicId,
      isLaunchedInsideTrack: isRenderedWithinTrack,
      showPreview: true,
    });
  };

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.course].edit({
              public_id_and_slug: publicIdAndSlug,
            })}
            title="Edit"
            icon="pencil"
          />

          <DropDownMenu.Item
            onClick={() => setShowCourseFormModal(true)}
            title="Import new version"
            icon="refresh"
          />

          {includes([COURSE_STATUS_PUBLISHED, COURSE_STATUS_DRAFT], status) && (
            <DropDownMenu.Item
              onClick={() => setShowCourseConfigModal(true)}
              title="Advanced Settings"
              icon="settings"
              disabled={!hasScormCloudID}
            />
          )}

          <DropDownMenu.Item
            onClick={handlePreviewCourseClick}
            title="Preview"
            icon="view"
            disabled={!canPreview}
          />

          {canAssign && (
            <DropDownMenu.Item
              route={mapRoute('contentItemManageAssignments', {
                public_id_and_slug: publicIdAndSlug,
              })}
              title="Manage Assignments"
              icon="group"
            />
          )}
          <DropDownMenu.Item
            onClick={() => setShowSurveyModal(true)}
            title="Manage Surveys"
            icon="survey"
          />
          {!isArchived && (
            <DropDownMenu.Item
              onClick={() => archiveItem(publicId)}
              title="Archive"
              icon="archive"
            />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={() => unarchiveItem(publicId)}
                title="Unarchive"
                icon="unarchive"
              />
              <DropDownMenu.Item
                onClick={() => setShowDeleteModal(true)}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            </>
          )}
          {canAssign && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showCourseConfigModal && (
        <CourseConfigModal course={content} handleClose={() => setShowCourseConfigModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showCourseFormModal && (
        <CourseFormModal
          handleClose={() => setShowCourseFormModal(false)}
          action="Update"
          courseIdUpdate={id}
          handleUploadCompleted={(publicIdAndSlug) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new course version.'
            );
            if (!is_inline) {
              history.push(
                mapRoute('standAloneCourseDetail', {
                  public_id_and_slug: publicIdAndSlug,
                })
              );
            }
            setShowCourseFormModal(false);
          }}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default CourseContextMenu;
