import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { map, keys, isEmpty, isArray, join, values, isObject, isString } from 'vendor/lodash';

export const ErrorContainer = styled.div`
  width: 100%;
  height: fit-content;
  background: mistyrose;
  text-align: center;
  border: red 1px solid;
  border-radius: 5px;
  font-weight: bold;
  color: red;
  padding: 12px;
`;

const ErrorList = styled.ul`
  font-weight: normal;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

class FormErrorContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      reset: true,
    };
  }

  componentDidUpdate = () => {
    const { invalid, error, submitting } = this.props;
    const { reset } = this.state;

    if (submitting && !reset) {
      this.setState({ reset: true });
    }

    if (!submitting && reset && invalid && !isEmpty(error)) {
      window.scrollTo(0, 0);
      this.setState({ reset: false });
    }
  };

  render = () => {
    const { invalid, error } = this.props;

    if (isEmpty(error) || !invalid) {
      return null;
    }

    const errorArray = isArray(error) ? error : [error];

    const errorDisplay = map(errorArray, (e) =>
      isString(e)
        ? e
        : map(values(e), (v) => {
            if (isArray(v)) {
              return join(map(v, (i) => (isObject(i) ? values(i) : i)));
            }
            return v;
          })
    );

    return (
      <div>
        <ErrorContainer>
          <div>
            <span>Oops! It appears there are some form errors. See below.</span>
            <div>
              <ErrorList>
                {map(errorDisplay, (e) =>
                  isString(e) ? (
                    <li key={e}>{e}</li>
                  ) : (
                    map(keys(e), (key) => <li key={key}>{e[key]}</li>)
                  )
                )}
              </ErrorList>
            </div>
          </div>
        </ErrorContainer>
      </div>
    );
  };
}

FormErrorContainer.propTypes = {
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default FormErrorContainer;
