import { map } from 'vendor/lodash';

export const PERIOD_OPTION = {
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

export const PERIOD_OPTION_SHORT_FORM = {
  hours: 'h',
  days: 'd',
  weeks: 'w',
  months: 'mo',
};

export const MATTER_RELATION = {
  before_matter_starts: 'before_matter_starts',
  before_matter_ends: 'before_matter_ends',
  after_matter_starts: 'after_matter_starts',
  after_matter_ends: 'after_matter_ends',
};

export const MATTER_RELATION_MARK = {
  before_matter_starts: 'before event starts',
  before_matter_ends: 'before event ends',
  after_matter_starts: 'after event starts',
  after_matter_ends: 'after event ends',
};

export const matterRelationOptions = map(MATTER_RELATION_MARK, (label, value) => ({
  value,
  label,
}));

export const CONTENT_MATTER_RELATION_MARK = {
  after_matter_starts: 'after assignment',
  after_matter_ends: 'after completion',
};

export const contentMatterRelationOptions = map(CONTENT_MATTER_RELATION_MARK, (label, value) => ({
  value,
  label,
}));

export const PERIOD_TYPE = {
  before: 'before',
  after: 'after',
};

export const CLOCK_MARK = {
  matter_starts: 'item starts',
  matter_ends: 'item ends',
};

export const CONTENT_ITEMS_CLOCK_MARK = {
  matter_starts: 'assignment date',
  matter_ends: 'completion date',
};

export const periodOptions = [
  { value: 'hours', label: 'hour(s)' },
  { value: 'days', label: 'day(s)' },
  { value: 'weeks', label: 'week(s)' },
  { value: 'months', label: 'month(s)' },
];

export const periodTypes = [
  { value: 'before', label: 'before' },
  { value: 'after', label: 'after' },
];

export const clockMarks = [
  { value: 'matter_starts', label: 'event starts' },
  { value: 'matter_ends', label: 'event ends' },
];

export const SurveyQuestionKind = {
  SHORT_TEXT: 'short_text',
  PARAGRAPH_TEXT: 'paragraph_text',
  MULTIPLE_CHOICE: 'multiple_choice',
  MULTIPLE_CHOICE_AND_OTHER: 'multiple_choice_and_other',
  SINGLE_CHOICE: 'single_choice',
  SINGLE_CHOICE_AND_OTHER: 'single_choice_and_other',
  LINEAR_SCALE: 'linear_scale',
  RATING_SCALE: 'rating_scale',
};

export const SurveyRelationshipEntityType = {
  ALL: '',
  EVENT: 'event',
  EVENT_TYPE: 'event_type',
  ARTICLE: 'article',
  VIDEO: 'video',
  CODELAB: 'codelab',
  COURSE: 'course',
  PROGRAM: 'program',
  TRACK: 'track',
  SESSION: 'session',
  CONTENT_ITEM: 'content_item',
};

export const SurveyRelationshipStatus = {
  NOT_STARTED: 'not_started',
  OPEN: 'open',
  CLOSED: 'closed',
};

export const SurveyQuestionKindInitialValueMap = {
  [SurveyQuestionKind.SHORT_TEXT]: '',
  [SurveyQuestionKind.PARAGRAPH_TEXT]: '',
  [SurveyQuestionKind.MULTIPLE_CHOICE]: [],
  [SurveyQuestionKind.MULTIPLE_CHOICE_AND_OTHER]: [],
  [SurveyQuestionKind.SINGLE_CHOICE]: '',
  [SurveyQuestionKind.LINEAR_SCALE]: null,
  [SurveyQuestionKind.RATING_SCALE]: null,
};

export const SurveyRelationshipTypeLabelMap = {
  [SurveyRelationshipEntityType.EVENT]: 'Event',
  [SurveyRelationshipEntityType.EVENT_TYPE]: 'Event Type',
  [SurveyRelationshipEntityType.ARTICLE]: 'Article',
  [SurveyRelationshipEntityType.VIDEO]: 'Video',
  [SurveyRelationshipEntityType.CODELAB]: 'Codelab',
  [SurveyRelationshipEntityType.COURSE]: 'Course',
  [SurveyRelationshipEntityType.PROGRAM]: 'Program',
  [SurveyRelationshipEntityType.TRACK]: 'Track',
  [SurveyRelationshipEntityType.SESSION]: 'session',
};

export const SurveyRelationshipStatusLabelMap = {
  [SurveyRelationshipStatus.NOT_STARTED]: 'Not Started',
  [SurveyRelationshipStatus.OPEN]: 'Open',
  [SurveyRelationshipStatus.CLOSED]: 'Closed',
};

export const SurveyRelationshipTypes = [
  { name: 'Event', value: 'event' },
  { name: 'Event Type', value: 'event_type' },
  { name: 'Article', value: 'article' },
  { name: 'Video', value: 'video' },
  { name: 'Codelab', value: 'codelab' },
  { name: 'Course', value: 'course' },
  { name: 'Program', value: 'program' },
  { name: 'Session', value: 'session' },
  { name: 'Track', value: 'track' },
];
