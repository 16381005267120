import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import RadioField from 'app/inputs/components/RadioField';
import entitiesAction from 'entities/actions';
import InputLabel from 'inputs/components/InputLabel';
import TextField from 'inputs/components/TextField';
import Button from 'shared/components/Button';
import Form from 'shared/components/OldForm';
import * as formValidations from 'shared/formValidations';
import { pick, map } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

import './user-add-form.scss';

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const UserAddForm = ({ error, invalid, roleOptions, submitting, handleSubmit }) => {
  const handleOnSubmit = () => {
    return handleSubmit(
      onSubmitActions(entitiesAction.userData.create.toString(), (values) => {
        const payload = pick(values, ['name', 'email', 'role']);

        return {
          key: 'userAdd',
          body: payload,
        };
      })
    );
  };

  return (
    <form className="user-add-form" onSubmit={handleOnSubmit()}>
      <Form.ErrorContainer invalid={invalid} error={error} submitting={submitting} />

      <Field name="name" label="Full Name" required component={TextField} />
      <Field name="email" label="Email" required component={TextField} />

      <InputLabel>Role</InputLabel>
      <div className="role-selector">
        {map(roleOptions, (item, i) => (
          <Field
            component={RadioField}
            name="role"
            label={item.display}
            id={item.value}
            optionValue={item.value}
            key={i}
          />
        ))}
      </div>

      <SubmitSection>
        <Button type="submit" disabled={submitting} alt="Add user and send invite" size="large">
          Add and Send Invite
        </Button>
      </SubmitSection>
    </form>
  );
};

UserAddForm.propTypes = {
  error: PropTypes.object,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  roleOptions: PropTypes.array,
};

export default reduxForm({
  form: 'userAdd',
  validate: formValidations.createValidator({
    name: formValidations.required(),
    email: formValidations.required(),
  }),
})(UserAddForm);
