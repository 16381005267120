import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TextInput from 'inputs/components/TextInput';
import colors from 'services/colors';
import { OldIcon } from 'shared/components/Icon';
import { map, invokeMap, get, isEmpty, filter, toLower, includes, concat } from 'vendor/lodash';

import Item from './Item';

export const ItemList = styled.ul`
  list-style: none;
  background: #fff;
  margin: 0;
  padding: 0;

  ${({ searchable }) =>
    searchable &&
    `
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  `}
`;

const SearchInputContainer = styled.div`
  padding: 3px 3px;
`;

const SearchIcon = styled(OldIcon)`
  color: ${colors.neutral200};
`;

const CloseIcon = styled(OldIcon)``;

class ListSelector extends React.Component {
  constructor(props) {
    super(props);

    if (props.searchInputRef) {
      this.localSearchInputRef = props.searchInputRef;
    } else {
      this.localSearchInputRef = React.createRef();
    }

    this.state = {
      searchQuery: '',
    };
  }

  onItemClick = (value) => {
    const { selecteds, onChange, singleSelect } = this.props;

    let selectedValues;

    if (singleSelect) {
      selectedValues = [value.toString()];
    } else {
      selectedValues = invokeMap([...selecteds], 'toString');
      const index = selectedValues.indexOf(value.toString());

      if (index >= 0) {
        selectedValues.splice(index, 1);
      } else {
        selectedValues.push(value.toString());
      }
    }

    this.clearSearch(selectedValues);

    if (onChange) {
      onChange(selectedValues);
    }
  };

  onAllClick = () => {
    const { onChange } = this.props;

    if (onChange) onChange([]);
  };

  getItemComponents = () => {
    const { items, singleSelect, children } = this.props;

    if (children) {
      return React.Children.map(children, (child) => {
        if (!get(child, 'props.item')) return child;

        return React.cloneElement(child, {
          isChecked: this.isSelected(child.props.item),
          onClick: this.onItemClick,
          singleSelect,
        });
      });
    }

    return map(items, (item) => (
      <Item
        key={item.value}
        item={item}
        isChecked={this.isSelected(item)}
        onClick={this.onItemClick}
        singleSelect={singleSelect}
      />
    ));
  };

  getItemsToDisplay = () => {
    const { onSearchChange } = this.props;
    const { searchQuery } = this.state;

    const components = this.getItemComponents();

    if (!isEmpty(searchQuery) && isEmpty(onSearchChange)) {
      return filter(components, (c) =>
        includes(toLower(get(c, 'props.item.name')), toLower(searchQuery))
      );
    }

    return components;
  };

  handleSearchChange = (e, selectedValues) => {
    const { onSearchChange } = this.props;

    const value = get(e, 'target.value', e);

    this.setState({ searchQuery: value });
    if (onSearchChange) onSearchChange(value, selectedValues);
  };

  clearSearch = (selectedValues) => {
    this.handleSearchChange('', selectedValues);
    if (this.localSearchInputRef.current) {
      this.localSearchInputRef.current.focus();
    }
  };

  isAllSelected = () => {
    const { selecteds } = this.props;
    return !selecteds || selecteds.length === 0;
  };

  isSelected = (item) => {
    const { selecteds } = this.props;

    if (isEmpty(item)) {
      return false;
    }

    const selectedValues = invokeMap([...selecteds], 'toString');
    const index = selectedValues.indexOf(item.value.toString());

    return index >= 0;
  };

  render = () => {
    const { singleSelect, emptyOptionText, searchable } = this.props;
    const { searchQuery } = this.state;

    const itemsToDisplay = this.getItemsToDisplay();
    const orderedList = concat(
      filter(itemsToDisplay, (i) => get(i, 'props.isChecked')),
      filter(itemsToDisplay, (i) => !get(i, 'props.isChecked'))
    );

    return (
      <ItemList searchable={searchable}>
        {searchable && (
          <SearchInputContainer>
            <TextInput
              inputRef={this.localSearchInputRef}
              onChange={this.handleSearchChange}
              value={searchQuery}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isEmpty(searchQuery) ? (
                      <SearchIcon name="magnify" />
                    ) : (
                      <CloseIcon name="close" onClick={this.clearSearch} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </SearchInputContainer>
        )}
        {(!singleSelect || emptyOptionText) && isEmpty(searchQuery) && (
          <Item
            key="allSelector"
            item={{ name: emptyOptionText || 'Select All' }}
            isChecked={this.isAllSelected()}
            onClick={this.onAllClick}
            singleSelect={singleSelect}
            notRemovable
          />
        )}
        {orderedList}
      </ItemList>
    );
  };
}

ListSelector.defaultProps = {
  selecteds: [],
};

ListSelector.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  items: PropTypes.array,
  selecteds: PropTypes.array,
  onChange: PropTypes.func,
  singleSelect: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  searchable: PropTypes.bool,
  searchInputRef: PropTypes.object,

  onSearchChange: PropTypes.func,
};

export default ListSelector;
