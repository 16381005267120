import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE,
  MENTORSHIP_REQUEST_STATUS_OPEN,
} from 'app/program/constants';
import { useCurrentUser } from 'app/shared/hooks';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Text from 'shared/components/Text';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '../Modal';
import UserHighlight from '../UserHighlight';

import { MentorshipSuggestionPill } from './MentorshipSuggestionPill';

const ModalContent = styled.div`
  position: relative;
  max-height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 18px;
`;

export const MentorshipSuggestionModal = ({
  mentorshipRequest,
  title,
  onClose,
  isCompleted,
  onDismiss,
}) => {
  const { id: currentUserId } = useCurrentUser();

  const mentorOrMentee =
    mentorshipRequest.kind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE ? 'mentee' : 'mentor';

  const currentUserIsSender = currentUserId === mentorshipRequest.sender_id;
  const suggestionLabel = currentUserIsSender
    ? `You suggested this ${mentorOrMentee}: `
    : `Suggested ${mentorOrMentee}: `;

  const canDismiss =
    mentorshipRequest.status === MENTORSHIP_REQUEST_STATUS_OPEN &&
    mentorshipRequest.receiver_id === currentUserId;

  return (
    <Modal title={title} handleClose={onClose} maxWidth={600} overflow="hidden">
      <ModalBody fullSize scrollable>
        <ModalContent>
          <UserHighlight
            user={currentUserIsSender ? mentorshipRequest.receiver : mentorshipRequest.sender}
            nameColor={isCompleted ? colors.neutral500 : colors.neutral900}
          />
          <MentorshipSuggestionPill
            mentorshipRequest={mentorshipRequest}
            suggestionLabel={suggestionLabel}
          />
          <Text size="p">{mentorshipRequest.note}</Text>
        </ModalContent>
      </ModalBody>
      <ModalFooter variant="buttons">
        {canDismiss && (
          <ModalFooterButton type="button" color="error" onClick={onDismiss}>
            Dismiss
          </ModalFooterButton>
        )}
        <ModalFooterButton
          type="button"
          color="primary"
          route={mapRoute('userProfile', { id: mentorshipRequest.suggested.id })}
        >
          View Profile
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

MentorshipSuggestionModal.propTypes = {
  title: PropTypes.string,
  mentorshipRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onDismiss: PropTypes.func,
  isCompleted: PropTypes.bool,
};
