import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import UserHighlight from 'shared/components/UserHighlight';
import { map } from 'vendor/lodash';

const UserList = styled.div``;

const UserItem = styled.div`
  border-bottom: 1px solid ${colors.neutral200};
  padding: 12px 0;
  &:last-child {
    border: none;
  }
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const GroupUserList = (props) => {
  const { users, isLoadingMore, loadMoreLink, fetchNextPage } = props;
  return (
    <UserList>
      {map(users, (u) => (
        <UserItem key={u.id}>
          <UserHighlight user={u} />
        </UserItem>
      ))}
      {(isLoadingMore || Boolean(loadMoreLink)) && (
        <LoadMoreContainer>
          <LoadMoreButton
            isLoading={isLoadingMore}
            show={Boolean(loadMoreLink)}
            onClick={fetchNextPage}
            size="small"
            type="button"
          />
        </LoadMoreContainer>
      )}
    </UserList>
  );
};

GroupUserList.propTypes = {
  users: PropTypes.array,
  fetchNextPage: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  loadMoreLink: PropTypes.string,
};

export default GroupUserList;
