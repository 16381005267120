// eslint-disable-next-line no-unused-vars
import React from 'react';

import Dashboard from 'backoffice/components/Dashboard';
import { useCurrentExpressionWithoutPage } from 'backoffice/utils';
import { ApiURLs } from 'services/requests-base';
import { STATUS_DONE } from 'shared/constants';

import { useAssignmentsContext } from './Context';

const Actions = () => {
  const expression = useCurrentExpressionWithoutPage();

  const { fetchFiltersStatus, exportCSVURL, dashboardName } = useAssignmentsContext();

  if (fetchFiltersStatus !== STATUS_DONE) return null;

  const buildExportUrl = (rqlExpression) => {
    const baseUrl = ApiURLs[exportCSVURL]();
    return `${baseUrl}?${rqlExpression}`;
  };

  return (
    <Dashboard.ExportCsvButton
      dashboardName={dashboardName}
      buttonUrl={buildExportUrl(expression)}
    />
  );
};

export { Actions };
