import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import TextField from 'inputs/components/TextField';
import colors from 'services/colors';
import {
  getGoogleSlidesEmbedLink,
  getGoogleDocsEmbedLink,
  getGoogleSheetsEmbedLink,
} from 'services/embed';
import ButtonLink from 'shared/components/ButtonLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { filter, map, trim, includes } from 'vendor/lodash';

const CloudDocsURLLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DuplicateWarning = styled.div`
  color: ${colors.alert600};
  font-size: 14px;
  margin-top: 4px;
`;

const ButtonLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const getEmbedLink = (url) => {
  if (!url) return undefined;
  const link =
    getGoogleSlidesEmbedLink(url) || getGoogleDocsEmbedLink(url) || getGoogleSheetsEmbedLink(url);
  return link;
};

const validateRequired = Form.validations.required();

const validateUrl = (values, allValues, { embedLink }) => {
  if (!embedLink) return 'This format is not supported. Please enter a Google File URL.';
  return undefined;
};

const CloudDocModal = ({
  handleClose,
  embedLink,
  handleSubmit,
  invalid,
  pristine,
  editorState,
}) => {
  // Grab all the current embedded links in order to validate if we're adding duplicate content
  const contentState = editorState.getCurrentContent();
  const filteredLinks = filter(contentState.getBlocksAsArray(), (b) => b.getEntityAt(0));
  const embeddedLinks = map(filteredLinks, (b) => {
    const entity = contentState.getEntity(b.getEntityAt(0));
    return entity.data.src;
  });

  const hasDuplicate = includes(embeddedLinks, embedLink);

  const embedHelpArticleLink =
    'https://help.plusplus.app/en/articles/6082524-how-to-embed-media-in-an-article';

  return (
    <Modal handleClose={handleClose} title="Insert Cloud Doc">
      <ModalBody>
        <Form.FieldGroup>
          <CloudDocsURLLabelContainer>
            <ButtonLinkWrapper>
              <ButtonLink url={embedHelpArticleLink} target="_blank">
                <Text size="h5">How to embed media</Text>
              </ButtonLink>
            </ButtonLinkWrapper>
          </CloudDocsURLLabelContainer>
          <Field
            placeholder="Insert URL"
            label="Published Cloud Doc Url"
            name="cloud_doc_url"
            component={TextField}
            validate={[validateRequired, validateUrl]}
          />
          {hasDuplicate && (
            <DuplicateWarning>
              Note,{' '}
              <ButtonLink url={embedLink} target="_blank">
                another cloud doc
              </ButtonLink>{' '}
              with the same URL already exists.
            </DuplicateWarning>
          )}
        </Form.FieldGroup>
        <iframe
          width="500px"
          height="200px"
          allowFullScreen
          frameBorder="0"
          src={embedLink}
          title={embedLink}
        />
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose} color="error" size="large">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} size="large" disabled={invalid || pristine}>
          Insert
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

CloudDocModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,

  embedLink: PropTypes.string,

  invalid: PropTypes.bool,
  pristine: PropTypes.bool,

  editorState: PropTypes.object,
};

const CloudDocModalForm = reduxForm({
  form: 'cloudDoc',
  onSubmit: (values, dispatch, { cloudDocUrl, handleInsertClick }) => {
    handleInsertClick(cloudDocUrl);
  },
})(CloudDocModal);

const formSelector = formValueSelector('cloudDoc');
const mapStateToProps = (state) => {
  const cloudDocUrl = formSelector(state, 'cloud_doc_url');
  const parsedUrl = trim(cloudDocUrl);
  return {
    cloudDocUrl: parsedUrl,
    embedLink: getEmbedLink(parsedUrl),
  };
};

export default connect(mapStateToProps)(CloudDocModalForm);
