import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import type { ValueOf } from 'type-fest';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import {
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
  CONTENT_TYPES,
  FACILITATOR_ROLES,
  LEARNING_TYPE_TO_CONTENT_TYPE_MAPPING,
  LEARNING_TYPES,
} from 'catalog/constants';
import { getDefaultCatalogFiltersQuerystring } from 'catalog/utils';
import { useFetchDataListPaginated } from 'data-fetching/hooks';
import { ATTENDANCE_STATUSES } from 'enrollments/constants';
import actions from 'entities/actions';
import { contentSchema, eventSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { queries } from 'queries';
import { useEntityMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import { formatStickyFilters, getStickyFiltersFromUser } from 'services/sticky-filters';
import { ContentItem } from 'shared-content-item/interfaces';
import { shouldShowAttendees } from 'shared-content-item/services';
import { STATUS_NOT_REQUESTED } from 'shared/constants';
import {
  useCurrentUser,
  useEncodedCurrentRoute,
  useLabels,
  useLocalStorage,
  useToggles,
} from 'shared/hooks';
import {
  CONTENT_ITEM_PERMISSIONS,
  SEE_ARTICLE_FEEDBACKS,
  SEE_CODELAB_FEEDBACK,
  SEE_COURSE_FEEDBACKS,
  SEE_LINKEDCONTENT_FEEDBACKS,
  SEE_MENTORSHIP_PROGRAM_FEEDBACK,
  SEE_TASK_FEEDBACKS,
  SEE_TRACK_FEEDBACKS,
  SEE_VIDEO_FEEDBACKS,
} from 'shared/permissions';
import { filter, get, includes, indexOf, isEmpty, map, noop, sortBy, toLower } from 'vendor/lodash';
import rql from 'vendor/rql';

export const useLearningTypeLabels = ({ isPlural = false } = {}): {
  [key in ValueOf<typeof LEARNING_TYPES>]: string;
} => {
  const labels = useLabels();

  return {
    [LEARNING_TYPES.assessments]: isPlural
      ? labels.label_assessment_plural
      : labels.label_assessment,
    [LEARNING_TYPES.articles]: isPlural ? labels.label_article_plural : labels.label_article,
    [LEARNING_TYPES.codelabs]: isPlural ? labels.label_codelab_plural : labels.label_codelab,
    [LEARNING_TYPES.courses]: isPlural ? labels.label_course_plural : labels.label_course,
    [LEARNING_TYPES.events]: isPlural ? 'Events' : 'Event',
    [LEARNING_TYPES.event_types]: isPlural
      ? labels.label_event_type_plural
      : labels.label_event_type,
    [LEARNING_TYPES.linkedcontent]: isPlural
      ? labels.label_linkedcontent_plural
      : labels.label_linkedcontent,
    [LEARNING_TYPES.officehour_programs]: isPlural
      ? labels.label_program_plural
      : labels.label_program,
    [LEARNING_TYPES.programs]: isPlural ? labels.label_program_plural : labels.label_program,
    [LEARNING_TYPES.mentorship_programs]: isPlural
      ? labels.label_mentorship_program_plural
      : labels.label_mentorship_program,
    [LEARNING_TYPES.questions]: isPlural ? labels.label_question_plural : labels.label_question,
    [LEARNING_TYPES.text_questions]: isPlural ? 'Short Answers' : 'Short Answer',
    [LEARNING_TYPES.multiple_choice_questions]: isPlural ? 'Multiple Choices' : 'Multiple Choice',
    [LEARNING_TYPES.scheduled_tracks]: isPlural
      ? `Scheduled ${labels.label_track_plural}`
      : `Scheduled ${labels.label_track}`,
    [LEARNING_TYPES.tasks]: isPlural ? labels.label_task_plural : labels.label_task,
    [LEARNING_TYPES.tracks]: isPlural ? labels.label_track_plural : labels.label_track,
    [LEARNING_TYPES.videos]: isPlural ? 'Videos' : 'Video',
    [LEARNING_TYPES.all]: isPlural ? 'Content Items' : 'Content Item',
  };
};

export const useGetContentTypeLabel = (isPlural = false): ((string) => string) => {
  const labels = useLearningTypeLabels({ isPlural });

  return (contentType: string) => {
    return labels[CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType]] || contentType;
  };
};

export const useCatalogFetchData = ({
  selectedLearningTypes,
  rqlExpression,
}: CatalogFetchDataProps) => {
  const pageSize = 24;

  // Learning types are not supported by the content item API, so they need to be
  // converted to their respective content types
  const selectedContentTypes = map(
    selectedLearningTypes,
    (learningType) => LEARNING_TYPE_TO_CONTENT_TYPE_MAPPING[learningType]
  );
  const defaultCatalogFilters = getDefaultCatalogFiltersQuerystring({
    selectedContentTypes,
    pageSize,
  });
  const search = `${defaultCatalogFilters}&${rqlExpression}`;
  return useFetchDataListPaginated({
    ...queries.content_items.list(search),
    enabled: !isEmpty(rqlExpression), // This will never be empty, it will at least have the ordering
  });
};

interface CatalogFetchDataProps {
  selectedLearningTypes: string[];
  rqlExpression: string;
}

export const useStickyFilters = () => {
  const { public_id: userPublicId, client_encryption_key: userEncryptionKey } = useCurrentUser();
  const [stickyFiltersObject, updateValue] = useLocalStorage('sticky_filters', 1);
  const stickyFiltersFromUser = getStickyFiltersFromUser(
    stickyFiltersObject,
    userPublicId,
    userEncryptionKey
  );
  const updateStickyFilters = useCallback(
    (filterPageName: string, newFilters: object) =>
      updateValue({
        [userPublicId]: formatStickyFilters(
          stickyFiltersFromUser || {},
          filterPageName,
          newFilters,
          userEncryptionKey
        ),
      }),
    [stickyFiltersFromUser, updateValue, userEncryptionKey, userPublicId]
  );

  const getStickyFilters = (filterPageName: string) =>
    get(stickyFiltersFromUser, filterPageName, null);

  return { getStickyFilters, updateStickyFilters };
};

export const useContentTypeRoutes = ({ withOriginRoute = false } = {}) => {
  const currentRoute = useEncodedCurrentRoute();
  const withOrigin = (path) => {
    return withOriginRoute ? `${path}?origin=${currentRoute}` : path;
  };

  const questionRoutes = {
    // Questions don't have direct link to its detail. They should be accessed only under an assessment.
    create: withOriginRoute
      ? `${mapRoute('questionCreate')}?origin=${currentRoute}`
      : mapRoute('questionCreate'),
    edit: ({ public_id }) =>
      withOriginRoute
        ? `${mapRoute('questionEdit', { public_id })}?origin=${currentRoute}`
        : mapRoute('questionEdit', { public_id }),
  };

  return {
    [CONTENT_TYPES.question]: questionRoutes,
    [CONTENT_TYPES.text_question]: questionRoutes,
    [CONTENT_TYPES.multiple_choice_question]: questionRoutes,
    [CONTENT_TYPES.article]: {
      create: withOriginRoute
        ? `${mapRoute('articleCreate')}?origin=${currentRoute}`
        : mapRoute('articleCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('standAloneArticleDetail', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('standAloneArticleDetail', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('articleEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('articleEdit', { public_id }),
    },
    [CONTENT_TYPES.assessment]: {
      create: withOriginRoute
        ? `${mapRoute('assessmentCreate')}?origin=${currentRoute}`
        : mapRoute('assessmentCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('assessmentDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('assessmentDetails', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('assessmentEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('assessmentEdit', { public_id }),
    },
    [CONTENT_TYPES.linkedcontent]: {
      create: withOriginRoute
        ? `${mapRoute('linkedContentCreate')}?origin=${currentRoute}`
        : mapRoute('linkedContentCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('linkedContentDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('linkedContentDetails', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('linkedContentEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('linkedContentEdit', { public_id }),
    },
    [CONTENT_TYPES.codelab]: {
      create: withOrigin(mapRoute('codelabImport')),
      details: ({ public_id_and_slug }) =>
        withOrigin(mapRoute('standAloneCodelabDetail', { public_id_and_slug })),
      edit: ({ public_id_and_slug }) => withOrigin(mapRoute('codelabEdit', { public_id_and_slug })),
    },
    [CONTENT_TYPES.course]: {
      create: withOriginRoute
        ? `${mapRoute('courseImport')}?origin=${currentRoute}`
        : mapRoute('courseImport'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('standAloneCourseDetail', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('standAloneCourseDetail', { public_id_and_slug }),
      edit: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('courseEdit', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('courseEdit', { public_id_and_slug }),
    },
    [CONTENT_TYPES.event]: {
      create: withOriginRoute
        ? `${mapRoute('eventNew')}?origin=${currentRoute}`
        : mapRoute('eventNew'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('eventDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('eventDetails', { public_id_and_slug }),
      edit: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('eventEdit', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('eventEdit', { public_id_and_slug }),
    },
    [CONTENT_TYPES.eventtype]: {
      create: withOriginRoute
        ? `${mapRoute('eventTypeCreate')}?origin=${currentRoute}`
        : mapRoute('eventTypeCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('eventTypeDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('eventTypeDetails', { public_id_and_slug }),
      edit: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('eventTypeEdit', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('eventTypeEdit', { public_id_and_slug }),
    },
    [CONTENT_TYPES.program]: {
      create: withOriginRoute
        ? `${mapRoute('programCreate')}?origin=${currentRoute}`
        : mapRoute('programCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('programDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('programDetails', { public_id_and_slug }),
      edit: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('programEdit', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('programEdit', { public_id_and_slug }),
    },
    [CONTENT_TYPES.mentorship_program]: {
      create: withOriginRoute
        ? `${mapRoute('mentorshipProgramCreate')}?origin=${currentRoute}`
        : mapRoute('mentorshipProgramCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('mentorshipProgramDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('mentorshipProgramDetails', { public_id_and_slug }),
      edit: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('mentorshipProgramEdit', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('mentorshipProgramEdit', { public_id_and_slug }),
    },
    [CONTENT_TYPES.track]: {
      create: withOriginRoute
        ? `${mapRoute('trackCreate')}?origin=${currentRoute}`
        : mapRoute('trackCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('trackDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('trackDetails', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('trackEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('trackEdit', { public_id }),
    },
    [CONTENT_TYPES.scheduled_track]: {
      create: withOriginRoute
        ? `${mapRoute('scheduledTrackCreate')}?origin=${currentRoute}`
        : mapRoute('scheduledTrackCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('scheduledTrackDetails', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('scheduledTrackDetails', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('scheduledTrackEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('scheduledTrackEdit', { public_id }),
    },
    [CONTENT_TYPES.video]: {
      create: withOriginRoute
        ? `${mapRoute('videoCreate')}?origin=${currentRoute}`
        : mapRoute('videoCreate'),
      details: ({ public_id_and_slug }) =>
        withOriginRoute
          ? `${mapRoute('standAloneVideoDetail', { public_id_and_slug })}?origin=${currentRoute}`
          : mapRoute('standAloneVideoDetail', { public_id_and_slug }),
      edit: ({ public_id }) =>
        withOriginRoute
          ? `${mapRoute('videoEdit', { public_id })}?origin=${currentRoute}`
          : mapRoute('videoEdit', { public_id }),
    },
    [CONTENT_TYPES.task]: {
      // Tasks shouldn't have direct link to its detail. They should be accessed only under a track.
      create: `${mapRoute('taskCreate')}?origin=${currentRoute}`,
      edit: ({ public_id }) => `${mapRoute('taskEdit', { public_id })}?origin=${currentRoute}`,
    },
  };
};

export const useLearningTypeFilterOptions = () => {
  const labels = useLabels();
  const { catalog_sections_order: catalogSectionsOrder } = useCurrentUser();
  const { toggle_mentorship_programs: toggleMentorshipPrograms } = useToggles();

  const baseLearningTypes = [
    { value: LEARNING_TYPES.articles, name: labels.label_article_plural },
    { value: LEARNING_TYPES.codelabs, name: labels.label_codelab_plural },
    { value: LEARNING_TYPES.courses, name: labels.label_course_plural },
    { value: LEARNING_TYPES.event_types, name: labels.label_event_type_plural },
    { value: LEARNING_TYPES.programs, name: labels.label_program_plural },
    { value: LEARNING_TYPES.tracks, name: labels.label_track_plural },
    { value: LEARNING_TYPES.videos, name: 'Videos' },
    { value: LEARNING_TYPES.assessments, name: labels.label_assessment_plural },
    { value: LEARNING_TYPES.linkedcontent, name: labels.label_linkedcontent_plural },
    ...(toggleMentorshipPrograms
      ? [
          {
            value: LEARNING_TYPES.mentorship_programs,
            name: labels.label_mentorship_program_plural,
          },
        ]
      : []),
  ];

  const learningTypesInCatalogSectionsOrder = filter(
    baseLearningTypes,
    (learningType) => indexOf(catalogSectionsOrder, learningType.value) > -1
  );

  return sortBy(learningTypesInCatalogSectionsOrder, (learningType) =>
    indexOf(catalogSectionsOrder, learningType.value)
  );
};

export const useContentPeople = (content: ContentItem, userStatus: string[] = ['active']) => {
  const currentUser = useCurrentUser();
  const shouldFetchAttendees =
    shouldShowAttendees(currentUser, content) ||
    includes(get(content, 'permissions'), CONTENT_ITEM_PERMISSIONS.see_assignees);
  const isEvent = content.content_type === CONTENT_TYPES.event;
  const action = isEvent ? actions.enrollment.retrieveList : actions.assignment.list;

  const [
    fetchAssignments,
    {
      data: { results: assignments, count: assignmentsCount },
      status,
    },
  ] = useEntities(action, null);

  const loadEngagedPeople = useCallback(() => {
    // Ensure to fetch only once
    if (shouldFetchAttendees && content && status === STATUS_NOT_REQUESTED) {
      const filter = isEvent
        ? {
            event: content.id,
            status: {
              $in: [
                ATTENDANCE_STATUSES.enrolled,
                ATTENDANCE_STATUSES.waitlisted,
                ATTENDANCE_STATUSES.checked_in,
              ],
            },
          }
        : {
            content_item: content.public_id,
            user_account_status: {
              $in: userStatus,
            },
            state: {
              $in: [
                ASSIGNMENT_STATES.not_started,
                ASSIGNMENT_STATES.in_progress,
                ASSIGNMENT_STATES.completed,
                ASSIGNMENT_STATES.dropped,
              ],
            },
          };
      fetchAssignments(
        rql({
          ...filter,
          page_size: 16,
          view_mode: 'engaged_people',
        }),
        { skipSchema: true }
      );
    }
  }, [content, fetchAssignments, shouldFetchAttendees, status, isEvent]);

  useEffect(() => {
    loadEngagedPeople();
  }, [loadEngagedPeople]);

  const getContentOrganizers = () => {
    const organizers = filter(content.facilitators, (facilitator) =>
      includes([FACILITATOR_ROLES.main_organizer, FACILITATOR_ROLES.co_organizer], facilitator.role)
    );
    return map(organizers, (organizer) => organizer.user);
  };

  const getContentMaintainers = () => {
    const maintainers = filter(content.facilitators, (facilitator) =>
      includes(
        [
          FACILITATOR_ROLES.maintainer,
          FACILITATOR_ROLES.co_maintainer,
          FACILITATOR_ROLES.author,
          FACILITATOR_ROLES.co_author,
          FACILITATOR_ROLES.main_host,
          FACILITATOR_ROLES.co_host,
          FACILITATOR_ROLES.presenter,
        ],
        facilitator.role
      )
    );

    return map(maintainers, (maintainer) => maintainer.user);
  };

  const organizers = getContentOrganizers();
  const maintainers = getContentMaintainers();
  const engagedPeople = map(assignments, (assignment) => assignment.user);
  // Content item assignments 2.0 use 'total_assignments'.
  // 'assignments_count' will be deprecated once all content are migrated.
  const engagedPeopleCount =
    assignmentsCount || get(content, 'total_assignments', get(content, 'assignments_count', 0));
  const showPeople = !isEmpty(organizers) || !isEmpty(maintainers) || !isEmpty(engagedPeople);

  return {
    showPeople,
    organizers,
    maintainers,
    engagedPeople,
    engagedPeopleCount: engagedPeopleCount ?? 0,
    assignmentsCount,
  };
};

export const useContentFeedback = (content, contentType) => {
  const FEEDBACK_PAGE_SIZE = 21;

  const getSeeFeedbackPermission = (content) => {
    switch (content.content_type) {
      case CONTENT_TYPES.video:
        return includes(content.permissions, SEE_VIDEO_FEEDBACKS);
      case CONTENT_TYPES.linkedcontent:
        return includes(content.permissions, SEE_LINKEDCONTENT_FEEDBACKS);
      case CONTENT_TYPES.codelab:
        return includes(content.permissions, SEE_CODELAB_FEEDBACK);
      case CONTENT_TYPES.course:
        return includes(content.permissions, SEE_COURSE_FEEDBACKS);
      case CONTENT_TYPES.article:
        return includes(content.permissions, SEE_ARTICLE_FEEDBACKS);
      case CONTENT_TYPES.track:
        return includes(content.permissions, SEE_TRACK_FEEDBACKS);
      case CONTENT_TYPES.task:
        return includes(content.permissions, SEE_TASK_FEEDBACKS);
      case CONTENT_TYPES.mentorship_program:
        return includes(content.permission, SEE_MENTORSHIP_PROGRAM_FEEDBACK);
      default:
        return false;
    }
  };

  // Content item assignments 2.0 use shared permissions from 'CONTENT_ITEM_PERMISSIONS'.
  // 'getSeeFeedbackPermission' will be deprecated once all content are migrated.
  const showFeedback =
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.see_feedback) ||
    getSeeFeedbackPermission(content);

  const learningTypes = [
    LEARNING_TYPES.articles,
    LEARNING_TYPES.videos,
    LEARNING_TYPES.tracks,
    LEARNING_TYPES.tasks,
    LEARNING_TYPES.codelabs,
    LEARNING_TYPES.linkedcontent,
    LEARNING_TYPES.mentorship_programs,
  ];

  const getAction = () => {
    if (includes(learningTypes, contentType)) {
      return actions.content.feedbackList;
    }

    if (contentType === LEARNING_TYPES.courses) {
      return actions.courseRegistration.feedbackList;
    }

    if (contentType === LEARNING_TYPES.events) {
      return actions.event.feedbackList;
    }

    return null;
  };

  const action = getAction();

  const [
    fetchFeedback,
    {
      data: { results: feedback },
      status: feedbackStatus,
    },
  ] = useEntities(action, null);

  useEffect(() => {
    if (!showFeedback) {
      return;
    }

    if (includes(learningTypes, contentType)) {
      fetchFeedback({
        content_item: content.public_id,
        page_size: FEEDBACK_PAGE_SIZE,
        o: '-completed',
        only_with_text: true,
      });
    } else if (contentType === LEARNING_TYPES.courses) {
      fetchFeedback({
        course: [content.id],
        page_size: FEEDBACK_PAGE_SIZE,
        o: '-created',
        only_with_text: true,
        view_mode: 'feedback',
      });
    } else if (contentType === LEARNING_TYPES.events) {
      fetchFeedback(content.public_id);
    }
  }, []);

  const currentUserAssignment = content.assignment || content.user_enrollment;

  return { showFeedback, currentUserAssignment, feedback, feedbackStatus };
};

export const useEventTypeFieldLabels = () => {
  const { label_internal_groups: labelInternalGroups, label_channel: labelChannel } = useLabels();
  const learningTypeLabels = useLearningTypeLabels();

  return {
    presenters_ids: 'Presenters',
    organizer_id: 'Organizer',
    co_organizers_ids: 'Co-Organizer(s)',
    name: 'Event Title',
    content_body: 'Description',
    cover: 'Cover',
    tags: 'Categories, Tags & Flexible Filters',
    location_id: 'Location',
    is_local: 'Event is In Person',
    is_online: 'Event is Online',
    watch_link: 'Virtual Conference Link',
    duration: 'Expected Duration',
    external_link_description: 'Enrollment Instructions',
    external_link: 'Enrollment Link',
    external_survey_link: 'Third-Party Survey Link',
    is_hidden: 'Event is Hidden',
    groups_ids: `Restrict Event to ${toLower(labelInternalGroups)}`,
    hide_enrollees: 'Hide Attendees',
    enrollment_limit: 'Enrollment Cap (In Person)',
    wait_list_limit: 'Waitlist Cap (In Person)',
    online_enrollment_limit: 'Enrollment Cap (Online)',
    online_wait_list_limit: 'Waitlist Cap (Online)',
    survey_relationships: 'Surveys',
    office_hour_id: learningTypeLabels[LEARNING_TYPES.programs],
    channel_id: labelChannel,
  };
};

export const useContentItemFetcher = (publicId: string, type: string) => {
  const {
    toggle_composable_tracks: toggleComposableTracks,
    toggle_mentorship_program: toggleMentorshipProgram,
  } = useToggles();

  const [fetchContent, { data: content, status: contentStatus }] = useEntities(
    actions.content.retrieveDetails,
    null,
    {
      schema: contentSchema,
    }
  );

  const [fetchEvent, { data: event, status: eventStatus }] = useEntities(
    actions.event.retrieveDetails,
    null,
    { schema: eventSchema }
  );

  if (
    includes(
      [
        CONTENT_TYPES.article,
        CONTENT_TYPES.assessment,
        CONTENT_TYPES.codelab,
        CONTENT_TYPES.course,
        CONTENT_TYPES.eventtype,
        CONTENT_TYPES.linkedcontent,
        CONTENT_TYPES.task,
        CONTENT_TYPES.video,
      ],
      type
    ) ||
    (type === CONTENT_TYPES.track && toggleComposableTracks) ||
    (type === CONTENT_TYPES.mentorship_program && toggleMentorshipProgram)
  ) {
    return {
      fetchItem: () => fetchContent(publicId),
      contentItem: content,
      status: contentStatus,
    };
  }
  if (type === CONTENT_TYPES.event) {
    return { fetchItem: () => fetchEvent(publicId), contentItem: event, status: eventStatus };
  }
  if (includes([CONTENT_TYPES.multiple_choice_question, CONTENT_TYPES.text_question], type)) {
    return {
      fetchItem: () => fetchContent(publicId, { content_type: type }),
      contentItem: content,
      status: contentStatus,
    };
  }

  return { fetchItem: noop, contentItem: null, status: null };
};

export const useContentOnSubmitSuccessHandler = () => {
  const history = useHistory();

  const { label_track_plural: labelTrackPlural } = useLabels();

  const { trackEntityActivity } = useEntityMetrics();

  const contentTypeRoutes = useContentTypeRoutes();
  const getContentTypeLabel = useGetContentTypeLabel();

  const getRedirectRoute = (backRoute: string, isEdit: boolean, result: ContentItem): string => {
    const {
      content_type: contentType,
      is_inline: isInline,
      public_id: publicId,
      public_id_and_slug: publicIdAndSlug,
    } = result;

    const catalogRoute = mapRoute('unifiedCatalogList');
    const detailRoute = contentTypeRoutes[contentType]?.details?.({
      public_id_and_slug: publicIdAndSlug,
    });
    const backRouteContainsDetailRoute = includes(backRoute, publicId);

    // Standalone Create
    // Not every content item has detail page
    if (!isInline && !isEdit) return detailRoute || backRoute;

    // Standalone Edit
    if (!isInline && isEdit) return backRoute;

    // Inline
    return backRouteContainsDetailRoute ? catalogRoute : backRoute;
  };

  const handleContentOnSubmitSuccessHandler = ({
    backRoute,
    isEdit,
    result,
  }: {
    backRoute: string;
    isEdit: boolean;
    result: ContentItem;
  }) => {
    const { content_type: contentType, id, is_inline: isInline } = result;

    trackEntityActivity({
      entityType: contentType,
      id,
      isEdit,
    });

    const redirectRoute = getRedirectRoute(backRoute, isEdit, result);
    history.push(redirectRoute);

    const contentLabel = getContentTypeLabel(contentType);
    const toastTitle = `${contentLabel} ${isEdit ? 'modified' : 'added'} successfully!`;
    const toastText = isInline
      ? `This item can be only found and consumed within ${labelTrackPlural}.`
      : '';
    toast.success(toastTitle, toastText);
  };

  return { handleContentOnSubmitSuccessHandler };
};
