import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { useChannelRoutes } from 'features/channels/hooks/useChannelRoutes';
import { Channel } from 'features/channels/types';
import colors from 'services/colors';
import { get } from 'vendor/lodash';
import { Typography, Link, Avatar, LinkProps as MUILinkProps } from 'vendor/mui';

type LinkProps = MUILinkProps & { component: ElementType; to: string };

const ChannelLink = styled(Link)<LinkProps>`
  color: ${colors.neutral900};
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: ${colors.neutral900};
  }
`;

type ChannelBylineProps = {
  channel: Channel;
};

function ChannelByline(props: ChannelBylineProps) {
  const { channel } = props;

  const { detail: channelDetailRoute } = useChannelRoutes();
  const channelDetailRouteUrl = channelDetailRoute({
    slug: get(channel, 'slug'),
  });

  return (
    <ChannelLink component={RouterLink} to={channelDetailRouteUrl} underline="hover">
      <Avatar
        src={channel.cover}
        alt={channel.name}
        variant="square"
        sx={{ width: 24, height: 24 }}
      />
      <Typography
        variant="body2"
        component="span"
        sx={{
          fontSize: '0.75rem',
          lineHeight: '1',
        }}
      >
        by
      </Typography>
      <Typography
        variant="body2"
        component="span"
        noWrap
        sx={{
          fontSize: '0.75rem',
          lineHeight: '1.25',
          fontWeight: 500,
        }}
      >
        {channel.name}
      </Typography>
    </ChannelLink>
  );
}

export default ChannelByline;
