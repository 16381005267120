import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ContentSidebarWrapper from './ContentSidebar';
import { ContentSidebarContext } from './ContentSidebarContext';

const ContentTopbarPage = ({
  renderTopbar,
  renderSidebarContent,
  renderSidebarFooter,
  hideOpenSidebarButton,
  sidebarComponentContent,
  comesFromContent,
  children,
  updateStateFilter,
  showScroll,
}) => {
  // Start closed on mobile
  const sidebarCloseState = comesFromContent ?? false;
  const sidebarOpenState = useState(
    Boolean(renderSidebarContent) && window.innerWidth > 768 && !sidebarCloseState
  );

  return (
    <ContentSidebarContext.Provider value={sidebarOpenState}>
      {renderTopbar}
      <ContentSidebarWrapper
        hasTopbar={Boolean(renderTopbar)}
        hideOpenSidebarButton={hideOpenSidebarButton}
        renderSidebarContent={renderSidebarContent}
        renderSidebarFooter={renderSidebarFooter}
        sidebarComponentContent={sidebarComponentContent}
        sidebarOpenState={sidebarOpenState}
        updateStateFilter={updateStateFilter}
        showScroll={showScroll}
      >
        {children}
      </ContentSidebarWrapper>
    </ContentSidebarContext.Provider>
  );
};

ContentTopbarPage.propTypes = {
  renderTopbar: PropTypes.node,
  renderSidebarContent: PropTypes.func,
  renderSidebarFooter: PropTypes.func,
  hideOpenSidebarButton: PropTypes.bool,
  children: PropTypes.node,
  comesFromContent: PropTypes.bool,
  sidebarComponentContent: PropTypes.func,
  updateStateFilter: PropTypes.func,
  showScroll: PropTypes.bool,
};

export default ContentTopbarPage;
