import { ReactNode, useState } from 'react';

import SquareCheckboxInput from 'inputs/components/SquareCheckboxInput';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { Stack } from 'vendor/mui';

interface ConfirmOpenLinkModalProps {
  title?: string;
  handleConfirm: (value) => void;
  confirmButtonLabel?: string;
  handleClose: (event?: object, reason?: string) => void;
  children?: ReactNode;
}

const ConfirmOpenLinkModal = ({
  title = 'Opening external link',
  handleConfirm,
  confirmButtonLabel = 'Open',
  handleClose,
  children,
}: ConfirmOpenLinkModalProps) => {
  const [dismiss, setDismiss] = useState(false);
  return (
    <Modal
      title={title}
      handleClose={handleClose}
      width={window.innerWidth > 580 ? 580 : null}
      minBodyHeight={120}
    >
      <ModalBody>
        <Stack direction="column" spacing={2}>
          <Text size="h5" block>
            {children}
          </Text>
          <SquareCheckboxInput
            name="hide_dialog"
            label="Do not show this again"
            labelSize="h5"
            checked={dismiss}
            variant="action"
            tourId={null}
            onChange={() => setDismiss(!dismiss)}
          />
        </Stack>
      </ModalBody>
      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton variant="text" color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton type="button" color="primary" onClick={() => handleConfirm(dismiss)}>
          {confirmButtonLabel}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmOpenLinkModal;
