import PropTypes from 'prop-types';
import React from 'react';

import { useEntityMetrics } from 'services/metrics';
import Modal, { ModalBody } from 'shared/components/Modal';
import { useCurrentUser } from 'shared/hooks';
import { includes } from 'vendor/lodash';

import UserAddForm from './UserAddForm';

const UserAddModal = ({ show, handleClose, onSubmitActions, onSubmitSuccess }) => {
  const currentUser = useCurrentUser();
  const { trackEntityActivity } = useEntityMetrics();

  const handleSubmitSuccess = (result) => {
    trackEntityActivity({
      id: result.id,
      isEdit: false,
      entityType: 'user',
    });
    onSubmitSuccess();
  };

  if (!show || !includes(['admin', 'organizer'], currentUser.role)) {
    return null;
  }

  const roleOptions = [
    {
      display: 'External',
      value: 'external',
    },
    {
      display: 'Regular',
      value: 'regular',
    },
  ];

  if (currentUser.role === 'admin') {
    roleOptions.push(
      {
        display: 'Organizer',
        value: 'organizer',
      },
      {
        display: 'Admin',
        value: 'admin',
      }
    );
  }

  return (
    <Modal
      title="Add a Person"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <UserAddForm
          onSubmit={onSubmitActions}
          onSubmitSuccess={handleSubmitSuccess}
          initialValues={{ role: 'regular' }}
          roleOptions={roleOptions}
        />
      </ModalBody>
    </Modal>
  );
};

UserAddModal.propTypes = {
  show: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  onSubmitActions: PropTypes.func,
};

export default UserAddModal;
