import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { STATUS_LOADING, STATUS_LOADING_MORE } from 'app/shared/constants';
import { ACCESS_LEVELS, CONTENT_TYPES, LEARNING_TYPES, PUBLISHED_STATUS } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import AddContentMenuItems from 'content-items/components/AddContentMenuItems';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import InputLabel from 'inputs/components/InputLabel';
import SearchInput from 'inputs/components/SearchInput';
import SelectField from 'inputs/components/SelectField';
import colors from 'services/colors';
import ButtonLink from 'shared/components/ButtonLink';
import DropDownMenu from 'shared/components/DropDownMenu';
import IconButton from 'shared/components/IconButton';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import Text from 'shared/components/Text';
import { useDebounce } from 'shared/hooks';
import { map, includes, filter } from 'vendor/lodash';

import ContentSelect from './ContentSelect';

type GenericDictionary = {
  [x: string]: string | string[] | number | GenericDictionary;
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 12px;
  border-bottom: 1px solid ${colors.neutral200};
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 4px;
  }
  &:last-child {
    margin-top: 10px;
  }
`;

const SelectsWrapperLeft = styled.div`
  display: flex;
  gap: 12px;
`;

const SelectsWrapperRight = styled.div`
  display: flex;
  gap: 12px;
`;

const SelectOrderWrapper = styled.div`
  width: 98px;
`;

const SelectContentTypeWrapper = styled.div`
  width: 120px;
`;

const SelectAccessWrapper = styled.div`
  width: 165px;
`;

const ResultWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 10px;

  > * + * {
    margin-left: 4px;
  }
`;

const ListWrapper = styled.div`
  padding: 8px;

  > * + * {
    margin-top: 4px;
  }
`;

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px auto;
`;

const CONTENTS_ORDER_OPTIONS = [
  { value: 'name', label: 'A-Z' },
  { value: '-name', label: 'Z-A' },
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
];

const getContentTypeOptions = (labels) => {
  return [
    { value: CONTENT_TYPES.all, label: 'All Types' },
    { value: CONTENT_TYPES.article, label: labels[LEARNING_TYPES.articles] },
    { value: CONTENT_TYPES.codelab, label: labels[LEARNING_TYPES.codelabs] },
    { value: CONTENT_TYPES.course, label: labels[LEARNING_TYPES.courses] },
    { value: CONTENT_TYPES.eventtype, label: labels[LEARNING_TYPES.event_types] },
    { value: CONTENT_TYPES.task, label: labels[LEARNING_TYPES.tasks] },
    { value: CONTENT_TYPES.video, label: labels[LEARNING_TYPES.videos] },
  ];
};

// Rename 'Hidden' to 'Unlisted' when all forms are updated
const ACCESS_LEVEL_OPTIONS = [
  { value: [ACCESS_LEVELS.public], label: 'Public only' },
  { value: [ACCESS_LEVELS.public, ACCESS_LEVELS.unlisted], label: 'Public & Hidden' },
];

const CONTENT_PAGE_SIZE = 12;

const ContentItemPicker = ({
  contentType,
  alreadyAddedItems,
  onAdd,
  handleClose,
  handleDropDownItemClick,
  showCreateNewButton,
  assignee,
  initialSelectedItems,
}) => {
  // We're hiding the access level select because tasks are always unlisted
  const isContentTypeTask = contentType === CONTENT_TYPES.task;
  const isContentTypeAll = contentType === CONTENT_TYPES.all;
  const initialAccessLevel = isContentTypeTask
    ? [ACCESS_LEVELS.unlisted]
    : ACCESS_LEVEL_OPTIONS[0].value;

  // This state does not contain the "alreadyAddedItems" because it is not possible to uncheck an item that is already included.
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>(initialSelectedItems);
  const [searchText, setSearchText] = useState('');
  const [orderValue, setOrderValue] = useState(CONTENTS_ORDER_OPTIONS[0].value);
  const [accessValue, setAccessValue] = useState(initialAccessLevel);
  const [contentTypeValue, setContentTypeValue] = useState(CONTENT_TYPES.all);

  const debouncedSearchText = useDebounce(searchText, 500);

  const learningTypeLabelsSingle = useLearningTypeLabels();

  const [
    fetchContents,
    { data: contents, count: contentsCount, status: contentsStatus },
    loadMoreContents,
  ] = useEntities(actions.content.retrieveList, null, {
    schema: [contentSchema],
    loadMoreAction: actions.content.retrieveListLoadMore,
  });

  const refreshContents = () => {
    const filterArgs: GenericDictionary = {
      q: debouncedSearchText,
      access_level: accessValue,
      content_type: [
        CONTENT_TYPES.article,
        CONTENT_TYPES.codelab,
        CONTENT_TYPES.course,
        CONTENT_TYPES.eventtype,
        CONTENT_TYPES.task,
        CONTENT_TYPES.video,
      ],
      o: orderValue,
      page_size: CONTENT_PAGE_SIZE,
      status: PUBLISHED_STATUS,
      exclude_assigned_users: [],
    };
    if (!isContentTypeAll) {
      filterArgs.content_type = [contentType];
    }
    if (assignee) {
      filterArgs.exclude_assigned_users = [assignee.id];
    }
    if (isContentTypeAll && contentTypeValue !== CONTENT_TYPES.all) {
      filterArgs.content_type = [contentTypeValue];
    }
    fetchContents(filterArgs);
  };

  useEffect(() => {
    refreshContents();
  }, [debouncedSearchText, orderValue, accessValue, contentTypeValue]);

  useEffect(() => {
    onAdd?.(map(selectedItems, (item) => ({ content_item: item })));
  }, [selectedItems, onAdd]);

  const handleOnSearchTextChange = (value) => setSearchText(value);

  const handleOnOrderValueChange = (value) => setOrderValue(value);

  const handleOnAccessValueChange = (value) => setAccessValue(value);

  const handleOnContentTypeValueChange = (value) => setContentTypeValue(value);

  const handleCheckboxChange = (item) => {
    if (includes(map(selectedItems, 'public_id'), item.public_id)) {
      setSelectedItems(
        filter(selectedItems, (seletectedItem) => seletectedItem.public_id !== item.public_id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleDropDownItemClickAndClose = (contentType) => {
    handleDropDownItemClick(contentType);
    handleClose();
  };

  const isLoading = contentsStatus === STATUS_LOADING;

  const contentTypeOptions = getContentTypeOptions(learningTypeLabelsSingle);

  return (
    <div>
      <Header>
        <HeaderRow>
          <InputLabel />
          {showCreateNewButton && (
            <DropDownMenu
              renderButton={({ toggleMenu }) => (
                <ButtonLink
                  target="_blank"
                  variant="primary"
                  icon="external"
                  iconPlacement="right"
                  onClick={toggleMenu}
                  route={undefined}
                >
                  Create New
                </ButtonLink>
              )}
            >
              <AddContentMenuItems handleDropDownItemClick={handleDropDownItemClickAndClose} />
            </DropDownMenu>
          )}
        </HeaderRow>

        <SearchInput
          id="search_input"
          label="Search"
          placeholder="Search by keyword"
          value={searchText}
          onChange={handleOnSearchTextChange}
        />

        <HeaderRow>
          <SelectsWrapperLeft>
            <SelectContentTypeWrapper>
              <SelectField
                options={contentTypeOptions}
                input={{
                  name: 'content_type_filter',
                  value: contentTypeValue,
                  onChange: handleOnContentTypeValueChange,
                }}
              />
            </SelectContentTypeWrapper>
            <SelectAccessWrapper>
              <SelectField
                options={ACCESS_LEVEL_OPTIONS}
                input={{
                  name: 'access_level_filter',
                  value: accessValue,
                  onChange: handleOnAccessValueChange,
                }}
              />
            </SelectAccessWrapper>
          </SelectsWrapperLeft>
          <SelectsWrapperRight>
            <SelectOrderWrapper>
              <SelectField
                options={CONTENTS_ORDER_OPTIONS}
                input={{
                  name: 'order_filter',
                  value: orderValue,
                  onChange: handleOnOrderValueChange,
                }}
              />
            </SelectOrderWrapper>
          </SelectsWrapperRight>
        </HeaderRow>
      </Header>

      {isLoading && <Loading />}

      {!isLoading && (
        <ListWrapper>
          {contentsCount === 0 && (
            <>
              <Text block size="h5" color={colors.neutral500}>
                No results found, try changing your search.
              </Text>
              <Text block size="h5" color={colors.neutral500}>
                You can also create a new one or&nbsp;
                <ButtonLink variant="primary" onClick={refreshContents}>
                  refresh the list
                </ButtonLink>
                .
              </Text>
            </>
          )}

          <ResultWrapper>
            {Boolean(selectedItems.length) && (
              <Text block size="h5" color={colors.neutral500}>
                {selectedItems.length} selected -
              </Text>
            )}
            <Text size="h5" color={colors.neutral500}>
              {contentsCount} results found
            </Text>
            <IconButton
              title="Refresh"
              iconName="refresh"
              width={12}
              height={12}
              color={colors.action600}
              hoverColor={colors.action700}
              onClick={refreshContents}
            />
          </ResultWrapper>

          {contentsCount > 0 &&
            map(contents, (content) => {
              const alreadySelected = includes(
                map(alreadyAddedItems, 'content_item.public_id'),
                content.public_id
              );
              const newlySelected = includes(map(selectedItems, 'public_id'), content.public_id);

              return (
                <ContentSelect
                  key={`select-${content.public_id}`}
                  content={content}
                  alreadySelected={alreadySelected}
                  newlySelected={newlySelected}
                  handleCheckboxChange={handleCheckboxChange}
                  isExistingContentModal={isContentTypeAll}
                />
              );
            })}

          <LoadMoreButtonWrapper>
            <LoadMoreButton
              isLoading={contentsStatus === STATUS_LOADING_MORE}
              show={contentsCount > contents.length}
              onClick={loadMoreContents}
              size="small"
            />
          </LoadMoreButtonWrapper>
        </ListWrapper>
      )}
    </div>
  );
};

ContentItemPicker.defaultProps = {
  showCreateNewButton: true,
  initialSelectedItems: [],
};

ContentItemPicker.propTypes = {
  contentType: PropTypes.string,
  showCreateNewButton: PropTypes.bool,
  alreadyAddedItems: PropTypes.array,
  initialSelectedItems: PropTypes.array,
  onAdd: PropTypes.func,
  handleClose: PropTypes.func,
  handleDropDownItemClick: PropTypes.func,
  assignee: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default ContentItemPicker;
