import PropTypes from 'prop-types';
import React from 'react';

import TextInput from 'inputs/components/TextInput';

const SettingShortTextField = ({ input, type, disabled, ariaLabel }) => {
  return (
    <TextInput
      value={input.value.value}
      onChange={(e) => input.onChange({ ...input.value, value: e.target.value, inherited: false })}
      type={type === 'int' || type === 'float' ? 'number' : type}
      disabled={disabled}
      aria-label={ariaLabel}
    />
  );
};

SettingShortTextField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default SettingShortTextField;
