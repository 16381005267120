import { isString } from 'vendor/lodash';

const getClosedAnnouncements = (userId) => {
  const closedAnnouncementsStr = window.localStorage.getItem(`closedAnnouncements-${userId}`);

  if (!isString(closedAnnouncementsStr)) {
    return [];
  }

  return JSON.parse(closedAnnouncementsStr);
};

const addClosedAnnouncements = (userId, announcementId) => {
  const closedAnnouncements = getClosedAnnouncements(userId);
  window.localStorage.setItem(
    `closedAnnouncements-${userId}`,
    JSON.stringify(closedAnnouncements.concat(announcementId))
  );
};

export { getClosedAnnouncements, addClosedAnnouncements };
