import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';

const UNSELECTED_ITEM_HOVER_COLOR = colors.neutral500;

const ItemLi = styled.li`
  padding: 0;
  width: 100%;
  color: #333333;
  list-style: none;

  && {
    ${({ disabled }) => disabled && `color: ${colors.neutral500}`}
  }

  &:not(:last-child) {
    border-bottom: ${({ borderless }) => (borderless ? 'none' : `${colors.neutral100} solid 1px`)};
  }
`;

const MultiSelectItem = styled(ItemLi)`
  ${({ isSelected, color }) =>
    isSelected
      ? `
    color: ${color}
  `
      : `
    &:hover {
      color: ${UNSELECTED_ITEM_HOVER_COLOR};
    }
  `};
`;

const SingleSelectItem = styled(ItemLi)`
  ${({ isSelected }) =>
    isSelected
      ? `
    background: ${colors.emphasis600};
    color: ${colors.emphasis600TextColor};
  `
      : `
    &:hover {
      background: ${colors.neutral200};
    }
  `};
`;

const checkMarkWidth = 32;

const CheckmarkArea = styled.div`
  display: inline-block;
  width: ${checkMarkWidth}px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 2px;
`;

const ItemName = styled.div`
  display: inline-block;
  width: calc(100% - ${checkMarkWidth}px);
  padding: 9px 10px 9px 0;
  font-size: 16px;
`;

const ImageContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 6px;
  display: inline-block;
`;

const Image = styled.img`
  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: ${colors.action700};
  color: ${colors.action700TextColor};
  height: 24px;
  width: 24px;
`;

const SelectableListItem = ({
  isSelected,
  onClick,
  children,
  singleSelect,
  icon,
  className,
  notRemovable,
  borderless,
  disabled,
  color,
  imageSrc,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  let LIWrapper = MultiSelectItem;
  if (singleSelect) {
    LIWrapper = SingleSelectItem;
  }

  const showPropIcon = Boolean(icon) && !isSelected && !isHovered;
  const showCheckIcon =
    !imageSrc &&
    !singleSelect &&
    ((notRemovable && isSelected) || (isSelected && !isHovered) || (!isSelected && isHovered));
  const showCloseIcon = !singleSelect && !notRemovable && isSelected && isHovered;

  return (
    <LIWrapper
      className={className}
      isSelected={isSelected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      borderless={borderless}
      disabled={disabled}
      color={color}
    >
      <Clicker disabled={disabled} fullWidth onClick={onClick}>
        <CheckmarkArea>
          {showPropIcon && <Icon name={icon} height={12} width={12} color={color} />}
          {showCheckIcon && (
            <Icon
              name="check"
              height={12}
              width={12}
              color={isSelected ? color : UNSELECTED_ITEM_HOVER_COLOR}
            />
          )}
          {showCloseIcon && <Icon name="close" height={10} width={10} color={color} />}
        </CheckmarkArea>
        {imageSrc && (
          <ImageContainer size={4}>
            <Image src={imageSrc} />
          </ImageContainer>
        )}
        <ItemName>{children}</ItemName>
      </Clicker>
    </LIWrapper>
  );
};

SelectableListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  singleSelect: PropTypes.bool,
  icon: PropTypes.string,
  notRemovable: PropTypes.bool,
  borderless: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  imageSrc: PropTypes.string,
};

SelectableListItem.defaultProps = {
  borderless: false,
  disabled: false,
  color: colors.emphasis600,
};

export default SelectableListItem;
