import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import InfoBox from 'shared/components/InfoBox';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
`;

const ExternalWaitlistModal = ({ updateEnrollmentStatus, status, show, event, handleClose }) => {
  const handleEnrollClick = () => {
    updateEnrollmentStatus(status);
    handleClose();
  };

  const buttonText = event.external_link ? 'Join Externally' : 'Join';

  if (!show) {
    return null;
  }

  return (
    <Modal
      title="Waitlist"
      width={window.innerWidth > 768 ? 600 : 'auto'}
      handleClose={handleClose}
    >
      <ModalBody>
        <ModalBodyWrapper>
          <Text size="h4">Get on the official waitlist!</Text>
          <InfoBox content={event.external_link_description} />
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton type="button" onClick={handleEnrollClick} alt={buttonText}>
          {buttonText}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ExternalWaitlistModal.propTypes = {
  event: PropTypes.object,
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  status: PropTypes.string,
  updateEnrollmentStatus: PropTypes.func,
};

export default ExternalWaitlistModal;
