import PropTypes from 'prop-types';
import React from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import {
  ATTENDANCE_METHOD_LOCAL,
  ATTENDANCE_METHOD_ONLINE,
  STATUS_RESERVED,
} from 'program/constants';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { get, isEmpty } from 'vendor/lodash';

const SessionGroupEnrollButtons = ({ session, group, children, refreshEnrollments }) => {
  const [updateEnrollment, { status: updateEnrollmentStatus }] = useEntities(
    actions.sessionEnrollment.create,
    ({ status }) => {
      if (status === STATUS_DONE) {
        refreshEnrollments();
      }
    },
    {
      key: `session${session.public_id}EnrollmentGroup${group.id}`,
    }
  );

  const enrollGroup = (attendanceMethod) => {
    updateEnrollment({
      session_public_id: session.public_id,
      group_id: group.id,
      attendance_method: attendanceMethod,
      status: STATUS_RESERVED,
    });
  };

  const allowsOnline = get(session, 'allows_online', false);
  const allowsLocal = get(session, 'allows_local', false);

  const text = isEmpty(children) ? 'Enroll' : children;

  if (updateEnrollmentStatus === STATUS_LOADING) {
    return <Loading size={8} hasMargin={false} />;
  }

  return (
    <React.Fragment>
      {allowsLocal && (
        <Button
          size="small"
          onClick={() => enrollGroup(ATTENDANCE_METHOD_LOCAL)}
          startIcon={<Icon name="location" />}
        >
          {text}
        </Button>
      )}

      {allowsOnline && (
        <Button
          size="small"
          onClick={() => enrollGroup(ATTENDANCE_METHOD_ONLINE)}
          startIcon={<Icon name="online" />}
        >
          {text}
        </Button>
      )}
    </React.Fragment>
  );
};

SessionGroupEnrollButtons.propTypes = {
  session: PropTypes.object,
  group: PropTypes.object,
  children: PropTypes.node,
  refreshEnrollments: PropTypes.func,
};

export default SessionGroupEnrollButtons;
