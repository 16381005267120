import { useState } from 'react';

import AssignmentStateChip from 'app/assignments/components/AssignmntStateChip';
import { CellLink } from 'app/backoffice/components/Dashboard/Cells';
import { useContentTypeRoutes } from 'app/catalog/hooks';
import ContentTypePill from 'app/content-items/components/ContentTypePill';
import { useFetchDataList } from 'app/data-fetching/hooks';
import { queries } from 'queries';
import { ContentItem } from 'shared-content-item/interfaces';
import { User } from 'shared/components/types';
import { DataGrid } from 'vendor/mui';

interface ListTrackItemAssignmentsProps {
  contentItem: ContentItem;
  user: User;
}

const useFetchData = (contentItemId: number, userId: number, currentPage = 0, pageSize = 5) => {
  return useFetchDataList({
    ...queries.track_items.list(contentItemId, userId, {
      page: currentPage,
      page_size: pageSize,
      view_mode: 'report',
    }),
  });
};

const ListTrackItemAssignments = ({ contentItem, user }: ListTrackItemAssignmentsProps) => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const response = useFetchData(contentItem.id, user.id, currentPage + 1, pageSize);
  const { data, count, isLoading } = response;
  const contentTypeRoutes = useContentTypeRoutes();
  return (
    <DataGrid
      autoHeight
      sx={{
        border: 'none',
      }}
      loading={isLoading}
      columns={[
        {
          field: 'order',
          headerName: '#',
          filterable: false,
          sortable: false,
          editable: false,
          flex: 1,
          renderCell: ({ row: trackItem }) => trackItem.order + 1,
        },
        {
          field: 'content_type',
          headerName: 'Type',
          filterable: false,
          sortable: false,
          editable: false,
          flex: 3,
          renderCell: ({ row: trackItem }) =>
            trackItem.content_item && (
              <ContentTypePill item={trackItem.content_item} helpTextRenderMode="label" />
            ),
        },
        {
          field: 'name',
          headerName: 'Content Item',
          filterable: false,
          sortable: false,
          editable: false,
          flex: 8,
          renderCell: ({ row: trackItem }) => {
            const route = contentTypeRoutes[trackItem.content_item?.content_type]?.details;

            if (!route) return trackItem.content_item?.name;

            return (
              <CellLink
                to={contentTypeRoutes[trackItem.content_item?.content_type]?.details?.({
                  public_id_and_slug: trackItem.content_item?.public_id_and_slug,
                })}
                $highlighted
              >
                {trackItem.content_item?.name}
              </CellLink>
            );
          },
        },
        {
          field: 'status',
          headerName: 'Status',
          filterable: false,
          sortable: false,
          editable: false,
          flex: 3,
          renderCell: ({ row: trackItem }) => (
            <>
              {trackItem.content_item.user_assignment && (
                <AssignmentStateChip assignment={trackItem.content_item.user_assignment} />
              )}
              {!trackItem.content_item.user_assignment && 'Not assigned'}
            </>
          ),
        },
      ]}
      rows={data || []}
      rowCount={count}
      pagination
      rowsPerPageOptions={[pageSize]}
      page={currentPage}
      pageSize={pageSize}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      paginationMode="server"
      sortingMode="server"
      disableSelectionOnClick
    />
  );
};

export default ListTrackItemAssignments;
