import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import CardList from 'shared-cards/components/CardList';
import Loading from 'shared/components/Loading';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import {
  STATUS_LOADING,
  STATUS_LOADING_MORE,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
} from 'shared/constants';
import { includes } from 'vendor/lodash';

import EventCard from '../EventCard';

const EventCardListWrapper = styled.div`
  width: 100%;
`;

const NoEventsFoundMessage = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 25px;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export class EventCardList extends React.Component {
  fetchNextPage = () => {
    const { fetchNextPage, eventListMoreLink } = this.props;
    fetchNextPage(eventListMoreLink);
  };

  render() {
    const {
      eventList,
      eventListStatus,
      eventListMoreLink,
      noEventsMessage,
      singleCreatorTitle,
      multipleCreatorsTitle,
    } = this.props;

    let noEventsFoundMessage = noEventsMessage;
    if (!noEventsFoundMessage) {
      noEventsFoundMessage = 'No events found for the selected filters.';
    }

    const isLoading = includes([STATUS_NOT_REQUESTED, STATUS_LOADING], eventListStatus);
    const isLoadingMore = eventListStatus === STATUS_LOADING_MORE;
    const noEventsFound =
      (!isLoading && eventList.length === 0) || eventListStatus === STATUS_ERROR;

    return (
      <EventCardListWrapper>
        {isLoading && <Loading />}

        {(() => {
          if (noEventsFound) {
            return <NoEventsFoundMessage>{noEventsFoundMessage}</NoEventsFoundMessage>;
          }
          return (
            <CardList
              items={eventList}
              renderItem={(item) => (
                <EventCard
                  event={item}
                  singleCreatorTitle={singleCreatorTitle}
                  multipleCreatorsTitle={multipleCreatorsTitle}
                />
              )}
            />
          );
        })()}
        <LoadMoreWrapper>
          <LoadMoreButton
            isLoading={isLoadingMore}
            show={Boolean(eventListMoreLink)}
            onClick={this.fetchNextPage}
            size="large"
          />
        </LoadMoreWrapper>
      </EventCardListWrapper>
    );
  }
}

EventCardList.propTypes = {
  noEventsMessage: PropTypes.string,

  fetchNextPage: PropTypes.func,
  eventList: PropTypes.array,
  eventListStatus: PropTypes.string,
  eventListMoreLink: PropTypes.string,

  singleCreatorTitle: PropTypes.string,
  multipleCreatorsTitle: PropTypes.string,
};

const mapStateToProps = (state) => ({
  singleCreatorTitle: state.user.currentUser.labels.label_events_single_creator_title,
  multipleCreatorsTitle: state.user.currentUser.labels.label_events_multiple_creators_title,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventCardList);
