import { handleActions } from 'redux-actions';

import { STATUS_LOADING, STATUS_DONE, STATUS_ERROR } from '../shared/constants';

import actions from './actions';

const defaultState = {
  alerts: [],
  alertsResponse: null,
  alertsStatus: STATUS_LOADING,

  alertCreateResponse: null,
  alertCreateStatus: STATUS_LOADING,

  alertDeleteResponse: null,
  alertDeleteStatus: STATUS_LOADING,
};

export default handleActions(
  {
    [actions.userAlertListFetchRequestSubmit]: (state) => ({
      ...state,
      alertsStatus: STATUS_LOADING,
    }),
    [actions.userAlertListFetchRequestSuccess]: (state, action) => ({
      ...state,
      alerts: action.payload.results,
      alertsResponse: action.payload,
      alertsStatus: STATUS_DONE,
    }),
    [actions.userAlertListFetchRequestFailure]: (state, action) => ({
      ...state,
      alertsResponse: action.payload,
      alertsStatus: STATUS_ERROR,
    }),

    [actions.userAlertCreateRequestSubmit]: (state) => ({
      ...state,
      alertCreateStatus: STATUS_LOADING,
    }),
    [actions.userAlertCreateRequestSuccess]: (state, action) => ({
      ...state,
      alertCreateResponse: action.payload,
      alertCreateStatus: STATUS_DONE,
    }),
    [actions.userAlertCreateRequestFailure]: (state, action) => ({
      ...state,
      alertCreateResponse: action.payload,
      alertCreateStatus: STATUS_ERROR,
    }),

    [actions.userAlertDeleteRequestSubmit]: (state) => ({
      ...state,
      alertDeleteStatus: STATUS_LOADING,
    }),
    [actions.userAlertDeleteRequestSuccess]: (state) => ({
      ...state,
      alertDeleteStatus: STATUS_DONE,
    }),
    [actions.userAlertDeleteRequestFailure]: (state) => ({
      ...state,
      alertDeleteStatus: STATUS_ERROR,
    }),
  },
  defaultState
);
