import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiURLs, fetchURL } from 'services/requests-base';

import actions from './actions';

export function* currentUser() {
  const url = ApiURLs['api_users:current-user']();

  try {
    const response = yield call(fetchURL, url);

    yield put(actions.currentUserRequestSuccess(response.data));
  } catch (e) {
    yield put(actions.currentUserRequestFailure({ _error: e.data }));
  }
}

export function* currentUserUpdate(action) {
  const url = ApiURLs['api_users:current-user']();

  const options = action.options || {};
  options.method = 'PATCH';
  options.body = JSON.stringify(action.payload);

  try {
    yield call(fetchURL, url, options);
    const response = yield call(fetchURL, url);

    yield put(actions.currentUserUpdateRequestSuccess(response.data));
  } catch (e) {
    yield put(actions.currentUserUpdateRequestFailure({ _error: e.data }));
  }
}

export default function* sagas() {
  yield takeLatest(actions.currentUserRequestSubmit.toString(), currentUser);
  yield takeLatest(actions.currentUserUpdateRequestSubmit.toString(), currentUserUpdate);
}
