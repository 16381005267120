import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { Typography } from 'vendor/mui';

export const DropEnrollmentModal = ({ event, show, handleClose, performUnenroll }) => {
  if (!show) return null;

  return (
    <Modal
      title="Drop event"
      width={window.innerWidth > 768 ? 700 : 'auto'}
      maxWidth={700}
      handleClose={handleClose}
    >
      <ModalBody>
        <Typography>
          You are about to drop <b>{event?.name}</b>. Are you sure you wish to proceed?
        </Typography>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton
          color="neutral"
          variant="text"
          type="button"
          onClick={handleClose}
          alt="Cancel"
        >
          Cancel
        </ModalFooterButton>
        <ModalFooterButton color="error" type="button" alt="Drop" onClick={performUnenroll}>
          Drop
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DropEnrollmentModal.propTypes = {
  event: PropTypes.object,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  performUnenroll: PropTypes.func,
};
