import {
  Widget,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
  WidgetContent,
} from 'common/components/widget';
import { CreateEventWidgetAction } from 'features/events/components/CreateEventWidgetAction';
import { mapRoute } from 'services/requests';
import { get, map, size } from 'vendor/lodash';

import { UsersWidgetLoadingContent, UsersWidgetRow } from '../components/BaseUsersAsyncWidget';
import { useUsersWidgetQuery } from '../hooks/useUsersWidgetQuery';

const searchQuery = 'my_attendance=true&ordering(name)';

type MyAttendanceWidgetActionProps = {
  total: number;
};

function MyAttendanceWidgetAction(props: MyAttendanceWidgetActionProps) {
  const { total } = props;

  if (total <= 3) {
    return <CreateEventWidgetAction />;
  }

  const viewAllUrl = `${mapRoute('peopleList')}?${searchQuery}`;

  return <WidgetLinkAction label={`View all ${total}`} url={viewAllUrl} />;
}

type MyAttendanceWidgetProps = {
  hideOnEmpty?: boolean;
};

export function MyAttendanceWidget(props: MyAttendanceWidgetProps) {
  const { hideOnEmpty = false } = props;

  const { data, isLoading } = useUsersWidgetQuery(searchQuery);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="My Participants"
      tooltip="People who participated at Events I ran"
      action={!isLoading ? <MyAttendanceWidgetAction total={resultsTotal} /> : null}
    >
      <WidgetContent>
        <WidgetContentList>
          {displayResults ? (
            map(results, (row) => (
              <WidgetContentListItem key={row.id}>
                <UsersWidgetRow user={row} />
              </WidgetContentListItem>
            ))
          ) : (
            <UsersWidgetLoadingContent animate={isLoading} />
          )}
        </WidgetContentList>
      </WidgetContent>
    </Widget>
  );
}
