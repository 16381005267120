import PropTypes from 'prop-types';
import React from 'react';

import { displayDatetimeRange } from 'services/datetime';
import Pill from 'shared/components/Pill';

const TimeslotRangePill = ({ start, end, timezone, displayTimezone, isOnline }) => {
  const datetimeRange = displayDatetimeRange(start, end, timezone, displayTimezone, true, isOnline);

  return (
    <Pill
      variant="lighterGrayWithDarkerTextColor"
      icon="calendar-clock"
      label={datetimeRange}
      labelMaxWidth={280}
    />
  );
};

TimeslotRangePill.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  timezone: PropTypes.string,
  displayTimezone: PropTypes.string,
  isOnline: PropTypes.bool,
};

export default TimeslotRangePill;
