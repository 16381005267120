import { includes, map } from 'lodash';

import { useCurrentUser } from 'app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';

import { Track } from '../types';

export function useCanStartTrack(track: Track) {
  const currentUser = useCurrentUser();

  const facilitatorsIds = map(track.facilitators, (facilitator) => facilitator.user_id);

  return (
    track.access_level === 'public' ||
    currentUser.role === 'admin' ||
    currentUser.role === 'organizer' ||
    includes(facilitatorsIds, currentUser.id) ||
    includes(track.permissions, 'group_access') ||
    includes(track.permissions, CONTENT_ITEM_PERMISSIONS.assign)
  );
}
