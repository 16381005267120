import styled from 'styled-components';

import colors from 'services/colors';

export default styled.hr`
  height: ${({ height }) => height || 1}px;
  background-color: ${({ color }) => color || colors.neutral100};
  border: 0;
  margin: ${({ margin }) => margin || 0};
`;
