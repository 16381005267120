import {
  Widget,
  WidgetContentList,
  WidgetContentListItem,
  WidgetLinkAction,
  WidgetContent,
} from 'common/components/widget';
import { mapRoute } from 'services/requests';
import { useCurrentUser } from 'shared/hooks';
import { map, get, size } from 'vendor/lodash';

import {
  EventWidgetRow,
  EventsWidgetLoadingContent,
  EvetnsWidgetEmptyContent,
} from '../components/BaseEventsAsyncWidget';
import { CreateEventWidgetAction } from '../components/CreateEventWidgetAction';
import { useEventsWidgetQuery } from '../hooks/useEventsWidgetQuery';

type EventsIOwnWidgetActionProps = {
  search: string;
  total: number;
};

function EventsIOwnWidgetAction(props: EventsIOwnWidgetActionProps) {
  const { search, total } = props;

  if (total <= 3) {
    return <CreateEventWidgetAction />;
  }

  const viewAllUrl = `${mapRoute('eventsList')}?${search}`;

  return <WidgetLinkAction label={`View all ${total}`} url={viewAllUrl} />;
}

type EventsIOwnWidgetProps = {
  hideOnEmpty?: boolean;
};

export function EventsIOwnWidget(props: EventsIOwnWidgetProps) {
  const { hideOnEmpty = false } = props;

  const currentUser = useCurrentUser();
  const userID = get(currentUser, 'id');

  const search = `eq(facilitator,${userID})&gt(start_time,-PT0H)&ordering(start_time)`;
  const { data, isLoading } = useEventsWidgetQuery(search);

  const results = get(data, 'results', []);
  const resultsTotal = get(data, 'total', 0);
  const displayResults = !isLoading && size(results) > 0;

  if (hideOnEmpty && (isLoading || !displayResults)) {
    return null;
  }

  return (
    <Widget
      title="Events I Own"
      tooltip="Events that I own or present"
      action={!isLoading ? <EventsIOwnWidgetAction search={search} total={resultsTotal} /> : null}
    >
      {isLoading || displayResults ? (
        <WidgetContent>
          <WidgetContentList>
            {displayResults ? (
              map(results, (row) => (
                <WidgetContentListItem key={row.id}>
                  <EventWidgetRow event={row} />
                </WidgetContentListItem>
              ))
            ) : (
              <EventsWidgetLoadingContent />
            )}
          </WidgetContentList>
        </WidgetContent>
      ) : (
        <EvetnsWidgetEmptyContent label="You do not own any events yet" />
      )}
    </Widget>
  );
}
