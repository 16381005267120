import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import {
  convertItemValuesToString,
  FilterOptionsQueryList,
} from 'navigation/components/FilterBar/FilterOptions';
import { STATUS_DONE, STATUS_LOADING } from 'shared/constants';
import { useLabels } from 'shared/hooks';
import { filter, get, includes, join, size } from 'vendor/lodash';

const GroupsFilter = ({ selecteds, disabled, onChange, setSelectedGroups }) => {
  const param = 'group';
  const selectedGroups = selecteds[param] || [];

  const { label_internal_groups: labelInternalGroups } = useLabels();
  const [options, setOptions] = useState([]);

  const [fetch, { status: fetchOptionsStatus }] = useEntities(
    actions.groups.retrieveList,
    ({ status, data: { results } }) => {
      if (status === STATUS_DONE) setOptions(convertItemValuesToString(results));
    }
  );

  const fetchOptions = (queryParams = {}) => {
    const includeIds = get(queryParams, 'include_ids');
    const pageSize = size(includeIds) + 10;
    fetch(
      {
        ...queryParams,
        include_ids: join(includeIds, ','),
        page_size: pageSize,
        o: 'name',
        view_mode: 'filter_options',
      },
      { skipSchema: true }
    );
  };

  const handleOnChange = (selectedGroups) => {
    onChange({
      ...selecteds,
      [param]: selectedGroups,
    });
  };

  useEffect(() => {
    const groups = filter(options, (group) => includes(selectedGroups, group.value));
    setSelectedGroups(groups);
  }, [selecteds, options]);

  return (
    <FilterOptionsQueryList
      allItemName={`All ${labelInternalGroups}`}
      onChange={handleOnChange}
      options={options}
      fetchOptions={fetchOptions}
      isFetchingOptions={fetchOptionsStatus === STATUS_LOADING}
      selecteds={selectedGroups}
      disabled={disabled}
      searchPlaceholder="Search and select"
    />
  );
};

GroupsFilter.propTypes = {
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  setSelectedGroups: PropTypes.func,
};

export default GroupsFilter;
