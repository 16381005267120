import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';

const SaveSegmentButton = ({ label, variant, onClick }) => {
  return (
    <Button
      variant={variant}
      startIcon={<Icon name="bookmark" />}
      aria-label={label}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

SaveSegmentButton.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

SaveSegmentButton.defaultProps = {
  label: 'Save',
  variant: 'text',
};

export default SaveSegmentButton;
