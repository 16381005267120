import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Text from 'shared/components/Text';
import { InfoOutlinedIcon } from 'vendor/mui-icons.tsx';
import { Stack, Tooltip } from 'vendor/mui.tsx';

const Radio = styled.div`
  min-width: 20px;
  height: 20px;
  border: 1px solid ${colors.neutral200};
  border-radius: 50%;
  display: flex;
  justify-content: center;

  ${({ checked, disabled, checkedBgColor }) =>
    disabled
      ? `border: 2px solid ${colors.neutral200};`
      : checked
      ? `border: 2px solid ${checkedBgColor}`
      : ''}
`;

const Circle = styled.i`
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  display: ${({ checked }) => (checked ? 'block' : 'none')};
  align-self: center;

  ${({ checked, disabled, checkedBgColor }) =>
    disabled
      ? `background-color: ${colors.neutral200}`
      : checked
      ? `background-color: ${checkedBgColor}`
      : ''}
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  cursor: inherit;
`;

const Label = styled.label`
  position: relative;
  width: calc(100% - 28px);
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  ${({ fullSize }) => fullSize && 'width: 100%;'}
  ${({ disabled }) => disabled && `cursor: not-allowed; color: ${colors.neutral400}`};
  ${({ noMargin }) => noMargin && 'margin: 0;'};

  :hover ${Radio} {
    ${({ checked, disabled }) =>
      !checked && !disabled && `border: 2px solid ${colors.emphasis100};`}
  }

  :hover ${Circle} {
    ${({ checked, disabled }) =>
      !checked &&
      !disabled &&
      `
        background-color: ${colors.emphasis100};
        display: block;
      `}
  }
`;

const LabelText = styled(Text)`
  margin-left: 8px;
  margin-right: 8px;
  align-self: flex-start;
  line-height: 20px;
`;

const HelpText = styled(Text)`
  margin-left: 8px;
  margin-right: 8px;
  align-self: flex-start;
  line-height: 20px;
`;

export const RadioInput = ({
  label,
  helpText,
  value,
  disabled,
  name,
  inputValue,
  onChange,
  labelSize,
  fullSize,
  checkedBgColor,
  noMargin,
  info,
}) => {
  const checked = value === inputValue;

  const handleChange = () => {
    if (disabled) {
      return;
    }
    onChange(value);
  };

  return (
    <Label disabled={disabled} checked={checked} fullSize={fullSize} noMargin={noMargin}>
      <Input
        type="radio"
        value={value}
        name={name}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      <Radio disabled={disabled} checked={checked} checkedBgColor={checkedBgColor}>
        <Circle disabled={disabled} checked={checked} checkedBgColor={checkedBgColor} />
      </Radio>
      <Stack direction="column">
        {label && <LabelText size={labelSize}>{label}</LabelText>}
        {helpText && <HelpText size="h6" color={colors.neutral600}>{helpText}</HelpText>}
      </Stack>
      {label && info && (
        <Tooltip title={info} placement="right" arrow>
          <InfoOutlinedIcon htmlColor={colors.action600} />
        </Tooltip>
      )}
    </Label>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  labelSize: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullSize: PropTypes.bool,
  checkedBgColor: PropTypes.string,
  noMargin: PropTypes.bool,
  info: PropTypes.string,
};

RadioInput.defaultProps = {
  labelSize: 'h4',
  fullSize: true,
  checkedBgColor: colors.emphasis600,
  noMargin: false,
};

export default RadioInput;
