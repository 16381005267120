import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { Modal, Rating } from 'shared';
import { ModalBody } from 'shared/components/Modal';
import Text from 'shared/components/Text';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * + * {
    margin-top: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > * + * {
    margin-left: 8px;
  }
`;

const FeedbackViewMoreModal = ({ title, feedbackText, feedbackRating, userTitle, handleClose }) => {
  return (
    <Modal title={title} width={500} handleClose={handleClose}>
      <ModalBody>
        <Header>
          <Text size="h3" lineHeight={20} medium>
            {userTitle}
          </Text>
          <Rating value={feedbackRating} readOnly />
        </Header>
        <Content>
          <Text size="h4" color={colors.neutral900}>
            {feedbackText}
          </Text>
        </Content>
      </ModalBody>
    </Modal>
  );
};

FeedbackViewMoreModal.propTypes = {
  title: PropTypes.string,
  feedbackText: PropTypes.string,
  feedbackRating: PropTypes.number,
  userTitle: PropTypes.string,
  handleClose: PropTypes.func,
};

export default FeedbackViewMoreModal;
