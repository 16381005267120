import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SessionDetail from 'program/components/SessionDetail';
import { map, sortBy } from 'vendor/lodash';

export const GroupTitle = styled.div`
  border-bottom: 1px solid #e6e7e8;
  font-weight: 700;
  font-size: 16px;
  padding: 16px;
  color: #747473;
`;

export const GroupContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  border-radius: 5px;
  margin: 12px;
  background-color: #fff;
  width: calc(100% - 24px);

  > *:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const SessionsGroup = (props) => {
  const { title, sessions, onEnrollmentDelete } = props;
  const sortedSessions = sortBy(sessions, ['starts_at_tz_aware']);
  return (
    <GroupContainer>
      <GroupTitle>{title}</GroupTitle>
      {map(sortedSessions, (session) => (
        <SessionDetail
          key={session.id}
          session={session}
          onEnrollmentDelete={onEnrollmentDelete}
          componentName="sessionGroup"
        />
      ))}
    </GroupContainer>
  );
};

SessionsGroup.propTypes = {
  title: PropTypes.string,
  sessions: PropTypes.arrayOf(PropTypes.object),
  onEnrollmentDelete: PropTypes.func,
};

export default SessionsGroup;
