import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const BellIcon = ({ width, height, fill }) => {
  const d =
    'm 10,-0.00223463 c -0.7436336,0 -1.3446392,0.6013677 -1.3446392,1.34463923 V 3.015263 C 5.6868519,3.6361417 3.4567947,6.2677663 3.4567947,9.4208281 L 2.9556431,13.730027 c -0.5270362,0.2537 -0.8928969,0.787081 -0.8928969,1.411694 v 0.148228 c 0,0.86855 0.7021957,0.928189 1.5705104,0.928189 H 16.363214 c 0.867952,0 1.57404,-0.05964 1.57404,-0.928189 v -0.148228 c 0,-0.624613 -0.365863,-1.157633 -0.892896,-1.411694 L 16.543206,9.4208281 c 0,-3.1534234 -2.226528,-5.7848068 -5.195037,-6.4055651 V 1.3424046 C 11.348169,0.59913325 10.743272,-0.00223463 10,-0.00223463 Z M 7.6495283,17.64748 c -1e-7,1.297896 1.053541,2.346943 2.3504717,2.346943 1.296932,0 2.347184,-1.049047 2.346943,-2.346943 z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

BellIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default BellIcon;
