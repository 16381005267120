import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING } from 'shared/constants';
import { isNil, map } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

import { ScheduleTrackEventSelect } from './ScheduleTrackEventSelect';

const ScheduleTrackEventReselectContainer = ({
  event: originalEvent,
  setEventsCount,
  onSelectEvent,
}) => {
  const { event_type: eventType } = originalEvent;
  const [selectEvent, setSelectEvent] = useState();

  const [
    fetchEvents,
    {
      data: { results: events, count: eventsCount },
      status: eventsStatus,
    },
  ] = useEntities(actions.event.retrieveList, null);

  const refreshEvents = () => {
    fetchEvents(
      {
        event_types: [eventType.id],
        exclude_events: [originalEvent.id],
        period: 'upcoming',
        o: 'date',
        view_mode: 'schedule_track',
      },
      { fetchAll: true, skipSchema: true }
    );
  };

  useEffect(() => {
    refreshEvents();
  }, []);

  useEffect(() => {
    setEventsCount(eventsCount);
  }, [eventsCount]);

  useEffect(() => {
    if (selectEvent) {
      onSelectEvent(selectEvent);
    }
  }, [selectEvent]);

  const isLoading = eventsStatus === STATUS_LOADING;

  if (isNil(eventType)) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      {isLoading && <Loading />}

      {!isLoading && eventsCount === 0 && <Typography>There are no upcoming events.</Typography>}

      {!isLoading &&
        map(events, (event) => {
          return (
            <ScheduleTrackEventSelect
              event={event}
              inputValue={selectEvent?.public_id}
              onChange={setSelectEvent}
              key={`event-option-${event.public_id}`}
            />
          );
        })}
    </Box>
  );
};

ScheduleTrackEventReselectContainer.propTypes = {
  event: PropTypes.object,
  onSelectEvent: PropTypes.func,
  setEventsCount: PropTypes.func,
};

export default ScheduleTrackEventReselectContainer;
