import { SvgIcon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useListSegments } from 'backoffice/hooks';
import { buildSegmentData, DASHBOARD_SEGMENT_ACTIONS } from 'backoffice/services';
import ButtonLink from 'shared/components/ButtonLink';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Modal, { ModalBody } from 'shared/components/Modal';
import { STATUS_DONE } from 'shared/constants';
import { map, keys } from 'vendor/lodash';

import DeleteSegmentModal from './DeleteSegmentModal';
import SaveSegmentModal from './SaveSegmentModal';

const SEGMENTS_PER_PAGE = 7;

const SegmentsBusyStateModal = ({
  metadata: { label, type },
  handleClose,
  fetchDashboardSegments,
}) => {
  // OPTIMIZATION: pass the same hook instance that's being used to handle
  // the segments in the dashboard sidebar, so we can simplify the logic.
  // It would allows us, i.e., to call only one refresher function in the
  // edit/delete modals (we could actually remove the edit/delete modals
  // from this component and use only the ones from the PageContent component.
  // CHALLENGES: make sure we won't enter an infinite looping of re-renders
  // due to multiple fetches being repeated
  const segments = useListSegments();
  const history = useHistory();

  const [showEditSegmentModal, setShowEditSegmentModal] = useState(false);
  const [editSegmentModalMetadata, setEditSegmentModalMetadata] = useState({});

  const [showDeleteSegmentModal, setShowDeleteSegmentModal] = useState(false);
  const [deleteSegmentModalMetadata, setDeleteSegmentModalMetadata] = useState({});

  const modalsManager = {
    edit: {
      setter: setShowEditSegmentModal,
      metadataSetter: setEditSegmentModalMetadata,
    },
    remove: {
      setter: setShowDeleteSegmentModal,
      metadataSetter: setDeleteSegmentModalMetadata,
    },
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [furthestFetchedPage, setFurthestFetchedPage] = useState(-1);

  const fetchBusyStateModalSegments = ({ isRefetching = false } = {}) => {
    // Improvement: Keep the user in the same page after the re-fetch
    if (isRefetching) {
      setCurrentPage(0);
      setFurthestFetchedPage(0);
    }
    return segments.fetch({ content_type: type, page_size: SEGMENTS_PER_PAGE });
  };

  useEffect(() => {
    // We only want to fetch a page if it hasn't been fetched yet
    if (currentPage <= furthestFetchedPage) return;
    // Fetch data for the current page, using the appropriate method
    if (currentPage === 0) fetchBusyStateModalSegments();
    else segments.fetchMore();
    // We can now update the furthest fetched page
    setFurthestFetchedPage(currentPage);
  }, [currentPage]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterable: false,
      sortable: false,
      editable: false,
      renderCell: ({ row: { name, url } }) => {
        return (
          <ButtonLink
            onClick={() => {
              history.push(url);
              handleClose();
            }}
          >
            {name}
          </ButtonLink>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Created at',
      width: 160,
      filterable: false,
      sortable: false,
      editable: false,
    },
    {
      field: 'created_by',
      headerName: 'Created by',
      width: 150,
      filterable: false,
      sortable: false,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 20,
      sortable: false,
      editable: false,
      getActions: ({ row: { id, segment } }) => {
        return map(keys(DASHBOARD_SEGMENT_ACTIONS), (actionName) => {
          const action = DASHBOARD_SEGMENT_ACTIONS[actionName];
          return (
            <GridActionsCellItem
              key={`${actionName}-${id}-busy`}
              label={action.title}
              icon={
                <SvgIcon fontSize="small">
                  <Icon name={action.icon} />
                </SvgIcon>
              }
              onClick={() => {
                const manager = modalsManager[actionName];
                manager.setter(true);
                manager.metadataSetter(segment);
              }}
              showInMenu
            />
          );
        });
      },
    },
  ];

  const rows = map(segments.data, (segment) => {
    const { public_id: id, name, created, created_by, url } = buildSegmentData(segment);
    return { id, name, created, created_by, url, segment };
  });

  return (
    <>
      <Modal title={`All ${label} Segments`} handleClose={handleClose} width={600}>
        <ModalBody>
          {segments.status !== STATUS_DONE ? (
            <Loading />
          ) : (
            <DataGrid
              sx={{
                // These css's are specifically for hiding the column separator
                // that is added to the last column item automatically (and also the
                // second-to-last one, as we count the actions as a column)
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader:nth-last-child(2) .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                // One of the project scss files add a margin bottom to <p> elements, so we
                // must manually remove this margin by customizing the class in the pagination
                '& p.MuiTablePagination-displayedRows': {
                  margin: 0,
                  fontWeight: 500,
                },
              }}
              paginationMode="server"
              rowCount={segments.count}
              rowsPerPageOptions={[SEGMENTS_PER_PAGE]}
              page={currentPage}
              pageSize={SEGMENTS_PER_PAGE}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              // As we must use server side pagination, we must provide only
              // the current slice of rows that we have fetched to the table
              rows={rows.slice(
                currentPage * SEGMENTS_PER_PAGE,
                (currentPage + 1) * SEGMENTS_PER_PAGE
              )}
              columns={columns}
              autoHeight
            />
          )}
        </ModalBody>
      </Modal>
      {showEditSegmentModal && (
        <SaveSegmentModal
          segment={editSegmentModalMetadata}
          refreshSegments={() => {
            fetchDashboardSegments({ isRefetching: true });
            fetchBusyStateModalSegments({ isRefetching: true });
          }}
          handleClose={() => {
            setShowEditSegmentModal(false);
            setEditSegmentModalMetadata({});
          }}
        />
      )}
      {showDeleteSegmentModal && (
        <DeleteSegmentModal
          segment={deleteSegmentModalMetadata}
          refreshSegments={() => {
            fetchDashboardSegments({ isRefetching: true });
            fetchBusyStateModalSegments({ isRefetching: true });
          }}
          handleClose={() => {
            setShowDeleteSegmentModal(false);
            setDeleteSegmentModalMetadata({});
          }}
        />
      )}
    </>
  );
};

SegmentsBusyStateModal.propTypes = {
  metadata: PropTypes.object,
  handleClose: PropTypes.func,
  fetchDashboardSegments: PropTypes.func,
};

export default SegmentsBusyStateModal;
