import { displayDate, displayDatetime, displayTime } from 'services/datetime';
import { useCurrentUser } from 'shared/hooks';
import { isEmpty, map } from 'vendor/lodash';
import { Chip, List, ListItem, ListItemText, ListItemSecondaryAction } from 'vendor/mui';

import { ImpactItem } from './interfaces';

interface UpcomingImpactItemProps {
  item: ImpactItem;
}

interface UpcomingImpactListProps {
  items: ImpactItem[];
  emptyMessage: string;
  sx?: object;
}

interface EmptyItemProps {
  message: string;
}

const UpcomingImpactItem = ({ item }: UpcomingImpactItemProps) => {
  const currentUser = useCurrentUser();

  const humanizedDatetime: string = displayDatetime(
    item.datetime,
    item.timezone,
    currentUser.timezone,
    item.is_online
  );
  const humanizedDate: string = displayDate(
    item.datetime,
    item.timezone,
    currentUser.timezone,
    item.is_online,
    { includeTimezone: false }
  );
  const humanizedTime: string = displayTime(
    item.datetime,
    item.timezone,
    currentUser.timezone,
    item.is_online
  );

  const primaryText: string = item.name ? item.name : humanizedDate;
  const secondaryText: string = item.name ? humanizedDatetime : `At ${humanizedTime}`;
  const impactLabel: string = item.impact > 0 ? `+${item.impact}` : '0';
  const impactColor: 'default' | 'primary' = item.impact > 0 ? 'primary' : 'default';

  return (
    <ListItem disableGutters>
      <ListItemText primary={primaryText} secondary={secondaryText} />
      <ListItemSecondaryAction sx={{ width: '16%', textAlign: 'center' }}>
        <Chip size="small" label={impactLabel} color={impactColor} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const EmptyItem = ({ message }: EmptyItemProps) => {
  return (
    <ListItem disableGutters>
      <ListItemText secondary={message} />
    </ListItem>
  );
};

export const UpcomingImpactList = ({ items, emptyMessage, sx }: UpcomingImpactListProps) => {
  return (
    <List sx={{ width: '100%', paddingY: 0, ...sx }}>
      {isEmpty(items) ? (
        <EmptyItem message={emptyMessage} />
      ) : (
        map(items, (item) => <UpcomingImpactItem key={item.public_id} item={item} />)
      )}
    </List>
  );
};
