import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RadioField from 'inputs/components/RadioField';
import { map } from 'vendor/lodash';

const SingleSelect = styled.div`
  margin-top: 16px;

  > * + * {
    margin-top: 20px;
  }
`;

const SurveyQuestionSingleSelection = ({ input, meta, question }) => {
  const { options } = question;
  return (
    <SingleSelect>
      {map(options, (option) => (
        <RadioField key={option} optionValue={option} label={option} input={input} meta={meta} />
      ))}
    </SingleSelect>
  );
};

SurveyQuestionSingleSelection.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  question: PropTypes.object,
};

export default SurveyQuestionSingleSelection;
