import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import { useLearningTypeFilterOptions } from 'catalog/hooks';
import { useGetAllCachedOptions } from 'filters/hooks';
import { PillsDefinitionProps } from 'filters/types';
import { getLearningTypesPillsDef } from 'filters/utils';
import { filter, includes, map, sortBy, get, concat, keys } from 'vendor/lodash';

export const useCatalogPillsDefinition = ({ filters }: PillsDefinitionProps) => {
  // Get all cached options to avoid refetch data
  const allTags = useGetAllCachedOptions({ keyPrefix: 'tags' });
  const allUsers = useGetAllCachedOptions({ keyPrefix: 'old_users' });
  const allChannels = useGetAllCachedOptions({ keyPrefix: 'channels' });
  const allStatuses = { published: 'Published', archived: 'Archived' };
  const learningTypesOptions = useLearningTypeFilterOptions();
  const selectedLearningTypes = rqlToInput(get(filters, 'type'));
  const isFixed = get(rqlToInput(get(filters, 'fixed')), '0') === 'true';
  const searchValue = get(filters, 'q.$eq', '');
  const selectedCategories = rqlToInput(get(filters, 'category'));
  const selectedUsers = rqlToInput(get(filters, 'facilitator'));
  const selectedChannels = rqlToInput(get(filters, 'channel'));
  const selectedStatus = rqlToInput(get(filters, 'status'));
  const assignedContent = get(rqlToInput(get(filters, 'assigned_by_others')), '0', null) === 'true';
  const mySavedContent = get(rqlToInput(get(filters, 'self_assigned')), '0', null) === 'true';
  // Tags and flexible filters
  const otherFilters = sortBy(
    filter(
      keys(filters),
      (key: string) =>
        !includes(
          [
            'type',
            'q',
            'category',
            'facilitator',
            'status',
            'fixed',
            'self_assigned',
            'assigned_by_others',
            'channel',
          ],
          key
        )
    )
  );
  return {
    pills: [
      ...(isFixed
        ? []
        : getLearningTypesPillsDef({
            values: selectedLearningTypes,
            learningTypesOptions,
            filterName: 'type',
          })),
      ...(searchValue
        ? [
            {
              id: `search-${searchValue}`,
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),
      ...(assignedContent
        ? [
            {
              id: 'assigned-content',
              value: 'true',
              label: 'Assigned Content',
              filterName: 'assigned_by_others',
            },
          ]
        : []),
      ...(mySavedContent
        ? [
            {
              id: 'my-saved-content',
              value: 'true',
              label: 'My Saved Content',
              filterName: 'self_assigned',
            },
          ]
        : []),
      ...map(selectedCategories, (value: string) => ({
        id: `category-${value}`,
        value: value,
        label: get(allTags, value, { name: value }).name,
        icon: 'hashtag',
        filterName: 'category',
      })),
      ...map(selectedUsers, (value: string) => ({
        id: `user-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'facilitator',
      })),
      ...map(selectedStatus, (value: string) => ({
        id: `status-${value}`,
        value: value,
        label: get(allStatuses, value, value),
        filterName: 'status',
      })),
      ...map(selectedChannels, (value: string) => ({
        id: `channel-${value}`,
        value: value,
        label: get(allChannels, value, { name: value }).name,
        icon: 'channel',
        filterName: 'channel',
      })),
      ...concat(
        [],
        ...map(otherFilters, (filterName: string) =>
          map(rqlToInput(get(filters, filterName)), (value: string) => ({
            id: `${filterName}-${value}`,
            value: value,
            label: get(allTags, value, { name: value }).name,
            icon: 'tag',
            filterName,
          }))
        )
      ),
    ],
  };
};

export default useCatalogPillsDefinition;
