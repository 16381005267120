import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ATTENDANCE_METHODS_LABELS, ATTENDANCE_STATUSES_LABELS } from 'app/enrollments/constants';
import Dashboard from 'backoffice/components/Dashboard';
import DashboardTopBar from 'backoffice/components/Dashboard/DashboardTopBar';
import RQLDashboard from 'backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from 'backoffice/hooks';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { queries } from 'queries';
import Page404 from 'scenes/404';
import {
  alphabeticalAscendingText,
  alphabeticalDescendingText,
  DASHBOARD_EVENT_ENROLLMENTS,
} from 'scenes/Dashboard/constants';
import { mapRoute } from 'services/requests';
import { ApiURLs } from 'services/requests-base';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING, STATUS_DONE } from 'shared/constants';
import { usePublicIdFromURL, useLabels } from 'shared/hooks';
import { map, get } from 'vendor/lodash';

type EnrollmentReportProps = {
  segmentPublicId: string;
  filterObj: any;
  updateOrdering: any;
  updatePagination: any;
};

const PAGE_SIZE = 15;

const EnrollmentSection = (props: EnrollmentReportProps) => {
  const { segmentPublicId, filterObj, updateOrdering, updatePagination } = props;

  const { label_event_type: labelEventType } = useLabels();

  const queryParams = useLocation();
  const searchParams = queryParams.search;

  const { data, isLoading, isError, refetch } = useQuery(
    queries.segments.enrollment(segmentPublicId, searchParams)
  );

  useEffect(() => {
    refetch();
  }, [segmentPublicId, searchParams, refetch]);

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  if (!data) return null;

  const { count, results: users } = data;

  return (
    <RQLDashboard
      dashboardName={DASHBOARD_EVENT_ENROLLMENTS}
      rows={users}
      pageSize={PAGE_SIZE}
      totalCount={count}
      requestStatus={isLoading ? STATUS_LOADING : STATUS_DONE}
      refreshDashboard={queries.segments.report}
      filterObj={filterObj}
      updateOrdering={updateOrdering}
      updatePagination={updatePagination}
    >
      <Dashboard.Column
        size="15"
        header={
          <Dashboard.Header
            title="Attendee"
            orderingKey="user_name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => <Dashboard.UserCell data={row.user} highlighted />}
      </Dashboard.Column>

      <Dashboard.Column size="10" header={<Dashboard.Header title="Status" />}>
        {({ row }) => get(ATTENDANCE_STATUSES_LABELS, row.attendance_status, '')}
      </Dashboard.Column>
      <Dashboard.Column size="10" header={<Dashboard.Header title="Attendance Method" />}>
        {({ row }) => get(ATTENDANCE_METHODS_LABELS, row.attendance_method, '')}
      </Dashboard.Column>
      <Dashboard.Column
        size="10"
        header={<Dashboard.Header title="Checked in at" orderingKey="checkin_datetime" />}
      >
        {({ row }) => <Dashboard.DateTimeCell data={get(row, 'checkin.checkedin_at')} />}
      </Dashboard.Column>

      <Dashboard.Column
        size="12"
        header={
          <Dashboard.Header
            title={labelEventType}
            orderingKey="event_type.name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <Dashboard.CellLink
            to={mapRoute('eventTypeDetails', {
              public_id_and_slug: `${get(row, 'event.event_type.public_id_and_slug')}`,
            })}
          >
            {get(row, 'event.event_type.name')}
          </Dashboard.CellLink>
        )}
      </Dashboard.Column>

      <Dashboard.Column
        size="15"
        header={<Dashboard.Header title="Event Date" orderingKey="event_start_time" />}
      >
        {({ row }) => <Dashboard.DateTimeCell data={get(row, 'event.starts_at')} />}
      </Dashboard.Column>
      <Dashboard.Column
        size="8"
        alignRight
        header={<Dashboard.Header title="Rating" alignRight orderingKey="feedback_rating" />}
      >
        {({ row }) => get(row, 'rating.rating', '')}
      </Dashboard.Column>
      <Dashboard.Column
        size="15"
        header={
          <Dashboard.Header
            title="Location"
            orderingKey="location.name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <Dashboard.LocationCell
            data={{
              location: {
                name: get(row, 'event.location.name', ''),
                deleted_at: get(row, 'event.location.deleted_at'),
              },
              allowsOnline: row.event_is_online,
              allowsLocal: true,
            }}
          />
        )}
      </Dashboard.Column>
    </RQLDashboard>
  );
};

const EnrollmentReport = () => {
  const { publicId: segmentPublicId } = usePublicIdFromURL();

  const {
    data: segment,
    isLoading,
    isError,
  } = useQuery(queries.segments.report(segmentPublicId || ''));

  const [fetchFilters, { data: filters, status: filterStatus }] = useEntities(
    actions.userData.retrieveFilters,
    null
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const { filterObj, updateOrdering, updatePagination } = useRQLRouteFilters(
    'user_name',
    map(filters, (item) => item.filter),
    PAGE_SIZE
  );

  const buildExportUrl = (publicId) => {
    return ApiURLs['api_segments:enrollment-export'](publicId);
  };

  if (isError) return <Page404 />;

  if (isLoading || filterStatus === STATUS_LOADING) return <Loading />;

  if (!segment) return null;

  return (
    <>
      <DashboardTopBar title={segment.name} subTitle={segment.description}>
        <Dashboard.ExportCsvButton
          dashboardName="Enrollment-Report"
          buttonUrl={buildExportUrl(segment.public_id)}
        />
      </DashboardTopBar>

      <EnrollmentSection
        segmentPublicId={segment.public_id}
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      />
    </>
  );
};

export default EnrollmentReport;
