import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Cover from 'shared/components/Cover';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID } from 'shared/hooks';
import { noop } from 'vendor/lodash';

const ItemContainer = styled.div`
  border-bottom: 1px solid ${colors.neutral50};

  ${({ large }) => (large ? 'height: 98px;' : 'height: 73px;')};
  ${({ canClick }) => canClick && 'cursor: pointer;'};
  ${({ bordered }) => bordered && `border: 1px solid ${colors.neutral50};`};

  &:last-of-type {
    ${({ bordered }) => !bordered && 'border-bottom: 0;'};
  }

  &:hover {
    ${({ canClick }) => canClick && `background-color: ${colors.neutral50}`};
  }
`;

const CoverContainer = styled.div`
  display: inline-block;
  ${({ large }) => (large ? 'height: 96px;' : 'height: 72px;')};
  ${({ large }) => (large ? 'width: 170px;' : 'width: 128px;')};
`;

const InfoContainer = styled.div`
  ${({ large }) => (large ? 'width: calc(100% - 170px);' : 'width: calc(100% - 128px);')};
  display: inline-block;
  vertical-align: top;
  padding: 12px;
`;

const EventTypeListItem = ({ eventType, handleClick, bordered, canClick, large }) => {
  const { upcoming_events_count: eventsCount } = eventType;

  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const titleTooltip = useTooltipUID();

  return (
    <ItemContainer
      bordered={bordered}
      canClick={canClick}
      large={large}
      onClick={() => handleClick(eventType.public_id_and_slug)}
    >
      <CoverContainer large={large}>
        <Cover coverImage={eventType.cover} borderRadius={false} />
      </CoverContainer>
      <InfoContainer large={large}>
        <Text
          size={large ? 'h2' : 'h4'}
          color={large ? colors.neutral900 : colors.emphasis600}
          ellipsisOnOverflow
          ref={nodeRef}
          {...titleTooltip.targetProps}
        >
          {eventType.name}
        </Text>
        <Text block size={large ? 'h4' : 'h5'} color={colors.neutral600}>
          {eventsCount || 0} upcoming event{eventsCount !== 1 ? 's' : ''}
        </Text>
        <Tooltip id={titleTooltip.uid} hide={!hasEllipsis} maxWidth={300}>
          {eventType.name}
        </Tooltip>
      </InfoContainer>
    </ItemContainer>
  );
};

EventTypeListItem.propTypes = {
  eventType: PropTypes.object,
  handleClick: PropTypes.func,

  bordered: PropTypes.bool,
  canClick: PropTypes.bool,
  large: PropTypes.bool,
};

EventTypeListItem.defaultProps = {
  handleClick: noop,

  bordered: false,
  canClick: true,
  large: false,
};

export default EventTypeListItem;
