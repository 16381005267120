export const MONTH_FORMAT = 'MMM YY';
export const QUARTER_FORMAT = '[Q]Q YY';
export const YEAR_FORMAT = 'YYYY';

export const INTERVAL_PAGINATION_SETTINGS = {
  month: {
    format: MONTH_FORMAT,
    pageSize: 12, // 12 results per page
  },
  quarter: {
    format: QUARTER_FORMAT,
    pageSize: 4, // 4 results per page
  },
  year: {
    format: YEAR_FORMAT,
    pageSize: 10, // 10 results per page
  },
};
