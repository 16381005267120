import styled from 'styled-components';

import colors from 'services/colors';

export interface TextProps {
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  primary?: boolean;
  medium?: boolean;
  bold?: boolean;
  display?: string;
  block?: boolean;
  color?: string;
  align?: string;
  lineHeight?: number;
  ellipsisOnOverflow?: boolean;
  ellipsisOnLine?: number;
  wrapAnywhere?: boolean;
  maxWidth?: number | string;
}

const Text = styled.span<TextProps>`
  margin: 0;
  font-size: ${({ size }) => {
    let fontSize = '14';
    if (size === 'h1') fontSize = '28';
    if (size === 'h2') fontSize = '24';
    if (size === 'h3') fontSize = '18';
    if (size === 'h4' || size === 'p') fontSize = '16';
    if (size === 'h5') fontSize = '14';
    if (size === 'h6') fontSize = '12';
    return fontSize;
  }}px;
  ${({ primary }) => primary && `color: ${colors.emphasis600};`};
  ${({ medium }) => medium && 'font-weight: 500;'};
  ${({ bold }) => bold && 'font-weight: 600;'};
  ${({ display }) => display && `display: ${display};`};
  ${({ block }) => block && 'display: block;'};
  ${({ color }) => color && `color: ${color};`};
  ${({ align }) => align && `text-align: ${align};`};
  // Should be removed when new typography is implemented
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`};
  ${({ ellipsisOnOverflow }) =>
    ellipsisOnOverflow &&
    `
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `};
  ${({ ellipsisOnLine }) =>
    ellipsisOnLine &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${ellipsisOnLine};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `};
  ${({ wrapAnywhere }) => wrapAnywhere && 'overflow-wrap: anywhere;'}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
`;

export default Text;
