import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { COMPLETED_STATUS, INCOMPLETE_STATUS, UNASSIGNED_STATUS } from 'app/catalog/constants';
import { useGetContentTypeLabel } from 'app/catalog/hooks';
import AssignmentTraitIcon from 'app/content-items/components/AssignmentTraitIcon';
import { assignmentSchema, contentSchema } from 'app/entities/schema';
import { useEntities } from 'app/entities/utils';
import { AssignDueDateModal } from 'app/shared';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import Dashboard from 'backoffice/components/Dashboard';
import DashboardDynamicFilterBar from 'backoffice/components/Dashboard/DashboardDynamicFilterBar';
import RQLDashboard from 'backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from 'backoffice/hooks';
import actions from 'entities/actions';
import { useAssignmentsContext } from 'scenes/Dashboard/Assignments/Context';
import ListTrackItemAssignmentsModal from 'scenes/Dashboard/Assignments/ListTrackItemAssignmentsModal';
import ProgressCell from 'scenes/Dashboard/Assignments/ProgressCell';
import colors from 'services/colors';
import { getContentRouteBasedOnType, TRACK_TYPES } from 'services/contents';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import HR from 'shared/components/HR';
import { STATUS_DONE } from 'shared/constants';
import { get, includes, map } from 'vendor/lodash';
import { Stack } from 'vendor/mui';
import { RuleIcon } from 'vendor/mui-icons';

import {
  alphabeticalAscendingText,
  alphabeticalDescendingText,
  DASHBOARD_ASSIGNMENTS,
  DASHBOARD_TYPES,
} from '../constants';

// Used for Dashboard UI and action dispatch
const ASSIGNMENTS_PAGE_SIZE = 15;

const getContentType = (row) => {
  // row after unassigning may not include deconstructed_content_item briefly
  return get(row, 'deconstructed_content_item.content_type');
};

export const AssignmentsPage = ({ dynamicFilters }) => {
  const { trackActivity } = useMetrics();

  const getContentTypeLabel = useGetContentTypeLabel();

  const {
    expression,
    filterObj,
    addFilter,
    removeFilter,
    updateFilter,
    updateOrdering,
    updatePagination,
    updateFilters,
  } = useRQLRouteFilters(
    'user_name',
    map(dynamicFilters, (item) => item.filter),
    ASSIGNMENTS_PAGE_SIZE
  );

  const {
    refreshDashboard,
    breadcrumbItems,
    assignments,
    assignmentsStatus,
    assignmentsCount,
    enableSegments,
  } = useAssignmentsContext();

  const [targetUser, setTargetUser] = React.useState(null);
  const [targetTrack, setTargetTrack] = React.useState(null);
  const [showTrackItemAssignmentListModal, setShowTrackItemAssignmentListModal] =
    React.useState(false);

  const [editAssignmentModalOptions, setEditAssignmentOptions] = React.useState({
    visible: false,
    initialDueDate: undefined,
    userId: undefined,
    contentItemId: undefined,
  });

  const [updateAssignment] = useEntities(
    actions.contentAssignment.update,
    ({ status }) => {
      if (status === STATUS_DONE) {
        refreshDashboard(expression);
      }
    },
    {
      schema: assignmentSchema,
    }
  );

  const unassignUser = (userId, contentItemId) => {
    const body = {
      assignment_status: UNASSIGNED_STATUS,
      user_pk: userId,
      force_drop_track_item: true,
      contentSchema,
    };
    updateAssignment(contentItemId, userId, body);
  };

  const updateCompletion = (userId, contentItemId, completionStatus) => {
    const body = {
      completion_status: completionStatus,
      user_pk: userId,
      completion_status_for_write: completionStatus,
      contentSchema,
    };

    if (completionStatus === COMPLETED_STATUS) {
      trackActivity(METRICS_ACTIVITIES.MANAGER_MARK_COMPLETE, {});
    } else {
      trackActivity(METRICS_ACTIVITIES.MANAGER_MARK_INCOMPLETE, {});
    }

    updateAssignment(contentItemId, userId, body);
  };

  const updateDueDate = (userId, contentItemId, dueDate) => {
    const body = {
      user_pk: userId,
      due_date: dueDate,
      contentSchema,
    };
    updateAssignment(contentItemId, userId, body);
  };

  const handleRowViewProgressClick = React.useCallback(
    (assignment) => {
      setTargetUser(assignment.user);
      setTargetTrack(assignment.deconstructed_content_item);
      setShowTrackItemAssignmentListModal(true);
    },
    [setTargetUser, setTargetTrack, setShowTrackItemAssignmentListModal]
  );
  const handleViewProgressClose = () => {
    setTargetUser(null);
    setTargetTrack(null);
    setShowTrackItemAssignmentListModal(false);
  };

  // When the current filters change, the entity list should be listed with these filters
  useEffect(() => {
    refreshDashboard(expression);
  }, [expression]);

  return (
    <>
      <RQLDashboard
        dashboardName={DASHBOARD_ASSIGNMENTS}
        rows={assignments}
        pageSize={ASSIGNMENTS_PAGE_SIZE}
        totalCount={assignmentsCount}
        refreshDashboard={() => refreshDashboard(expression)}
        requestStatus={assignmentsStatus}
        breadcrumbItems={breadcrumbItems}
        actionBar={<Dashboard.ActionsBar resourceName="Assignments" />}
        filterBar={
          <DashboardDynamicFilterBar
            contentType={DASHBOARD_TYPES.assignments}
            dynamicFilters={dynamicFilters}
            filterObj={filterObj}
            addFilter={addFilter}
            removeFilter={removeFilter}
            updateFilter={updateFilter}
            updateFilters={updateFilters}
            enableSegments={enableSegments}
          />
        }
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      >
        <Dashboard.Column
          size="15"
          header={
            <Dashboard.Header
              title="Assignee"
              orderingKey="user_name"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
          filterObj={filterObj}
          updateOrdering={updateOrdering}
          updatePagination={updatePagination}
        >
          {({ row }) => <Dashboard.UserCell data={row.user} showAvatar highlighted />}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={
            <Dashboard.Header
              title="Type"
              orderingKey="content_item_type"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => getContentTypeLabel(get(row.deconstructed_content_item, 'content_type'))}
        </Dashboard.Column>
        <Dashboard.Column
          size="20"
          header={
            <Dashboard.Header
              title="Content"
              orderingKey="content_item_name"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => {
            const contentTypeRoute = getContentRouteBasedOnType(
              get(row.deconstructed_content_item, 'content_type')
            );

            const contentItemName = get(row.deconstructed_content_item, 'name');

            return (
              <>
                {!contentTypeRoute && contentItemName}
                {contentTypeRoute && (
                  <Dashboard.CellLink
                    to={mapRoute(contentTypeRoute, {
                      public_id_and_slug: `${get(
                        row.deconstructed_content_item,
                        'public_id_and_slug'
                      )}`,
                    })}
                  >
                    {contentItemName}
                  </Dashboard.CellLink>
                )}
              </>
            );
          }}
        </Dashboard.Column>
        <Dashboard.Column
          size="15"
          header={
            <Dashboard.Header
              title="Assigned by"
              orderingKey="created_by"
              ascendingText={alphabeticalAscendingText}
              descendingText={alphabeticalDescendingText}
            />
          }
        >
          {({ row }) => <Dashboard.UserCell data={row.created_by} showAvatar highlighted />}
        </Dashboard.Column>
        <Dashboard.Column
          size="15"
          header={<Dashboard.Header title="Assigned on" orderingKey="created" />}
        >
          {({ row }) => <Dashboard.DateCell data={row.created} />}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Due on" orderingKey="due_date" />}
        >
          {({ row }) => (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Dashboard.DateCell data={row.due_date} />
              {row.is_overdue && <AssignmentTraitIcon trait="overdue" />}
            </Stack>
          )}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Expires on" orderingKey="expires_at" />}
        >
          {({ row }) => (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Dashboard.DateCell data={row.expires_at} />
              {row.is_expired && <AssignmentTraitIcon trait="expired" />}
            </Stack>
          )}
        </Dashboard.Column>
        <Dashboard.Column
          size="12"
          header={<Dashboard.Header title="Progress" orderingKey="progress" />}
        >
          {({ row }) => (
            <ProgressCell row={row} handleRowViewProgressClick={handleRowViewProgressClick} />
          )}
        </Dashboard.Column>
        <Dashboard.Column size="12" header={<Dashboard.Header title="Status" />}>
          {({ row }) => <Dashboard.AssignmentStateCell data={row} />}
        </Dashboard.Column>
        <Dashboard.MenuColumn header={<Dashboard.Header title="" />}>
          {({ row }) => (
            <div>
              <Dashboard.MenuColumnItem
                title="Edit"
                icon="edit"
                onClick={() =>
                  setEditAssignmentOptions({
                    visible: true,
                    initialDueDate: row.due_date,
                    contentItemId: row.deconstructed_content_item.id,
                    userId: row.user.id,
                  })
                }
              />
              {!row.completed_datetime && (
                <Dashboard.MenuColumnItem
                  title="Mark as completed"
                  icon="check"
                  onClick={() =>
                    updateCompletion(
                      row.user.id,
                      row.deconstructed_content_item.id,
                      COMPLETED_STATUS
                    )
                  }
                />
              )}
              {row.completed_datetime && (
                <>
                  <HR />
                  <Dashboard.MenuColumnItem
                    title="Mark as incomplete"
                    icon="close"
                    color={colors.error600}
                    textColor={colors.error600}
                    onClick={() =>
                      updateCompletion(
                        row.user.id,
                        row.deconstructed_content_item.id,
                        INCOMPLETE_STATUS
                      )
                    }
                  />
                </>
              )}
              {!row.completed_datetime && row.state !== ASSIGNMENT_STATES.dropped && (
                <>
                  <HR />
                  <Dashboard.MenuColumnItem
                    title="Unassign"
                    icon="delete"
                    color={colors.error600}
                    textColor={colors.error600}
                    onClick={() => unassignUser(row.user.id, row.deconstructed_content_item.id)}
                  />
                </>
              )}
              {includes(TRACK_TYPES, getContentType(row)) && (
                <Dashboard.MenuColumnItem
                  title="View Progress"
                  icon={RuleIcon}
                  onClick={() => handleRowViewProgressClick(row)}
                />
              )}
            </div>
          )}
        </Dashboard.MenuColumn>
      </RQLDashboard>
      {editAssignmentModalOptions.visible && (
        <AssignDueDateModal
          initialValues={{
            dueDate: editAssignmentModalOptions.initialDueDate,
          }}
          handleClose={() => setEditAssignmentOptions({ visible: false })}
          title="Editing assignment"
          onSubmit={({ dueDate }) => {
            updateDueDate(
              editAssignmentModalOptions.userId,
              editAssignmentModalOptions.contentItemId,
              dueDate
            );
            setEditAssignmentOptions({
              visible: false,
              initialDueDate: null,
              contentItemId: null,
              userId: null,
            });
          }}
        />
      )}
      {showTrackItemAssignmentListModal && targetUser && targetTrack && (
        <ListTrackItemAssignmentsModal
          handleClose={handleViewProgressClose}
          handleBack={handleViewProgressClose}
          user={targetUser}
          contenItem={targetTrack}
        />
      )}
    </>
  );
};

AssignmentsPage.propTypes = {
  dynamicFilters: PropTypes.array,
};

const Assignments = () => {
  const { dynamicFilters, fetchFiltersStatus } = useAssignmentsContext();

  if (fetchFiltersStatus !== STATUS_DONE) return null;

  return <AssignmentsPage dynamicFilters={dynamicFilters} />;
};

export default Assignments;
