import TextEditorInput, {
  TextEditorInputProps,
} from 'inputs/components/TextEditorInput/TextEditorInput';
import Loading from 'shared/components/Loading';
import { get, isUndefined } from 'vendor/lodash';

interface Props
  extends Omit<TextEditorInputProps, 'value' | 'label' | 'onChange' | 'initial' | 'readOnly'> {
  input: any;
  inputProps: any;
}

const SettingRichTextTemplateField = ({ input, ...props }: Props) => {
  // The input value format is [template_path, template_content]
  // If there's a template, the content is from it
  // Otherwise, it's a customized content
  const value = get(input, 'value.value.1');

  if (isUndefined(value)) return <Loading />;

  return (
    <TextEditorInput
      {...props}
      label={undefined}
      value={value}
      onChange={(newValue) => {
        input.onChange({ ...input.value, value: ['', newValue], inherited: false });
      }}
      readOnly={false}
    />
  );
};

SettingRichTextTemplateField.propTypes = {};

export default SettingRichTextTemplateField;
