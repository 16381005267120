import { Field } from 'redux-form';

import CheckboxField from 'inputs/components/CheckboxField';
import { FormFieldGroup } from 'shared/components/Form';
import { useLabels } from 'shared/hooks';

const InlineItemField = () => {
  const { label_track_plural: labelTrackPlural } = useLabels();

  return (
    <FormFieldGroup>
      <Field
        name="is_inline"
        label="Inline Item"
        infoText={`When enabled, this content can be only found and consumed through ${labelTrackPlural}, preventing unnecessary noise on the catalog and empowering owners to control content access centrally.`}
        labelSize="h5"
        labelWeight="medium"
        component={CheckboxField}
      />
    </FormFieldGroup>
  );
};

export default InlineItemField;
