import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';

const BaseSlider = ({ ...rest }) => {
  return <input {...rest} type="range" />;
};

const Slider = styled(BaseSlider)`
  &[type='range'] {
    --range: calc(${(props) => props.max || 0} - ${(props) => props.min || 0});
    --ratio: calc((${(props) => props.value || 0} - ${(props) => props.min || 0}) / var(--range));
    --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
    width: 100%;
    margin: 0;
    padding: 0;
    height: 1.5em;
    background: transparent;
    font: 1em/1 arial, sans-serif;
  }
  &[type='range'],
  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &[type='range']::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.25em;
    background: ${({ disabled }) => (disabled ? colors.neutral400 : colors.neutral200)};
  }
  &[type='range']::-webkit-slider-runnable-track {
    background: linear-gradient(
        ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)},
        ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)}
      )
      0 / var(--sx) 100% no-repeat
      ${({ disabled }) => (disabled ? colors.neutral400 : colors.neutral200)};
  }
  &[type='range']::-moz-range-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.25em;
    background: ${({ disabled }) => (disabled ? colors.neutral400 : colors.neutral200)};
  }
  &[type='range']::-ms-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.25em;
    background: ${({ disabled }) => (disabled ? colors.neutral400 : colors.neutral200)};
  }
  &[type='range']::-moz-range-progress {
    height: 0.25em;
    background: ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)};
  }
  &[type='range']::-ms-fill-lower {
    height: 0.25em;
    background: ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)};
  }
  &[type='range']::-webkit-slider-thumb {
    margin-top: -0.625em;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)};
    cursor: pointer;
  }
  &[type='range']::-moz-range-thumb {
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: ${({ disabled }) => (disabled ? colors.neutral600 : colors.action600)};
    cursor: pointer;
  }
  &[type='range']::-ms-thumb {
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: ${({ disabled }) => (disabled ? colors.neutral400 : colors.action600)};
    cursor: pointer;
  }
  &[type='range']::-ms-tooltip {
    display: none;
  }
`;

Slider.defaultProps = {
  disabled: false,
  min: 0,
  max: 100,
  step: 1,
};

Slider.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.object,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  rows: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};

export default Slider;
