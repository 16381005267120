import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const FatArrowIcon = ({ width, height, fill, orientation }) => {
  const d = 'M3.88 5L10 11.136 16.12 5 18 6.89l-8 8.038-8-8.039z';

  let transform = null;
  if (orientation === 'up') {
    transform = 'matrix(1 0 0 -1 0 19.928)';
  } else if (orientation === 'right') {
    transform = 'matrix(0 1 1 0 .036 -.036)';
  }

  return (
    <SvgIcon
      d={d}
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      transform={transform}
    />
  );
};

FatArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  orientation: PropTypes.string,
};

FatArrowIcon.defaultProps = {
  width: 20,
  height: 20,
};

export default FatArrowIcon;
