import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const InfoIcon = ({ width, height, fill }) => {
  const d =
    'm 10.696568,-0.01902488 c -1.4579006,0 -2.6619038,1.16689168 -2.6619038,2.62479128 0,1.4941743 1.2040032,2.6990141 2.6619038,2.6990141 1.421624,-2e-7 2.624791,-1.2040034 2.624791,-2.6619028 0,-1.4578994 -1.166891,-2.66190258 -2.624791,-2.66190258 z M 6.7222686,6.5092155 c -0.2193757,0 -0.437672,-0.00253 -0.6207731,0.033737 -0.3644749,0.07255 -0.5491146,0.2546252 -0.5836618,0.5465507 -0.036275,0.2556505 0.1475011,0.5490603 0.4757012,0.6578845 a 7.3015889,7.3015889 0 0 0 0.8738058,0.145072 c 0.692675,0.070822 0.9109172,0.3295768 0.9109172,1.0222517 0,0.1088242 -0.037111,0.2167037 -0.037111,0.3272554 C 7.449221,10.882968 7.159266,12.560405 6.8673407,14.201405 l -0.4385898,2.844087 c -0.1813738,1.38535 1.1695367,2.698987 2.2266866,2.844086 0.6546729,0.110551 1.2767686,0.107961 1.9331695,0.107961 1.385349,0 2.548543,-0.580828 3.498596,-1.565428 a 1.7446427,1.7446427 0 0 0 0.364367,-0.620774 c 0.110553,-0.400749 -0.219133,-0.656266 -0.657885,-0.50944 -0.255651,0.07254 -0.473083,0.252171 -0.728734,0.360995 -0.3282,0.146826 -0.656938,0.255514 -0.98514,0.293516 -0.400749,0.03628 -0.690758,-0.182911 -0.799583,-0.583661 -0.07254,-0.291924 -0.112225,-0.618991 -0.07423,-0.910917 0.07254,-0.620125 0.220079,-1.240549 0.33063,-1.858947 0.328201,-1.896651 0.690678,-3.756136 1.018877,-5.6510597 0.108826,-0.5113012 0.182184,-1.0203893 0.182184,-1.5316906 0,-0.6201255 -0.365014,-0.9109172 -0.985139,-0.9109172 z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

InfoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default InfoIcon;
