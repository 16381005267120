import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import ButtonLink from 'shared/components/ButtonLink';
import Icon from 'shared/components/Icon';
import Text from 'shared/components/Text';
import { useCopyToClipboard } from 'shared/hooks';

const CopyWrapper = styled(ButtonLink)`
  display: flex;
  gap: 3px;
  width: ${({ showLabel }) => (showLabel ? '70px' : 'auto')};
`;

const Copy = ({ content, labelCopy, labelCopied, showLabel }) => {
  const { clipboard, copyToClipboard } = useCopyToClipboard(2000);

  return (
    <CopyWrapper onClick={() => copyToClipboard(content)} showLabel={showLabel}>
      <Icon
        color={colors.action600}
        width={12}
        height={12}
        name={clipboard.copied ? 'check' : 'clone'}
      />
      {showLabel && (
        <Text color={colors.action600} bold>
          {clipboard.copied ? labelCopied : labelCopy}
        </Text>
      )}
    </CopyWrapper>
  );
};

Copy.propTypes = {
  content: PropTypes.string.isRequired,
  labelCopy: PropTypes.string,
  labelCopied: PropTypes.string,
  showLabel: PropTypes.bool,
};

Copy.defaultProps = {
  labelCopy: 'Copy',
  labelCopied: 'Copied',
  showLabel: true,
};

export default Copy;
