import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './SvgIcon';

const PlusIcon = ({ width, height, fill }) => {
  const d = 'M11.32 11.32V18H8.68v-6.68H2V8.68h6.68V2h2.64v6.68H18v2.64h-6.68z';
  return <SvgIcon d={d} fill={fill} width={width} height={height} viewBox="0 0 20 20" />;
};

PlusIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default PlusIcon;
