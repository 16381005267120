import PropTypes from 'prop-types';

import { UserAvatar } from 'app/shared';
import Pill from 'app/shared/components/Pill';
import colors from 'services/colors';
import { map, slice } from 'vendor/lodash';
import { Box, Paper, Typography } from 'vendor/mui';

export const UniquePeopleImpacted = ({ users, totalCount }) => {
  const renderImpactedUsersAvatars = () => {
    const userDisplayLimit = 30;
    const hasMore = totalCount > userDisplayLimit;

    return (
      <>
        {map(slice(users, 0, userDisplayLimit), (impactedUser: any) => (
          <UserAvatar key={impactedUser.id} size="small" user={impactedUser} showPopover />
        ))}
        {hasMore && (
          <Pill
            label={`+${totalCount - userDisplayLimit}`}
            // todo: handle this eslint error about missing props
            icon={undefined}
            imagePlaceHolder={undefined}
            imageSrc={undefined}
            labelMaxWidth={undefined}
            onClick={undefined}
            onEdit={undefined}
            size={undefined}
            variant={undefined}
            iconMarginRight={undefined}
            toolTip={undefined}
            round={undefined}
          />
        )}
      </>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        height: '120px',
        borderRadius: '4px',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.neutral100,
          p: '20px',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
          minWidth: '5em',
        }}
      >
        <Typography variant="h4">{totalCount}</Typography>
      </Box>
      <Box sx={{ p: 1, height: '100%' }}>
        <Typography variant="body1" gutterBottom>
          {totalCount > 0 ? 'People impacted:' : 'No people impacted yet.'}
        </Typography>
        {totalCount > 0 && (
          <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {renderImpactedUsersAvatars()}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

UniquePeopleImpacted.propTypes = {
  users: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
};
