import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import colors from 'services/colors';
import { withProductTour } from 'services/product-tour';
import Text from 'shared/components/Text';

const getVariantColor = (variant) => {
  if (variant === 'action') return colors.action600;
  return colors.emphasis600;
};

const focusedBoxStyle = css`
  ${({ checked, variant }) =>
    checked
      ? `
    opacity: 0.7;
  `
      : `
    border-color: ${getVariantColor(variant)};
  `}
`;

export const Box = styled.span`
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${colors.neutral100};
    cursor: not-allowed;
  `}

  ${({ checked, disabled, variant }) =>
    checked
      ? `background-color: ${disabled ? colors.neutral200 : getVariantColor(variant)};`
      : `border-color: ${colors.neutral200};`}
`;

export const LabelContainer = styled.label`
  display: inline-flex;
  align-items: center;
  margin: 0;
  ${({ disabled }) => !disabled && 'cursor: pointer;'}

  &:hover ${Box} {
    ${({ disabled }) => !disabled && focusedBoxStyle}
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  :focus + ${Box} {
    ${({ disabled }) => !disabled && focusedBoxStyle}
  }
`;

const ProductTourBox = withProductTour(Box);

export const Label = styled(Text)`
  margin-left: 8px;
  font-weight: normal;

  ${({ disabled }) => (disabled ? `color: ${colors.neutral400};` : '')}
`;

export const Checkmark = styled.span`
  position: absolute;
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// This selects which props will be excluded from 'inputProps'
function SquareCheckboxInput(props) {
  const { label, labelSize, tourId, variant, ...inputProps } = props;
  const { checked, disabled } = inputProps;

  return (
    // The `div` wrapper is needed since the label is inline,
    //  so the label does not occupy full width
    <CheckBoxWrapper>
      <LabelContainer checked={checked} disabled={disabled} variant={variant}>
        <Input type="checkbox" {...inputProps} />
        <ProductTourBox checked={checked} disabled={disabled} tourId={tourId} variant={variant}>
          {checked && <Checkmark />}
        </ProductTourBox>
        {label && (
          <Label size={labelSize} disabled={disabled}>
            {label}
          </Label>
        )}
      </LabelContainer>
    </CheckBoxWrapper>
  );
}

SquareCheckboxInput.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  labelSize: PropTypes.string,
  tourId: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

SquareCheckboxInput.defaultProps = {
  labelSize: 'h4',
};

export default SquareCheckboxInput;
