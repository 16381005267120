import { TextReader } from 'app/inputs/components/LazyTextEditor';
import colors from 'services/colors';
import { Paper, Box, Typography } from 'vendor/mui';

interface HeaderProps {
  title: string;
  subtitle?: string;
  actionButtons: React.ReactNode;
  useDraftSubtitle?: boolean;
}

export const TrackItemFormHeader = ({
  title,
  subtitle,
  actionButtons,
  useDraftSubtitle = false,
}: HeaderProps) => {
  const draftSubtitle = useDraftSubtitle && subtitle ? JSON.parse(subtitle) : null;

  return (
    <Paper
      elevation={0}
      sx={{ display: 'flex', width: '100%', justifyContent: 'center', borderRadius: '8px' }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', p: '20px', rowGap: '8px', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Box>{actionButtons}</Box>
        </Box>
        <Box sx={{ marginTop: -1.5 }}>
          <Typography sx={{ color: colors.neutral500 }}>
            {draftSubtitle ? <TextReader value={draftSubtitle} /> : subtitle || 'No description'}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
