import PropTypes from 'prop-types';

// From EnrollmentActionButton component
export const PerformEnrollmentActionsPropType = PropTypes.shape({
  performLocalEnrollment: PropTypes.func,
  performOnlineEnrollment: PropTypes.func,
  performJoinLocalWaitlist: PropTypes.func,
  performJoinOnlineWaitlist: PropTypes.func,
  performLeaveWaitlist: PropTypes.func,
  performUnenroll: PropTypes.func,
});

// From getUserEnrollmentStatus and getEventStatus helper in services.js
//  Also getEnrollmentConditions from EnrollmentActionButton.js
export const EnrollmentStatusPropType = PropTypes.shape({
  eventHasLocalEnrollAvailable: PropTypes.bool,
  eventHasLocalWaitlistAvailable: PropTypes.bool,
  eventHasOnlineEnrollAvailable: PropTypes.bool,
  eventHasOnlineWaitlistAvailable: PropTypes.bool,
  eventIsFullLocal: PropTypes.bool,
  eventIsFullOnline: PropTypes.bool,
  eventIsFull: PropTypes.bool,
  eventIsLocal: PropTypes.bool,
  eventIsOnline: PropTypes.bool,

  userIsOnLocalWaitlist: PropTypes.bool,
  userIsOnOnlineWaitlist: PropTypes.bool,
  userIsOnWaitlist: PropTypes.bool,
  userIsEnrolledLocal: PropTypes.bool,
  userIsEnrolledOnline: PropTypes.bool,
  userIsEnrolled: PropTypes.bool,

  showLocalEnroll: PropTypes.bool,
  showLocalWaitlist: PropTypes.bool,
  showOnlineEnroll: PropTypes.bool,
  showOnlineWaitlist: PropTypes.bool,
});

// From getSpotsStatus in services.js
export const SpotsStatusPropType = PropTypes.shape({
  localWaitlistCurrentSpot: PropTypes.number,
  localWaitlistPretendedSpot: PropTypes.number,
  localWaitlistSpotsLeft: PropTypes.number,
  localSpotsLeft: PropTypes.number,
  hasUnlimitedLocalSpots: PropTypes.bool,

  onlineWaitlistCurrentSpot: PropTypes.number,
  onlineWaitlistPretendedSpot: PropTypes.number,
  onlineWaitlistSpotsLeft: PropTypes.number,
  onlineSpotsLeft: PropTypes.number,
  hasUnlimitedOnlineSpots: PropTypes.bool,
});
