import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import actions from 'entities/actions';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import Button from 'shared/components/Button';
import { FormFieldGroup } from 'shared/components/Form';
import Form from 'shared/components/OldForm';
import { pick } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const FormContainer = styled.div`
  padding-top: 16px;
`;

const FieldsContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
`;

const StyledHR = styled.hr`
  color: ${colors.neutral200};
  width: 100%;
  margin: 0px;
`;

const SubmitSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  gap: 16px;
`;

const SaveSegmentForm = ({ handleSubmit, submitting, handleClose }) => {
  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <FieldsContainer>
          <FormFieldGroup>
            <Field
              name="name"
              label="Name"
              required
              component={TextField}
              validate={[validateRequired]}
              subtext="This is the name of your segment, it must have an unique value."
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              multiline
              rows={2}
              label="Description (optional)"
              component={TextField}
              id="description"
              name="description"
            />
          </FormFieldGroup>
        </FieldsContainer>
        <StyledHR />
        <SubmitSection>
          <Button type="button" color="error" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small" disabled={submitting}>
            Save
          </Button>
        </SubmitSection>
      </form>
    </FormContainer>
  );
};

SaveSegmentForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  submitting: PropTypes.bool,
};

const ConnectedSaveSegmentForm = reduxForm({
  form: 'saveSegmentForm',
  onSubmit: (values, dispatch, { isEdit, expression, contentType, initialValues = {} }) => {
    return onSubmitActions(actions.segment[isEdit ? 'update' : 'create'].toString(), (values) => {
      const payload = {
        ...pick(values, ['name', 'description']),
        // The segment edit form should only update the
        // name and description and ignore the filters
        ...(isEdit ? {} : { expression, content_type: contentType, raw_filters: {} }),
      };

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: 'saveSegmentForm',
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => {
    toast.success('Segment successfully saved.');
    return onSubmitSuccessHandler(result);
  },
  onSubmitFail: () => {
    toast.error('Failed to save segment. Check the flagged fields and try again.');
  },
})(SaveSegmentForm);

export default ConnectedSaveSegmentForm;
