import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import FacilitatorField from 'app/inputs/components/FacilitatorField';
import { useSettingsSectionsList } from 'app/settings/hooks';
import { prepareSettingsValuesForSubmission } from 'app/settings/services';
import { FACILITATOR_ROLES } from 'catalog/constants';
import actions from 'entities/actions';
import CoverImageField from 'inputs/components/CoverImageField';
import DurationField, { validateDuration } from 'inputs/components/DurationField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import { FormFieldGroup } from 'shared/components/Form';
import NewContentForm from 'shared/components/NewContentForm';
import CompletionSection from 'shared/components/NewContentForm/CompletionSection';
import Form from 'shared/components/OldForm';
import { useFormPreventTransition, useFormSelector, useLabels, useToggles } from 'shared/hooks';
import { map, pick, toLower } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

const TaskForm = ({
  form,
  topBarActionName,
  isEdit,
  backRoute,
  change,
  invalid,
  error,
  handleSubmit,
  submitting,
  isModalForm,
}) => {
  const {
    label_task: labelTask,
    label_task_plural: labelTaskPlural,
    label_track_plural: labelTrackPlural,
  } = useLabels();

  useFormPreventTransition(form);

  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();

  const taskName = useFormSelector(form, 'name');

  const HCArticleURL = 'https://help.plusplus.app/en/articles/6342526-how-to-add-tasks-to-a-track';
  const infoPanelText = `${labelTaskPlural} represent activities for people to complete. They can only exist with ${toLower(
    labelTrackPlural
  )}.`;

  const [settingsSectionsList] = useSettingsSectionsList('task/form', form);

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const taskSectionsList = [
    {
      id: 'task-details',
      label: `${labelTask} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelTask)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="task_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              inputId="duration"
              name="duration"
              label="Estimated Duration (H:MM)"
              component={DurationField}
              validate={[validateDuration]}
            />
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: <FacilitatorField label="Maintainer(s)" />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: `Due Date`,
      icon: 'clock',
      section: <CompletionSection form={form} labelContentType={labelTask} change={change} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  return (
    <NewContentForm
      contentNameSingular={labelTask}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCArticleURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={taskName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={[]}
      contentSectionsList={taskSectionsList}
      advancedSettingsList={[...settingsSectionsList]}
      isModalForm={isModalForm}
    />
  );
};

TaskForm.propTypes = {
  form: PropTypes.string,
  topBarActionName: PropTypes.string,
  isEdit: PropTypes.bool,
  backRoute: PropTypes.string,

  // Redux props
  change: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isModalForm: PropTypes.bool,
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { initialValues, form, isEdit }) => {
    const actionName = actions.task[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const payload = {
        ...pick(values, [
          'content_body',
          'cover',
          'duration',
          'facilitators_ids',
          'name',
          'time_to_complete',
          'time_to_retake',
        ]),
        is_completable: true,
        is_hidden: true,
        is_inline: true,
      };

      payload.facilitators = map(payload.facilitators_ids, (id) => ({
        user_id: id,
        role: FACILITATOR_ROLES.maintainer,
      }));

      if (!payload.duration) payload.duration = '00:00:00';

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: form,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(TaskForm);
