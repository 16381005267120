import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Loading from 'shared/components/Loading';
import { ErrorContainer } from 'shared/components/OldForm/FormErrorContainer';
import { map, size } from 'vendor/lodash';

import CourseConfigItem from './CourseConfigItem';

const CourseConfigForm = ({ fieldValues, loading, requestError, courseId, refreshSettings }) => {
  const [disableActions, setDisableActions] = useState(false);

  // When it finishes loading, reset state
  useEffect(() => {
    if (!loading) {
      setDisableActions(false);
    }
  }, [loading]);

  return (
    <>
      {size(fieldValues) === 0 && loading && <Loading hasMargin />}
      {requestError && <ErrorContainer>{requestError}</ErrorContainer>}
      {map(fieldValues, (field) => (
        <CourseConfigItem
          field={field}
          key={field.id}
          courseId={courseId}
          disableActions={disableActions}
          onStartRequest={() => setDisableActions(true)}
          onEndRequest={() => setDisableActions(false)}
          refreshSettings={refreshSettings}
        />
      ))}
    </>
  );
};

CourseConfigForm.propTypes = {
  fieldValues: PropTypes.array,
  loading: PropTypes.bool,
  requestError: PropTypes.string,
  courseId: PropTypes.string,
  refreshSettings: PropTypes.func,
};

export default CourseConfigForm;
