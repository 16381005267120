import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Button from 'shared/components/Button';
import LoadMoreButton from 'shared/components/LoadMoreButton';
import UserHighlight from 'shared/components/UserHighlight';
import { map, concat, includes, without, isEmpty } from 'vendor/lodash';

const UserList = styled.div``;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.neutral200};
  width: 100%;
  padding: 12px 0;
  &:last-child {
    border: none;
  }
`;

const UserContainer = styled.div`
  display: flex;
  ${({ markedForRemoval }) =>
    markedForRemoval &&
    `
    -webkit-filter: grayscale(1);
    span {
      color: ${colors.neutral200};
    }
  `}
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

class GroupUserList extends React.Component {
  onClickToRemove = (userId) => {
    const { removalInput } = this.props;
    const { onChange, value } = removalInput;
    let onChangeValue = concat(value, userId);
    if (isEmpty(value)) {
      onChangeValue = [userId];
    }
    onChange(onChangeValue);
  };

  onClickToUndoRemove = (userId) => {
    const { removalInput } = this.props;
    const { onChange, value } = removalInput;
    if (!includes(value, userId)) {
      return;
    }
    const newPeopleToRemove = without(value, userId);
    onChange(newPeopleToRemove);
  };

  onClickToUndoAdd = (userId) => {
    const { handleUndoAddPeople } = this.props;
    handleUndoAddPeople(userId);
  };

  render() {
    const {
      users,
      removalEnabled,
      additionEnabled,
      removalInput,
      fetchNextPage,
      isLoadingMore,
      loadMoreLink,
    } = this.props;

    return (
      <UserList>
        {map(users, (u) => (
          <UserItem key={u.id}>
            <UserContainer markedForRemoval={removalEnabled && includes(removalInput.value, u.id)}>
              <UserHighlight user={u} />
            </UserContainer>
            {removalEnabled && !includes(removalInput.value, u.id) && (
              <Button
                onClick={() => this.onClickToRemove(u.id)}
                size="small"
                type="button"
                color="error"
              >
                Remove
              </Button>
            )}
            {removalEnabled && includes(removalInput.value, u.id) && (
              <Button
                onClick={() => this.onClickToUndoRemove(u.id)}
                size="small"
                type="button"
                color="secondary"
              >
                Undo Remove
              </Button>
            )}
            {additionEnabled && (
              <Button
                onClick={() => this.onClickToUndoAdd(u.id)}
                size="small"
                type="button"
                color="secondary"
              >
                Undo Add
              </Button>
            )}
          </UserItem>
        ))}
        {(isLoadingMore || Boolean(loadMoreLink)) && (
          <LoadMoreContainer>
            <LoadMoreButton
              isLoading={isLoadingMore}
              show={Boolean(loadMoreLink)}
              onClick={fetchNextPage}
              size="small"
              type="button"
            />
          </LoadMoreContainer>
        )}
      </UserList>
    );
  }
}

GroupUserList.propTypes = {
  users: PropTypes.array,
  removalEnabled: PropTypes.bool,
  additionEnabled: PropTypes.bool,
  removalInput: PropTypes.object,
  handleUndoAddPeople: PropTypes.func,
  fetchNextPage: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  loadMoreLink: PropTypes.string,
};

export default GroupUserList;
