import React from 'react';

import { ContentItem } from 'app/shared-content-item/interfaces';
import { CONTENT_TYPES } from 'catalog/constants';
import { User } from 'shared/components/types';
import { get, includes } from 'vendor/lodash';
import { Card, Divider, Stack } from 'vendor/mui';

import { ContentItemHeaderContent } from './ContentItemHeaderContent';
import { ContentItemHeaderCover } from './ContentItemHeaderCover';
import { ContentItemHeaderFooter } from './ContentItemHeaderFooter';

export interface ContentItemHeaderProps {
  content: ContentItem;
  contextMenu: React.ReactNode;
  hideCover?: boolean;
  primaryActionButton?: React.ComponentType<{ content: ContentItem }>;
  secondaryActionButton?: React.ComponentType<{ content: ContentItem }>;
  clickableName?: boolean;
  isRequired?: boolean;
  maintainers?: User[];
  isPreviewMode?: boolean;
  detailContent?: React.ReactNode;
}

const ContentItemHeader = (props: ContentItemHeaderProps) => {
  const {
    content,
    contextMenu,
    hideCover,
    primaryActionButton,
    secondaryActionButton,
    clickableName,
    isRequired = true,
    maintainers,
    isPreviewMode = false,
    detailContent,
  } = props;

  const { content_type: contentType } = content;
  const isArchived = get(content, 'is_archived', Boolean(get(content, 'archival.archived_at')));
  const showFooter = !includes([CONTENT_TYPES.program], contentType);

  return (
    <Card sx={{ display: 'flex', overflow: 'visible' }}>
      <Stack direction="column" divider={<Divider />} flexGrow={1}>
        <Stack direction="row">
          {content.cover && !hideCover && (
            <ContentItemHeaderCover cover={content.cover} alt={content.name} />
          )}
          <ContentItemHeaderContent
            content={content}
            contextMenu={contextMenu}
            clickableName={clickableName}
            isRequired={isRequired}
            maintainers={maintainers}
            detailContent={detailContent}
          />
        </Stack>
        {showFooter && (
          <ContentItemHeaderFooter
            content={content}
            primaryActionButton={primaryActionButton}
            secondaryActionButton={secondaryActionButton}
            isArchived={isArchived}
            isPreviewMode={isPreviewMode}
          />
        )}
      </Stack>
    </Card>
  );
};

export default ContentItemHeader;
