import { ChevronLeft, ChevronRight, Try, KeyboardTab as ExitIcon } from '@mui/icons-material';
import { Box, Button, Divider, Rating } from '@mui/material';
import { grey } from '@mui/material/colors';
import { get } from 'lodash';
import * as React from 'react';

import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { CONTENT_TYPES } from 'app/catalog/constants';
import { Assignment, ContentItem } from 'app/shared-content-item/interfaces';
import { useIsPreviewQueryParam } from 'app/shared/hooks';
import ContentRating from 'app/stand-alone-shared/components/ContentRating';
import { useAssignmentActionsCtx } from 'features/contentitems/contexts/AssignmentActionsCtx';
import { useEnsureContentItemAssignmentQuery } from 'features/contentitems/hooks/useEnsureContentItemAssignmentQuery';
import colors from 'services/colors';
import { VisibilityIcon } from 'vendor/mui-icons';

import { useComposableTrackCtx } from '../contexts/ComposableTrackCtx';
import { useTrackContentConsumptionCtx } from '../contexts/TrackContentConsumptionCtx';
import { useTrackContentNavigationCtx } from '../contexts/TrackContentNavigationCtx';
import { useTrackStartContent } from '../hooks/useTrackStartContent';

import { TrackItemAssignmentActionsProvider } from './TrackItemAssignmentActionsProvider';
import { TrackItemPrimaryActionSwitcher } from './TrackItemPrimaryActionSwitcher';

type TrackItemFeedbackActionProps = {
  contentItem: ContentItem;
  assignment: Assignment;
};

function TrackItemFeedbackAction(props: TrackItemFeedbackActionProps) {
  const { contentItem, assignment } = props;

  const [isRatingContent, setRatingContent] = React.useState(false);

  const { rate, isLoading } = useAssignmentActionsCtx();

  const handleRateContent = (rateBody: any) => {
    rate(rateBody);
  };

  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);

  return (
    <>
      {currentRating <= 0 && (
        <Button
          variant="text"
          startIcon={<Try />}
          onClick={() => setRatingContent(true)}
          disabled={isLoading}
        >
          Feedback
        </Button>
      )}

      {currentRating > 0 && (
        <Box display="flex" alignItems="center" gap="4px">
          <Rating value={currentRating} readOnly />

          <Button
            variant="text"
            size="small"
            onClick={() => setRatingContent(true)}
            disabled={isLoading}
            startIcon={<Try />}
          >
            Edit
          </Button>
        </Box>
      )}

      {isRatingContent && (
        <ContentRating
          content={contentItem}
          onSubmitFunc={handleRateContent}
          handleClose={() => setRatingContent(false)}
        />
      )}
    </>
  );
}

type TrackAdvanceActionProps = {
  contentItem?: ContentItem;
};

function TrackItemAdvanceAction(props: TrackAdvanceActionProps) {
  const { contentItem } = props;

  const { currentTrackNode } = useTrackContentConsumptionCtx();
  const {
    current: currentNavigation,
    onAdvance,
    onExitCurrentTrack,
  } = useTrackContentNavigationCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const trackAssignment = currentTrackNode.track.user_assignment;
  const isCurrentTrackCompleted = trackAssignment?.state === ASSIGNMENT_STATES.completed;

  const isLastPage = currentNavigation.page === currentNavigation.totalPages;
  const isCurrentItemCompleted =
    contentItem?.user_assignment?.state === ASSIGNMENT_STATES.completed;
  const displayAsPrimaryAction = isCurrentItemCompleted || isPreviewMode;

  if (!isLastPage) {
    return (
      <Button
        variant={displayAsPrimaryAction ? 'contained' : 'outlined'}
        endIcon={<ChevronRight />}
        onClick={onAdvance}
        disabled={isLastPage}
      >
        Next
      </Button>
    );
  }

  return (
    <Button
      variant={displayAsPrimaryAction ? 'contained' : 'outlined'}
      endIcon={<ExitIcon />}
      onClick={onExitCurrentTrack}
    >
      {isCurrentTrackCompleted ? 'Finish' : 'Exit'}
    </Button>
  );
}

type TrackItemActionsRendererProps = {
  contentItem: ContentItem;
  assignment: Assignment;
};

function TrackItemActionsRenderer(props: TrackItemActionsRendererProps) {
  const { contentItem, assignment } = props;

  const isCurrentItemCompleted = assignment.state === ASSIGNMENT_STATES.completed;

  return (
    <TrackItemAssignmentActionsProvider contentItem={contentItem} assignment={assignment}>
      <Box display="flex" alignItems="center" gap={2}>
        {isCurrentItemCompleted && (
          <TrackItemFeedbackAction contentItem={contentItem} assignment={assignment} />
        )}

        <TrackItemPrimaryActionSwitcher contentItem={contentItem} />

        <TrackItemAdvanceAction contentItem={contentItem} />
      </Box>
    </TrackItemAssignmentActionsProvider>
  );
}

type TrackItemPreviewActionsRendererProps = {
  contentItem: ContentItem;
};

function TrackItemPreviewActionsRenderer(props: TrackItemPreviewActionsRendererProps) {
  const { contentItem } = props;

  const { getSectionOrItemIndex, filterTrackItems } = useComposableTrackCtx();
  const { currentTrackItemContentType: contentType } = useTrackContentConsumptionCtx();
  const onStartTrackContent = useTrackStartContent();

  const handlePreviewTrack = () => {
    const trackItems = filterTrackItems(contentItem.public_id);
    const firstItem = trackItems[0];
    const firstItemIdx = getSectionOrItemIndex(
      contentItem.public_id,
      firstItem.content_item.public_id
    );

    onStartTrackContent({
      page: firstItemIdx + 1,
      preview: true,
    });
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {contentType === CONTENT_TYPES.track && (
        <Button onClick={handlePreviewTrack} startIcon={<VisibilityIcon />}>
          Preview
        </Button>
      )}

      <TrackItemAdvanceAction contentItem={contentItem} />
    </Box>
  );
}

export function TrackNavigationBar() {
  const { currentTrackItem, currentSection } = useTrackContentConsumptionCtx();
  const { current: currentNavigation, onGoBack, onAdvance } = useTrackContentNavigationCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const { data: contentItem } = useEnsureContentItemAssignmentQuery(currentTrackItem?.content_item);
  const assignment = contentItem?.user_assignment;

  const isFirstPage = currentNavigation.page === 1;
  const isSection = currentSection != null;

  const canDisplayPreviewActions = isPreviewMode && contentItem != null;
  const canDisplayItemActions = contentItem != null && assignment != null;

  return (
    <Box>
      <Divider />

      <Box
        height="70px"
        paddingX="32px"
        display="flex"
        alignItems="center"
        bgcolor={grey[100]}
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={onGoBack}
          disabled={isFirstPage}
          sx={{
            backgroundColor: colors.neutral0,
            '&:hover': {
              backgroundColor: colors.neutral50,
            },
          }}
        >
          Previous
        </Button>

        {isSection ? (
          <Button variant="contained" endIcon={<ChevronRight />} onClick={onAdvance}>
            Next
          </Button>
        ) : canDisplayPreviewActions ? (
          <TrackItemPreviewActionsRenderer contentItem={contentItem} />
        ) : canDisplayItemActions ? (
          <TrackItemActionsRenderer contentItem={contentItem} assignment={assignment} />
        ) : null}
      </Box>
    </Box>
  );
}
