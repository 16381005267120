import PropTypes from 'prop-types';
import React from 'react';

import { sendExceptionToSentry } from 'services/utils';

class LazyErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { error: true };
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    sendExceptionToSentry(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h4>
            Component failed loading, please try again. If the problem persists contact us at
            support@plusplus.co
          </h4>
        </div>
      );
    }
    return children;
  }
}

LazyErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default LazyErrorBoundary;
