import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import actions from 'app/entities/actions';
import { sessionSchema, userProfileSchema } from 'app/entities/schema';
import { getDataFromState, useEntities } from 'app/entities/utils';
import Loading from 'app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from 'app/shared/constants';
import { IdParam } from 'common/types';
import { map, pick, size } from 'vendor/lodash';

import { Session, ImpactItem, User } from '../shared/interfaces';
import { UpcomingImpactContainer } from '../shared/UpcomingImpactContainter';
import { UpcomingImpactModal } from '../shared/UpcomingImpactModal';

const CONTAINER_PAGE_SIZE = 4;
const MODAL_PAGE_SIZE = 25;

function mapSessionsToImpactItems(sessions: Session[]): ImpactItem[] {
  return map(sessions, (session) => ({
    ...pick(session, ['public_id', 'timezone']),
    datetime: session.starts_at,
    is_online: session.allows_online,
    impact: session.enrollments_count,
  }));
}

interface UpcomingSessionsImpactModalProps {
  user: User;
  handleClose: () => void;
}

const UpcomingSessionsImpactModal = ({ user, handleClose }: UpcomingSessionsImpactModalProps) => {
  const [items, setItems] = useState<ImpactItem[]>([]);

  const [fetchItems, { status, data, count }, loadMoreItems] = useEntities(
    actions.sessionMentorship.retrieveList,
    () => {
      if (status === STATUS_DONE) setItems(mapSessionsToImpactItems(data));
    },
    {
      schema: [sessionSchema],
      loadMoreAction: actions.sessionMentorship.retrieveListLoadMore,
    }
  );

  useEffect(() => {
    fetchItems({
      host: user.id,
      o: 'starts_at',
      page_size: MODAL_PAGE_SIZE,
      period: 'upcoming',
      view_mode: 'impact',
    });
  }, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UpcomingImpactModal
      title={`Upcoming Session Impact from ${user.name}`}
      emptyMessage="No upcoming sessions yet."
      items={items}
      status={status}
      showLoadMoreButton={size(items) < count}
      loadMoreItems={loadMoreItems}
      handleClose={handleClose}
    />
  );
};

export const UpcomingSessionsImpact = () => {
  const { id: userId } = useParams<IdParam>();

  const { status: profileStatus, data: profileData } = useSelector((state) =>
    getDataFromState(`userProfile${userId}`, state, userProfileSchema)
  );

  const [items, setItems] = useState<ImpactItem[]>([]);
  const [showViewAllModal, setShowViewAllModal] = useState<boolean>(false);

  const [fetchItems, { status, data, count }] = useEntities(
    actions.sessionMentorship.retrieveList,
    () => {
      if (status === STATUS_DONE) setItems(mapSessionsToImpactItems(data));
    },
    {
      schema: [sessionSchema],
    }
  );

  useEffect(() => {
    fetchItems({
      host: userId,
      o: 'starts_at',
      page_size: CONTAINER_PAGE_SIZE,
      period: 'upcoming',
      view_mode: 'impact',
    });
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (profileStatus === STATUS_LOADING) {
    <Loading />;
  }

  return (
    <UpcomingImpactContainer
      title="Sessions"
      emptyMessage="No upcoming sessions yet."
      items={items}
      status={status}
      showViewAllButton={count > CONTAINER_PAGE_SIZE}
      handleViewAllButtonClick={() => setShowViewAllModal(true)}
      showViewAllModal={showViewAllModal}
      viewAllModal={
        <UpcomingSessionsImpactModal
          user={{ id: profileData.id, name: profileData.display_name }}
          handleClose={() => setShowViewAllModal(false)}
        />
      }
    />
  );
};
