import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useCurrentUser } from 'app/shared/hooks';
import actions from 'entities/actions';
import { userSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import MediaPoint from 'shared/components/MediaPoint';
import Text from 'shared/components/Text';
import UserAvatar from 'shared/components/UserAvatar';
import { STATUS_DONE } from 'shared/constants';
import { get, isEmpty, isEqual } from 'vendor/lodash';
import { Tooltip, Typography, grey, Stack } from 'vendor/mui';
import { HelpOutlineIcon } from 'vendor/mui-icons';

const HeaderInfoContainer = styled.div`
  margin: 16px 0;
`;

const SessionsInfoContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;

  ${MediaPoint.Tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SessionsCountContainer = styled.div``;

const HostHeaderContainer = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 16px;
`;

const HostHeader = ({ host }) => (
  <HeaderInfoContainer>
    <UserAvatar size="xl" hasBorder={false} user={host} showPopover sendEmailCTA />
    <HostHeaderContainer>
      <Text size="h1" color={colors.neutral900} block>
        {host.name}
      </Text>
      <Link to={mapRoute('userProfile', { id: host.id })}>
        <Text size="h4" color={colors.emphasis600}>
          View Profile
        </Text>
      </Link>
    </HostHeaderContainer>
  </HeaderInfoContainer>
);

HostHeader.propTypes = {
  host: PropTypes.object,
};

const SessionFilterHeader = ({ program, hostId, host, hostStatus, sessionsCount, fetchHost }) => {
  const {
    coaching_booking_closes_cutoff_minutes: coachingBookingClosesCutoffMinutes,
    coaching_booking_opens_cutoff_minutes: coachingBookingOpensCutoffMinutes,
  } = useCurrentUser();
  useEffect(() => {
    if (hostId) {
      fetchHost();
    }
  }, [hostId]);

  if (hostId && !isEqual(hostStatus, STATUS_DONE)) return <Loading />;

  return (
    <div>
      {hostId && !isEmpty(host) && isEmpty(program) && <HostHeader host={host} />}

      <SessionsInfoContainer>
        <SessionsCountContainer>
          <Text size="h4" color={colors.neutral900}>
            {sessionsCount > 1 || sessionsCount === 0
              ? `${sessionsCount} available sessions`
              : `${sessionsCount} available session`}{' '}
            <Tooltip
              title={
                <Stack direction="column" spacing={2}>
                  <Typography variant="subtitle">
                    Showing only upcoming sessions that are within the parameters of the booking
                    cutoff settings.
                  </Typography>
                  <Typography variant="subtitle">
                    You can book a session{' '}
                    {moment
                      .duration(Math.abs(coachingBookingOpensCutoffMinutes), 'seconds')
                      .humanize()}{' '}
                    {coachingBookingOpensCutoffMinutes < 0 ? 'before it starts' : 'after it starts'}{' '}
                    until{' '}
                    {moment
                      .duration(Math.abs(coachingBookingClosesCutoffMinutes), 'seconds')
                      .humanize()}{' '}
                    {coachingBookingClosesCutoffMinutes < 0
                      ? 'before it starts'
                      : 'after it starts'}
                  </Typography>
                </Stack>
              }
              arrow
            >
              <HelpOutlineIcon sx={{ fontSize: 14, color: grey[600] }} />
            </Tooltip>
          </Text>
        </SessionsCountContainer>
      </SessionsInfoContainer>
    </div>
  );
};

SessionFilterHeader.propTypes = {
  program: PropTypes.object,

  hostId: PropTypes.string,
  host: PropTypes.object,
  hostStatus: PropTypes.string,
  fetchHost: PropTypes.func,

  sessionsCount: PropTypes.number,
};

const mapStateToProps = (state, props) => {
  const hostId = get(props, 'hostId');
  const hostRequestState = getDataFromState(`host${hostId}`, state, userSchema);

  return {
    hostStatus: hostRequestState.status,
    host: hostRequestState.data,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const hostId = get(props, 'hostId');

  return {
    fetchHost: () => dispatch(actions.userData.retrieveDetails(`host${hostId}`, hostId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionFilterHeader);
