import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FormFieldError } from 'app/shared/components/Form/FormComponents';
import InfoText from 'shared/components/InfoText';

const ContentContainer = styled.div`
  span {
    display: block;
  }
`;

const CourseUploadFieldHelpText = ({ helpText, error, left, top }) => {
  return (
    <InfoText
      top={top}
      left={left}
      fontSize={12}
      content={
        <ContentContainer>
          <FormFieldError customErrorMessage={error} />

          {helpText}
        </ContentContainer>
      }
    />
  );
};

CourseUploadFieldHelpText.defaultProps = {
  top: 0,
  left: 16,
};

CourseUploadFieldHelpText.propTypes = {
  helpText: PropTypes.object,
  error: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
};

export default CourseUploadFieldHelpText;
