import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
} from 'event-shared/constants';
import { useCurrentUser, usePreviousLocation, useQueryParams } from 'shared/hooks';
import { includes } from 'vendor/lodash';

const getSegmentClient = () => window.analytics;

export const identify = (userId) => {
  const client = getSegmentClient();
  if (!client) return;

  client.identify(userId);
};

export const METRICS_ACTIVITIES = {
  EVENT_VIEW: 'Event View',
  EVENTS_VIEW: 'Events View',
  EVENT_ENROLL: 'Event Enroll',
  EVENT_UNENROLL: 'Event Unenroll',
  EVENT_ENROLLMENTS_VIEW: 'Event Enrollments View',
  CODELAB_LAUNCH: 'Codelab Launch',
  COURSE_LAUNCH: 'Course Launch',
  VIDEO_PLAY: 'Video Play',
  MENTEES_VIEW: 'Mentees View',
  MENTORS_VIEW: 'Mentors View',
  PEOPLE_VIEW: 'People View',
  MENTORSHIP_SESSION_BOOK: 'Mentorship Session Book',
  MENTORSHIP_SESSION_CANCEL: 'Mentorship Session Cancel',
  MENTORSHIP_SESSION_REQUEST: 'Mentorship Session Request',
  PROGRAM_SESSION_BOOK: 'Program Session Book',
  PROGRAM_SESSION_CANCEL: 'Program Session Cancel',
  CONTENT_ITEM_VIEW: 'Content Item View',
  CONTENT_ITEM_ASSIGNMENTS_VIEW: 'Content Item Assignments View',
  CONTENT_ITEM_TODO: 'Content Item TODO',
  CONTENT_ITEM_UNTODO: 'Content Item unTODO',
  EVENT_TYPE_REQUEST: 'Event Type Request',
  USER_PROFILE_VIEW: 'User Profile View',
  USER_PROFILE_EDIT: 'User Profile Edit',
  MY_GROUPS_VIEW: 'My Groups View',
  GROUP_VIEW: 'Group View',
  ENTITY_CREATE: 'Entity Create',
  ENTITY_BULK_DUPLICATE: 'Entity Bulk Duplicate',
  ENTITY_UPDATE: 'Entity Update',
  ENTITY_DELETE: 'Entity Delete',
  DASHBOARD_VIEW: 'Dashboard View',
  DASHBOARD_SEGMENT_VIEW: 'Dashboard Segment View',
  DASHBOARD_BULK_ACTION: 'Dashboard Bulk Action',
  CHARTS_VIEW: 'Charts View',
  SURVEY_PREVIEW: 'Survey Preview',
  CATALOG_VIEW: 'Catalog View',
  AUTOMATED_RULE_VIEW: 'Automated Rule View',
  MENTORSHIP_SUGGEST: 'Suggest mentorship',
  MY_TEM_VIEW: 'My Team View',
  ENGAGEMENT_VIEW: 'Engagement View',
  IMPACT_VIEW: 'Impact View',
  MANAGER_ASSIGN_CONTENT: 'Manager Assign Content',
  MANAGER_MENTORSHIP_SUGGEST: 'Manager Suggest Mentorship',
  MANAGER_MARK_COMPLETE: 'Manager Mark as Manager',
  MANAGER_MARK_INCOMPLETE: 'Manager Mark as Incomplete',
  TAB_CLICK: 'Tab Click',
  MY_HUB_VIEW: 'MyHub View',
  MY_HUB_SEARCH: 'MyHub Search',
  REPORT_SHARED: 'Report Shared',
};

export const getEnrollmentStatusLabel = (status) => {
  return includes([ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE], status)
    ? 'enrolled'
    : 'waitlisted';
};

export const getAttendanceMethodLabel = (status) => {
  return includes([ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_WAIT_LIST], status)
    ? 'local'
    : 'online';
};

export const useMetrics = () => {
  const client = getSegmentClient();
  const { id, role } = useCurrentUser();
  const lastLocation = usePreviousLocation();
  const tenant = window.CUSTOMER.schema_name;

  const context = {
    ip: '0.0.0.0', // Reset IP information
    page: {
      referrer: '-', // Reset default referrer information
    },

    userId: id,
    userRole: role,
    tenant,
    referrerUrl: lastLocation?.pathname ?? '',
  };

  const trackActivity = (event, properties) => {
    if (!client) return;
    client.track(event, { ...properties, userId: context.userId, tenant }, { context });
  };

  return {
    trackActivity,
  };
};

export const useEntityMetrics = () => {
  const { trackActivity } = useMetrics();
  const { cloned } = useQueryParams();

  const trackEntityActivity = ({ id, isEdit, entityType }) => {
    if (isEdit) {
      trackActivity(METRICS_ACTIVITIES.ENTITY_UPDATE, {
        entityType,
        entityId: id,
      });
    } else {
      trackActivity(METRICS_ACTIVITIES.ENTITY_CREATE, {
        entityType,
        entityId: id,
        duplicatedEntityId: cloned ? Number(cloned) : undefined,
      });
    }
  };

  return { trackEntityActivity };
};
