import React from 'react';

import ModalManager from 'shared/components/ModalManager';

import BulkAddUsersModal from './BulkAddUsersModal';
import BulkAddUsersSuccessModal from './BulkAddUsersSuccessModal';

class BulkAddUsersFlow extends React.Component {
  constructor(props) {
    super(props);

    this.bulkAddUsersModalManager = React.createRef();
    this.successModalManager = React.createRef();
  }

  startFlow = () => {
    this.bulkAddUsersModalManager.current.show();
  };

  handleSubmitSuccess = () => {
    this.bulkAddUsersModalManager.current.hide();
    this.successModalManager.current.show();
  };

  handleAddMore = () => {
    this.successModalManager.current.hide();
    this.bulkAddUsersModalManager.current.show();
  };

  render() {
    return (
      <>
        <ModalManager ref={this.bulkAddUsersModalManager}>
          <BulkAddUsersModal onSubmitSuccess={this.handleSubmitSuccess} />
        </ModalManager>
        <ModalManager ref={this.successModalManager}>
          <BulkAddUsersSuccessModal onAddMoreClick={this.handleAddMore} />
        </ModalManager>
      </>
    );
  }
}

export default BulkAddUsersFlow;
