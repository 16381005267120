import React, { useEffect, useState } from 'react';
import { FormSection } from 'redux-form';

import { ApiURLs, fetchURL } from 'services/requests-base';
import SettingContextBaseBlock from 'settings/components/SettingContextBaseBlock';
import { getSettingHashKey, getSettingIcon } from 'settings/services';
import { map } from 'vendor/lodash';

export const useSettingsSectionsList = (context, form) => {
  const [settings, setSettings] = useState({});
  const [sectionsList, setSectionsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const url = `${ApiURLs['api_settings:settings']()}?context=${context}`;

    setLoading(true);

    fetchURL(url)
      .then((response) => {
        setSettings(response.data);
        setLoading(false);

        return response;
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!settings) return;

    setSectionsList(
      map(settings?.items, (value, key) => {
        const hashKey = getSettingHashKey(key);
        const icon = getSettingIcon(key);

        return {
          id: hashKey,
          label: value.label,
          icon,
          section: (
            <FormSection name="settings" key={hashKey}>
              <SettingContextBaseBlock
                form={form}
                settingName={key}
                setting={value}
                depth={1}
                defaultOpen={false}
              />
            </FormSection>
          ),
          sectionProps: {
            defaultOpen: false,
            hasFormSectionContainer: true,
          },
        };
      })
    );
  }, [settings]);

  return [sectionsList, loading];
};

export const useParentSettings = (context, view_mode, parent_content_id) => {
  const [settings, setSettings] = useState({});
  const settingsUrl = `${ApiURLs['api_settings:settings']()}?context=${context}${
    parent_content_id
      ? `&view_mode=${view_mode}&parent_content_id=${parent_content_id}&use_serializer=true`
      : `&view_mode=${view_mode}&use_serializer=true`
  }`;

  useEffect(() => {
    fetchURL(settingsUrl).then((response) => {
      setSettings(response.data);

      return response;
    });
  }, []);

  return settings;
};
