import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { STATUS_DONE } from 'app/shared/constants';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { FilterOptionsQueryList } from 'navigation/components/FilterBar/FilterOptions';
import { filter, get, includes, join, map, size, toLower } from 'vendor/lodash';

const UserFilter = ({
  param,
  selecteds,
  onChange,
  label,
  placeholderLabel,
  extraQueryParams,
  setSelectedUsers,
}) => {
  const [options, setOptions] = useState([]);

  const [fetch] = useEntities(
    actions.userData.retrieveList,
    ({ data: { results, count }, status }) => {
      if (status === STATUS_DONE) {
        const options = map(results, (u) => ({
          name: u.display_name,
          value: `${u.value}`,
          image: u.profile_image,
          dataType: 'user',
        }));

        if (count > size(results)) {
          options.push({
            name: `and ${count - results.length} more`,
            value: '',
            isDisabled: true,
          });
        }
        setOptions(options);
      }
    }
  );

  const fetchOptions = (queryParams = {}) => {
    const q = get(queryParams, 'q');
    const includeIds = get(queryParams, 'include_ids');
    const pageSize = size(includeIds) + 10;

    fetch(
      {
        q,
        include_ids: join(includeIds, ','),
        page_size: pageSize,
        view_mode: 'filter_options',
        ...extraQueryParams,
      },
      { skipSchema: true }
    );
  };

  const handleOnChange = (newUsers) => {
    onChange({
      ...selecteds,
      ...{ [param]: newUsers },
    });
  };

  const selectedUsers = selecteds[param] || [];

  useEffect(() => {
    if (setSelectedUsers) {
      const users = filter(options, (user) => includes(selectedUsers, user.value));

      setSelectedUsers(users);
    }
  }, [selecteds, options]);

  return (
    <FilterOptionsQueryList
      onChange={handleOnChange}
      options={options}
      fetchOptions={fetchOptions}
      selecteds={selectedUsers}
      searchPlaceholder={`Search by ${placeholderLabel || toLower(label)}`}
      imageOnDropdownItem
    />
  );
};

UserFilter.propTypes = {
  param: PropTypes.string.isRequired,
  selecteds: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholderLabel: PropTypes.string,
  extraQueryParams: PropTypes.object,
  setSelectedUsers: PropTypes.func,
};

export default UserFilter;
